import { DataTableCol } from "../../../../components/data-table/types";

export const PrincipalDataGridCols: DataTableCol[] = [
  {
    title: "Name",
    control: "name",
    canShowColumn: true,
  },
  {
    title: "User Name",
    control: "user_name",
    canShowColumn: true,
  },
  {
    title: "Email",
    control: "email_id",
    canShowColumn: true,
  },
  {
    title: "Mobile Number",
    control: "mobile_number",
    canShowColumn: true,
  },

  {
    title: "City/Village",
    control: "city_village",
    canShowColumn: true,
  },
  {
    title: "Actions",
    // control: 'both'
    control: "one",
    actions: true,
    isEdit: false,
    isDelete: false,
    canShowColumn: false,
    setPassword: false,
  },
];
