import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { DataTable } from "../../../components/data-table";
import { RolesDataGridCols } from "./data-grid-cols";
import { ToastContainer, toast } from "react-toastify";
import {
  GET_ROLES_PAGE_NUMBER,
  DELETE_ROLE,
} from "../../../app/service/roles.service";
import { GET_OBJECTS } from "../../../app/service/access-object.service";
import {
  GET_ACCESS_PERMISSIONS,
  UPDATE_PERMISSIONS,
} from "../../../app/service/access-permissions.service";
import { type } from "os";

export const RolesList = () => {
  const [pageArray, setPageNumbers] = useState([]);
  const [activePage, setActivePage] = useState<any>();
  const [rolesList, setRolesList] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const search = useLocation().search;
  const currentPageNumber: any = new URLSearchParams(search).get("page");
  const history = useHistory();
  const [canShowRolePopup, setCanRolePopup] = useState(false);
  const [objectsList, setObjectsList] = useState<any>([]);
  const [read, setRead] = useState(false);
  const [write, setWrite] = useState(false);
  const [selectedRole, setSelectedRole] = useState<any>();
  const [btnDisable, setBtnDisable] = useState(false);
  const [expand, setExpand] = useState(false);
  const [accessObjectsList, setAccessObjectsList] = useState<any>([]);
  const [accessNew, setAccessNew] = useState<any>([]);
  const [userRole, setUserRole] = useState<any>();

  useEffect(() => {
    getRoles();
    const accessObjectsData: any = localStorage.getItem("access_objects_data");
    setAccessObjectsList(JSON.parse(accessObjectsData));
    handleRolesCols(JSON.parse(accessObjectsData));
    const userRole = localStorage.getItem("user_role");
    setUserRole(userRole);

    if (userRole === "SUPER_ADMIN") {
      for (let j = 0; j < RolesDataGridCols.length; j++) {
        RolesDataGridCols[j].isPrivileges = true;
        RolesDataGridCols[j].canShowColumn = true;
      }
    }
  }, []);

  const filterData = (originalData: any) => {
    let finalData = [];
    let parentData = [];
    let childData = [];
    for (let index = 0; index < originalData.length; index++) {
      let element: any = originalData[index];
      if (element.object_code.includes("-")) {
        childData.push(element);
      } else {
        element.child = [];
        element.canShowChildren = false;
        parentData.push(element);
      }
    }
    for (let index = 0; index < childData.length; index++) {
      const element = childData[index];
      if (element.object_code.includes("-")) {
        let splitTitle = element.object_code.split("-");
        for (let i = 0; i < parentData.length; i++) {
          let ele: any = parentData[i];
          if (ele.object_code == splitTitle[0]) {
            ele.child.push(element);
          }
        }
      } else {
        parentData.push(element);
      }
    }
    finalData = parentData;
    setObjectsList(finalData);
  };

  const getPermissions = (roleCode: any) => {
    setLoading(true);
    GET_ACCESS_PERMISSIONS(roleCode).then((res: any) => {
      if (res) {
        if (res.status === "fail") {
          toast.error(res.message);
          setLoading(false);
        } else {
          const data = res;
          if (res.length > 0) {
            filterData(data);
            setLoading(false);
          } else {
            setObjectsList([]);
            setLoading(false);
            setBtnDisable(true);
          }
        }
      }
    });
  };

  function getRoles() {
    const activePageNumber = currentPageNumber > 0 ? currentPageNumber - 1 : 0;
    setLoading(true);
    GET_ROLES_PAGE_NUMBER(activePageNumber).then((res: any) => {
      if (res) {
        if (res.status === "fail") {
          toast.error(res.message);
          setLoading(false);
        } else {
          setActivePage(currentPageNumber ? currentPageNumber : 1);
          const data = res.rows;
          setRolesList(data);
          const pageNumbers = Math.ceil(res.count / 10);
          const pageArray: any = [];
          for (let i = 1; i <= pageNumbers; i++) {
            pageArray.push(i);
          }
          setPageNumbers(pageArray);
          setLoading(false);
        }
      }
    });

  }

  const onPageChange = (data: any) => {
    if (data) {
      history.push(`/dashboard/roles?page=${data}`);
    }
    setActivePage(data);
    const pageNumber = data - 1;
    setLoading(true);
    GET_ROLES_PAGE_NUMBER(pageNumber).then((res: any) => {
      if (res) {
        if (res.status === "fail") {
          toast.error(res.message);
          setLoading(false);
        } else {
          const data = res["rows"];
          setRolesList(data);
          setLoading(false);
        }
      }
    });
  };

  const onEditRole = (data: any) => {
    history.push(`/dashboard/roles/form/${data.id}`);
  };

  const onDeleteRole = (data: any) => {
    setLoading(true);
    const id = data.id;
    DELETE_ROLE(id).then((res: any) => {
      if (res.status === "fail") {
        toast.error(res.message);
        setLoading(false);
      } else {
        setLoading(false);
        toast.success("Role Deleted Successfully");
        getRoles();
      }
    });
  };

  const getRoleDetails = (data: any) => {
    setCanRolePopup(true);
    setSelectedRole(data);
    getPermissions(data.role_code);
    // setBtnDisable(true);
  };

  const handleRoleClose = () => {
    setCanRolePopup(false);
  };

  const handlePermissionChild = (
    data: any,
    type: string,
    item: any,
    i: number,
    j: number
  ) => {
    const objectListData = objectsList;

    if (type === "read") {
      objectListData[i].child[j].p_read = data.target.checked ? 1 : 0;
      if (objectListData[i].child[j].p_read === 1) {
        objectListData[i].p_read = 1;

      }
    } else if (type === "write") {
      objectListData[i].child[j].p_write = data.target.checked ? 1 : 0;
      // objectListData[i].p_write ===  1;
      if (objectListData[i].child[j].p_write === 1) {
        objectListData[i].p_write = 1;
      }
    }


    setObjectsList(objectListData);

  };

  const handlePermission = (data: any, type: string, i: number) => {
    const objectData = objectsList;

    if (type === "read") {
      objectData[i].p_read = data.target.checked ? 1 : 0;
    } else if (type === "write") {
      objectData[i].p_write = data.target.checked ? 1 : 0;
    }
    setObjectsList([...objectData]);

  };

  function savePermission() {
    const postData: any = []; //{role_code:selectedRole.role_code,objects:[]};
    const objectData = objectsList;

    for (let i = 0; i < objectData.length; i++) {
      if (objectData[i].p_read === 1 || objectData[i].p_write === 1) {
        postData.push({
          object_code: objectData[i].object_code,
          p_read: objectData[i].p_read,
          p_write: objectData[i].p_write,
        });
      }
      for (let j = 0; j < objectData[i].child.length; j++) {
        if (
          objectData[i].child[j].p_read === 1 ||
          objectData[i].child[j].p_write === 1
        ) {
          postData.push({
            object_code: objectData[i].child[j].object_code,
            p_read: objectData[i].child[j].p_read,
            p_write: objectData[i].child[j].p_write,
          });
        }
      }
    }
    const newObject = {
      role_code: selectedRole.role_code,
      objects: postData,
    };
    UPDATE_PERMISSIONS(newObject).then((res: any) => {
      if (res.status === "fail") {
        toast.error(res.message);
        setLoading(false);
      } else {
        toast.success("Access permissions created/updated successfully!");
        history.push("/dashboard/roles");
        setCanRolePopup(false);
      }
    });
  }

  const handleObjectAccordion = (e: any, i: any, item: any) => {
    let expands = expand;
    setExpand(!item.canShowChildren);
    item.canShowChildren = !item.canShowChildren;
    var element = e.target;
    var panel: any = document.getElementsByClassName(`panel${i}`);
    if (panel.length > 0) {
      for (let index = 0; index < panel.length; index++) {
        const element = panel[index];
        if (item.canShowChildren) {
          element.classList.remove("hideTab");
          element.classList.add("showTab");
        } else {
          element.classList.remove("showTab");
          element.classList.add("hideTab");
        }
      }
    } else {
      panel.classList.remove("hideTab");
      panel.classList.add("showTab");
    }

  };



  const handleRolesCols = (accessObjectsList: any) => {
    const preparedData = accessObjectsList?.filter(
      (el: any) => el.object_code === "ROLES"
    );
    setAccessNew(preparedData);

    if (preparedData?.length > 0) {
      for (let i = 0; i < preparedData[0].child.length; i++) {
        for (let j = 0; j < RolesDataGridCols.length; j++) {
          if (
            preparedData[0].child[i].object_code === "ROLES-EDIT" &&
            preparedData[0].child[i].p_read === 1
          ) {
            if (RolesDataGridCols[j].title == "Actions") {
              // RolesDataGridCols[j].isEdit = true;
            }
          }

          if (
            preparedData[0].child[i].object_code === "ROLES-DELETE" &&
            preparedData[0].child[i].p_read === 1
          ) {
            if (RolesDataGridCols[j].title == "Actions") {
              // RolesDataGridCols[j].isDelete = true;
            }
          }

          if (
            preparedData[0].child[i].object_code === "ROLES-PRIVILEGES" &&
            preparedData[0].child[i].p_read === 1
          ) {
            if (RolesDataGridCols[j].title == "Actions") {
              RolesDataGridCols[j].isPrivileges = true;
            }
          }

          if (
            (preparedData[0].child[i].object_code === "ROLES-EDIT" &&
              preparedData[0].child[i].p_read === 1) ||
            (preparedData[0].child[i].object_code === "ROLES-DELETE" &&
              preparedData[0].child[i].p_read === 1) ||
            (preparedData[0].child[i].object_code === "ROLES-PRIVILEGES" &&
              preparedData[0].child[i].p_write === 1)
          ) {
            if (RolesDataGridCols[j].title == "Actions") {
              RolesDataGridCols[j].canShowColumn = true;
            }
          }


        }

      }
    }

  };
  function onHandleNew() {

    if (accessNew?.length > 0) {
      for (let i = 0; i < accessNew[0].child.length; i++) {
        if (
          accessNew[0].child[i].object_code === "ROLES-NEW" &&
          accessNew[0].child[i].p_read === 1
        ) {
          return true;
        }
      }
    }
  }

  return (
    <div>
      <div
        className={`modal fade ${canShowRolePopup ? "show" : "false"}`}
        style={{ display: canShowRolePopup ? "block" : "none" }}
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog parent-model-dialog" role="document">
          <div className="modal-content parent-model">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Privileges
              </h5>
              <button
                type="button"
                className="close popup-close cursor-pointer"
                data-dismiss="modal"
                aria-label="Close"
                onClick={handleRoleClose}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body parent-info parent-model">
              {loading && (
                <div className="text-center p-5">
                  <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              )}
              {!loading && (
                <>
                  <div className="mar-t-20 custom-scroll table-responsive">
                    <table className="table fixed-table-header mb-0">
                      <thead>
                        <tr>
                          <th>Object Code</th>
                          <th>Object Name</th>
                          <th>Read </th>
                          <th>Write </th>
                        </tr>
                      </thead>
                      <tbody>
                        {objectsList.map((item: any, i: number) => (
                          <React.Fragment>
                            {/* <div> */}
                            <tr key={i}>
                              <td>{item.object_code}</td>
                              <td>{item.object_name}</td>
                              <td>
                                <input
                                  className="ms-3 cursor-pointer"
                                  type="checkbox"
                                  checked={item.p_read === 1 ? true : false}
                                  onChange={(event) =>
                                    handlePermission(event, "read", i)
                                  }
                                />
                              </td>
                              <td>
                                <input
                                  className="ms-3 cursor-pointer"
                                  type="checkbox"
                                  checked={item.p_write === 1 ? true : false}
                                  onChange={(event) =>
                                    handlePermission(event, "write", i)
                                  }
                                />

                                {item.child.length > 0 && (
                                  <span
                                    className="text-end  border-0 cursor-pointer  bi bi-chevron-down"
                                    style={{
                                      position: "relative",
                                      left: "30%",
                                    }}
                                    onClick={(event) =>
                                      handleObjectAccordion(event, i, item)
                                    }
                                  ></span>
                                )}
                              </td>
                            </tr>

                            {item.child.length > 0 &&
                              item.child.map((name: any, j: number) => (
                                <>
                                  <tr
                                    className={`bg_child panel${i} hideTab `}
                                    id="bg_child"
                                    key={j}
                                  >
                                    <td className="py-2">{name.object_code}</td>
                                    <td>{name.object_name}</td>
                                    <td>
                                      <input
                                        className="ms-3 cursor-pointer"
                                        type="checkbox"
                                        defaultChecked={
                                          name.p_read === 1 ? true : false
                                        }
                                        onChange={(event) =>
                                          handlePermissionChild(
                                            event,
                                            "read",
                                            item,
                                            i,
                                            j
                                          )
                                        }
                                      />
                                    </td>
                                    <td>
                                      <input
                                        className="ms-3 cursor-pointer"
                                        type="checkbox"
                                        defaultChecked={
                                          name.p_write === 1 ? true : false
                                        }
                                        onChange={(event) =>
                                          handlePermissionChild(
                                            event,
                                            "write",
                                            item,
                                            i,
                                            j
                                          )
                                        }
                                      />
                                    </td>
                                  </tr>
                                </>
                              ))}

                            {/* </div> */}
                          </React.Fragment>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  {objectsList.length === 0 && (
                    <div className="mt-3 text-center">No records found.</div>
                  )}
                </>
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary cursor-pointer"
                data-dismiss="modal"
                onClick={() => savePermission()}
                disabled={btnDisable}
              >
                Save
              </button>

              <button
                type="button"
                className="btn btn-secondary cursor-pointer"
                data-dismiss="modal"
                onClick={handleRoleClose}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="row border-top border-primary py-3">
        <div className="row mb-3 mb-md-0 ">
          <div className="col-md-10 col-7">
            <h2>Roles</h2>
          </div>
          {onHandleNew() && (
            <div className="border-primary py-md-3 py-1 text-end col-md-2 col-5 ps-4 ps-md-0">
              {/* <Link
                to={`/dashboard/roles/form/0`}
                className="btn btn-primary btn-sm px-5 rounded-12"
              >
                New
              </Link> */}
            </div>
          )}
        </div>
        {loading && (
          <div className="text-center p-5">
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        )}
        {!loading && (
          <DataTable
            TableCols={RolesDataGridCols}
            tableData={rolesList}
            editInfo={onEditRole}
            deleteInfo={onDeleteRole}
            onChange={getRoleDetails}
            activePageNumber={activePage}
            pageNumber={onPageChange}
            pageNumbers={pageArray}
          ></DataTable>
        )}
      </div>
    </div>
  );
};
