import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { GET_MLA_QUESTIONS, MLA_ANSWERS_UPDATE, MLA_TIME_UPDATE } from '../../../app/service/mla.service';
import { MNS_QUESTIONS_LIST, MNS_SUBMIT_QUESTIONS } from '../../../app/service/mns-service';
import { CREATE_MODULE_ANSWERS, GET_MNS_MODULES_BY_ID, GET_MNS_MODULE_BY_QUESTIONS, GET_MODULES_BY_ID, UPDATE_ASSIGN_USER } from '../../../app/service/module.service';
import FormBuilder from '../../../components/form-builder';


export const MLAQuestionsForm = () => {
    const [questionsData, setQuestionsData] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    const [canShowCompleteFormBtn, setCanShowCompleteFormBtn] = useState(false);
    const [questionsFormData, setQuestionsFormData] = useState<any>({});
    const [moduleType, setModuleType] = useState<any>();
    const [tempInfo, setTempInfo] = useState<any>({});
    const [minutes, setMinutes] = useState(2);
    const [seconds, setSeconds] = useState(0);
    const [timeUpAlert, setTimeUpalert] = useState(false);
    const [time_remain, settime_remain] = useState<any>();
    const [time, setTime] = useState<any>({});
    const [timeLeft, setTimeLeft] = useState<any>(null);
    const [startTimer, setStartTimer] = useState(false);
    const [canShowHeader, setShowHeader] = useState(false);
    const [mnsInfo, setMnsInfo] = useState<any>(null);
    const [attemptLevels, setAttemptLevels] = useState<any>([1, 2, 3]);
    const [testInfo, setTestInfo] = useState<any>({});
    const [type, setType] = useState<any>('');
    const [cycleNumber, setCycleNumber] = useState<any>('');
    const [worksheets, setWorksheets] = useState<any[]>([]);
    const [timeRemain, setTimeRemain] = useState('');
    const [startTime, setStartTime] = useState(new Date());
    const [canShowPopup, setCanShowPopup] = useState(false);

    let timeUp = false;
    let time_remain_storage = '';
    let { moduleUUID, userCode, schoolCode } = useParams<{ moduleUUID: string, userCode: string, schoolCode: string }>();

    const surveyForm = useRef<any>({});
    const history = useHistory();
    let timeRemaining = 0;

    useEffect(() => {
        getMLAInfo();
    }, []);

    useEffect(() => {
        if (startTimer) {
            const timer = setInterval(() => {
                startTimerTemp();
            }, 1000);

            return () => {
                clearInterval(timer);
            };
        }
    }, [startTimer])

    const startTimerTemp = () => {
        const testStartDate: any = localStorage.getItem("test_start_date");
        let timeMillis = moment(testStartDate, 'YYYY-MM-DD hh:mm:ss').toDate().getTime();
        let reqTimeMillis = 2 * 24 * 60 * 60 * 1000;
        let actualTimeToComplete = timeMillis + reqTimeMillis;

        let x = new Date(actualTimeToComplete)
        let currentTime = new Date().getTime();

        let timerResult = x.getTime() - currentTime;
        let tSeconds = Math.floor(timerResult / 1000);
        let hours = Math.floor(tSeconds / 3600); // get hours
        let minutes = Math.floor((tSeconds - (hours * 3600)) / 60); // get minutes
        let seconds = tSeconds - (hours * 3600) - (minutes * 60); //  get seconds


        setTimeRemain((hours < 10 ? "0" + hours : hours) + ":" + (minutes < 10 ? "0" + minutes : minutes) + ":" + (seconds < 10 ? "0" + seconds : seconds) + ' hrs');
    }


    function getMLAInfo() {
        const testInfo: any = localStorage.getItem("test_info");
        const type = localStorage.getItem("type");
        const cycleNumber = localStorage.getItem("cycleNumber");
        const worksheets: any = localStorage.getItem("WORKSHEETS");
        const testStartDate: any = localStorage.getItem("test_start_date");
        setTestInfo(JSON.parse(testInfo));
        setType(type);
        setCycleNumber(cycleNumber);
        getMLAQuestionsList(JSON.parse(testInfo), type, testStartDate);
        setWorksheets(JSON.parse(worksheets));
    }


    function getMLAQuestionsList(testInfo: any, type: any, testStartDate: any) {
        let payload = {
            "mode": type,
            "test_no": testInfo.question_paper_id ? testInfo?.question_paper_id : '',
            "ability": testInfo.ability ? testInfo?.ability : '',
            "worksheet_no": testInfo.id ? testInfo?.id : ''
        }

        setShowHeader(false);
        setLoading(true);
        GET_MLA_QUESTIONS(payload).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    if (res) {
                        if (res.questions && res.questions.length > 0) {
                            setQuestionsData(res.questions);
                            setShowHeader(true);
                            setStartTimer(true);
                            setLoading(false);
                            if (type === 'TESTS' && (!testStartDate || testStartDate === '')) {
                                storeInitialTimeForTest();
                            }
                        } else {
                            setQuestionsData([]);
                            setShowHeader(true);
                            setStartTimer(true);
                            setLoading(false);
                        }

                    }

                }
            }
        });
    }

    function storeInitialTimeForTest() {
        let payload = {
            "user_code": userCode,
            "school_code": schoolCode,
            "cycle_no": cycleNumber ? cycleNumber : 1,
            "test_start_date": moment(startTime).format('YYYY-MM-DD HH:mm:ss')
        }

        MLA_TIME_UPDATE(payload).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                } else {
                    if (res) {
                    }
                }
            }
        });
    }


    const handleOptions = (data: any) => {
        setQuestionsFormData(data);
    }

    function handleQuestionAnswers(e: any, item: any, i: number) {
        let mnsQuestions = questionsData;
        mnsQuestions[i].answer = e.target.value;
        setQuestionsData(mnsQuestions);
    }


    const handleCompleteSubmit = () => {
        let payload = {
            "user_code": userCode,
            "school_code": schoolCode,
            "cycle_no": cycleNumber ? cycleNumber : 1,
            "mode": type,
            "test_no": testInfo.test_no ? testInfo.test_no : '',
            "question_paper_id": testInfo.question_paper_id,
            "ability": testInfo.ability ? testInfo.ability : '',
            "priority_no": testInfo.priority ? testInfo.priority : '',
            "time_taken": 10,
            "test_start_date": moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
            "questions": questionsData
        }
        MLA_ANSWERS_UPDATE(payload).then((res: any) => {
            if (res) {
                const data = res;
                if (type === 'WORKSHEETS') {
                    setCanShowPopup(true);
                    toast.success('You have successfully completed worksheet');
                    return;
                }
                localStorage.setItem('time_remain', timeRemain);
                history.push(`/dashboard/mla/success-page/${moduleUUID}/${userCode}/${schoolCode}`);
            }
        });
    }

    const getCircleColor = (item: any) => {
        if (item.question_paper_id === testInfo.question_paper_id) {
            return '#FCEE21';
        } else if (item.status === 'completed') {
            return '#8CC63F';
        } else {
            return '#FFFFFF';
        }
    }

    const handleBack = () => {
        history.push(`/dashboard/mns/dashboard/${moduleUUID}/${userCode}/${schoolCode}`);
    }

    const handleclose = () => {
        setCanShowPopup(false);
        history.push(`/dashboard/mns/dashboard/${moduleUUID}/${userCode}/${schoolCode}`);
    }

    const handleSubmit = () => {
        setCanShowPopup(false);
        history.push(`/dashboard/mns/dashboard/${moduleUUID}/${userCode}/${schoolCode}`);
    }


    return (
        <div className="row border-top border-primary py-3">
            <div className={`modal fade ${canShowPopup ? 'show' : 'false'}`} style={{ display: (canShowPopup ? "block" : 'none') }} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">You have successfully completed worksheet</h5>
                            <button type="button" className="close popup-close cursor-pointer" data-dismiss="modal" aria-label="Close" onClick={handleclose}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary cursor-pointer" onClick={handleSubmit}>OK</button>
                            <button type="button" className="btn btn-secondary cursor-pointer" data-dismiss="modal" onClick={handleclose}>CANCEL</button>
                        </div>
                    </div>
                </div>
            </div>
            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading && <div>
                {canShowHeader && <div className="row questions-grid mt-4">
                    {type === 'TESTS' && < div className='col-md-8'>
                        <div style={{ fontSize: '14px' }}>MLA</div>
                        <div style={{ fontSize: '25px', fontStyle: 'italic', fontWeight: 'bold' }}>Test -0{testInfo.test_no ? testInfo.test_no : ''}</div>
                    </div>}
                    {type === 'WORKSHEETS' && <div className='col-md-10'>
                        <div style={{ fontSize: '14px' }}>Priority - {testInfo.priority}</div>
                        <div style={{ fontSize: '25px', fontWeight: 'bold' }}>{testInfo.name}</div>
                        <div style={{ fontSize: '12px' }}>Worksheets</div>
                        {worksheets.map((item: any, i: number) => (
                            <div className="circle" style={{ backgroundColor: getCircleColor(item) }}>
                                <p>{i + 1}</p>
                            </div>
                        ))}
                    </div>}
                    {type === 'TESTS' && <div className='col-md-2'>
                        <div>Time Limit</div>
                        <div>{timeRemain}</div>
                    </div>}
                    <div className='col-md-2'>
                        <button className="btn btn-primary btn-sm px-5 rounded-12 cursor-pointer" onClick={handleBack}>Back</button>
                    </div>
                </div>}
                {questionsData.map((question: any, i: number) => (
                    <div className="questions-grid mt-4" key={i}>
                        <div className='row col-md-12'>
                            <div className='col-md-6'>
                                <span className='fw-bold' style={{ fontSize: '22px' }}>Q{question.question_number}:</span>
                                <span className='fw-bold' style={{ fontSize: '22px', paddingLeft: '15px' }}>
                                    {question.question}
                                </span>
                            </div>
                            <div className='col-md-4 pt-2'>
                                <FormBuilder onUpdate={handleOptions}>
                                    <form ref={surveyForm}>
                                        {question.question_type === 'input_text' && <input type="text" className="form-control" name="option" value={question.answer ? question.answer + '' : ''} onChange={(e) => { handleQuestionAnswers(e, question, i) }} />}
                                    </form>
                                </FormBuilder>
                            </div>
                        </div>
                    </div>
                ))}
                {questionsData.length > 0 && <div className="py-3 text-center mt-4">
                    <button className="btn btn-primary px-5 rounded-5 cursor-pointer" onClick={handleCompleteSubmit}>Submit</button>
                </div>}
            </div>}
        </div >
    )
}
