import React, { useEffect, useRef, useState } from 'react'
import { Link, useHistory, useLocation, useParams } from 'react-router-dom'
import { BULK_UPLOAD_STUDENTS, DELETE_USER, DOWNLOAD_STUDENTS, DOWNLOAD_STUDENT_FAILED_EXIST, DOWNLOAD_STUDENT_TEMPLATE, GET_USER_BY_SCHOOL_CODE, GET_USER_Filter } from '../../../../app/service/users.service'
import { DataTable } from '../../../../components/data-table'
import { StudentsDataGridCols } from './data-grid-cols'
import { toast } from 'react-toastify';
import FormBuilder from '../../../../components/form-builder';
import { GET_BULK_UPLOAD_STATUS, GET_TOTAL_SCHOOLS } from '../../../../app/service/school.service'
import appConfig from '../../../../config/constant';

export const StudentsList = () => {
    let { id, schoolCode } = useParams<{ id: string, schoolCode: string }>();
    const schoolId = parseInt(id);
    const schoolsCode = schoolCode;
    const [pageArray, setPageNumbers] = useState([]);
    const [activePage, setActivePage] = useState<any>();
    const [studentList, setStudentList] = useState<any>([]);
    const [schoolList, setSchoolList] = useState<any>([]);
    const [loading, setLoading] = useState(false);
    const [searchData, setSearchData] = useState<any>({});
    const [userRole, setUserRole] = useState<any>();
    const usersForm = useRef<any>({});
    const [canShowPopup, setCanShowPopup] = useState(false);
    const [successList, setSuccessList] = useState<any>([]);
    const [failedList, setFailedList] = useState<any>([]);
    const [alredyExistList, setAlredyExistList] = useState<any>([]);
    const [preparedSearchData, setPreparedSearchData] = useState<any>({});
    const [accessObjectsList, setAccessObjectsList] = useState<any>([]);
    const [schoolStudents, setSchoolStudents] = useState(false);
    const [listTemplate, setListTemplate] = useState(false);
    const [loginCredentials, setLoginCredentials] = useState(false);
    const [listUpload, setListUpload] = useState(false);
    const [listUploadStatus, setListUploadStatus] = useState(false);
    const [createStudents, setCreateStudents] = useState(false);
    const [failedListPath, setFailedListPath] = useState(true);

    const filteredData = [
        { name: 'Email ID', value: 'email_id' },
        { name: 'Mobile Number', value: 'mobile_number' },
        { name: 'Whatsapp Number', value: 'whatsapp_number' },
        { name: 'State', value: 'state' },
        { name: 'District', value: 'district' },
        { name: 'City', value: 'city_village' },
        // { name: 'Date of Registration', value: 'date_of_registration' },
        { name: 'Block', value: 'taluka' },
        { name: 'Gender', value: 'gender' },
        { name: 'Address', value: 'address' },
        { name: 'Blood Group', value: 'blood_group' },
        { name: 'Date of Birth', value: 'date_of_birth' },
        { name: 'Pincode', value: 'pin_code' },
        { name: 'User Name', value: 'user_name' },
    ];
    const search = useLocation().search;
    const currentPageNumber: any = new URLSearchParams(search).get('page');
    const history = useHistory();

    useEffect(() => {
        const userRole = localStorage.getItem("user_role");
        setUserRole(userRole);
        getStudents();
        getSchools();
        getActionsInfo(userRole);

        const accessObjectsData: any = localStorage.getItem("access_objects_data");
        setAccessObjectsList(JSON.parse(accessObjectsData));
        handleSchoolsStudentCols(JSON.parse(accessObjectsData));
    }, []);

    function getActionsInfo(userRole: any) {
        for (let i = 0; i < StudentsDataGridCols.length; i++) {
            if (StudentsDataGridCols[i].title === "Actions") {
                if (userRole === 'INSTITUTE_ADMIN') {
                    StudentsDataGridCols.splice(7, 1);
                }
            }
        }
    }

    function getStudents() {
        const activePageNumber = currentPageNumber > 0 ? (currentPageNumber - 1) : 0;
        if (schoolsCode) {
            setLoading(true);
            GET_USER_BY_SCHOOL_CODE(schoolsCode, 'STUDENT', activePageNumber, {}).then((res: any) => {
                if (res) {
                    if (res.status === 'fail') {
                        toast.error(res.message);
                        setLoading(false);
                    } else {
                        setActivePage(currentPageNumber ? currentPageNumber : 1);
                        const data = res.rows;
                        data.forEach((element: any) => {
                            element.school_student = element.name;
                        });
                        setStudentList(data);
                        const pageNumbers = Math.ceil(res.count / 10);
                        const pageArray: any = [];
                        for (let i = 1; i <= pageNumbers; i++) {
                            pageArray.push(i);
                        }
                        setPageNumbers(pageArray);
                        setLoading(false);
                    }
                }
            });
        }
    }


    const onPageChange = (data: any) => {
        if (data) {
            if (userRole === "CHAIRMAN" || userRole === "GENERAL_SECRETARY" || userRole === "INSTITUTE_ADMIN") {
                history.push(`/institute-dashboard/school/info/${schoolId}/studentslist/${schoolsCode}?page=${data}`);

            } else {
                history.push(`/dashboard/school/info/${schoolId}/studentslist/${schoolsCode}?page=${data}`);
            }
        }
        setActivePage(data);
        const pageNumber = data - 1;
        setLoading(true);
        GET_USER_BY_SCHOOL_CODE(schoolsCode, 'STUDENT', pageNumber, preparedSearchData ? preparedSearchData : {}).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    const data = res['rows'];
                    data.forEach((element: any) => {
                        element.school_student = element.name;
                    });
                    setStudentList(data);
                    setLoading(false);
                }
            }
        });
    }

    const onEditStudent = (data: any) => {
        history.push(`/dashboard/school/info/${schoolId}/studentsform/${schoolsCode}/${data.id}`);
        if (userRole === "CHAIRMAN" || userRole === "GENERAL_SECRETARY" || userRole === "INSTITUTE_ADMIN") {
            history.push(`/institute-dashboard/school/info/${schoolId}/studentsform/${schoolsCode}/${data.id}`);

        }
    }

    const onDeleteStudent = (data: any) => {
        setLoading(true);
        const id = data.user_code;
        DELETE_USER(id).then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res.message);
                setLoading(false);
            } else {
                setLoading(false);
                toast.success("Students Deleted Successfully");
                getStudents();
            }
        });
    }

    function handleUploadBulkUpload(e: any) {
        if (e.target.files && e.target.files[0]) {
            let formData = new FormData();
            formData.append('file', e.target.files[0], e.target.files[0].name);
            formData.append('school_code', schoolsCode);
            setLoading(true);

            BULK_UPLOAD_STUDENTS(formData).then((res: any) => {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    setLoading(false);
                    toast.success("Bulk Upload Successfully");
                    getStudents();
                }
            });
        }

    }

    function getSchools() {
        GET_TOTAL_SCHOOLS().then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res.message);
            } else {
                const data = res['rows'];
                setSchoolList(data);
            }
        });
    }

    const handleInput = (data: any) => {
        setSearchData(data);
    };

    function handleSearch() {
        const data = { ...searchData.value };
        if (data.searchFilter === 'state') {
            data.state = data.search_name;
        } else if (data.searchFilter === 'city_village') {
            data.city_village = data.search_name;
        } else if (data.searchFilter === 'mobile_number') {
            data.mobile_number = data.search_name;
        } else if (data.searchFilter === 'whatsapp_number') {
            data.whatsapp_number = data.search_name;
        } else if (data.searchFilter === 'taluka') {
            data.taluka = data.search_name;
        } else if (data.searchFilter === 'gender') {
            data.gender = data.search_name;
        } else if (data.searchFilter === 'email_id') {
            data.email_id = data.search_name;
        } else if (data.searchFilter === 'address') {
            data.address = data.search_name;
        } else if (data.searchFilter === 'blood_group') {
            data.blood_group = data.search_name;
        } else if (data.searchFilter === 'date_of_birth') {
            const date_of_birth = data.search_name;
            if (data.search_name) {
                const preparedDate = date_of_birth.split('-');
                const date = preparedDate[0];
                const month = preparedDate[1];
                const year = preparedDate[2];
                data.date_of_birth = year + '-' + month + '-' + date;
            }
            // data.date_of_birth = data.search_name;
        } else if (data.searchFilter === 'district') {
            data.district = data.search_name;
        } else if (data.searchFilter === 'pin_code') {
            data.pin_code = data.search_name;
        } else if (data.searchFilter === 'user_name') {
            data.user_name = data.search_name;
        }
        delete data.search_name;
        delete data.searchFilter;
        if (data) {
            setLoading(true);
            for (const key of Object.keys(data)) {
                if (data[key] === "") {
                    delete data[key];
                }
            }
            setPreparedSearchData(data);
            GET_USER_Filter(schoolsCode, data).then((res: any) => {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    if (userRole === "CHAIRMAN" || userRole === "GENERAL_SECRETARY" || userRole === "INSTITUTE_ADMIN") {
                        history.push(`/institute-dashboard/school/info/${schoolId}/studentslist/${schoolsCode}?page=${1}`);
                    } else {
                        history.push(`/dashboard/school/info/${schoolId}/studentslist/${schoolsCode}?page=${1}`);
                    }
                    setActivePage(1);
                    const data = res.rows;
                    data.forEach((element: any) => {
                        element.school_student = element.name;
                    });
                    setStudentList(data);
                    const pageNumbers = Math.ceil(res.count / 10);
                    const pageArray: any = [];
                    for (let i = 1; i <= pageNumbers; i++) {
                        pageArray.push(i);
                    }
                    setPageNumbers(pageArray);
                    setLoading(false);
                }
            });
        }
    }

    function handleRefresh() {
        const data = searchData
        data.value = {};
        setSearchData({ ...data });
        setPreparedSearchData({});
        getStudents();
        usersForm.current.reset();
    }

    function handleDownloadStudents() {
        setLoading(true);
        DOWNLOAD_STUDENTS(schoolsCode).then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res.message);
                setLoading(false);
            } else {
                setLoading(false);
                const data = res;
                toast.success("Download Students Successfully");
                if (data.url) {
                    const url = `${appConfig.FILE_UPLOAD_URL}${data.url.charAt(0) === "/" ? data.url.substring(1) : data.url}`;
                    window.location.href = url;
                }
            }
        });
    }

    function handleUploadBulkUploadStatus() {
        setLoading(true);
        GET_BULK_UPLOAD_STATUS(schoolsCode).then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res.message);
                setLoading(false);
            } else {
                if (res.rows.length > 0) {
                    toast.success(res.rows[0].status);
                    const data = res.rows[res.rows.length - 1];
                    if (data.result) {
                        setSuccessList(data.result.succesList);
                        setFailedList(data.result.failedList);
                        setAlredyExistList(data.result.alreadyExistList);
                    }

                    if (res.rows[res.rows.length - 1].filepath === null || res.rows[res.rows.length - 1].filepath === "") {
                        setFailedListPath(false)
                    }

                }
                setCanShowPopup(true);
                getStudents();
                setLoading(false);
            }
        });

    }

    function handleDownloadTemplate() {
        setLoading(true);
        const data = '/templates/students_bulk_upload.xls';
        if (data) {
            const url = `${appConfig.FILE_UPLOAD_URL}${data.charAt(0) === "/" ? data.substring(1) : data}`;
            window.location.href = url;
            toast.success("Download Student Template Successfully");

        }
        setLoading(false);

    }

    const handleFilterChange = (e: any) => {
        const data = { ...searchData.value };
        setSearchData(data);
    }

    const handleclose = () => {
        setCanShowPopup(false);
    }

    const handleSchoolsStudentCols = (accessObjectsList: any) => {
        const preparedData = accessObjectsList?.filter((el: any) => el.object_code === "SCHOOLS");
        if (preparedData?.length > 0) {
            for (let i = 0; i < preparedData[0].child.length; i++) {
                for (let j = 0; j < StudentsDataGridCols.length; j++) {

                    if (
                        preparedData[0].child[i].object_code === "SCHOOLS-STUDENTS" &&
                        preparedData[0].child[i].p_read === 1
                    ) {
                        setSchoolStudents(true);
                    }

                    if (
                        preparedData[0].child[i].object_code === "SCHOOLS-STUDENTS-STUDENT-LIST-TEMPLATE-DOWNLOAD" &&
                        preparedData[0].child[i].p_read === 1
                    ) {
                        setListTemplate(true);
                    }

                    if (
                        preparedData[0].child[i].object_code === "SCHOOLS-STUDENTS-STUDENT-LOGIN-CREDENTIALS-DOWNLOAD" &&
                        preparedData[0].child[i].p_read === 1
                    ) {
                        setLoginCredentials(true);
                    }

                    if (
                        preparedData[0].child[i].object_code === "SCHOOLS-STUDENTS-STUDENT-LIST-UPLOAD" &&
                        preparedData[0].child[i].p_read === 1
                    ) {
                        setListUpload(true);
                    }

                    if (
                        preparedData[0].child[i].object_code === "SCHOOLS-STUDENTS-STUDENT-LIST-UPLOAD-STATUS" &&
                        preparedData[0].child[i].p_read === 1
                    ) {
                        setListUploadStatus(true);
                    }

                    if (
                        preparedData[0].child[i].object_code === "SCHOOLS-STUDENTS-CREATE-INDIVIDUAL-STUDENT" &&
                        preparedData[0].child[i].p_read === 1
                    ) {
                        setCreateStudents(true);
                    }
                    if (preparedData[0].child[i].object_code === "SCHOOLS-STUDENTS-EDIT" && preparedData[0].child[i].p_read === 1) {
                        if (StudentsDataGridCols[j].title == "Actions") {
                            StudentsDataGridCols[j].isEdit = true;
                        }

                    }

                    if (preparedData[0].child[i].object_code === "SCHOOLS-STUDENTS-DELETE" && preparedData[0].child[i].p_read === 1) {
                        if (StudentsDataGridCols[j].title == "Actions") {
                            StudentsDataGridCols[j].isDelete = true;
                        }
                    }

                    if (preparedData[0].child[i].object_code === "SCHOOLS-STUDENTS-SET-PASSWORD" && preparedData[0].child[i].p_read === 1) {
                        if (StudentsDataGridCols[j].title == "Actions") {
                            StudentsDataGridCols[j].setPassword = true;
                        }
                    }
                    if (preparedData[0].child[i].object_code === "SCHOOLS-STUDENTS-RESET-PROGRESS" && preparedData[0].child[i].p_read === 1) {
                        if (StudentsDataGridCols[j].title == "Actions") {
                            StudentsDataGridCols[j].resetProgress = true;
                        }
                    }

                    if ((preparedData[0].child[i].object_code === "SCHOOLS-STUDENTS-SET-PASSWORD" && preparedData[0].child[i].p_read === 1) ||
                        (preparedData[0].child[i].object_code === "SCHOOLS-STUDENTS-RESET-PROGRESS" && preparedData[0].child[i].p_read === 1) ||
                        (preparedData[0].child[i].object_code === "SCHOOLS-STUDENTS-DELETE" && preparedData[0].child[i].p_read === 1) ||
                        (preparedData[0].child[i].object_code === "SCHOOLS-STUDENTS-EDIT" && preparedData[0].child[i].p_read === 1)) {
                        if (StudentsDataGridCols[j].title == "Actions") {

                            StudentsDataGridCols[j].canShowColumn = true;
                        }
                    }
                }
            }
        }

    }
    function handleDownloadFaildList() {
        setLoading(true);
        DOWNLOAD_STUDENT_FAILED_EXIST(schoolsCode).then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res.message);
                setLoading(false);
            } else {
                setLoading(false);
                const data = res;
                toast.success("Download Student List Successfully");
                if (data.url) {
                    const url = `${appConfig.FILE_UPLOAD_URL}${data.url.charAt(0) === "/" ? data.url.substring(1) : data.url}`;
                    window.location.href = url;
                }
            }
        });
    }

    return (
        <div>
            <div className={`modal fade ${canShowPopup ? 'show' : 'false'}`} style={{ display: (canShowPopup ? "block" : 'none') }} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog student-upload-dialog" role="document">
                    <div className="modal-content student-upload-model">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Student Upload Records</h5>
                            <button type="button" className="close popup-close" data-dismiss="modal" aria-label="Close" onClick={handleclose}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            {/* <ReactJson src={uploadStatusData} theme="hopscotch"/> */}
                            <div>
                                <div className='row'>
                                    <div className='col-md-10'>
                                        <h4>Success List</h4>
                                    </div>
                                    <div className='col-md-2'>
                                        Count: {successList.length}
                                    </div>
                                </div>
                                <table className="table table-striped table-bordered">
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Mobile Number</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {successList.map((item: any, i: number) => (
                                            <tr key={i}>
                                                <td>{item?.name}</td>
                                                <td>{item?.mobile}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                {successList.length === 0 && <div className='text-center'>No records found.</div>}
                            </div>
                            <div className="mt-4">
                                <div className='row'>
                                    <div className='d-flex justify-content-between col-md-10'>
                                        <h4>Failed List</h4>
                                        {(failedListPath && failedList?.length > 0) && <p><a className="btn btn-primary sc-profile-viewbtn mb-0" onClick={handleDownloadFaildList}>Download </a></p>}
                                    </div>
                                    <div className='col-md-2'>
                                        Count: {failedList.length}
                                    </div>
                                </div>
                                <table className="table table-striped table-bordered">
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Mobile Number</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {failedList.map((item: any, i: number) => (
                                            <tr key={i}>
                                                <td>{item?.name}</td>
                                                <td>{item?.mobile}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                {failedList.length === 0 && <div className='text-center'>No records found.</div>}
                            </div>
                            <div className="mt-4">
                                <div className='row'>
                                    <div className='d-flex justify-content-between col-md-10'>
                                        <h4>Already Exist List</h4>
                                        {(failedListPath && alredyExistList?.length > 0) && <p><a className="btn btn-primary sc-profile-viewbtn mb-0" onClick={handleDownloadFaildList}>Download </a></p>}
                                    </div>
                                    <div className='col-md-2'>
                                        Count: {alredyExistList.length}
                                    </div>
                                </div>
                                <table className="table table-striped table-bordered">
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Mobile Number</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {alredyExistList.map((item: any, i: number) => (
                                            <tr key={i}>
                                                <td>{item?.name}</td>
                                                <td>{item?.mobile}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                {alredyExistList.length === 0 && <div className='text-center'>No records found.</div>}
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary cursor-pointer" data-dismiss="modal" onClick={handleclose}>Close</button>
                        </div>
                    </div>
                </div>
            </div>
            {schoolStudents &&
                <>
                    <div>
                        {(userRole !== 'TEACHER' || userRole !== 'PARENT' || userRole !== 'STUDENT') && <div className="border-primary py-3 text-end">
                            {listTemplate && <button className="btn btn-sm btn-primary px-4 rounded-12 cursor-pointer" style={{ marginRight: '10px', color: 'white' }} onClick={() => handleDownloadTemplate()}>Student List Template Download
                            </button>}
                            {loginCredentials && <div className="file btn btn-sm btn-primary px-4 rounded-12 d-inline-block cursor-pointer" onClick={() => handleDownloadStudents()} style={{ marginRight: '10px' }}>Student Login credentials download
                            </div>}
                            {listUpload && <div className="file btn btn-sm btn-primary px-4 rounded-12 d-inline-block" style={{ marginRight: '10px', cursor: 'pointer' }}>Student list upload
                                <input type="file" style={{ cursor: 'pointer' }} onChange={(e) => handleUploadBulkUpload(e)} onClick={(e: any) => {
                                    e.target.value = null
                                }} />
                            </div>}
                            {listUploadStatus && <button className="btn btn-sm btn-warning px-4 rounded-12 cursor-pointer" style={{ marginRight: '10px', color: 'white' }} onClick={() => handleUploadBulkUploadStatus()}>Students list upload status
                            </button>}
                            {createStudents && <>
                                {userRole !== "CHAIRMAN" && userRole !== "GENERAL_SECRETARY" && userRole !== "INSTITUTE_ADMIN" && <Link to={`/dashboard/school/info/${schoolId}/studentsform/${schoolsCode}/0`} className="btn btn-primary btn-sm px-4 rounded-12 cursor-pointer">Create Student</Link>}

                                {(userRole === "CHAIRMAN" || userRole === "GENERAL_SECRETARY" || userRole === "INSTITUTE_ADMIN") && <Link to={`/institute-dashboard/school/info/${schoolId}/studentsform/${schoolsCode}/0`} className="btn btn-primary btn-sm px-4 rounded-12 cursor-pointer">Create Student</Link>}
                            </>}
                        </div>}
                    </div>
                    <div className="row" style={{ marginBottom: '15px', marginTop: '25px' }}>
                        <div className="col-md-10">
                            <FormBuilder onUpdate={handleInput}>
                                <form ref={usersForm}>
                                    <div className="row">
                                        <div className="col-md-3">
                                            <input type="text" className="form-control ft-14" placeholder="Search name" name="name" />
                                        </div>
                                        <div className="col-md-3">
                                            <input type="text" className="form-control ft-14" placeholder="Search email" name="email_id" />
                                        </div>
                                        <div className="col-md-3">
                                            <select className="form-select ft-14" name="standard">
                                                <option value="">Select standard</option>
                                                <option value="6">6</option>
                                                <option value="7">7</option>
                                                <option value="8">8</option>
                                                <option value="9">9</option>
                                                <option value="10">10</option>
                                                <option value="11">11</option>
                                                <option value="12">12</option>
                                            </select>
                                        </div>
                                        <div className="col-md-3">
                                            <select className="form-select  ft-14" name="division">
                                                <option value="">Select division</option>
                                                <option value="A">A</option>
                                                <option value="B">B</option>
                                                <option value="C">C</option>
                                                <option value="D">D</option>
                                                <option value="E">E</option>
                                            </select>
                                        </div>
                                        <div className="col-md-3 mt-2">
                                            <input type="text" className="form-control ft-14" placeholder="Custom search" name="search_name" />
                                        </div>
                                        <div className="col-md-3 mt-2">
                                            <select name="searchFilter" className="form-select ft-14" onChange={(e) => { handleFilterChange(e) }}>
                                                <option value="">Select filter</option>
                                                {filteredData.map((name: any, i: number) => (
                                                    <option key={i} value={name.value}>
                                                        {name.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </form>
                            </FormBuilder>
                        </div>
                        <div className="col-md-2 mt-5">
                            <button className="btn btn-primary px-3 cursor-pointer" type="button" onClick={() => handleSearch()}>Search</button>&nbsp;
                            <button className="btn clear-btn cursor-pointer" type="button" onClick={() => handleRefresh()}>
                                Clear
                            </button>
                        </div>
                    </div>

                    {loading &&
                        <div className="text-center p-5">
                            <div className="spinner-border" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                    }
                    {!loading && <DataTable TableCols={StudentsDataGridCols}
                        editInfo={onEditStudent} deleteInfo={onDeleteStudent}
                        tableData={studentList} pageNumber={onPageChange}
                        activePageNumber={activePage} pageNumbers={pageArray}></DataTable>}
                </>
            }
        </div>
    )
}