import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { InstituteFormPage } from "./form";
import MultiStepperNavbar from '../../../components/multi-stepper';
import { AdminUser } from './admin-user';
import { ChairmanUser } from './chairman-user';
import { GeneralSecretary } from './general-secretary-user';



interface Props {
    instituteId: number;
}

const AddInstitutePage = () => {
    let { id } = useParams<{ id: string }>();
    const editInstituteId = parseInt(id) || 0;

    const [activeTab, setActiveTab] = useState(0);
    const [instituteId, setInstituteId] = useState(0);
    const history = useHistory();
    const [formSteps, setFormSteps] = useState<any>([]);

    useEffect(() => {
        const formStepsDefault = [
            { name: 'Details' },
            { name: 'Chairman', disabled: editInstituteId === 0 },
            { name: 'General Secretary', disabled: editInstituteId === 0 },
            { name: 'Admin', disabled: editInstituteId === 0 }
        ];
        setFormSteps(formStepsDefault);
    }, []);

    const getInstituteId = (data: any) => {
        if (instituteId === 0) {
            history.push(`/dashboard/institute/new/${data}`);
        }
        setInstituteId(data);
        setActiveTab(1);
        const formStepsDefault = [
            { name: 'Details', disabled: true },
            { name: 'Chairman' },
            { name: 'General Secretary', disabled: true },
            { name: 'Admin', disabled: true }
        ];
        setFormSteps(formStepsDefault);
    }

    const getChairmanPage = (data: any) => {
        setActiveTab(2);
        const formStepsDefault = [
            { name: 'Details', disabled: true },
            { name: 'Chairman', disabled: true },
            { name: 'General Secretary' },
            { name: 'Admin', disabled: true }
        ];
        setFormSteps(formStepsDefault);
    }

    const getAdminPage = (data: any) => {
        setActiveTab(3);
        const formStepsDefault = [
            { name: 'Details', disabled: true },
            { name: 'Chairman', disabled: true },
            { name: 'General Secretary', disabled: true },
            { name: 'Admin' }
        ];
        setFormSteps(formStepsDefault);
    }

    const getListPage = (data: any) => {
        history.push(`/dashboard/institute/list`);
    }

    const getCurrentStep = () => {
        let element;
        switch (activeTab) {
            case 0:
                element = <InstituteFormPage instituteId={getInstituteId} />
                break;
            case 1:
                element = <ChairmanUser instituteIdNumber={instituteId} chairmanName={getChairmanPage} />
                break;
            case 2:
                element = <GeneralSecretary instituteIdNumber={instituteId} generalSecretaryName={getAdminPage} />
                break;
            case 3:
                element = <AdminUser instituteIdNumber={instituteId} adminName={getListPage} />
                break;
        }
        return element;
    }
    return (
        <div className="row border-top border-primary py-3">
            {!editInstituteId && <h2 className='text-center'>Add New Institute</h2>}
            <MultiStepperNavbar steps={formSteps} updateTab={setActiveTab} selectedTab={activeTab}></MultiStepperNavbar>
            {getCurrentStep()}
        </div>
    );
};

export default AddInstitutePage;