import React, { useEffect, useState } from 'react'
import { Link, NavLink, useHistory, useLocation, useParams } from 'react-router-dom'
import { GET_INSTITUTE_BY_ID } from '../../../app/service/institute.service';
import appConfig from '../../../config/constant';
import NestedInstitute from '../../../routes/insideinstitute';
import "react-datepicker/dist/react-datepicker.css";
import { DateComponent } from '../../../components/date';
import moment from 'moment';
import { toast } from 'react-toastify';
import { Breadcum } from '../../../components/breadcum';
import ProfileNestedInstitute from '../../../routes/profileinsideinstitute';

export const InstituteInFo = () => {
    const [InstituteInfo, setInstituteInfo] = useState<any>({});
    const [instituteCode, setInstituteCode] = useState<any>();
    const [loading, setLoading] = useState(false);
    const [canShowPopup, setCanShowPopup] = useState(false);
    const [instituteLogo, setInstituteLogo] = useState<any>();
    const [userRole, setUserRole] = useState<any>("");
    const [todayDate, setTodayDate] = useState(moment().format('YYYY-MM-DD'));
    let { id } = useParams<{ id: string }>();
    const InstituteId = Number(id);
    const location = useLocation();
    const [chairMan, setChairMan] = useState(false);
    const [generalSecratary, setGeneralSecratary] = useState(false);
    const [admin, setAdmin] = useState(false);
    const [viewMoreInfo, setViewMoreInfo] = useState(false);
    const { pathname } = location;
    const splitLocation = pathname.split("/");
    const [breadcumInfo, setBreadcumInfo] = useState<any>([
        {
            label: 'Institutes',
            value: 'Institutes',
            routerLink: '/dashboard/institute/list',
            isActive: false
        },
        {
            label: 'Institute Name',
            value: '',
            routerLink: '',
            isActive: false
        }
    ]);
    useEffect(() => {
        const userRole = localStorage.getItem("user_role");
        setUserRole(userRole);
        setLoading(true);
        if (!isNaN(InstituteId)) {
            GET_INSTITUTE_BY_ID(InstituteId).then((res: any) => {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    if (res) {
                        const data = res.rows[0];
                        setInstituteInfo(data);
                        getBreadcumList(data);
                        setInstituteCode(data.organization_code);
                        setInstituteLogo(data.logo_url);
                        setLoading(false);
                        if (data.date_of_registration) {
                            const date_of_registration: any = new Date(data.date_of_registration);
                            setTodayDate(date_of_registration);
                        }
                    }
                }
            });
        }
        const accessObjectsData: any = localStorage.getItem("access_objects_data");
        onHandleNav(JSON.parse(accessObjectsData));
    }, [InstituteId]);

    function getBreadcumList(data: any) {
        breadcumInfo.splice(1, breadcumInfo.length - 1, {
            label: 'Institute Name',
            value: data.name_of_organization,
            isActive: true
        });
        setBreadcumInfo(breadcumInfo);
    }

    function onClickViewDetail() {
        setCanShowPopup(true);
    }

    const handleclose = () => {
        setCanShowPopup(false);
    }

    function onHandleNav(accessObjectsList: any) {
        const preparedData = accessObjectsList?.filter((el: any) => el.object_code === "INSTITUTES");
        if (preparedData?.length > 0) {
            for (let i = 0; i < preparedData[0].child.length; i++) {
                if (preparedData[0].child[i].object_code === "INSTITUTES-CHAIRMAN" && preparedData[0].child[i].p_read === 1) {
                    setChairMan(true);
                }
                if (preparedData[0].child[i].object_code === "INSTITUTES-GENERAL-SECRETARY" && preparedData[0].child[i].p_read === 1) {
                    setGeneralSecratary(true);
                }
                if (preparedData[0].child[i].object_code === "INSTITUTES-INSTITUTE-ADMIN" && preparedData[0].child[i].p_read === 1) {
                    setAdmin(true);
                }
                if (preparedData[0].child[i].object_code === "INSTITUTES-VIEW-MORE-INFORMATION" && preparedData[0].child[i].p_read === 1) {
                    setViewMoreInfo(true);
                }
            }
        }
    }

    return (
        <div className="row border-top border-primary py-3">
            <div className={`modal fade ${canShowPopup ? 'show' : 'false'}`} style={{ display: (canShowPopup ? "block" : 'none') }} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog parent-model-dialog" role="document">
                    <div className="modal-content parent-model">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Institute  Details</h5>
                            <button type="button" className="close popup-close cursor-pointer" data-dismiss="modal" aria-label="Close" onClick={handleclose}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body parent-info">
                            <div className="row custom-form">
                                <div className="col-md-6">
                                    <div className="mb-4">
                                        <label className="form-label mb-0">Name of Education Society /Trust /Organaisation</label>
                                        <input className="form-control form-control-lg" type="text" name="name_of_organization" disabled defaultValue={InstituteInfo?.name_of_organization} />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-4">
                                        <label className="form-label mb-0">Short form of Organization</label>
                                        <input className="form-control form-control-lg" type="text" name="short_name" disabled defaultValue={InstituteInfo?.short_name} />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-4">
                                        <label className="form-label mb-0">Display Name</label>
                                        <input className="form-control form-control-lg" type="text" name="display_name" disabled defaultValue={InstituteInfo?.display_name} />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-4">
                                        <label className="form-label mb-0">Email ID</label>
                                        <input className="form-control form-control-lg" type="text" name="email_id" disabled defaultValue={InstituteInfo?.email_id} />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-4">
                                        <label className="form-label mb-0">Contact Number ( Mobile / Landline)</label>
                                        <input className="form-control form-control-lg" type="number" name="contact_number" disabled defaultValue={InstituteInfo?.contact_number} />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-4">
                                        <label className="form-label mb-0">Registration Number</label>
                                        <input className="form-control form-control-lg" type="text" name="registration_number" disabled defaultValue={InstituteInfo?.registration_number} />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-4">
                                        <label className="form-label mb-0">Fax Number</label>
                                        <input className="form-control form-control-lg" type="text" name="fax_number" disabled defaultValue={InstituteInfo?.fax_number} />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-4">
                                        <label className="form-label mb-0">Date of Registration</label>
                                        {InstituteInfo.date_of_registration &&
                                            <DateComponent yearDiff={0} idDisable={true} defaultData={InstituteInfo.date_of_registration}></DateComponent>}
                                        {!InstituteInfo.date_of_registration && <DateComponent idDisable={true} defaultData={todayDate} yearDiff={0}></DateComponent>}
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-4">
                                        <label className="form-label mb-0">Office Timings </label>
                                        <input className="form-control form-control-lg" type="text" name="timings" disabled defaultValue={InstituteInfo?.timings} />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-4">
                                        <label className="form-label mb-0">Head Quarter Address</label>
                                        <input className="form-control form-control-lg" type="text" name="address" disabled defaultValue={InstituteInfo?.address} />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-4">
                                        <label className="form-label mb-0">State</label>
                                        <select name="state" className="form-select form-select-lg mb-3" disabled>
                                            <option value="">{InstituteInfo?.state}</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-4">
                                        <label className="form-label mb-0">District</label>
                                        <select name="district" className="form-select form-select-lg mb-3" disabled>
                                            <option value="">{InstituteInfo?.district}</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-4">
                                        <label className="form-label mb-0">Block</label>
                                        <input className="form-control form-control-lg" type="text" name="taluka" disabled defaultValue={InstituteInfo?.taluka} />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-4">
                                        <label className="form-label mb-0">Area/Locality</label>
                                        <input className="form-control form-control-lg" type="text" name="area" disabled defaultValue={InstituteInfo?.area} />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-4">
                                        <label className="form-label mb-0">City/Village (Optional)</label>
                                        <input className="form-control form-control-lg" type="text" name="city_village" disabled defaultValue={InstituteInfo?.city_village} />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-4">
                                        <label className="form-label mb-0">PIN Code</label>
                                        <input className="form-control form-control-lg" type="number" name="pin_code" disabled defaultValue={InstituteInfo?.pin_code} />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-4">
                                        <label className="form-label mb-0">GST Number</label>
                                        <input className="form-control form-control-lg" type="text" name="gst_number" disabled defaultValue={InstituteInfo?.gst_number} />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-4">
                                        <label className="form-label mb-0">PAN Number</label>
                                        <input className="form-control form-control-lg" type="text" name="pan_number" disabled defaultValue={InstituteInfo?.pan_number} />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-4">
                                        <label className="form-label mb-0">website address</label>
                                        <input className="form-control form-control-lg" type="text" name="website_url" disabled defaultValue={InstituteInfo?.website_url} />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-4">
                                        <label className="form-label mb-0">Number of Primary School (1 to 5) </label>
                                        <input className="form-control form-control-lg" type="number" name="num_of_primary_school" disabled defaultValue={InstituteInfo?.num_of_primary_school} />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-4">
                                        <label className="form-label mb-0">Number of Secondary School(6 to 10)</label>
                                        <input className="form-control form-control-lg" type="number" name="num_of_secondary_school" disabled defaultValue={InstituteInfo?.num_of_secondary_school} />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-4">
                                        <label className="form-label mb-0">Number of Junior College(11, 12)</label>
                                        <input className="form-control form-control-lg" type="number" name="num_of_junior_college" disabled defaultValue={InstituteInfo?.num_of_junior_college} />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-4">
                                        <label className="form-label mb-0">Number of Senior College </label>
                                        <input className="form-control form-control-lg" type="number" name="num_of_senior_college" disabled defaultValue={InstituteInfo?.num_of_senior_college} />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-4">
                                        <label className="form-label mb-0">Number of Professional Colleges </label>
                                        <input className="form-control form-control-lg" type="number" name="num_of_professional_colleges" disabled defaultValue={InstituteInfo?.num_of_professional_colleges} />
                                    </div>
                                </div>
                                {instituteLogo && <div className="col-md-4">
                                    <div className="mb-4">
                                        <label className="form-label mb-0 d-block">Preview Logo</label>
                                        <img src={`${appConfig.FILE_UPLOAD_URL}${instituteLogo.charAt(0) === "/" ? instituteLogo.substring(1) : instituteLogo}`} style={{ height: '100px', width: '100px', objectFit: 'contain' }} />
                                    </div>
                                </div>}
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary cursor-pointer" data-dismiss="modal" onClick={handleclose}>Close</button>
                        </div>
                    </div>
                </div>
            </div>
            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }

            {!loading && <div>
                <div>
                    {(userRole === "SUPER_ADMIN") && <Breadcum breadcumList={breadcumInfo} />}
                    {(userRole !== "SUPER_ADMIN") && <h2>Institute Details</h2>}
                    <div className="mar-t-20 custom-scroll table-responsive">
                        <table className="table fixed-table-header mb-0">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Contact Number</th>
                                    <th>State</th>
                                    <th>City</th>
                                    <th>Area/Locality</th>
                                    <th>Website Address</th>
                                    {viewMoreInfo && <th>Action</th>}
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{InstituteInfo?.name_of_organization}</td>
                                    <td>{InstituteInfo?.email_id}</td>
                                    <td>{InstituteInfo?.contact_number}</td>
                                    <td>{InstituteInfo?.state}</td>
                                    <td>{InstituteInfo?.city_village}</td>
                                    <td>{InstituteInfo?.area}</td>
                                    <td>{InstituteInfo?.website_url}</td>
                                    {viewMoreInfo && <td>
                                        <button className="file btn btn-sm btn-primary px-4 rounded-12 d-inline-block cursor-pointer" onClick={() => onClickViewDetail()}>View More Info</button>
                                    </td>}
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="mt-5">
                    {userRole !== "CHAIRMAN" && userRole !== "GENERAL_SECRETARY" && userRole !== "INSTITUTE_ADMIN" &&

                        <ul className="nav nav-tabs custom-tabs scrolling_nav">
                            <li className="nav-item">
                                {chairMan && <NavLink className={splitLocation[5] === "chairmanform" ? "nav-link active" : "nav-link"} to={{ pathname: `/dashboard/institute/info/${InstituteId}/chairmanlist/${instituteCode}`, state: { data: "" } }}>Chairman</NavLink>}
                            </li>
                            <li className="nav-item">
                                {generalSecratary && <NavLink className={splitLocation[5] == "generalsecretaryform" ? "nav-link active" : "nav-link"} to={{ pathname: `/dashboard/institute/info/${InstituteId}/generalsecretarylist/${instituteCode}`, state: { data: "" } }}>General Secretary</NavLink>}
                            </li>
                            <li className="nav-item">
                                {admin && <NavLink className={splitLocation[5] == "adminform" ? "nav-link active" : "nav-link"} to={{ pathname: `/dashboard/institute/info/${InstituteId}/adminlist/${instituteCode}`, state: { data: "" } }}>Institute Admin</NavLink>}
                            </li>
                        </ul>}
                    {(userRole === "CHAIRMAN" || userRole === "GENERAL_SECRETARY" || userRole === "INSTITUTE_ADMIN") &&

                        <ul className="nav nav-tabs custom-tabs scrolling_nav">
                            <li className="nav-item">
                                {chairMan && <NavLink className={splitLocation[5] === "chairmanform" ? "nav-link active" : "nav-link"} to={{ pathname: `/institute-dashboard/institute/info/${InstituteId}/chairmanlist/${instituteCode}`, state: { data: "" } }}>Chairman</NavLink>}
                            </li>
                            <li className="nav-item">
                                {generalSecratary && <NavLink className={splitLocation[5] == "generalsecretaryform" ? "nav-link active" : "nav-link"} to={{ pathname: `/institute-dashboard/institute/info/${InstituteId}/generalsecretarylist/${instituteCode}`, state: { data: "" } }}>General Secretary</NavLink>}
                            </li>
                            <li className="nav-item">
                                {admin && <NavLink className={splitLocation[5] == "adminform" ? "nav-link active" : "nav-link"} to={{ pathname: `/institute-dashboard/institute/info/${InstituteId}/adminlist/${instituteCode}`, state: { data: "" } }}>Institute Admin</NavLink>}
                            </li>
                        </ul>}
                    <div>
                        {(userRole === "CHAIRMAN" || userRole === "GENERAL_SECRETARY" || userRole === "INSTITUTE_ADMIN") &&
                            <ProfileNestedInstitute />
                        }
                        < NestedInstitute />
                    </div>
                </div>
            </div>}
        </div>
    )
}

