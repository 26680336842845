import React, { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Breadcum } from "../../../../components/breadcum";
import { DataTable } from "../../../../components/data-table";
import { SponsorReportViewGridCols } from "./data-grid-cols";
import { toast } from "react-toastify";
import { GET_SPONSOR_SCHOOL_INSTITUTE_ID, GET_STUDENTS_BY_SPONSOR_REPORT_LIST, GET_STUDNETS_DATA_BY_SCHOOL_CODE } from "../../../../app/service/sponsor";
import { GET_STUDENTS_BY_STANDARD_DIVISION } from "../../../../app/service/users.service";
import { GET_INSTITUTE } from "../../../../app/service/institute.service";


export const SponsoredStudentsReports = () => {
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const [activePage, setActivePage] = useState<any>();
    const [studentReportList, setStudentReportList] = useState<any>([]);
    const [pageArray, setPageNumbers] = useState([]);
    const search = useLocation().search;
    const currentPageNumber: any = new URLSearchParams(search).get('page');
    const [couponPopup, setCouponPopup] = useState(false);
    const [instituteData, setInstituteData] = useState<any>([]);
    const [schoolsData, setSchoolsData] = useState<any>([]);
    const [studentList, setStudentList] = useState<any>([]);
    const [standardList, setStandardList] = useState<any>([]);
    const [divisionList, setDivisionList] = useState<any>([]);
    const [studentsSelected, setStudentsSelected] = useState<any>([]);
    const [selectedStandard, setSelectedStandard] = useState<string>('');
    const [schoolsCodeData, setSchoolsCodeData] = useState<any>();
    const [studentsData, setStudentsData] = useState<any>([]);
    const [searchData, setSearchData] = useState<any>({});
    let orgCode: any = new URLSearchParams(search).get('organization_code');
    let schoolCode: any = new URLSearchParams(search).get('institute_school_code');
    let stanDard: any = new URLSearchParams(search).get('standard');
    let diviSion: any = new URLSearchParams(search).get('division');
    let paymentFromDate: any = new URLSearchParams(search).get('payment_dt_from');
    let paymentToDate: any = new URLSearchParams(search).get('payment_dt_to');
    const [scholLoading, setSchoolLoading] = useState(false);
    const [standardLoading, setStandardLoading] = useState(false);
    const [divisionLoading, setDivisionLoading] = useState(false);
    const [querParamsData, setQuerParamsData] = useState<Object>({
        organization_code: orgCode,
        institute_school_code: schoolCode,
        standard: stanDard,
        division: diviSion,
        payment_dt_from: paymentFromDate,
        payment_dt_to: paymentToDate
    });
    const [breadcumInfo, setBreadcumInfo] = useState<any>([
        {
            label: "Sponsorship Usage Report",
            value: "Sponsorship Usage Report",
            routerLink: "/dashboard/sponsor-report/report",
            isActive: false,
        },
        {
            label: "Students Reports",
            value: "Students Reports",
            routerLink: "",
            isActive: true,
        },
    ]);
    useEffect(() => {
        getInstitutesData();
        getStudentsList();
    }, []);
    function getInstitutesData() {
        // setLoading(true);
        GET_INSTITUTE().then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res.message);
                // setLoading(false);
            } else {
                const data = res.rows;
                setInstituteData(data);
                // setLoading(false);
            }
        });
    }
    function getStudentsList() {
        const activePageNumber = currentPageNumber > 0 ? (currentPageNumber - 1) : 0;
        setLoading(true);
        GET_STUDENTS_BY_SPONSOR_REPORT_LIST(activePageNumber, querParamsData).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    const data = res.rows;
                    data.filter((element: any) => (
                        element.name_of_organizations = element.name_of_organization,
                        element.name_of_schools = element.name_of_school
                    ));
                    const pageNumbers = Math.ceil(res.count / 10);
                    const pageArray: any = [];
                    for (let i = 1; i <= pageNumbers; i++) {
                        pageArray.push(i);
                    }
                    setPageNumbers(pageArray);
                    setStudentReportList(data);
                    // setTimeout(() => {
                    setLoading(false);
                    // }, 2000);
                }
            }
        });
    }

    const handleChange = (e: any) => {
        setSchoolsData([]);
        setStandardList([]);
        setDivisionList([]);
        setStudentList([]);
        setStudentsSelected([]);

        if (e.target.value) {
            searchData.organization_code = e.target.value;
            searchData.institute_school_code = "";
            searchData.standard = "";
            searchData.division = "";
            getSchoolsByInstituteCode(e.target.value);
        } else {
            setSearchData({});
        }
    }
    function getSchoolsByInstituteCode(data: any) {
        setSchoolLoading(true);
        GET_SPONSOR_SCHOOL_INSTITUTE_ID(data, 0, {}).then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res.message);
                setSchoolLoading(false);
            } else {
                const data = res.rows;
                let preparedData: any = [];
                let originalSchoolsData: any = []
                setSchoolsData(data);
                setSchoolLoading(false);
            }
        });
    }
    function handleSchoolChange(e: any) {
        setStandardList([]);
        setDivisionList([]);
        setStudentList([]);
        setStudentsSelected([]);
        setSchoolsCodeData('');

        if (e.target.value) {
            getStudentsBySchoolCode(e.target.value);
            setSchoolsCodeData(e.target.value);
            searchData.institute_school_code = e.target.value;
            searchData.standard = "";
            searchData.division = "";
        } else {
            searchData.institute_school_code = "";
            searchData.standard = "";
            searchData.division = "";
        }
    }
    function getStudentsBySchoolCode(data: any) {
        setStandardLoading(true);
        GET_STUDNETS_DATA_BY_SCHOOL_CODE(data, 'STUDENT', 0, {}).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setStandardLoading(false);
                } else {
                    const data = res.rows;
                    data.forEach((element: any) => {
                        element.school_student = element.name;
                    });

                    setStudentsData(data);
                    let preparedStandard: any[] = [];
                    data.filter(function (item: any) {
                        let i = preparedStandard.findIndex((x: any) => (x.standard == item.standard));
                        if (i <= -1) {
                            preparedStandard.push({ standard: item.standard, value: item.standard });
                        }
                        return null;
                    });
                    preparedStandard.sort((a, b) => a.standard - b.standard)
                    setStandardList(preparedStandard);
                    setStandardLoading(false);
                }
            }
        });
    }
    function handleStandardChange(e: any) {
        setDivisionList([]);
        setStudentList([]);
        setStudentsSelected([]);
        if (e.target.value) {
            let preparedData = {
                standard: e.target.value,
            }
            getStudentsByStandardDivision(preparedData);
            setSelectedStandard(e.target.value);
            searchData.standard = e.target.value;
            searchData.division = "";
            setDivisionLoading(true);
            setTimeout(() => {
                getDivisionData(e);
            }, 1000);
        } else {
            searchData.standard = "";
            searchData.division = "";
        }
    }
    function getDivisionData(e: any) {
        let preparedDivison: any[] = [];
        let element = studentsData.filter((ele: any) => {
            if (ele.standard == e.target.value) {

                return ele.division;
            }
        });
        element.filter(function (item: any) {
            let i = preparedDivison.findIndex((x: any) => (x.division == item.division));
            if (i <= -1) {
                preparedDivison.push({ division: item.division, value: item.division });
            }
            return null;
        });
        preparedDivison.sort((a, b) => a.division.localeCompare(b.division));
        setDivisionList(JSON.parse(JSON.stringify(preparedDivison)));
        setDivisionLoading(false);
    }

    function getStudentsByStandardDivision(data: any) {
        for (const key of Object.keys(data)) {
            if (data[key] === "") {
                delete data[key];
            }
        }
        GET_STUDENTS_BY_STANDARD_DIVISION(schoolsCodeData, data).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    const data = res.rows;
                    const preparedData = [];

                    if (data.length > 0) {
                        for (let i = 0; i < data.length; i++) {

                            for (let x = 0; x < studentsSelected.length; x++) {
                                if (data[i].user_code === studentsSelected[x].user_code) {
                                    data[i].completedStatus = true;
                                }
                            }
                        }
                        for (let k = 0; k < data.length; k++) {
                            if (data[k].completedStatus) {
                                preparedData.push({
                                    label: data[k].name,
                                    value: data[k].user_code,
                                    disabled: true
                                });
                            } else {
                                preparedData.push({
                                    label: data[k].name,
                                    value: data[k].user_code
                                });
                            }
                        }
                        setStudentList(preparedData);

                    } else {
                        setStudentList([]);
                    }
                }
            }
        });
    }

    function handleDivisionChange(e: any) {
        setStudentList([]);
        setStudentsSelected([]);
        if (e.target.value) {
            let preparedData = {
                standard: selectedStandard,
                division: e.target.value,
            }
            getStudentsByStandardDivision(preparedData);
            searchData.division = e.target.value;
        } else {
            let preparedData = {
                standard: selectedStandard,
                division: "",
            }
            getStudentsByStandardDivision(preparedData);
        }

    }
    function onChangeMultiSelectStudents(e: any) {
        setStudentsSelected(e)
    }
    function filterSearch() {
        if (Object.keys(searchData).length > 0) {
            setLoading(true);
            GET_STUDENTS_BY_SPONSOR_REPORT_LIST(0, searchData).then((res: any) => {
                if (res) {
                    if (res.status === 'fail') {
                        toast.error(res.message);
                        setLoading(false);
                    } else {
                        const data = res.rows;
                        data.filter((element: any) => (
                            element.name_of_organizations = element.name_of_organization,
                            element.name_of_schools = element.name_of_school
                        ));
                        const pageNumbers = Math.ceil(res.count / 10);
                        const pageArray: any = [];
                        for (let i = 1; i <= pageNumbers; i++) {
                            pageArray.push(i);
                        }
                        setPageNumbers(pageArray);
                        setStudentReportList(data);
                        setLoading(false);
                    }
                }
            });
        }
    }

    const onPageChange = (data: any) => {
        let payLoad: any = [];
        if (Object.keys(searchData).length > 0) {
            payLoad.push(searchData);
        } else {
            payLoad.push(querParamsData);
        }
        if (data) {
            history.push(`/dashboard/sponsor-report/student-list?page=${data}`);
        }
        setActivePage(data);
        const pageNumber = data - 1;
        setLoading(true);
        GET_STUDENTS_BY_SPONSOR_REPORT_LIST(pageNumber, payLoad[0]).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    const data = res.rows;
                    data.filter((element: any) => (
                        element.name_of_organizations = element.name_of_organization,
                        element.name_of_schools = element.name_of_school
                    ));
                    const pageNumbers = Math.ceil(res.count / 10);
                    const pageArray: any = [];
                    for (let i = 1; i <= pageNumbers; i++) {
                        pageArray.push(i);
                    }
                    setPageNumbers(pageArray);
                    setStudentReportList(data);
                    setLoading(false);

                }
            }
        });

    }
    function handleRefresh() {
        getInstitutesData();
        getStudentsList();
        setSearchData({});
        setInstituteData([]);
        setSchoolsData([]);
        setStandardList([]);
        setDivisionList([]);
        setStudentList([]);
        setStudentsSelected([]);
        getInstitutesData();

    }
    return (
        <div>
            <div>
                <div className="mt-3">
                    <Breadcum breadcumList={breadcumInfo} />
                </div>
                <h4 className="border-bottom pb-3 mb-3">Sponsored Students List</h4>
                <div className={`row custom-form ms-md-1 mb-3 `}>
                    <div className="col-md-4 col-lg-3">
                        <div className="mb-4">
                            <label className="form-label mb-0 mt-2">Name of Institute</label>
                            <span style={{ color: 'red', fontSize: '20px', paddingLeft: '10px' }}></span>
                            <select name="organization_code" className="form-select form-select-lg mb-3 f14" onChange={(e) => { handleChange(e) }}>
                                <option value={""}>Select the institute</option>
                                {instituteData.map((name: any, i: number) => (
                                    <option key={i} value={name.organization_code}>
                                        {name.name_of_organization}
                                    </option>
                                ))}
                            </select>

                        </div>
                    </div>
                    <div className="col-md-4 col-lg-3">

                        <div className="mb-4">
                            {scholLoading &&
                                <div className="ms-5 ps-5 mt-4 pt-3 position-absolute  align-items-end">
                                    <div className="spinner-border ms-5 ms-md-0" role="status" style={{ height: "23px", width: "23px" }} >
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div>
                            }
                            <label className="form-label mb-0 mt-2">Name of School </label>
                            <select name="institute_school_code" className="form-select form-select-lg mb-3 f16" onChange={(e) => { handleSchoolChange(e) }}>
                                <option value="">Select the school</option>
                                {schoolsData?.map((name: any, i: number) => (
                                    <option key={i} value={name.school_code}>
                                        {name.name_of_school}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="col-md-3 col-lg-2">

                        <div className="mb-4">
                            {standardLoading &&
                                <div className="ms-5 ps-4 mt-4 pt-3 position-absolute  align-items-end">
                                    <div className="spinner-border ms-5 ms-md-0" role="status" style={{ height: "23px", width: "23px" }} >
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div>
                            }
                            <label className="form-label mb-0 mt-2">Standard</label>
                            <select className="form-select form-select-lg mb-3 f16" name="standard" onChange={(e) => { handleStandardChange(e) }}>
                                <option value="">Select standard</option>
                                {standardList?.map((data: any, i: number) => {
                                    return (
                                        <option value={data.value} key={i}>{data.standard}</option>
                                    )
                                })}
                            </select>
                        </div>
                    </div>
                    <div className="col-md-3 col-lg-2">

                        <div className="mb-4">
                            {divisionLoading &&
                                <div className="ms-5 ps-4 mt-4 pt-3 position-absolute  align-items-end">
                                    <div className="spinner-border ms-5 ms-md-0" role="status" style={{ height: "23px", width: "23px" }} >
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div>
                            }
                            <label className="form-label mb-0 mt-2">Division</label>
                            <select className="form-select form-select-lg mb-3 f16" name="division" onChange={(e) => { handleDivisionChange(e) }}>
                                <option value="">Select division</option>
                                {divisionList?.map((data: any, i: number) => {
                                    return (
                                        <option value={data.value} key={i}>{data.division}</option>
                                    )
                                })}
                            </select>
                        </div>
                    </div>

                    <div className="col-md-4  col-lg-2">
                        <button className="btn btn-primary mt-4 " onClick={filterSearch}>Search</button>
                        <button className="btn clear-btn cursor-pointer mt-4 ms-3" type="button" onClick={() => handleRefresh()}>
                            Clear
                        </button>
                    </div>

                </div>
                {loading &&
                    <div className="text-center p-5">
                        <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                }
                {!loading &&
                    <DataTable TableCols={SponsorReportViewGridCols} tableData={studentReportList}
                        activePageNumber={activePage} pageNumber={onPageChange} pageNumbers={pageArray}></DataTable>}
            </div>

        </div>
    )

}