import React, { useEffect, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import { Breadcum } from "../../../../components/breadcum";
import { Link, useHistory, useLocation } from 'react-router-dom';
import { GET_ACTIVE_STUDENTS, GET_ACTIVE_STUDENTS_PAGE_NUMBER } from '../../../../app/service/school-dashboard.service';
import { DataTable } from '../../../../components/data-table'
import { returnPaginationRange } from '../../../../app/utility/appUtils';


function SchoolActiveStudents() {
    const userSchoolName = localStorage.getItem('school_name')
    const [activePage, setActivePage] = useState<any>();
    const userRole = localStorage.getItem("user_role");
    const search = useLocation().search;
    const currentPageNumber: any = new URLSearchParams(search).get("page");
    const [pageNumbers, setPageNumbers] = useState<any>([]);
    const [studentsList, setStudentList] = useState<any>([]);
    const [loading, setLoading] = useState(false);
    const [breadcumInfo, setBreadcumInfo] = useState<any>([
        {
            label: "Dashboard",
            value: "Dashboard",
            routerLink: "/school-dashboard/view",
            isActive: false,
        },
        {
            label: "School Name",
            value: "",
            routerLink: "",
            isActive: false,
        },
    ]);
    const history = useHistory();
    let totalPage = pageNumbers.length
    const arrayOfPages = returnPaginationRange(pageNumbers.length, activePage, 10, 1)
    useEffect(() => {
        activeStudentsdetails();
        for (let i = 0; i < breadcumInfo.length; i++) {
            const element = breadcumInfo[i];
            element.routerLink = "/school-dashboard/view";
        }

    }, []);

    const activeStudentsdetails = () => {
        const data: any = localStorage.getItem("institute_code");
        setLoading(true);
        GET_ACTIVE_STUDENTS(data, 0).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    if (res) {
                        const data = res;
                        getBreadcumList(data);

                        setStudentList(data.rows);
                        const pageNumbers = Math.ceil(res.count / 10);
                        const pageArray: any = [];
                        for (let i = 1; i <= pageNumbers; i++) {
                            pageArray.push(i);
                        }
                        setPageNumbers(pageArray);
                        setActivePage(data);
                        setActivePage(currentPageNumber ? currentPageNumber : 1);
                    }
                    setLoading(false);
                }
            }
        });
    }
    function getBreadcumList(data: any) {
        breadcumInfo.splice(1, breadcumInfo.length - 1, {
            label: "Registered School",
            value: data.name_of_school,
            isActive: true,
        });
        setBreadcumInfo(breadcumInfo);
    }

    function onClickPage(number: number) {
        let dots: any = "..."

        if (activePage !== 1 && number === dots) {
            setActivePage(1);
            onPageChange(1);

        } else if (activePage !== pageNumbers.length && number === dots) {
            setActivePage(totalPage - 1);
            onPageChange(totalPage - 1);
        }
        else {
            setActivePage(number);
            onPageChange(number);
        }
    }
    function onClickPreviousPage() {
        setActivePage(activePage - 1);
        onPageChange(activePage - 1);

    }

    function onClickNextPage() {
        setActivePage(activePage + 1);
        onPageChange(activePage + 1);

    }

    const onPageChange = (data: any) => {

        if (data) {
            if (userRole === "SUPER_ADMIN") {
                history.push(`/dashboard/view/active-studentslist/list?page=${data}`);
            } else {
                history.push(`/school-dashboard/view/active-studentslist/list?page=${data}`);
            }
        }
        setActivePage(data);
        const pageNumber = data - 1;
        setLoading(true);
        const school_code = localStorage.getItem("institute_code");
        GET_ACTIVE_STUDENTS_PAGE_NUMBER(school_code, pageNumber).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    const data = res['rows'];
                    setStudentList(data);
                    setLoading(false);
                }

            }
        });
    }
    const onClickDoubbleArrows = (arrow: any) => {
        if (arrow === "&raquo;") {
            setActivePage(totalPage);
            onPageChange(totalPage);

        } else {
            setActivePage(1);
            onPageChange(1);

        }
    }
    return (
        <div >
            {loading &&
                <div className="text-center p-5 mt-5">
                    <div className="spinner-border mt-5" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading &&
                <div>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb border-bottom ps-0 pt-0 pb-2" style={{ letterSpacing: "0" }}>
                            {userRole === "SUPER_ADMIN" && <li className="breadcrumb-item mt-3"><Link to='/dashboard/view/schoolinfo' style={{ color: '#0d6efd' }}>Dashboard</Link> / <span>Active Students</span></li>}
                            {userRole !== "SUPER_ADMIN" && <li className="breadcrumb-item"><span className='active pe-1'><Link to='/school-dashboard/view'>Dashboard</Link></span>  / <span className='px-1'>Active Students</span></li>}
                        </ol>
                    </nav>
                    <div>
                        <div className='table-responsive'>
                            <table className='table table_new_ui'>
                                <thead>
                                    <tr>
                                        <th style={{ width: "120px" }}>Student Name</th>
                                        <th style={{ width: '120px' }}>User Name</th>
                                        <th style={{ width: '100px' }}> Email </th>
                                        <th style={{ width: '100px' }}> Mobile Number </th>
                                        <th style={{ width: "100px" }}>Standard</th>
                                        <th style={{ width: '100px' }}>Division	</th>
                                    </tr>
                                </thead>
                                <tbody >
                                    {studentsList.map((data: any, i: number) => {
                                        return (<tr key={i}>
                                            <td>{data.name}</td>
                                            <td>{data.user_name}</td>
                                            <td>{data.email_id}</td>
                                            <td>{data.mobile_number}</td>
                                            <td>{data.standard}</td>
                                            <td>{data.division}</td>
                                        </tr>
                                        )
                                    })}

                                </tbody>
                            </table>
                        </div>
                        <div>

                            {
                                studentsList.length > 0 && (
                                    <nav className="mt-3" style={{ float: "right" }}>
                                        {pageNumbers && (
                                            <ul className="pagination">
                                                <li className="page-item" onClick={() => onClickDoubbleArrows("&laquo;")}><span className={`page-link prev ${activePage == 1 ? "disabled" : ""}`}>&laquo;</span></li>

                                                <li className="page-item">
                                                    <button className={`page-link prev ${activePage == 1 ? "disabled" : ""}`} onClick={() => onClickPreviousPage()}>
                                                        &lsaquo;
                                                    </button>
                                                </li>
                                                {pageNumbers.length > 5 &&
                                                    arrayOfPages.map((value: any, i: number) => (
                                                        <li key={i} className={activePage == value ? "active page-item" : "page-item"} onClick={() => onClickPage(value)}><span className="page-link">{value}</span></li>

                                                    ))}
                                                {pageNumbers.length <= 5 &&
                                                    pageNumbers.map((number: number, i: number) => (
                                                        <li className={activePage == number ? "active page-item" : "page-item"} key={i}>
                                                            <button className="page-link" onClick={() => onClickPage(number)}>
                                                                {number}
                                                            </button>
                                                        </li>
                                                    ))}
                                                <li className="page-item">
                                                    <button className={`page-link next ${activePage == pageNumbers.length ? "disabled" : ""}`} onClick={() => onClickNextPage()}>
                                                        &rsaquo;
                                                    </button>
                                                </li>
                                                <li className="page-item" onClick={() => onClickDoubbleArrows("&raquo;")}><span className={`page-link next ${activePage == pageNumbers.length ? "disabled" : ""}`}>&raquo;</span></li>

                                            </ul>
                                        )}
                                    </nav>
                                )
                            }
                        </div>
                        {studentsList.length === 0 && <p className='mt-2 text-center f14'>No Data Available</p>}
                    </div>

                </div>
            }
        </div>
    )

}

export default SchoolActiveStudents;