import React, { useEffect, useState } from 'react'
import { Link, NavLink, useHistory, useLocation, useParams } from 'react-router-dom'
import appConfig from '../../../config/constant';
import "react-datepicker/dist/react-datepicker.css";
import NestedIndividualStudent from '../../../routes/insideindividualstudent';
import { toast } from 'react-toastify';
import { GET_INDIVIDUAL_USER_USER_CODE } from '../../../app/service/users.service';
import moment from 'moment';
import { DateComponent } from '../../../components/date';
import { Breadcum } from '../../../components/breadcum';
import NestedModuleList from '../../../routes/insidemodules';

export const ModulesMNS = () => {

    return (
        <div className="py-3" style={{ textAlign: 'center' }}>
           <h5>*MNS Worksheeets are Created Dynamically.</h5> 
        </div>
    )
}

