import API from "../utility/axios";
const apiUrl = '/api/v1';

export const CREATE_DEPARTMENT = (data: any): Promise<any> => {
    return API.post(`${apiUrl}/department`, data);
}

export const UPDATE_DEPARTMENT = (data: any): Promise<any> => {
    return API.put(`${apiUrl}/department`, data);
}

export const GET_DEPARTMENT = (pageNumber: number): Promise<any> => {
    return API.get(`${apiUrl}/department?limit=${10}&page_num=${pageNumber}`);
}

export const DELETE_DEPARTMENT = (id: number): Promise<any> => {
    return API.delete(`${apiUrl}/department/${id}`);
}

export const GET_DEPARTMENT_BY_ID = (id: number): Promise<any> => {
    return API.get(`${apiUrl}/department?id=${id}`);
}

export const GET_DEPARTMENTS = (): Promise<any> => {
    return API.get(`${apiUrl}/department`);
}

export const GET_DEPARTMENT_SEARCH = (data: any): Promise<any> => {
    return API.get(`${apiUrl}/department?limit=${10}&page_num=${0}`, { params: data });
}

