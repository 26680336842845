import React from 'react'
import { Route, Switch } from 'react-router'
import { ChildForm } from '../pages/dashboard/school/parent/child/form'
import { ChildList } from '../pages/dashboard/school/parent/child/list'
import { ParentModulesList } from '../pages/dashboard/school/parent/module/list'

const NestedParent = () => {
    return (
        <Switch>
            <Route path="/dashboard/parent/info/:id/modulelist/:schoolCode/:studentId" component={ParentModulesList}></Route>
            <Route path="/dashboard/parent/info/:id/childform/:schoolCode/:studentId/:userId" component={ChildForm}></Route>
            <Route path="/dashboard/parent/info/:id/childlist/:schoolCode/:studentId" component={ChildList}></Route>
        </Switch>
    )
}

export default NestedParent