import { Switch, Route } from "react-router";
import { SchooolReportList } from "./school-report-list";
import { StudentReportList } from "./student/list";


interface Props {
    match?: any;
    courseId: number;
}
const CASReportRoutes = (props: Props) => {

    let url: string | undefined = props.match?.url;
    
    if (url?.endsWith('/')) {
        url = url.substr(0, url.length - 1);
    }

    return (
        <Switch>
            <Route exact path={`${url}/school/list`} >
                <SchooolReportList></SchooolReportList>
            </Route>
            <Route exact path={`${url}/school/info/:id/studentlist`} >
                <StudentReportList></StudentReportList>
            </Route>
        </Switch>
    );
}
export default CASReportRoutes;