import React from 'react';
import CASReportRoutes from './routes';

const CASReportPage = (props: any) => {
    
    return (
        <div>
            <CASReportRoutes {...props} />
        </div>
    );
};

export default CASReportPage;