import API from "../utility/axios";
const apiUrl = "/api/v2";

export const GET_ABHYAS_QUESTION_LIST = (
  sheetUuid: string,
  // mode: string,
  data: any
): Promise<any> => {
  return API.get(`${apiUrl}/abhyaas/pariksha?uuid=${sheetUuid}`, {
    params: data,
  });
};
export const GET_ABHYAS_QUESTION_BY_UUID = (Uuid: string): Promise<any> => {
  return API.get(`${apiUrl}/abhyaas/pariksha?uuid=${Uuid}`);
};

export const UPDATE_ABHYAS_QUESTION = (
  data: any,
  sheetUuid: string
): Promise<any> => {
  return API.put(`${apiUrl}/abhyaas/pariksha/${sheetUuid}`, data);
};

export const CREATE_ABHYAS_QUESTION = (
  data: any,
  sheetUuid: string
): Promise<any> => {
  return API.put(`${apiUrl}/abhyaas/pariksha/${sheetUuid}`, data);
};

export const GET_ABHYAS_QUESTION_FILTER = (
  data: any,
  sheetUuid: string
): Promise<any> => {
  return API.get(
    `${apiUrl}/abhyaas/pariksha/${sheetUuid}?limit=${10}&page=${0}`,
    {
      params: data,
    }
  );
};
export const DELETE_ABHYAS_QUESTION = (
  Uuid: number,
  id: number,
  sheetUuid: string
): Promise<any> => {
  return API.delete(
    `${apiUrl}/abhyaas/pariksha/${sheetUuid}?id=${id}&uuid=${Uuid}`
  );
};

export const GET_ABHYAS_QUESTION_TEST_LIST = (
  pageNumber: number,

  data: any
): Promise<any> => {
  return API.get(`${apiUrl}/abhyaas/pariksha?limit=${10}&page=${pageNumber}`, {
    params: data,
  });
};

export const GET_ABHYAS_QUESTION_LIST_TEST = (
  pageNumber: number,

  data: any
): Promise<any> => {
  return API.get(`${apiUrl}/abhyaas/pariksha?limit=${10}&page=${pageNumber}`, {
    params: data,
  });
};
