import React, { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { DataTable } from "../../../../../components/data-table";
import { FormControlError, FormField, FormValidators } from "../../../../../components/form-builder/model/form-field";
import { toast } from "react-toastify";
import { FormValidator } from "../../../../../components/form-builder/validations";
import FormBuilder from "../../../../../components/form-builder";
import { Breadcum } from "../../../../../components/breadcum";
import { GET_BOARD_BY_UUID } from "../../../../../app/service/abhyas-board.service";
import moment from "moment";
import { CREATE_WORKSHEET, DELETE_WORKSHEET, GET_WORKSHEET_BY_UUID, GET_WORKSHEET_FILTER, GET_WORKSHEET_LIST, UPDATE_WORKSHEET } from "../../../../../app/service/abhyas-worksheet.service";
import { AbhyasWorksheetDataGridCols } from "./worksheet-data-grid-cols";
import { MathJaxContext, MathJax } from "better-react-mathjax";

interface Props {
    isLinked: string;
    display: string;
    boardUuid: any;
    mediumUuid: any;
    standardUuid: any;
    subjectUuid: any;
    chapterUuid: any;
    topicUuid: any;
    selectList?: (e: string, number: number) => void;

}
export const AbhyasWorksheetDataList: React.FC<Props> = (props) => {
    const [breadCrumLoading, setBreadCrumLoading] = useState<boolean>(false);
    const [worksheetList, setWorksheetList] = useState<any>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [pageArray, setPageNumbers] = useState([]);
    const [activePage, setActivePage] = useState(0);
    const [canShowPopup, setCanShowPopup] = useState<boolean>(false);
    const [worksheetUuid, setWorksheetUuid] = useState<string>("0");
    const [formLoading, setFormLoading] = useState<boolean>(false);
    const [currentFormData, setCurrentFormData] = useState<any>({});
    const [formData, setFormData] = useState<any>([]);
    const [formValidationErrors, setFormValidationErrors] = useState<FormControlError[]>([]);
    const [nameError, setNameError] = useState('');
    const [preparedSearchData, setPreparedSearchData] = useState<any>({
        mode: "ABHYAAS",
        sorting_field: "number",
        sorting_type: "asc",
    });
    const medimForm = useRef<any>({});
    const searchForm = useRef<any>({});
    const [searchData, setSearchData] = useState<any>({});
    const [typeError, setTypeError] = useState<boolean>(false);
    const [levelError, setLevelError] = useState<boolean>(false);
    const [numberError, setNumberError] = useState<string>("");
    let { code, id, BoardUuid, BoardName, MediumUuid, MediumName, StandardUuid, StandardName, SubjectUuid, SubjectName, ChapterUuid, ChapterName, TopicUuid, TopicName } = useParams<{ code: string, id: string, BoardUuid: string, BoardName: string, MediumUuid: string, MediumName: string, StandardUuid: string, StandardName: string, SubjectUuid: string, SubjectName: string, ChapterUuid: string, ChapterName: string, TopicUuid: string, TopicName: string }>();
    const moduleCode = code;
    const moduleId = Number(id);
    const boardUuid = BoardUuid ? BoardUuid : props.boardUuid;
    const mediumUuid = MediumUuid ? MediumUuid : props.mediumUuid;
    const standardUuid = StandardUuid ? StandardUuid : props.standardUuid;
    const subjectUuid = SubjectUuid ? SubjectUuid : props.subjectUuid;
    const chapterUuid = ChapterUuid ? ChapterUuid : props.chapterUuid;
    const topicUuid = TopicUuid ? TopicUuid : props.topicUuid;
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const FormValidations = [
        new FormField('type', [FormValidators.REQUIRED]),
        new FormField('level', [FormValidators.REQUIRED]),
    ];
    const history = useHistory();
    const [showQuestionPopUp, setAhowQuestionPopUp] = useState<boolean>(false);
    const [questionsList, setQuestionsList] = useState<any>([]);
    const [loadingWorksheetQuestions, setLoadingWorksheetQuestions] = useState(false);
    const [breadcumInfo, setBreadcumInfo] = useState<any>([
        {
            label: 'Board',
            value: decodeURIComponent(BoardName),
            routerLink: `/dashboard/module/info/ABHYAS/${moduleCode}/${moduleId}/worksheet`,
            subName: "(board)",
            isActive: false
        },
        {
            label: 'Medium',
            value: decodeURIComponent(MediumName),
            routerLink: `/dashboard/module/info/ABHYAS/${moduleCode}/${moduleId}/${boardUuid}/${BoardName}/medium`,
            subName: "(Medium)",
            isActive: false
        },
        {
            label: 'standard',
            value: decodeURIComponent(StandardName),
            routerLink: `/dashboard/module/info/ABHYAS/${moduleCode}/${moduleId}/${boardUuid}/${BoardName}/${MediumUuid}/${MediumName}/standard`,
            subName: "(standard)",
            isActive: false
        },
        {
            label: 'subject',
            value: decodeURIComponent(SubjectName),
            routerLink: `/dashboard/module/info/ABHYAS/${moduleCode}/${moduleId}/${boardUuid}/${BoardName}/${MediumUuid}/${MediumName}/${standardUuid}/${StandardName}/subject`,
            subName: "(subject)",
            isActive: false
        },
        {
            label: 'chapter',
            value: decodeURIComponent(ChapterName),
            routerLink: `/dashboard/module/info/ABHYAS/${moduleCode}/${moduleId}/${boardUuid}/${BoardName}/${MediumUuid}/${MediumName}/${standardUuid}/${StandardName}/${subjectUuid}/${SubjectName}/chapter`,
            subName: "(chapter)",
            isActive: false
        },
        {
            label: 'topic',
            value: decodeURIComponent(TopicName),
            routerLink: `/dashboard/module/info/ABHYAS/${moduleCode}/${moduleId}/${boardUuid}/${BoardName}/${MediumUuid}/${MediumName}/${standardUuid}/${StandardName}/${subjectUuid}/${SubjectName}/${chapterUuid}/${ChapterName}/topic`,
            subName: "(topic)",
            isActive: false
        },
        {
            label: 'Worksheets',
            value: 'Worksheets',
            nameHighlight: true,
            isActive: true
        }
    ]);

    useEffect(() => {
        getWorksheetData();

    }, [])


    const getWorksheetData = () => {
        setLoading(true);

        GET_WORKSHEET_LIST(activePage == 0 ? activePage : activePage - 1, boardUuid, mediumUuid, standardUuid, subjectUuid, chapterUuid, topicUuid, "WORKSHEETS", preparedSearchData).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res?.err);
                    setLoading(false);

                } else {
                    const data = res?.rows;
                    data?.forEach((element: any) => {
                        element.created_dt = moment(element?.created_dt?.split("T")[0]).format('DD-MM-yyyy');
                        if (element?.modified_dt) {
                            element.modified_dt = moment(element?.modified_dt?.split("T")[0]).format('DD-MM-yyyy');
                        }
                        if (element?.level === "LOW") {
                            element.level = "EASY";
                        } else if (element?.level === "HIGH") {
                            element.level = "HARD";
                        }
                    });
                    setWorksheetList(data);
                    const pageNumbers = Math.ceil(res.count / 10);
                    const pageArray: any = [];
                    for (let i = 1; i <= pageNumbers; i++) {
                        pageArray.push(i);
                    }
                    setPageNumbers(pageArray);
                    setLoading(false);

                }
            }
        });
    }


    const onPageChange = (data: any) => {
        setActivePage(data);
        const pageNumber = data - 1;
        setLoading(true);
        GET_WORKSHEET_LIST(pageNumber, boardUuid, mediumUuid, standardUuid, subjectUuid, chapterUuid, topicUuid, "WORKSHEETS", preparedSearchData ? preparedSearchData : {}).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res?.err);
                    setLoading(false);
                } else {
                    const data = res.rows;
                    data?.forEach((element: any) => {
                        element.created_dt = moment(element?.created_dt?.split("T")[0]).format('DD-MM-yyyy');
                        if (element?.modified_dt) {
                            element.modified_dt = moment(element?.modified_dt?.split("T")[0]).format('DD-MM-yyyy');
                        }
                    });
                    setWorksheetList(data);
                    setLoading(false);
                }
            }
        });
    }
    const onDelete = (data: any) => {
        setLoading(true);
        const id = data.uuid;
        DELETE_WORKSHEET(id).then((res: any) => {
            if (res.status === "fail") {
                toast.error(res?.err);
                setLoading(false);
            } else {
                setLoading(false);
                toast.success(res.message);
                getWorksheetData();
            }
        });

    }
    const onEdit = (data: any) => {
        setWorksheetUuid(data?.uuid);
        setFormLoading(false);
        setNameError('');
        setLevelError(false);
        setNumberError("");
        setCanShowPopup(true);
        if (data?.uuid) {
            setFormLoading(true);
            GET_WORKSHEET_BY_UUID(data?.uuid).then((res: any) => {
                if (res.status === 'fail') {
                    toast.error(res?.err);
                    setFormLoading(false);

                } else {
                    if (res.rows.length > 0) {
                        setCurrentFormData(res.rows[0]);
                        setFormLoading(false);

                    }
                }
            });
        }
    }
    const handleClose = () => {
        setFormData({});
        setCurrentFormData({});
        setCanShowPopup(false);
        setAhowQuestionPopUp(false);

    }
    const addWorksheet = (data: any) => {
        setFormLoading(false);
        setTypeError(false);
        setLevelError(false);
        setCanShowPopup(true);
        setWorksheetUuid(data);
        setNumberError("");
        const datafrom = {
            level: '',
            type: "",
            number: '',
            name: ''
        };
        setFormData({ ...datafrom });
        setCurrentFormData({ ...datafrom });
        medimForm?.current.reset();
    }
    const handleFormInput = (data: any) => {
        data.value = { ...currentFormData, ...data.value };
        setFormData(data);
        const errors: any = FormValidator(FormValidations, data.value);
        setFormValidationErrors(errors);
    };

    const handleLevelChange = (e: any) => {
        const data = { ...formData.value };
        data.level = e.target.value;
        if (e.target.value) {
            setLevelError(false);
        }
        if (currentFormData) {
            currentFormData.level = e.target.value;
        }
    }
    const createWorksheet = () => {
        const formsData = formData.value ? { ...formData.value } : { ...currentFormData };
        setIsFormSubmitted(true);
        const errors: FormControlError[] = FormValidator(FormValidations, formsData);
        setFormValidationErrors(errors);
        Object.keys(formsData).forEach(function (key) {
            if (typeof formsData[key] === 'string') {
                formsData[key] = formsData[key].trim();
            }
        });
        const payLoad = {
            board_uuid: boardUuid,
            medium_uuid: mediumUuid,
            standard_uuid: standardUuid,
            subject_uuid: subjectUuid,
            chapter_uuid: chapterUuid,
            topic_uuid: topicUuid,
            uuid: "",
            mode: "ABHYAAS",
            type: "WORKSHEETS",
            exam_type: "",
            level: formsData?.level ? formsData?.level : "",
            number: formsData?.number,
            name: formsData?.name,

        }
        if (formsData?.level && !numberError && formsData?.number) {
            setFormLoading(true);
            CREATE_WORKSHEET(payLoad).then((res: any) => {
                if (res.status === 'fail') {
                    const errorValue = Array.isArray(res?.err) ? res?.err[0] : res?.err;
                    toast.error(errorValue);
                    setFormLoading(false);
                } else {
                    toast.success(res.message);
                    getWorksheetData();
                    setFormLoading(false);
                    setCanShowPopup(false);
                    setFormData({});
                    setCurrentFormData({});
                }
            });
        } else {
            if (!formsData?.level) {
                setLevelError(true);
            }
            if (!formsData?.number) {
                setNumberError("Please enter worksheet number");

            } else {
                setNumberError(numberError);
            }

        }
    }
    const updateWorksheet = () => {
        const formsData = formData.value ? { ...formData.value } : { ...currentFormData };
        setIsFormSubmitted(true);
        const errors: FormControlError[] = FormValidator(FormValidations, formsData);
        const payLoad = {
            uuid: worksheetUuid,
            board_uuid: boardUuid,
            medium_uuid: mediumUuid,
            standard_uuid: standardUuid,
            subject_uuid: subjectUuid,
            chapter_uuid: chapterUuid,
            topic_uuid: topicUuid,
            mode: "ABHYAAS",
            type: "WORKSHEETS",
            exam_type: "",
            level: formsData?.level ? formsData?.level : "",
            number: formsData?.number,
            name: formsData?.name,
        }
        setFormValidationErrors(errors);
        if (formsData?.level && !numberError && formsData?.number) {
            setFormLoading(true);
            UPDATE_WORKSHEET(payLoad).then((res: any) => {
                if (res.status === 'fail') {
                    const errorValue = Array.isArray(res?.err) ? res?.err[0] : res?.err;
                    toast.error(errorValue);
                    setFormLoading(false);
                } else {
                    toast.success(res.message);
                    getWorksheetData();
                    setFormLoading(false);
                    setCanShowPopup(false);
                    setFormData({});
                    setCurrentFormData({});
                }
            });
        } else {
            if (!formsData?.level) {
                setLevelError(true);
            }
            if (!formsData?.number) {
                setNumberError("Please enter worksheet number");

            } else {
                setNumberError(numberError);
            }
        }
    }
    const handleSearchInput = (data: any) => {
        setSearchData(data);
    };
    function handleSearch() {
        const data = { ...searchData.value };
        if (data?.search) {
            setLoading(true);
            for (const key of Object.keys(data)) {
                if (data[key] === "") {
                    delete data[key];
                }
            }
            setPreparedSearchData(data);
            let preparedData = {
                board_uuid: boardUuid,
                medium_uuid: mediumUuid,
                standard_uuid: standardUuid,
                subject_uuid: subjectUuid,
                chapter_uuid: chapterUuid,
                topic_uuid: topicUuid,
                mode: "ABHYAAS",
                type: "WORKSHEETS",
                sorting_field: "number",
                search: data?.search,
                sorting_type: "asc"
            }

            GET_WORKSHEET_FILTER(preparedData).then((res: any) => {
                if (res.status === 'fail') {
                    toast.error(res?.err);
                    setLoading(false);
                } else {
                    const data = res.rows;
                    data?.forEach((element: any) => {
                        element.created_dt = moment(element?.created_dt?.split("T")[0]).format('DD-MM-yyyy');
                        if (element?.modified_dt) {
                            element.modified_dt = moment(element?.modified_dt?.split("T")[0]).format('DD-MM-yyyy');
                        }
                    });
                    setActivePage(0);
                    setWorksheetList(data);
                    const pageNumbers = Math.ceil(res.count / 10);
                    const pageArray: any = [];
                    for (let i = 1; i <= pageNumbers; i++) {
                        pageArray.push(i);
                    }
                    setPageNumbers(pageArray);
                    setLoading(false);
                }
            });
        }
    }
    function handleRefresh() {
        const data = searchData;
        data.value = {};
        setSearchData({ ...data });
        preparedSearchData.search = "";
        preparedSearchData.sorting_field = "number";
        preparedSearchData.sorting_type = "asc";
        preparedSearchData.mode = "ABHYAAS"
        setPreparedSearchData(preparedSearchData);
        searchForm.current.reset();
        getWorksheetData();

    }
    const onActions = (data: any) => {
        if (props.isLinked === "false" && props.selectList) {
            props.selectList(data?.data?.uuid, data?.data?.number);
        }
        else
            if (data.type === "link") {
                history.push(
                    `/dashboard/module/info/ABHYAS/${moduleCode}/${moduleId}/${boardUuid}/${BoardName}/${MediumUuid}/${MediumName}/${standardUuid}/${StandardName}/${subjectUuid}/${SubjectName}/${chapterUuid}/${ChapterName}/${topicUuid}/${TopicName}/${data?.data?.uuid}/${data?.data?.number}/${data?.data?.type}/question`
                );
            }
    };
    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleSearch();
        }
    };
    const viewQuestion = (data: any) => {
        setAhowQuestionPopUp(true);
        setLoadingWorksheetQuestions(true);
        let preparedData = JSON.parse(data?.data)
        setQuestionsList(preparedData);
        setLoadingWorksheetQuestions(false);
    }
    function onViewQuestion(name: any) {
        return { __html: name };
    }
    const handleTestNumber = (value: any) => {
        const data = { ...formData.value };
        data.number = value;
        const re = /^[0-9\b]+$/;
        if (re.test(value)) {
            setNumberError("")
        }
        else {
            setNumberError("Please enter only numbers")
        }
        if (currentFormData) {
            currentFormData.number = value;
        }
    }
    return (
        <div>
            <div
                className={`modal fade ${showQuestionPopUp ? "show" : "false"}`}
                style={{ display: showQuestionPopUp ? "block" : "none" }}
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog worksheet-quesions-dialog" role="document">
                    <div className="modal-content worksheet-questions-model">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                                Questions List
                            </h5>
                            <button
                                type="button"
                                className="close popup-close cursor-pointer"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={handleClose}
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body parent-info parent-model ">
                            {loadingWorksheetQuestions &&
                                <div className="text-center p-5">
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div>
                            }
                            {!loadingWorksheetQuestions && <div>
                                <MathJaxContext>
                                    <MathJax>
                                        {questionsList?.map((question: any, i: number) => (
                                            <div className="mt-4" key={i}>
                                                <div className='row col-md-12'>
                                                    <div className='row'>
                                                        {/* <div className='col-md-1' style={{ alignSelf: 'center' }}>
                                                            <span className='fw-bold' style={{ fontSize: '22px' }}>Q{question.id}:</span>
                                                        </div> */}
                                                        <div className='col-12' style={{ paddingTop: '15px' }}>
                                                            <div className='d-flex'>
                                                                <p className='fw-bold mb-0' style={{ fontSize: '22px' }}>
                                                                    Q{question.id}:
                                                                </p>
                                                                <div className='ms-4 ps-2'>
                                                                    <span className='fw-bold' style={{ fontSize: '22px' }}>
                                                                        {/* <MathJaxContext> */}
                                                                        {/* <MathJax> */}
                                                                        <div dangerouslySetInnerHTML={onViewQuestion(question.question)}></div>
                                                                        {/* </MathJax> */}
                                                                        {/* </MathJaxContext> */}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {question.options.map((options: any, j: number) => (
                                                        <div className='row' key={j}>
                                                            <div className='col-md-1'>
                                                                <span>{options.option}.</span>
                                                            </div>
                                                            <div className='col-md-6'>
                                                                {/* <MathJaxContext> */}
                                                                {/* <MathJax> */}
                                                                <span dangerouslySetInnerHTML={onViewQuestion(options.value)}></span>
                                                                {/* </MathJax> */}
                                                                {/* </MathJaxContext> */}
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        ))}
                                        {questionsList.length == 0 && <h6 className="text-center mt-3">No Data Found</h6>}

                                    </MathJax>
                                </MathJaxContext>
                            </div>}
                        </div>
                        <div className="modal-footer">

                            <button
                                type="button"
                                className="btn btn-secondary cursor-pointer btn-sm px-4 rounded-12 cursor-pointer py-2"
                                data-dismiss="modal"
                                onClick={handleClose}
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className={`modal fade ${canShowPopup ? "show" : "false"}`}
                style={{ display: canShowPopup ? "block" : "none" }}
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog " role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                                {worksheetUuid === "0" ?
                                    (<span>Add Worksheet</span>)
                                    : (<span> Update Worksheet</span>)
                                }
                            </h5>

                            <button
                                type="button"
                                className="close popup-close cursor-pointer"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={handleClose}
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body parent-info ">
                            {formLoading && (
                                <div className="text-center p-5">
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div>
                            )}
                            {!formLoading && (
                                <>
                                    <FormBuilder onUpdate={handleFormInput}>
                                        <form ref={medimForm}>
                                            <div className="row custom-form">
                                                <div className="col-md-12 ">
                                                    <div className="mb-4">
                                                        <label className="form-label mb-1">Worksheet Number <sup className="text-danger f14">*</sup></label>
                                                        <input className="form-control form-control-lg f18" type="text" name="number" value={currentFormData?.number} placeholder="Please enter  test number" onChange={(e) => { handleTestNumber(e.target.value) }} />
                                                        {numberError && <p className="text-danger">{numberError}</p>}
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="mb-4">
                                                        <label className="form-label ">Level  <sup style={{ color: 'red', fontSize: '14px', }}>*</sup></label>
                                                        <select name="level" id="level" className="form-select f18" onChange={(e) => { handleLevelChange(e) }} value={currentFormData?.level} >
                                                            <option value="">Select Level</option>
                                                            <option value="LOW">Easy</option>
                                                            <option value="MEDIUM">Medium</option>
                                                            <option value="HIGH">Hard</option>
                                                        </select>
                                                        {levelError && <p className="text-danger">Please select level</p>}
                                                    </div>
                                                </div>
                                                <div className="col-md-12 ">
                                                    <div className="mb-4">
                                                        <label className="form-label mb-1">Name</label>
                                                        <input className="form-control form-control-lg f18" type="text" name="name" defaultValue={currentFormData?.name} placeholder="Please enter worksheet name" />
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </FormBuilder>
                                </>
                            )}
                        </div>
                        <div className="modal-footer">
                            {worksheetUuid === "0" && < a className="btn btn-primary btn-sm px-4 rounded-12 cursor-pointer py-2" onClick={() => createWorksheet()}>Add</a>}
                            {worksheetUuid !== "0" && <a className="btn btn-primary btn-sm px-4 rounded-12 cursor-pointer py-2" onClick={() => updateWorksheet()}>Update</a>}
                            <button
                                type="button"
                                className="btn btn-secondary cursor-pointer btn-sm px-4 rounded-12 cursor-pointer py-2"
                                data-dismiss="modal"
                                onClick={handleClose}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`mt-3 ${props.display === "none" && "d-none"}`}>
                <Breadcum breadcumList={breadcumInfo} margin={"classic"} />
            </div>
            <div className="row  mt-3" style={{ marginBottom: '15px' }}>
                <div className="col-md-4">
                    <FormBuilder onUpdate={handleSearchInput}>
                        <form ref={searchForm}>
                            <input type="text" id="search" className="form-control ft-14" placeholder="search" name="search" onKeyDown={(e) => handleKeyDown(e)} />
                            {/* <select name="level" id="level" className="form-select f16" >
                                <option value="">select level</option>
                                <option value="LOW">LOW</option>
                                <option value="MEDIUM">MEDIUM</option>
                                <option value="HIGH">HIGH</option>
                            </select> */}
                        </form>
                    </FormBuilder>
                </div>
                <div className="col-md-4 pt-3 pt-md-0 ">
                    <button className="btn btn-primary px-3 cursor-pointer" type="button" onClick={() => handleSearch()}>Search</button>&nbsp;
                    <button className="btn clear-btn cursor-pointer" type="button" onClick={() => handleRefresh()}>
                        Clear
                    </button>
                </div>
                <div className="col-md-4 pt-3 pt-md-0 text-end ">
                    <p className="mb-0"><button className="btn btn-primary" onClick={() => addWorksheet("0")}>Add Worksheet </button></p>
                </div>
            </div>
            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading &&
                <div className="mt-3">
                    <DataTable TableCols={AbhyasWorksheetDataGridCols} tableData={worksheetList}
                        onActions={(e) => onActions(e)}
                        onChange={viewQuestion}
                        editInfo={onEdit}
                        deleteInfo={onDelete}
                        pageNumber={onPageChange} activePageNumber={activePage} pageNumbers={pageArray}></DataTable>
                </div>}

        </div>
    )
}

