import React, { useEffect, useState } from 'react'
import { Link, NavLink, useParams } from 'react-router-dom'
import { GET_MODULES_BY_SCHOOL_ID, GET_MODULES_BY_USER_CODE } from '../../../../../app/service/module.service';
import { DataTable } from '../../../../../components/data-table';
import { ModuleDataGridCols } from './data-grid-cols';

export const ParentModulesList = () => {
    let { id, schoolCode, studentId } = useParams<{ id: string, schoolCode: string, studentId: string }>();
    const schoolId = parseInt(id);
    const schoolsCode = schoolCode;
    const studentsCode = studentId;

    const [modulesData, setModulesData] = useState<any[]>([]);
    const [pageArray, setPageNumbers] = useState([]);
    const [activePage, setActivePage] = useState(1);

    useEffect(() => {
        GET_MODULES_BY_USER_CODE(studentsCode).then((res: any) => {
            if (res) {
                const data = res.rows;
                setModulesData(data);
                const pageNumbers = Math.ceil(res.count / 10);
                const pageArray: any = [];
                for (let i = 1; i <= pageNumbers; i++) {
                    pageArray.push(i);
                }
                setPageNumbers(pageArray);
            }
        });
    }, []);

    const onPageChange = (data: any) => {
        setActivePage(data);
    }


    return (
        <div>
            <div className="row">
            </div>
            <DataTable TableCols={ModuleDataGridCols} tableData={modulesData}
                pageNumber={onPageChange} activePageNumber={activePage} pageNumbers={pageArray}></DataTable>
        </div>
    )
}
