import React, { useEffect, useState } from 'react'
import { Link, useHistory, useLocation, useParams } from 'react-router-dom'
import { DataTable } from '../../../components/data-table';
import { PaymentDataGridCols } from './data-grid-cols'
import { ToastContainer, toast } from 'react-toastify';
import { GET_PAYMENT } from '../../../app/service/payment-service';
import { GET_USER_USER_CODE } from '../../../app/service/users.service';
import { GET_SCHOOL_BY_SCHOOL_CODE } from '../../../app/service/school.service';
import moment from 'moment';

export const PaymentsList = () => {
    const [pageArray, setPageNumbers] = useState([]);
    const [activePage, setActivePage] = useState<any>();
    const [rolesList, setPaymentList] = useState<any>([]);
    const [loading, setLoading] = useState(false);
    const [canShowPaymentPopup, setCanShowPaymentPopup] = useState(false);
    const [schoolData, setSchoolData] = useState<any>({});
    const [usersData, setUsersData] = useState<any>([]);
    const search = useLocation().search;
    const currentPageNumber: any = new URLSearchParams(search).get('page');
    const history = useHistory();
    const [fromDate, setFromDate] = useState<any>();
    const [toDate, setToDate] = useState<any>();
    const [startDate, setStartDate] = useState<any>();

    useEffect(() => {
        getPayments();

        const accessObjectsData: any = localStorage.getItem("access_objects_data");
        handlePaymentsCols(JSON.parse(accessObjectsData));
    }, []);

    function getPayments() {
        const activePageNumber = currentPageNumber > 0 ? (currentPageNumber - 1) : 0;
        setLoading(true);
        GET_PAYMENT(activePageNumber).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    setActivePage(currentPageNumber ? currentPageNumber : 1);
                    const data = res.rows;

                    for (let i = 0; i < data.length; i++) {
                        if (Array.isArray(data[i].user_ids)) {
                            data[i].user_ids_count = data[i].user_ids.length;
                        } else {
                            data[i].user_ids_count = 1
                        }
                        data[i].payment_school_name = data[i].name_of_school;
                        data[i].payment_status = data[i].status === 1 ? 'completed' : 'failed';
                        if (data[i].user_ids.length > 0) {
                            data[i].user_ids = data[i].user_ids.toString();
                        }
                        if (data[i].created_at) {
                            data[i].created_at = moment(data[i].created_at.split("T")[0]).format('DD-MM-yyyy');

                        }
                    }
                    setPaymentList(data);
                    const pageNumbers = Math.ceil(res.count / 10);
                    const pageArray: any = [];
                    for (let i = 1; i <= pageNumbers; i++) {
                        pageArray.push(i);
                    }
                    setPageNumbers(pageArray);
                    setLoading(false);
                }
            }
        });
    }


    const onPageChange = (data: any) => {
        if (data) {
            history.push(`/dashboard/payments?page=${data}`);
        }
        setActivePage(data);
        const pageNumber = data - 1;
        setLoading(true);
        GET_PAYMENT(pageNumber).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    const data = res['rows'];
                    for (let i = 0; i < data.length; i++) {
                        if (Array.isArray(data[i].user_ids)) {
                            data[i].user_ids_count = data[i].user_ids.length;
                        } else {
                            data[i].user_ids_count = 1
                        }
                        data[i].payment_school_name = data[i].name_of_school;
                        data[i].payment_status = data[i].status === 1 ? 'completed' : 'failed';
                        if (data[i].created_at) {
                            data[i].created_at = moment(data[i].created_at.split("T")[0]).format('DD-MM-yyyy');

                        }
                    }
                    setPaymentList(data);
                    setLoading(false);
                }

            }
        });
    }

    const handlePaymentClose = () => {
        setCanShowPaymentPopup(false);
    }

    const getPaymentDetails = (data: any) => {
        setCanShowPaymentPopup(true);
        getSchoolDetails(data);
        getUserDetails(data);

    }

    function getSchoolDetails(item: any) {
        setLoading(true);
        GET_SCHOOL_BY_SCHOOL_CODE(item.school_code).then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res.message);
                setLoading(false);
            } else {
                if (res.rows.length > 0) {
                    const data = res.rows[0];
                    setSchoolData(data);
                }
                setLoading(false);
            }
        });
    }

    function getUserDetails(item: any) {
        const userIds = item.user_ids.toString();
        setLoading(true);
        GET_USER_USER_CODE(userIds).then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res.message);
                setLoading(false);
            } else {
                if (res.rows.length > 0) {
                    const data = res.rows;
                    setUsersData(data);
                }
                setLoading(false);
            }
        });
    }

    const handlePaymentsCols = (accessObjectsList: any) => {
        const preparedData = accessObjectsList?.filter((el: any) => el.object_code === "PAYMENT");
        if (preparedData?.length > 0) {
            for (let i = 0; i < preparedData[0].child.length; i++) {
                for (let j = 0; j < PaymentDataGridCols.length; j++) {
                    if (preparedData[0].child[i].object_code === "PAYMENT-VIEW-MORE" && preparedData[0].child[i].p_read === 1) {
                        if (PaymentDataGridCols[j].title == "Actions") {
                            PaymentDataGridCols[j].viewMore = true;
                            PaymentDataGridCols[j].canShowColumn = true;

                        }

                    }


                }
            }
        }
    }


    return (
        <div className="row border-top border-primary py-3">
            <div className={`modal fade ${canShowPaymentPopup ? 'show' : 'false'}`} style={{ display: (canShowPaymentPopup ? "block" : 'none') }} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog parent-model-dialog" role="document">
                    <div className="modal-content parent-model">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Payment Details</h5>
                            <button type="button" className="close popup-close cursor-pointer" data-dismiss="modal" aria-label="Close" onClick={handlePaymentClose}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body parent-info parent-model">
                            {loading &&
                                <div className="text-center p-5">
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div>
                            }
                            {!loading && <div>
                                <h3>School Details</h3>
                                <div className="mt-3 custom-scroll table-responsive">
                                    <table className="table fixed-table-header mb-0">
                                        <thead>
                                            <tr>
                                                <th>School Name</th>
                                                <th>UDISE Number</th>
                                                <th>Contact Number</th>
                                                <th>Official Email</th>
                                                <th>State</th>
                                                <th>City</th>
                                                <th>Date of Registration</th>
                                            </tr>
                                        </thead>
                                        {schoolData && <tbody>
                                            <tr>
                                                <td>{schoolData?.name_of_school}</td>
                                                <td>{schoolData?.udise_code}</td>
                                                <td>{schoolData?.contact_number}</td>
                                                <td>{schoolData?.email_id}</td>
                                                <td>{schoolData?.state}</td>
                                                <td>{schoolData?.city_village}</td>
                                                <td>{schoolData?.date_of_registration}</td>
                                            </tr>
                                        </tbody>}
                                    </table>
                                </div>

                                <h3 className='mt-3'>User Details</h3>
                                <div className="custom-scroll table-responsive">
                                    <table className="table fixed-table-header mb-0">
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>User Name</th>
                                                <th>Email</th>
                                                <th>Mobile Number</th>
                                                <th>Standard</th>
                                                <th>Division</th>
                                                <th>City</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {usersData.map((item: any, i: number) => (
                                                <tr key={i}>
                                                    <td>{item?.name}</td>
                                                    <td>{item?.user_code}</td>
                                                    <td>{item?.email_id}</td>
                                                    <td>{item?.mobile_number}</td>
                                                    <td>{item?.standard}</td>
                                                    <td>{item?.division}</td>
                                                    <td>{item?.city_village}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary cursor-pointer" data-dismiss="modal" onClick={handlePaymentClose}>Close</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-10">
                    <h2>Payments</h2>
                </div>
            </div>

            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading && <DataTable TableCols={PaymentDataGridCols}
                onChange={getPaymentDetails}
                tableData={rolesList}
                activePageNumber={activePage} pageNumber={onPageChange} pageNumbers={pageArray}></DataTable>}
        </div>
    )
}
