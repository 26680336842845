import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { GET_MLA_TWO_COMPLETE_INFO } from '../../../app/service/mla-two.service';

export const MLATwoAbilityResultPage = () => {
    const [mlaInfo, setMlaInfo] = useState(null);
    const [sectionData, setSectionData] = useState([]);
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    let { moduleUUID, userCode, schoolCode } = useParams<{ moduleUUID: string, userCode: string, schoolCode: string }>();
    const modules_uuid = moduleUUID;
    const user_code = userCode;
    const school_code = schoolCode;

    useEffect(() => {
        getMLATwoInfo();
    }, []);

    function getMLATwoInfo() {
        setLoading(true);
        let payload = {
            "module_uuid": modules_uuid,
            "school_code": school_code,
            "user_code": user_code,
            "cycle_no": ''
        }

        GET_MLA_TWO_COMPLETE_INFO(payload).then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res.message);
                setLoading(false);
            } else {
                if (res) {
                    const data = res;
                    setLoading(false);
                    setMlaInfo(data);
                    loadResults(data.test_results);
                }
            }
        });
    }

    const loadResults = (test_results: any) => {
        let topic_data: any = [];
        test_results.forEach((item: any) => {
            let topic = topic_data.find((a: any) => a === item.topic);
            if (!topic) {
                topic_data.push(item.topic);
            }
        });

        let sectionList: any = [];
        topic_data.forEach((item: any) => {
            let data = test_results.filter((topic: any) => topic.topic === item);
            sectionList.push({
                title: item,
                data: data
            });
        });

        setSectionData(sectionList);
    }

    const getFieldColor = (value: any) => {
        if (value === 0) {
            return 'red';
        } else if (value === 1 || value === 2) {
            return '#ED7D31';
        } else if (value === 3) {
            return '#8cc63f';
        } else {
            return '#333333';
        }
    }

    const handleBack = () => {
        history.push(`/dashboard/mns/dashboard/${modules_uuid}/${user_code}/${school_code}`);
    }

    function getSolvedWorkSheetNumbers(count: any) {
        if (count.length > 0) {
            return count[0].worksheets;
        } else {
            return '';
        }
    }

    return (
        <div>
            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading && <section className="mb-4 mt-4 px-3 px-md-0">
                <div className="bg-white rounded-16 p-3 p-lg-5 container">
                    <div className="row align-items-center">
                        <div className="col-md-12 ps-lg-5">
                            <div className="row mb-0">
                                <div className='col-md-6'>
                                    <span className='fw-bold'>Ability wise entry test results</span>
                                </div>
                                <div className='col-md-6' style={{ textAlign: 'right' }}>
                                    <button className="btn btn-primary btn-sm px-5 rounded-12 cursor-pointer" onClick={handleBack}>Back</button>
                                </div>
                            </div>
                            <div className="mt-3 custom-scroll table-responsive">
                                <table className="table ability-table mb-0">
                                    <thead>
                                        <tr>
                                            <th>Ability</th>
                                            <th>Test1 marks</th>
                                            <th>Test2 marks</th>
                                            <th>Test3 marks</th>
                                            <th>Combine Score</th>
                                            <th>Solved worksheet</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {sectionData.map((detail: any, i: number) => (
                                            <React.Fragment key={i}>
                                                <tr style={{ textAlign: 'initial' }}>
                                                    <td className='ability-topic-header' colSpan={6}>{detail.title}</td>
                                                </tr>
                                                {
                                                    detail.data.length > 0 && detail.data.map((item: any, j: number) => (
                                                        <tr key={j}>
                                                            <td>{item.name}</td>
                                                            <td style={{ fontSize: 11, fontWeight: '600', color: getFieldColor(item.test_1) }}>{item.test_1}</td>
                                                            <td style={{ fontSize: 11, fontWeight: '600', color: getFieldColor(item.test_2) }}>{item.test_2}</td>
                                                            <td style={{ fontSize: 11, fontWeight: '600', color: getFieldColor(item.test_3) }}>{item.test_3}</td>
                                                            <td style={{ fontSize: 11, fontWeight: '600', color: getFieldColor(item.average) }}>{item.average}</td>
                                                            {/* <td style={{ fontSize: 11, fontWeight: '600', color: getFieldColor(item.worksheets) }}>{item.worksheets}</td> */}
                                                            <td style={{ fontSize: 11, fontWeight: '600', color: getFieldColor(getSolvedWorkSheetNumbers(item.worksheets)) }}>{getSolvedWorkSheetNumbers(item.worksheets)}</td>

                                                        </tr>
                                                    ))
                                                }
                                            </React.Fragment>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </section>}
        </div>
    )
}