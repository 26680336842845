import React, { useEffect, useState } from 'react'
import moment from 'moment';

interface Props {
    onDate?: any;
    yearDiff?: number;
    defaultData?: string;
    idDisable?: boolean;
}
export const DateComponent: React.FC<Props> = (props) => {
    const month = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
    const [dates, setDates] = useState<any>([]);
    const [years, setYears] = useState<any>([]);
    const [canShowDate, setCanShowDate] = useState<any>();
    const [canShowMonth, setCanShowMonth] = useState<any>();
    const [canShowYear, setCanShowYear] = useState<any>();

    useEffect(() => {
        const now = new Date().getUTCFullYear();
        let yearStart = now - (props?.yearDiff || 0);
        const year = (yearStart - (props?.yearDiff === -30 ? props?.yearDiff : props?.yearDiff === 10 ? props?.yearDiff : 60));
        const years = Array(yearStart > year ? (props?.yearDiff === 10 ? (yearStart - (year + 1)) : yearStart - year) : (year - yearStart) + 1).fill('').map((v, idx) => yearStart - idx);
        const dates = [];
        for (let index = 1; index <= 31; index++) {
            dates.push(index <= 9 ? `0${index}` : index)
        }
        setDates(dates);
        setYears(years);
        if (props?.yearDiff === -30) {
            const preparedYears = years.sort();
            setYears(preparedYears);
        }
        if (props?.defaultData) {
            setCanShowDate(moment(props?.defaultData).format('DD'));
            setCanShowMonth(moment(props?.defaultData).format('MM'));
            setCanShowYear(moment(props?.defaultData).format('YYYY'));
        }
    }, [])

    const handleDateofBirthChange = (e: any, type: any) => {
        let date = canShowDate;
        let month = canShowMonth;
        let year = canShowYear;
        if (type === 'day') {
            setCanShowDate(e.target.value);
            date = e.target.value;
        } else if (type === 'month') {
            setCanShowMonth(e.target.value);
            month = e.target.value;

        } else if (type === 'year') {
            setCanShowYear(e.target.value);
            year = e.target.value;
        }
        const completeDate = `${date ? date : '00'}-${month ? month : '00'}-${year ? year : '0000'}`;
        const someday = moment(completeDate, 'DD-MM-YYYY', true);
        let error = '';
        const daysInMonth = moment(moment(`${year}-${month}`), "YYYY-MM").daysInMonth();
        const isMonthDays = daysInMonth === NaN ? false : true;
        if ((daysInMonth <= Number(date) && type !== 'day')) {
            setCanShowDate('');
        }
        if (isMonthDays && month) {
            const dates = [];
            for (let index = 1; index <= daysInMonth; index++) {
                dates.push(index <= 9 ? `0${index}` : index)
            }
            setDates(dates);
        }
        const newDate = completeDate.split('-');
        const diff = someday.isValid();

        if (newDate[0] === '00' && newDate[1] === '00' && newDate[2] === '0000') {
            error = 'Please select date';
        } else if (newDate[0] !== '00' && newDate[1] === '00' && newDate[2] === '0000') {
            error = 'Please select month and year';
        } else if (newDate[0] === '00' && newDate[1] !== '00' && newDate[2] === '0000') {
            error = 'Please select date and year';
        } else if (newDate[0] === '00' && newDate[1] === '00' && newDate[2] !== '0000') {
            error = 'Please select date and month';
        } else if (newDate[0] === '00' && newDate[1] !== '00' && newDate[2] !== '0000') {
            error = 'Please select date';
        } else if (newDate[0] !== '00' && newDate[1] === '00' && newDate[2] !== '0000') {
            error = 'Please select month';
        } else if (newDate[0] !== '00' && newDate[1] !== '00' && newDate[2] === '0000') {
            error = 'Please select year';
        } else if (!diff && newDate[2] !== '0000') {
            error = 'Invalid Date';
        }
        const dateObj = {
            isValid: diff,
            error,
            date: moment(completeDate, "DD-MM-YYYY")
        }
        props.onDate(dateObj);
    }

    return (
        <div className='mt-3'>
            <select name="day" value={canShowDate} disabled={props?.idDisable} style={{ marginLeft: '0px' }} className="custom-dob form-select" onChange={(e) => { handleDateofBirthChange(e, 'day') }}>
                <option value="">Day</option>
                {dates.map((name: any, i: number) => (
                    <option key={i} value={name}>
                        {name}
                    </option>
                ))}
            </select>
            <select name="month" value={canShowMonth} disabled={props?.idDisable} className="custom-dob form-select" onChange={(e) => { handleDateofBirthChange(e, 'month') }}>
                <option value="">Month</option>
                {month.map((name: any, i: number) => (
                    <option key={i} value={name}>
                        {name}
                    </option>
                ))}
            </select>
            <select name="year" value={canShowYear} disabled={props?.idDisable} className="custom-dob form-select" onChange={(e) => { handleDateofBirthChange(e, 'year') }}>
                <option value="">Year</option>
                {years.map((name: any, i: number) => (
                    <option key={i} value={name}>
                        {name}
                    </option>
                ))}
            </select>
        </div>
    )
}
