import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Link, NavLink, useParams } from "react-router-dom";
import LogoImg from "../../assets/images/iamsamarth-Logo-Final.svg";
import { InstituteDashBoardRoutes } from "../../routes/institute-dashboard";
import { toast } from 'react-toastify';
import { ParentDashBoardRoutes } from "../../routes/parent-dashboard/parent-dashboard";


function ParentDashboardView() {
    const [canShowPopup, setCanShowPopup] = useState(false);
    const history = useHistory();
    const userName = localStorage.getItem("user_name");
    const userRole = localStorage.getItem("user_role");
    const [toggleIcon, setToggleIcon] = useState<any>("nav_toggler");
    const [header, setHeader] = useState<any>("header");
    const [body, setBody] = useState<any>("inside-bg");
    const [nav, setNav] = useState<string>("l-navbar");
    const [icon, setIcon] = useState<string>("bx bx-menus");
    const schoolCode = localStorage.getItem("institute_code");


    useEffect(() => {


    }, []);
    const navToggle = (): void => {
        toggleIcon === "nav_toggler" ? setToggleIcon("nav_toggler toggle") : setToggleIcon("nav_toggler");
        body === "inside-bg" ? setBody("body-pd") : setBody("inside-bg");
        header === "header" ? setHeader("header body-pd") : setHeader("header");
        nav === "l-navbar" ? setNav("l-navbar show") : setNav("l-navbar");
        icon === "bx bx-menus" ? setIcon("bx bx-x") : setIcon("bx bx-menus");

    }



    const handleclose = () => {
        setCanShowPopup(false);
    };

    const handleSubmit = () => {
        setCanShowPopup(false);
        history.push("/home");
        localStorage.clear();
    };

    function onClickHome() {
        setCanShowPopup(true);
    }
    useEffect(() => {

    }, []);
    return (
        <div>
            <body className={body}>
                <div
                    className={`modal fade ${canShowPopup ? "show" : "false"}`}
                    style={{ display: canShowPopup ? "block" : "none" }}
                    role="dialog"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                >
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">
                                    Are You Sure Want To Logout
                                </h5>
                                <button
                                    type="button"
                                    className="close popup-close cursor-pointer"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                    onClick={handleclose}
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-primary cursor-pointer"
                                    onClick={handleSubmit}
                                >
                                    Yes
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-secondary cursor-pointer"
                                    data-dismiss="modal"
                                    onClick={handleclose}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <header className={header} id="header">
                    <div className="header_toggle" onClick={navToggle}> <i className={icon} id="header-toggle"></i> </div>
                    <a href="#" className="nav_logo mb-0 d-lg-none"><i className="nav_logo-icon"></i></a>
                    <div className="me-md-auto ms-md-3 d-none d-lg-block"> <span className="f18 fw-light">Parent Dashboard</span> </div>
                    <div className="d-none d-lg-flex align-items-center ">
                        <span className="text-end user-name pe-3">
                            {userName}
                            <br />
                            <small>{userRole}</small>
                        </span>
                        <a className="mx-3 logout-icon cursor-pointer" onClick={() => {
                            onClickHome();
                        }}>Sign out</a>
                    </div>
                </header>
                <div className={nav} id="nav-bar">
                    <nav className="navinstitute">
                        <div> <a className="nav_logo"> <i className="nav_logo-icon"></i> </a>
                            <div className="nav_list">
                                <NavLink to='/parent-dashboard/view' className="nav_link"> <i className="dashboard-icon nav-icon"></i><span className="nav_name">Dashboard</span></NavLink>

                            </div>
                        </div>
                        <div className="nav_link d-lg-none mb-4" onClick={() => { onClickHome(); }}>
                            <i className="bx bx-log-out nav-icon"></i>
                            <span className="nav_name ms-2 ">SignOut</span>
                        </div>
                    </nav>
                </div>
                <div className="content-body">
                    <div className="container-fluid">
                        <div className="main-title ">

                            <ParentDashBoardRoutes />
                        </div>
                    </div>
                </div>
            </body>
        </div>
    );
}
export default ParentDashboardView;
