import React from 'react'
import { Link } from 'react-router-dom'

export const UsersInfo = () =>{
    return(
        <div>
            <h2>Users Info</h2>
            <div className="">
                <ul className="nav nav-tabs custom-tabs" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                    <button className="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">Admin</button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">Teacher</button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact" type="button" role="tab" aria-controls="contact" aria-selected="false">Student</button>
                </li>
                </ul>
                <div className="tab-content" id="myTabContent">
                <div className="tab-pane fade show active py-2" id="home" role="tabpanel" aria-labelledby="home-tab">Admin information</div>
                <div className="tab-pane fade py-2" id="profile" role="tabpanel" aria-labelledby="profile-tab">Teachers information</div>
                <div className="tab-pane fade py-2" id="contact" role="tabpanel" aria-labelledby="contact-tab">Students information</div>
                </div>
            </div>
            <div className="border-primary py-3 text-end">
               <Link to="/dashboard/users" className="btn btn-primary btn-sm px-5 rounded-12">Back</Link>
            </div>
        </div>
    )
}