import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { GET_MNS_DETAILS, GET_MNS_STUDENT_STATUS } from '../../../app/service/mns-service';

export const MNSTestLevelStatusPage = () => {
    const [assesmentTestStatus, setAssesmentTestStatus] = useState<any>();
    const [MNSDetails, setMNSDetails] = useState<any>([]);
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    let { moduleUUID, userCode, schoolCode } = useParams<{ moduleUUID: string, userCode: string, schoolCode: string }>();
    const modules_uuid = moduleUUID;
    const user_code = userCode;
    const school_code = schoolCode;

    useEffect(() => {
        getMNSStudentStatus();
        getMNSDetails();
    }, []);

    function getMNSDetails() {
        GET_MNS_DETAILS().then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res.message);
            } else {
                if (res) {
                    const data = res;
                    setMNSDetails(data);
                }
            }
        });
    }

    function getMNSStudentStatus() {
        let payload = {
            module_uuid: modules_uuid,
            school_code: school_code,
            user_code: user_code
        }
        setLoading(true);
        GET_MNS_STUDENT_STATUS(payload).then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res.message);
                setLoading(false);
            } else {
                if (res) {
                    const data = res;
                    let status = Array.isArray(data.assesment_test) ? data.assesment_test.length > 0 : Object.keys(data.assesment_test).length !== 0;
                    if (status) {
                        history.push(`/dashboard/mns/dashboard/${modules_uuid}/${user_code}/${school_code}`);
                    } else {
                        history.push(`/dashboard/mns/test-level-status/${modules_uuid}/${user_code}/${school_code}`);
                    }
                    setAssesmentTestStatus(status);
                    setLoading(false);
                }
            }
        });
    }

    function onClickEntryTest() {
        history.push(`/dashboard/mns/questions/list/${modules_uuid}/${user_code}/${school_code}`);
    }

    const handleBack = () => {
        history.goBack();
    }

    return (
        <div>
            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading &&
                <div>
                    <div className='row'>
                        <div className='col-md-10'></div>
                        <div className='col-md-2 mt-2'>
                            <button className="btn btn-primary btn-sm px-5 rounded-12 cursor-pointer float-right" onClick={handleBack}>Back</button>
                        </div>
                    </div>
                    <section className="mb-4 mt-4 px-3 px-md-0 text-center">
                        <div className="bg-white rounded-16 container">
                            <div className="row align-items-center">
                                <div className="col-md-12 ps-lg-5">
                                    <div className="mb-0">
                                        <span className='fw-bold' style={{ fontSize: '40px' }}>MNS</span>
                                        <span className='px-3'>(Minimum Numerical Skills)</span>
                                    </div>
                                    <div className='pt-4'>
                                        <h5 className="m-0">Introduction</h5>
                                        <span className="m-0">About MNS</span>
                                    </div>
                                    <div className='pt-4'>
                                        <h5 className="m-0">Instructions</h5>
                                        <span className="m-0">Details on how to navigate MNS assessments</span>
                                    </div>
                                    <div className='pt-4'>
                                        {MNSDetails.map((detail: any, i: number) => (
                                            <p key={i}>{i + 1}.{detail}</p>
                                        ))}
                                    </div>
                                    <div className='pt-5'>
                                        <button className='start-now-btn' onClick={() => { onClickEntryTest() }}>
                                            <span>Begin Entry Test</span>
                                            <span style={{ paddingLeft: '1rem' }}>
                                                <i className="bi bi-arrow-right"></i>
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            }
        </div>
    )
}