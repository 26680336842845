import React, { useEffect, useRef, useState } from "react";
import FormBuilder from "../../../components/form-builder";
import { Link, useHistory, useLocation } from "react-router-dom";
import { DataTable } from "../../../components/data-table";
import { SponsorDataGridCols } from './data-grid-cols'
import { DELETE_SPONSOR, FECTH_SPONSORS_LIST, GET_SPONSORS_SEARCH } from "../../../app/service/sponsor";
import { toast } from "react-toastify";
import moment from "moment";

export const SopnsorList = () => {
    const [searchData, setSearchData] = useState<any>({});
    const sponserForm = useRef<any>({});
    const [loading, setLoading] = useState(false);
    const [sponserList, setSponserList] = useState<any>([]);
    const [activePage, setActivePage] = useState<any>();
    const history = useHistory();
    const [pageArray, setPageNumbers] = useState([]);
    const search = useLocation().search;
    const currentPageNumber: any = new URLSearchParams(search).get('page');
    const [preparedSearchData, setPreparedSearchData] = useState<any>({})

    useEffect(() => {
        getSponsorsList();

    }, []);
    function getSponsorsList() {
        const activePageNumber = currentPageNumber > 0 ? (currentPageNumber - 1) : 0;
        setLoading(true);
        FECTH_SPONSORS_LIST(activePageNumber, {}).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                }
                else {
                    const data = res.rows;
                    data.forEach((element: any) => {
                        if (element.expiry_dt) {
                            if (new Date(element?.expiry_dt) < new Date()) {
                                element.linkExpired = "expired"
                            } else {
                                element.linkExpired = "not-expired"
                            }
                            element.expiry_dt = moment(element.expiry_dt.split("T")[0]).format('DD-MM-yyyy');
                        }
                        const urlElement = element?.payment_link?.split('?uuid=');
                        if (urlElement?.length === 2) {
                            element.payment_link = urlElement[0] + '/' + urlElement[1];
                        } else {
                            element.payment_link = element?.payment_link;
                        }
                    });

                    setSponserList(data);
                    const pageNumbers = Math.ceil(res.count / 10);
                    const pageArray: any = [];
                    for (let i = 1; i <= pageNumbers; i++) {
                        pageArray.push(i);
                    }
                    setPageNumbers(pageArray);
                    setLoading(false);
                }
            }

        });

    }

    function handleSearch() {
        const data = { ...searchData.value };
        if (Object.keys(data).length > 0) {
            data.search = data.search.trim();

            if (data) {
                for (const key of Object.keys(data)) {
                    if (data[key] === "") {
                        delete data[key];
                    }
                }
                setPreparedSearchData(data);
                setLoading(true);

                GET_SPONSORS_SEARCH(data?.search).then((res: any) => {
                    if (res.status === 'fail') {
                        toast.error(res.message);
                        setLoading(false);
                    } else {
                        history.push(`/dashboard/sponsor?page=${1}`);
                        setActivePage(1);
                        const data = res.rows;
                        data.forEach((element: any) => {
                            if (element.expiry_dt) {
                                if (new Date(element?.expiry_dt) < new Date()) {
                                    element.linkExpired = "expired"
                                } else {
                                    element.linkExpired = "not-expired"
                                }
                                element.expiry_dt = moment(element.expiry_dt.split("T")[0]).format('DD-MM-yyyy');
                            }
                        });
                        setSponserList(data);
                        const pageNumbers = Math.ceil(res.count / 10);
                        const pageArray: any = [];
                        for (let i = 1; i <= pageNumbers; i++) {
                            pageArray.push(i);
                        }
                        setPageNumbers(pageArray);
                        setLoading(false);
                    }
                });

            }
        }
    }
    const handleInput = (data: any) => {
        setSearchData(data);
    };
    function handleRefresh() {
        const data = searchData;
        data.value = {};
        setSearchData({ ...data });
        getSponsorsList();
        setPreparedSearchData({});
        sponserForm.current.reset();

    }
    const onDeleteSponser = (data: any) => {
        setLoading(true);
        const id = data.uuid;
        DELETE_SPONSOR(id).then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res.message);
                setLoading(false);
            } else {
                setLoading(false);
                toast.success("Sponsor Deleted Successfully");
                getSponsorsList();

            }
        });
    }
    const onPageChange = (data: any) => {

        if (data) {
            history.push(`/dashboard/sponsor?page=${data}`);
        }
        setActivePage(data);
        const pageNumber = data - 1;
        setLoading(true);
        FECTH_SPONSORS_LIST(pageNumber, preparedSearchData ? preparedSearchData : {}).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                }
                else {
                    const data = res.rows;
                    data.forEach((element: any) => {
                        if (element.expiry_dt) {

                            if (new Date(element?.expiry_dt) < new Date()) {
                                element.linkExpired = "expired"
                            } else {
                                element.linkExpired = "not-expired"
                            }
                            element.expiry_dt = moment(element.expiry_dt.split("T")[0]).format('DD-MM-yyyy');
                        }
                    });
                    setSponserList(data);
                    const pageNumbers = Math.ceil(res.count / 10);
                    const pageArray: any = [];
                    for (let i = 1; i <= pageNumbers; i++) {
                        pageArray.push(i);
                    }
                    setPageNumbers(pageArray);
                    setLoading(false);
                }
            }

        });

    }
    const onEditSponsor = (data: any) => {
        history.push(`/dashboard/sponsor/form/${data.uuid}`);
    }
    return (
        <div>
            <div className="row border-top border-primary py-3">
                <div className="row">
                    <div className="col-md-10 col-7">
                        <h2 className='mt-1 mt-md-0'>Sponsor</h2>
                    </div>
                    <div className="border-primary py-md-3 py-1 text-end col-md-2 col-5 ps-4 ps-md-0">
                        <Link to={`/dashboard/sponsor/form/0`} className="btn btn-primary btn-sm px-5 rounded-12">New</Link>
                    </div>
                </div>
                <div className="row" style={{ marginBottom: '15px' }}>
                    <div className="col-md-3 my-3 my-md-0">
                        <FormBuilder onUpdate={handleInput}>
                            <form ref={sponserForm}>
                                <div className="row">
                                    <div className='col-md-12'>
                                        <div className='row'>
                                            <div className="col-md-12">
                                                <input type="text" className="form-control ft-14" placeholder="Search with sponsor name" name="search" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </FormBuilder>
                    </div>
                    <div className="col-md-4  text-end text-md-start">
                        <button className="btn btn-primary px-3 cursor-pointer" type="button" onClick={() => handleSearch()}>Search</button>&nbsp;
                        <button className="btn clear-btn cursor-pointer" type="button" onClick={() => handleRefresh()}>
                            Clear
                        </button>
                    </div>
                </div>
                {loading &&
                    <div className="text-center p-5">
                        <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                }
                {!loading && <DataTable TableCols={SponsorDataGridCols} tableData={sponserList} deleteInfo={onDeleteSponser}
                    editInfo={onEditSponsor}
                    activePageNumber={activePage} pageNumber={onPageChange} pageNumbers={pageArray}></DataTable>}
            </div>
        </div>
    )
}