import React, { useEffect, useState } from "react";
import { MultiSelect } from "react-multi-select-component";
import {
  Link,
  NavLink,
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom";
import { SchoolListItem } from "../../../app/model/school/school-list";
import { GET_SCHOOL_BY_ID } from "../../../app/service/school.service";
import appConfig from "../../../config/constant";
import NestedDashboard from "../../../routes/insidedashboard";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { DateComponent } from "../../../components/date";
import { toast } from "react-toastify";
import { Breadcum } from "../../../components/breadcum";
import InstituteNestedDashboard from "../../../routes/institute-insidedashboard";

export const SchoolInFo = () => {
  const history = useHistory();
  const [isActive, setIsActive] = useState(true);
  const [schoolInfo, setSchoolInfo] = useState<any>({});
  const [schoolCode, setSchoolCode] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [canShowPopup, setCanShowPopup] = useState(false);
  const [schoolLogo, setSchoolLogo] = useState<any>();
  const [userRole, setUserRole] = useState<any>("");
  const [mediumSelected, setMediumSelected] = useState([]);
  const [todayDate, setTodayDate] = useState(moment().format("YYYY-MM-DD"));
  const [breadcumInfo, setBreadcumInfo] = useState<any>([
    {
      label: "Schools",
      value: "Schools",
      routerLink: "/dashboard/school/list",
      isActive: false,
    },
    {
      label: "School Name",
      value: "",
      routerLink: "",
      isActive: false,
    },
  ]);
  let { id } = useParams<{ id: string }>();
  const schoolId = Number(id);
  const location = useLocation();
  const [accessPrincipal, setAccessPrincipal] = useState(false);
  const [vicePrincipal, setVicePrincipal] = useState(false);
  const [accessSchoolAdmin, setAccessSchooolAdmin] = useState(false);
  const [schoolTeacher, setSchoolTeacher] = useState(false);
  const [schoolStudents, setSchoolStudents] = useState(false);
  const [schoolStudentsCount, setSchoolStudentsCount] = useState(false);
  const [schoolModule, setSchoolModule] = useState(false);
  const [payment, setPayment] = useState(false);
  const [viewMoreInfo, setViewMoreInfo] = useState(false);
  // const queryParams = new URLSearchParams(location.hash);
  //destructuring pathname from location
  const { pathname } = location;

  //Javascript split method to get the name of the path in array
  const splitLocation = pathname.split("/");

  const mediumType = [
    { label: "Assamese", value: "Assamese" },
    { label: "Bengali", value: "Bengali" },
    { label: "Bodo", value: "Bodo" },
    { label: "Dogri", value: "Dogri" },
    { label: "English", value: "English" },
    { label: "Gujarati ", value: "Gujarati" },
    { label: "Hindi", value: "Hindi" },
    { label: "Kannada", value: "Kannada" },
    { label: "Kashmiri", value: "Kashmiri" },
    { label: "Konkani", value: "Konkani" },
    { label: "Maithili", value: "Maithili" },
    { label: "Malayalam", value: "Malayalam" },
    { label: "Manipuri ", value: "Manipuri" },
    { label: "Marathi", value: "Marathi" },
    { label: "Nepali", value: "Nepali" },
    { label: "Odia", value: "Odia" },
    { label: "Punjabi", value: "Punjabi" },
    { label: "Sanskrit", value: "Sanskrit" },
    { label: "Santali", value: "Santali" },
    { label: "Sindhi ", value: "Sindhi" },
    { label: "Tamil", value: "Tamil" },
    { label: "Telugu ", value: "Telugu" },
    { label: "Urdu", value: "Urdu" },
  ];

  useEffect(() => {

    const userRole = localStorage.getItem("user_role");
    setUserRole(userRole);
    for (let i = 0; i < breadcumInfo.length; i++) {
      const element = breadcumInfo[i];
      if (userRole === "CHAIRMAN" || userRole === "GENERAL_SECRETARY" || userRole === "INSTITUTE_ADMIN") {
        element.routerLink = "/institute-dashboard/school/list";
      } else {
        element.routerLink = "/dashboard/school/list";
      }

    }
    setLoading(true);
    if (!isNaN(schoolId)) {
      GET_SCHOOL_BY_ID(schoolId).then((res: any) => {
        if (res.status === "fail") {
          toast.error(res.message);
          setLoading(false);
        } else {
          if (res) {
            const data = res.rows[0];
            setSchoolInfo(data);
            getBreadcumList(data);
            setLoading(false);
            setSchoolCode(data.school_code);
            if (data.logo_url) {
              setSchoolLogo(data.logo_url);
            }
            if (data.medium) {
              setMediumSelected(data.medium);
            }
            if (data.date_of_registration) {
              const date_of_registration: any = new Date(
                data.date_of_registration
              );
              setTodayDate(date_of_registration);
            }
          }
        }
      });
    }

    const accessObjectsData: any = localStorage.getItem("access_objects_data");
    onHandleNav(JSON.parse(accessObjectsData));
  }, [schoolId]);



  function getBreadcumList(data: any) {
    breadcumInfo.splice(1, breadcumInfo.length - 1, {
      label: "School Name",
      value: data.name_of_school,
      isActive: true,
    });
    setBreadcumInfo(breadcumInfo);
  }

  function onClickViewDetail() {
    setCanShowPopup(true);
  }

  const handleclose = () => {
    setCanShowPopup(false);
  };

  const handleBack = () => {
    history.push("/dashboard/school/list");
  };

  function onHandleNav(accessObjectsList: any) {
    const preparedData = accessObjectsList?.filter(
      (el: any) => el.object_code === "SCHOOLS"
    );
    if (preparedData?.length > 0) {
      for (let i = 0; i < preparedData[0].child.length; i++) {
        if (
          preparedData[0].child[i].object_code === "SCHOOLS-PRINCIPAL" &&
          preparedData[0].child[i].p_read === 1
        ) {
          setAccessPrincipal(true);
        }

        if (
          preparedData[0].child[i].object_code === "SCHOOLS-VICE-PRINCIPAL" &&
          preparedData[0].child[i].p_read === 1
        ) {
          setVicePrincipal(true);
        }

        if (
          preparedData[0].child[i].object_code === "SCHOOLS-SCHOOL-ADMIN" &&
          preparedData[0].child[i].p_read === 1
        ) {
          setAccessSchooolAdmin(true);
        }

        if (
          preparedData[0].child[i].object_code === "SCHOOLS-TEACHER" &&
          preparedData[0].child[i].p_read === 1
        ) {
          setSchoolTeacher(true);
        }
        if (
          preparedData[0].child[i].object_code === "SCHOOLS-STUDENTS" &&
          preparedData[0].child[i].p_read === 1
        ) {
          setSchoolStudents(true);
        }
        if (
          preparedData[0].child[i].object_code === "SCHOOLS-STUDENTS-COUNT" &&
          preparedData[0].child[i].p_read === 1
        ) {
          setSchoolStudentsCount(true);
        }
        if (
          preparedData[0].child[i].object_code === "SCHOOLS-MODULE" &&
          preparedData[0].child[i].p_read === 1
        ) {
          setSchoolModule(true);
        }
        if (
          preparedData[0].child[i].object_code === "SCHOOLS-PAYMENT" &&
          preparedData[0].child[i].p_read === 1
        ) {
          setPayment(true);
        }
        if (
          preparedData[0].child[i].object_code ===
          "SCHOOLS-VIEW-MORE-INFORMATION" &&
          preparedData[0].child[i].p_read === 1
        ) {
          setViewMoreInfo(true);
        }
      }
    }
  }

  return (
    <div className="row border-top border-primary py-3">
      {loading && (
        <div className="text-center p-5">
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}

      <div
        className={`modal fade ${canShowPopup ? "show" : "false"}`}
        style={{ display: canShowPopup ? "block" : "none" }}
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog parent-model-dialog" role="document">
          <div className="modal-content parent-model">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                School Details
              </h5>
              <button
                type="button"
                className="close popup-close cursor-pointer"
                data-dismiss="modal"
                aria-label="Close"
                onClick={handleclose}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body parent-info">
              <div className="row custom-form">
                <div className="col-md-6">
                  <div className="mb-4">
                    <label className="form-label mb-0">
                      Name of Institute
                    </label>
                    <select
                      name="organization_code"
                      className="form-select form-select-lg mb-3"
                      disabled
                    >
                      <option value="">{schoolInfo?.name_of_organization}</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-4">
                    <label className="form-label mb-0">Short form Name</label>
                    <input
                      className="form-control form-control-lg"
                      type="text"
                      name="short_name"
                      disabled
                      defaultValue={schoolInfo?.org_short_name}
                      placeholder="Short form of name"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-4">
                    <label className="form-label mb-0">
                      Name of School /College /Organisation
                    </label>
                    <input
                      className="form-control form-control-lg"
                      type="text"
                      name="name_of_school"
                      disabled
                      defaultValue={schoolInfo?.name_of_school}
                      placeholder="Name of organization"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-4">
                    <label className="form-label mb-0">Address</label>
                    <input
                      className="form-control form-control-lg"
                      type="text"
                      name="address"
                      disabled
                      defaultValue={schoolInfo?.address}
                      placeholder="Official address"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-4">
                    <label className="form-label mb-0">
                      Contact Number ( Mobile / Landline)
                    </label>
                    <input
                      className="form-control form-control-lg"
                      type="number"
                      name="contact_number"
                      disabled
                      defaultValue={schoolInfo?.contact_number}
                      placeholder="Mobile/Landline"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-4">
                    <label className="form-label mb-0">
                      Date of Registration
                    </label>
                    <DateComponent
                      idDisable={true}
                      defaultData={todayDate}
                      yearDiff={0}
                    ></DateComponent>

                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-4">
                    <label className="form-label mb-0">State</label>
                    <select
                      name="state"
                      className="form-select form-select-lg mb-3"
                      disabled
                    >
                      <option value="">{schoolInfo?.state}</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-4">
                    <label className="form-label mb-0">District</label>
                    <select
                      name="district"
                      className="form-select form-select-lg mb-3"
                      disabled
                    >
                      <option value="">{schoolInfo?.district}</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-4">
                    <label className="form-label mb-0">Block</label>
                    <input
                      className="form-control form-control-lg"
                      type="text"
                      disabled
                      name="taluka"
                      defaultValue={schoolInfo?.taluka}
                    />

                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-4">
                    <label className="form-label mb-0">
                      City/Village (Optional)
                    </label>
                    <input
                      className="form-control form-control-lg"
                      type="text"
                      disabled
                      name="city_village"
                      defaultValue={schoolInfo?.city_village}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-4">
                    <label className="form-label mb-0">PIN Code</label>
                    <input
                      className="form-control form-control-lg"
                      type="number"
                      disabled
                      name="pin_code"
                      defaultValue={schoolInfo?.pin_code}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-4">
                    <label className="form-label mb-0">Email ID</label>
                    <input
                      className="form-control form-control-lg"
                      type="text"
                      disabled
                      name="email_id"
                      defaultValue={schoolInfo?.email_id}
                      placeholder="Please enter email"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-4">
                    <label className="form-label mb-0">Fax Number</label>
                    <input
                      className="form-control form-control-lg"
                      type="text"
                      disabled
                      name="fax_number"
                      defaultValue={schoolInfo?.fax_number}
                      placeholder="Please enter fax number"
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="mb-4">
                    <label className="form-label mb-0">UDISE Code</label>
                    <input
                      className="form-control form-control-lg"
                      type="text"
                      disabled
                      name="udise_code"
                      defaultValue={schoolInfo?.udise_code}
                      placeholder="Please enter UDISE code"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-4">
                    <label className="form-label mb-0">School Timings</label>
                    <input
                      className="form-control form-control-lg"
                      type="text"
                      disabled
                      name="timings"
                      defaultValue={schoolInfo?.timings}
                      placeholder="Please enter timings"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-4">
                    <label className="form-label mb-0">Cluster Code</label>
                    <input
                      className="form-control form-control-lg"
                      type="text"
                      disabled
                      name="cluster_code"
                      defaultValue={schoolInfo?.cluster_code}
                      placeholder="Please enter cluster code"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-4">
                    <label className="form-label mb-0">Curriculum </label>
                    <select
                      className="form-select form-select-lg mb-3"
                      disabled
                      name="curriculum"
                    >
                      <option value="">{schoolInfo?.curriculum}</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-4">
                    <label className="form-label mb-0">Category</label>
                    <select
                      className="form-select form-select-lg mb-3"
                      disabled
                      name="category"
                    >
                      <option value="">{schoolInfo?.cluster_code}</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-4">
                    <label className="form-label mb-0">
                      Medium of Instruction
                    </label>
                    <MultiSelect
                      disabled
                      options={mediumType}
                      value={mediumSelected}
                      labelledBy="Select"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-4">
                    <label className="form-label mb-0">
                      Registration Number (Optional)
                    </label>
                    <input
                      className="form-control form-control-lg"
                      type="text"
                      disabled
                      name="registration_number"
                      defaultValue={schoolInfo?.registration_number}
                      placeholder="Please enter registration number"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-4">
                    <label className="form-label mb-0">
                      Website address (Optional)
                    </label>
                    <input
                      className="form-control form-control-lg"
                      type="text"
                      disabled
                      name="website_url"
                      defaultValue={schoolInfo?.website_url}
                      placeholder="Please enter website"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-4">
                    <label className="form-label mb-0">
                      GST Number (Optional)
                    </label>
                    <input
                      className="form-control form-control-lg"
                      type="text"
                      disabled
                      name="gst_number"
                      defaultValue={schoolInfo?.gst_number}
                      placeholder="Please enter GST number"
                    />
                  </div>
                </div>
                {schoolLogo && (
                  <div className="col-md-4">
                    <div className="mb-4">
                      <label className="form-label mb-0 d-block">
                        Preview Logo
                      </label>
                      <img
                        src={`${appConfig.FILE_UPLOAD_URL}${schoolLogo.charAt(0) === "/" ? schoolLogo.substring(1) : schoolLogo}`}
                        style={{
                          width: "100px",
                          height: "100px",
                          objectFit: "contain",
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary cursor-pointer"
                data-dismiss="modal"
                onClick={handleclose}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
      {!loading && (
        <div>
          <div>
            {(userRole === "INSTITUTE_ADMIN" ||
              userRole === "SUPER_ADMIN" ||
              userRole === "CHAIRMAN" ||
              userRole === "GENERAL_SECRETARY" ||
              userRole === "FULL_TIME_MENTOR") && (
                <Breadcum breadcumList={breadcumInfo} />
              )}
            <div className="row">
              <div className="col-md-10">
                <h2>School Details</h2>
              </div>

            </div>
            <div className="mar-t-20 custom-scroll table-responsive">
              <table className="table fixed-table-header mb-0">
                <thead>
                  <tr>
                    <th>School Name</th>
                    <th>School UDISE</th>
                    <th>Mobile Number</th>
                    <th>Email ID</th>
                    <th>City</th>
                    <th>State</th>
                    {viewMoreInfo && <th>Action</th>}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{schoolInfo.name_of_school}</td>
                    <td>{schoolInfo.udise_code}</td>
                    <td>{schoolInfo.contact_number}</td>
                    <td>{schoolInfo.email_id}</td>
                    <td>{schoolInfo.taluka}</td>
                    <td>{schoolInfo.state}</td>
                    {viewMoreInfo && (
                      <td>
                        <button
                          className="file btn btn-sm btn-primary px-4 rounded-12 d-inline-block cursor-pointer"
                          onClick={() => onClickViewDetail()}
                        >
                          View More Info
                        </button>
                      </td>
                    )}
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="mt-5">
            {userRole !== "CHAIRMAN" && userRole !== "GENERAL_SECRETARY" && userRole !== "INSTITUTE_ADMIN" &&
              <ul className="nav nav-tabs custom-tabs scrolling_nav">

                {userRole !== "VICE_PRINCIPAL" &&
                  userRole !== "TEACHER" &&
                  userRole !== "FULL_TIME_MENTOR" && (
                    <li className="nav-item ">
                      {accessPrincipal && (
                        <NavLink
                          className={
                            splitLocation[5] === "principalform"
                              ? "nav-link active"
                              : "nav-link"
                          }
                          to={{
                            pathname: `/dashboard/school/info/${schoolId}/principallist/${schoolCode}`,
                            state: { data: "" },
                          }}
                        >
                          Principal
                        </NavLink>
                      )}
                    </li>
                  )}
                {userRole !== "TEACHER" && userRole !== "FULL_TIME_MENTOR" && (
                  <li className="nav-item">
                    {vicePrincipal && (
                      <NavLink
                        className={
                          splitLocation[5] == "viceprincipalform"
                            ? "nav-link active"
                            : "nav-link"
                        }
                        to={{
                          pathname: `/dashboard/school/info/${schoolId}/viceprincipallist/${schoolCode}`,
                          state: { data: "" },
                        }}
                      >
                        Vice Principal
                      </NavLink>
                    )}
                  </li>
                )}
                {userRole !== "TEACHER" && userRole !== "FULL_TIME_MENTOR" && (
                  <li className="nav-item">
                    {accessSchoolAdmin && (
                      <NavLink
                        className={
                          splitLocation[5] == "adminform"
                            ? "nav-link active"
                            : "nav-link"
                        }
                        to={{
                          pathname: `/dashboard/school/info/${schoolId}/adminlist/${schoolCode}`,
                          state: { data: "" },
                        }}
                      >
                        School Admin
                      </NavLink>
                    )}
                  </li>
                )}
                {userRole !== "TEACHER" && userRole !== "FULL_TIME_MENTOR" && (
                  <li className="nav-item">
                    {schoolTeacher && (
                      <NavLink
                        className={
                          splitLocation[5] == "teachersform"
                            ? "nav-link active"
                            : "nav-link"
                        }
                        to={{
                          pathname: `/dashboard/school/info/${schoolId}/teacherslist/${schoolCode}`,
                          state: { data: "" },
                        }}
                      >
                        Teacher
                      </NavLink>
                    )}
                  </li>
                )}
                <li className="nav-item">
                  {schoolStudents && (
                    <NavLink
                      className={
                        splitLocation[5] == "studentsform"
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to={{
                        pathname: `/dashboard/school/info/${schoolId}/studentslist/${schoolCode}`,
                        state: { data: "" },
                      }}
                    >
                      Students
                    </NavLink>
                  )}
                </li>
                <li className="nav-item">
                  {schoolStudentsCount && (
                    <NavLink
                      className={
                        splitLocation[5] == "studentcountform"
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to={{
                        pathname: `/dashboard/school/info/${schoolId}/student-count-list/${schoolCode}`,
                        state: { data: "" },
                      }}
                    >
                      Students Count
                    </NavLink>
                  )}
                </li>
                <li className="nav-item">
                  {schoolModule && (
                    <NavLink
                      className={
                        splitLocation[5] == "module"
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to={{
                        pathname: `/dashboard/school/info/${schoolId}/modulelist/${schoolCode}`,
                        state: { data: "" },
                      }}
                    >
                      Module
                    </NavLink>
                  )}
                </li>
                {userRole !== "TEACHER" && (
                  <li className="nav-item">
                    {payment && (
                      <NavLink
                        className={
                          splitLocation[5] == "payment"
                            ? "nav-link active"
                            : "nav-link"
                        }
                        to={{
                          pathname: `/dashboard/school/info/${schoolId}/paymentlist/${schoolCode}`,
                          state: { data: "" },
                        }}
                      >
                        Payment
                      </NavLink>
                    )}
                  </li>
                )}




              </ul>
            }


            {(userRole === "CHAIRMAN" || userRole === "GENERAL_SECRETARY" || userRole === "INSTITUTE_ADMIN") &&
              <ul className="nav nav-tabs custom-tabs scrolling_nav">
                <li className="nav-item ">
                  {accessPrincipal && (
                    <NavLink
                      className={
                        splitLocation[5] === "principalform"
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to={{
                        pathname: `/institute-dashboard/school/info/${schoolId}/principallist/${schoolCode}`,
                        state: { data: "" },
                      }}
                    >
                      Principal
                    </NavLink>
                  )}
                </li>
                <li className="nav-item">
                  {vicePrincipal && (
                    <NavLink
                      className={
                        splitLocation[5] == "viceprincipalform"
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to={{
                        pathname: `/institute-dashboard/school/info/${schoolId}/viceprincipallist/${schoolCode}`,
                        state: { data: "" },
                      }}
                    >
                      Vice Principal
                    </NavLink>
                  )}
                </li>
                <li className="nav-item">
                  {accessSchoolAdmin && (
                    <NavLink
                      className={
                        splitLocation[5] == "adminform"
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to={{
                        pathname: `/institute-dashboard/school/info/${schoolId}/adminlist/${schoolCode}`,
                        state: { data: "" },
                      }}
                    >
                      School Admin
                    </NavLink>
                  )}
                </li>
                <li className="nav-item">
                  {schoolTeacher && (
                    <NavLink
                      className={
                        splitLocation[5] == "teachersform"
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to={{
                        pathname: `/institute-dashboard/school/info/${schoolId}/teacherslist/${schoolCode}`,
                        state: { data: "" },
                      }}
                    >
                      Teacher
                    </NavLink>
                  )}
                </li>
                <li className="nav-item">
                  {schoolStudents && (
                    <NavLink
                      className={
                        splitLocation[5] == "studentsform"
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to={{
                        pathname: `/institute-dashboard/school/info/${schoolId}/studentslist/${schoolCode}`,
                        state: { data: "" },
                      }}
                    >
                      Students
                    </NavLink>
                  )}
                </li>
                <li className="nav-item">
                  {schoolStudentsCount && (
                    <NavLink
                      className={
                        splitLocation[5] == "studentcountform"
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to={{
                        pathname: `/institute-dashboard/school/info/${schoolId}/student-count-list/${schoolCode}`,
                        state: { data: "" },
                      }}
                    >
                      Students Count
                    </NavLink>
                  )}
                </li>
                <li className="nav-item">
                  {schoolModule && (
                    <NavLink
                      className={
                        splitLocation[5] == "module"
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to={{
                        pathname: `/institute-dashboard/school/info/${schoolId}/modulelist/${schoolCode}`,
                        state: { data: "" },
                      }}
                    >
                      Module
                    </NavLink>
                  )}
                </li>
                <li className="nav-item">
                  {payment && (
                    <NavLink
                      className={
                        splitLocation[5] == "payment"
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to={{
                        pathname: `/institute-dashboard/school/info/${schoolId}/paymentlist/${schoolCode}`,
                        state: { data: "" },
                      }}
                    >
                      Payment
                    </NavLink>
                  )}
                </li>

              </ul>}

            <div>
              {(userRole === "CHAIRMAN" || userRole === "GENERAL_SECRETARY" || userRole === "INSTITUTE_ADMIN") &&
                <InstituteNestedDashboard />
              }
              <NestedDashboard />
            </div>
          </div>

        </div>
      )}
    </div>
  );
};
