import API from "../utility/axios";
const apiUrl = "/api/v2";

export const GET_BOARD_LIST = (pageNumber: number, data: any): Promise<any> => {
  return API.get(`${apiUrl}/abhyaas/boards?limit=${10}&page=${pageNumber}`, {
    params: data,
  });
};
export const GET_BOARD_BY_UUID = (Uuid: string): Promise<any> => {
  return API.get(`${apiUrl}/abhyaas/boards?uuid=${Uuid}`);
};

export const UPDATE_BOARD = (data: any, Uuid: string): Promise<any> => {
  return API.put(`${apiUrl}/abhyaas/boards/${Uuid}`, data);
};

export const CREATE_BOARD = (data: any): Promise<any> => {
  return API.post(`${apiUrl}/abhyaas/boards`, data);
};

export const GET_BOARD_FILTER = (data: any): Promise<any> => {
  return API.get(`${apiUrl}/abhyaas/boards?limit=${10}&page=${0}`, {
    params: data,
  });
};
export const DELETE_BOARD = (id: number): Promise<any> => {
  return API.delete(`${apiUrl}/abhyaas/boards/${id}`);
};
