import API from "../utility/axios";
const apiUrl = "/api/v2";

export const FETCH_PARIKSHA_USER_QUESTION_INFO = (data: any): Promise<any> => {
  return API.post(`${apiUrl}/module/pariksha/user/questions/list`, data);
};

export const FETCH_PARIKSHA_USER_QUESTION_PAPER = (data: any): Promise<any> => {
  return API.post(`${apiUrl}/module/pariksha/fetch/paper`, data);
};
export const UPDATE_PARIKSHA_USER_QUESTION_PAPER = (
  data: any
): Promise<any> => {
  return API.post(`${apiUrl}/module/pariksha/user/answers/update`, data);
};
