import React from 'react'
import { Route, Switch } from 'react-router'
import { MLAOneTests } from '../pages/dashboard/modules/mla-one/tests'
import { MLAOneWorksheet } from '../pages/dashboard/modules/mla-one/worksheets'

const NestedMLAOneList = () => {
    return (
        <Switch>
            <Route path="/dashboard/module/info/MLA-1/:code/:id/worksheet" component={MLAOneWorksheet}></Route>
            <Route path="/dashboard/module/info/MLA-1/:code/:id/tests" component={MLAOneTests}></Route>
        </Switch>
    )
}

export default NestedMLAOneList