import React, { useEffect, useRef, useState } from 'react'
import { GET_BOARD_FILTERS_LIST, GET_CHAPTER_FILTERS_LIST, GET_MEDIUM_FILTERS_LIST, GET_STANDARD_FILTERS_LIST, GET_SUBJECT_FILTERS_LIST, GET_TOPIC_FITERS_LIST } from '../../../../../app/service/abhyas-worksheet-filter.service';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import FormBuilder from '../../../../../components/form-builder';
import moment from 'moment';
import { CREATE_ABHYAS_QUESTION, DELETE_ABHYAS_QUESTION, GET_ABHYAS_QUESTION_LIST, UPDATE_ABHYAS_QUESTION } from '../../../../../app/service/abhyas-question.service';
import { AbhyasMediumList } from '../../abhyas/medium/list';
import { AbhyasBoardList } from '../../abhyas/board/list';
import { GET_PARIKSHA_SELECT_TEST_LIST, GET_PARIKSHA_TEST_FILTER, GET_PARIKSHA_TEST_LIST } from '../../../../../app/service/pariksha-test.service';
import { AbhyasStandardList } from '../../abhyas/standard/list';
import { AbhyasSubjectList } from '../../abhyas/subject/list';
import { AbhyasChapterList } from '../../abhyas/chapter/list';
import { AbhyasTopicList } from '../topic/list';
import { AbhyasWorksheetDataList } from './worksheet-list';
import { GET_WORKSHEET_FILTER_LIST } from '../../../../../app/service/abhyas-worksheet.service';
import AbhyasQuestionsList from '../question/questions-list';
import { Breadcum } from '../../../../../components/breadcum';



export const AbhyasWorksheetList = () => {
    const [activePage, setActivePage] = useState(0);
    const [loading, setLoading] = useState<boolean>(false);
    const [mediumLoading, setMediumLoading] = useState<boolean>(false);
    const [standardLoading, setStandardLoading] = useState<boolean>(false);
    const [subjectLoading, setSubjectLoading] = useState<boolean>(false);
    const [chapterLoading, setChapterLoading] = useState<boolean>(false);
    const [topicLoading, setTopicLoading] = useState<boolean>(false);
    const [questionsLoading, setQuestionsLoading] = useState<boolean>(false);
    const [boardsList, setBoardsList] = useState<any>([]);
    const [mediumList, setMediumList] = useState<any>([]);
    const [standardList, setStandardList] = useState<any>([]);
    const [subjectList, setSubjectList] = useState<any>([]);
    const [chapterList, setChapterList] = useState<any>([]);
    const [worksheetList, setWorksheetList] = useState<any>([]);
    const [topicList, setTopicList] = useState<any>([]);
    const [questionsList, setQuestionsList] = useState<any>([]);
    const [filterData, setFilterData] = useState<any>({});
    const [showComponent, setShowComponent] = useState<string>("");
    const [topicName, setTopicName] = useState<string | undefined>("");
    const [worksheetNumber, setWorksheetNumber] = useState<number | undefined>();

    const filterForm = useRef<any>({});
    ///  Questions///
    const [count, setCount] = useState<number>(0);


    useEffect(() => {
        getBoardData();
    }, [])

    const getBoardData = () => {
        setLoading(true);
        GET_BOARD_FILTERS_LIST().then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res?.err);
                    setLoading(false);
                } else {
                    const data = res['rows'];
                    setBoardsList(data);
                    setLoading(false);
                }
            }
        });
    }
    const handleInput = (data: any) => {
        let preData = { ...data.value }
        setFilterData(preData);
    };
    const onChangeBoard = (e: any) => {
        setMediumList([]);
        setStandardList([]);
        setSubjectList([]);
        setChapterList([]);
        setWorksheetList([]);
        setTopicList([]);
        setQuestionsList([]);
        setCount(0);
        if (e) {
            setCount(1);
            setShowComponent(e);
            setMediumLoading(true);
            GET_MEDIUM_FILTERS_LIST(e).then((res: any) => {
                if (res) {
                    if (res.status === 'fail') {
                        toast.error(res.message);
                        setMediumLoading(false);

                    } else {
                        const data = res['rows'];
                        setMediumList(data);
                        setMediumLoading(false);

                    }
                }
            });
        }

    }
    const onChangeMedium = (e: any) => {
        setStandardList([]);
        setSubjectList([]);
        setChapterList([]);
        setWorksheetList([]);
        setTopicList([]);
        setQuestionsList([]);
        setCount(1);
        setShowComponent(filterData?.board_uuid);
        if (e) {
            setShowComponent(e);
            setCount(2);
            setStandardLoading(true)
            GET_STANDARD_FILTERS_LIST(filterData?.board_uuid, e).then((res: any) => {
                if (res) {
                    if (res.status === 'fail') {
                        toast.error(res?.err);
                        setStandardLoading(false);
                    } else {
                        const data = res['rows'];
                        setStandardList(data);
                        setStandardLoading(false);

                    }
                }
            });
        }
    }
    const onChangeStandard = (e: any) => {
        setSubjectList([]);
        setChapterList([]);
        setWorksheetList([]);
        setTopicList([]);
        setQuestionsList([]);
        setCount(2);
        setShowComponent(filterData?.medium_uuid);
        if (e) {
            setShowComponent(e);
            setCount(3);
            setSubjectLoading(true);
            GET_SUBJECT_FILTERS_LIST(filterData?.board_uuid, filterData?.medium_uuid, e).then((res: any) => {
                if (res) {
                    if (res.status === 'fail') {
                        toast.error(res?.err);
                        setSubjectLoading(false);
                    } else {
                        const data = res['rows'];
                        setSubjectList(data);
                        setSubjectLoading(false);
                    }
                }
            });
        }
    }
    const onChangeSubject = (e: any) => {
        setChapterList([]);
        setWorksheetList([]);
        setTopicList([]);
        setQuestionsList([]);
        setCount(3);
        setShowComponent(filterData?.standard_uuid);
        if (e) {
            setCount(4);
            setShowComponent(e);
            setChapterLoading(true);
            GET_CHAPTER_FILTERS_LIST(filterData?.board_uuid, filterData?.medium_uuid, filterData?.standard_uuid, e).then((res: any) => {
                if (res) {
                    if (res.status === 'fail') {
                        toast.error(res?.err);
                        setChapterLoading(false);

                    } else {
                        const data = res['rows'];
                        setChapterList(data);
                        setChapterLoading(false);

                    }
                }
            });
        }
    }
    const onChangeChapter = (e: any) => {
        setTopicList([]);
        setQuestionsList([]);
        setCount(4);
        setShowComponent(filterData?.subject_uuid);
        if (e) {
            setCount(5);
            setShowComponent(e);
            setTopicLoading(true);
            GET_TOPIC_FITERS_LIST(filterData?.board_uuid, filterData?.medium_uuid, filterData?.standard_uuid, filterData?.subject_uuid, e).then((res: any) => {
                if (res) {
                    if (res.status === 'fail') {
                        toast.error(res?.err);
                        setTopicLoading(false);

                    } else {
                        const data = res['rows'];
                        setTopicList(data);
                        setTopicLoading(false);

                    }
                }
            });
        }
    }
    const onChangeTopic = (e: any, name?: string) => {
        setTopicName(name)
        filterData.topic_uuid = e;
        setWorksheetList([]);
        setQuestionsList([]);
        setCount(5);
        setShowComponent(filterData?.chapter_uuid);
        if (e) {
            setCount(6);
            setShowComponent(e);
            setTopicLoading(true);
            GET_WORKSHEET_FILTER_LIST(filterData?.board_uuid, filterData?.medium_uuid, filterData?.standard_uuid, filterData?.subject_uuid, filterData?.chapter_uuid, e, "WORKSHEETS", "ABHYAAS").then((res: any) => {
                if (res) {
                    if (res.status === 'fail') {
                        toast.error(res?.err);
                        setTopicLoading(false);

                    } else {
                        const data = res['rows'];
                        setWorksheetList(data);
                        setTopicLoading(false);

                    }
                }
            });
        }

    }
    const onChangeWorksheet = (e: any, number?: number) => {
        setWorksheetNumber(number)
        filterData.worksheet_uuid = e;
        setQuestionsList([]);
        setCount(6);
        setShowComponent(filterData?.topic_uuid);
        if (e) {
            setCount(7);
            setShowComponent(e);
            setQuestionsLoading(true);
            GET_ABHYAS_QUESTION_LIST(e, {}).then((res: any) => {
                if (res) {
                    if (res.status === 'fail') {
                        toast.error(res?.err);
                        setQuestionsLoading(false);
                    } else {
                        const data = res.rows;
                        data?.forEach((element: any, i: number) => {
                            element.created_dt = moment(element?.created_dt?.split("T")[0]).format('DD-MM-yyyy');
                            if (element?.modified_dt) {
                                element.modified_dt = moment(element?.modified_dt?.split("T")[0]).format('DD-MM-yyyy');
                            }
                            element.data = JSON.parse(element?.data)
                        });
                        setQuestionsList(data[0]?.data);
                        setQuestionsLoading(false);
                    }
                }
            });
        }
    }

    const handleRefresh = () => {
        setMediumList([]);
        setStandardList([]);
        setSubjectList([]);
        setChapterList([]);
        setWorksheetList([]);
        setTopicList([]);
        setQuestionsList([]);
        const data = {};
        setFilterData({ ...data });
        setCount(0);
        filterForm.current.reset();
    }



    return (
        <div>

            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading &&
                <div className='my-3'>
                    <FormBuilder onUpdate={handleInput}>
                        <form ref={filterForm}>
                            <div className="row">
                                <div className="col-md-3">
                                    <div className='mb-4'>
                                        <label>Board</label>
                                        <select name="board_uuid" id="board_uuid" className="form-select ft-14 " onChange={(e) => { onChangeBoard(e.target.value) }}>
                                            <option value="">Select Board</option>
                                            {boardsList?.map((name: any, i: number) => (
                                                <option key={i} value={name?.uuid}>
                                                    {name?.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className='mb-4 position-relative'>
                                        {/* {mediumLoading &&
                                            <div className=" mt-4 pt-1 position-absolute " style={{ right: "8px" }}>
                                                <div className="spinner-border ms-5 ms-md-0" role="status" style={{ height: "23px", width: "23px" }} >
                                                    <span className="sr-only">Loading...</span>
                                                </div>
                                            </div>
                                        } */}
                                        <label>Medium</label>

                                        <select name="medium_uuid" id="medium_uuid" className="form-select ft-14 " onChange={(e) => { onChangeMedium(e.target.value) }}>
                                            <option value="">Select Medium</option>
                                            {mediumList?.map((name: any, i: number) => (
                                                <option key={i} value={name?.uuid}>
                                                    {name?.name}
                                                </option>
                                            ))}
                                        </select>

                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className='mb-4  position-relative'>
                                        {/* {standardLoading &&
                                            <div className=" mt-4 pt-1 position-absolute " style={{ right: "8px" }}>
                                                <div className="spinner-border ms-5 ms-md-0" role="status" style={{ height: "23px", width: "23px" }} >
                                                    <span className="sr-only">Loading...</span>
                                                </div>
                                            </div>
                                        } */}
                                        <label>Standard</label>
                                        <select name="standard_uuid" id="standard_uuid" className="form-select ft-14 " onChange={(e) => { onChangeStandard(e.target.value) }}>
                                            <option value="">Select Standard</option>
                                            {standardList?.map((name: any, i: number) => (
                                                <option key={i} value={name?.uuid}>
                                                    {name?.standard}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className='mb-4  position-relative'>
                                        {/* {subjectLoading &&
                                            <div className=" mt-4 pt-1 position-absolute " style={{ right: "8px" }}>
                                                <div className="spinner-border ms-5 ms-md-0" role="status" style={{ height: "23px", width: "23px" }} >
                                                    <span className="sr-only">Loading...</span>
                                                </div>
                                            </div>
                                        } */}
                                        <label>Subject</label>
                                        <select name="subject_uuid" id="subject_uuid" className="form-select ft-14 " onChange={(e) => { onChangeSubject(e.target.value) }}>
                                            <option value="">Select Subject</option>
                                            {subjectList?.map((name: any, i: number) => (
                                                <option key={i} value={name?.uuid}>
                                                    {name?.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className='mb-4  position-relative'>
                                        {/* {chapterLoading &&
                                            <div className=" mt-4 pt-1 position-absolute " style={{ right: "8px" }}>
                                                <div className="spinner-border ms-5 ms-md-0" role="status" style={{ height: "23px", width: "23px" }} >
                                                    <span className="sr-only">Loading...</span>
                                                </div>
                                            </div>
                                        } */}
                                        <label>Chapter</label>
                                        <select name="chapter_uuid" id="chapter_uuid" className="form-select ft-14 " onChange={(e) => { onChangeChapter(e.target.value) }}>
                                            <option value="">Select Chapter</option>
                                            {chapterList?.map((name: any, i: number) => (
                                                <option key={i} value={name?.uuid}>
                                                    {name?.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                {/* <div className="col-md-3">
                                    <div className='mb-4  position-relative'>
                                        <label>Topic</label>
                                        <select name="topic_uuid" id="topic_uuid" className="form-select ft-14 " onChange={(e) => { onChangeTopic(e.target.value) }}>
                                            <option value="">Select Topic</option>
                                            {topicList?.map((name: any, i: number) => (
                                                <option key={i} value={name?.uuid}>
                                                    {name?.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className='mb-4  position-relative'>
                                        <label>Worksheet</label>
                                        <select name="worksheet_uuid" id="worksheet_uuid" className="form-select ft-14 " onChange={(e) => { onChangeWorksheet(e.target.value) }}>
                                            <option value="">Select Worksheet</option>
                                            {worksheetList?.map((name: any, i: number) => (
                                                <option key={i} value={name?.uuid}>
                                                    {name?.number}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div> */}
                                <div className="col-md-3 ">
                                    <button className="btn btn-primary cursor-pointer mt-4" type="button" onClick={() => handleRefresh()}>
                                        Clear
                                    </button>
                                </div>
                            </div>
                        </form>
                    </FormBuilder>
                    {/* // Question // */}


                    {(count == 0) &&
                        <AbhyasBoardList isLinked={"false"} />

                    }
                    {(count == 1 && showComponent === filterData?.board_uuid) &&
                        <div>
                            <h5 className="mb-3">Mediums</h5>
                            <AbhyasMediumList isLinked={"false"} display={"none"} boardUuid={filterData?.board_uuid} updateList={onChangeBoard} />
                        </div>
                    }
                    {(count == 2 && showComponent === filterData?.medium_uuid) &&
                        <div>
                            <h5 className="mb-3">Standards</h5>
                            <AbhyasStandardList isLinked={"false"} display={"none"} boardUuid={filterData?.board_uuid} mediumUuid={filterData?.medium_uuid} updateList={onChangeMedium} />
                        </div>
                    }
                    {(count == 3 && showComponent === filterData?.standard_uuid) &&
                        <div>
                            <h5 className="mb-3">Subjects</h5>
                            <AbhyasSubjectList isLinked={"false"} display={"none"} boardUuid={filterData?.board_uuid} mediumUuid={filterData?.medium_uuid} standardUuid={filterData?.standard_uuid} updateList={onChangeStandard} />
                        </div>
                    }
                    {(count == 4 && showComponent === filterData?.subject_uuid) &&
                        <div>
                            <h5 className="mb-3">Chapters</h5>
                            <AbhyasChapterList isLinked={"false"} display={"none"} boardUuid={filterData?.board_uuid} mediumUuid={filterData?.medium_uuid} standardUuid={filterData?.standard_uuid} subjectUuid={filterData?.subject_uuid} updateList={onChangeSubject} />
                        </div>
                    }
                    {(count == 5 && showComponent === filterData?.chapter_uuid) &&
                        <div>
                            <h5 className="mb-3">Topics</h5>
                            <AbhyasTopicList isLinked={"false"} display={"none"} boardUuid={filterData?.board_uuid} mediumUuid={filterData?.medium_uuid} standardUuid={filterData?.standard_uuid} subjectUuid={filterData?.subject_uuid} chapterUuid={filterData?.chapter_uuid} updateList={onChangeChapter} selectList={onChangeTopic} />
                        </div>
                    }
                    {(count == 6 && showComponent === filterData?.topic_uuid) &&
                        <div>
                            <nav aria-label="breadcrumb" className="first">
                                <ol className={`breadcrumb indigo lighten-6 first mb-4 bg-dark navbar-light  px-md-4`}>
                                    <li className="breadcrumb-item font-weight-bold font-italic">
                                        <span className='cursor-pointer' onClick={() => onChangeChapter(filterData.chapter_uuid)}><span className='breadcum-label mr-md-3 mr-2 black-text text-uppercase active'>{topicName}  </span> <span className='font-weight-bold f10 text-black ms-1 fw_bold '>( Topic )</span></span>
                                        <i className="bi bi-chevron-right breadcum-arrow"></i>
                                    </li>
                                    <li className="breadcum-active-label ">
                                        <span className='black-text text-uppercase active-1 disabled-link active'>Worksheets </span>
                                    </li>
                                </ol>
                            </nav>
                            <AbhyasWorksheetDataList isLinked={"false"} display={"none"} boardUuid={filterData?.board_uuid} mediumUuid={filterData?.medium_uuid} standardUuid={filterData?.standard_uuid} subjectUuid={filterData?.subject_uuid} chapterUuid={filterData?.chapter_uuid} topicUuid={filterData?.topic_uuid} selectList={onChangeWorksheet} />
                        </div>
                    }
                    {(count == 7 && showComponent === filterData?.worksheet_uuid) &&
                        <div>
                            <AbhyasQuestionsList topicName={topicName} worksheetNumber={worksheetNumber} isLinked={"false"} display={"none"} chapterUuid={filterData?.chapter_uuid} topicUuid={filterData?.topic_uuid} sheetUuid={filterData?.worksheet_uuid} onSelectTopic={onChangeChapter} selectList={onChangeTopic} />
                        </div>
                    }
                </div>

            }
        </div>
    )
}

