export interface PopupAction {
    label: string;
    type: string;
    callback: () => void;
}
export class PopupData {
    constructor(public active: boolean,
        public title: string,
        public info: string,
        public actions: PopupAction[]) {
    }
}