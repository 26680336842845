import { DataTableCol } from "../../../../../components/data-table/types";

export const AbhyasStandardDataGridCols: DataTableCol[] = [
    {
        title: "Standard",
        control: "standard",
        sortable: true,
        canShowColumn: true,
        isLink: true,
    },
    {
        title: " Name",
        control: "display_text",
        sortable: true,
        canShowColumn: true,
    },
    {
        title: "Created",
        control: "created_dt",
        sortable: true,
        canShowColumn: true,
    },
    {
        title: "Modified",
        control: "modified_dt",
        sortable: true,
        canShowColumn: true,
    },
    {
        title: "Actions",
        control: "board_info",
        isEdit: true,
        // isDelete: true,
        canShowColumn: true,
    },
];
