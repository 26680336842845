import React, { useEffect, useRef, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { GET_MLA_TWO_QUESTIONS, GET_MLA_TWO_TEST_DETAILS, MLA_TWO_ABILITY_UPDATE } from '../../../../app/service/mla-two.service';
import appConfig from '../../../../config/constant';
import { MathJaxContext, MathJax } from 'better-react-mathjax';

export const MLATwoTests = () => {
    const [loading, setLoading] = useState(false);
    const [testsList, setTestsList] = useState<any>([]);
    const [testsRecords, setTestsRecords] = useState<any>([]);
    const [activePage, setActivePage] = useState(1);
    const [pageNumbers, setPageNumbers] = useState([]);
    const [questionsData, setQuestionsData] = useState<any[]>([]);
    const [canShowQuestionsPopup, setCanShowQuestionsPopup] = useState(false);
    const [loadingTestQuestions, setLoadingTestQuestions] = useState(false);
    const [canShowTestsActionsPopup, setCanShowTestsActionsPopup] = useState(false);
    const [testNumber, setTestNumber] = useState<any>('');

    const [testsView, setTestsView] = useState(false);
    const [testEdit, setTestEdit] = useState(false);
    const [testDelete, setTestDelete] = useState(false);
    const [addTest, setAddTest] = useState(false);
    const [accessAction, setAccessAction] = useState(false);

    let { id, code } = useParams<{ id: string, code: string }>();

    const history = useHistory();


    useEffect(() => {
        getTestsList();
        const accessObjectsData: any = localStorage.getItem("access_objects_data");
        onHandleAction(JSON.parse(accessObjectsData));
    }, []);

    function getTestsList() {
        setLoading(true);
        GET_MLA_TWO_TEST_DETAILS().then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    setLoading(false);
                    setTestsList(res.tests);
                    setTestsRecords(res.tests.slice(0, 10));
                    const pageNumbers = Math.ceil(res.tests.length / 10);
                    const pageArray: any = [];
                    for (let i = 1; i <= pageNumbers; i++) {
                        pageArray.push(i);
                    }
                    setPageNumbers(pageArray);
                    setActivePage(1);
                }
            }
        });
    }


    function onViewQuestion(name: any) {
        return { __html: name };
    }

    function onClickAddTest() {
        history.push(`/dashboard/module/info/MLA-2/${code}/${id}/tests-add/${testsList.length + 1}`);
    }

    function onTestEditClick(item: any) {
        history.push(`/dashboard/module/info/MLA-2/${code}/${id}/tests-edit/${item.test_no}`);
    }

    function onTestDelete(item: any) {
        setCanShowTestsActionsPopup(true);
        setTestNumber(item.test_no);
    }


    function onClickPage(pageNumber: number) {
        setActivePage(pageNumber);
        handlePagination(pageNumber);
    }

    function onClickNextPage() {
        setActivePage(activePage + 1);
        handlePagination(activePage + 1);
    }

    function onClickPreviousPage() {
        setActivePage(activePage - 1);
        handlePagination(activePage - 1);
    }

    function handlePagination(pageNumber: any) {
        const itemsPerPage = (pageNumber - 1) * 10;
        const perPageCount = testsList.slice(itemsPerPage, pageNumber * 10);
        setTestsRecords(perPageCount);
    }


    function onClickView(item: any) {
        setCanShowQuestionsPopup(true);
        let payload = {
            "mode": 'TESTS',
            "test_no": item.test_no,
            "ability": '',
            "worksheet_no": ''
        }
        setLoadingTestQuestions(true);
        GET_MLA_TWO_QUESTIONS(payload).then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res.message);
                setLoadingTestQuestions(false);
            } else {
                setQuestionsData(res.questions);
                setLoadingTestQuestions(false);
            }
        })
    }

    const handleQuestionsClose = () => {
        setCanShowQuestionsPopup(false);
    }

    const handleWorksheetTypeClose = () => {
        setCanShowTestsActionsPopup(false);
    }

    function handleDelete() {
        setCanShowTestsActionsPopup(false);
        const payload = {
            "mode": "TESTS",
            "status": 'DELETE',
            'test_no': testNumber,
            "url": '',
            data: []
        }
        setLoading(true);
        MLA_TWO_ABILITY_UPDATE(payload).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    getTestsList();
                    setLoading(false);
                }
            }
        });
    }

    function handleTestTemplateDownload() {
        const url = `${appConfig.FILE_UPLOAD_URL}templates/${"tests_upload.xlsx"}`;
        setLoading(true);
        setTimeout(() => {
            toast.success('Successfully Downloaded');
            window.location.href = url;
            setLoading(false);
        }, 1000);
    }

    function onHandleAction(accessObjectsList: any) {
        const preparedData = accessObjectsList?.filter((el: any) => el.object_code === "MODULES");
        if (preparedData?.length > 0) {
            for (let i = 0; i < preparedData[0].child.length; i++) {
                if (preparedData[0].child[i].object_code === "MODULES-MLA-2-TESTS-VIEW" && preparedData[0].child[i].p_read === 1) {

                    setTestsView(true);
                }

                if (preparedData[0].child[i].object_code === "MODULES-MLA-2-TESTS-EDIT" && preparedData[0].child[i].p_read === 1) {

                    setTestEdit(true);
                }
                if (preparedData[0].child[i].object_code === "MODULES-MLA-2-TESTS-DELETE" && preparedData[0].child[i].p_read === 1) {

                    setTestDelete(true);
                }

                if (preparedData[0].child[i].object_code === "MODULES-MLA-2-TESTS-ADD-TEST" && preparedData[0].child[i].p_read === 1) {

                    setAddTest(true);
                }


                if ((preparedData[0].child[i].object_code === "MODULES-MLA-2-TESTS-DELETE" && preparedData[0].child[i].p_read === 1) ||
                    (preparedData[0].child[i].object_code === "MODULES-MLA-2-TESTS-EDIT" && preparedData[0].child[i].p_read === 1) ||
                    (preparedData[0].child[i].object_code === "MMODULES-MLA-2-TESTS-VIEW" && preparedData[0].child[i].p_read === 1)) {
                    setAccessAction(true)
                }

            }
        }
    }

    return (
        <div className="row py-3">
            <div className={`modal fade parent-modal-fade ${canShowQuestionsPopup ? 'show' : 'false'}`} style={{ display: (canShowQuestionsPopup ? "block" : 'none') }} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog worksheet-quesions-dialog" role="document">
                    <div className="modal-content worksheet-questions-model">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Questions List</h5>
                            <button type="button" className="close popup-close cursor-pointer" data-dismiss="modal" aria-label="Close" onClick={handleQuestionsClose}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            {loadingTestQuestions &&
                                <div className="text-center p-5">
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div>
                            }
                            {!loadingTestQuestions && <div>
                                <MathJaxContext>
                                    <MathJax>
                                        {questionsData.map((question: any, i: number) => (
                                            <div className="mt-4" key={i}>
                                                <div className='row'>
                                                    <div className='row'>
                                                        <div className='col-md-1' style={{ alignSelf: 'center' }}>
                                                            <span className='fw-bold' style={{ fontSize: '22px' }}>Q{question.question_number}:</span>
                                                        </div>
                                                        <div className='col-md-11' style={{ paddingTop: '15px' }}>
                                                            <span className='fw-bold' style={{ fontSize: '22px' }}>
                                                                {/* <MathJaxContext> */}
                                                                {/* <MathJax> */}
                                                                <div dangerouslySetInnerHTML={onViewQuestion(question.question)}></div>
                                                                {/* </MathJax> */}
                                                                {/* </MathJaxContext> */}
                                                            </span>
                                                        </div>
                                                    </div>

                                                    {question.options.map((options: any, j: number) => (
                                                        <div className='row' key={j}>
                                                            <div className='col-md-1'>
                                                                <span>{options.options}.</span>
                                                            </div>
                                                            <div className='col-md-6'>
                                                                {/* <MathJaxContext> */}
                                                                {/* <MathJax> */}
                                                                <span dangerouslySetInnerHTML={onViewQuestion(options.value)}></span>
                                                                {/* </MathJax> */}
                                                                {/* </MathJaxContext> */}
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        ))}
                                    </MathJax>
                                </MathJaxContext>
                            </div>}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary cursor-pointer" data-dismiss="modal" onClick={handleQuestionsClose}>Close</button>&nbsp;
                        </div>
                    </div>
                </div>
            </div>
            <div className={`modal fade parent-modal-fade ${canShowTestsActionsPopup ? 'show' : 'false'}`} style={{ display: (canShowTestsActionsPopup ? "block" : 'none') }} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">{`Are you sure want to delete  Test Number ${testNumber}`}</h5>
                            <button type="button" className="close popup-close cursor-pointer" data-dismiss="modal" aria-label="Close" onClick={handleWorksheetTypeClose}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary cursor-pointer" onClick={() => handleDelete()}>Yes</button>
                            <button type="button" className="btn btn-secondary cursor-pointer" data-dismiss="modal" onClick={handleWorksheetTypeClose}>Cancel</button>&nbsp;
                        </div>
                    </div>
                </div>
            </div>
            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading &&
                <React.Fragment>
                    <div>
                        {addTest && <button className="px-4 py-1 mb-2 cursor-pointer msb-btn" style={{ float: 'right' }} onClick={() => onClickAddTest()}>Add Test</button>}&nbsp;
                        {/* <button className="px-4 py-1 mb-2 cursor-pointer msb-btn" style={{ float: 'right', marginRight: '10px' }} onClick={() => handleTestTemplateDownload()}>Tests List Template Download</button>&nbsp; */}
                    </div>
                    <div className="table-responsive mt-2">
                        <table className="table fixed-table-header mb-0">
                            <thead>
                                <tr>
                                    <th>Test No</th>
                                    <th>Questions Count</th>
                                    {accessAction && <th>Action</th>}
                                </tr>
                            </thead>
                            <tbody>
                                {testsRecords.map((name: any, i: number) => (
                                    <tr key={i}>
                                        <td>{name.test_no}</td>
                                        <td>{name.questions}</td>
                                        <td>
                                            {testsView && <i className="bi bi-eye-fill cursor-pointer p-2" onClick={() => onClickView(name)}></i>}
                                            {testEdit && <i className="bi bi-pencil-square cursor-pointer edit-icon p-2" onClick={() => onTestEditClick(name)}></i>}
                                            {testDelete && <i className="bi bi-trash cursor-pointer p-2" onClick={() => onTestDelete(name)}></i>}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    {testsList.length === 0 && <div className='pt-2' style={{ textAlign: 'center' }}>
                        No records Found
                    </div>}
                </React.Fragment>}
            {testsList.length > 0 &&
                <div>
                    <nav className='mt-3' style={{ float: 'right' }}>
                        <ul className="pagination">
                            <li className="page-item">
                                <button className={`page-link prev ${activePage === 1 ? 'disabled' : ''}`} onClick={() => onClickPreviousPage()}>Previous</button>
                            </li>
                            {pageNumbers.map((number: number, i: number) => (
                                <li className={activePage === number ? 'active page-item' : 'page-item'} key={i}>
                                    <button className="page-link" onClick={() => onClickPage(number)}>{number}</button>
                                </li>
                            ))}
                            <li className="page-item">
                                <button className={`page-link next ${activePage === pageNumbers.length ? 'disabled' : ''}`} onClick={() => onClickNextPage()}>Next</button>
                            </li>
                        </ul>
                    </nav>
                </div>
            }
        </div>
    )
}

