import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { MathJaxContext, MathJax } from 'better-react-mathjax';
import Countdown from 'react-countdown';
import { FETCH_ABHYAS_USER_QUESTION_PAPER, UPDATE_ABHYAS_USER_QUESTION_PAPER } from '../../../../app/service/abhyas-module-questions.service';
import TimerInfo from '../../../dashboard/mns/timer/timerinfo';
import { UPDATE_PARIKSHA_USER_QUESTION_PAPER } from '../../../../app/service/pariksha-module-questions';


export const AbhyasUserQuestionsList = () => {
    const [questionsData, setQuestionsData] = useState<any>([]);
    const [loading, setLoading] = useState(false);
    const [startTimer, setStartTimer] = useState(false);
    const [canShowHeader, setShowHeader] = useState(false);
    const [timeRemain, setTimeRemain] = useState<any>('');
    const [startTime, setStartTime] = useState(new Date());
    const [alphabets, setAlphabets] = useState<any>(["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z"]);
    const [canShowPopup, setCanShowPopup] = useState(false);
    // const [test_start_date, setTestStartDate] = useState<any>(getTimeDetails());
    let { moduleUUID, userCode, schoolCode, subjectUuid } = useParams<{ moduleUUID: string, userCode: string, schoolCode: string, subjectUuid: string }>();
    const history = useHistory();
    const [minutes, setMinutes] = useState(60);
    const [seconds, setSeconds] = useState(0);
    let completedTime: string = "";
    let time_remain_storage = '';
    let timeUp = false;
    const [isActive, setIsActive] = useState<boolean>(false);
    let selectUuids = JSON.parse(localStorage.getItem('userSelectedSubjectInfo') || '{}');
    const questionRefs = useRef<(HTMLInputElement | null)[]>([]);
    const backworKey = localStorage.getItem("backword_key");
    const type = localStorage.getItem("abhyas_type");
    const testInfo: any = JSON.parse(localStorage.getItem("abhyas_test_info") || '{}');
    const cycleNumber = localStorage.getItem("abhyas_cycle_no");
    const worksheets: any = JSON.parse(localStorage.getItem('abhyas_worksheets') || '{}');
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [imageSize, setImageSize] = useState<number>(0);

    useEffect(() => {
        const hasDefaultMethodBeenCalled = localStorage.getItem('hasDefaultMethodBeenCalled');

        if (!hasDefaultMethodBeenCalled) {
            localStorage.setItem('hasDefaultMethodBeenCalled', JSON.stringify(true));
        } else {

            refreshCalled();
        }

        return () => {
            localStorage.removeItem('hasDefaultMethodBeenCalled');
        };
    }, []);
    useEffect(() => {
        if (backworKey === "true") {
            history.push('/student-dashboard/list')
        }
        getAbhyaasQuestionsList(testInfo, type, cycleNumber);
        // getAbhyaasInfo();
        // getTimeInfo();

    }, []);
    useEffect(() => {
        let interval: NodeJS.Timeout;

        if (isActive) {
            interval = setInterval(() => {
                setSeconds((prevSeconds) => prevSeconds + 1);
            }, 1000);
        }

        return () => clearInterval(interval);
    }, [isActive]);
    // function getTimeDetails() {
    //     const testStartDate: any = localStorage.getItem("abhyas_test_start_date");

    //     return testStartDate ? testStartDate : null;
    // }

    // function getTimeInfo() {
    //     const testStartDate: any = localStorage.getItem("abhyas_test_start_date");
    //     let time = getTime(testStartDate);
    //     let timeMillis = moment(time, 'YYYY-MM-DD hh:mm:ss').toDate().getTime();
    //     let reqTimeMillis = 2 * 24 * 60 * 60 * 1000;
    //     let actualTimeToComplete = timeMillis + reqTimeMillis;
    //     let x = new Date(actualTimeToComplete)
    //     setTimeRemain(x);
    //     let currentTime = new Date().getTime();
    //     let timerResult = x.getTime() - currentTime;
    //     let tSeconds = Math.floor(timerResult / 1000);
    //     let hours = Math.floor(tSeconds / 3600);
    //     setHoursValue(hours);

    // }

    function refreshCalled() {
        getAbhyaasQuestionsList(testInfo, type, cycleNumber);
        // getAbhyaasInfo()
        // getAbhyaasInfo()
        setTimeout(() => {
            handleCompleteSubmit();
        }, 2000);

    }

    function getTime(time: any) {
        if (time !== 'null') {
            return time;
        } else {
            return time ? time : startTime;
        }
    }
    function getAbhyaasInfo() {
        const testInfo: any = localStorage.getItem("abhyas_test_info");
        const cycleNumber = localStorage.getItem("abhyas_cycle_no");
        const worksheets: any = localStorage.getItem('abhyas_worksheets');
        // setTestInfo(JSON.parse(testInfo));

        // setCycleNumber(cycleNumber);
        // setWorksheets(JSON.parse(worksheets));
        getAbhyaasQuestionsList(JSON.parse(testInfo), type, cycleNumber);
    }

    function getAbhyaasQuestionsList(testInfo: any, type: any, cycleNumber: any) {
        let payload = {
            "type": type,
            "uuid": testInfo?.question_paper_uuid
        }
        setShowHeader(false);
        setLoading(true);
        FETCH_ABHYAS_USER_QUESTION_PAPER(payload).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    const errorValue = Array.isArray(res?.err) ? res?.err[0] : res?.err;
                    toast.error(errorValue);
                    setLoading(false);
                } else {
                    if (res) {
                        if (res) {
                            // if (res.hasOwnProperty("data") ) {
                            if (Array.isArray(res)) {
                                localStorage.setItem("questionsData", JSON.stringify(res));
                                setQuestionsData(res);
                                setShowHeader(true);
                                setStartTimer(true);
                                if (type === 'WORKSHEETS') {
                                    setIsActive(true);
                                }
                                // setLoading(false);
                                setLoading(false);
                            } else {
                                toast.error("Questions not found");
                                setLoading(false);

                            }

                        } else {
                            // setQuestionsData([]);
                            setShowHeader(true);
                            setStartTimer(true);
                            setLoading(false);
                        }

                    }

                }
            }
        });
    }



    function onViewQuestion(name: any) {
        return { __html: name };
    }


    function handleQuestionOptions(item: any, index: number, j: number) {
        const data = questionsData;
        for (let i = 0; i < alphabets.length; i++) {
            if (j === i) {
                data[index].answer = alphabets[i];
            }
        }
        setQuestionsData([...data]);
        localStorage.setItem("questionsData", JSON.stringify(data));
    }


    const handleCompleteSubmit = () => {
        if (testInfo?.module_code === "PARIKSHA") {
            const payload = {
                "user_code": userCode,
                "school_code": schoolCode,
                "subject_uuid": subjectUuid,
                "type": type,
                "question_paper_uuid": testInfo?.question_paper_uuid,
                "time_taken": type === "TESTS" ? (calculateTimeDifference() ? calculateTimeDifference() : localStorage.getItem("setTime")) : (getTimeTaken(seconds) ? getTimeTaken(seconds) : localStorage.getItem("setTime")),
                "questions": questionsData.length > 0 ? questionsData : JSON.parse(localStorage.getItem("questionsData") || '{}'),
                "test_time": `${minutes}.0`,
                "test_uuid": testInfo?.test_uuid,
                "topic_uuid": testInfo?.topic_uuid,
                "worksheet_uuid": testInfo?.worksheet_uuid,
            }

            setLoading(true);
            UPDATE_PARIKSHA_USER_QUESTION_PAPER(payload).then((res: any) => {
                if (res) {
                    if (res.status === 'fail') {
                        const errorValue = Array.isArray(res?.err) ? res?.err[0] : res?.err;
                        toast.error(errorValue);
                        setLoading(false);
                    } else {
                        localStorage.setItem("backword_key", "true");
                        setLoading(false);
                        const data = res;
                        if (type === 'WORKSHEETS') {
                            toast.success("You have successfully Submitted worksheet");
                            handleclose();
                            return;
                        }
                    }
                }
            });
        } else {

            const payload = {
                "user_code": userCode,
                "school_code": schoolCode,
                "subject_uuid": subjectUuid,
                "cycle_no": cycleNumber ? parseInt(cycleNumber) : 1,
                "type": type,
                "test_no": testInfo && testInfo.test_no ? testInfo.test_no : 1,
                "question_paper_uuid": type === 'TESTS' ? (testInfo?.question_paper_uuid ? testInfo?.question_paper_uuid : '') : (testInfo?.question_paper_uuid ? testInfo?.question_paper_uuid : ''),
                "question_paper_level": testInfo.level ? testInfo.level : '',
                "priority_no": testInfo?.priority ? testInfo?.priority : '',
                "time_taken": type === "TESTS" ? (calculateTimeDifference() ? calculateTimeDifference() : localStorage.getItem("setTime")) : (getTimeTaken(seconds) ? getTimeTaken(seconds) : localStorage.getItem("setTime")),
                "questions": questionsData.length > 0 ? questionsData : JSON.parse(localStorage.getItem("questionsData") || '{}'),
                "test_time": `${minutes}.0`,
            }
            if (type === "WORKSHEETS") {
                let addPayLoad = {
                    "topic_uuid": testInfo?.topic_uuid,
                    "worksheet_uuid": testInfo?.worksheet_uuid,
                }
                Object.assign(payload, { ...addPayLoad })
            }

            setLoading(true);
            UPDATE_ABHYAS_USER_QUESTION_PAPER(payload).then((res: any) => {
                if (res) {
                    if (res.status === 'fail') {
                        const errorValue = Array.isArray(res?.err) ? res?.err[0] : res?.err;
                        toast.error(errorValue);
                        setLoading(false);
                    } else {
                        localStorage.setItem("backword_key", "true");
                        setLoading(false);
                        const data = res;
                        if (type === 'WORKSHEETS') {
                            toast.success("You have successfully Submitted worksheet");
                            handleclose();
                            // setCanShowPopup(true);
                            return;
                        }
                        history.push(`/student-dashboard/list/abhyas/success-page/${moduleUUID}/${userCode}/${schoolCode}/${testInfo.test_no}`);
                    }

                }
            });
        }

    }

    const getCircleColor = (item: any) => {
        if (item?.status === "pending") {
            return '#FCEE21';
        } else if (item?.status === 'completed') {
            if (item?.result === "fail") {
                return '#f15a24';
            } else {
                return '#8CC63F';
            }
        } else {
            return '#FFFFFF';
        }
    }


    const handleclose = () => {
        setCanShowPopup(false);
        let prepredData = { ...selectUuids };
        history.push(`/student-dashboard/list/abhyas/progress-view/${moduleUUID}/${userCode}/${prepredData?.school_code}/${prepredData?.board_uuid}/${prepredData?.medium_uuid}/${prepredData?.standard_uuid}/${prepredData?.subject_uuid}`)
    }


    // Renderer callback with condition
    const renderer = ({
        days,
        hours,
        minutes,
        seconds,
        completed,
    }: {
        days: any;
        hours: any;
        minutes: any;
        seconds: any;
        completed: any;
    }) => {
        if (completed) {
            return <div></div>
            // Render a completed state
        } else {
            // Render a countdown
            return (
                <span style={{ fontSize: "1rem", paddingTop: "10px" }}>
                    {hours} Hr - {minutes} Min - {seconds} Sec
                </span>
            );
        }
    };
    const calculateTimeDifference = () => {
        const initialTime = `${"59"}:${"59"}`
        const [initialMinutes, initialSeconds] = initialTime.split(':').map(Number);
        const [finalMinutes, finalSeconds] = completedTime.split(':').map(Number);
        const initialTimeInSeconds = Number(initialMinutes) * 60 + Number(initialSeconds);
        const finalTimeInSeconds = Number(finalMinutes) * 60 + finalSeconds ? Number(finalSeconds) : 0;
        const differenceInSeconds = Number(initialTimeInSeconds * 100) - Number(finalTimeInSeconds * 100);

        if (differenceInSeconds >= 60) {
            var mins = moment.utc(moment(initialTime, "mm:ss").diff(moment(completedTime, "mm:ss"))).format("mm.ss")
            localStorage.setItem("setTime", mins === 'Invalid date' ? `${minutes}.${seconds}` : mins.replace('00', '0'));
            return mins === 'Invalid date' ? `${minutes}.${seconds}` : mins.replace('00', '0')
        } else {
            localStorage.setItem("setTime", `0.${differenceInSeconds}`);
            return `0.${differenceInSeconds}`;
        }

    };
    const getTimeTaken = (totalSeconds: number) => {
        const minutes = Math.floor(totalSeconds / 60);
        const seconds = totalSeconds % 60;
        let totalTime = `${minutes.toString().padStart(2, '0')}.${seconds.toString().padStart(2, '0')}`
        let finalTime = totalTime?.replace('00', '0')
        localStorage.setItem("setTime", `${Number(finalTime)}`);

        return Number(finalTime);
    }
    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>, currentIndex: number) => {
        if (event.key === 'Enter') {
            event.preventDefault(); // Prevent page reload
            const nextIndex = currentIndex + 1;
            if (nextIndex < questionRefs.current.length) {
                questionRefs.current[nextIndex]?.focus();
            }
        }
    };
    // const calculateDifference = () => {
    //     const initialTime = `${minutes}:${seconds}`
    //     const [initialMinutes, initialSeconds] = initialTime.split(':').map(Number);
    //     const [finalMinutes, finalSeconds] = completedTime.split(':').map(Number);
    //     const parsedTime1 = initialMinutes;
    //     const parsedTime2 = finalMinutes;

    //     // Calculate the difference
    //     const diffMinutes = parsedTime1.minutes - parsedTime2.minutes;
    //     const diffSeconds = parsedTime1.seconds - parsedTime2.seconds;

    //     setDifference({
    //         minutes: diffMinutes,
    //         seconds: diffSeconds,
    //     });
    // };
    const getDomElements = (htmlString: any, tag: string) => {
        const parsedContent = new DOMParser().parseFromString(htmlString, 'text/html');
        const pElements = parsedContent.querySelectorAll('p');
        const imgElement: any = parsedContent.querySelector('img');
        if (tag === "p") {
            const paragraphs: any[] = [];
            pElements.forEach((pElement) => {
                paragraphs.push(`<p class="mb-0">${pElement.innerHTML}</p>`);
                // paragraphs.push(<p className='mb-0'>{pElement.innerHTML}</p>);
            });
            return <div dangerouslySetInnerHTML={{ __html: paragraphs?.join('') }} />

            // return paragraphs?.join('');
            // return <p>{pElement ? pElement.textContent : ""}</p>
        }
        if (tag === "img") {
            if (imgElement) {
                return imgElement ? imgElement.getAttribute('src') : ''
            } else {
                return "";
            }

        }
        return "";
    }
    const handlePrevious = () => {
        const data = questionsData;
        if (!data[currentQuestion].checked) {
            data[currentQuestion].answer = "";
            data[currentQuestion].checked = false;
        }
        localStorage.setItem("questionsData", JSON.stringify(data));
        setQuestionsData([...data]);
        getImageDimensions(data[currentQuestion - 1].question);
        setCurrentQuestion((prev) => prev - 1);
    };
    const handleClearSelection = () => {
        setCurrentQuestion(currentQuestion);
        const data = questionsData;
        data[currentQuestion].answer = "";
        data[currentQuestion].checked = false;
        localStorage.setItem("questionsData", JSON.stringify(data));
        setQuestionsData([...data]);
    };
    const handleNext = () => {
        const data = questionsData;
        if (data[currentQuestion].answer) {
            data[currentQuestion].checked = true;
        }
        localStorage.setItem("questionsData", JSON.stringify(data));
        setQuestionsData([...data]);
        getImageDimensions(data[currentQuestion + 1].question);
        setCurrentQuestion((prev) => prev + 1);
    };
    const selectQuestion = (questioNo: any) => {
        let preElement = parseInt(questioNo);
        const data = questionsData;
        if (data[currentQuestion].answer && (data[currentQuestion]?.id === questionsData[questionsData.length - 1]?.id)) {
            data[currentQuestion].checked = true;
        } else if (!data[currentQuestion].checked) {
            data[currentQuestion].answer = "";
            data[currentQuestion].checked = false;
        }
        getImageDimensions(data[preElement - 1].question);
        setCurrentQuestion(preElement - 1);
    }
    const getImageDimensions = (question: any) => {
        const parsedContent = new DOMParser().parseFromString(question, 'text/html');
        const imgElement: any = parsedContent.querySelector('img');
        if (imgElement) {
            const img = new Image();
            img.src = imgElement.getAttribute('src');
            img.onload = () => {
                setImageSize(img.height);
            };
        }
    };

    return (
        <div className="row  py-3">
            <div className={`modal fade ${canShowPopup ? 'show' : 'false'}`} style={{ display: (canShowPopup ? "block" : 'none') }} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">You have successfully Submitted worksheet</h5>
                            <button type="button" className="close popup-close cursor-pointer" data-dismiss="modal" aria-label="Close" onClick={handleclose}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary cursor-pointer" onClick={handleclose}>OK</button>
                            <button type="button" className="btn btn-secondary cursor-pointer" data-dismiss="modal" onClick={handleclose}>CANCEL</button>
                        </div>
                    </div>
                </div>
            </div>
            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading && <div>
                <nav aria-label="breadcrumb">
                    {type === "TESTS" && <ol className="breadcrumb pb-2 ps-0 pt-0 border-bottom">
                        <li className="breadcrumb-item "><Link to="/student-dashboard/list"><span className='pe-2'>Dashboard</span></Link></li> /
                        <li className="breadcrumb-item "><Link to={`/student-dashboard/list/abhyas/test-level-status/${moduleUUID}/${userCode}/${schoolCode}`}><span>Abhyaas</span></Link></li> /
                        {/* <li className="breadcrumb-item "><Link to={`/student-dashboard/list/abhyas/progress-view/${moduleUUID}/${userCode}/${selectUuids?.school_code}/${selectUuids?.board_uuid}/${selectUuids?.medium_uuid}/${selectUuids?.standard_uuid}/${selectUuids?.subject_uuid}`}><span>{testInfo?.subject_name}</span></Link></li> / */}
                        <li className="breadcrumb-item "><span> {testInfo?.subject_name?.toUpperCase()} Assessment {testInfo?.test_no}</span></li>
                    </ol>}
                    {type === 'WORKSHEETS' && <ol className="breadcrumb pb-2 ps-0 pt-0 border-bottom">
                        <li className="breadcrumb-item "><Link to="/student-dashboard/list"><span className='pe-2'>Dashboard</span></Link></li> /
                        <li className="breadcrumb-item "><Link to={`/student-dashboard/list/abhyas/test-level-status/${moduleUUID}/${userCode}/${schoolCode}`}><span>Abhyaas</span></Link></li> /
                        <li className="breadcrumb-item "><Link to={`/student-dashboard/list/abhyas/progress-view/${moduleUUID}/${userCode}/${selectUuids?.school_code}/${selectUuids?.board_uuid}/${selectUuids?.medium_uuid}/${selectUuids?.standard_uuid}/${selectUuids?.subject_uuid}`}><span>{testInfo?.subject_name?.toUpperCase()} Dashboard </span></Link></li> /
                        <li className="breadcrumb-item "><span>Assessment Worksheet</span></li>
                    </ol>}
                </nav>
                {type === 'WORKSHEETS' && <h5 className='text-center mt-2 mb-4 '> Worksheet : {testInfo?.module_code === "PARIKSHA" ? "Assigned by PARIKSHA" : "Assessment Tests"}</h5>}
                {canShowHeader &&
                    <div id='navbar--fixed-top' >
                        <div className=' p-3 time-block  mt-3 d-md-flex justify-content-between align-items-end'>
                            {type === 'TESTS' && <div>
                                <h2 className="m-0"> Test - {testInfo?.test_no < 9 && <span>0</span>}{testInfo?.test_no ? testInfo?.test_no : ''}</h2>
                                <h3 className="m-0"> Time Limit -  {minutes} : {seconds < 9 ? `0${seconds}` : seconds}  mins</h3>
                            </div>}
                            {type === 'WORKSHEETS' && <div>
                                <h2 className="m-0"> {testInfo?.topic_name}</h2>
                                <h3 className="m-0"> {testInfo?.name}</h3>
                                <h3 className="m-0"> Worksheets </h3>
                                {worksheets?.map((item: any, i: number) => (
                                    <div key={i} className="circle cursor-pointer" style={{ backgroundColor: getCircleColor(item) }}>
                                        <p>{i + 1}</p>
                                    </div>
                                ))}
                            </div>}
                            {type !== 'WORKSHEETS' && <div>
                                {/* <p className='mt-3 mb-0 m-md-0 f12'>Remaining Time</p> */}
                                <TimerInfo
                                    minutes={minutes}
                                    seconds={seconds}
                                    startTimer={startTimer}
                                    time_remain={(value: any) => {
                                        if (value === '') {
                                            time_remain_storage = "5";
                                        } else {
                                            time_remain_storage = value;
                                            completedTime = time_remain_storage;
                                        }

                                    }}
                                    timeUp={() => {
                                        if (type === "TESTS") {
                                            handleCompleteSubmit();
                                        }
                                        timeUp = true;
                                    }} />
                            </div>}

                        </div>
                    </div>}
                {questionsData?.length > 0 && <div className='row'>
                    <div className='col-9 mt-3'>
                        <div className='q_display_block'>
                            <MathJaxContext>
                                <MathJax>
                                    <div  >
                                        <div className='row'>
                                            {/* <div className='col-2 col-md-1' style={{ alignSelf: 'center' }}>
                                                <div>
                                                    <p className='fw-bold mt-1' style={{ fontSize: '22px' }}>
                                                        Q{questionsData[currentQuestion]?.id}:
                                                    </p>
                                                </div>
                                            </div> */}
                                            <div className='col-12'>
                                                <div className='d-flex'>
                                                    <p className='fw-bold mb-0' style={{ fontSize: '22px' }}>
                                                        Q{questionsData[currentQuestion]?.id}:
                                                    </p>
                                                    <div className='ms-4 ps-3'>
                                                        <span className='fw-bold' style={{ fontSize: '22px' }}>
                                                            <div className={`${imageSize > 190 ? "img_ck_lg" : "img_ck"}`} dangerouslySetInnerHTML={onViewQuestion(questionsData[currentQuestion]?.question)}></div>
                                                        </span>
                                                        {/* {getDomElements(questionsData[currentQuestion]?.question, "p") && <span className='fw-bold' style={{ fontSize: '22px' }}>
                                                            <div className='text-break'>
                                                                {getDomElements(questionsData[currentQuestion]?.question, "p")}
                                                            </div>
                                                        </span>}
                                                        {getDomElements(questionsData[currentQuestion]?.question, "img") && <div >
                                                            <img className="img_mathjx" src={getDomElements(questionsData[currentQuestion].question, "img")} alt="" />
                                                        </div>} */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <form>
                                            {questionsData[currentQuestion]?.options?.map((options: any, j: number) => (
                                                <div className='row pt-3' key={j}>
                                                    <div className='col-2 col-md-1'>
                                                        <span>
                                                            <input className={`mb-0 cursor-pointer ${getDomElements(options?.value, "img") && "mt-5"}`} type="radio" name="option" checked={questionsData[currentQuestion]?.answer === alphabets[j] ? true : false} onChange={(e) => { handleQuestionOptions(options, currentQuestion, j) }}
                                                                onKeyDown={(e) => handleKeyDown(e, currentQuestion)}
                                                            // value={question.answer = question?.correct_answer}
                                                            />
                                                        </span>
                                                    </div>
                                                    <div className={`col-9`}>
                                                        {/* {getDomElements(options?.value, "p") && <span>
                                                            <div className='text-break mb-0'>
                                                                {getDomElements(options.value, "p")}
                                                            </div>
                                                        </span>}
                                                        {getDomElements(options?.value, "img") && <div>
                                                            <img className="img_mathjx_optn" src={getDomElements(options?.value, "img")} alt="" />
                                                        </div>} */}
                                                        <span className='img_ck_otp ' dangerouslySetInnerHTML={onViewQuestion(options.value)}></span>
                                                    </div>
                                                </div>
                                            ))}
                                        </form>
                                    </div>

                                </MathJax>
                            </MathJaxContext>
                        </div>
                        <div className='d-flex justify-content-center mt-3'>
                            <button className={`me-2 btn-test ${currentQuestion === 0 && "bg-white text-secondary"}`} onClick={handlePrevious} disabled={currentQuestion === 0} >
                                Back
                            </button>
                            <button className=' me-2 btn-test ' onClick={handleClearSelection}>Clear Selection</button>
                            <button className={` btn-test ${currentQuestion === questionsData.length - 1 && "bg-white text-secondary"}`} onClick={handleNext} disabled={currentQuestion === questionsData.length - 1}>
                                Save and Next
                            </button>
                        </div>
                    </div>
                    <div className='col-3'>
                        <div className='ms-3'>
                            <div className='q_board mt-3 justify-content-center'>
                                <h3 className='text-danger f18 text-center mt-1 pb-2 border-bottom'>Total Questions</h3>
                                <div className='question_no_block border-bottom'>
                                    <div className="question-pattern  py-2 ">
                                        {questionsData.map((questionNo: any, i: number) => (
                                            <span key={i} className={`number cursor-pointer  ${questionNo.id == currentQuestion + 1 ? "bg-secondary" : questionNo?.checked ? "q-primary" : "q-danger"}`} onClick={() => selectQuestion(questionNo.id)}>
                                                {questionNo.id}
                                            </span>
                                        ))}
                                    </div>
                                </div>
                                <div className='d-flex justify-content-around mt-1'>
                                    <div className='d-flex'>
                                        <div className='square-answer'></div>
                                        <div className='answer f14 fw-bold'>Answered</div>
                                    </div>
                                    <div className='d-flex ps-2'>
                                        <div className='square-not-answer'></div>
                                        <div className='not-answer f14 fw-bold'>Not Answered</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {questionsData.length > 0 &&
                            <div className="py-2 text-center mt-1" style={{ paddingRight: '40px' }}>
                                <button type='button' className="btn submit-btn-test mb-3 px-3 py-2 cursor-pointer" onClick={handleCompleteSubmit}>Submit Test</button>
                            </div>
                        }
                    </div>
                </div>}
                {/* <div>
                    <MathJaxContext>
                        <MathJax>
                            {questionsData?.map((question: any, i: number) => (
                                <div className="mt-2" key={i}>
                                    <div className='row'>
                                        <div className='col-2 col-md-1' style={{ alignSelf: 'center' }}>
                                            <span className='fw-bold' style={{ fontSize: '22px' }}>
                                                Q{question?.id}:
                                            </span>
                                        </div>
                                        <div className='col-10 col-md-11' style={{ paddingTop: '15px' }}>
                                          
                                            {getDomElements(question?.question, "p") && <span className='fw-bold' style={{ fontSize: '22px' }}>
                                                <div className='text-break'>
                                                    {getDomElements(question?.question, "p")}
                                                </div>
                                            </span>}
                                            {getDomElements(question?.question, "img") && <div>
                                                <img className="img_mathjx" src={getDomElements(question.question, "img")} alt="" />
                                            </div>}
                                        </div>
                                    </div>
                                    <form>
                                        {question?.options?.map((options: any, j: number) => (
                                            <div className='row pt-3' key={j}>
                                                <div className='col-2 col-md-1'>
                                                    <span>
                                                        <input className={`mb-0 cursor-pointer ${getDomElements(options?.value, "img") && "mt-5"}`} type="radio" name="option" onChange={(e) => { handleQuestionOptions(options, i, j) }}
                                                            onKeyDown={(e) => handleKeyDown(e, i)}
                                                        />
                                                    </span>
                                                </div>
                                                <div className={`col-9`}>
                                                    {getDomElements(options?.value, "p") && <span>
                                                        <div className='text-break mb-0'>
                                                            {getDomElements(options.value, "p")}
                                                        </div>
                                                    </span>}
                                                    {getDomElements(options?.value, "img") && <div>
                                                        <img className="img_mathjx_optn" src={getDomElements(options?.value, "img")} alt="" />
                                                    </div>}
                                                </div>
                                            </div>
                                        ))}
                                    </form>
                                </div>
                            ))}
                        </MathJax>
                    </MathJaxContext>
                </div>
                {questionsData.length > 0 &&
                    <div className="py-3 text-center mt-4 border-top">
                        <button className="btn btn-primary mb-3 px-5 cursor-pointer" onClick={handleCompleteSubmit}>Submit</button>
                        <Link to={`/student-dashboard/list/abhyas/progress-view/${moduleUUID}/${userCode}/${selectUuids?.school_code}/${selectUuids?.board_uuid}/${selectUuids?.medium_uuid}/${selectUuids?.standard_uuid}/${selectUuids?.subject_uuid}`}><button className='btn btn-link mb-3'>Cancel</button></Link>
                    </div>
                } */}
            </div>}
        </div >
    )
}
