import React, { useEffect, useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom';
import { CREATE_DEPARTMENT, GET_DEPARTMENT_BY_ID, UPDATE_DEPARTMENT } from '../../../app/service/department.service';
import FormBuilder from '../../../components/form-builder';
import { FormControlError, FormField, FormValidators } from '../../../components/form-builder/model/form-field';
import { FormValidator, GetControlIsValid } from '../../../components/form-builder/validations';
import { toast } from 'react-toastify';

export const DepartmentForm = () => {
    const [departmentData, setDepartmentData] = useState<any>({});
    const [adminvalidationErrors, setDepartmentvalidationErrors] = useState<FormControlError[]>([]);
    const [currentDepartmentData, setCurrentDepartmentData] = useState<any>({});
    const [loading, setLoading] = useState(false);
    const [nameError, setNameError] = useState('');
    const [descriptionError, setDescriptionError] = useState('');
    const history = useHistory();
    let { id } = useParams<{ id: string }>();
    const departmentId = parseInt(id);
    const departmentFormValidations = [
        new FormField('department_name', [FormValidators.REQUIRED]),
        new FormField('department_description', [FormValidators.REQUIRED]),
    ];


    useEffect(() => {
        if (departmentId > 0) {
            setLoading(true);
            GET_DEPARTMENT_BY_ID(departmentId).then((res: any) => {
                if (res) {
                    if (res.status === 'fail') {
                        toast.error(res.message);
                        setLoading(false);
                    } else {
                        setLoading(false);
                        setCurrentDepartmentData(res.rows[0]);
                    }
                }
            });
        }
    }, [departmentId]);

    function createDepartment() {
        const departmentsData = departmentData.value ? { ...departmentData.value } : { ...currentDepartmentData };
        const errors: FormControlError[] = FormValidator(departmentFormValidations, departmentsData);
        setDepartmentvalidationErrors(errors);
        Object.keys(departmentsData).forEach(function (key) {
            if (typeof departmentsData[key] === 'string') {
                departmentsData[key] = departmentsData[key].trim();
            }
        });
        if (onChangeName(departmentsData.department_name) && onChangeDescription(departmentsData.department_description)) {
            setLoading(true);
            CREATE_DEPARTMENT(departmentsData).then((res: any) => {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                    setCurrentDepartmentData(departmentsData);
                } else {
                    toast.success("Department Created Successfully");
                    history.push('/dashboard/department');
                    setLoading(false);
                }
            });
        } else {
            if (!nameError && !onChangeName(departmentsData.department_name)) {
                setNameError('Please enter department name');
            }
            if (!descriptionError && !onChangeDescription(departmentsData.department_description)) {
                setDescriptionError('Please enter department description');
            }
        }
    }

    function updateDepartment() {
        const departmentsData = departmentData.value ? { ...departmentData.value } : { ...currentDepartmentData };
        const errors: FormControlError[] = FormValidator(departmentFormValidations, departmentsData);
        delete departmentsData.department_code;
        delete departmentsData.sub_department;
        delete departmentsData.status;
        delete departmentsData.created_at;
        delete departmentsData.updated_at;

        setDepartmentvalidationErrors(errors);
        if (onChangeName(departmentsData.department_name) && onChangeDescription(departmentsData.department_description)) {
            departmentsData.id = departmentId;
            setLoading(true);
            UPDATE_DEPARTMENT(departmentsData).then((res: any) => {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    toast.success("Department Updated Successfully");
                    history.push('/dashboard/department');
                    setLoading(false);
                }
            });
        } else {
            if (!nameError && !onChangeName(departmentsData.department_name)) {
                setNameError('Please enter department name');
            }
            if (!descriptionError && !onChangeDescription(departmentsData.department_description)) {
                setDescriptionError('Please enter department description');
            }
        }
    }

    const getDepartmentInputValid = (control: string) => {
        const value = GetControlIsValid(adminvalidationErrors, control);
        return value;
    }

    const handleDepartmentInput = (data: any) => {
        data.value = { ...currentDepartmentData, ...data.value };
        setDepartmentData(data);
        const errors: any = FormValidator(departmentFormValidations, data.value);
        setDepartmentvalidationErrors(errors);
    };

    const onChangeName = (event: any) => {
        setNameError('');
        const reg = /^[A-Za-z]+(?:[ _-][A-Za-z]+)*$/;

        if (event) {
            if (!reg.test(event.trim())) {
                setNameError('Department name can`t contain numbers and special characters');
            } else {
                return true;
            }
        } else {
            setNameError('Please enter department name');
        }
    }

    const onChangeDescription = (event: any) => {
        setDescriptionError('');
        if (event) {
            if (event.trim().length <= 2) {
                setDescriptionError('Department description minimum 3 characters');
            } else if (event.trim().length >= 256) {
                setDescriptionError('Department description maximum 256 characters');
            } else {
                return true;
            }
        } else {
            setDescriptionError('Please enter department description');
        }
    }

    return (

        <div>
            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading && <div>
                <div className="row border-top border-primary py-3">
                    {departmentId === 0 && <h2>Create Department</h2>}
                    {departmentId > 0 && <h2>Update Department</h2>}
                </div>
                <FormBuilder onUpdate={handleDepartmentInput}>
                    <form>
                        <div className="row custom-form">
                            <div className="col-md-4">
                                <div className="mb-4">
                                    <label className="form-label">Department Name</label>
                                    <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>*</span>
                                    <input className="form-control form-control-lg" type="text" name="department_name" defaultValue={currentDepartmentData.department_name} onChange={(e) => onChangeName(e.target.value)} placeholder="Please enter department name" />
                                    {nameError && <p className="text-danger">{nameError}</p>}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="mb-4">
                                    <label className="form-label">Department Description</label>
                                    <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>*</span>
                                    <br />
                                    <input className="form-control form-control-lg" type="text" name="department_description" defaultValue={currentDepartmentData.department_description} onChange={(e) => onChangeDescription(e.target.value)} placeholder="Please enter department description" />
                                    {descriptionError && <p className="text-danger">{descriptionError}</p>}

                                </div>
                            </div>
                        </div>
                    </form>
                </FormBuilder>
                <div className="form-footer border-top border-primary py-3 text-end mt-4">
                    {departmentId === 0 && < a className="btn btn-primary btn-sm px-5 rounded-12 cursor-pointer" onClick={() => createDepartment()}>Create</a>}
                    {departmentId > 0 && <a className="btn btn-primary btn-sm px-5 rounded-12 cursor-pointer" onClick={() => updateDepartment()}>Update</a>}
                    <Link className="text-dark text-decoration-none ms-2" to={`/dashboard/department`}>Cancel</Link>
                </div>
            </div>}
        </div >
    )
}
