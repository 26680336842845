import React from 'react'
import { Route, Switch } from 'react-router'
import { SponsorReportList } from '../pages/dashboard/sponsor-report/sponsor-list/list';
import { SopnsorReportsData } from '../pages/dashboard/sponsor-report/report/view';



const NestedSponsorReport = () => {
    return (
        <Switch>
            <Route exact path="/dashboard/sponsor-report/list" component={SponsorReportList}></Route>
            <Route path="/dashboard/sponsor-report/report" component={SopnsorReportsData}></Route>

        </Switch>
    )
}

export default NestedSponsorReport;