import React, { Fragment, useEffect, useRef, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import { GET_MNS_STUDENT_STATUS } from '../../../../app/service/mns-service';
import { toast } from 'react-toastify';
import FormBuilder from '../../../../components/form-builder';



export const TestResultData = () => {
    let { level, mode, moduleUUID, userCode, schoolCode, testNo } = useParams<{ level: string, mode: string, moduleUUID: string, userCode: string, schoolCode: string, testNo: string }>();
    const [loading, setLoading] = useState(false);
    const [testData, setTestData] = useState<any>({});
    const [testResult, setTestResult] = useState<any>([])


    useEffect(() => {
        getMNSStudentStatus();

    }, [])

    function getMNSStudentStatus() {
        let payload = {
            module_uuid: moduleUUID,
            school_code: schoolCode,
            user_code: userCode
        }
        setLoading(true);

        GET_MNS_STUDENT_STATUS(payload).then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res.message);
                setLoading(false);

            } else {
                if (res) {
                    const data = res;
                    setTestData(data?.assesment_test);
                    setTestResult(data?.assesment_test?.questions_list)
                    setLoading(false);

                }

            }

        });
    }

    return (
        <>
            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {
                !loading && <div>

                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb pb-2 ps-0 pt-0 border-bottom">
                            <li className="breadcrumb-item "><Link to="/student-dashboard/list"><span className='pe-2'>Dashboard</span></Link></li> /
                            <li className="breadcrumb-item "><Link to={`/student-dashboard/list/test-success/${level}/${mode}/${moduleUUID}/${userCode}/${schoolCode}/${testNo}`}><span>MNS Test</span></Link></li> /
                            <li className="breadcrumb-item "><span>Entry Test Results</span></li>
                        </ol>
                    </nav>
                    <h2 className="f22 fw-bold">
                        Entry Test<span className="fw-light ms-2">(Total Score : {testData?.student_score})</span>
                    </h2>

                    <div className="row question-block mt-4 justify-content-between">
                        <div className="col-lg-8">
                            {testResult?.map((question: any, i: number) => (
                                <Fragment key={i}>
                                    {question.list.length > 1 && <div className='col-md-12'>
                                        {/* <div className=' q-number'>Q{question.q_number}: </div> */}
                                        <div className='d-flex justify-content-between'>
                                            <div className=' q-number'>Q{question.q_number}: </div>
                                            <p className='mb-0'>
                                                <span className=' marked_text f16'>Score :<span className=' ms-1 text-black'>{question?.score}</span></span>
                                            </p>
                                        </div>
                                    </div>}
                                    {question.list.map((questionName: any, j: number) => (
                                        <div className={`row ${question.list.length === 1 && "mb-md-2 mb-lg-3"}`} key={j}>
                                            {question.list.length === 1 && <div className='col-md-8 pt-2'>
                                                <span className=' q-number'>Q{question.q_number}: </span>
                                                <span className=' q-name'>
                                                    {questionName.question}
                                                </span>
                                            </div>}
                                            {question.list.length > 1 && <div className='col-md-5 pt-md-2'>
                                                <span className=' q-name'>
                                                    <span className=' q-name'>{j === 0 ? "a:" : ''}</span>
                                                    <span className=' q-name'>{j === 1 ? "b:" : ''}</span>
                                                    <span className=' q-name'>{j === 2 ? "c:" : ''}</span>
                                                    {questionName.question}
                                                </span>
                                            </div>}
                                            <div className='col-md-4 pt-2'>
                                                {questionName.question_type === 'input_text' &&
                                                    <input type="text" className="form-control q-input" name="option" disabled
                                                        defaultValue={question?.list[j].student_ans}
                                                    />
                                                }
                                            </div>
                                            <div className='col-md-3 pt-2'>
                                                <p className='marked_text f16'>
                                                    {questionName?.result === "correct" && <i className='fa fa-check-circle  f18 text-completed me-2 pt-1'></i>}
                                                    {questionName?.result === "wrong" && <i className='fa fa-times-circle-o f18 me-2 text-inprogess pt-1'></i>}
                                                    Correct Answer : <span className='text-black'>{questionName.ans}</span>
                                                    {question.list.length === 1 && <span className=' marked_text f16 ms-3'>Score :<span className=' ms-1 text-black'>{question?.score}</span></span>}
                                                </p>
                                            </div>
                                        </div>
                                    ))}
                                </Fragment>
                            ))}

                        </div>

                    </div>
                </div>}
        </>)

}