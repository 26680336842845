import React, { useEffect, useState, useRef } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { DELETE_DEPARTMENT, GET_DEPARTMENT, GET_DEPARTMENT_SEARCH } from '../../../app/service/department.service';
import { DataTable } from '../../../components/data-table';
import { DepartmentDataGridCols } from './data-grid-cols'
import { toast } from 'react-toastify';
import moment from 'moment';
import FormBuilder from '../../../components/form-builder';

export const DepartmentList = () => {
    const [pageArray, setPageNumbers] = useState([]);
    const [activePage, setActivePage] = useState<any>();
    const [departmentList, setDepartmentList] = useState<any>([]);
    const [loading, setLoading] = useState(false);
    const departmentForm = useRef<any>({});
    const [searchData, setSearchData] = useState<any>({});
    const search = useLocation().search;
    const currentPageNumber: any = new URLSearchParams(search).get('page');
    const [accessObjectsList, setAccessObjectsList] = useState<any>([]);
    const [departmentAccessList, setDepartmentAccessList] = useState<any>([]);
    const history = useHistory();

    useEffect(() => {
        const accessObjectsData: any = localStorage.getItem("access_objects_data");
        setAccessObjectsList(JSON.parse(accessObjectsData));
        handleDepartmentCols(JSON.parse(accessObjectsData));
        getDepartments();
    }, []);

    function getDepartments() {
        const activePageNumber = currentPageNumber > 0 ? (currentPageNumber - 1) : 0;
        setLoading(true);
        GET_DEPARTMENT(activePageNumber).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    setActivePage(currentPageNumber ? currentPageNumber : 1);
                    const data = res.rows;
                    data.forEach((element: any) => {
                        element.created_at = moment(element.created_at.split("T")[0]).format('DD-MM-yyyy');
                    });
                    setDepartmentList(data);
                    const pageNumbers = Math.ceil(res.count / 10);
                    const pageArray: any = [];
                    for (let i = 1; i <= pageNumbers; i++) {
                        pageArray.push(i);
                    }
                    setPageNumbers(pageArray);
                    setLoading(false);
                }
            }
        });
    }

    const onPageChange = (data: any) => {
        if (data) {
            history.push(`/dashboard/department?page=${data}`);
        }
        setActivePage(data);
        const pageNumber = data - 1;
        setLoading(true);
        GET_DEPARTMENT(pageNumber).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    const data = res['rows'];
                    data.forEach((element: any) => {
                        element.created_at = moment(element.created_at.split("T")[0]).format('DD-MM-yyyy');
                    });
                    setDepartmentList(data);
                    setLoading(false);
                }

            }
        });
    }

    const onEditDepartment = (data: any) => {
        history.push(`/dashboard/department/form/${data.id}`);
    }

    const onDeleteDepartment = (data: any) => {
        setLoading(true);
        const id = data.id;
        DELETE_DEPARTMENT(id).then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res.message);
                setLoading(false);
            } else {
                setLoading(false);
                toast.success("Department Deleted Successfully");
                getDepartments();
            }
        });
    }

    const handleInput = (data: any) => {
        setSearchData(data);
    };

    function handleSearch() {
        const data = { ...searchData.value };
        if (data) {
            setLoading(true);
            for (const key of Object.keys(data)) {
                if (data[key] === "") {
                    delete data[key];
                }
            }
            GET_DEPARTMENT_SEARCH(data).then((res: any) => {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    history.push(`/dashboard/department?page=${1}`);
                    setActivePage(1);
                    const data = res.rows;
                    data.forEach((element: any) => {
                        element.created_at = moment(element.created_at.split("T")[0]).format('DD-MM-yyyy');
                    });
                    setDepartmentList(data);
                    const pageNumbers = Math.ceil(res.count / 10);
                    const pageArray: any = [];
                    for (let i = 1; i <= pageNumbers; i++) {
                        pageArray.push(i);
                    }
                    setPageNumbers(pageArray);
                    setLoading(false);
                }
            });
        }
    }

    function handleRefresh() {
        const data = searchData;
        data.value = {};
        setSearchData({ ...data });
        getDepartments();
        departmentForm.current.reset();
    }

    const handleDepartmentCols = (accessObjectsList: any) => {
        const preparedData = accessObjectsList?.filter((el: any) => el.object_code === "DEPARTMENTS");
        setDepartmentAccessList(preparedData);
        if (preparedData?.length > 0) {
            for (let i = 0; i < preparedData[0].child.length; i++) {
                for (let j = 0; j < DepartmentDataGridCols.length; j++) {
                    if (preparedData[0].child[i].object_code === "DEPARTMENTS-EDIT" && preparedData[0].child[i].p_read === 1) {
                        if (DepartmentDataGridCols[j].title == "Actions") {
                            DepartmentDataGridCols[j].isEdit = true;
                        }

                    }

                    if (preparedData[0].child[i].object_code === "DEPARTMENTS-DELETE" && preparedData[0].child[i].p_read === 1) {
                        if (DepartmentDataGridCols[j].title == "Actions") {
                            DepartmentDataGridCols[j].isDelete = true;
                        }
                    }

                    if ((preparedData[0].child[i].object_code === "DEPARTMENTS-DELETE" && preparedData[0].child[i].p_read === 1) ||
                        (preparedData[0].child[i].object_code === "DEPARTMENTS-EDIT" && preparedData[0].child[i].p_read === 1)) {
                        if (DepartmentDataGridCols[j].title == "Actions") {
                            DepartmentDataGridCols[j].canShowColumn = true;
                        }
                    }
                }
            }
        }
    }

    function onHandleNew() {
        if (departmentAccessList.length > 0) {
            for (let i = 0; i < departmentAccessList[0].child.length; i++) {
                if (departmentAccessList[0].child[i].object_code === "DEPARTMENTS-NEW" && departmentAccessList[0].child[i].p_read === 1) {
                    return true;
                }
            }
        }
    }

    return (
        <div className="row border-top border-primary py-3">
            <div className="row">
                <div className="col-md-10 col-7">
                    <h2 className='mt-1 mt-md-0'>Departments</h2>
                </div>
                {onHandleNew() && <div className="border-primary py-md-3 py-1 text-end col-md-2 col-5 ps-4 ps-md-0">
                    <Link to={`/dashboard/department/form/0`} className="btn btn-primary btn-sm px-5 rounded-12">New</Link>
                </div>}
            </div>
            <div className="row" style={{ marginBottom: '15px' }}>
                <div className="col-md-3 my-3 my-md-0">
                    <FormBuilder onUpdate={handleInput}>
                        <form ref={departmentForm}>
                            <div className="row">
                                <div className='col-md-12'>
                                    <div className='row'>
                                        <div className="col-md-12">
                                            <input type="text" className="form-control ft-14" placeholder="Search with department name" name="department_name" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </FormBuilder>
                </div>
                <div className="col-md-4  text-end text-md-start">
                    <button className="btn btn-primary px-3 cursor-pointer" type="button" onClick={() => handleSearch()}>Search</button>&nbsp;
                    <button className="btn clear-btn cursor-pointer" type="button" onClick={() => handleRefresh()}>
                        Clear
                    </button>
                </div>
            </div>
            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading && <DataTable TableCols={DepartmentDataGridCols} tableData={departmentList} editInfo={onEditDepartment} deleteInfo={onDeleteDepartment}
                activePageNumber={activePage} pageNumber={onPageChange} pageNumbers={pageArray}></DataTable>}
        </div>
    )
}
