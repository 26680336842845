import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { MathJaxContext, MathJax } from 'better-react-mathjax';
import TimerInfo from '../dashboard/mns/timer/timerinfo';
import { FETCH_ABHYAS_USER_QUESTION_PAPER, UPDATE_ABHYAS_USER_QUESTION_PAPER } from '../../app/service/abhyas-module-questions.service';
import { FETCH_PARIKSHA_USER_QUESTION_PAPER, UPDATE_PARIKSHA_USER_QUESTION_PAPER } from '../../app/service/pariksha-module-questions';

declare const window: any;


export const ParikshaMobileQuestionsForm = () => {
    const [questionsData, setQuestionsData] = useState<any>([]);
    const [loading, setLoading] = useState(false);
    const [startTimer, setStartTimer] = useState(false);
    const [canShowHeader, setShowHeader] = useState(false);
    const [timeRemain, setTimeRemain] = useState<any>('');
    const [startTime, setStartTime] = useState(new Date());
    const [alphabets, setAlphabets] = useState<any>(["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z"]);
    const [canShowPopup, setCanShowPopup] = useState(false);
    const [test_start_date, setTestStartDate] = useState<any>('');
    const history = useHistory();
    const [minutes, setMinutes] = useState(60);
    const [seconds, setSeconds] = useState(0);
    let completedTime: string = "";
    let time_remain_storage = '';
    let timeUp = false;
    const [isActive, setIsActive] = useState<boolean>(false);
    let selectUuids = JSON.parse(sessionStorage.getItem('userSelectedSubjectInfo') || '{}');
    const questionRefs = useRef<(HTMLInputElement | null)[]>([]);

    const [appData, setAppData] = useState<any>({});
    const [timeRemainForApp, setTimeRemainForApp] = useState<string>('');
    const [webviewInitialized, setWebviewInitialized] = useState<boolean>(false);
    const [attemptQuestionCount, setAttemptQuestionCount] = useState<number>(0);


    useEffect(() => {
        document.addEventListener("message", handleNativeMessage);

        return () => {
            window.removeEventListener('message', handleNativeMessage);
        };
    }, []);


    useEffect(() => {
        if (!webviewInitialized) {
            setWebviewInitialized(true);
            sendMessageToApp({ action: 'init_webview', type: '', data: 'initialized' });
        }

    }, [webviewInitialized]);


    function handleNativeMessage(message: any) {
        let data = JSON.parse(message.data);
        if (data?.key && data?.key === 'init') {
            setAppData(data);
            getTimeDetails(data);
            localStorage.setItem("token",
                data.token);
            setLoading(true);
            setTimeout(() => {
                setShowHeader(true);
                setStartTimer(true);
                setMinutes(data?.time);
                if (data?.questions) {
                    setQuestionsData(data?.questions);
                }
                setLoading(false);

            }, 100);
        } else {

        }
    }



    function getTimeDetails(data: any) {
        // const testStartDate: any = sessionStorage.getItem("abhyas_test_start_date");

        // return testStartDate ? testStartDate : null;
        const testStartDate: any = data.abhyas_test_start_date;
        setTestStartDate(testStartDate ? testStartDate : null);

        let time = getTime(testStartDate);
        let timeMillis = moment(time, 'YYYY-MM-DD hh:mm:ss').toDate().getTime();
        let reqTimeMillis = 2 * 24 * 60 * 60 * 1000;
        let actualTimeToComplete = timeMillis + reqTimeMillis;
        let x = new Date(actualTimeToComplete)
        setTimeRemain(x);

        let currentTime = new Date().getTime();
        let timerResult = x.getTime() - currentTime;
        let tSeconds = Math.floor(timerResult / 1000);
        let hours = Math.floor(tSeconds / 3600);
        let minutes = Math.floor((tSeconds - (hours * 3600)) / 60); // get minutes
        let seconds = tSeconds - (hours * 3600) - (minutes * 60); //  get seconds
        setTimeRemainForApp((hours < 10 ? "0" + hours : hours) + ":" + (minutes < 10 ? "0" + minutes : minutes) + ":" + (seconds < 10 ? "0" + seconds : seconds) + ' hrs');

    }

    function getTime(time: any) {
        if (time !== 'null') {
            return time;
        } else {
            return time ? time : startTime;
        }
    }

    function onViewQuestion(name: any) {
        // let preElement = `<p class="mb-0">${name}</p>`
        return { __html: name };
    }

    function handleQuestionOptions(item: any, index: number, j: number) {
        const data = questionsData;
        for (let i = 0; i < alphabets.length; i++) {
            if (j === i) {
                data[index].answer = alphabets[i];
                data[index].checked = true;
            }
        }
        sessionStorage.setItem("questionsData", JSON.stringify(data));

        setQuestionsData(data);
    }


    const handleCompleteSubmit = () => {

        const payload = {
            "user_code": appData?.user_code,
            "school_code": appData?.school_code,
            "subject_uuid": appData?.subject_uuid,
            "type": appData?.type,
            "exam_type": appData?.exam_type,
            "test_time": `${minutes}.0`,
            "chapter_uuid": appData?.chapter_uuid ? appData?.chapter_uuid : "",
            "question_paper_uuid": appData?.question_paper_uuid,
            "time_taken": calculateTimeDifference() ? calculateTimeDifference() : "",
            "questions": questionsData.length > 0 ? questionsData : JSON.parse(sessionStorage.getItem("questionsData") || '{}')
        }

        setLoading(true);
        UPDATE_PARIKSHA_USER_QUESTION_PAPER(payload).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    const errorValue = Array.isArray(res?.err) ? res?.err[0] : res?.err;
                    toast.error(errorValue);
                    setLoading(false);
                } else {
                    setLoading(false);
                    const data = res;
                    if (appData?.type === 'WORKSHEETS') {
                        sendMessageToApp({ action: 'show_popup', type: 'worksheet_submitted', data: '' });
                        return;
                    }
                    sendMessageToApp({ action: 'show_popup', type: 'test_submitted', timeRemain: timeRemainForApp });
                }

            }
        });

    }

    const getCircleColor = (item: any) => {
        if (item?.status === "pending") {
            return '#FCEE21';
        } else if (item?.status === 'completed') {
            if (item.result === "fail") {
                return '#f15a24';

            } else {
                return '#8CC63F';

            }
        } else {
            return '#FFFFFF';
        }
    }

    const sendMessageToApp = (data: any) => {
        if (window?.ReactNativeWebView) {
            try {
                window?.ReactNativeWebView.postMessage(JSON.stringify(data));
            } catch (error) {

            }
        }
    }


    // Renderer callback with condition
    const renderer = ({
        days,
        hours,
        minutes,
        seconds,
        completed,
    }: {
        days: any;
        hours: any;
        minutes: any;
        seconds: any;
        completed: any;
    }) => {
        if (completed) {
            return <div></div>
            // Render a completed state
        } else {
            // Render a countdown
            return (
                <span style={{ fontSize: "1rem", paddingTop: "10px" }}>
                    {hours} Hr - {minutes} Min - {seconds} Sec
                </span>
            );
        }
    };
    const calculateTimeDifference = () => {
        const initialTime = `${"59"}:${"59"}`
        const [initialMinutes, initialSeconds] = initialTime.split(':').map(Number);
        const [finalMinutes, finalSeconds] = completedTime.split(':').map(Number);
        const initialTimeInSeconds = Number(initialMinutes) * 60 + Number(initialSeconds);
        const finalTimeInSeconds = Number(finalMinutes) * 60 + finalSeconds ? Number(finalSeconds) : 0;
        const differenceInSeconds = Number(initialTimeInSeconds * 100) - Number(finalTimeInSeconds * 100);

        if (differenceInSeconds >= 60) {
            var mins = moment.utc(moment(initialTime, "mm:ss").diff(moment(completedTime, "mm:ss"))).format("mm.ss")
            sessionStorage.setItem("setTime", mins === 'Invalid date' ? `${minutes}.${seconds}` : mins.replace('00', '0'));
            return mins === 'Invalid date' ? `${minutes}.${seconds}` : mins.replace('00', '0')
        } else {
            sessionStorage.setItem("setTime", `0.${differenceInSeconds}`);
            return `0.${differenceInSeconds}`;
        }

    };
    const getTimeTaken = (totalSeconds: number) => {
        const minutes = Math.floor(totalSeconds / 60);
        const seconds = totalSeconds % 60;
        let totalTime = `${minutes.toString().padStart(2, '0')}.${seconds.toString().padStart(2, '0')}`
        let finalTime = totalTime?.replace('00', '0')
        sessionStorage.setItem("setTime", `${Number(finalTime)}`);

        return Number(finalTime);
    }
    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>, currentIndex: number) => {
        if (event.key === 'Enter') {
            event.preventDefault(); // Prevent page reload
            const nextIndex = currentIndex + 1;
            if (nextIndex < questionRefs.current.length) {
                questionRefs.current[nextIndex]?.focus();
            }
        }
    };
    const submitTest = () => {
        const attemptQuestionsLength = questionsData.filter((el: any) => el.checked);
        if (attemptQuestionsLength?.length > 0) {
            setAttemptQuestionCount(attemptQuestionsLength?.length);
            setCanShowPopup(true);
        } else {
            toast.error("Please attempt at least one question");
            setAttemptQuestionCount(0);
        }

    }

    const onClickCancel = () => {
        setCanShowPopup(false);
    }
    const getDomElements = (htmlString: any, tag: string) => {
        const parsedContent = new DOMParser().parseFromString(htmlString, 'text/html');
        const pElements = parsedContent.querySelectorAll('p');
        const imgElement: any = parsedContent.querySelector('img');
        if (tag === "p") {
            const paragraphs: any[] = [];
            pElements.forEach((pElement) => {
                paragraphs.push(`<p class="mb-0">${pElement.innerHTML}</p>`);
            });
            return <div dangerouslySetInnerHTML={{ __html: paragraphs?.join('') }} />
        }
        if (tag === "img") {
            if (imgElement) {
                return imgElement ? imgElement.getAttribute('src') : ''
            } else {
                return "";
            }

        }
        return "";
    }
    return (
        <div className="row  py-3">
            <div
                className={`modal fade ${canShowPopup ? "show" : "false"}`}
                style={{ display: canShowPopup ? "block" : "none" }}
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog pariksha-model-dialog" role="document">
                    <div className="modal-content pariksha-model-content">
                        <div className="modal-body parent-info pariksha-model-info">
                            <div className='mt-3'>
                                <h5>You have attempted {attemptQuestionCount} out of {questionsData?.length} questions</h5>
                            </div>
                            <div className='mt-4 mb-3'>
                                <h5>Do you want to submit the test ?</h5>
                                <div className='mt-4'>
                                    <button className='btn test-btn' onClick={handleCompleteSubmit}>YES</button>
                                    <button className='btn test-btn' onClick={onClickCancel}>NO</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading && <div>

                {canShowHeader &&
                    <div className='abhyas-fixed-top  q_zindex'>
                        <div className='p-2 abhyas-logo'>
                            <i className="nav_logo-icon"></i>
                        </div>
                        <div className='row p-2 time-block-app d-md-flex justify-content-between align-items-end'>
                            {appData?.type === 'TESTS' && <div className='col-6'>
                                <h2 className="m-0"> {appData?.test_name && appData?.test_name}</h2>
                                <h3 className="m-0"> Time Limit -  {minutes} : {seconds < 9 ? `0${seconds}` : seconds}  mins</h3>
                            </div>}
                            {appData?.type === 'WORKSHEETS' && <div>
                                <h2 className="m-0"> {appData?.topic_name}</h2>
                                <h3 className="m-0"> {appData?.name}</h3>
                                <h3 className="m-0"> Worksheets </h3>
                                {appData?.worksheets?.map((item: any, i: number) => (
                                    <div key={i} className="circle cursor-pointer" style={{ backgroundColor: getCircleColor(item) }}>
                                        <p>{i + 1}</p>
                                    </div>
                                ))}
                            </div>}
                            {appData?.type !== 'WORKSHEETS' && <div className='col-6'>
                                {/* <p className='mt-3 mb-0 m-md-0 f12'>Remaining Time</p> */}
                                <TimerInfo
                                    minutes={minutes}
                                    seconds={seconds}
                                    startTimer={startTimer}
                                    time_remain={(value: any) => {
                                        if (value === '') {
                                            time_remain_storage = "5";
                                        } else {
                                            time_remain_storage = value;
                                            completedTime = time_remain_storage;
                                        }

                                    }}
                                    timeUp={() => {
                                        if (appData?.type === "TESTS") {
                                            handleCompleteSubmit();
                                        }
                                        timeUp = true;
                                    }} />
                            </div>}

                        </div>
                    </div>}

                <div className='px-3 pb-3 mt-2'>
                    <MathJaxContext>
                        <MathJax>
                            {questionsData?.map((question: any, i: number) => (
                                <div className="" key={i}>
                                    <div className='d-flex'>
                                        <div>
                                            <p className='fw-bold mb-0' style={{ fontSize: '22px' }}>
                                                Q{question?.id}:
                                            </p>
                                        </div>
                                        <div className='ms-3'>
                                            <span className='fw-bold' style={{ fontSize: '22px' }}>
                                                <div className='img_ck' dangerouslySetInnerHTML={onViewQuestion(question.question)}></div>
                                            </span>
                                        </div>
                                    </div>
                                    {/* <div className='row'>
                                        <div className='col-2 col-md-1' style={{ alignSelf: 'center' }}>
                                            <div>
                                                <p className='fw-bold mb-0' style={{ fontSize: '22px' }}>
                                                    Q{question?.id}:
                                                </p>
                                            </div>
                                        </div>

                                        <div className={`${getDomElements(question?.question, "img") ? "col-7 me-2" : "col-8"}`} style={{ paddingTop: '15px' }}>

                                            {getDomElements(question?.question, "p") && <span className='fw-bold' style={{ fontSize: '22px' }}>
                                                <div className='text-break'>
                                                    {getDomElements(question?.question, "p")}
                                                </div>
                                            </span>}
                                            {getDomElements(question?.question, "img") && <div >
                                                <img className=" img_mathjx_q" src={getDomElements(question.question, "img")} alt="" />
                                            </div>}
                                        </div>
                                    </div> */}
                                    <form>
                                        {question?.options?.map((options: any, j: number) => (
                                            <div className='row pt-3' key={j}>
                                                <div className='col-2 col-md-1'>
                                                    <span className={`${getDomElements(options?.value, "img") && "mt-5 "}`}>
                                                        <input className={`mb-0 cursor-pointer  ${getDomElements(options?.value, "img") && "mt-5 "}`} type="radio" name="option" onChange={(e) => { handleQuestionOptions(options, i, j) }}
                                                            onKeyDown={(e) => handleKeyDown(e, i)}
                                                        // value={question.answer = question?.correct_answer}
                                                        />
                                                    </span>
                                                </div>
                                                <div className={`col-9`}>
                                                    {/* {getDomElements(options?.value, "p") && <span>
                                                        <div className='text-break mb-0'>
                                                            {getDomElements(options?.value, "p")}
                                                        </div>
                                                    </span>}
                                                    {getDomElements(options?.value, "img") && <div>
                                                        <img className="w-75" src={getDomElements(options?.value, "img")} alt="" />
                                                    </div>} */}
                                                    <span className='img_ck_otp' dangerouslySetInnerHTML={onViewQuestion(options.value)}></span>
                                                </div>
                                            </div>
                                        ))}
                                    </form>
                                </div>
                            ))}
                        </MathJax>
                    </MathJaxContext>
                </div>
                {questionsData.length > 0 &&
                    <div className="py-3 text-center mt-4 border-top">
                        <button className="btn btn-primary mb-3 px-5 cursor-pointer" onClick={submitTest}>Submit</button>
                        {/* <Link to={`/student-dashboard/list/abhyas/progress-view/${moduleUUID}/${userCode}/${selectUuids?.school_code}/${selectUuids?.board_uuid}/${selectUuids?.medium_uuid}/${selectUuids?.standard_uuid}/${selectUuids?.subject_uuid}`}><button className='btn btn-link mb-3'>Cancel</button></Link> */}
                    </div>
                }
            </div>}
        </div >
    )
}
