import React from "react";
import NestedAbhyasList from "../../../../routes/insideabhyas";
export const AbhyasInfo = () => {
    return (
        <div>
            <div>
                <NestedAbhyasList />
            </div>
        </div>
    )
}