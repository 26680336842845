import { Switch, Route, Redirect } from "react-router";
import { StudentInFo } from "./student-info";


interface Props {
    match?: any;
}
const StudentRoutes = (props: Props) => {

    let url: string | undefined = props.match?.url;
    if (url?.endsWith('/')) {
        url = url.substr(0, url.length - 1);
    }

    return (
        <Switch>
            <Route path={`${url}/student/info/:id`} exact>
                <StudentInFo></StudentInFo>
            </Route>
        </Switch>
    );
}
export default StudentRoutes;