import { DataTableCol } from "../../../components/data-table/types";

export const SubscriptionGridCols: DataTableCol[] = [
  {
    title: "Name",
    control: "name",
    canShowColumn: true,
  },
  {
    title: "Price",
    control: "price",
    canShowColumn: true,
  },
];
