import React from 'react';
import ManageSchoolRoutes from './routes';

const ManageSchoolPage = (props: any) => {
    
    return (
        <div>
            <ManageSchoolRoutes {...props} />
        </div>
    );
};

export default ManageSchoolPage;