import React, { useEffect, useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { DELETE_USER, GET_USER_BY_SCHOOL_CODE } from '../../../../app/service/users.service'
import { DataTable } from '../../../../components/data-table'
import { AdminDataGridCols } from './data-grid-cols'

export const AdminLIst = () => {
    let { id, schoolCode } = useParams<{ id: string, schoolCode: string }>();
    const schoolId = parseInt(id);
    const schoolsCode = schoolCode;
    const [pageArray, setPageNumbers] = useState([]);
    const [activePage, setActivePage] = useState(1);
    const [adminList, setAdminList] = useState<any>([]);
    const [loading, setLoading] = useState(false);
    const [userRole, setUserRole] = useState<any>();
    const [accessObjectsList, setAccessObjectsList] = useState<any>([]);
    const [accessSchoolAdmin, setAccessSchooolAdmin] = useState(false);
    const [apiloaded, setApiloaded] = useState<any>(false);
    const history = useHistory();

    useEffect(() => {
        const userRole = localStorage.getItem("user_role");
        setUserRole(userRole);

        const accessObjectsData: any = localStorage.getItem("access_objects_data");
        setAccessObjectsList(JSON.parse(accessObjectsData));
        handleSchoolAdminCols(JSON.parse(accessObjectsData));

        if (schoolsCode) {
            setLoading(true);
            GET_USER_BY_SCHOOL_CODE(schoolsCode, 'SCHOOL_ADMIN', 0, {}).then((res: any) => {
                setApiloaded(true);
                if (res) {
                    if (res.status === 'fail') {
                        toast.error(res.message);
                        setLoading(false);
                    } else {
                        setLoading(false);
                        const data = res.rows;
                        if (data.length > 0) {
                            setAdminList(data);
                            const pageNumbers = Math.ceil(res.count / 10);
                            const pageArray: any = [];
                            for (let i = 1; i <= pageNumbers; i++) {
                                pageArray.push(i);
                            }
                            setPageNumbers(pageArray);
                        }
                    }
                }
            });
        }
    }, [schoolsCode]);

    const onPageChange = (data: any) => {
        setActivePage(data);
    }

    const onEditAdmin = (data: any) => {
        history.push(`/dashboard/school/info/${schoolId}/adminform/${schoolsCode}/${data.id}`);
        if (userRole === "CHAIRMAN" || userRole === "GENERAL_SECRETARY" || userRole === "INSTITUTE_ADMIN") {
            history.push(`/institute-dashboard/school/info/${schoolId}/adminform/${schoolsCode}/${data.id}`);

        }
    }

    const handleSchoolAdminCols = (accessObjectsList: any) => {
        const preparedData = accessObjectsList?.filter((el: any) => el.object_code === "SCHOOLS");
        if (preparedData?.length > 0) {
            for (let i = 0; i < preparedData[0].child.length; i++) {

                if (
                    preparedData[0].child[i].object_code === "SCHOOLS-SCHOOL-ADMIN" &&
                    preparedData[0].child[i].p_read === 1
                ) {
                    setAccessSchooolAdmin(true);
                }

                for (let j = 0; j < AdminDataGridCols.length; j++) {
                    if (preparedData[0].child[i].object_code === "SCHOOLS-SCHOOL-ADMIN-EDIT" && preparedData[0].child[i].p_read === 1) {
                        if (AdminDataGridCols[j].title == "Actions") {
                            AdminDataGridCols[j].isEdit = true;
                        }

                    }

                    if (preparedData[0].child[i].object_code === "SCHOOLS-SCHOOL-ADMIN-SET-PASSWORD" && preparedData[0].child[i].p_read === 1) {
                        if (AdminDataGridCols[j].title == "Actions") {
                            AdminDataGridCols[j].setPassword = true;
                        }
                    }

                    if ((preparedData[0].child[i].object_code === "SCHOOLS-SCHOOL-ADMIN-SET-EDIT" && preparedData[0].child[i].p_read === 1) ||
                        (preparedData[0].child[i].object_code === "SCHOOLS-SCHOOL-ADMIN-SET-PASSWORD" && preparedData[0].child[i].p_read === 1)) {
                        if (AdminDataGridCols[j].title == "Actions") {

                            AdminDataGridCols[j].canShowColumn = true;
                        }
                    }
                }
            }
        }


    }

    return (
        <div>
            {accessSchoolAdmin &&
                <>
                    {((adminList.length === 0 && apiloaded) && (userRole === 'SUPER_ADMIN' || userRole === 'INSTITUTE_ADMIN' || userRole === 'SCHOOL_ADMIN')) &&
                        <div className="border-primary py-3 text-end">
                            {userRole !== "CHAIRMAN" && userRole !== "GENERAL_SECRETARY" && userRole !== "INSTITUTE_ADMIN" && <Link to={`/dashboard/school/info/${schoolId}/adminform/${schoolsCode}/0`} className="btn btn-primary btn-sm px-5 rounded-12">New</Link>}

                            {(userRole === "CHAIRMAN" || userRole === "GENERAL_SECRETARY" || userRole === "INSTITUTE_ADMIN") && <Link to={`/institute-dashboard/school/info/${schoolId}/adminform/${schoolsCode}/0`} className="btn btn-primary btn-sm px-5 rounded-12">New</Link>}
                        </div>}
                    {loading &&
                        <div className="text-center p-5">
                            <div className="spinner-border" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                    }

                    {!loading &&
                        <DataTable TableCols={AdminDataGridCols} tableData={adminList} editInfo={onEditAdmin}
                            activePageNumber={activePage} pageNumber={onPageChange} pageNumbers={pageArray}></DataTable>}
                </>
            }
        </div>
    )
}