import React, { useEffect, useState } from 'react'
import { Link, useHistory, useLocation, useParams } from 'react-router-dom'
import { GET_SCHOOL_BY_ID } from '../../../../app/service/school.service';
import { DELETE_USER, GET_USER_BY_SCHOOL_CODE } from '../../../../app/service/users.service';
import { DataTable } from '../../../../components/data-table'
import { PrincipalDataGridCols } from './data-grid-cols';
import { ToastContainer, toast } from 'react-toastify';

export const PrincipaList = () => {
    let { id, schoolCode } = useParams<{ id: string, schoolCode: string }>();
    const schoolId = parseInt(id);
    const schoolsCode = schoolCode;
    const [pageArray, setPageNumbers] = useState([]);
    const [activePage, setActivePage] = useState<any>();
    const [principalList, setPrincipalList] = useState<any>([]);
    const [loading, setLoading] = useState(false);
    const [userRole, setUserRole] = useState<any>();
    const search = useLocation().search;
    const currentPageNumber: any = new URLSearchParams(search).get('page');
    const history = useHistory();
    const [accessObjectsList, setAccessObjectsList] = useState<any>([]);
    const [accessPrincipal, setAccessPrincipal] = useState(false);
    const [apiloaded, setApiloaded] = useState<any>(false);

    useEffect(() => {
        const userRole = localStorage.getItem("user_role");
        setUserRole(userRole);
        getPrincipals();
        const accessObjectsData: any = localStorage.getItem("access_objects_data");
        setAccessObjectsList(JSON.parse(accessObjectsData));
        handleSchoolsPrincipalCols(JSON.parse(accessObjectsData));
    }, []);

    function getPrincipals() {
        const activePageNumber = currentPageNumber > 0 ? (currentPageNumber - 1) : 0;
        if (schoolsCode) {
            setLoading(true);
            GET_USER_BY_SCHOOL_CODE(schoolCode, 'PRINCIPAL', activePageNumber, {}).then((res: any) => {
                setApiloaded(true);
                if (res) {
                    if (res.status === 'fail') {
                        toast.error(res.message);
                        setLoading(false);
                    } else {
                        setActivePage(currentPageNumber ? currentPageNumber : 1);
                        const data = res.rows;
                        if (data.length > 0) {
                            setPrincipalList(data);
                            const pageNumbers = Math.ceil(res.count / 10);
                            const pageArray: any = [];
                            for (let i = 1; i <= pageNumbers; i++) {
                                pageArray.push(i);
                            }
                            setPageNumbers(pageArray);
                        }
                        setLoading(false);
                    }
                }
            });
        }
    }


    const onPageChange = (data: any) => {
        setActivePage(data);
    }

    const onEditPrincipal = (data: any) => {
        history.push(`/dashboard/school/info/${schoolId}/principalform/${schoolsCode}/${data.id}`);
        if (userRole === "CHAIRMAN" || userRole === "GENERAL_SECRETARY" || userRole === "INSTITUTE_ADMIN") {
            history.push(`/institute-dashboard/school/info/${schoolId}/principalform/${schoolsCode}/${data.id}`);

        }
    }

    const onDeletePrincipal = (data: any) => {
        const id = data.id;
        DELETE_USER(id).then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res.message);
                setLoading(false);
            } else {
                setLoading(false);
                toast.success("Chairman Deleted Successfully");
                getPrincipals();
            }
        });
    }

    const handleSchoolsPrincipalCols = (accessObjectsList: any) => {
        const preparedData = accessObjectsList?.filter((el: any) => el.object_code === "SCHOOLS");
        if (preparedData?.length > 0) {
            for (let i = 0; i < preparedData[0].child.length; i++) {
                for (let j = 0; j < PrincipalDataGridCols.length; j++) {

                    if (
                        preparedData[0].child[i].object_code === "SCHOOLS-PRINCIPAL" &&
                        preparedData[0].child[i].p_read === 1
                    ) {
                        setAccessPrincipal(true);
                    }
                    if (preparedData[0].child[i].object_code === "SCHOOLS-PRINCIPAL-EDIT" && preparedData[0].child[i].p_read === 1) {
                        if (PrincipalDataGridCols[j].title == "Actions") {
                            PrincipalDataGridCols[j].isEdit = true;
                        }

                    }

                    if (preparedData[0].child[i].object_code === "SCHOOLS-PRINCIPAL-SET-PASSWORD" && preparedData[0].child[i].p_read === 1) {
                        if (PrincipalDataGridCols[j].title == "Actions") {
                            PrincipalDataGridCols[j].setPassword = true;
                        }
                    }

                    if ((preparedData[0].child[i].object_code === "SCHOOLS-PRINCIPAL-SET-PASSWORD" && preparedData[0].child[i].p_read === 1) ||
                        (preparedData[0].child[i].object_code === "SCHOOLS-PRINCIPAL-EDIT" && preparedData[0].child[i].p_read === 1)) {
                        if (PrincipalDataGridCols[j].title == "Actions") {

                            PrincipalDataGridCols[j].canShowColumn = true;
                        }
                    }
                }
            }
        }


    }

    return (
        <div>
            {accessPrincipal &&
                <>

                    {((principalList.length === 0 && apiloaded) && (userRole === 'SUPER_ADMIN' || userRole === 'INSTITUTE_ADMIN' || userRole === 'SCHOOL_ADMIN')) &&
                        < div className="border-primary py-3 text-end">
                            {userRole !== "CHAIRMAN" && userRole !== "GENERAL_SECRETARY" && userRole !== "INSTITUTE_ADMIN" && <Link to={`/dashboard/school/info/${schoolId}/principalform/${schoolsCode}/0`} className="btn btn-primary btn-sm px-5 rounded-12">New</Link>}

                            {(userRole === "CHAIRMAN" || userRole === "GENERAL_SECRETARY" || userRole === "INSTITUTE_ADMIN") && <Link to={`/institute-dashboard/school/info/${schoolId}/principalform/${schoolsCode}/0`} className="btn btn-primary btn-sm px-5 rounded-12">New</Link>}
                        </div>}
                    {
                        loading &&
                        <div className="text-center p-5">
                            <div className="spinner-border" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                    }

                    {
                        !loading &&
                        <DataTable TableCols={PrincipalDataGridCols} tableData={principalList}
                            editInfo={onEditPrincipal} deleteInfo={onDeletePrincipal}
                            activePageNumber={activePage} pageNumber={onPageChange} pageNumbers={pageArray}></DataTable>}
                </>
            }
        </div >
    )
}