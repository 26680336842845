import React, { useEffect, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import { Breadcum } from "../../../../components/breadcum";
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { GET_ACTIVE_STUDENTS, GET_ACTIVE_STUDENTS_PAGE_NUMBER } from '../../../../app/service/school-dashboard.service';
import { DataTable } from '../../../../components/data-table'
import { returnPaginationRange } from '../../../../app/utility/appUtils';
import { InstituteActiveStudentsDataGridCols } from './data-grid-cols';


function ActiveStudentsList() {
    const userRole = localStorage.getItem("user_role");
    const [activePage, setActivePage] = useState<any>();
    const search = useLocation().search;
    const currentPageNumber: any = new URLSearchParams(search).get("page");
    const [pageNumbers, setPageNumbers] = useState<any>([]);
    let totalPage = pageNumbers.length
    let { id, schoolCode } = useParams<{ id: string, schoolCode: string }>();
    const schoolId = id;
    const schoolsCode = schoolCode;
    const [studentsList, setStudentList] = useState<any>([]);
    const [loading, setLoading] = useState(false);
    const [breadcumInfo, setBreadcumInfo] = useState<any>([
        {
            label: "Dashboard",
            value: "Dashboard",
            routerLink: "/institute-dashboard/dashboard",
            isActive: false,
        },
        {
            label: "School Name",
            value: "",
            routerLink: "",
            isActive: false,
        },
    ]);
    const history = useHistory();
    const arrayOfPages = returnPaginationRange(pageNumbers.length, activePage, 10, 1)

    useEffect(() => {
        activeStudentsdetails(schoolsCode);
        for (let i = 0; i < breadcumInfo.length; i++) {
            const element = breadcumInfo[i];
            element.routerLink = "/institute-dashboard/dashboard";

        }

    }, []);


    const activeStudentsdetails = (data: string) => {

        setLoading(true);
        GET_ACTIVE_STUDENTS(data, 0).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    if (res) {
                        const data = res;
                        getBreadcumList(data);
                        setStudentList(data.rows);
                        const pageNumbers = Math.ceil(res.count / 10);
                        const pageArray: any = [];
                        for (let i = 1; i <= pageNumbers; i++) {
                            pageArray.push(i);
                        }
                        setPageNumbers(pageArray);
                        setActivePage(data);
                        setActivePage(currentPageNumber ? currentPageNumber : 1);
                    }
                    setLoading(false);
                }
            }
        });
    }
    function getBreadcumList(data: any) {
        breadcumInfo.splice(1, breadcumInfo.length - 1, {
            label: "Registered School",
            value: data.name_of_school,
            isActive: true,
        });
        setBreadcumInfo(breadcumInfo);
    }

    function onClickPage(number: number) {

        let dots: any = "..."

        if (activePage !== 1 && number === dots) {
            setActivePage(1);
            onPageChange(1);

        } else if (activePage !== pageNumbers.length && number === dots) {
            setActivePage(totalPage - 1);
            onPageChange(totalPage - 1);
        }
        else {
            setActivePage(number);
            onPageChange(number);
        }
    }
    function onClickPreviousPage() {
        setActivePage(activePage - 1);
        onPageChange(activePage - 1);
    }




    function onClickNextPage() {
        setActivePage(activePage + 1);
        onPageChange(activePage + 1);

    }

    const onPageChange = (data: any) => {

        if (data) {
            if (userRole === "SUPER_ADMIN") {
                history.push(`/dashboard/view/active-studentslist/${schoolId}/${schoolsCode}?page=${data}`);
            } else {
                history.push(`/institute-dashboard/dashboard/active-studentslist/${schoolId}/${schoolsCode}?page=${data}`);
            }
        }
        setActivePage(data);
        const pageNumber = data - 1;
        setLoading(true);
        GET_ACTIVE_STUDENTS_PAGE_NUMBER(schoolsCode, pageNumber).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    const data = res['rows'];
                    setStudentList(data);
                    setLoading(false);
                }

            }
        });
    }
    const onClickDoubbleArrows = (arrow: any) => {
        if (arrow === "&raquo;") {
            setActivePage(totalPage);
        } else {
            setActivePage(1);
        }
    }
    return (
        <div >
            {loading &&
                <div className="text-center p-5 mt-5">
                    <div className="spinner-border mt-5" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading &&
                <div>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb border-bottom ps-0 pt-0 pb-2" style={{ letterSpacing: "0" }}>
                            {userRole === "SUPER_ADMIN" && <li className="breadcrumb-item mt-3"><Link to='/dashboard/view/instituteinfo' style={{ color: '#0d6efd' }}>Dashboard</Link> / <Link to='/dashboard/view/active-students' style={{ color: '#0d6efd' }}>{schoolId}</Link> / <span>Active Students</span></li>}
                            {userRole !== "SUPER_ADMIN" && <li className="breadcrumb-item"><Link to='/institute-dashboard/dashboard' style={{ color: '#0d6efd' }}>Dashboard</Link> / <Link to='/institute-dashboard/dashboard/active-students' style={{ color: '#0d6efd' }}>{schoolId}</Link> / <span>Active Students</span></li>}
                        </ol>
                    </nav>
                    {loading &&
                        <div className="text-center p-5">
                            <div className="spinner-border" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                    }
                    {!loading && <DataTable TableCols={InstituteActiveStudentsDataGridCols}
                        type={"classic"}
                        tableData={studentsList} pageNumber={onPageChange}
                        activePageNumber={activePage} pageNumbers={pageNumbers}></DataTable>}
                </div>
            }
        </div>
    )

}

export default ActiveStudentsList;