



import React, { useEffect, useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom';
import FormBuilder from "../../../components/form-builder";
import { FormControlError, FormField, FormValidators } from '../../../components/form-builder/model/form-field';
import { FormValidator, GetControlIsValid } from '../../../components/form-builder/validations';
import { ToastContainer, toast } from 'react-toastify';
import { CREATE_ROLE, GET_ROLE_BY_ID, UPDATE_ROLE } from '../../../app/service/roles.service';



export const RoleForm = () => {
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [roleData, setRoleData] = useState<any>({});
    const [currentRoleData, setCurrentRoleData] = useState<any>({});
    const [adminvalidationErrors, setRolevalidationErrors] = useState<FormControlError[]>([]);
    const [loading, setLoading] = useState(false);
    const [nameError, setNameError] = useState('');
    const [codeError, setCodeError] = useState('');
    const [descriptionError, setDescriptionError] = useState('');
    const [disabled, setDisabled] = useState(false);






    const history = useHistory();
    let { id } = useParams<{ id: string }>();
    const roleId = parseInt(id);

    const roleFormValidations = [
        new FormField('role_name', [FormValidators.REQUIRED]),
        new FormField('role_description', [FormValidators.REQUIRED]),
        new FormField('role_code', [FormValidators.REQUIRED]),

    ];
    useEffect(() => {
        if (roleId > 0) {
            setLoading(true);
            GET_ROLE_BY_ID(roleId).then((res: any) => {
                if (res) {
                    if (res.status === 'fail') {
                        toast.error(res.message);
                        setLoading(false);
                    } else {
                        setLoading(false);
                        setCurrentRoleData(res.rows[0]);
                        setDisabled(!disabled);
                    }
                }
            });
        }
    }, [roleId]);


    function createRole() {
        const rolesData = roleData.value ? { ...roleData.value } : { ...currentRoleData };
        setIsFormSubmitted(true);
        const errors: FormControlError[] = FormValidator(roleFormValidations, rolesData);
        setRolevalidationErrors(errors);
        Object.keys(rolesData).forEach(function (key) {
            if (typeof rolesData[key] === 'string') {
                rolesData[key] = rolesData[key].trim();
            }
        });
        if (onChangeName(rolesData.role_name) && onChangeDescription(rolesData.role_description) && onChangeCode(rolesData.role_code)) {
            setLoading(true);
            rolesData.status = 1;
            CREATE_ROLE(rolesData).then((res: any) => {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                    setCurrentRoleData(rolesData);
                } else {
                    toast.success("Role Created Successfully");
                    history.push('/dashboard/roles');
                    setLoading(false);
                }
            });
        } else {
            if (!nameError && !onChangeName(rolesData.role_name)) {
                setNameError('Please enter role name');
            }
            if (!descriptionError && !onChangeDescription(rolesData.role_description)) {
                setDescriptionError('Please enter role description');
            }
            if (!codeError && !onChangeCode(rolesData.role_code)) {
                setCodeError('Please enter role code');
            }

        }
    }


    function updateRole() {
        const rolesData = roleData.value ? { ...roleData.value } : { ...currentRoleData };
        setIsFormSubmitted(true);
        const errors: FormControlError[] = FormValidator(roleFormValidations, rolesData);
        delete rolesData.id;
        delete rolesData.created_at;
        delete rolesData.updated_at;


        setRolevalidationErrors(errors);
        if (onChangeName(rolesData.role_name) && onChangeDescription(rolesData.role_description) && onChangeCode(rolesData.role_code)) {
            setLoading(true);
            rolesData.status = 1;
            UPDATE_ROLE(rolesData).then((res: any) => {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);

                } else {
                    toast.success("Role Updated Successfully");
                    history.push('/dashboard/roles');
                    setLoading(false);

                }
            });
        } else {
            if (!nameError && !onChangeName(rolesData.role_name)) {
                setNameError('Please enter role name');
            }
            if (!descriptionError && !onChangeDescription(rolesData.role_description)) {
                setDescriptionError('Please enter role description');
            }
            if (!codeError && !onChangeCode(rolesData.role_code)) {
                setCodeError('Please enter role code');
            }
        }
    }

    const handleRoleInput = (data: any) => {
        data.value = { ...currentRoleData, ...data.value };
        setRoleData(data);
        const errors: any = FormValidator(roleFormValidations, data.value);
        setRolevalidationErrors(errors);
    };


    const onChangeName = (event: any) => {
        setNameError('');
        const reg = /^[A-Za-z]+(?:[ _-][A-Za-z]+)*$/;

        if (event) {
            if (!reg.test(event.trim())) {
                setNameError('Role name can`t contain numbers and special characters');
            } else {
                return true;
            }
        } else {
            setNameError('Please enter role name');
        }
    }

    const onChangeCode = (event: any) => {
        setCodeError('');
        const reg = /^[A-Za-z]+(?:[ _-][A-Za-z]+)*$/;

        if (event) {
            if (!reg.test(event.trim())) {
                setCodeError('Role code can`t contain numbers and special characters');
            } else {
                return true;
            }
        } else {
            setCodeError('Please enter role code');
        }
    }

    const onChangeDescription = (event: any) => {
        setDescriptionError('');
        if (event) {
            if (event.trim().length <= 2) {
                setDescriptionError('Role description minimum 3 characters');
            } else if (event.trim().length >= 256) {
                setDescriptionError('Role description maximum 256 characters');
            } else {
                return true;
            }
        } else {
            setDescriptionError('Please enter role description');
        }
    }
    return (
        <div>
            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }

            {!loading && <div>
                <div className="row border-top border-primary py-3">
                    {roleId === 0 && <h2>Create Role</h2>}
                    {roleId > 0 && <h2>Update Role</h2>}
                </div>
                <FormBuilder onUpdate={handleRoleInput}>
                    <form>
                        <div className="row custom-form">
                            <div className="col-md-4">
                                <div className="mb-4">
                                    <label className="form-label">Role Name</label>
                                    <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>*</span>
                                    <input className="form-control form-control-lg" type="text" name="role_name" onChange={(e) => onChangeName(e.target.value)} defaultValue={currentRoleData.role_name} placeholder="Please enter role name" />
                                    {nameError && <p className="text-danger">{nameError}</p>}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="mb-4">
                                    <label className="form-label">Role Code</label>
                                    <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>*</span>
                                    <input className="form-control form-control-lg" type="text" name="role_code" onChange={(e) => onChangeCode(e.target.value)} defaultValue={currentRoleData.role_code} placeholder="Please enter role code" disabled={disabled} style={{ backgroundColor: disabled ? 'hsl(0,0%,96.5%)' : 'none' }} />
                                    {codeError && <p className="text-danger">{codeError}</p>}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="mb-4">
                                    <label className="form-label">Role Description</label>
                                    <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>*</span>
                                    <br />
                                    <input className="form-control form-control-lg" type="text" name="role_description" onChange={(e) => onChangeDescription(e.target.value)} defaultValue={currentRoleData.role_description} placeholder="Please enter role description" />
                                    {descriptionError && <p className="text-danger">{descriptionError}</p>}

                                </div>
                            </div>
                        </div>
                    </form>
                </FormBuilder>
                <div className="form-footer border-top border-primary py-3 text-end mt-4">
                    {roleId === 0 && < a className="btn btn-primary btn-sm px-5 rounded-12 cursor-pointer" onClick={() => createRole()}>Create</a>}
                    {roleId > 0 && <a className="btn btn-primary btn-sm px-5 rounded-12 cursor-pointer" onClick={() => updateRole()}>Update</a>}
                    <Link className="text-dark text-decoration-none ms-2" to={`/dashboard/roles`}>Cancel</Link>
                </div>

            </div>}
        </div>
    )
}
