import React, { SyntheticEvent } from 'react';
import { useSpring, animated } from 'react-spring';
import { connect } from 'react-redux';
import { PopupData } from '../../redux/app-utility/model/popup-data';
import { AppUtilityActions } from '../../redux/app-utility/action';
import { Utility } from '../../app/utility';
interface Props {
    canshow?: boolean;
    popupData?: PopupData;
    updatePopup?: (val: PopupData) => void;
}
const Popup: React.FC<Props> = (props: Props) => {
    const sprintProps = useSpring({
        transform: props.popupData?.active ? 'translate3d(0,0vh,0)' : `translate3d(0,-100vh,0)`
    });
    const handleAction = () => {
        if (props.updatePopup) {
            props.updatePopup(new PopupData(false, '', '', []));
        }
    };
    const onClickContainer = (event: SyntheticEvent) => {
        if (props.updatePopup) {
            props.updatePopup(new PopupData(false, '', '', []));
        }
    };

    return (
        <animated.div style={sprintProps} className="popup cursor-pointer" onClick={onClickContainer}>
            <div className="popup-inner" onClick={Utility.PreventDefaultAndPropagation}>
                <div className="popup-header border-bottom border-primary">
                    <h3 className="">Success</h3>
                </div>
                <div className="popup-body">
                    <p>School created successfully.</p>
                    <div className="text-center">
                        <button className="btn btn-primary cursor-pointer" onClick={handleAction}>Close</button>
                    </div>
                </div>
            </div>
        </animated.div>
    );
}


const mapStateToProps = (state: any) => {
    return {
        popupData: state.AppUtilityReducer.popupData
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        updatePopup: (data: PopupData) => dispatch(AppUtilityActions.updatePopup(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Popup);