import React, { Fragment, useEffect, useRef, useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { MNS_QUESTIONS_LIST, MNS_SUBMIT_QUESTIONS, MNS_SUBMIT_QUESTIONS_STATUS } from '../../../../app/service/mns-service';
import { CREATE_MODULE_ANSWERS, GET_MNS_MODULES_BY_ID, GET_MNS_MODULE_BY_QUESTIONS, GET_MODULES_BY_ID, UPDATE_ASSIGN_USER } from '../../../../app/service/module.service';
import FormBuilder from '../../../../components/form-builder';
import TimerInfo from '../../../dashboard/mns/timer/timerinfo';
import { MNSProgressView } from './dashboard';
import TimerData from './timer/timarinfo';
import LASTSECONDS from '../../../../assets/audioclips/last-ten-seconds.mp3'
import COUNTNUMBERGIF from '../../../../assets/animated-gif/count-number.gif'
import STRATEXAMGIF from '../../../../assets/animated-gif/start-exam.gif'
import TESTSUBMITAUDIO from '../../../../assets/audioclips/submit-test-tune.mp3'
import moment from 'moment';


export const MNSQuestionsList = () => {
    const [questionsData, setQuestionsData] = useState<any>([]);
    const [loading, setLoading] = useState(false);
    const [userRole, setUserRole] = useState<any>();
    const [canShowCompleteFormBtn, setCanShowCompleteFormBtn] = useState(false);
    const [questionsFormData, setQuestionsFormData] = useState<any>({});
    const [moduleType, setModuleType] = useState<any>();
    const [tempInfo, setTempInfo] = useState<any>({});
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);
    const [timeUpAlert, setTimeUpalert] = useState(false);
    const [time_remain, settime_remain] = useState<any>();
    const [time, setTime] = useState<any>({});
    const [timeLeft, setTimeLeft] = useState<any>(null);
    const [startTimer, setStartTimer] = useState(false);
    const [canShowHeader, setShowHeader] = useState(false);
    const [mnsInfo, setMnsInfo] = useState<any>(null);
    const [attemptLevels, setAttemptLevels] = useState<any>([1, 2, 3, 4, 5, 6, 7]);
    let timeUp = false;
    let time_remain_storage = '';
    const surveyForm = useRef<any>({});
    const inputRefs = useRef<any>([]);
    const [testStart, setTestStart] = useState<boolean>(false);
    const audioRef = useRef<HTMLAudioElement | null>(null);
    const submitAudioRef = useRef<HTMLAudioElement | null>(null);
    const history = useHistory();
    let { moduleUUID, userCode, schoolCode } = useParams<{ moduleUUID: string, userCode: string, schoolCode: string }>();
    const modules_uuid = moduleUUID;
    const user_code = userCode;
    const school_code = schoolCode;
    let timeRemaining = 0;
    const [canShowAlertPopup, setCanShowAlertPopup] = useState(false);
    const backworKey = localStorage.getItem("backword_key");
    const [remainingTime, setRemainingTime] = useState('');
    const buttonRef = useRef<HTMLButtonElement>(null);
    const isRefreshed = useRef(true);
    let completedTime: string = "";

    useEffect(() => {
        const hasDefaultMethodBeenCalled = localStorage.getItem('hasDefaultMethodBeenCalled');

        if (!hasDefaultMethodBeenCalled) {
            initMethods();
            localStorage.setItem('hasDefaultMethodBeenCalled', JSON.stringify(true));
        } else {
            refreshCalled();
        }

        return () => {
            localStorage.removeItem('hasDefaultMethodBeenCalled');
        };
    }, []);

    useEffect(() => {
        const handleBeforeUnload = (event: any) => {
            event.preventDefault();
            refreshCalled();
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);


    function initMethods() {
        setTestStart(false);
        if (backworKey === "true") {
            history.push('/student-dashboard/list')
        }
        setTimeout(() => {
            const userRole = localStorage.getItem("user_role");
            setUserRole(userRole);
            getMNSQuestionsList();

        }, 4000);
        setTimeout(() => {
            setTestStart(true);

        }, 6000);

    }

    function refreshCalled() {
        setTestStart(true);
        getMNSQuestionsList();
        setTimeout(() => {
            handleCompleteSubmit();
        }, 2000);

    }

    function getMNSQuestionsList() {
        const payload = {
            "module_uuid": modules_uuid,
            "school_code": school_code,
            "user_code": user_code,
            "request_type": ""
        }

        setShowHeader(false);
        setLoading(true);
        MNS_QUESTIONS_LIST(payload).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    if (res) {
                        sessionStorage.setItem("questionsData", JSON.stringify(res?.questions_list?.en));
                        setRemainingTime(res.test_time);
                        res.consecutive_success = res.attempt - 1
                        sessionStorage.setItem("tempInfo", JSON.stringify(res));
                        setTempInfo(res);
                        setMnsInfo(res);
                        setStartTimer(true);
                        setQuestionsData(res?.questions_list?.en);
                        let timeString: string = `${res?.test_time}`
                        let remainTimeStorage = localStorage.getItem("setTimer")
                        if (remainTimeStorage && res?.test_time) {
                            let [mins, sec] = remainTimeStorage.split(':');
                            setMinutes(Number(mins));
                            if (sec) {
                                setSeconds(Number(sec));
                            }
                        } else {
                            if (res?.test_time) {
                                let [mins, sec] = timeString.split('.');
                                setMinutes(Number(mins));
                                if (sec) {
                                    setSeconds(Number(sec))
                                }
                            }
                        }
                        setShowHeader(true);
                        setLoading(false);
                    }

                }
            }
        });
    }


    const handleOptions = (data: any) => {
        setQuestionsFormData(data);
    }

    function handleQuestionAnswers(e: any, item: any, i: number, j: number) {
        let mnsQuestions = questionsData;
        mnsQuestions[i].list[j].student_ans = e.target.value;
        setQuestionsData(mnsQuestions);
        sessionStorage.setItem("questionsData", JSON.stringify(mnsQuestions));

    }


    const handleCompleteSubmit = () => {
        let request = Object.keys(tempInfo).length > 0 ? tempInfo : JSON.parse(sessionStorage.getItem('tempInfo') || '{}');
        request['questions_list']['en'] = questionsData.length > 0 ? questionsData : JSON.parse(sessionStorage.getItem("questionsData") || '{}');
        request['module_uuid'] = modules_uuid;
        request['school_code'] = school_code;
        request['user_code'] = user_code;
        request['time_taken'] = calculateTimeDifference() ? calculateTimeDifference() : sessionStorage.getItem("setTime");
        if (!request.hasOwnProperty('mode')) {
            request['mode'] = 'levels'
        }
        if (submitAudioRef.current) {
            submitAudioRef.current.play().catch((error) => {
            });
        }
        setLoading(true);
        MNS_SUBMIT_QUESTIONS(request).then((res: any) => {
            if (res) {
                updateQuestionsStatus();

                setTimeout(() => {
                    const data = res;
                    const preparedData = JSON.stringify(data);
                    localStorage.setItem("mnsInfo", preparedData);
                    setCanShowAlertPopup(false);
                    localStorage.setItem("backword_key", "true");
                    if (mnsInfo && Object.keys(mnsInfo).length > 0) {
                        history.push(`/student-dashboard/list/test-success/${mnsInfo.sub_level}/${mnsInfo.mode}/${modules_uuid}/${user_code}/${school_code}/${mnsInfo?.attempt}`);
                    } else {
                        let mnsInfo = JSON.parse(sessionStorage.getItem("tempInfo") || '{}')
                        if (!mnsInfo.hasOwnProperty('mode')) {
                            mnsInfo['mode'] = 'levels'
                        }
                        history.push(`/student-dashboard/list/test-success/${mnsInfo.sub_level}/${mnsInfo.mode}/${modules_uuid}/${user_code}/${school_code}/${mnsInfo?.attempt}`);
                    }

                    setLoading(false);
                }, 1000);


            }
        });
    }
    function updateQuestionsStatus() {
        let payLoad = {
            module_uuid: modules_uuid,
            school_code: school_code,
            user_code: user_code,
            request_type: "update_status"
        }
        MNS_SUBMIT_QUESTIONS_STATUS(payLoad).then((res: any) => {
            if (res) {

            }
        });
    }
    const getLogo = (index: number) => {
        let currentAttempt = mnsInfo ? mnsInfo?.consecutive_success : 0;

        if (index == currentAttempt) {
            return '#FCEE21';
        }

        if (mnsInfo && mnsInfo?.consecutive_success == 0) {
            return '#FFFFFF';
        }

        if (mnsInfo?.consecutive_success > 0 && mnsInfo?.consecutive_success > index) {

            return '#8CC63F';
        } else {
            return '#ffffff';
        }
    }



    function handleEnter(event: any, index: number, parentIndex: number, inputLength: any) {
        if (event.key === "Enter") {
            event.preventDefault();
            inputRefs.current[parentIndex + 1]?.focus();
        }
    }


    const playAudio = () => {
        if (audioRef.current) {
            audioRef.current.play().catch((error) => {
            });
        }
    }



    const calculateTimeDifference = () => {
        const initialTime = `${minutes}:${seconds}`
        const [initialMinutes, initialSeconds] = initialTime.split(':').map(Number);
        const [finalMinutes, finalSeconds] = completedTime.split(':').map(Number);
        const initialTimeInSeconds = Number(initialMinutes) * 60 + Number(initialSeconds);
        const finalTimeInSeconds = Number(finalMinutes) * 60 + finalSeconds ? Number(finalSeconds) : 0;
        const differenceInSeconds = Number(initialTimeInSeconds * 100) - Number(finalTimeInSeconds * 100);
        if (differenceInSeconds >= 60) {
            var mins = moment.utc(moment(initialTime, "mm:ss").diff(moment(completedTime, "mm:ss"))).format("mm.ss")
            sessionStorage.setItem("setTime", mins === 'Invalid date' ? `${minutes}.${seconds}` : mins.replace('00', '0'));
            return mins === 'Invalid date' ? `${minutes}.${seconds}` : mins.replace('00', '0')
        } else {
            sessionStorage.setItem("setTime", `0.${differenceInSeconds}`);
            return `0.${differenceInSeconds}`;
        }

    };
    return (
        <div>
            <audio ref={submitAudioRef}>
                <source src={TESTSUBMITAUDIO} type="audio/mpeg" />
            </audio>
            {!testStart &&
                <audio autoPlay>
                    <source src={LASTSECONDS} type="audio/mpeg" />
                </audio>
            }
            {!testStart &&
                <div className="row">
                    <div className='col-11  text-center'>
                        <div className='success-block'>
                            <img
                                src={STRATEXAMGIF}
                                alt="GIF"
                                style={{ width: '280px', height: '280px', }}
                                className="gif-image position-relative me-2 "
                            />
                            <p className="position-relative" style={{ top: "-65px" }}>
                                <img
                                    src={COUNTNUMBERGIF}
                                    alt="GIF"
                                    style={{ width: '120px', height: '120px' }}
                                    className="gif-image"
                                />
                            </p>
                        </div>
                    </div>
                </div>
            }

            <div
                className={`modal fade ${canShowAlertPopup ? "show" : "false"}`}
                style={{ display: canShowAlertPopup ? "block" : "none" }}
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                                Times up!!!
                            </h5>
                            <button
                                type="button"
                                className="close popup-close cursor-pointer"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={handleCompleteSubmit}
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className='modal-body'>
                            Your inputs submitted successfully
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-primary cursor-pointer"
                                onClick={handleCompleteSubmit}
                            >
                                Ok
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {testStart && <div>
                {loading &&
                    <div className="text-center p-5">
                        <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                }
                {!loading && <div>
                    <audio ref={audioRef} src={LASTSECONDS} preload="none" />
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb pb-2 ps-0 pt-0 border-bottom">
                            <li className="breadcrumb-item "><Link to="/student-dashboard/list"><span className='pe-2'>Dashboard</span></Link></li> /

                            <li className="breadcrumb-item "> {mnsInfo && mnsInfo?.mode && mnsInfo?.mode === 'assesment_test' ? <span> Entry Test</span> : <span> Maths Practice</span>}</li>

                        </ol>
                    </nav>

                    <div className="mt-3">
                        <h2 className="f22 fw-bold">
                            MNS <span className="fw-light">(Minimum Numerical Skills)</span>
                        </h2>
                    </div>
                    {canShowHeader &&
                        <div
                            className={`p-3 time-block sticky-block mt-3 d-md-flex justify-content-between align-items-end ${!canShowAlertPopup && "z_index_ni"}`}
                        >
                            {mnsInfo && mnsInfo?.mode && mnsInfo?.mode === 'assesment_test' ?
                                <div>
                                    <h2 className="m-0">Entry Test</h2>
                                    <h3 className="m-0"> Time Limit -  {minutes} : {seconds < 9 ? `0${seconds}` : seconds}  mins</h3>
                                </div>
                                :
                                <div className='d-md-flex'>
                                    <div>
                                        <h2 className="m-0">level - {mnsInfo?.sub_level}</h2>
                                        <h3 className="m-0"> Time Limit - {minutes} : {seconds < 9 ? `0${seconds}` : seconds}  mins</h3>
                                    </div>
                                    <div className="attempts me-auto ms-md-5">
                                        <p className="m-0 f12">Attempts</p>
                                        <ul className="list-inline m-0">
                                            {attemptLevels.map((item: any, i: number) => (

                                                <li className="list-inline-item" style={{ backgroundColor: getLogo(i) }}><a>{item}</a></li>
                                            ))}

                                        </ul>

                                    </div>
                                </div>
                            }
                            <div className='mt-md-0 mt-3'>
                                <TimerInfo
                                    minutes={minutes}
                                    seconds={seconds}
                                    startTimer={startTimer}
                                    time_remain={(value: any) => {
                                        if (value === '') {
                                            time_remain_storage = "5";
                                        } else {
                                            time_remain_storage = value;
                                            completedTime = time_remain_storage;
                                            localStorage.setItem("setTimer", time_remain_storage);
                                            if (time_remain_storage === "0:11") {
                                                playAudio();
                                            }
                                        }

                                    }}
                                    timeUp={() => {

                                        setTimeUpalert(true);
                                        setCanShowAlertPopup(true);
                                        timeUp = true;
                                    }} />
                            </div>
                        </div>}
                    <div className="row question-block mt-4 justify-content-between">
                        <div className="col-md-6">
                            {questionsData?.map((question: any, i: number) => (
                                <Fragment key={i}>
                                    {question.list.length > 1 && <div className='col-md-12'>
                                        <div className=' q-number'>Q{question.q_number}: </div>
                                    </div>}
                                    {question.list.map((questionName: any, j: number) => (
                                        <div className={`row ${question.list.length === 1 && "mb-md-2 mb-lg-3"}`} key={j}>
                                            {question.list.length === 1 && <div className='col-md-8 pt-2'>
                                                <span className=' q-number'>Q{question.q_number}: </span>
                                                <span className=' q-name'>
                                                    {questionName.question}
                                                </span>
                                            </div>}
                                            {question.list.length > 1 && <div className='col-md-5 pt-md-2'>
                                                <span className=' q-name'>
                                                    <span className=' q-name'>{j === 0 ? "a:" : ''}</span>
                                                    <span className=' q-name'>{j === 1 ? "b:" : ''}</span>
                                                    <span className=' q-name'>{j === 2 ? "c:" : ''}</span>
                                                    {questionName.question}
                                                </span>
                                            </div>}
                                            <div className='col-md-4 pt-2'>
                                                <FormBuilder onUpdate={handleOptions}>
                                                    <form ref={surveyForm}>
                                                        {questionName.question_type === 'input_text' &&
                                                            <input type="text" className="form-control q-input" name="option"
                                                                value={question.selectedOption}
                                                                // defaultValue={question?.list.map((data: any) => (data.ans))}
                                                                // value={question?.list.map((data: any) => { data.student_ans = data.ans })}
                                                                ref={(el) => (inputRefs.current[i] = el)}
                                                                onKeyPress={(e) => handleEnter(e, j, i, questionsData.length)}
                                                                onChange={(e) => { handleQuestionAnswers(e, question, i, j) }} />
                                                        }
                                                    </form>
                                                </FormBuilder>
                                            </div>
                                        </div>
                                    ))}
                                </Fragment>
                            ))}

                        </div>

                    </div>

                    {questionsData?.length > 0 && <div className="py-3 text-center mt-3 border-top">
                        <button className="btn btn-primary mb-3 px-5 cursor-pointer" onClick={handleCompleteSubmit}>Submit</button>
                        <Link to="/student-dashboard/list"><button className='btn btn-link mb-3'>Cancel</button></Link>
                    </div>}
                </div>}
            </div>}
        </div >
    )
}
function useFocusNext() {
    throw new Error('Function not implemented.');
}

