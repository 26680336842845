import { DataTableCol } from "../../../components/data-table/types";

export const UsersDataGridCols: DataTableCol[] = [
  {
    title: "Name",
    control: "name",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "Mobile Number",
    control: "contact_number",
    sortable: true,
    canShowColumn: true,
  },

  {
    title: "Email (User Name)",
    control: "email_id",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "Gender",
    control: "gender",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "Department",
    control: "department_display_name",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "Role Name",
    control: "role_name",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "Registration Date",
    control: "date_of_registration",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "Actions",
    control: "both",
    isEdit: false,
    isDelete: false,
    canShowColumn: false,
    setPassword: false,
    // control: 'one'
  },
];
