import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { Link, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { FETCH_PARIKSHA_USER_QUESTION_INFO } from '../../../../app/service/pariksha-module-questions';
import { white } from 'precise-ui/dist/es6/colors';

export const ParikshaTestResultData = () => {
    const [resultInfo, setResultInfo] = useState<any>({});
    const [loading, setLoading] = useState(false);
    const [resultData, setResultData] = useState<any>([]);
    const history = useHistory();
    let { moduleUUID, userCode, schoolCode } = useParams<{ moduleUUID: string, userCode: string, schoolCode: string }>();
    const modules_uuid = moduleUUID;
    const user_code = userCode;
    const school_code = schoolCode;
    let selectUuids = JSON.parse(localStorage.getItem('userSelectedSubjectInfo') || '{}');
    const testUuid = localStorage.getItem("testUuid");
    const testData: any = JSON.parse(localStorage.getItem("result-test-data") || '{}');
    const [totalQuestions, setTotalQuestions] = useState<number>(0);
    const [attempted, setAttempted] = useState<number>(0);
    const [totalCorrect, setTotalCorrect] = useState<number>(0);
    const [totalWrong, setTotalWrong] = useState<number>(0);
    const [score, setScore] = useState<number>(0);
    const [totalWorksheets, setTotalWorksheets] = useState<number>(0);
    const testInfo: any = JSON.parse(localStorage.getItem("pariksha_test_info") || '{}');

    useEffect(() => {
        setLoading(true);
        setResultInfo(testData);
        const replacedData = testData?.topic_wise_result?.map((item: any) => ({
            ...item,
            levels: item?.levels?.map(replaceValues)
        }));
        setResultData(replacedData);
        setLoading(false);
        let totalQuestion = 0;
        let totalCorrect = 0;
        let totalWrong = 0;
        let totalWorksheets = 0;
        let score = 0;
        let attempted = 0;
        for (let i = 0; i < testData?.topic_wise_result?.length; i++) {
            totalQuestion += testData?.topic_wise_result[i]?.questions;
            totalCorrect += testData?.topic_wise_result[i]?.correct;
            totalWrong += testData?.topic_wise_result[i]?.wrong;
            totalWorksheets += testData?.topic_wise_result[i]?.worksheets;
            score += testData?.topic_wise_result[i]?.percentage;
            attempted += testData?.topic_wise_result[i]?.attempted;


        }
        setTotalQuestions(totalQuestion);
        setTotalWrong(totalWrong);
        setTotalCorrect(totalCorrect);
        setTotalWorksheets(totalWorksheets);
        setAttempted(attempted);
        let preparedScore = (score / parseInt(testData?.topic_wise_result?.length))
        setScore(preparedScore);

    }, []);


    const handleBack = () => {
        // history.push(`/student-dashboard/list/pariksha/progress-view/${moduleUUID}/${userCode}/${schoolCode}/${selectUuids?.board_uuid}/${selectUuids?.medium_uuid}/${selectUuids?.standard_uuid}/${selectUuids?.subject_uuid}`)
        history.push(`/student-dashboard/list/pariksha/success-page/${moduleUUID}/${userCode}/${schoolCode}/${testInfo.test_no}`)

    }

    function getSolvedWorkSheetNumbers(count: any) {
        if (count.length > 0) {
            return count[0].worksheets;
        } else {
            return '';
        }
    }
    const replaceValues = (value: any) => {
        switch (value) {
            case "LOW":
                return "Easy";
            case "MEDIUM":
                return "Medium";
            case "HIGH":
                return "Hard";
            default:
                return value;
        }
    };

    return (
        <div>
            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading &&
                <div >
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb pb-2 ps-0 pt-0 border-bottom">
                            <li className="breadcrumb-item "><Link to="/student-dashboard/list"><span className='pe-2'>Dashboard</span></Link></li> /
                            <li className="breadcrumb-item "><Link to={`/student-dashboard/list/pariksha/test-initiative/${moduleUUID}/${userCode}/${schoolCode}`}><span className='pe-2'>Pariksha</span></Link></li> /

                            <li className="breadcrumb-item "><Link to={`/student-dashboard/list/pariksha/test-level-status/${moduleUUID}/${userCode}/${schoolCode}`}><span>Start Pariksha</span></Link></li> /
                            <li className="breadcrumb-item "><span className="active">{resultInfo?.exam_type?.split('_')
                                .map((word: any) => word?.charAt(0).toUpperCase() + word?.slice(1).toLowerCase())
                                .join(' ')}</span></li> /
                            {/* <li className="breadcrumb-item "><Link to={`/student-dashboard/list/pariksha/progress-view/${moduleUUID}/${userCode}/${selectUuids?.school_code}/${selectUuids?.board_uuid}/${selectUuids?.medium_uuid}/${selectUuids?.standard_uuid}/${selectUuids?.subject_uuid}`}><span>{testInfo?.test_name}</span></Link></li> / */}
                            <li className="breadcrumb-item "><Link to={`/student-dashboard/list/pariksha/success-page/${moduleUUID}/${userCode}/${schoolCode}/${testInfo.test_no}`}><span>Submition</span></Link></li> /
                            <li className="breadcrumb-item "><span>Result</span></li>
                        </ol>
                    </nav>
                    <div className='text-end me-5'>
                        <button className="btn btn-primary btn-sm px-5 rounded-12 cursor-pointer" onClick={() => history.goBack()}>Back</button>
                    </div>
                    <section className="mb-4 mt-4 px-3 px-md-0">
                        <div className="bg-white rounded-16 px-3 pb-3  pb-lg-5  px-lg-5 ">
                            <div className="row align-items-center">
                                <div className="col-md-12 ps-lg-5">
                                    <div className="row mb-0">
                                        <div className='col-md-6'>
                                            <h5 className=' text-primary'>Test Result Summary {resultInfo?.exam_type && <span className='text-black'> - {resultInfo?.exam_type?.split('_')
                                                .map((word: any) => word?.charAt(0).toUpperCase() + word?.slice(1).toLowerCase())
                                                .join(' ')}   {(resultInfo?.exam_type === "CHAPTER_WISE_TEST") && <span>({resultData[0]?.chapter_name})</span>}</span>}</h5>
                                        </div>

                                    </div>
                                    {testUuid === "0" &&
                                        <div>
                                            <div className="mt-3 custom-scroll table-responsive ">
                                                <table className="table ability-table mb-0">
                                                    <thead>
                                                        <tr className='ability-topic-header'>
                                                            {/* <th>Chapter Name</th> */}
                                                            <th>Topic Name</th>
                                                            <th className='text-center'>Questions</th>
                                                            <th className='text-center'>Attempted</th>
                                                            <th className='text-center'>Correct</th>
                                                            <th className='text-center'>Wrong</th>
                                                            <th className='text-center'>Score %</th>
                                                            <th className='text-center'>Wroksheets</th>
                                                            {/* <th className='text-center'>Test Result</th> */}

                                                            {/* <th>Levels</th> */}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {resultData?.map((item: any, i: number) => (
                                                            <React.Fragment key={i}>
                                                                <tr>
                                                                    {/* <td>{item?.chapter_name}</td> */}
                                                                    <td className={`${item?.worksheets == 0 && "text-completed"}`}>{item?.topic_name}</td>
                                                                    <td className='text-center'>{item?.questions}</td>
                                                                    <td className='text-center'>{item?.attempted}</td>
                                                                    <td className='text-center'>{item?.correct}</td>
                                                                    <td className='text-center'>{item?.wrong}</td>
                                                                    <td className='text-center'>{item?.percentage?.toFixed(0)}</td>
                                                                    <td className='text-center'>{item?.worksheets}</td>
                                                                    {/* <td className={`text-center fw-bold ${resultInfo?.result?.result === "fail" ? "text-inprogess" : "text-completed"}`}>{resultInfo?.result?.result === "fail" ? "Failed" : "Passed"}</td> */}

                                                                    {/* <td>{item?.levels.toString()?.split(',')?.join(', ')}</td> */}

                                                                </tr>
                                                                {/* <tr style={{ textAlign: 'initial' }}>
                                                    <td className='ability-topic-header' colSpan={6}>{detail?.title}</td>
                                                </tr> */}

                                                            </React.Fragment>
                                                        ))}<tr className='border-bottom border-white' >
                                                            <td colSpan={1} className="text-center text-black fw-bold" scope="col">Total</td>
                                                            <td className='text-black fw-bold text-center'>{totalQuestions}</td>
                                                            <td className='text-black fw-bold text-center'>{attempted}</td>
                                                            <td className='text-black fw-bold text-center'>{totalCorrect}</td>
                                                            <td className='text-black fw-bold text-center'>{totalWrong}</td>
                                                            <td className='text-black fw-bold text-center'>{score?.toFixed()}</td>
                                                            <td className='text-black fw-bold text-center'>{(totalWorksheets == 0 || totalWorksheets > 0) && totalWorksheets}</td>
                                                            {/* <td className='text-black fw-bold text-center'></td> */}
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className='d-flex mt-5 justify-content-center'>
                                                <div>
                                                    <h5 className='text-secondary fw-normal text-center'>You have <span className={` ${resultInfo?.result?.result !== "fail" && "text-completed"} `}>Passed </span> /<span className={` ${resultInfo?.result?.result === "fail" && "text-inprogess"} `}>Failed</span> the Test!</h5>
                                                    <h5 className='text-secondary fw-normal text-center'>With reference to the topic wise result of the test, The topic wise worksheets are assigned to you.</h5>
                                                </div>
                                            </div>
                                        </div>

                                    }

                                </div>
                            </div>
                        </div>
                    </section>
                </div>}
        </div>
    )
}