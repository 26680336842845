import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import badgeImg from '../../../assets/images/Badge.png';

export default function CommonLevel(props: any) {

    useEffect(() => {

    }, []);

    return (
        <div>
            <div className="card info-card-2 shadow  mb-5 rounded mb-4">
                <img className="card-img-top" />
                <div className="card-body p-3">
                    <div className='row'>
                        <div className='col-md-4 px-3'>
                            <div className="level-tag">
                                <h3 >Level {props.level}</h3>
                            </div>
                        </div>
                        <div className='col-md-8 row'>
                            {props.data.length > 0 &&
                                props.data.map((item: any, i: number) => (
                                    <div key={i} className="col-md-4">
                                        {(item?.level === props?.level && item?.status === 'completed') && <div className='row'>
                                            <div>
                                                <img src={badgeImg} />
                                                <span style={{ paddingLeft: '1.5rem' }}>{item.sub_level}</span>
                                            </div>
                                        </div>
                                        }
                                    </div>
                                ))}
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
};
