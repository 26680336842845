import { DataTableCol } from "../../../../../components/data-table/types";

export const AbhyasWorksheetDataGridCols: DataTableCol[] = [
    {
        title: "Worksheet  No",
        control: "number",
        sortable: true,
        canShowColumn: true,
        isLink: true,

    },

    // {
    //     title: "Type",
    //     control: "type",
    //     sortable: true,
    //     canShowColumn: true,
    // },
    {
        title: "Level",
        control: "level",
        sortable: true,
        canShowColumn: true,
    },
    {
        title: "Name",
        control: "name",
        sortable: true,
        canShowColumn: true,
    },
    {
        title: "Created",
        control: "created_dt",
        sortable: true,
        canShowColumn: true,
    },
    {
        title: "Modified",
        control: "modified_dt",
        sortable: true,
        canShowColumn: true,
    },
    {
        title: "Actions",
        control: "board_info",
        isView: true,
        isEdit: true,
        // isDelete: true,
        canShowColumn: true,
    },
];
