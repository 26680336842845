import React from 'react'
import { Route, Switch } from 'react-router'
import { StudentModulesList } from '../pages/dashboard/school/students/module/list'
import { ParentForm } from '../pages/dashboard/school/students/parent/form'
import { ParentList } from '../pages/dashboard/school/students/parent/list'

const NestedStudent = () => {
    return (
        <Switch>
            <Route path="/dashboard/school/student/info/:id/modulelist/:schoolCode/:studentId" component={StudentModulesList}></Route>
            <Route path="/dashboard/school/student/info/:id/parentform/:schoolCode/:studentId/:userId" component={ParentForm}></Route>
            <Route path="/dashboard/school/student/info/:id/parentlist/:schoolCode/:studentId" component={ParentList}></Route>
        </Switch>
    )
}

export default NestedStudent