import React, { useEffect, useState } from 'react'
import { GET_STUDENT_COUNT } from '../../../../app/service/student-count-service';
import { ToastContainer, toast } from 'react-toastify';
import { useParams } from 'react-router-dom';

export const StudentCountList = () => {
    const [studentCountList, setStudentCountList] = useState<any>([]);
    const [loading, setLoading] = useState(false);
    const [totalGirls, setTotalGirls] = useState<number>();
    const [totalBoys, setTotalBoys] = useState<number>();
    const [schoolStudentsCount, setSchoolStudentsCount] = useState(false);
    let { id, schoolCode } = useParams<{ id: string, schoolCode: string }>();
    const schoolsCode = schoolCode;
    useEffect(() => {
        getStudentCount();

        const accessObjectsData: any = localStorage.getItem("access_objects_data");
        onHandleTable(JSON.parse(accessObjectsData));
    }, []);

    function getStudentCount() {
        setLoading(true);
        GET_STUDENT_COUNT(schoolsCode).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    const data = res.rows;
                    setLoading(false);
                    setStudentCountList(data);
                    let maleCount = 0;
                    let femaleCount = 0;
                    for (let i = 0; i < data.length; i++) {
                        maleCount += data[i].male;
                        femaleCount += data[i].female;
                    }
                    setTotalBoys(maleCount);
                    setTotalGirls(femaleCount);
                }
            }
        });
    }
    function onHandleTable(accessObjectsList: any) {
        const preparedData = accessObjectsList?.filter(
            (el: any) => el.object_code === "SCHOOLS"
        );
        if (preparedData?.length > 0) {
            for (let i = 0; i < preparedData[0].child.length; i++) {

                if (
                    preparedData[0].child[i].object_code === "SCHOOLS-STUDENTS-COUNT" &&
                    preparedData[0].child[i].p_read === 1
                ) {
                    setSchoolStudentsCount(true);
                }

            }
        }
    }
    return (
        <div>
            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading &&
                <div className='mar-t-20 custom-scroll table-responsive'>
                    {schoolStudentsCount && <table className="table fixed-table-header mb-0">
                        <thead>
                            <tr>
                                <th>Standard</th>
                                <th>Division</th>
                                <th>Girls</th>
                                <th>Boys</th>
                            </tr>
                        </thead>
                        <tbody>
                            {studentCountList.map((name: any, i: number) => (
                                <tr key={i}>
                                    <td>{name.standard}</td>
                                    <td>{name.division}</td>
                                    <td>{name.female}</td>
                                    <td>{name.male}</td>
                                </tr>
                            ))}
                            {studentCountList.length > 0 &&
                                <tr>
                                    <td>Total</td>
                                    <td></td>
                                    <td>{totalGirls}</td>
                                    <td>{totalBoys}</td>
                                </tr>}
                        </tbody>
                    </table>}
                </div>}
            {studentCountList.length === 0 && <div style={{ textAlign: 'center' }}>
                No records Found
            </div>}
        </div>
    )
}
