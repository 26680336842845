import React, { useEffect, useRef, useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { CREATE_MODULE_ANSWERS, GET_MNS_MODULES_BY_ID, GET_MNS_MODULES_BY_ID_NEW, GET_MNS_MODULES_BY_ID_NEW_STUDENt, GET_MODULES_BY_ID, GET_MODULE_BY_QUESTIONS, UPDATE_ASSIGN_USER } from '../../../app/service/module.service';
import { GET_USER_USER_CODE } from '../../../app/service/users.service';
import FormBuilder from '../../../components/form-builder';

export const ParentServeyQuestions = () => {
    const [moduleData, setModuleData] = useState<any>({});
    const [questionsData, setQuestionsData] = useState<any[]>([]);
    const [answerData, setAnswerData] = useState<any[]>([]);
    const [currentAnswerData, setCurrentAnswerData] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    const [languageQuestionsData, setLanguageQuestionsData] = useState<any>([]);
    const [userRole, setUserRole] = useState<any>();
    const [canShowPopup, setCanShowPopup] = useState(false);
    const [canShowCompleteFormBtn, setCanShowCompleteFormBtn] = useState(false);
    const [questionsFormData, setQuestionsFormData] = useState<any>({});
    const surveyForm = useRef<any>({});
    const history = useHistory();
    let { userCode, moduleCode, language } = useParams<{ userCode: string, moduleCode: string, language: string }>();
    const user_code = userCode;
    const modules_code = moduleCode;
    const laguageCode = language;
    let parentCode = localStorage.getItem("user_code");
    const parentUserCode = `${parentCode}`;
    const [studentInfo, setStudentInfo] = useState<any>({});
    const moduleUuid = localStorage.getItem("moduleUuid");

    const languages = [{
        "name": "English",
        "code": "en"
    }, {
        "name": "Hindi",
        "code": "hi"
    }, {
        "name": "Marathi",
        "code": "mr"
    },
    {
        "name": "Gujarati",
        "code": "gu"
    }
    ];
    useEffect(() => {
        const userRole = localStorage.getItem("user_role");
        setUserRole(userRole);
        const childUserCode = sessionStorage.getItem('childUserCode') || '';
        if (userRole === 'PARENT') {
            setLoading(true);
            GET_MNS_MODULES_BY_ID_NEW(parentUserCode, "CAS-PARENT", user_code).then((res: any) => {
                if (res) {
                    if (res.status === 'fail') {
                        toast.error(res.message);
                        setLoading(false);
                    } else {
                        let data = res.rows.filter((el: any) => el.module_code === 'CAS-PARENT');
                        setModuleData(data[0]);
                        getQuestions(data[0]);
                        if (data[0]?.module_status === "completed") {
                            history.push("/parent-dashboard/view")
                        }
                        setLoading(false);
                    }
                }
            });
        } else {
            GET_MNS_MODULES_BY_ID_NEW_STUDENt(user_code, modules_code).then((res: any) => {
                if (res) {
                    if (res.status === 'fail') {
                        toast.error(res.message);
                    } else {

                        setModuleData(res.rows[0]);
                        getQuestions(res.rows[0]);
                    }
                }
            });
        }
        getUserDetails();

    }, []);

    function getQuestions(data: any) {

        const payload = {
            user_code: data?.user_code,
            school_code: data?.school_code,
            module_code: data?.module_code,
            sub_module: data?.sub_module,
            module_ver: data?.module_ver,
            target_std: "10",
            user_role: "PARENT",
            child_user_code: user_code
        }
        setLoading(true);
        GET_MODULE_BY_QUESTIONS(payload).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    let questionsData = res;
                    const data = res.en;
                    setLanguageQuestionsData(res);
                    const answers = res.answer;
                    setCurrentAnswerData(answers);
                    for (let i = 0; i < data.length; i++) {
                        for (let j = 0; j < answers.length; j++) {
                            if (i + 1 === answers[j].key) {

                                data[i].questionIndex = i;
                                if (answers[j].value === "a") {
                                    data[i].selectedOption = "" + 0;

                                } else if (answers[j].value === "b") {
                                    data[i].selectedOption = "" + 1;

                                } else if (answers[j].value === "c") {
                                    data[i].selectedOption = "" + 2;
                                } else {
                                    data[i].selectedOption = answers[j].value ? answers[j].value : null;
                                }
                            }
                        }
                    }
                    if (laguageCode === "en") {
                        setQuestionsData(questionsData.en);

                    } else if (laguageCode === "hi") {
                        setQuestionsData(questionsData.hi);

                    } else if (laguageCode === "mr") {
                        setQuestionsData(questionsData.mr);

                    } else if (laguageCode === "gu") {
                        setQuestionsData(questionsData.gu);

                    } else {
                        setQuestionsData(data);
                    }
                    setLoading(false);
                    const selectedQuestions = data.filter((el: any) => el.selectedOption);

                    if (selectedQuestions.length === data.length) {
                        setCanShowCompleteFormBtn(true);
                    } else {
                        setCanShowCompleteFormBtn(false);
                    }
                }
            }
        });
    }

    function handleQuestionOptions(e: any, j: number, index: number) {
        const data = questionsData;
        data[j].checked = true;
        data[j].questionIndex = j;
        data[j].selectedOption = data[j].question_type === 'multiple_choice' ? "" + index : (e.target.value ? e.target.value : null);
        const selectedQuestions = questionsData.filter(el => el.selectedOption);

        if (selectedQuestions.length === questionsData.length) {
            setCanShowCompleteFormBtn(true);
        } else {
            setCanShowCompleteFormBtn(false);
        }
        setQuestionsData(data);
    }

    const handleOptions = (data: any) => {
        setQuestionsFormData(data);
    }

    const handleSubmit = () => {
    }

    function onSubmitAnswers(type: any) {
        const answer = [];

        for (let j = 0; j < questionsData.length; j++) {
            if (questionsData[j].selectedOption) {
                let requiredAnswer = "";
                if (questionsData[j].selectedOption === "0") {
                    requiredAnswer = "a";
                } else if (questionsData[j].selectedOption === "1") {
                    requiredAnswer = "b";
                } else if (questionsData[j].selectedOption === "2") {
                    requiredAnswer = "c";
                } else {
                    requiredAnswer = questionsData[j].selectedOption;
                }
                answer.push({
                    "key": questionsData[j].questionIndex + 1,
                    "value": requiredAnswer,
                    'type': questionsData[j].question_type
                });
            }

        }
        let payload = {
            user_code: parentUserCode,
            school_code: moduleData.school_code,
            module_code: moduleData.module_code,
            sub_module: moduleData.sub_module,
            module_ver: moduleData.module_ver,
            target_std: "10",
            answer: answer,
            user_role: userRole,
        }
        if (userRole === 'PARENT') {
            payload = {
                ...payload, ...{ child_user_code: moduleData?.child_user_code ? moduleData?.child_user_code : '' }
            }
        }



        setLoading(true);
        CREATE_MODULE_ANSWERS(payload).then(res => {
            if (res.status === 'fail') {
                toast.error(res.message);
                setLoading(false);
            } else {
                setLoading(false);
                if (type === "submit") {
                    setCanShowPopup(true);
                }
            }
        });
    }

    const handleCompleteSubmit = () => {
        onSubmitAnswers("complete");
        moduleUpdate();
    }

    function moduleUpdate() {
        const payload = {
            id: moduleData.id,
            module_status: "completed"
        }
        setLoading(true);
        UPDATE_ASSIGN_USER(payload).then(res => {
            if (res.status === 'fail') {
                toast.error(res.message);
                setLoading(false);
            } else {
                setLoading(false);
                toast.success("Thank you for completing the survey.You can access Score sheet & Report now");
                history.push(`/parent-dashboard/view/servy-success/${moduleUuid}/${parentUserCode}/${user_code}`);
                setCanShowPopup(true);
            }
        });
    }




    function getUserDetails() {
        GET_USER_USER_CODE(user_code).then((response: any) => {
            if (response.status === 'fail') {
                toast.error(response.message);
                setLoading(false);
            } else {
                const data = response.rows[0];
                setStudentInfo(data);
            }
        });
    }
    return (
        <div>
            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading &&
                <div id="stk">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb pb-2 pt-0 px-0 border-bottom">
                            <li className="breadcrumb-item pe-2"><Link to='/parent-dashboard/view'>Dashboard</Link></li> /
                            <li className="breadcrumb-item"><a>Career AspirationTM Survey</a></li>

                        </ol>
                    </nav>
                    <div className="mt-3">
                        <p className="mb-0 text-success">Parent Survey for</p>
                        <h2 className="f22 fw-light">{studentInfo?.name} | {studentInfo?.standard}<sup>th</sup> Class</h2>
                    </div>

                    <div
                        className="p-3 time-block mt-3 d-md-flex justify-content-between align-items-end"
                    >
                        <div>
                            <h2 className="m-0">CAS Survey - {laguageCode === "en" && <span>English</span>} {laguageCode === "mr" && <span>मराठी</span>}{laguageCode === "hi" && <span>हिंदी</span>}{laguageCode === "gu" && <span>ગુજરાતી</span>}</h2>
                            <h3 className="m-0">01 of 5 Sets</h3>
                        </div>
                    </div>
                    <div className="row question-block mt-4">
                        <div className="col-md-9">
                            {questionsData?.map((question: any, i: number) => (
                                <div className="ps-md-3 mb-3">
                                    <h3>  {question.question_type === 'multiple_choice' && <span className='q-number'>Q: {i}. </span>}
                                        <span className='q-name'>{question.question}</span></h3>

                                    <FormBuilder onUpdate={handleOptions}>
                                        <form ref={surveyForm}>
                                            {question.options.map((option: any, j: number) => (
                                                <div key={j} style={{ display: "inline-block" }}>
                                                    {question.question_type === 'multiple_choice' &&
                                                        <div className='me-3 pe-1'>
                                                            <input className="form-check-input" type="radio" name="option" defaultChecked={parseInt(question?.selectedOption) === j} value={option} key={j} onChange={(e) => { handleQuestionOptions(e, i, j) }} />
                                                            <label className='ms-2'>{option}</label>
                                                        </div>
                                                    }
                                                </div>
                                            ))}
                                            {question.question_type === 'textarea' && <textarea className="form-control " name="option" style={{ width: '50%' }} value={question.selectedOption} onChange={(e) => { handleQuestionOptions(e, i, -1) }}></textarea>}
                                            {question.question_type === 'input_text' && <input type="text" className="form-control " name="option" style={{ width: '50%' }} value={question.selectedOption} onChange={(e) => { handleQuestionOptions(e, i, -1) }} />}
                                        </form>
                                    </FormBuilder>
                                </div>
                            ))}

                        </div>
                    </div>

                    <div className="border-top py-3 text-center">

                        <button className="btn btn-primary mb-3 px-5" disabled={!canShowCompleteFormBtn} onClick={handleCompleteSubmit}>Submit Test</button>

                        <button type="submit" className="btn btn-link mb-3"><Link to="/parent-dashboard/view">Cancel</Link></button>


                    </div>
                </div>}
        </div>
    )
}