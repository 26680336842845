import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { DataTable } from "../../../../components/data-table";
import { SponsorReportViewGridCols } from "./data-grid-cols";
import { MultiSelect } from "react-multi-select-component";
import { GET_INSTITUTE } from "../../../../app/service/institute.service";
import { toast } from "react-toastify";
import { FETCH_SPONSOR_REPORT_FILTER_LIST, GET_SPONSOR_REPORT_DATA_LIST, GET_SPONSOR_SCHOOL_INSTITUTE_ID, GET_STUDNETS_DATA_BY_SCHOOL_CODE } from "../../../../app/service/sponsor";
import { GET_STUDENTS_BY_STANDARD_DIVISION } from "../../../../app/service/users.service";
import moment from "moment";
import VIEWICONS from '../../../../institute-dashboard-assets/images/view-icons.svg'


export const SopnsorReportsData = () => {
    const [sponsorData, setSponsorData] = useState<any>([]);
    const [loading, setLoading] = useState(false);
    const [activePage, setActivePage] = useState<any>();
    const history = useHistory();
    const [pageArray, setPageNumbers] = useState([]);
    const [fromDate, setFromDate] = useState<any>("");
    const [toDate, setToDate] = useState<any>("");
    const [instituteData, setInstituteData] = useState<any>([]);
    const [schoolsData, setSchoolsData] = useState<any>([]);
    const [studentList, setStudentList] = useState<any>([]);
    const [standardList, setStandardList] = useState<any>([]);
    const [divisionList, setDivisionList] = useState<any>([]);
    const [studentsSelected, setStudentsSelected] = useState<any>([]);
    const [selectedOrganization, setSelectedOrganization] = useState<string>('');
    const [selectedNameOfSchool, setSelectedNameOfSchool] = useState<string>('');
    const [selectedStandard, setSelectedStandard] = useState<string>('');
    const [selectedDivision, setSelectedDivision] = useState<string>('');
    const [selectedStudents, setSelectedStudents] = useState<any>([]);
    const [schoolsCodeData, setSchoolsCodeData] = useState<any>();
    const [studentsData, setStudentsData] = useState<any>([]);
    const search = useLocation().search;
    const currentPageNumber: any = new URLSearchParams(search).get('page');
    const [scholLoading, setSchoolLoading] = useState(false);
    const [standardLoading, setStandardLoading] = useState(false);
    const [divisionLoading, setDivisionLoading] = useState(false);

    useEffect(() => {
        getInstitutesData();
        getSponsorData();

    }, []);
    function getInstitutesData() {
        // setLoading(true);
        GET_INSTITUTE().then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res.message);
                // setLoading(false);
            } else {
                const data = res.rows;
                setInstituteData(data);
                // setLoading(false);
            }
        });
    }

    function getSponsorData() {
        setLoading(true);
        GET_SPONSOR_REPORT_DATA_LIST().then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    const data = res;
                    setSponsorData(data);
                    setLoading(false);
                }
            }
        });
    }

    const handleChange = (e: any) => {
        setSchoolsData([]);
        setStandardList([]);
        setDivisionList([]);
        setStudentList([]);
        setStudentsSelected([]);
        setSelectedNameOfSchool('');
        setSelectedStandard('');
        setSelectedDivision('');
        setSelectedStudents('');
        if (e.target.value) {
            setSelectedOrganization(e.target.value)
            getSchoolsByInstituteCode(e.target.value);
        } else {
            setSelectedOrganization('');
        }
    }
    function getSchoolsByInstituteCode(data: any) {
        setSchoolLoading(true);
        GET_SPONSOR_SCHOOL_INSTITUTE_ID(data, 0, {}).then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res.message);
                setSchoolLoading(false);
            } else {
                const data = res.rows;
                let preparedData: any = [];
                let originalSchoolsData: any = []
                setSchoolsData(data);
                setSchoolLoading(false);
            }
        });
    }
    function handleSchoolChange(e: any) {
        setStandardList([]);
        setDivisionList([]);
        setStudentList([]);
        setStudentsSelected([]);
        setSelectedStandard('');
        setSelectedDivision('');
        setSelectedStudents('');
        setSchoolsCodeData('');
        if (e.target.value) {
            getStudentsBySchoolCode(e.target.value);
            setSchoolsCodeData(e.target.value);
            setSelectedNameOfSchool(e.target.value);
        } else {
            setSelectedNameOfSchool('')
        }
    }
    function getStudentsBySchoolCode(data: any) {
        setStandardLoading(true);
        GET_STUDNETS_DATA_BY_SCHOOL_CODE(data, 'STUDENT', 0, {}).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setStandardLoading(false);
                } else {
                    const data = res.rows;
                    data.forEach((element: any) => {
                        element.school_student = element.name;
                    });

                    setStudentsData(data);
                    let preparedStandard: any[] = [];
                    data.filter(function (item: any) {
                        let i = preparedStandard.findIndex((x: any) => (x.standard == item.standard));
                        if (i <= -1) {
                            preparedStandard.push({ standard: item.standard, value: item.standard });
                        }
                        return null;
                    });
                    preparedStandard.sort((a, b) => a.standard - b.standard)
                    setStandardList(preparedStandard);
                    // setLoading(false);
                    setStandardLoading(false);
                }
            }
        });
    }
    function handleStandardChange(e: any) {
        setDivisionList([]);
        setSelectedDivision('');
        setStudentList([]);
        setStudentsSelected([]);
        setSelectedStudents('');
        if (e.target.value) {
            let preparedData = {
                standard: e.target.value,
            }
            getStudentsByStandardDivision(preparedData);
            setSelectedStandard(e.target.value)
            setDivisionLoading(true);
            setTimeout(() => {
                getDivisionData(e);
            }, 1000);
        } else {
            setSelectedStandard('');
        }
    }
    function getDivisionData(e: any) {
        // setDivisionLoading(true);
        let preparedDivison: any[] = [];
        let element = studentsData.filter((ele: any) => {
            if (ele.standard == e.target.value) {

                return ele.division;
            }
        });
        element.filter(function (item: any) {
            let i = preparedDivison.findIndex((x: any) => (x.division == item.division));
            if (i <= -1) {
                preparedDivison.push({ division: item.division, value: item.division });
            }
            return null;
        });
        preparedDivison.sort((a, b) => a.division.localeCompare(b.division));
        setDivisionList(JSON.parse(JSON.stringify(preparedDivison)));
        setDivisionLoading(false);
    }
    function getStudentsByStandardDivision(data: any) {
        for (const key of Object.keys(data)) {
            if (data[key] === "") {
                delete data[key];
            }
        }
        GET_STUDENTS_BY_STANDARD_DIVISION(schoolsCodeData, data).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    const data = res.rows;
                    const preparedData = [];

                    if (data.length > 0) {
                        for (let i = 0; i < data.length; i++) {

                            for (let x = 0; x < studentsSelected.length; x++) {
                                if (data[i].user_code === studentsSelected[x].user_code) {
                                    data[i].completedStatus = true;
                                }
                            }
                        }
                        for (let k = 0; k < data.length; k++) {
                            if (data[k].completedStatus) {
                                preparedData.push({
                                    label: data[k].name,
                                    value: data[k].user_code,
                                    disabled: true
                                });
                            } else {
                                preparedData.push({
                                    label: data[k].name,
                                    value: data[k].user_code
                                });
                            }
                        }
                        setStudentList(preparedData);

                    } else {
                        setStudentList([]);
                    }
                }
            }
        });
    }

    function handleDivisionChange(e: any) {
        setStudentList([]);
        setStudentsSelected([]);
        setSelectedStudents('');

        if (e.target.value) {
            setSelectedDivision(e.target.value);
            let preparedData = {
                standard: selectedStandard,
                division: e.target.value,
            }
            getStudentsByStandardDivision(preparedData);
        } else {
            setSelectedDivision('');
            let preparedData = {
                standard: selectedStandard
            }
            getStudentsByStandardDivision(preparedData);
        }
    }
    function onChangeMultiSelectStudents(e: any) {
        setStudentsSelected(e)
    }
    function filterData() {
        const payload = {
            organization_code: selectedOrganization,
            institute_school_code: selectedNameOfSchool,
            standard: selectedStandard,
            division: selectedDivision,
            payment_dt_from: fromDate ? `${fromDate} 00:00:00` : "",
            payment_dt_to: toDate ? `${toDate} 23:59:59` : ""
        }
        if (selectedOrganization || selectedNameOfSchool || selectedStandard || selectedDivision || toDate) {
            setLoading(true);
            FETCH_SPONSOR_REPORT_FILTER_LIST(payload).then((res: any) => {
                if (res) {
                    if (res.status === 'fail') {
                        toast.error(res.message);
                        setLoading(false);
                    } else {
                        const data = res;
                        setSponsorData(data);
                        setLoading(false);
                    }
                }
            });
        }

    }
    function openStudentsReport() {
        let routesData = {
            selectedOrganization: selectedOrganization ? selectedOrganization : 0,
            selectedNameOfSchool: selectedNameOfSchool ? selectedNameOfSchool : 0,
            selectedStandard: selectedStandard ? selectedStandard : 0,
            selectedDivision: selectedDivision ? selectedDivision : 0,
            fromDate: fromDate ? fromDate : 0,
            toDate: toDate ? toDate : 0,

        }
        history.push(`/dashboard/sponsor-report/student-list?organization_code=${selectedOrganization}&institute_school_code=${selectedNameOfSchool}&standard=${selectedStandard}&division=${selectedDivision}&payment_dt_from=${fromDate}&payment_dt_to=${toDate}`)
    }
    function handleRefresh() {
        setInstituteData([]);
        setSchoolsData([]);
        setStandardList([]);
        setDivisionList([]);
        setSelectedOrganization("");
        setSelectedNameOfSchool("");
        setSelectedStandard("");
        setSelectedDivision("");
        setFromDate("");
        setToDate("");
        getSponsorData();
        getInstitutesData();

    }
    return (
        <div>
            <div className="mt-5">
                <div className={`row custom-form`}>
                    <div className="col-11">
                        <div className="row f14">
                            <div className="col-md-6 col-lg-3">
                                <div className="mb-4">
                                    <label className="form-label mb-0 mt-2">Name of Institute</label>
                                    <span style={{ color: 'red', fontSize: '20px', paddingLeft: '10px' }}></span>
                                    <select name="organization_code" className="form-select form-select-lg mb-3 f14" onChange={(e) => { handleChange(e) }}>
                                        <option value={""}>Select the institute</option>
                                        {instituteData.map((name: any, i: number) => (
                                            <option key={i} value={name.organization_code}>
                                                {name.name_of_organization}
                                            </option>
                                        ))}
                                    </select>

                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3">

                                <div className="mb-4">
                                    {scholLoading &&
                                        <div className="ms-5 ps-5 mt-4 pt-3 position-absolute  align-items-end">
                                            <div className="spinner-border ms-5 ms-md-0" role="status" style={{ height: "23px", width: "23px" }} >
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                        </div>
                                    }
                                    <label className="form-label mb-0 mt-2">Name of School </label>
                                    <select name="institute_school_code" className="form-select form-select-lg mb-3 f16" onChange={(e) => { handleSchoolChange(e) }}>
                                        <option value="">Select the school</option>
                                        {schoolsData?.map((name: any, i: number) => (
                                            <option key={i} value={name.school_code}>
                                                {name.name_of_school}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-3">

                                <div className="mb-4">
                                    {standardLoading &&
                                        <div className="ms-5 ps-4 mt-4 pt-3 position-absolute  align-items-end">
                                            <div className="spinner-border ms-5 ms-md-0" role="status" style={{ height: "23px", width: "23px" }} >
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                        </div>
                                    }
                                    <label className="form-label mb-0 mt-2">Standard</label>
                                    <select className="form-select form-select-lg mb-3 f16" name="standard" onChange={(e) => { handleStandardChange(e) }}>
                                        <option value="">Select standard</option>
                                        {standardList?.map((data: any, i: number) => {
                                            return (
                                                <option value={data.value} key={i}>{data.standard}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-3">

                                <div className="mb-4">
                                    {divisionLoading &&
                                        <div className="ms-5 ps-4 mt-4 pt-3 position-absolute  align-items-end">
                                            <div className="spinner-border ms-5 ms-md-0" role="status" style={{ height: "23px", width: "23px" }} >
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                        </div>
                                    }
                                    <label className="form-label mb-0 mt-2">Division</label>
                                    <select className="form-select form-select-lg mb-3 f16" name="division" onChange={(e) => { handleDivisionChange(e) }}>
                                        <option value="">Select division</option>
                                        {divisionList?.map((data: any, i: number) => {
                                            return (
                                                <option value={data.value} key={i}>{data.division}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                                {/* // } */}
                            </div>

                        </div>
                    </div>
                </div>
                <div className="row custom-form mb-5">
                    <div className="col-md-4 ">
                        <label className="form-label mb-0 mt-3">Payment From Date</label>
                        <input type="date" className='form-control' name="expiry_dt" value={fromDate} onChange={(e) => setFromDate(e.target.value)} onKeyDown={(event) => {
                            event.preventDefault();
                        }} />
                    </div>
                    <div className="col-md-4">
                        <label className="form-label mb-0 mt-3">Payment To Date</label>
                        <input type="date" className='form-control' name="expiry_dt" value={toDate} onChange={(e) => setToDate(e.target.value)} onKeyDown={(event) => {
                            event.preventDefault();
                        }} />
                    </div>
                    <div className="col-md-4 ">
                        <button className="btn btn-primary mt-4 ms-md-4" onClick={filterData}>Search</button>
                        <button className="btn clear-btn cursor-pointer mt-4 ms-3" type="button" onClick={() => handleRefresh()}>
                            Clear
                        </button>
                    </div>
                </div>
                {loading &&
                    <div className="text-center p-5">
                        <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                }
                {!loading &&
                    <div>
                        <div className='table-responsive mar-t-20 custom-scroll'>
                            <table className='table table  fixed-table-header mb-0'>
                                <thead>
                                    <tr>
                                        {sponsorData?.organization_name && <th style={{ width: '150px' }}>Institute Name</th>}
                                        {sponsorData?.school_name && <th style={{ width: '150px' }}> School Name</th>}
                                        <th style={{ width: '90px' }}> Total Students</th>
                                        <th style={{ width: '100px' }}> Sponsored Students</th>
                                        <th style={{ width: '100px' }}> Sponsored Boys</th>
                                        <th style={{ width: '100px' }}>Sponsored Girls</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Object.keys(sponsorData).length > 0 &&
                                        <tr>
                                            {sponsorData?.organization_name && <td>{sponsorData?.organization_name}</td>}
                                            {sponsorData?.school_name && <td> {sponsorData?.school_name}</td>}
                                            <td> {sponsorData?.total_students}</td>
                                            <td>{sponsorData?.total_sponsored > 0 && <img className='eye-icons ms-1 cursor-pointer me-2' src={VIEWICONS} onClick={() => openStudentsReport()} />}{sponsorData?.total_sponsored} </td>
                                            <td>{sponsorData?.total_sponsored_boys} </td>
                                            <td>{sponsorData?.total_sponsored_girls}</td>
                                        </tr>}
                                </tbody>
                            </table>
                        </div>
                        {Object.keys(sponsorData).length == 0 && <p className="f16 text-center">No Data Available</p>}
                    </div>
                }
            </div>
        </div>
    )
}