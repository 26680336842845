import React, { useEffect, useState } from "react";
import { MultiSelect } from 'react-multi-select-component';
import { FormControlError, FormField, FormValidators } from "../../../components/form-builder/model/form-field";
import { FormValidator, GetControlIsValid } from "../../../components/form-builder/validations";
import errorMessages from "../../../components/error-messages";
import FormBuilder from "../../../components/form-builder";
import { GET_INSTITUTE } from "../../../app/service/institute.service";
import { toast } from "react-toastify";
import { FECTH_SPONSORS_DATA, GENERATE_PAYMENT_LINK, GET_SPONSOR_SCHOOL_INSTITUTE_ID, GET_STUDNETS_DATA_BY_SCHOOL_CODE, UPDATE_SPONSORS_DATA } from "../../../app/service/sponsor";
import { Label } from "recharts";
import { GET_STUDENTS_BY_STANDARD_DIVISION } from "../../../app/service/users.service";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { UI_BASE_URL } from "../../../config/constant";
import moment from "moment";
import VIEWICONS from '../../../institute-dashboard-assets/images/view-icons.svg'

function SponsorForm() {
    const [validOrganization, setValidOrganization] = useState(false);
    const [checkValidOrg, setCheckValidOrg] = useState(false);
    const [sponsorvalidationErrors, setSponsorvalidationErrors] = useState<FormControlError[]>([]);
    let { id } = useParams<{ id: any }>();
    const history = useHistory();
    const [currentSponsorData, setCurrentSponsorData] = useState<any>([]);
    const sponsorId = id;
    const [nameError, setNameError] = useState('');
    const [paymentNameError, setPaymentNameError] = useState('');
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [email, setEmail] = useState(false);
    const [mobileNumber, setMobileNumber] = useState(false);
    const [sponsorData, setSponsorData] = useState<any>({});
    const [loading, setLoading] = useState(false);
    const [sponsorLoading, setSponsorLoading] = useState(false);
    const [instituteData, setInstituteData] = useState<any>([]);
    const [schoolsData, setSchoolsData] = useState<any>([]);
    const [studentList, setStudentList] = useState<any>([]);
    const [studentsData, setStudentsData] = useState<any>([]);
    const [paymentCompletedList, setPaymentCompletedList] = useState<any>([]);
    const [studentsSelected, setStudentsSelected] = useState<any>([]);
    const [standardList, setStandardList] = useState<any>([]);
    const [divisionList, setDivisionList] = useState<any>([]);
    const [schoolsCodeData, setSchoolsCodeData] = useState<any>();
    const [preparedSchoolData, setPreparedSchoolData] = useState<any>();
    const [onlySchoolsData, setOnlySchoolsData] = useState<any>();
    let originalAllSchoolsData: any;
    const [canShowStudentsPopup, setCanStudentsPopup] = useState(false);
    const [studentDetails, setStudentDetails] = useState([]);
    const [dateError, setDateError] = useState('');
    const [selectedOrganization, setSelectedOrganization] = useState<string>('');
    const [selectedNameOfSchool, setSelectedNameOfSchool] = useState<string>('');
    const [selectedStandard, setSelectedStandard] = useState<string>('');
    const [selectedDivision, setSelectedDivision] = useState<string>('');
    const [selectedStudents, setSelectedStudents] = useState<any>([]);
    const [mobileNumberValue, setMobileNumberValue] = useState<string>('');
    const [emailData, setEmailData] = useState<string>('');
    const [scholLoading, setSchoolLoading] = useState(false);
    const [standardLoading, setStandardLoading] = useState(false);
    const [divisionLoading, setDivisionLoading] = useState(false);
    const [studentLoading, setStudentLoading] = useState(false);
    const currentUrl = window.location.href.split('#')[0];


    let allPreparedData: any;
    const [sponsorForm, setSponsorForm] = useState<any>([{
        institute_school_code: "",
        standard: "",
        division: "",
        students: []
    }])

    const sponsorFormValidations = [
        // new FormField('name', [FormValidators.REQUIRED]),
        new FormField('email_id', [FormValidators.REQUIRED]),
        new FormField('mobile_number', [FormValidators.REQUIRED]),
        // new FormField('organization_code', [FormValidators.REQUIRED]),

    ];
    const [numFields, setNumFields] = useState<number>(0);
    const [minDates, setMinDates] = useState<any>("")

    const [sponsorInputNames, setSponsorInputNames] = useState([])
    const [sponsorDataName, setSponsorDataName] = useState({
        institute_code: "",
        institute_school_code: "",
        standard: "",
        division: "",
        students: []
    })

    const [updateSponsorData, setUpdateSponsorData] = useState([]);
    const [studentPoupData, setStudentPoupData] = useState([]);
    useEffect(() => {
        if (sponsorId === "0") {
            getInstitutesData();
        }
        else {
            getSponsorData();
        }

    }, [divisionList]);
    function getSponsorData() {
        setSponsorLoading(true);

        FECTH_SPONSORS_DATA(sponsorId).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setSponsorLoading(false);
                }
                else {
                    const data = res.rows[0];
                    if (data?.expiry_dt) {
                        data.expiry_dt = moment(data.expiry_dt.split("T")[0]).format('yyyy-MM-DD');
                    }

                    setCurrentSponsorData(data);
                    if (data?.expiry_dt) {
                        setMinDates(data.expiry_dt);
                    }
                    setMobileNumberValue(data?.sponsor_details?.mobile_number);
                    setEmailData(data?.sponsor_details?.email_id);
                    setSponsorLoading(false);
                }
            }

        });
    }

    function getInstitutesData() {
        // setLoading(true);
        GET_INSTITUTE().then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res.message);
                // setLoading(false);
            } else {
                const data = res.rows;
                setInstituteData(data);
                // setLoading(false);
            }
        });
    }
    function addForms() {
        if (checkValidOrg) {


            let studentInfo = [...selectedStudents];

            studentInfo.map((x, i) => {
                let data = x;
                data['user_code'] = x.value;
                delete data.value;
                delete data.label;
                return data;
            })

            let sponserData = {
                "institute_code": selectedOrganization,
                "institute_school_code": selectedNameOfSchool,
                "standard": selectedStandard,
                "division": selectedDivision,
                "students": studentInfo
            }

            let list: any = [...updateSponsorData];
            list.push(sponserData);
            setUpdateSponsorData(list);
            let preparedListData: any = [...sponsorInputNames]
            preparedListData.push(sponsorDataName);
            setSponsorInputNames(preparedListData);
            setInstituteData([]);
            setSchoolsData([]);
            setStandardList([]);
            setDivisionList([]);
            setStudentList([]);
            setStudentsSelected([]);
            setSelectedOrganization('');
            setSelectedNameOfSchool('');
            setSelectedStandard('');
            setSelectedDivision('');
            setSelectedStudents('');
            setSponsorDataName({
                institute_code: "",
                institute_school_code: "",
                standard: "",
                division: "",
                students: []
            })

            setCheckValidOrg(false);
            getInstitutesData();

        } else {
            if (!checkValidOrg) {
                setValidOrganization(true);
            }
        }

    }
    function onDeleteForm(i: number) {
        let newSponsorForm = [...updateSponsorData];
        newSponsorForm.splice(i, 1);
        setUpdateSponsorData(newSponsorForm);

        let nameOfObjectValue = [...sponsorInputNames];
        nameOfObjectValue.splice(i, 1);
        setSponsorInputNames(nameOfObjectValue);
    }
    const getSponsorInputValid = (control: string) => {
        const value = GetControlIsValid(sponsorvalidationErrors, control);
        return value;
    }
    const onChangeName = (event: any) => {
        setNameError('');
        const reg = /^[A-Za-z]+(?:[ _-][A-Za-z]+)*$/;
        if (event) {
            if (!reg.test(event.trim())) {
                setNameError('Enter only alphabets');
            } else {
                return true;
            }
        } else {
            setNameError('Please enter name');
        }
    }
    const onChangePaymentName = (event: any) => {
        setPaymentNameError('');
        const reg = /^[A-Za-z]+(?:[ _-][A-Za-z]+)*$/;
        if (event) {
            if (!reg.test(event.trim())) {
                setPaymentNameError('Enter only alphabets');
            } else {
                return true;
            }
        }
    }
    const handleEmailChange = (e: any) => {
        const data = { ...sponsorData?.value };
        const re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,6})+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            data.email_id = e.target.value;
            setEmail(false);
        } else {
            data.email_id = e.target.value;
            setEmail(true);
        }
        if (e.target.value === '') {
            setEmail(false);
        }
        setEmailData('');
        setSponsorData(data);


    }
    const handleMobileChange = (e: any) => {
        const data = { ...sponsorData?.value };
        const re = /(6|7|8|9)\d{9}/;
        if ((e.target.value === '' || re.test(e.target.value)) && e.target.value.length === 10) {
            data.mobile_number = e.target.value;
            setMobileNumber(false);
        } else {
            data.mobile_number = e.target.value;
            setMobileNumber(true);
        }
        if (e.target.value === '') {
            setMobileNumber(false);
        }
        setMobileNumberValue("");
        setSponsorData(data);
    }
    const handleChange = (e: any) => {
        setSchoolsData([]);
        setStandardList([]);
        setDivisionList([]);
        setStudentList([]);
        setStudentsSelected([]);
        setSelectedNameOfSchool('');
        setSelectedStandard('');
        setSelectedDivision('');
        setSelectedStudents('');
        if (e.target.value) {
            if (e) {
                setCheckValidOrg(true);
                setValidOrganization(false);
            } else {
                setCheckValidOrg(false);
            }
            setSelectedOrganization(e.target.value)
            getSchoolsByInstituteCode(e.target.value);
            sponsorDataName.institute_code = instituteData?.find((x: any) => x.organization_code === e.target.value)?.name_of_organization;
            sponsorDataName.institute_school_code = "";
            sponsorDataName.standard = "";
            sponsorDataName.division = "";
            sponsorDataName.students = []
        } else {
            setSelectedOrganization('');
        }

    }
    function getSchoolsByInstituteCode(data: any) {
        setSchoolLoading(true);
        GET_SPONSOR_SCHOOL_INSTITUTE_ID(data, 0, {}).then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res.message);
                setSchoolLoading(false);
            } else {
                const data = res.rows;
                let preparedData: any = [];
                let originalSchoolsData: any = []
                setSchoolsData(data);
                setSchoolLoading(false);

            }
        });
    }
    function handleSchoolChange(e: any) {
        setStandardList([]);
        setDivisionList([]);
        setStudentList([]);
        setStudentsSelected([]);
        setSelectedOrganization('');
        setSelectedStandard('');
        setSelectedDivision('');
        setSelectedStudents('');
        if (e.target.value) {
            getStudentsBySchoolCode(e.target.value);
            setSchoolsCodeData(e.target.value);
            setSelectedNameOfSchool(e.target.value);
            sponsorDataName.institute_school_code = schoolsData?.find((x: any) => x.school_code === e.target.value)?.name_of_school;
            sponsorDataName.standard = "";
            sponsorDataName.division = "";
            sponsorDataName.students = []
        }
        else {
            setSchoolsCodeData('');
            setSelectedNameOfSchool('')
        }
    }
    function handleStandardChange(e: any) {

        setDivisionList([]);
        setSelectedDivision('');
        setStudentList([]);
        setStudentsSelected([]);
        setSelectedStudents('');
        if (e.target.value) {
            setDivisionLoading(true);
            setTimeout(() => {
                getDivisionData(e);
            }, 1000);
            setSelectedStandard(e.target.value);
            let preparedData = {
                standard: e.target.value,
            }
            getStudentsByStandardDivision(preparedData);
            sponsorDataName.standard = e.target.value;
            sponsorDataName.division = "";
            sponsorDataName.students = []
        } else {
            setSelectedStandard('');
        }
    }
    function getDivisionData(e: any) {
        let preparedDivison: any[] = [];
        let element = studentsData.filter((ele: any) => {
            if (ele.standard == e.target.value) {

                return ele.division;
            }
        });
        element.filter(function (item: any) {
            let i = preparedDivison.findIndex((x: any) => (x.division == item.division));
            if (i <= -1) {
                preparedDivison.push({ division: item.division, value: item.division });
            }
            return null;
        });
        preparedDivison.sort((a, b) => a.division.localeCompare(b.division));
        setDivisionList(JSON.parse(JSON.stringify(preparedDivison)));

        setDivisionLoading(false);
    }
    function handleDivisionChange(e: any) {
        setStudentList([]);
        setStudentsSelected([]);
        setSelectedStudents('');

        if (e.target.value) {
            setStudentLoading(true);
            let preparedData = {
                standard: sponsorDataName.standard,
                division: e.target.value,
            }
            getStudentsByStandardDivision(preparedData);

            setSelectedDivision(e.target.value);

            sponsorDataName.division = e.target.value;
            sponsorDataName.students = []
        } else {
            setSelectedDivision('');
            let preparedData = {
                standard: sponsorDataName.standard
            }
            getStudentsByStandardDivision(preparedData);
        }
    }
    function getStudentsBySchoolCode(data: any) {
        setStandardLoading(true);
        GET_STUDNETS_DATA_BY_SCHOOL_CODE(data, 'STUDENT', 0, {}).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setStandardLoading(false);
                } else {
                    const data = res.rows;
                    data.forEach((element: any) => {
                        element.school_student = element.name;
                    });
                    setStudentsData(data);
                    let preparedStandard: any[] = [];
                    data.filter(function (item: any) {
                        let i = preparedStandard.findIndex((x: any) => (x.standard == item.standard));
                        if (i <= -1) {
                            preparedStandard.push({ standard: item.standard, value: item.standard });
                        }
                        return null;
                    });
                    preparedStandard.sort((a, b) => a.standard - b.standard)
                    setStandardList(preparedStandard);
                    setStandardLoading(false);
                }
            }
        });
    }
    function getStudentsByStandardDivision(data: any) {
        for (const key of Object.keys(data)) {
            if (data[key] === "") {
                delete data[key];
            }
        }
        // setStudentLoading(true);
        GET_STUDENTS_BY_STANDARD_DIVISION(schoolsCodeData, data).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setStudentLoading(false);
                } else {
                    const data = res.rows;
                    const preparedData = [];

                    if (data.length > 0) {
                        for (let i = 0; i < data.length; i++) {
                            for (let j = 0; j < paymentCompletedList.length; j++) {
                                if (data[i].user_code === paymentCompletedList[j].user_code) {
                                    data[i].completedStatus = true;
                                }
                            }
                            for (let x = 0; x < studentsSelected.length; x++) {
                                if (data[i].user_code === studentsSelected[x].user_code) {
                                    data[i].completedStatus = true;
                                }
                            }
                        }
                        for (let k = 0; k < data.length; k++) {
                            if (data[k].completedStatus) {
                                preparedData.push({
                                    label: data[k].name,
                                    value: data[k].user_code,
                                    disabled: true
                                });
                            } else {
                                preparedData.push({
                                    label: data[k].name,
                                    value: data[k].user_code
                                });
                            }
                        }
                        setStudentList(preparedData);
                        setStudentPoupData(data);
                        setStudentLoading(false);

                    } else {
                        setStudentList([]);
                        setStudentLoading(false);

                    }
                    setStudentLoading(false);
                }
            }
        });
    }
    function handleSponsorInput(data: any) {
        data.value = { ...currentSponsorData, ...data.value };
        setSponsorData(data);
        const errors: any = FormValidator(sponsorFormValidations, data.value);
        setSponsorvalidationErrors(errors);
    }

    function handleSubmit() {
        const sponsorsData = sponsorData.value ? { ...sponsorData?.value } : { ...currentSponsorData };
        let studentInfo = [...selectedStudents];

        studentInfo.map((x, i) => {
            let data = x;
            data['user_code'] = x.value;
            delete data.value;
            delete data.label;
            return data;
        })

        const payload = {
            name: sponsorsData.name ? sponsorsData.name : '',
            code: "STUDENT_SUBSCRIPTION",
            sponsor_name: sponsorsData.sponsor_name,
            expiry_dt: minDates ? `${minDates} 23:59:59` : null,
            sponsor_details: {
                mobile_number: sponsorsData.mobile_number,
                email_id: sponsorsData.email_id
            },
            students: updateSponsorData.length > 0 ? updateSponsorData : [{
                "institute_code": selectedOrganization,
                "institute_school_code": selectedNameOfSchool,
                "standard": selectedStandard,
                "division": selectedDivision,
                "students": studentInfo
            }],
            payment_link: `${currentUrl}#/sponsor/paymentdata`
        }

        // setIsFormSubmitted(true);
        const errors: FormControlError[] = FormValidator(sponsorFormValidations, sponsorsData);
        setSponsorvalidationErrors(errors);
        if (!email && !mobileNumber && onChangeName(sponsorsData?.sponsor_name) && (checkValidOrg || updateSponsorData.length > 0) && onChangePaymentName(sponsorsData?.name)) {
            setLoading(true);

            GENERATE_PAYMENT_LINK(payload).then((res: any) => {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    const data = res;
                    toast.success("Sponsor Created Successfully");
                    history.push('/dashboard/sponsor');
                    setLoading(false);
                }
            });
        }
        else {
            if (!nameError && !onChangeName(sponsorsData?.sponsor_name)) {
                setNameError('Please enter name');
            }
            if (!checkValidOrg && updateSponsorData.length < 1) {
                setValidOrganization(true);
            }
            if (!paymentNameError && !onChangePaymentName(sponsorsData?.name)) {
                setPaymentNameError('Please enter payment name');
            }
        }
    }
    function handleUpdate() {
        const sponsorsData = sponsorData.value ? { ...sponsorData?.value } : { ...currentSponsorData };
        const payload = {
            name: sponsorsData.name ? sponsorsData.name : "",
            code: "STUDENT_SUBSCRIPTION",
            sponsor_name: sponsorsData.sponsor_name,
            sponsor_details: {
                mobile_number: sponsorsData.mobile_number ? sponsorsData.mobile_number : mobileNumberValue,
                email_id: sponsorsData.email_id ? sponsorsData.email_id : emailData
            },
            expiry_dt: minDates ? `${minDates} 23:59:59` : null,
            payment_status: sponsorsData.payment_status,
            transaction_uuid: ''

        }
        const errors: FormControlError[] = FormValidator(sponsorFormValidations, sponsorsData);
        setSponsorvalidationErrors(errors);
        if (!email && !mobileNumber && onChangeName(sponsorsData?.sponsor_name) && onChangePaymentName(sponsorsData?.name)) {
            setLoading(true);
            UPDATE_SPONSORS_DATA(sponsorId, payload).then((res: any) => {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    const data = res;
                    toast.success("Sponsor Updated Successfully");
                    history.push('/dashboard/sponsor');
                    setLoading(false);
                }
            });
        }
        else {
            if (!nameError && !onChangeName(sponsorsData?.sponsor_name)) {
                setNameError('Please enter name');
            }
            if (!paymentNameError && !onChangePaymentName(sponsorsData?.name)) {
                setPaymentNameError('Please enter payment name');
            }
        }
    }
    function onChangeMultiSelectStudents(e: any) {
        setStudentsSelected(e)
        setSelectedStudents(e);
        // sponsorDataName.students = e
        let preparedStudents: any = [];
        studentPoupData.filter((ele: any, i: number) => {
            for (let x = 0; x < e.length; x++) {
                if (e[x].value === ele.user_code) {
                    preparedStudents.push({
                        name: ele.name,
                        name_of_school: ele.name_of_school,
                        standard: ele.standard,
                        division: ele.division
                    })
                }
            }
        })
        sponsorDataName.students = preparedStudents;


    }

    const getStudentsDetails = (data: any) => {
        setCanStudentsPopup(true);
        setStudentDetails(data?.students);
    }
    const handleStudentsClose = () => {
        setCanStudentsPopup(false);
    };
    const getCurrentDate = () => {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const day = String(today.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };
    return (
        <div>
            <div
                className={`modal fade ${canShowStudentsPopup ? "show" : "false"}`}
                style={{ display: canShowStudentsPopup ? "block" : "none" }}
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog parent-model-dialog" role="document">
                    <div className="modal-content parent-model">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                                Selected Students
                            </h5>
                            <button
                                type="button"
                                className="close popup-close cursor-pointer"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={handleStudentsClose}
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body parent-info parent-model">
                            <div className='table-responsive mar-t-20 custom-scroll'>
                                <table className='table table  fixed-table-header mb-0'>
                                    <thead>
                                        <tr>
                                            <th style={{ width: "70px" }}>S.No</th>
                                            <th style={{ width: '120px' }}>Student Name</th>
                                            {sponsorId === "0" && <th style={{ width: '120px' }}>School Name</th>}
                                            <th style={{ width: '120px' }}> Standard</th>
                                            <th style={{ width: '90px' }}> Division</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {studentDetails?.map((data: any, i: number) => {
                                            return (
                                                <tr key={i}>
                                                    <td>{i + 1}</td>
                                                    <td> {data.name}</td>
                                                    {sponsorId === "0" && <td> {data.name_of_school}</td>}
                                                    <td> {data.standard}</td>
                                                    <td>{data.division} </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                            {studentDetails.length == 0 && <p className="f14 text-center mt-3">No Data Available</p>}
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary cursor-pointer"
                                data-dismiss="modal"
                                onClick={handleStudentsClose}
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {sponsorLoading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!sponsorLoading && <div>
                <div className="row border-top border-primary py-3">
                    <div style={{ borderBottom: 'solid 1px black', marginTop: '15px', paddingLeft: '10px' }} className="mb-4">
                        <h5 className="form-label mb-2 d-block">Sponsor Details</h5>
                    </div>
                    <FormBuilder onUpdate={handleSponsorInput}>
                        <form>
                            <div className="row custom-form">
                                <div className="col-md-4">
                                    <div className="mb-4">
                                        <label className="form-label mb-0">Sponsorship Name</label>
                                        <span style={{ color: 'red', fontSize: '20px', paddingLeft: '10px' }}>*</span>
                                        <input className="form-control form-control-lg" type="text" name="name" onChange={(e) => onChangePaymentName(e.target.value)} placeholder="Please enter sponsorship name" defaultValue={currentSponsorData?.name} />
                                        {paymentNameError && <p className="text-danger">{paymentNameError}</p>}
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="mb-4">
                                        <label className="form-label mb-0 mt-3">Expriy Date</label>
                                        <input type="date" className='form-control' name="expiry_dt" value={minDates} min={getCurrentDate()} onChange={(e) => setMinDates(e.target.value)} onKeyDown={(event) => {
                                            event.preventDefault();
                                        }} />
                                        {dateError && <p className="text-danger">{dateError}</p>}
                                    </div>
                                </div>
                            </div>
                            <div className="row custom-form">
                                <div className="col-md-4">
                                    <div className="mb-4">
                                        <label className="form-label mb-0">Mrs./ Mr
                                            <span style={{ paddingLeft: '5px' }}>Name</span></label>
                                        <span style={{ color: 'red', fontSize: '20px', paddingLeft: '10px' }}>*</span>
                                        <input className="form-control form-control-lg" type="text" name="sponsor_name" onChange={(e) => onChangeName(e.target.value)} placeholder="Please enter name" defaultValue={currentSponsorData?.sponsor_name} />
                                        {nameError && <p className="text-danger">{nameError}</p>}
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="mb-4">
                                        <label className="form-label mb-0 mt-1">Email</label>
                                        <input className="form-control form-control-lg" type="text" name="email_id" onChange={(e) => { handleEmailChange(e) }} placeholder="Please enter email" defaultValue={currentSponsorData?.sponsor_details?.email_id} />
                                        {email && <p className="text-danger">{errorMessages.email}</p>}
                                        {isFormSubmitted && !getSponsorInputValid('email_id') && <p className="text-danger">Please enter email</p>}
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="mb-4">
                                        <label className="form-label mb-0 mt-1">Mobile Number</label>
                                        <input className="form-control form-control-lg" type="text" name="mobile_number" maxLength={10} onChange={(e) => { handleMobileChange(e) }} placeholder="Please enter mobile number" defaultValue={currentSponsorData?.sponsor_details?.mobile_number} />
                                        {mobileNumber && <p className="text-danger">{errorMessages.mobile_number}</p>}
                                        {isFormSubmitted && !getSponsorInputValid('mobile_number') && <p className="text-danger">Please enter mobile number</p>}
                                    </div>
                                </div>
                            </div>
                        </form>
                    </FormBuilder>
                    {sponsorId === "0" &&
                        <div>
                            <div style={{ borderBottom: 'solid 1px black', marginTop: '15px', paddingLeft: '10px' }} className="mb-4">
                                <h5 className="form-label mb-2 d-block">Add Schools / Students</h5>
                            </div>
                            {/* {sponsorForm?.map((name: any, i: number) => ( */}
                            <div className={`row custom-form `}>
                                <div className="col-11">
                                    <div className="row f14">
                                        <div className="col-md-6 col-lg-3">
                                            <div className="mb-4">
                                                <label className="form-label mb-0 mt-2">Name of Institute</label>
                                                <span style={{ color: 'red', fontSize: '20px', paddingLeft: '10px' }}>*</span>
                                                <select name="organization_code" className="form-select form-select-lg mb-3 f14" onChange={(e) => { handleChange(e) }}>
                                                    <option value={""}>Select the institute</option>
                                                    {instituteData.map((name: any, i: number) => (
                                                        <option key={i} value={name.organization_code}>
                                                            {name.name_of_organization}
                                                        </option>
                                                    ))}
                                                </select>
                                                {validOrganization && <p className="text-danger">Please select name of organization</p>}
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-lg-3">
                                            <div className="mb-4">
                                                {scholLoading &&
                                                    <div className="ms-5 ps-5 mt-4 pt-3 position-absolute  align-items-end">
                                                        <div className="spinner-border ms-5 ms-md-0" role="status" style={{ height: "23px", width: "23px" }} >
                                                            <span className="sr-only">Loading...</span>
                                                        </div>
                                                    </div>
                                                }
                                                <label className="form-label mb-0 mt-2">Name of School </label>
                                                <select name="institute_school_code" className="form-select form-select-lg mb-3 f16" onChange={(e) => { handleSchoolChange(e) }}>
                                                    <option value={""}>Select the school</option>
                                                    {schoolsData?.map((name: any, i: number) => (
                                                        <option key={i} value={name.school_code}>
                                                            {name.name_of_school}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-lg-2">
                                            <div className="mb-4">
                                                {standardLoading &&
                                                    <div className="ms-5 ps-4 mt-4 pt-3 position-absolute  align-items-end">
                                                        <div className="spinner-border ms-5 ms-md-0" role="status" style={{ height: "23px", width: "23px" }} >
                                                            <span className="sr-only">Loading...</span>
                                                        </div>
                                                    </div>
                                                }
                                                <label className="form-label mb-0 mt-2">Standard</label>
                                                <select className="form-select form-select-lg mb-3 f16" name="standard" onChange={(e) => { handleStandardChange(e) }}>
                                                    <option value="">Select standard</option>
                                                    {standardList?.map((data: any, i: number) => {
                                                        return (
                                                            <option value={data.value} key={i}>{data.standard}</option>
                                                        )
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-lg-2">

                                            <div className="mb-4">
                                                {divisionLoading &&
                                                    <div className="ms-5 ps-4 mt-4 pt-3 position-absolute  align-items-end">
                                                        <div className="spinner-border ms-5 ms-md-0" role="status" style={{ height: "23px", width: "23px" }} >
                                                            <span className="sr-only">Loading...</span>
                                                        </div>
                                                    </div>
                                                }
                                                <label className="form-label mb-0 mt-2">Division</label>
                                                <select className="form-select form-select-lg mb-3 f16" name="division" onChange={(e) => { handleDivisionChange(e) }}>
                                                    <option value="">Select division</option>
                                                    {divisionList?.map((data: any, i: number) => {
                                                        return (
                                                            <option value={data.value} key={i}>{data.division}</option>
                                                        )
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-lg-2">

                                            <div className="mb-4 mt-md-1">
                                                {studentLoading &&
                                                    <div className="ms-5 ps-3 mt-4 pt-3 position-absolute  align-items-end">
                                                        <div className="spinner-border ms-5 ms-md-0" role="status" style={{ height: "23px", width: "23px" }} >
                                                            <span className="sr-only">Loading...</span>
                                                        </div>
                                                    </div>
                                                }
                                                <label className="form-label mb-2">Students</label>
                                                <MultiSelect className=""
                                                    options={studentList}
                                                    value={studentsSelected}
                                                    // onChange={setStudentsSelected}
                                                    onChange={(e: any) => onChangeMultiSelectStudents(e)}
                                                    labelledBy="Select"

                                                />
                                            </div>
                                            {/* } */}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-1 col-md-1 pt-5 pt-md-0 mt-5 mt-md-0">
                                    <div className="mt-md-4 text-center d-none d-md-block">
                                        <button className="btn btn-primary mt-lg-2 mt-md-5" type="button" onClick={addForms}>+</button>
                                    </div>
                                </div>
                            </div>

                            {sponsorInputNames.length > 0 && <div className='table-responsive mb-4 mt-1 '>
                                <table className='table fixed-table-header mb-0'>
                                    <thead>
                                        <tr >
                                            <th style={{ width: "50px" }}>S.No</th>
                                            <th style={{ width: '150px' }}>Organization Name</th>
                                            <th style={{ width: '150px' }}> School Name</th>
                                            <th style={{ width: '90px' }}> Standard</th>
                                            <th style={{ width: '90px' }}> Division</th>
                                            <th style={{ width: '90px' }}> Students</th>
                                            <th style={{ width: '100px' }}> Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {sponsorInputNames?.map((data: any, i: number) => {
                                            return (
                                                <tr key={i}>
                                                    <td>{i + 1}</td>
                                                    <td> {data.institute_code}</td>
                                                    <td> {data.institute_school_code}</td>
                                                    <td> {data.standard}</td>
                                                    <td> {data.division}</td>
                                                    <td>{data.students.length > 0 && <span><img className='eye-icons me-3 cursor-pointer' src={VIEWICONS} onClick={() => getStudentsDetails(data)} />{data.students.length}</span>}</td>
                                                    <td> <i className="bi bi-trash cursor-pointer f20 ms-2" onClick={() => onDeleteForm(i)}></i> </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>}
                        </div>
                    }
                    {sponsorId !== "0" && <div className='table-responsive  mb-4 mt-1'>
                        <table className='table  fixed-table-header mb-0'>
                            <thead>
                                <tr>
                                    <th style={{ width: "50px" }}>S.No</th>
                                    <th style={{ width: '150px' }}>Institute Name</th>
                                    <th style={{ width: '150px' }}> School Name</th>
                                    <th style={{ width: '90px' }}> Students</th>
                                    {/* <th style={{ width: '100px' }}> Subscription Price</th>
                                    <th style={{ width: '100px' }}>Total Price</th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {currentSponsorData?.students?.map((data: any, i: number) => {
                                    return (
                                        <tr key={i}>
                                            <td>{i + 1}</td>
                                            <td> {data._organization.name_of_organization}</td>
                                            <td> {data._school.name_of_school}</td>
                                            <td>{data.students.length > 0 && <span><img className='eye-icons me-3 cursor-pointer' src={VIEWICONS} onClick={() => getStudentsDetails(data)} />{data.total_students}</span>}</td>
                                            {/* <td>{data.subscription_price} </td>
                                            <td>{data.school_payment_amount}</td> */}
                                        </tr>
                                    )
                                })}

                            </tbody>
                        </table>
                    </div>}
                    <div className="text-center ">
                        {sponsorId === "0" && <button className="btn btn-primary " onClick={handleSubmit}>Submit</button>}
                        {sponsorId !== "0" &&
                            < button className="btn btn-primary " onClick={handleUpdate}>Update</button>
                        }
                        <Link to='/dashboard/sponsor'><span className="ms-3">Cancel</span></Link>
                    </div>
                </div>
            </div>}
        </div>
    )
}
export default SponsorForm;
