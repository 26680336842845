import React, { useEffect, useRef, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { SchoolListItem } from '../../../app/model/school/school-list';
import { LOOK_UP_DATA } from '../../../app/service/lookup.service';
import { DataTable } from '../../../components/data-table'
import FormBuilder from '../../../components/form-builder';
import { SchoolDataGridCols } from './data-grid-cols';
import { toast } from 'react-toastify';
import { GET_SCHOOL_CAS_REPORTS, GET_SCHOOL_CAS_REPORT_FILTER } from '../../../app/service/cas-report.service';

export const SchooolReportList = () => {
    const [schoolData, setSchoolData] = useState<SchoolListItem[]>([]);
    const [loading, setLoading] = useState(false);
    const [pageArray, setPageNumbers] = useState([]);
    const [activePage, setActivePage] = useState<any>();
    const [statesData, setStatesData] = useState<any>([]);
    const [searchData, setSearchData] = useState<any>({});
    const schoolForm = useRef<any>({});
    const [userRole, setUserRole] = useState<any>();
    const [preparedSearchData, setPreparedSearchData] = useState<any>({});
    const [districtData, setDistrictData] = useState<any>([]);
    const filteredData = [
        { name: 'UDISE Number', value: 'udise_code' },
        { name: 'Email ID', value: 'email_id' },
        { name: 'Contact Number', value: 'contact_number' },
        { name: 'Category', value: 'category' },
        { name: 'Curriculum', value: 'curriculum' },
        { name: 'Date of Registration', value: 'date_of_registration' },
        { name: 'Taluka', value: 'taluka' },
        { name: 'Pan Number', value: 'pan_number' },
        { name: 'Address', value: 'address' },
        { name: 'Website Url', value: 'website_url' },
        { name: 'Pincode', value: 'pin_code' },
    ];
    const history = useHistory();
    const search = useLocation().search;
    const currentPageNumber: any = new URLSearchParams(search).get('page');

    useEffect(() => {
        LOOK_UP_DATA().then((res: any) => {
            if (res) {
                setStatesData(res.states);
            }
        });
        getSchools();
        setUserRole(userRole);

    }, []);


    function getSchools() {
        setLoading(true);
        getSchoolReport();
    }

    const getSchoolReport = () => {
        const activePageNumber = currentPageNumber > 0 ? (currentPageNumber - 1) : 0;
        GET_SCHOOL_CAS_REPORTS(activePageNumber, {}).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    const data = res.rows;
                    if (data.length > 0) {
                        setActivePage(currentPageNumber ? currentPageNumber : 1);
                        data.forEach((element: any) => {
                            element.school_name = element.name_of_school;
                            delete element['name_of_school'];
                        });
                        setSchoolData(data);
                        const pageNumbers = Math.ceil(res.count / 10);
                        const pageArray: any = [];
                        for (let i = 1; i <= pageNumbers; i++) {
                            pageArray.push(i);
                        }
                        setPageNumbers(pageArray);
                        setLoading(false);
                    }

                }
            }
        });
    }

    const onSearchText = (data: any) => {
        if (data.value.search) {
            history.push(`/dashboard/school?search=${data.value.search}`);
        }
    };

    const onPageChange = (data: any) => {
        if (data) {
            history.push(`/dashboard/cas-report/school/list?page=${data}`);
        }
        setActivePage(data);
        const pageNumber = data - 1;
        setLoading(true);
        GET_SCHOOL_CAS_REPORTS(pageNumber, preparedSearchData ? preparedSearchData : {}).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    const data = res.rows;
                    if (data.length > 0) {
                        data.forEach((element: any) => {
                            element.school_name = element.name_of_school;
                            delete element['name_of_school'];
                        });
                        setSchoolData(data);
                        const pageNumbers = Math.ceil(res.count / 10);
                        const pageArray: any = [];
                        for (let i = 1; i <= pageNumbers; i++) {
                            pageArray.push(i);
                        }
                        setPageNumbers(pageArray);
                    }
                    setLoading(false);
                }
            }
        });

    }

    const handleStateChange = (e: any) => {
        if (e.target.value) {
            const preparedData = statesData.filter((el: any) => el.state === e.target.value);
            // setCityData(preparedData[0].cities);
            setDistrictData(preparedData[0].districts);
        }
    }

    const handleDistrictChange = (e: any) => {
    }

    const handleInput = (data: any) => {
        setSearchData(data);
    };

    function handleSearch() {
        const data = { ...searchData.value };
        data.search_name = data?.search_name.trim();
        if (data.searchFilter === 'udise_code') {
            data.udise_code = data.search_name;
        } else if (data.searchFilter === 'contact_number') {
            data.contact_number = data.search_name;
        } else if (data.searchFilter === 'category') {
            data.category = data.search_name;
        } else if (data.searchFilter === 'taluka') {
            data.taluka = data.search_name;
        } else if (data.searchFilter === 'curriculum') {
            data.curriculum = data.search_name;
        } else if (data.searchFilter === 'email_id') {
            data.email_id = data.search_name;
        } else if (data.searchFilter === 'date_of_registration') {
            const date_of_registration = data.search_name;
            if (data.search_name) {
                const preparedDate = date_of_registration.split('-');
                const date = preparedDate[0];
                const month = preparedDate[1];
                const year = preparedDate[2];
                data.date_of_registration = year + '-' + month + '-' + date + 'T00:00:00Z';
            }
            // data.date_of_registration = data.search_name;
        } else if (data.searchFilter === 'pan_number') {
            data.pan_number = data.search_name;
        } else if (data.searchFilter === 'address') {
            data.address = data.search_name;
        } else if (data.searchFilter === 'website_url') {
            data.website_url = data.search_name;
        }
        else if (data.searchFilter === 'pin_code') {
            data.pin_code = data.search_name;
        }
        delete data.search_name;
        delete data.searchFilter;

        if (data) {
            setLoading(true);
            for (const key of Object.keys(data)) {
                if (data[key] === "") {
                    delete data[key];
                }
            }
            setPreparedSearchData(data);
            GET_SCHOOL_CAS_REPORT_FILTER(data).then((res: any) => {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    history.push(`/dashboard/cas-report/school/list?page=${1}`);
                    setActivePage(1);
                    const data = res.rows;
                    data.forEach((element: any) => {
                        element.school_name = element.name_of_school;
                        delete element['name_of_school'];
                    });
                    setSchoolData(data);
                    const pageNumbers = Math.ceil(res.count / 10);
                    const pageArray: any = [];
                    for (let i = 1; i <= pageNumbers; i++) {
                        pageArray.push(i);
                    }
                    setPageNumbers(pageArray);
                    setLoading(false);
                }
            });
        }
    }

    function handleRefresh() {
        const data = searchData
        data.value = {};
        setSearchData({ ...data });
        setPreparedSearchData({});
        getSchools();
        schoolForm.current.reset();
    }

    const handleFilterChange = (e: any) => {
        const data = { ...searchData.value };
        setSearchData(data);
    }

    return (
        <div>
            <div className="border-top border-primary py-3">
                <div className="row mb-2">
                    <div className="col-md-10">
                        <h2>CAS Reports</h2>
                    </div>
                </div>
                <div className="row" style={{ marginBottom: '15px' }}>
                    <div className="col-md-10">
                        <FormBuilder onUpdate={handleInput}>
                            <form ref={schoolForm}>
                                <div className="row">
                                    <div className="col-md-3" style={{ display: 'flex' }}>
                                        <input type="text" id="name" className="form-control ft-14" placeholder="Search name" name="name_of_school" />
                                    </div>
                                    <div className="col-md-3" style={{ display: 'flex' }}>
                                        <select name="state" id="state" className="form-select ft-14" onChange={(e) => { handleStateChange(e) }}>
                                            <option value="">Select state</option>
                                            {statesData.map((name: any, i: number) => (
                                                <option key={i} value={name.state}>
                                                    {name.state}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="col-md-3" style={{ display: 'flex' }}>
                                        <select name="taluka" id="taluka" className="form-select ft-14" onChange={(e) => { handleDistrictChange(e) }}>
                                            <option value="">Select District</option>
                                            {districtData.map((name: any, i: number) => (
                                                <option key={i} value={name}>
                                                    {name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="col-md-3" style={{ display: 'flex' }}>
                                        {/* <input type="text" id="area" className="form-control ft-14" placeholder="Search location" name="area" /> */}
                                    </div>
                                    <div className="col-md-3 mt-2">
                                        <input type="text" className="form-control ft-14" placeholder="Custom search" name="search_name" />
                                    </div>
                                    <div className="col-md-3 mt-2">
                                        <select name="searchFilter" className="form-select ft-14" onChange={(e) => { handleFilterChange(e) }}>
                                            <option value="">Select filter</option>
                                            {filteredData.map((name: any, i: number) => (
                                                <option key={i} value={name.value}>
                                                    {name.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </form>
                        </FormBuilder>
                    </div>
                    <div className="col-md-2 mt-5">
                        <button className="btn btn-primary px-3 cursor-pointer" type="button" onClick={() => handleSearch()}>Search</button>&nbsp;
                        <button className="btn clear-btn cursor-pointer" type="button" onClick={() => handleRefresh()}>
                            Clear
                        </button>
                    </div>
                </div>
                {loading &&
                    <div className="text-center p-5">
                        <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                }
                {!loading && <DataTable TableCols={SchoolDataGridCols} tableData={schoolData} searchText={onSearchText}
                    pageNumber={onPageChange} activePageNumber={activePage} pageNumbers={pageArray}></DataTable>}
            </div>
        </div >
    )
}

