import React from "react"
import { Route, Switch } from "react-router-dom";
import { SchoolProfileInformation } from "./view";
import { SchoolProfileStudentPayments } from "./payment-list";

interface Props {
    match?: any;
    url?: any;
}
const SchoolProfileRoutes = (props: Props) => {
    let url: string | undefined = props.match?.url;
    if (url?.endsWith('/')) {
        url = url.substr(0, url.length - 1);
    }

    return (
        <Switch>
            <Route exact path={`${url}`}>
                <SchoolProfileInformation></SchoolProfileInformation>
            </Route>
            <Route path={`${url}/teacher/list/:schoolCode`} exact>
                <SchoolProfileInformation></SchoolProfileInformation>
            </Route>
            <Route path={`${url}/students/list/:id/:schoolCode`} exact>
                <SchoolProfileInformation></SchoolProfileInformation>
            </Route>
            <Route path={`${url}/student-count/list/:schoolCode`} exact>
                <SchoolProfileInformation></SchoolProfileInformation>
            </Route>
            <Route path={`${url}/module/list/:schoolCode`} exact>
                <SchoolProfileInformation></SchoolProfileInformation>
            </Route>
            <Route path={`${url}/payment/list/:schoolCode`} exact>
                <SchoolProfileInformation></SchoolProfileInformation>
            </Route>
            <Route path={`${url}/payment-list/:schoolCode/:moduleUuid/`} exact>
                <SchoolProfileStudentPayments></SchoolProfileStudentPayments>
            </Route>
            <Route path={`${url}/studentsform/:id/:schoolCode/:userId`} exact>
                <SchoolProfileInformation></SchoolProfileInformation>
            </Route>
            <Route path={`${url}/teachersform/:schoolCode/:userId`} exact>
                <SchoolProfileInformation></SchoolProfileInformation>
            </Route>
        </Switch>
    )
}
export default SchoolProfileRoutes;