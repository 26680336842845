import React from 'react';
import ModulesInfoRoutes from './routes';

const ModulesInfoPage = (props: any) => {
    
    return (
        <div>
            <ModulesInfoRoutes {...props} />
        </div>
    );
};

export default ModulesInfoPage;