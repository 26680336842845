import API from "../utility/axios";
const apiUrl = "/api/v1";
const newApiUrl = "/api/v2";

export const GET_STUDENT_REPORT = (data: any): Promise<any> => {
  return API.post(`${newApiUrl}/school/user/module/cas/report`, data);
};

export const GET_REPORT_BY_USER = (data: any): Promise<any> => {
  return API.post(`${newApiUrl}/school/user/module/cas/report`, data);
};
