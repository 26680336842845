import React, { useEffect, useRef, useState } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'ckeditor5-classic-with-mathtype';
import { MathJaxContext, MathJax } from 'better-react-mathjax';
import { Breadcum } from '../../../../../components/breadcum';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { GET_MLA_TWO_QUESTIONS, MLA_TWO_ABILITY_UPDATE } from '../../../../../app/service/mla-two.service';

const MLATwoEditWorksheet = () => {
    const [questionsData, setQuestionsData] = useState<any>([]);
    const [updateQuestionsData, setUpdateQuestionsData] = useState<any>([]);
    const [loadingWorksheetQuestions, setLoadingWorksheetQuestions] = useState(false);
    const [canShowCkeditor, setCanShowCkeditor] = useState(false);
    const [inputData, setInputData] = useState<any>();
    const [questionData, setQuestionData] = useState<any>();
    const [canShowCKeditorPopup, setCanShowCKeditorPopup] = useState(false);
    const [canShowCKeditorAddPopup, setCanShowCKeditorAddPopup] = useState(false);
    const [loadingCkeditor, setLoadingCkeditor] = useState(false);
    const [currentQuestionData, setCurrentQuestionData] = useState<any>([]);
    const [questionIndex, setQuestionIndex] = useState<any>(null);
    const [optionIndex, setOptionIndex] = useState<any>(null);
    const [correctAnswer, setCorrectAnswer] = useState<any>('');
    const [editor, setEditor] = useState<any>();
    const [questionNumber, setQuestionNumber] = useState<any>('');
    const [alphabets, setAlphabets] = useState<any>(["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z"]);
    let { id, code, worksheetNumber, ability_code } = useParams<{ id: string, code: string, worksheetNumber: string, ability_code: string }>();

    const history = useHistory();
    const listRef: any = useRef(null);

    const [breadcumInfo, setBreadcumInfo] = useState<any>([
        {
            label: 'Worksheets',
            value: 'Worksheets',
            routerLink: `/dashboard/module/info/MLA-2/${code}/${id}/worksheet`,
            isActive: false
        },
        {
            label: 'Module Name',
            value: ability_code.replace(/_/g, ' '),
            routerLink: `/dashboard/module/info/MLA-2/${code}/${id}/worksheet-numbers/${ability_code}`,
            isActive: false
        },
        {
            label: 'Update Worksheet',
            value: 'Update Worksheet',
            isActive: true
        }
    ]);

    useEffect(() => {
        setBreadcumInfo(breadcumInfo);
        getWorksheetQuestions(worksheetNumber);
    }, []);



    function getWorksheetQuestions(worksheet_no: any) {
        let payload = {
            "mode": 'WORKSHEETS',
            "test_no": '',
            "ability": ability_code ? ability_code : '',
            "worksheet_no": parseInt(worksheet_no)
        }
        setLoadingWorksheetQuestions(true);
        GET_MLA_TWO_QUESTIONS(payload).then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res.message);
                setLoadingWorksheetQuestions(false);
            } else {
                setLoadingWorksheetQuestions(false);
                if (res.questions && res.questions.length > 0) {
                    setUpdateQuestionsData(res.questions);
                } else {
                    setUpdateQuestionsData([]);
                }
            }
        })
    }

    function onViewQuestion(name: any) {
        return { __html: name };
    }

    function onChange(e: any, editor: any) {
        setInputData(editor.getData());
    };

    function onClickEdit(question: any, i: number) {
        setCanShowCKeditorPopup(true);
        setQuestionData(question.question);
        const data = currentQuestionData;
        data.push(question);
        setCurrentQuestionData(data);
        setLoadingCkeditor(true);
        setCanShowCkeditor(false);
        setTimeout(() => {
            setLoadingCkeditor(false);
        }, 1000);
    }

    function onEditOptions(question: any, i: number, option: any, j: number) {
        setQuestionIndex(i);
        setOptionIndex(j);
        setQuestionData(option.value);
        setCanShowCkeditor(true);
    }

    function getCorrectAnswer(option: any) {
        for (let i = 0; i < alphabets.length; i++) {
            if (option === alphabets[i]) {
                return alphabets[i - 1];
            }
        }
    }

    function onDeleteOptions(question: any, i: number, option: any, optionIndex: number) {
        setLoadingCkeditor(true);
        const data = currentQuestionData;
        for (let i = 0; i < data.length; i++) {
            for (let j = 0; j < data[i].options.length; j++) {
                if (option.options === data[i].correct_answer) {
                    data[i].correct_answer = "";
                } else {
                    if (data[i].correct_answer === data[i].options[j].options) {

                        if (j > optionIndex) {
                            data[i].correct_answer = getCorrectAnswer(data[i].options[j].options);
                        } else {
                            data[i].correct_answer = data[i].correct_answer;
                        }
                    }
                }
            }
        }
        data[0].options.splice(optionIndex, 1);

        for (let i = 0; i < data[0].options.length; i++) {
            for (let j = 0; j < alphabets.length; j++) {
                if (i === j) {
                    data[0].options[i].options = alphabets[j];
                    data[0].options[i].value = data[0].options[i].value;
                }
            }
        }

        setTimeout(() => {
            setCurrentQuestionData(data);
            setLoadingCkeditor(false);
            setCanShowCkeditor(false);
        }, 1000);

    }

    function onEditQuestion(question: any, i: number) {
        setQuestionData(question.question);
        setQuestionIndex(i);
        setOptionIndex(null);
        setCanShowCkeditor(true);
    }

    const handleClose = () => {
        setCanShowCKeditorPopup(false);
        getWorksheetQuestions(worksheetNumber);
        setCurrentQuestionData([]);
        setQuestionIndex(null);
        setOptionIndex(null);
    }

    function getOptionLists(item: any, optionData: any) {
        const answerOptions = item;
        answerOptions.push({
            options: '',
            value: ''
        });
        for (let i = 0; i < answerOptions.length; i++) {
            for (let j = 0; j < alphabets.length; j++) {
                if (i === j && (answerOptions[i].value === '' || answerOptions[i].options === '')) {
                    answerOptions[i].options = alphabets[j];
                    answerOptions[i].value = optionData;
                }
            }
        }

        return answerOptions;
    }

    function onSave() {
        setLoadingCkeditor(true);
        const data = currentQuestionData;
        if (questionIndex !== null && optionIndex !== null) {
            data[questionIndex].options[optionIndex].value = inputData;
            setTimeout(() => {
                setCurrentQuestionData(data);
                setLoadingCkeditor(false);
                setCanShowCkeditor(false);
            }, 1000);
        } else if (questionIndex !== null) {
            data[0].question = inputData;
            setTimeout(() => {
                setCurrentQuestionData(data);
                setLoadingCkeditor(false);
                setCanShowCkeditor(false);
            }, 1000);
        } else {
            data[0].options = getOptionLists(data[0].options, inputData);
            setTimeout(() => {
                setCurrentQuestionData(data);
                setLoadingCkeditor(false);
                setCanShowCkeditor(false);
            }, 1000);
        }
        setQuestionData(inputData);
    }

    function handleQuestionOptions(item: any, index: number) {
        const data = currentQuestionData;
        for (let i = 0; i < alphabets.length; i++) {
            if (index === i) {
                data[0].correct_answer = alphabets[i];
            }
        }
        setCurrentQuestionData(data);
    }

    function getUpdateQuestionsData() {
        const result = Object.values(
            [].concat(updateQuestionsData, currentQuestionData)
                .reduce((r: any, c: any) => (r[c.question_number] = Object.assign((r[c.question_number] || {}), c), r), {})
        );
        return result;
    }

    function onAddAnswerOptions() {
        setCanShowCkeditor(true);
        setQuestionData('');
        setOptionIndex(null);
        setQuestionIndex(null);
    }

    function onUpdate() {
        const payload = {
            "mode": "WORKSHEETS",
            "status": 'UPDATE',
            "ability_code": ability_code,
            "url": '',
            "worksheet_no": worksheetNumber,
            data: getUpdateQuestionsData()
        }

        updateAbilities(payload, 'UPDATE');
    }

    function updateAbilities(payload: any, type: any) {

        MLA_TWO_ABILITY_UPDATE(payload).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                } else {
                    if (type === 'ADD') {
                        setCanShowCKeditorAddPopup(false);
                    } else {
                        setCanShowCKeditorPopup(false);
                        setCurrentQuestionData([]);
                        setQuestionIndex(null);
                        setOptionIndex(null);
                    }
                    getWorksheetQuestions(worksheetNumber);

                }
            }
        });
    }

    function capitalizeFirstLetter(string: any) {
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    }

    function onAddQuestion() {
        setLoadingCkeditor(false);
        if (updateQuestionsData.length > 0) {
            setQuestionNumber(updateQuestionsData.length + 1);
        } else {
            setQuestionNumber("1");
        }
        setQuestionsData([]);
        setCanShowCKeditorAddPopup(true);
        setCanShowCkeditor(false);
        getStatusSaveBtn();
        setTimeout(() => {
            setLoadingCkeditor(false);
        }, 2000);
    }

    function getOptions(index: number) {
        for (let i = 0; i < alphabets.length; i++) {
            if (index === i) {
                return alphabets[i];
            }
        }
    }

    function createMarkup(name: any) {
        return { __html: name };
    }

    function handleAddQuestionOptions(item: any, index: number, e: any) {
        for (let i = 0; i < alphabets.length; i++) {
            if ((index - 1) === i) {
                setCorrectAnswer(alphabets[i]);
            }
        }
    }

    function getStatusSaveBtn() {
        if (questionsData.length > 1) {
            return false;
        } else {
            return true;
        }
    }

    function onSaveAddQuestion() {
        const payload = {
            "mode": "WORKSHEETS",
            "status": 'UPDATE',
            "ability_code": ability_code,
            "url": '',
            "worksheet_no": parseInt(worksheetNumber),
            data: getQuestionsData()
        }

        updateAbilities(payload, 'ADD');

    }

    function getCkeditorValue(type: any) {
        if (type === 'question') {
            if (questionsData.length > 0) {
                return questionsData[0];
            }
        } else {
            const preparedOptions = [];
            const data = questionsData.slice(1);
            for (let i = 0; i < data.length; i++) {
                preparedOptions.push({
                    options: getOptions(i),
                    value: data[i]
                })
            }
            return preparedOptions;
        }
    }

    function getQuestionsData() {
        const data = [];
        if (updateQuestionsData.length > 0) {
            updateQuestionsData.push({
                id: "",
                question_number: "",
                question: "",
                question_type: 'radio_button',
                options: [],
                correct_answer: ''
            });

            for (let i = 0; i < updateQuestionsData.length; i++) {
                data.push({
                    id: i + 1,
                    question_number: i + 1,
                    question: (i === (updateQuestionsData.length - 1) ? getCkeditorValue('question') : updateQuestionsData[i].question),
                    question_type: 'radio_button',
                    options: (i === (updateQuestionsData.length - 1) ? getCkeditorValue('options') : updateQuestionsData[i].options),
                    correct_answer: (i === (updateQuestionsData.length - 1) ? (correctAnswer ? correctAnswer : '') : updateQuestionsData[i].correct_answer)
                })
            }

            return data;
        } else {
            const payload = {
                id: questionNumber,
                question_number: questionNumber,
                question: getCkeditorValue('question'),
                question_type: 'radio_button',
                options: getCkeditorValue('options'),
                correct_answer: correctAnswer ? correctAnswer : ''
            }
            data.push(payload);
            return data;
        }
    }

    const handleAddClose = () => {
        setCanShowCKeditorAddPopup(false);
        getWorksheetQuestions(worksheetNumber);
        setCurrentQuestionData([]);
        setQuestionIndex(null);
        setOptionIndex(null);
    }

    function onAddSave() {
        setCanShowCkeditor(true);
        if (editor.getData()) {
            questionsData.push(inputData);
        }
    }

    function addAnswerOptions() {
        setCanShowCkeditor(false);
        getStatusSaveBtn();
    }

    return (
        <div>
            <div className={`modal fade parent-modal-fade ${canShowCKeditorPopup ? 'show' : 'false'}`} style={{ display: (canShowCKeditorPopup ? "block" : 'none') }} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog worksheet-quesions-dialog" role="document">
                    <div className="modal-content worksheet-questions-model">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Update Question: {capitalizeFirstLetter(ability_code.replace(/_/g, ' '))}</h5>
                            <button type="button" className="close popup-close cursor-pointer" data-dismiss="modal" aria-label="Close" onClick={handleClose}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body" style={{ overflowX: 'hidden' }}>
                            {loadingCkeditor &&
                                <div className="text-center p-5">
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div>
                            }
                            {!loadingCkeditor && <div>
                                {/* <MathJaxContext>
                                    <MathJax> */}
                                <div className='cdk-question-view'>
                                    {currentQuestionData.map((question: any, i: number) => (
                                        <div className="mt-1" key={i}>
                                            <div className='row'>
                                                <div className='row'>
                                                    <div className='col-md-1' style={{ alignSelf: 'center' }}>
                                                        <span className='fw-bold' style={{ fontSize: '22px' }}>Q{question.question_number}:</span>
                                                    </div>
                                                    <div className='col-md-7 mb-3' ref={listRef} style={{ paddingTop: '25px' }}>
                                                        <span className='fw-bold' style={{ fontSize: '22px' }}>
                                                            <MathJaxContext>
                                                                <MathJax>
                                                                    <div dangerouslySetInnerHTML={onViewQuestion(question.question)}></div>
                                                                </MathJax>
                                                            </MathJaxContext>
                                                        </span>
                                                    </div>
                                                    <div className='col-md-2 p-0' style={{ alignSelf: 'center' }}>
                                                        <i className="bi bi-pencil-square cursor-pointer edit-icon p-2" onClick={() => onEditQuestion(question, i)}></i>
                                                    </div>
                                                </div>
                                                <div className='row pt-5'>
                                                    <div className='col-md-9'>
                                                        {question.options.map((options: any, j: number) => (
                                                            <div className='row' key={j}>
                                                                <div className='col-md-1'>
                                                                    <span>{options.options}.</span>
                                                                </div>
                                                                <div className='col-md-9'>
                                                                    <div className='row' style={{ position: 'relative' }}>
                                                                        <div className='col-md-8'>
                                                                            <MathJaxContext>
                                                                                <MathJax>
                                                                                    <span dangerouslySetInnerHTML={onViewQuestion(options.value)}></span>
                                                                                </MathJax>
                                                                            </MathJaxContext>
                                                                        </div>
                                                                        <div className='col-md-4'>
                                                                            {j === 0 && <div style={{ position: 'absolute', top: '-30px', left: '390px' }}>Choose Correct Answer</div>}
                                                                            <input type='radio' name="option" defaultChecked={question.correct_answer === options.options} onChange={(e) => { handleQuestionOptions(options, j) }} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='col-md-2'>
                                                                    <i className="bi bi-pencil-square cursor-pointer edit-icon p-2" onClick={() => onEditOptions(question, i, options, j)}></i>
                                                                    <i className="bi bi-trash cursor-pointer edit-icon p-2" onClick={() => onDeleteOptions(question, i, options, j)}></i>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                    <div className='col-md-3 mb-2'>
                                                        <button className='btn btn-primary' onClick={() => onAddAnswerOptions()}>Add Answer Options</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                {/* </MathJax>
                                </MathJaxContext> */}
                                {canShowCkeditor && <div className='mt-3'>
                                    <MathJaxContext>
                                        <CKEditor
                                            editor={ClassicEditor}
                                            id="editorId"
                                            data={questionData}
                                            config={{
                                                toolbar: {
                                                    items: [
                                                        'heading', 'MathType', 'ChemType',
                                                        '|',
                                                        'bold',
                                                        'italic',
                                                        'link',
                                                        'bulletedList',
                                                        'numberedList',
                                                        'imageUpload',
                                                        'mediaEmbed',
                                                        'insertTable',
                                                        'blockQuote',
                                                        'undo',
                                                        'redo'
                                                    ]
                                                },
                                                language: 'en',
                                            }}

                                            onChange={(event: any, editor: any) => {
                                                return onChange(event, editor);
                                            }}
                                            onReady={(editor: any) => {
                                                // You can store the "editor" and use when it is needed.
                                            }}
                                        />
                                        <MathJax>
                                        </MathJax>
                                    </MathJaxContext>
                                    <div className='mt-2'>
                                        <button className='btn btn-primary' onClick={() => onSave()}>Ok</button>&nbsp;
                                    </div>
                                </div>}
                            </div>}
                        </div>
                        <div className="modal-footer">
                            <div className='questions-save-btn'>
                                <button className='btn btn-primary' onClick={() => onUpdate()}>Update</button>
                            </div>
                            <button type="button" className="btn btn-secondary cursor-pointer" data-dismiss="modal" onClick={handleClose}>Close</button>&nbsp;
                        </div>
                    </div>
                </div>
            </div>
            <div className={`modal fade parent-modal-fade ${canShowCKeditorAddPopup ? 'show' : 'false'}`} style={{ display: (canShowCKeditorAddPopup ? "block" : 'none') }} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog worksheet-quesions-dialog" role="document">
                    <div className="modal-content worksheet-questions-model">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Add Question: {capitalizeFirstLetter(ability_code.replace(/_/g, ' '))} </h5>
                            <button type="button" className="close popup-close cursor-pointer" data-dismiss="modal" aria-label="Close" onClick={handleAddClose}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body" style={{ overflowX: 'hidden' }}>
                            {loadingCkeditor &&
                                <div className="text-center p-5">
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div>
                            }
                            {!loadingCkeditor && <div>
                                <div className='mt-2'>
                                    <h6>Question:{questionNumber}</h6>
                                </div>
                                {(questionsData && questionsData.length > 0) && <div className='mt-2'>
                                    <div className='cdk-question-view'>
                                        {questionsData && <div className='row' style={{ position: 'relative' }}>
                                            {questionsData.map((name: any, i: number) => (
                                                <div key={i} className=' col-md-8 row' style={{
                                                    marginTop: i !== 0 ? '15px' : '2px',
                                                    marginBottom: i !== 0 ? '5px' : '25px',
                                                    position: i !== 0 ? 'relative' : 'static'
                                                }}>
                                                    {i !== 0 && <div className='col-md-1'>
                                                        <span>{getOptions(i - 1)}.</span>
                                                    </div>}
                                                    <div className={i !== 0 ? 'col-md-4' : 'col-md-11'}>
                                                        <MathJaxContext>
                                                            <MathJax>
                                                                <div style={{ fontSize: i !== 0 ? '14px' : '25px' }} dangerouslySetInnerHTML={createMarkup(name)}>
                                                                </div>
                                                            </MathJax>
                                                        </MathJaxContext>
                                                    </div>

                                                    {(i !== 0 && questionsData.length >= 3) && <div className='col-md-4'>
                                                        {(i !== 0 && i === 1) && <div className='mt-2' style={{ position: 'absolute', top: '-40px', left: '240px' }}>
                                                            <div>Choose Correct Answer</div>
                                                        </div>}
                                                        <div className='col-md-1'>
                                                            <input type='radio' name="option" onChange={(e) => { handleAddQuestionOptions(name, i, e) }} />
                                                        </div>
                                                    </div>}
                                                </div>
                                            ))}
                                            <div className='col-md-3 mb-2'>
                                                <button className='btn btn-primary' onClick={() => addAnswerOptions()}>Add Answer Options</button>
                                            </div>
                                        </div>}
                                    </div>
                                </div>}
                                {!canShowCkeditor && <div className='mt-3'>
                                    <MathJaxContext>
                                        <CKEditor
                                            editor={ClassicEditor}
                                            id="editorId"
                                            data=''
                                            config={{
                                                toolbar: {
                                                    items: [
                                                        'heading', 'MathType', 'ChemType',
                                                        '|',
                                                        'bold',
                                                        'italic',
                                                        'link',
                                                        'bulletedList',
                                                        'numberedList',
                                                        'imageUpload',
                                                        'mediaEmbed',
                                                        'insertTable',
                                                        'blockQuote',
                                                        'undo',
                                                        'redo'
                                                    ]
                                                },
                                                language: 'en',
                                            }}

                                            onChange={(event: any, editor: any) => {
                                                return onChange(event, editor);
                                            }}
                                            onReady={(editor: any) => {
                                                // You can store the "editor" and use when it is needed.
                                                setEditor(editor);
                                            }}
                                        />
                                        <MathJax>
                                        </MathJax>
                                    </MathJaxContext>
                                    <div className='mt-2'>
                                        <button className='btn btn-primary' disabled={editor && editor.getData() ? false : true} onClick={() => onAddSave()}>Ok</button>&nbsp;
                                    </div>
                                </div>}
                            </div>}
                        </div>
                        <div className="modal-footer">
                            <div className='questions-save-btn'>
                                <button className='btn btn-primary' disabled={getStatusSaveBtn()} onClick={() => onSaveAddQuestion()}>Save</button>
                            </div>
                            <button type="button" className="btn btn-secondary cursor-pointer" data-dismiss="modal" onClick={handleAddClose}>Close</button>&nbsp;
                        </div>
                    </div>
                </div>
            </div>
            <div className='mt-2'>
                <Breadcum breadcumList={breadcumInfo} />
            </div>
            {
                loadingWorksheetQuestions &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {
                !loadingWorksheetQuestions && <div>
                    <div className='row'>
                        <div className='col-md-4 pt-2'>
                        </div>
                        <div className='col-md-6'>
                            <h3>Worksheet Number - {worksheetNumber}</h3>
                        </div>
                        <div className='col-md-2 text-end'>
                            <button className='btn btn-primary' onClick={() => onAddQuestion()}>Add Question</button>
                        </div>
                    </div>
                    <div>
                        <MathJaxContext>
                            <MathJax>
                                {updateQuestionsData.map((question: any, i: number) => (
                                    <div className="mt-4" key={i}>
                                        <div className='row'>
                                            <div className='col-md-11'>
                                                <div className='row'>
                                                    <div className='col-md-1' style={{ verticalAlign: 'middle', margin: 'auto' }}>
                                                        <span className='fw-bold' style={{ fontSize: '22px' }}>Q{question.question_number}:</span>
                                                    </div>
                                                    <div className='col-md-11' style={{ paddingTop: '15px' }}>
                                                        <span className='fw-bold' style={{ fontSize: '22px' }}>
                                                            {/* <MathJaxContext>
                                                        <MathJax> */}
                                                            <div dangerouslySetInnerHTML={onViewQuestion(question.question)}></div>
                                                            {/* </MathJax>
                                                    </MathJaxContext> */}
                                                        </span>
                                                    </div>
                                                </div>

                                                {question.options.map((options: any, j: number) => (
                                                    <div className='row' key={j}>
                                                        <div className='col-md-1'>
                                                            <span>{options.options}.</span>
                                                        </div>
                                                        <div className='col-md-6'>
                                                            {/* <MathJaxContext>
                                                        <MathJax> */}
                                                            <span dangerouslySetInnerHTML={onViewQuestion(options.value)}></span>
                                                            {/* </MathJax>
                                                    </MathJaxContext> */}
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                            <div className='col-md-1' style={{ paddingTop: '15px' }}>
                                                <i className="bi bi-pencil-square cursor-pointer edit-icon p-2" onClick={() => onClickEdit(question, i)}></i>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </MathJax>
                        </MathJaxContext>
                    </div>
                </div>
            }
        </div >
    );
};

export default MLATwoEditWorksheet;
