import React, { useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify';
import { ABILITY_UPDATE, GET_MLA_DETAILS, GET_MLA_ONE_WORKSHEETS, GET_MLA_QUESTIONS } from '../../../../app/service/mla.service';
import { UPLOAD_LOGO } from '../../../../app/service/school.service';
import FormBuilder from '../../../../components/form-builder';
import { FormControlError, FormField, FormValidators } from '../../../../components/form-builder/model/form-field';
import { FormValidator, GetControlIsValid } from '../../../../components/form-builder/validations';
import appConfig from '../../../../config/constant';

export const MLAOneWorksheet = () => {
    const [loading, setLoading] = useState(false);
    const [loadingWorksheet, setLoadingWorksheet] = useState(false);
    const [loadingWorksheetData, setLoadingWorksheetData] = useState(false);
    const [loadingWorksheetQuestions, setLoadingWorksheetQuestions] = useState(false);
    const [abilityList, setAbilityList] = useState<any>([]);
    const [abilityRecords, setAbilityRecords] = useState<any>([]);
    const [canShowWorksheetsPopup, setCanShowConfirmQuestionsPopup] = useState(false);
    const [canShowQuestionsPopup, setCanShowQuestionsPopup] = useState(false);
    const [canShowWorksheetActionsPopup, setCanShowWorksheetActionsPopup] = useState(false);
    const worksheetForm = useRef<any>({});
    const [worksheetData, setWorksheetData] = useState<any>({});
    const [validationErrors, setValidationErrors] = useState<FormControlError[]>([]);
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [activePage, setActivePage] = useState(1);
    const [pageNumbers, setPageNumbers] = useState([]);
    const [excelFileName, setExcelFileName] = useState<any>();
    const [worksheetUrl, setWorksheetUrl] = useState<any>();
    const [abilityInfo, setAbilityInfo] = useState<any>({});
    const [actionType, setActionType] = useState<any>('');
    const [worksheetNumbers, setWorksheetNumbers] = useState([]);
    const [worksheetError, setWorksheetError] = useState('');
    const [fileError, setFileError] = useState('');
    const [worksheetNumber, setWorksheetNumber] = useState('');
    const [questionsData, setQuestionsData] = useState<any[]>([]);

    const [worksheetsView, setWorksheetsView] = useState(false);

    // const formValidations = [
    //     new FormField('worksheet_no', [FormValidators.REQUIRED]),
    //     new FormField('file', [FormValidators.REQUIRED]),
    // ];

    useEffect(() => {
        getAbilityList();

        const accessObjectsData: any = localStorage.getItem("access_objects_data");
        onHandleAction(JSON.parse(accessObjectsData));
    }, []);

    function getAbilityList() {
        setLoading(true);
        GET_MLA_DETAILS().then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    setLoading(false);
                    setAbilityList(res.config.abilities);
                    setAbilityRecords(res.config.abilities.slice(0, 10));
                    const pageNumbers = Math.ceil(res.config.abilities.length / 10);
                    const pageArray: any = [];
                    for (let i = 1; i <= pageNumbers; i++) {
                        pageArray.push(i);
                    }
                    setPageNumbers(pageArray);
                    setActivePage(1);
                }
            }
        });
    }

    const handleInput = (data: any) => {
        const newData = { ...worksheetData, ...data.value };
        setWorksheetData(newData);
    }

    function onClickActionType(item: any, type: any) {
        setCanShowConfirmQuestionsPopup(true);
        setAbilityInfo(item);
        getWorksheets(item.code);

    }

    function getWorksheets(code: any) {
        setLoadingWorksheet(true);
        GET_MLA_ONE_WORKSHEETS(code).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoadingWorksheet(false);
                } else {
                    setLoadingWorksheet(false);
                    setWorksheetNumbers(res.worksheets);
                }
            }
        });
    }

    function getActionType() {
        if (actionType === 'ADD') {
            return 'Add Worksheet';
        } else if (actionType === 'UPDATE') {
            return `Do you want to Update Worksheet Number ${worksheetNumber}`;
        } else if (actionType === 'DELETE') {
            return `Are you sure want to delete  Worksheet Number ${worksheetNumber}`;
        }
    }

    const handleConfirmQuestionsClose = () => {
        setCanShowConfirmQuestionsPopup(false);
        getAbilityList();

    }

    const handleWorksheetTypeClose = () => {
        setCanShowWorksheetActionsPopup(false);
        setExcelFileName("");
        setWorksheetUrl('');
        setWorksheetError('');
        setFileError('');
    }

    const handleAbility = (type: any) => {
        const data = worksheetData || {};
        const payload = {
            "mode": "WORKSHEETS",
            "status": type,
            "ability_code": abilityInfo.code,
            "url": type === 'DELETE' ? '' : (worksheetUrl ? worksheetUrl : ''),
            // "worksheet_no": data.worksheet_no ? data.worksheet_no : ''
            "worksheet_no": worksheetNumber ? worksheetNumber : ''
        }
        if (type === 'ADD') {
            if (payload.url) {
                updateAbilities(payload);
            } else {
                if (!data.file) {
                    setFileError('Please select file');
                }
            }
        } else if (type === 'UPDATE') {
            if (payload.url && payload.worksheet_no) {
                updateAbilities(payload);
            } else {
                if (!data.file) {
                    setFileError('Please select file');
                }

            }
        } else if (type === 'DELETE') {
            if (payload.worksheet_no) {
                updateAbilities(payload);
            } else {
                if (!data.file) {
                    setFileError('Please select file');
                }
            }
        }
    }

    function updateAbilities(payload: any) {
        setLoadingWorksheet(true);
        ABILITY_UPDATE(payload).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoadingWorksheet(false);
                } else {
                    setExcelFileName("");
                    setWorksheetUrl('');
                    setLoadingWorksheet(false);
                    setCanShowWorksheetActionsPopup(false);
                    getWorksheets(payload.ability_code);
                }
            }
        });
    }

    function onClickPage(pageNumber: number) {
        setActivePage(pageNumber);
        handlePagination(pageNumber);
    }

    function onClickNextPage() {
        setActivePage(activePage + 1);
        handlePagination(activePage + 1);
    }

    function onClickPreviousPage() {
        setActivePage(activePage - 1);
        handlePagination(activePage - 1);
    }

    function handlePagination(pageNumber: any) {
        const itemsPerPage = (pageNumber - 1) * 10;
        const perPageCount = abilityList.slice(itemsPerPage, pageNumber * 10);
        setAbilityRecords(perPageCount);
    }

    function handleBrowseFile(e: any) {
        if (e.target.files && e.target.files[0]) {
            setExcelFileName(e.target.files[0].name);
            setFileError('');
            let formData = new FormData();
            formData.append('file', e.target.files[0], e.target.files[0].name);
            uploadWorksheet(formData);
        }
    }

    function uploadWorksheet(formdata: any) {
        UPLOAD_LOGO(formdata).then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res.message);
            } else {
                setWorksheetUrl(res.url);
            }
        });
    }

    const onWorkSheetClick = (item: any) => {
        setCanShowQuestionsPopup(true);
        setCanShowConfirmQuestionsPopup(false);
        getWorksheetQuestions(item.worksheet_no);
    }


    const handleQuestionsClose = () => {
        setCanShowQuestionsPopup(false);
    }

    function getWorksheetQuestions(worksheet_no: number) {
        let payload = {
            "mode": 'WORKSHEETS',
            "test_no": '',
            "ability": abilityInfo.code ? abilityInfo?.code : '',
            "worksheet_no": worksheet_no
        }
        setLoadingWorksheetQuestions(true);
        GET_MLA_QUESTIONS(payload).then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res.message);
                setLoadingWorksheetQuestions(false);
            } else {
                setLoadingWorksheetQuestions(false);
                setQuestionsData(res.questions);
            }
        })
    }

    function onChangeActionType(item: any, type: any) {
        setCanShowWorksheetActionsPopup(true);
        setActionType(type);
        setWorksheetNumber(item.worksheet_no);
        if (type) {
            if (type !== 'ADD') {
                setWorksheetData({});
                setFileError('');
                setWorksheetError('');
            } else {
                setLoadingWorksheet(true);
                setTimeout(() => {
                    setWorksheetData({});
                    setFileError('');
                    setWorksheetError('');
                    setLoadingWorksheet(false);
                }, 1000);
            }
        }
    }

    function handleWorksheetDownload() {
        const url = `${appConfig.FILE_UPLOAD_URL}templates/${"ability_worksheets_upload.xlsx"}`;
        setLoading(true);
        setTimeout(() => {
            toast.success('Successfully Downloaded');
            window.location.href = url;
            setLoading(false);
        }, 1000);
    }

    function onHandleAction(accessObjectsList: any) {
        const preparedData = accessObjectsList?.filter((el: any) => el.object_code === "MODULES");
        if (preparedData?.length > 0) {
            for (let i = 0; i < preparedData[0].child.length; i++) {
                if (preparedData[0].child[i].object_code === "MODULES-MLA-1-WORKSHEETS-VIEW" && preparedData[0].child[i].p_read === 1) {

                    setWorksheetsView(true);
                }


            }
        }
    }

    return (

        <div className="row py-3">
            <div className={`modal fade parent-modal-fade ${canShowWorksheetsPopup ? 'show' : 'false'}`} style={{ display: (canShowWorksheetsPopup ? "block" : 'none') }} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog worksheet-model-dialog" role="document">
                    <div className="modal-content worksheet-model">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Worksheet Numbers</h5>
                            <button type="button" className="close popup-close cursor-pointer" data-dismiss="modal" aria-label="Close" onClick={handleConfirmQuestionsClose}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            {loadingWorksheet &&
                                <div className="text-center p-5">
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div>
                            }
                            {!loadingWorksheet &&
                                <React.Fragment>
                                    <div>
                                        <button className="px-4 py-1 cursor-pointer msb-btn" style={{ float: 'right' }} onClick={() => onChangeActionType({}, 'ADD')}>Add Worksheet</button>&nbsp;
                                    </div>
                                    <div className="table-responsive mt-4">
                                        <table className="table fixed-table-header mb-0">
                                            <thead>
                                                <tr>
                                                    <th>Worksheet Number</th>
                                                    <th>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {worksheetNumbers.map((name: any, i: number) => (
                                                    <tr key={i}>
                                                        <td>{name.worksheet_no}</td>
                                                        <td>
                                                            {/* <i className="bi bi-plus-circle cursor-pointer edit-icon p-2" onClick={() => onChangeActionType(name, 'ADD')}></i> */}
                                                            <i className="bi bi-eye-fill cursor-pointer p-2" onClick={() => onWorkSheetClick(name)}></i>
                                                            <i className="bi bi-pencil-square cursor-pointer edit-icon p-2" onClick={() => onChangeActionType(name, 'UPDATE')}></i>
                                                            <i className="bi bi-trash cursor-pointer p-2" onClick={() => onChangeActionType(name, 'DELETE')}></i>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </React.Fragment>}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary cursor-pointer" data-dismiss="modal" onClick={handleConfirmQuestionsClose}>Close</button>&nbsp;
                        </div>
                    </div>
                </div>
            </div>
            <div className={`modal fade parent-modal-fade ${canShowQuestionsPopup ? 'show' : 'false'}`} style={{ display: (canShowQuestionsPopup ? "block" : 'none') }} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog worksheet-quesions-dialog" role="document">
                    <div className="modal-content worksheet-questions-model">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Questions List</h5>
                            <button type="button" className="close popup-close cursor-pointer" data-dismiss="modal" aria-label="Close" onClick={handleQuestionsClose}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            {loadingWorksheetQuestions &&
                                <div className="text-center p-5">
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div>
                            }
                            {!loadingWorksheetQuestions && <div>
                                {questionsData.map((question: any, i: number) => (
                                    <div className="mt-4" key={i}>
                                        <div className='row col-md-12'>
                                            <div className='col-md-6'>
                                                <span className='fw-bold' style={{ fontSize: '22px' }}>Q{question.question_number}:</span>
                                                <span className='fw-bold' style={{ fontSize: '22px', paddingLeft: '15px' }}>
                                                    {question.question}
                                                </span>
                                            </div>
                                            <div className='col-md-4 pt-2'>
                                                {question.question_type === 'input_text' && <input type="text" disabled className="form-control" name="option" />}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary cursor-pointer" data-dismiss="modal" onClick={handleQuestionsClose}>Close</button>&nbsp;
                        </div>
                    </div>
                </div>
            </div>
            <div className={`modal fade parent-modal-fade ${canShowWorksheetActionsPopup ? 'show' : 'false'}`} style={{ display: (canShowWorksheetActionsPopup ? "block" : 'none') }} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">{getActionType()}</h5>
                            <button type="button" className="close popup-close cursor-pointer" data-dismiss="modal" aria-label="Close" onClick={handleWorksheetTypeClose}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        {actionType !== 'DELETE' && <div className="modal-body">
                            {loadingWorksheetData &&
                                <div className="text-center p-5">
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div>
                            }
                            {!loadingWorksheetData && <FormBuilder onUpdate={handleInput}>
                                <form ref={worksheetForm}>
                                    <div className="row custom-form">

                                        {(actionType === 'UPDATE' || actionType === 'ADD') && <div className="col-md-12">
                                            <label className="form-label mb-1">Upload Excel File
                                                <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>*</span>
                                            </label><br />
                                            <div className="file btn btn-primary" style={{ marginRight: '10px' }}>Browse
                                                <input type="file" name='file' onChange={(e) => handleBrowseFile(e)} />
                                            </div>
                                            {excelFileName}
                                            {fileError && <p className="text-danger">{fileError}</p>}
                                        </div>}
                                    </div>
                                </form>
                            </FormBuilder>}
                        </div>}
                        <div className="modal-footer">
                            {actionType === 'ADD' && <button type="button" className="btn btn-primary cursor-pointer" onClick={() => handleAbility(actionType)}>Save</button>}
                            {actionType === 'UPDATE' && <button type="button" className="btn btn-primary cursor-pointer" onClick={() => handleAbility(actionType)}>Yes</button>}
                            {actionType === 'DELETE' && <button type="button" className="btn btn-primary cursor-pointer" onClick={() => handleAbility(actionType)}>Yes</button>}
                            <button type="button" className="btn btn-secondary cursor-pointer" data-dismiss="modal" onClick={handleWorksheetTypeClose}>Close</button>&nbsp;
                        </div>
                    </div>
                </div>
            </div>
            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading &&
                <React.Fragment>
                    <div>
                        <button className="px-4 py-1 mb-2 cursor-pointer msb-btn" style={{ float: 'right' }} onClick={() => handleWorksheetDownload()} >Worksheets List Template Download</button>
                    </div>
                    <div className="table-responsive mt-2">
                        <table className="table fixed-table-header mb-0">
                            <thead>
                                <tr>
                                    <th>Ability Id</th>
                                    <th>Topic</th>
                                    <th>Name</th>
                                    <th>Worksheets Count</th>
                                    {worksheetsView && <th>Action</th>}
                                </tr>
                            </thead>
                            <tbody>
                                {abilityRecords.map((name: any, i: number) => (
                                    <tr key={i}>
                                        <td>{name.ability_id}</td>
                                        <td>{name.topic}</td>
                                        <td>{name.name}</td>
                                        <td>{name.woksheets_count ? name.woksheets_count : 0}</td>
                                        {worksheetsView && <td>
                                            <i className="bi bi-eye-fill cursor-pointer p-2" onClick={() => onClickActionType(name, 'view')}></i>
                                        </td>}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    {abilityList.length === 0 && <div style={{ textAlign: 'center' }}>
                        No records Found
                    </div>}
                </React.Fragment>}

            {abilityList.length > 0 &&
                <div>
                    <nav className='mt-3' style={{ float: 'right' }}>
                        <ul className="pagination">
                            <li className="page-item">
                                <button className={`page-link prev ${activePage === 1 ? 'disabled' : ''}`} onClick={() => onClickPreviousPage()}>Previous</button>
                            </li>
                            {pageNumbers.map((number: number, i: number) => (
                                <li className={activePage === number ? 'active page-item' : 'page-item'} key={i}>
                                    <button className="page-link" onClick={() => onClickPage(number)}>{number}</button>
                                </li>
                            ))}
                            <li className="page-item">
                                <button className={`page-link next ${activePage === pageNumbers.length ? 'disabled' : ''}`} onClick={() => onClickNextPage()}>Next</button>
                            </li>
                        </ul>
                    </nav>
                </div>
            }
        </div>
    )
}

