import React, { useState } from "react";

interface Props {
    classWise: any;
    sectionWise: any;
}

export const MathsClassWiseMnsAnalysis = (props: Props) => {
    const [classWise, setClassWise] = useState<any>(props.classWise);
    const [sectionWise, setSectionWise] = useState<any>(props.sectionWise);

    const mapValueToLabel = (values: number): string => {
        let value = Math.round(values);

        if (value == 0) {
            return 'A1';
        } else if (value == 1) {
            return 'A1';
        } else if (value == 2) {
            return 'A2';
        } else if (value == 3) {
            return 'A3';
        } else if (value == 4) {
            return 'B1';
        } else if (value == 5) {
            return 'B2';
        } else if (value == 6) {
            return 'B3';
        } else if (value == 7) {
            return 'C1';
        } else if (value == 8) {
            return 'C2';
        } else if (value == 9) {
            return 'C3';
        } else {
            return '-';
        }
    };

    return (
        <div>
            <h3 className="mt-3 mb-2 section-hd">Students Status For MNS - Total Class</h3>
            <div className="row">
                <div className="col-md-6 col-lg accordion-collapse mb-4 dkpi-block">
                    <div className="dkpi-block-content dkpi-green-bg no-hover" >
                        <h3 className="dkpi-hd mb-0"><small className="mb-0">Number of Students</small>Completed</h3>
                        <p className="mt-1 mb-0 dkpi-num">{classWise[0]?.average_results.mns['students_completed']}</p>
                    </div>
                </div>
                <div className="col-md-6 col-lg mb-4 dkpi-block">
                    <div className="dkpi-block-content dkpi-blue-bg no-hover">
                        <h3 className="dkpi-hd mb-0"><small className="mb-0">Students</small>Attempted Test</h3>
                        <p className="mt-1 mb-0 dkpi-num">{classWise[0]?.average_results.mns['students_attempted_test']}</p>
                    </div>
                </div>
                <div className="col-md-6 col-lg mb-4 dkpi-block">
                    <div className="dkpi-block-content dkpi-yellow-bg no-hover">
                        <h3 className="dkpi-hd mb-0"><small className="mb-0">Students</small>Average Test Percentage</h3>
                        <p className="mt-1 mb-0 dkpi-num">{classWise[0]?.average_results?.mns['students_average_test_percentage']?.toFixed()}%</p>
                    </div>
                </div>
                <div className="col-md-6 col-lg mb-4 dkpi-block">
                    <div className="dkpi-block-content dkpi-pink-bg no-hover">
                        <h3 className="dkpi-hd mb-0"><small className="mb-0">Students</small>Average Level</h3>
                        {console.log(classWise[0]?.average_results.mns['students_average_level'], "classWise[0]?.average_results.mns['students_average_level']")}
                        <p className="mt-1 mb-0 dkpi-num">{mapValueToLabel(classWise[0]?.average_results.mns['students_average_level'])}</p>
                    </div>
                </div>
            </div>
            <h3 className="mt-3 mb-2 section-hd">Students MNS Levels For Whole Class</h3>
            <div className="table-responsive">
                <table className="table table_new_ui">
                    <thead>
                        <tr>
                            <th style={{ width: "200px" }}>Students Completed<br />
                                Level A1</th>
                            <th style={{ width: "180px" }}>Students Completed<br />
                                Level A2</th>
                            <th style={{ width: "150px" }}>Students Completed<br />
                                Level A3</th>
                            <th style={{ width: "150px" }}>Students Completed<br />
                                Level B1</th>
                            <th style={{ width: "150px" }}>Students Completed<br />
                                Level B2</th>
                            <th style={{ width: "150px" }}>Students Completed<br />
                                Level B3</th>
                            <th style={{ width: "150px" }}>Students Completed<br />
                                Level C1</th>
                            <th style={{ width: "150px" }}>Students Completed<br />
                                Level C2</th>
                            <th style={{ width: "150px" }}>Students Completed<br />
                                Level C3</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{classWise[0]?.average_results.mns['students_completed_level_A1']}</td>
                            <td>{classWise[0]?.average_results.mns['students_completed_level_A2']}</td>
                            <td>{classWise[0]?.average_results.mns['students_completed_level_A3']}</td>
                            <td>{classWise[0]?.average_results.mns['students_completed_level_B1']}</td>
                            <td>{classWise[0]?.average_results.mns['students_completed_level_B2']}</td>
                            <td>{classWise[0]?.average_results.mns['students_completed_level_B3']}</td>
                            <td>{classWise[0]?.average_results.mns['students_completed_level_C1']}</td>
                            <td>{classWise[0]?.average_results.mns['students_completed_level_C2']}</td>
                            <td>{classWise[0]?.average_results.mns['students_completed_level_C3']}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <h3 className="mt-3 mb-2 section-hd">Students MNS Levels For Whole Section</h3>
            <div className="table-responsive">
                <table className="table table_new_ui">
                    <thead>
                        <tr>
                            <th style={{ width: "300px" }}>Status</th>
                            {sectionWise?.map((data: any, index: number) => {
                                return (
                                    <>
                                        <th style={{ width: "150px" }} key={index}>{data.section} Section</th>

                                    </>)
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Students Completed</td>
                            {sectionWise?.map((data: any, index: number) => {
                                return (<>
                                    <td key={index}>{data.average_results.mns['students_completed']}</td>
                                </>)
                            })}
                        </tr>
                        <tr>
                            <td>Students Attempted Test</td>
                            {sectionWise?.map((data: any, index: number) => {
                                return (<>
                                    <td key={index}>{data.average_results.mns['students_attempted_test']}</td>
                                </>)
                            })}
                        </tr>
                        <tr>
                            <td>Students Average Test Percentage </td>
                            {sectionWise?.map((data: any, index: number) => {
                                return (<>
                                    <td key={index}>{data.average_results.mns['students_average_test_percentage']?.toFixed()} %</td>
                                </>)
                            })}
                        </tr>
                        <tr>
                            <td>Students Average Level</td>
                            {sectionWise?.map((data: any, index: number) => {
                                return (<>
                                    <td key={index}>{mapValueToLabel(data.average_results.mns['students_average_level'])}</td>
                                </>)
                            })}
                        </tr>
                    </tbody>
                </table>
            </div>
            <h3 className="mt-3 mb-2 section-hd">Students Section Wise MNS Levels</h3>
            <div className="table-responsive">
                <table className="table table_new_ui">
                    <thead>
                        <tr>
                            <th style={{ width: "300px" }}>Status</th>
                            {sectionWise?.map((data: any, index: number) => {
                                return (
                                    <>
                                        <th style={{ width: "150px" }} key={index}>{data.section} Section</th>
                                    </>)
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Students Completed Level A1</td>
                            {sectionWise?.map((data: any, index: number) => {
                                return (
                                    <>
                                        <td>{data.average_results.mns['students_completed_level_A1']}</td>

                                    </>)
                            })}
                        </tr>
                        <tr>
                            <td>Students Completed Level A2</td>
                            {sectionWise?.map((data: any, index: number) => {
                                return (
                                    <>
                                        <td>{data.average_results.mns['students_completed_level_A2']}</td>

                                    </>)
                            })}
                        </tr>
                        <tr>
                            <td>Students Completed Level A3</td>
                            {sectionWise?.map((data: any, index: number) => {
                                return (
                                    <>
                                        <td>{data.average_results.mns['students_completed_level_A3']}</td>

                                    </>)
                            })}
                        </tr>
                        <tr>
                            <td>Students Completed Level B1</td>
                            {sectionWise?.map((data: any, index: number) => {
                                return (
                                    <>
                                        <td>{data.average_results.mns['students_completed_level_B1']}</td>

                                    </>)
                            })}
                        </tr>
                        <tr>
                            <td>Students Completed Level B2</td>
                            {sectionWise?.map((data: any, index: number) => {
                                return (
                                    <>
                                        <td>{data.average_results.mns['students_completed_level_B2']}</td>

                                    </>)
                            })}
                        </tr>
                        <tr>
                            <td>Students Completed Level B3</td>
                            {sectionWise?.map((data: any, index: number) => {
                                return (
                                    <>
                                        <td>{data.average_results.mns['students_completed_level_B3']}</td>

                                    </>)
                            })}
                        </tr>
                        <tr>
                            <td>Students Completed Level C1</td>
                            {sectionWise?.map((data: any, index: number) => {
                                return (
                                    <>
                                        <td>{data.average_results.mns['students_completed_level_C1']}</td>

                                    </>)
                            })}
                        </tr>
                        <tr>
                            <td>Students Completed Level C2</td>
                            {sectionWise?.map((data: any, index: number) => {
                                return (
                                    <>
                                        <td>{data.average_results.mns['students_completed_level_C2']}</td>

                                    </>)
                            })}
                        </tr>
                        <tr>
                            <td>Students Completed Level C3</td>
                            {sectionWise?.map((data: any, index: number) => {
                                return (
                                    <>
                                        <td>{data.average_results.mns['students_completed_level_C3']}</td>

                                    </>)
                            })}
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}