import React, { useEffect, useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom';
import MSBLOGO from '../../../assets/images/iamsamarth-Logo-Final.svg';
import HRRLOGO from '../../../assets/images/HRRDC-logo.png';
import { ToastContainer, toast } from 'react-toastify';
import CanvasJSReact from '../../../../../assets/canvasjs.react';
import { GET_REPORT_BY_USER, GET_STUDENT_REPORT } from '../../../../../app/service/reports.service';
import { GET_MODULES_ID, GET_MODULE_BY_UUID, GET_MODULE_STUDENT_BY_ID } from '../../../../../app/service/module.service';
import appConfig from '../../../../../config/constant';
import { PDFViewer } from 'react-view-pdf';
import { GET_USER_USER_CODE } from '../../../../../app/service/users.service';

var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

export const ActiveStudentReportView = () => {
    const [barchartData, setBarchartData] = useState<any>([]);
    const [tableData, setTableData] = useState<any>([]);
    const [messagesData, setMessagesData] = useState<any>([]);
    const [reportData, setReportData] = useState(false);
    const [chartPreparedData, setChartPreparedData] = useState<any[]>([]);
    const [moduleData, setModuleData] = useState<any>({});
    const [loading, setLoading] = useState(false);
    const [pdfUrl, setPdfUrl] = useState('');
    const [invalidReport, setInvalidReport] = useState(false);
    const userRole = localStorage.getItem("user_role");
    let { id, studentCode } = useParams<{ id: string, studentCode: string }>();
    const moduleId = id;
    const studentsCode = studentCode;
    const [studentInfo, setStudentInfo] = useState<any>({});

    const history = useHistory();

    useEffect(() => {
        getUserDetails();
        getModuleData();

    }, []);

    function getModuleData() {
        GET_MODULE_BY_UUID(moduleId).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                } else {
                    const data = res.rows[0];
                    if (data) {
                        setModuleData(data);
                        getStudentReports(data);
                    }
                }
            }
        });
    }

    function getStudentReports(data: any) {
        const payload = {
            student_code: studentsCode,
            module_code: data.module_code,
            sub_module: data.sub_module,
            module_ver: data.module_ver,
            report_type: "student",
            // pdf: true
        }
        setLoading(true);
        GET_REPORT_BY_USER(payload).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                    setInvalidReport(true);
                } else {
                    const data = res;
                    setPdfUrl(`${appConfig.FILE_UPLOAD_URL}${data.url.charAt(0) === "/" ? data.url.substring(1) : data.url}`);
                    setLoading(false);
                    setReportData(true);
                    setInvalidReport(false);
                }
            }
        });

    }

    const options = {
        axisX: {
            interval: 1
        },
        axisY: {
            title: ""
        },
        data: [{
            type: "bar",
            color: "#006a4e",
            indexLabel: "{y}",
            axisYType: "primary",
            indexLabelFontColor: "black",
            yValueFormatString: "#,###'%'",
            dataPoints: chartPreparedData
        }],
    }

    function onClickReportDownload() {
        window.open(`${pdfUrl}`, "_blank", 'noopener,noreferrer');

    }

    function getUserDetails() {
        setLoading(true);
        GET_USER_USER_CODE(studentCode).then((response: any) => {
            if (response.status === 'fail') {
                toast.error(response.message);
                setLoading(false);
            } else {
                const data = response.rows[0];
                setStudentInfo(data);
                setLoading(false);

            }
        });
    }


    return (
        <div className="row  ">
            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading && <div>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb border-bottom ps-0 pt-0 pb-2" style={{ letterSpacing: "0" }}>
                        {userRole === "SUPER_ADMIN" && <li className="breadcrumb-item mt-3"><Link to='/dashboard/view/instituteinfo' style={{ color: '#0d6efd' }}>Dashboard</Link> / <Link to='/dashboard/view/active-students' style={{ color: '#0d6efd' }}>{studentInfo?.name_of_school}</Link> / <Link to={`/institute-dashboard/dashboard/active-studentslist/${studentInfo?.name_of_school}/${studentInfo?.institute_school_code}`} style={{ color: '#0d6efd' }}><span>Active Students</span></Link> / <Link to={`/institute-dashboard/dashboard/active-studentsmoduleslist/${studentCode}`} style={{ color: '#0d6efd' }}><span>{studentInfo?.name}</span></Link>/ <span>{moduleData?.module_code}</span></li>}
                        {userRole !== "SUPER_ADMIN" && <li className="breadcrumb-item"><Link to='/institute-dashboard/dashboard' style={{ color: '#0d6efd' }}>Dashboard</Link> / <Link to='/institute-dashboard/dashboard/active-students' style={{ color: '#0d6efd' }}>{studentInfo?.name_of_school}</Link> / <Link to={`/institute-dashboard/dashboard/active-studentslist/${studentInfo?.name_of_school}/${studentInfo?.institute_school_code}`} style={{ color: '#0d6efd' }}><span>Active Students</span></Link> / <Link to={`/institute-dashboard/dashboard/active-studentsmoduleslist/${studentCode}`} style={{ color: '#0d6efd' }}><span>{studentInfo?.name}</span></Link> / <span>{moduleData?.module_code}</span></li>}
                    </ol>
                </nav>
                <div className='row'>
                    <div className='col-md-11'>
                        {invalidReport && <h1 className='' style={{ textAlign: 'center' }}> Report not generated</h1>}
                    </div>

                </div>
                {reportData && <div style={{ backgroundColor: 'white', paddingTop: '50px' }}>
                    <div className="row">
                        <div className='col-md-12'>
                            <button className="btn btn-primary px-3 mb-2 cursor-pointer" style={{ border: 'none', float: 'right', backgroundColor: '#0f70cd', borderRadius: '5px', color: 'white', marginRight: '5px' }} onClick={() => onClickReportDownload()}>Download</button>
                        </div>
                    </div>
                    <div className="row">
                        <div className='col-md-12'>
                            <PDFViewer url={pdfUrl} />
                        </div>

                    </div>
                </div>}
            </div>}
        </div>
    )
}