import React, { useState, useEffect } from 'react';

export default function TimerInfo(props: any) {
    const [minutes, setMinutes] = useState(props.minutes);
    const [seconds, setSeconds] = useState(props.seconds);
    const [timeRemaining, setTimeRemaining] = useState(props.minutes);

    useEffect(() => {
        if (props.startTimer) {
            const timer = setInterval(() => {
                changeTime();
            }, 1000);

            if (timeRemaining === 0 && minutes === 0 && seconds === 0) {
                clearInterval(timer);
                props.timeUp(true);
            }
            return () => {
                clearInterval(timer);
            };
        }
    }, [timeRemaining, props.startTimer, seconds]);

    const changeTime = () => {
        if (seconds > 0) {

            setSeconds((prev: any) => prev - 1);
        }
        if (seconds == 0) {
            if (minutes == 0) {
                setTimeRemaining(0);
            } else {
                setMinutes((prev: any) => prev - 1);
                setSeconds(59);
            }
        }
        props.time_remain(minutes + ':' + seconds);
    }

    return (
        <div>
            <h1 className="m-0"><small>min</small>{(minutes < 10 ? '0' : '') + minutes + ':' + (seconds < 10 ? '0' : '') + seconds}<small>sec</small></h1>

        </div>
    )
}