import React, { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { DataTable } from "../../../../../components/data-table";
import { FormControlError, FormField, FormValidators } from "../../../../../components/form-builder/model/form-field";
import { toast } from "react-toastify";
import { FormValidator } from "../../../../../components/form-builder/validations";
import FormBuilder from "../../../../../components/form-builder";
import { Breadcum } from "../../../../../components/breadcum";
import { CREATE_CHAPTER, DELETE_CHAPTER, GET_CHAPTER_BY_UUID, GET_CHAPTER_FILTER, GET_CHAPTER_LIST, UPDATE_CHAPTER } from "../../../../../app/service/abhyas-chapter.service";
import { AbhyasChapterDataGridCols } from "./data-grid-cols";
import moment from "moment";

interface Props {
    isLinked: string;
    display: string;
    boardUuid: any;
    mediumUuid: any;
    standardUuid: any;
    subjectUuid: any;
    updateList?: (e: string) => void;
}
export const AbhyasChapterList: React.FC<Props> = (props) => {
    const [breadCrumLoading, setBreadCrumLoading] = useState<boolean>(false);
    const [chapterList, setChapterList] = useState<any>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [pageArray, setPageNumbers] = useState([]);
    const [activePage, setActivePage] = useState(0);
    const [canShowPopup, setCanShowPopup] = useState<boolean>(false);
    const [chapterId, setChapterId] = useState<string>("0");
    const [formLoading, setFormLoading] = useState<boolean>(false);
    const [currentFormData, setCurrentFormData] = useState<any>({});
    const [formData, setFormData] = useState<any>([]);
    const [formValidationErrors, setFormValidationErrors] = useState<FormControlError[]>([]);
    const [nameError, setNameError] = useState('');
    const [preparedSearchData, setPreparedSearchData] = useState<any>({});
    const [codeError, setCodeError] = useState('');
    const medimForm = useRef<any>({});
    const searchForm = useRef<any>({});
    const [searchData, setSearchData] = useState<any>({});
    let { code, id, BoardUuid, BoardName, MediumUuid, MediumName, StandardUuid, StandardName, SubjectUuid, SubjectName } = useParams<{ code: string, id: string, BoardUuid: string, BoardName: string, MediumUuid: string, MediumName: string, StandardUuid: string, StandardName: string, SubjectUuid: string, SubjectName: string }>();
    const moduleCode = code;
    const moduleId = id;
    const boardUuid = BoardUuid ? BoardUuid : props.boardUuid;
    const mediumUuid = MediumUuid ? MediumUuid : props.mediumUuid;
    const standardUuid = StandardUuid ? StandardUuid : props.standardUuid;
    const subjectUuid = SubjectUuid ? SubjectUuid : props.subjectUuid;
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const FormValidations = [
        new FormField('name', [FormValidators.REQUIRED]),
        new FormField('code', [FormValidators.REQUIRED]),
    ];
    const history = useHistory();
    const [breadcumInfo, setBreadcumInfo] = useState<any>([
        {
            label: 'Board',
            value: decodeURIComponent(BoardName),
            routerLink: `/dashboard/module/info/ABHYAS/${moduleCode}/${moduleId}/worksheet`,
            subName: "(board)",
            isActive: false
        },
        {
            label: 'Medium',
            value: decodeURIComponent(MediumName),
            routerLink: `/dashboard/module/info/ABHYAS/${moduleCode}/${moduleId}/${boardUuid}/${BoardName}/medium`,
            subName: "(Medium)",
            isActive: false
        },
        {
            label: 'standard',
            value: decodeURIComponent(StandardName),
            routerLink: `/dashboard/module/info/ABHYAS/${moduleCode}/${moduleId}/${boardUuid}/${BoardName}/${MediumUuid}/${MediumName}/standard`,
            subName: "(standard)",
            isActive: false
        },
        {
            label: 'subject',
            value: decodeURIComponent(SubjectName),
            routerLink: `/dashboard/module/info/ABHYAS/${moduleCode}/${moduleId}/${boardUuid}/${BoardName}/${MediumUuid}/${MediumName}/${standardUuid}/${StandardName}/subject`,
            subName: "(subject)",
            isActive: false
        },
        {
            label: 'chapters',
            value: 'chapters',
            nameHighlight: true,
            isActive: true
        }
    ]);

    useEffect(() => {
        for (let j = 0; j < AbhyasChapterDataGridCols.length; j++) {
            if (String(AbhyasChapterDataGridCols[j].isLink) === "true" || String(AbhyasChapterDataGridCols[j].isLink) === "false") {
                AbhyasChapterDataGridCols[j].isLink = props.isLinked === "false" ? false : true;
            }
        }

        getChapterData();

    }, [])


    const getChapterData = () => {
        setLoading(true);
        GET_CHAPTER_LIST(activePage == 0 ? activePage : activePage - 1, boardUuid, mediumUuid, standardUuid, subjectUuid, {}).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.err);
                    setLoading(false);
                    setBreadCrumLoading(false);

                } else {
                    const data = res['rows'];
                    data?.forEach((element: any) => {
                        element.created_dt = moment(element?.created_dt?.split("T")[0]).format('DD-MM-yyyy');
                        if (element?.modified_dt) {
                            element.modified_dt = moment(element?.modified_dt?.split("T")[0]).format('DD-MM-yyyy');
                        }
                    });
                    setChapterList(data);
                    const pageNumbers = Math.ceil(res.count / 10);
                    const pageArray: any = [];
                    for (let i = 1; i <= pageNumbers; i++) {
                        pageArray.push(i);
                    }
                    setPageNumbers(pageArray);
                    setLoading(false);
                    setBreadCrumLoading(false);

                }
            }
        });
    }


    const onPageChange = (data: any) => {
        setActivePage(data);
        const pageNumber = data - 1;
        setLoading(true);
        GET_CHAPTER_LIST(pageNumber, boardUuid, mediumUuid, standardUuid, subjectUuid, preparedSearchData ? preparedSearchData : {}).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.err);
                    setLoading(false);
                } else {
                    const data = res.rows;
                    data?.forEach((element: any) => {
                        element.created_dt = moment(element?.created_dt?.split("T")[0]).format('DD-MM-yyyy');
                        if (element?.modified_dt) {
                            element.modified_dt = moment(element?.modified_dt?.split("T")[0]).format('DD-MM-yyyy');
                        }
                    });
                    setChapterList(data);
                    setLoading(false);
                }
            }
        });
    }
    const onDelete = (data: any) => {
        setLoading(true);
        const id = data.uuid;
        DELETE_CHAPTER(id).then((res: any) => {
            if (res.status === "fail") {
                toast.error(res.message);
                setLoading(false);
            } else {
                setLoading(false);
                toast.success(res.message);
                getChapterData();
                if (props.subjectUuid && props.updateList) {
                    props.updateList(subjectUuid);
                }
            }
        });

    }
    const onEdit = (data: any) => {
        setChapterId(data?.uuid);
        setFormLoading(false);
        setCodeError('');
        setNameError('');
        setCanShowPopup(true);
        if (data?.uuid) {
            setFormLoading(true);
            GET_CHAPTER_BY_UUID(data?.uuid).then((res: any) => {
                if (res.status === 'fail') {
                    toast.error(res.err);
                    setFormLoading(false);

                } else {
                    if (res.rows.length > 0) {
                        setCurrentFormData(res.rows[0]);
                        setFormLoading(false);

                    }
                }
            });
        }
    }
    const handleClose = () => {
        setFormData({});
        setCurrentFormData({});
        setCanShowPopup(false);
    }
    const addUpdateBoard = (data: any) => {
        setFormLoading(false);
        setCodeError('');
        setNameError('');
        setCanShowPopup(true);
        setChapterId(data);
        const datafrom = {
            description: '',
            name: ''
        };
        setFormData({ ...datafrom });
        setCurrentFormData({ ...datafrom });
        medimForm?.current.reset();
    }
    const handleFormInput = (data: any) => {
        data.value = { ...currentFormData, ...data.value };
        setFormData(data);
        const errors: any = FormValidator(FormValidations, data.value);
        setFormValidationErrors(errors);
    };

    const onChangeName = (event: any) => {
        setNameError('');
        // if (currentFormData) {
        //     currentFormData.name = event;
        // }
        if (event) {
            return true;
        } else {
            setNameError('Please enter chapter name');
        }
    }
    const createChapter = () => {
        const formsData = formData.value ? { ...formData.value } : { ...currentFormData };
        setIsFormSubmitted(true);
        const errors: FormControlError[] = FormValidator(FormValidations, formsData);
        setFormValidationErrors(errors);
        Object.keys(formsData).forEach(function (key) {
            if (typeof formsData[key] === 'string') {
                formsData[key] = formsData[key].trim();
            }
        });
        if (onChangeName(formsData?.name)) {
            formsData.board_uuid = boardUuid;
            formsData.medium_uuid = mediumUuid;
            formsData.standard_uuid = standardUuid;
            formsData.subject_uuid = subjectUuid;
            setFormLoading(true);
            CREATE_CHAPTER([formsData]).then((res: any) => {
                if (res.status === 'fail') {
                    const errorValue = Array.isArray(res?.err) ? res?.err[0] : res?.err;
                    toast.error(errorValue);
                    setFormLoading(false);
                } else {
                    toast.success(res.message);
                    getChapterData();
                    setFormLoading(false);
                    setCanShowPopup(false);
                    setFormData({});
                    setCurrentFormData({});
                    if (props.subjectUuid && props.updateList) {
                        props.updateList(subjectUuid);
                    }
                }
            });
        } else {
            if (!nameError && !onChangeName(formsData?.name)) {
                setNameError('Please enter chapter name');
            }

        }
    }
    const updateChapter = () => {
        const formsData = formData.value ? { ...formData.value } : { ...currentFormData };
        setIsFormSubmitted(true);
        const errors: FormControlError[] = FormValidator(FormValidations, formsData);
        const payLoad = {
            subject_uuid: subjectUuid,
            medium_uuid: mediumUuid,
            standard_uuid: standardUuid,
            description: formsData?.description,
            name: formsData?.name,
            board_uuid: boardUuid,
            uuid: chapterId
        }
        setFormValidationErrors(errors);
        if (onChangeName(formsData?.name)) {

            setFormLoading(true);
            CREATE_CHAPTER([payLoad]).then((res: any) => {
                if (res.status === 'fail') {
                    const errorValue = Array.isArray(res?.err) ? res?.err[0] : res?.err;
                    toast.error(errorValue);
                    setFormLoading(false);
                } else {
                    toast.success(res.message);
                    getChapterData();
                    setFormLoading(false);
                    setCanShowPopup(false);
                    setFormData({});
                    setCurrentFormData({});
                    if (props.subjectUuid && props.updateList) {
                        props.updateList(subjectUuid);
                    }

                }
            });
        } else {
            if (!nameError && !onChangeName(formsData?.name)) {
                setNameError('Please enter medium name');
            }

        }
    }
    const handleSearchInput = (data: any) => {
        setSearchData(data);
    };
    function handleSearch() {
        const data = { ...searchData.value };
        if (data?.search) {
            setLoading(true);
            for (const key of Object.keys(data)) {
                if (data[key] === "") {
                    delete data[key];
                }
            }
            setPreparedSearchData(data);
            GET_CHAPTER_FILTER(data, boardUuid, mediumUuid, standardUuid, subjectUuid).then((res: any) => {
                if (res.status === 'fail') {
                    toast.error(res.err);
                    setLoading(false);
                } else {
                    const data = res.rows;
                    data?.forEach((element: any) => {
                        element.created_dt = moment(element?.created_dt?.split("T")[0]).format('DD-MM-yyyy');
                        if (element?.modified_dt) {
                            element.modified_dt = moment(element?.modified_dt?.split("T")[0]).format('DD-MM-yyyy');
                        }
                    });
                    setActivePage(0);
                    setChapterList(data);
                    const pageNumbers = Math.ceil(res.count / 10);
                    const pageArray: any = [];
                    for (let i = 1; i <= pageNumbers; i++) {
                        pageArray.push(i);
                    }
                    setPageNumbers(pageArray);
                    setLoading(false);
                }
            });
        }
    }
    function handleRefresh() {
        const data = searchData;
        data.value = {};
        setSearchData({ ...data });
        setPreparedSearchData({});
        getChapterData();
        searchForm.current.reset();
    }
    const onActions = (data: any) => {
        if (data.type === "link") {
            if (moduleCode === "PARIKSHA") {
                history.push(
                    `/dashboard/module/info/ABHYAS/${moduleCode}/${moduleId}/${boardUuid}/${BoardName}/${MediumUuid}/${MediumName}/${standardUuid}/${StandardName}/${subjectUuid}/${SubjectName}/${data?.data?.uuid}/${encodeURIComponent(data?.data?.name)}/topic`
                );
                // history.push(
                //     `/dashboard/module/info/ABHYAS/${moduleCode}/${moduleId}/${boardUuid}/${BoardName}/${MediumUuid}/${MediumName}/${standardUuid}/${StandardName}/${subjectUuid}/${SubjectName}/${data?.data?.uuid}/${data?.data?.name}/test`
                // );
            } else {
                history.push(
                    `/dashboard/module/info/ABHYAS/${moduleCode}/${moduleId}/${boardUuid}/${BoardName}/${MediumUuid}/${MediumName}/${standardUuid}/${StandardName}/${subjectUuid}/${SubjectName}/${data?.data?.uuid}/${encodeURIComponent(data?.data?.name)}/topic`
                );
            }
        }
    };
    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleSearch();
        }
    };
    return (
        <div>
            <div
                className={`modal fade ${canShowPopup ? "show" : "false"}`}
                style={{ display: canShowPopup ? "block" : "none" }}
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog parent-model-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                                {chapterId === "0" ? "Add Chapter" : "Update Chapter"}
                            </h5>
                            <button
                                type="button"
                                className="close popup-close cursor-pointer"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={handleClose}
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body parent-info ">
                            {formLoading && (
                                <div className="text-center p-5">
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div>
                            )}
                            {!formLoading && (
                                <>
                                    <FormBuilder onUpdate={handleFormInput}>
                                        <form ref={medimForm}>
                                            <div className="row ">

                                                <div className="col-md-6 custom-form">
                                                    <div className="mb-4">
                                                        <label className="form-label">Chapter Name <sup style={{ color: 'red', fontSize: '14px', }}>*</sup></label>
                                                        <input className="form-control form-control-lg f16 mt-md-4 fw-normal" type="text" name="name" defaultValue={currentFormData?.name} onChange={(e) => onChangeName(e.target.value)} placeholder="Please enter chapter name" />
                                                        {nameError && <p className="text-danger">{nameError}</p>}
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="mb-4">
                                                        <label className="form-label">Description </label>
                                                        <textarea className="form-control form-control-lg f16" name="description" defaultValue={currentFormData?.description} placeholder="Please enter chapter description" />
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </FormBuilder>
                                </>
                            )}
                        </div>
                        <div className="modal-footer">
                            {chapterId === "0" && < a className="btn btn-primary btn-sm px-4 rounded-12 cursor-pointer py-2" onClick={() => createChapter()}>Add</a>}
                            {chapterId !== "0" && <a className="btn btn-primary btn-sm px-4 rounded-12 cursor-pointer py-2" onClick={() => updateChapter()}>Update</a>}
                            <button
                                type="button"
                                className="btn btn-secondary cursor-pointer btn-sm px-4 rounded-12 cursor-pointer py-2"
                                data-dismiss="modal"
                                onClick={handleClose}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`mt-3 ${props.display === "none" && "d-none"}`}>
                {!breadCrumLoading &&
                    <Breadcum breadcumList={breadcumInfo} margin={"classic"} />}
            </div>

            <div className="row  mt-2" style={{ marginBottom: '15px' }}>
                <div className="col-md-4">
                    <FormBuilder onUpdate={handleSearchInput}>
                        <form ref={searchForm}>
                            <input type="text" id="search" className="form-control ft-14" placeholder="chapter name" name="search" onKeyDown={(e) => handleKeyDown(e)} />
                        </form>
                    </FormBuilder>
                </div>
                <div className="col-md-4 pt-3 pt-md-0 ">
                    <button className="btn btn-primary px-3 cursor-pointer" type="button" onClick={() => handleSearch()}>Search</button>&nbsp;
                    <button className="btn clear-btn cursor-pointer" type="button" onClick={() => handleRefresh()}>
                        Clear
                    </button>
                </div>
                <div className="col-md-4 pt-3 pt-md-0 text-end">
                    <p className="mb-0"><button className="btn btn-primary" onClick={() => addUpdateBoard("0")}>Add Chapter</button></p>
                </div>
            </div>
            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading &&
                <div className="mt-3">
                    <DataTable TableCols={AbhyasChapterDataGridCols} tableData={chapterList}
                        onActions={(e) => onActions(e)}
                        editInfo={onEdit}
                        deleteInfo={onDelete}
                        pageNumber={onPageChange} activePageNumber={activePage} pageNumbers={pageArray}></DataTable>
                </div>}

        </div>
    )
}