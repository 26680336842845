import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { DataTable } from "../../../components/data-table";
import { ObjectDataGridCols } from "./data-grid-cols";
import { useLocation } from "react-router-dom";
import { GET_OBJECT, DELETE_OBJECT } from '../../../app/service/access-object.service';
import { toast } from 'react-toastify';


export const AccessObjectList = () => {
  const [pageArray, setPageNumbers] = useState([]);
  const [activePage, setActivePage] = useState();
  const search = useLocation().search;
  const currentPageNumber: any = new URLSearchParams(search).get("page");
  const [loading, setLoading] = useState(false);
  const [objectList, setObjectList] = useState<any>([]);
  const [accessNew, setAccessNew] = useState<any>([]);
  const history = useHistory();

  useEffect(() => {
    getObjects();
    const accessObjectsData: any = localStorage.getItem("access_objects_data");
    handleAccessObjectsCols(JSON.parse(accessObjectsData));
  }, []);

  function getObjects() {
    const activePageNumber = currentPageNumber > 0 ? currentPageNumber - 1 : 0;
    setLoading(true);
    GET_OBJECT(activePageNumber).then((res: any) => {
      if (res) {
        if (res.status === 'fail') {
          toast.error(res.message);
          setLoading(false);
        } else {
          setActivePage(currentPageNumber ? currentPageNumber : 1);
          const data = res.rows;
          setObjectList(data);

          const pageNumbers = Math.ceil(res.count / 10);
          const pageArray: any = [];
          for (let i = 1; i <= pageNumbers; i++) {
            pageArray.push(i);
          }
          setPageNumbers(pageArray);
          setLoading(false);
        }
      }
    });
  }

  const onPageChange = (data: any) => {

    if (data) {
      history.push(`/dashboard/access-objects?page=${data}`);
    }
    setActivePage(data);
    const pageNumber = data - 1;
    setLoading(true);
    GET_OBJECT(pageNumber).then((res: any) => {
      if (res) {
        if (res.status === 'fail') {
          toast.error(res.message);
          setLoading(false);
        } else {
          const data = res["rows"];
          setObjectList(data);
          setLoading(false);
        }

      }
    });
  }
  const onDeleteObject = (data: any) => {
    setLoading(true);
    const id = data.id;
    DELETE_OBJECT(id).then((res: any) => {
      if (res.status === 'fail') {
        toast.error(res.message);
        setLoading(false);
      } else {
        setLoading(false);
        toast.success("Object Deleted Successfully");
        getObjects();
      }
    });
  }

  const onEditObject = (data: any) => {
    history.push(`/dashboard/access-objects/form/${data.id}`);
  };

  const handleAccessObjectsCols = (accessObjectsList: any) => {
    const preparedData = accessObjectsList?.filter((el: any) => el.object_code === "ACCESS");
    setAccessNew(preparedData);
    if (preparedData?.length > 0) {
      for (let i = 0; i < preparedData[0].child.length; i++) {
        for (let j = 0; j < ObjectDataGridCols.length; j++) {
          if (preparedData[0].child[i].object_code === "ACCESS-OBJECTS-EDIT" && preparedData[0].child[i].p_read === 1) {
            if (ObjectDataGridCols[j].title == "Actions") {
              ObjectDataGridCols[j].isEdit = true;
            }

          }

          if (preparedData[0].child[i].object_code === "ACCESS-OBJECTS-DELETE" && preparedData[0].child[i].p_read === 1) {
            if (ObjectDataGridCols[j].title == "Actions") {
              ObjectDataGridCols[j].isDelete = true;
            }
          }

          if ((preparedData[0].child[i].object_code === "ACCESS-OBJECTS-DELETE" && preparedData[0].child[i].p_read === 1) ||
            (preparedData[0].child[i].object_code === "ACCESS-OBJECTS-EDIT" && preparedData[0].child[i].p_read === 1)) {
            if (ObjectDataGridCols[j].title == "Actions") {

              ObjectDataGridCols[j].canShowColumn = true;
            }
          }
        }
      }
    }

  }
  function onHandleNew() {
    if (accessNew?.length > 0) {
      for (let i = 0; i < accessNew[0].child.length; i++) {
        if (accessNew[0].child[i].object_code === "ACCESS-OBJECTS-NEW" && accessNew[0].child[i].p_read === 1) {
          return true;
        }
      }
    }
  }


  return (
    <div className="row border-top border-primary py-3">
      <div className="row">
        <div className="col-md-10 col-7">
          <h2 className="mt-1 mt-md-0">Access Objects</h2>
        </div>
        {onHandleNew() &&
          <div className="border-primary py-md-3 py-1 text-end col-md-2 col-5 ps-4 ps-md-0">
            {/* <Link
              to={`/dashboard/access-objects/form/0`}
              className="btn btn-primary btn-sm px-5 rounded-12"
            >
              New
            </Link> */}
          </div>
        }
      </div>

      {loading &&
        <div className="text-center p-5">
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      }
      {!loading &&
        <DataTable TableCols={ObjectDataGridCols} tableData={objectList} editInfo={onEditObject} deleteInfo={onDeleteObject}
          activePageNumber={activePage} pageNumber={onPageChange} pageNumbers={pageArray}></DataTable>
      }
    </div>
  );
};
