import React from 'react'
import { Route, Switch } from 'react-router'
import { MLATwoTests } from '../pages/dashboard/modules/mla-two/tests';
import MLATwoAddTest from '../pages/dashboard/modules/mla-two/tests/add';
import MLATwoEditTests from '../pages/dashboard/modules/mla-two/tests/update';
import { MLATwoWorksheet } from '../pages/dashboard/modules/mla-two/worksheets';
import MLATwoAddWorksheet from '../pages/dashboard/modules/mla-two/worksheets/add';
import MLATwoEditWorksheet from '../pages/dashboard/modules/mla-two/worksheets/questions-edit';
import { MLATwoWorksheetInFo } from '../pages/dashboard/modules/mla-two/worksheets/worksheet-info';

const NestedMLATwoList = () => {
    return (
        <Switch>
            <Route path="/dashboard/module/info/MLA-2/:code/:id/worksheet" component={MLATwoWorksheet}></Route>
            <Route path="/dashboard/module/info/MLA-2/:code/:id/worksheet-numbers/:ability_code" component={MLATwoWorksheetInFo}></Route>
            <Route path="/dashboard/module/info/MLA-2/:code/:id/worksheet-add/:worksheetNumber/:ability_code" component={MLATwoAddWorksheet}></Route>
            <Route path="/dashboard/module/info/MLA-2/:code/:id/worksheet-edit/:worksheetNumber/:ability_code" component={MLATwoEditWorksheet}></Route>
            <Route path="/dashboard/module/info/MLA-2/:code/:id/tests" component={MLATwoTests}></Route>
            <Route path="/dashboard/module/info/MLA-2/:code/:id/tests-add/:testNumber" component={MLATwoAddTest}></Route>
            <Route path="/dashboard/module/info/MLA-2/:code/:id/tests-edit/:testNumber" component={MLATwoEditTests}></Route>
        </Switch>
    )
}

export default NestedMLATwoList