import React from 'react'
import { Switch, Route } from 'react-router-dom';
import { ParentDashboardFamilyReport } from '../../pages/parent-dashboard/dashboard/reports/family';
import { ParentDashboardParentReport } from '../../pages/parent-dashboard/dashboard/reports/parent';
import { ParentDashboardStudentReport } from '../../pages/parent-dashboard/dashboard/reports/student';
import { ParentServeyInstructions } from '../../pages/parent-dashboard/dashboard/servey-instructions';
import { ParentServeyQuestions } from '../../pages/parent-dashboard/dashboard/servey-questions';
import { ParentServeySuccessPage } from '../../pages/parent-dashboard/dashboard/servey-success';
import { ParentInformationDashboard } from '../../pages/parent-dashboard/dashboard/view';
import { ParentViewStudentMathsReport } from '../../pages/parent-dashboard/dashboard/reports/maths/student';

export const ParentDashBoardRoutes = () => {
    return (
        <Switch>
            <Route exact path="/parent-dashboard/view" component={ParentInformationDashboard}></Route>
            <Route exact path="/parent-dashboard/view/servey-instructions/:userCode/:moduleCode" component={ParentServeyInstructions}></Route>
            <Route exact path="/parent-dashboard/view/servey-questions/:userCode/:moduleCode/:language" component={ParentServeyQuestions}></Route>

            {/* // Reports Routing */}

            <Route exact path="/parent-dashboard/view/servy-success/:id/:userCode/:studentCode" component={ParentServeySuccessPage}></Route>
            <Route exact path="/parent-dashboard/view/reports/student" component={ParentDashboardStudentReport}></Route>
            <Route exact path="/parent-dashboard/view/reports/student/:id/:userCode/:studentCode" component={ParentDashboardStudentReport}></Route>
            <Route exact path="/parent-dashboard/view/reports/family" component={ParentDashboardFamilyReport}></Route>
            <Route exact path="/parent-dashboard/view/reports/family/:id/:userCode/:studentCode" component={ParentDashboardFamilyReport}></Route>
            <Route exact path="/parent-dashboard/view/reports/parent" component={ParentDashboardParentReport}></Route>
            <Route exact path="/parent-dashboard/view/reports/parent/:id/:userCode/:studentCode" component={ParentDashboardParentReport}></Route>
            <Route exact path="/parent-dashboard/view/maths/reports/student/:userCode" component={ParentViewStudentMathsReport}></Route>

        </Switch>
    )
}