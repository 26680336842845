import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { Link, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { GET_MLA_TWO_COMPLETE_INFO } from '../../../../app/service/mla-two.service';

export const MLATwoAbilityResultData = () => {
    const [mlaInfo, setMlaInfo] = useState(null);
    const [sectionData, setSectionData] = useState([]);
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    let { moduleUUID, userCode, schoolCode } = useParams<{ moduleUUID: string, userCode: string, schoolCode: string }>();
    const modules_uuid = moduleUUID;
    const user_code = userCode;
    const school_code = schoolCode;

    useEffect(() => {
        getMLATwoInfo();
    }, []);

    function getMLATwoInfo() {
        setLoading(true);
        let payload = {
            "module_uuid": modules_uuid,
            "school_code": school_code,
            "user_code": user_code,
            "cycle_no": ''
        }

        GET_MLA_TWO_COMPLETE_INFO(payload).then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res.message);
                setLoading(false);
            } else {
                if (res) {
                    const data = res;
                    setLoading(false);
                    setMlaInfo(data);
                    loadResults(data.test_results);
                }
            }
        });
    }

    const loadResults = (test_results: any) => {
        let topic_data: any = [];
        test_results.forEach((item: any) => {
            let topic = topic_data.find((a: any) => a === item.topic);
            if (!topic) {
                topic_data.push(item.topic);
            }
        });

        let sectionList: any = [];
        topic_data.forEach((item: any) => {
            let data = test_results.filter((topic: any) => topic.topic === item);
            sectionList.push({
                title: item,
                data: data
            });
        });

        setSectionData(sectionList);
    }

    const getFieldColor = (value: any) => {
        if (value === 0) {
            return 'red';
        } else if (value === 1 || value === 2) {
            return '#ED7D31';
        } else if (value === 3) {
            return '#8cc63f';
        } else {
            return '#333333';
        }
    }

    const handleBack = () => {
        history.push(`/student-dashboard/list/mns/view/${modules_uuid}/${user_code}/${school_code}`);
    }

    function getSolvedWorkSheetNumbers(count: any) {
        if (count.length > 0) {
            return count[0].worksheets;
        } else {
            return '';
        }
    }

    return (
        <div>
            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading && <section className="mb-4  px-3 px-md-0">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb pb-2 ps-0 pt-0 border-bottom">
                        <li className="breadcrumb-item me-2"> <Link to="/student-dashboard/list">Dashboard</Link></li>  /
                        <li className="breadcrumb-item"> <Link to={`/student-dashboard/list/mns/view/${modules_uuid}/${user_code}/${school_code}`} >Modules </Link></li> /
                        <li className="breadcrumb-item">MLA-2</li>
                    </ol>
                </nav>
                <div className="bg-white rounded-16 container">
                    <div className="mt-3">
                        <h2 className="f22 fw-bold">MLA-2<span className="fw-light">(Minimum Learning Ability)</span></h2>
                    </div>
                    <div className="p-3 time-block-app mt-3 d-md-flex justify-content-between align-items-center no-min-height">
                        <div>
                            <h2 className="m-0 f22 fw-normal">Abilities wise entry test results</h2>
                        </div>
                    </div>
                </div>
                <div className="bg-white px-3 mt-5">

                    {/* <div className="row mb-0">
                                <div className='col-md-6'>
                                    <span className='fw-bold'>Ability wise entry test results</span>
                                </div>
                                <div className='col-md-6' style={{ textAlign: 'right' }}>
                                    <button className="btn btn-primary btn-sm px-5 rounded-12 cursor-pointer" onClick={handleBack}>Back</button>
                                </div>
                            </div> */}
                    <div className="mt-3 custom-scroll table-responsive">
                        <table className="table table_new_ui ability-table mb-0">
                            <thead>
                                <tr>
                                    <th>Ability</th>
                                    <th>Test1 marks</th>
                                    <th>Test2 marks</th>
                                    <th>Test3 marks</th>
                                    <th>Combine Score</th>
                                    <th>Worksheets to be solved</th>
                                </tr>
                            </thead>
                            <tbody>
                                {sectionData.map((detail: any, i: number) => (
                                    <React.Fragment key={i}>
                                        <tr style={{ textAlign: 'initial' }}>
                                            <td className='bg-tpc' colSpan={6}>{detail.title}</td>
                                        </tr>
                                        {
                                            detail.data.length > 0 && detail.data.map((item: any, j: number) => (
                                                <tr key={j}>
                                                    <td>{item.name}</td>
                                                    <td style={{ fontSize: 11, fontWeight: '600', color: getFieldColor(item.test_1) }}>{item.test_1}</td>
                                                    <td style={{ fontSize: 11, fontWeight: '600', color: getFieldColor(item.test_2) }}>{item.test_2}</td>
                                                    <td style={{ fontSize: 11, fontWeight: '600', color: getFieldColor(item.test_3) }}>{item.test_3}</td>
                                                    <td style={{ fontSize: 11, fontWeight: '600', color: getFieldColor(item.average) }}>{item.average}</td>
                                                    <td style={{ fontSize: 11, fontWeight: '600', color: getFieldColor(getSolvedWorkSheetNumbers(item.worksheets)) }}>{getSolvedWorkSheetNumbers(item.worksheets)}</td>
                                                </tr>
                                            ))
                                        }
                                    </React.Fragment>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </section>}
        </div>
    )
}