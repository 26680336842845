import { DataTableCol } from "../../../components/data-table/types";

export const ModuleDataGridCols: DataTableCol[] = [
  {
    title: "Name",
    control: "module_name",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "Module Code",
    control: "module_code",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "Description",
    control: "module_description",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "Sub Module",
    control: "sub_module",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "Target Standard",
    control: "target_std",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "Version",
    control: "module_ver",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "Amount",
    control: "amount",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "Additional Child Discount",
    control: "offer_amount",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "List Price",
    control: "list_price",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "Assigned Date",
    control: "created_at",
    sortable: true,
    canShowColumn: true,
  },

  {
    title: "Actions",
    control: "modules_Info",
    editModule: false,
    isDelete: false,
    canShowColumn: false,
  },
];
