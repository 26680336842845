import { DataTableCol } from "../../../components/data-table/types";

export const ModuleDataGridCols: DataTableCol[] = [
  {
    title: "Name",
    control: "name_of_organization",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "Email",
    control: "email_id",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "Contact Number",
    control: "contact_number",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "State",
    control: "state",
    sortable: true,
    canShowColumn: true,
  },

  {
    title: "District",
    control: "district",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "Area/Locality",
    control: "area",
    sortable: true,
    canShowColumn: true,
  },

  {
    title: "Date of Registration",
    control: "date_of_registration",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "Subscription Fees",
    control: "subscription_price",
    sortable: true,
    canShowColumn: true,
  },

  {
    title: "Actions",
    control: "one",
    isEdit: false,
    isDelete: false,
    canShowColumn: false,
  },
];
