import API from "../utility/axios";
const apiUrl = '/api/v1';

export const LOOK_UP_DATA = (): Promise<any[]> => {
    return API.get(`${apiUrl}/lookup`);
}





