import React, { useEffect, useState } from 'react'


function MthsAnalysys() {

    return (
        <>
            view Report

        </>
    )


}
export default MthsAnalysys