import React from 'react';
import { NavLink } from 'react-router-dom';
import SponsorReportRoutes from './routes';

const SponsorReportPage = (props: any) => {

    return (
        <div>
            <SponsorReportRoutes {...props} />
        </div>
    );
};

export default SponsorReportPage;