import React, { useEffect, useState } from 'react'
import { Link, NavLink, useParams } from 'react-router-dom'
import { toast } from 'react-toastify';
import { GET_MODULES, GET_MODULES_BY_SCHOOL_ID, UPDATE_SCHOOL_MODULE } from '../../../../app/service/module.service';
import { DataTable } from '../../../../components/data-table';
import { ModuleDataGridCols } from './data-grid-cols';
import moment from 'moment';
import FormBuilder from '../../../../components/form-builder';
import { FormValidator } from '../../../../components/form-builder/validations';
import { FormControlError, FormField, FormValidators } from '../../../../components/form-builder/model/form-field';

export const SchoolModulesList = () => {
    let { id, schoolCode } = useParams<{ id: string, schoolCode: string }>();
    const schoolId = parseInt(id);
    const schoolsCode = schoolCode;
    const [modulesData, setModulesData] = useState<any[]>([]);
    const [currentModuleData, setCurrentModuleData] = useState<any>({});
    const [pageArray, setPageNumbers] = useState([]);
    const [activePage, setActivePage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [userRole, setUserRole] = useState<any>();
    const [canShowPopup, setCanShowPopup] = useState(false);
    const [assignModuleLoading, setAssignModuleLoading] = useState(false);
    const [assignUserValidationErrors, setAssignUserValidationErrors] = useState<FormControlError[]>([]);
    const [schoolModuleData, setSchoolModuleData] = useState<any>({});
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [accessObjectsList, setAccessObjectsList] = useState<any>([]);
    const [schoolModule, setSchoolModule] = useState(false);
    const formValidations = [
        new FormField('module_code', []),
        new FormField('sub_module', []),
        new FormField('module_ver', []),
        new FormField('target_std', []),
        new FormField('amount', []),
    ];

    useEffect(() => {
        const userRole = localStorage.getItem("user_role");
        setUserRole(userRole);
        getModules();
        const accessObjectsData: any = localStorage.getItem("access_objects_data");
        setAccessObjectsList(JSON.parse(accessObjectsData));
        handleSchoolModuleCols(JSON.parse(accessObjectsData));

    }, []);

    function getModules() {
        setLoading(true);
        GET_MODULES_BY_SCHOOL_ID(schoolsCode).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    const data = res.rows;
                    data.forEach((element: any) => {
                        element.created_at = moment(element.created_at.split("T")[0]).format('DD-MM-yyyy');
                    });
                    setModulesData(data);
                    const pageNumbers = Math.ceil(res.count / 10);
                    const pageArray: any = [];
                    for (let i = 1; i <= pageNumbers; i++) {
                        pageArray.push(i);
                    }
                    setPageNumbers(pageArray);
                    setLoading(false);
                }
            }
        });
    }

    const handleInput = (data: any) => {
        setSchoolModuleData(data);
        const errors: any = FormValidator(formValidations, data.value);
        setAssignUserValidationErrors(errors);
    }

    const onSearchText = (data: any) => {
    };

    const onPageChange = (data: any) => {
        setActivePage(data);
    }

    const onEditModules = (data: any) => {
        setCanShowPopup(true);
        setCurrentModuleData({ ...data });
        setAssignModuleLoading(true);
        setTimeout(() => {
            setAssignModuleLoading(false);
        }, 1000);
    }

    const handleclose = () => {
        setCanShowPopup(false);
        setCurrentModuleData({});
    }

    const handleSubmit = () => {
        const modulesData = schoolModuleData.value ? { ...schoolModuleData.value } : { ...currentModuleData };
        setIsFormSubmitted(true);
        const errors: FormControlError[] = FormValidator(formValidations, modulesData);
        const payload = {
            id: currentModuleData.id,
            school_code: schoolsCode,
            module_code: currentModuleData.module_code,
            sub_module: currentModuleData.sub_module,
            module_ver: currentModuleData.module_ver,
            target_std: currentModuleData.target_std,
            amount: modulesData.amount ? parseInt(modulesData.amount) : currentModuleData.amount,
        }
        setAssignModuleLoading(true);
        UPDATE_SCHOOL_MODULE(payload).then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res.message);
                setAssignModuleLoading(false);
            } else {
                toast.success("Module Updated Successfully");
                setAssignModuleLoading(false);
                setCanShowPopup(false);
                getModules();
            }
        });
    }

    const handleSchoolModuleCols = (accessObjectsList: any) => {
        const preparedData = accessObjectsList?.filter((el: any) => el.object_code === "SCHOOLS");
        if (preparedData?.length > 0) {
            for (let i = 0; i < preparedData[0].child.length; i++) {
                for (let j = 0; j < ModuleDataGridCols.length; j++) {
                    if (preparedData[0].child[i].object_code === "SCHOOLS-MODULE-EDIT" && preparedData[0].child[i].p_read === 1) {
                        if (ModuleDataGridCols[j].title == "Actions") {
                            ModuleDataGridCols[j].isEdit = true;
                            ModuleDataGridCols[j].canShowColumn = true;

                        }

                    }
                    if (
                        preparedData[0].child[i].object_code === "SCHOOLS-MODULE" &&
                        preparedData[0].child[i].p_read === 1
                    ) {
                        setSchoolModule(true);
                    }


                }
            }
        }


    }

    return (
        <div>
            <div className={`modal fade ${canShowPopup ? 'show' : 'false'}`} style={{ display: (canShowPopup ? "block" : 'none') }} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog assign-user-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Update Module</h5>
                            <button type="button" className="close popup-close cursor-pointer" data-dismiss="modal" aria-label="Close" onClick={handleclose}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            {assignModuleLoading &&
                                <div className="text-center p-5">
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div>
                            }
                            {!assignModuleLoading && <FormBuilder onUpdate={handleInput}>
                                <form>
                                    <div className="row custom-form">
                                        <div className="col-md-6">
                                            <div className="mb-4">
                                                <label className="form-label mb-0">Module Code</label>
                                                <input className="form-control form-control-lg" type="text" disabled name="module_code" defaultValue={currentModuleData?.module_code} />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="mb-4">
                                                <label className="form-label mb-0">Sub Module</label>
                                                <input className="form-control form-control-lg" type="text" disabled name="sub_module" defaultValue={currentModuleData?.sub_module} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row custom-form">
                                        <div className="col-md-6">
                                            <div className="mb-4">
                                                <label className="form-label mb-0">Module Version</label>
                                                <input className="form-control form-control-lg" type="text" disabled name="module_ver" defaultValue={currentModuleData?.module_ver} />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="mb-4">
                                                <label className="form-label mb-0">Target Standard</label>
                                                <input className="form-control form-control-lg" type="text" disabled name="target_std" defaultValue={currentModuleData?.target_std} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row custom-form">
                                        <div className="col-md-6">
                                            <div className="mb-4">
                                                <label className="form-label mb-0">Price</label>
                                                <input className="form-control form-control-lg" type="text" name="amount" defaultValue={currentModuleData?.amount} />
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </FormBuilder>}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary cursor-pointer" data-dismiss="modal" onClick={handleclose}>Close</button>
                            <button type="button" className="btn btn-primary cursor-pointer" onClick={handleSubmit}>Update</button>
                        </div>
                    </div>
                </div>
            </div>
            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading &&
                <>
                    {schoolModule && <DataTable TableCols={ModuleDataGridCols} tableData={modulesData}
                        editInfo={onEditModules}
                        searchText={onSearchText} pageNumber={onPageChange} activePageNumber={activePage} pageNumbers={pageArray}></DataTable>}
                </>
            }
        </div>
    )
}
