import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom';
export const ReportsView = () => {
    // pdfjs.GlobalWorkerOptions.workerSrc =
    //     `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    let { id, userCode, studentCode } = useParams<{ id: string, userCode: string, studentCode: string }>();
    const [userRole, setUserRole] = useState<any>();

    const moduleId = Number(id);
    const usersCode = userCode;
    const studentsCode = studentCode;
    const history = useHistory();

    useEffect(() => {
        const userRole = localStorage.getItem("user_role");
        setUserRole(userRole);
    }, []);
    // const pageNumber = 1;
    const handleReportChange = (e: any) => {
        const type = e.target.value;
        if (type === "student") {
            history.push(`/dashboard/reports/student/${moduleId}/${usersCode}/${studentsCode}`);
        } else if (type === "father") {
            history.push(`/dashboard/reports/parent/${moduleId}/${usersCode}/${studentsCode}`);
        } else if (type === "mother") {
            history.push(`/dashboard/reports/family/${moduleId}/${usersCode}/${studentsCode}`);
        }
    }

    const handleBack = () => {
        history.goBack();
    }

    return (
        <div className="row border-top border-primary py-3">
            <div className="col-md-3"><h2>Reports Info</h2></div>
            {moduleId > 0 && <div className="col-md-3">
                <div className="mb-4">
                    <select name="type" className="form-select form-select-lg mb-3" onChange={(e) => { handleReportChange(e) }}>
                        <option value="">Select </option>
                        <option value="student">Student Report</option>
                        {userRole !== 'STUDENT' && <option value="father">Father Report</option>}
                        {userRole !== 'STUDENT' && <option value="mother">Mother Report</option>}
                    </select>
                </div>
            </div>}
            {moduleId > 0 && <div className="col-md-6">
                <button className="btn btn-primary btn-sm px-4  rounded-12 cursor-pointer" style={{ float: 'right' }} onClick={handleBack}>Back</button>
            </div>}
        </div>
    )
}