import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import Logo from "../../../assets/images/Logo-final.png";
import { FECTH_SPONSORS_DATA, UPDATE_SPONSORS_DATA } from "../../../app/service/sponsor";
import { toast } from "react-toastify";
import VIEWICONS from '../../../institute-dashboard-assets/images/view-icons.svg'
import { CREATE_PAYMENT, UPDATE_PAYMENT } from "../../../app/service/payment-service";
import useRazorpay, { RazorpayOptions } from "react-razorpay";
import appConfig from "../../../config/constant";
import { ADD_UPDATE_SUBSCRIPTION_PRICE } from "../../../app/service/subscription.service";
import { UI_BASE_URL } from "../../../config/constant";
import { APPLY_COUPON_CODE, UPDATE_APPLIED_COUPON_CODE, UPDATE_PROMO_CODES } from "../../../app/service/promotions";
import moment from "moment";
import { CREATE_CCAVENUE_PAYMENT } from "../../../app/service/ccavenue.service";

export const SopnsorPaymentList = () => {
    const Razorpay = useRazorpay();
    const search = useLocation().search;
    let { sponsorUUID } = useParams<{ sponsorUUID: string }>();
    // const sponsorUuid: any = new URLSearchParams(search).get('uuid');
    const sponsorUuid = sponsorUUID;
    const [sponsorData, setSponsorData] = useState<any>([]);
    const [loading, setLoading] = useState(false);
    const [canShowStudentsPopup, setCanStudentsPopup] = useState(false);
    const [studentDetails, setStudentDetails] = useState([]);
    const [transactionId, SettransactionId] = useState<any>("");
    const [canShowPaymentDetailsPopup, setCanShowPaymentDetailsPopup] = useState(false);
    const [amount, setAmount] = useState<any>("");
    const history = useHistory();
    const [isExpired, setIsExpired] = useState(false);
    const [showCouponDiv, setShowCouponDiv] = useState(false);
    const [couponCodeValue, setCouponCodeValue] = useState<any>("");
    const [couponData, setCouponData] = useState<any>({});
    const [couponLoading, setCouponLoading] = useState(false);
    const [totalStudents, setTotalStudents] = useState<any>();
    const [todayDate, setTodayDate] = useState(moment().format('YYYY-MM-DD'));
    const RAZORPAY_OPTIONS: RazorpayOptions = {
        key: appConfig.paymentKey,
        amount: (amount + "000"),
        currency: "INR",
        name: "IAMSAMARTH",
        description: "Subscription Fee",
        image: "",//MSB LOGO
        order_id: "",

        notes: {
            address: "Razorpay Corporate Office",
        },
        theme: {
            color: "#3399cc",
        },
    };

    useEffect(() => {
        getSponsorData();

        // ccavenue //
        const paymentStatus: any = new URLSearchParams(search).get('status');
        const sponsorInfo = JSON.parse(localStorage.getItem('cc_sponsor_data') || '{}');
        const txnId = localStorage.getItem("cc_transaction_id");
        const couponsData = JSON.parse(localStorage.getItem("cc_coupon_data") || '{}');
        if (txnId && paymentStatus === "success") {
            sponsorUpdate(txnId, sponsorInfo, couponsData);
        } else {
            removeItemsFromeLocalStorage();
        }
    }, [])
    function getSponsorData() {
        setLoading(true);
        FECTH_SPONSORS_DATA(sponsorUuid).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res?.message);
                    setLoading(false);
                }
                else {
                    const data = res.rows[0];
                    localStorage.setItem("cc_sponsor_data", JSON.stringify(data));
                    setSponsorData(data);
                    if (data?.expiry_dt) {
                        let expiredDate = new Date(data?.expiry_dt) < new Date();
                        setIsExpired(expiredDate);
                    }

                    let total = 0;
                    for (let i = 0; i < data?.students?.length; i++) {
                        total = total + data?.students[i]?.total_students;
                    }
                    setTotalStudents(total)
                    setLoading(false);
                }
            }

        });
    }
    const handleStudentsClose = () => {
        setCanStudentsPopup(false);
    };
    const getStudentsDetails = (data: any) => {
        setCanStudentsPopup(true);
        setStudentDetails(data?.students);
    }
    const handlePayment = () => {

        createPayment(sponsorData?.amount, sponsorData);
    }
    function createPayment(amount: any, subscriptionData: any) {
        const subscriptionAmount = couponData?.final_amount ? couponData?.final_amount : amount;
        setAmount(subscriptionAmount);
        const payload = {
            school_code: '',
            user_ids: null,
            sub_amount: Math.round(subscriptionAmount)
        }

        if (amount > 0) {
            CREATE_PAYMENT(payload).then((res: any) => {
                if (res) {
                    if (res.status === 'fail') {
                        toast.error(res.message);
                    } else {
                        SettransactionId(res.txn_id);
                        if (couponData?.final_amount == 0 && Object.keys(couponData?.rule_applied).length > 0) {
                            sponsorUpdate(res.txn_id);
                            // updateCouponCode(res.txn_id);
                            updateAppliedCoupon(res.txn_id, couponData);
                        } else {
                            onOpenPaymentInfo(payload.sub_amount, res.txn_id, subscriptionData);
                        }
                    }
                }
            });
        }

    }
    function onOpenPaymentInfo(amount: any, txn_id: string, subscriptionData: any) {
        localStorage.setItem("cc_transaction_id", txn_id);
        if (amount > 0) {
            createCcavenuePayment(amount, txn_id);
            // RAZORPAY_OPTIONS.amount = amount + "00";
            // RAZORPAY_OPTIONS.handler = (response) => {
            //     if (amount > 0) {
            //         onUpdatePayment(txn_id, response, "success");
            //     }
            // }
            // let razorpay = new Razorpay(RAZORPAY_OPTIONS);

            // razorpay.open();

            // razorpay.on("payment.failed", function (response: any) {
            //     if (amount > 0) {
            //         onUpdatePayment(txn_id, response, "failed");
            //     }
            // });

        } else {
            if (amount > 0) {
                onUpdatePayment(txn_id, { "message": "SUM AMOUNT ZERO" }, "success");
            }
        }
    }
    function onUpdatePayment(transactionId: string, data: any, status: string) {

        const payload = {
            "txn_id": transactionId,
            "txn_details": data,
            "module_code": "",
            "module_uuid": '',
            "status": status,
            "payment_dt": todayDate
        }


        UPDATE_PAYMENT(payload).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                } else {
                    SettransactionId(res.txn_id);
                    setCanShowPaymentDetailsPopup(false);
                    if (status === "success") {
                        sponsorUpdate(payload.txn_id);
                    }
                }
            }
        });
    }

    function sponsorUpdate(txnId: any, sponsorData?: any, couponData?: any) {
        const payload = {
            name: sponsorData?.name,
            code: "STUDENT_SUBSCRIPTION",
            sponsor_name: sponsorData?.sponsor_name,
            sponsor_details: {
                mobile_number: sponsorData?.sponsor_details?.mobile_number,
                email_id: sponsorData?.sponsor_details?.email_id
            },
            payment_status: "completed",
            // txn_id: txnId,
            amount_paid: couponData?.final_amount ? couponData?.final_amount : sponsorData?.amount,
            promocode_uuid: couponData?.promocode_uuid,
            transaction_uuid: txnId

        }
        UPDATE_SPONSORS_DATA(sponsorData?.uuid, payload).then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res.message);
            } else {
                toast.success('Subscription Added Successfully');
                // updateCouponCode(txnId, couponData);
                updateAppliedCoupon(txnId, couponData);
                removeItemsFromeLocalStorage();
                getSponsorData();
            }
        });
    }
    function onApplyCouponValue(e: any) {
        setCouponCodeValue(e.target.value);
    }
    function applyCoupon() {
        setCouponData({});
        setShowCouponDiv(false);
        const payload = {
            promo_code: couponCodeValue,
            students_count: totalStudents,
            payable_amount: sponsorData?.amount,
            institute_code: "",
            school_code: ""
            //  schoolsCode
        }
        if (couponCodeValue) {
            setCouponLoading(true)
            APPLY_COUPON_CODE(payload).then((res: any) => {
                if (res.status === 'fail') {
                    toast.error(res.err);
                    setCouponLoading(false)
                } else {
                    let data = res
                    if (Object.keys(data?.rule_applied).length > 0) {
                        setShowCouponDiv(true);
                        localStorage.setItem("cc_coupon_data", JSON.stringify(data));
                        setCouponData(data);
                    } else {
                        toast.error("Coupon is not applicable");
                    }
                    setCouponLoading(false)
                }
            });
        }
    }
    function updateAppliedCoupon(txnId: any, couponData?: any) {
        const payload = {
            promotion_uuid: couponData?.promotion_uuid ? couponData?.promotion_uuid : "",
            promo_code_uuid: couponData?.promocode_uuid ? couponData?.promocode_uuid : "",
            applied_for_user_code: "",
            applied_sponsor_uuid: sponsorUuid,
            applied_dt: todayDate,
            applied_rules: couponData,
            status: 2
        }
        if ((couponData?.final_amount > 0 || couponData?.final_amount == 0) && Object.keys(couponData?.rule_applied).length > 0) {
            setLoading(true);
            UPDATE_APPLIED_COUPON_CODE(payload).then((res: any) => {
                if (res.status === 'fail') {
                    toast.error(res?.err);
                    setLoading(false);
                } else {
                    setLoading(false);
                }
            });
        }
    }
    function updateCouponCode(txnId: any, couponData?: any) {
        const payload = {
            txn_id: txnId,
            status: 2,
            applied_sponsor_uuid: sponsorUuid,
            applied_dt: todayDate,
            applied_rules: couponData
        }
        if ((couponData?.final_amount > 0 || couponData?.final_amount == 0) && Object.keys(couponData?.rule_applied).length > 0) {
            setLoading(true);
            UPDATE_PROMO_CODES(couponData?.promocode_uuid, payload).then((res: any) => {
                if (res.status === 'fail') {
                    toast.error(res.err);
                } else {
                    setLoading(false);
                }
            });
        }
    }
    const createCcavenuePayment = (amount: any, txn_id: string) => {
        let payLoad = {
            amount: amount,
            currency: "INR",
            transaction_uuid: txn_id,
            redirect_url: `${UI_BASE_URL}/sponsor/paymentdata/${sponsorUuid}`,
            cancel_url: `${UI_BASE_URL}/sponsor/paymentdata/${sponsorUuid}`,
        }
        CREATE_CCAVENUE_PAYMENT(payLoad).then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res?.error);
            } else {
                initiatePayment(res);

            }
        });
    }
    const initiatePayment = async (paymentData: any) => {
        window.location.href = paymentData.url;
    };
    const removeItemsFromeLocalStorage = () => {
        const keysToRemove = ['cc_sponsor_data', 'cc_transaction_id', 'cc_coupon_data'];
        keysToRemove.forEach((key: any) => {
            localStorage.removeItem(key);
        });
    };
    return (
        <div className={`bg-white ${couponData?.final_amount ? "" : "vh-100"}`} style={{ backgroundColor: "#f5fcff", height: "100%" }}>

            <div
                className={`modal fade ${canShowStudentsPopup ? "show" : "false"}`}
                style={{ display: canShowStudentsPopup ? "block" : "none" }}
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog parent-model-dialog" role="document">
                    <div className="modal-content parent-model">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                                Students
                            </h5>
                            <button
                                type="button"
                                className="close popup-close cursor-pointer"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={handleStudentsClose}
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body parent-info parent-model">
                            <div className='table-responsive tableFixHead'>
                                <table className='table table_new_ui fixed-table-header'>
                                    <thead>
                                        <tr>
                                            <th style={{ width: "70px" }}>S.No</th>
                                            <th style={{ width: '120px' }}>Student Name</th>
                                            <th style={{ width: '120px' }}> Standard</th>
                                            <th style={{ width: '90px' }}> Division</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {studentDetails?.map((data: any, i: number) => {
                                            return (
                                                <tr key={i}>
                                                    <td>{i + 1}</td>
                                                    <td> {data.name}</td>
                                                    <td> {data.standard}</td>
                                                    <td>{data.division} </td>
                                                </tr>
                                            )
                                        })}
                                        <tr >
                                            <td></td>
                                            <td> </td>
                                            <td> </td>
                                            <td> </td>
                                        </tr>
                                    </tbody>
                                </table>
                                {studentDetails.length == 0 && <p className="f14 text-center">No Data Available</p>}
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary cursor-pointer"
                                data-dismiss="modal"
                                onClick={handleStudentsClose}
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading &&
                <div>
                    {sponsorData?.payment_status === "completed" &&
                        <div> <img src={Logo} alt="" className="login-logo mt-4 ms-3" />

                            <div className="text-center pt-2 ">
                                <h1 className="text-completed fw-light mt-5"><i className="fa fa-check-circle pe-3"></i>Payment has been succesfully completed !</h1>
                                <Link to={`/`}>  <h1 className="mt-4"><small className="f22  text-mdgray">Click here return to</small> website</h1></Link>
                            </div></div>}
                    {sponsorData?.payment_status === "pending" && <div className="px-3 mx-md-5">
                        <div className="d-md-flex justify-content-md-between">
                        </div>
                        <div className="row  mt-5 justify-content-md-center">
                            <div className="col-12 col-lg-8">
                                <div> <img src={Logo} alt="" className="login-logo" /></div>

                                <h3 className="dkpi-hd mt-5">Sponsor Information</h3>
                                <p className="text-gray f14 mb-1">Name : <span className="f16 ms-2">{sponsorData?.sponsor_name}</span></p>
                                <p className="text-gray f14 mb-3">Mobile No : <span className="f16 ms-2">{sponsorData?.sponsor_details?.mobile_number}</span></p>

                                <div className='table-responsive'>
                                    <table className='table table_new_ui'>
                                        <thead>
                                            <tr>
                                                <th style={{ width: "70px" }}>S.No</th>
                                                <th style={{ width: '120px' }}>Institute Name</th>
                                                <th style={{ width: '120px' }}> School Name</th>
                                                <th style={{ width: '90px' }}> Students</th>
                                                <th style={{ width: '100px' }}> Subscription Price</th>
                                                <th style={{ width: '100px' }}>Total Price</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {sponsorData?.students?.map((data: any, i: number) => {
                                                return (
                                                    <tr key={i}>
                                                        <td>{i + 1}</td>
                                                        <td> {data._organization.name_of_organization}</td>
                                                        <td> {data._school.name_of_school}</td>
                                                        <td><img className='eye-icons me-3 cursor-pointer' src={VIEWICONS} onClick={() => getStudentsDetails(data)} />{data.total_students}</td>
                                                        <td>{data.subscription_price} </td>
                                                        <td>{data.school_payment_amount}</td>
                                                    </tr>
                                                )
                                            })}
                                            <tr>
                                                <td style={{ fontSize: "14px", fontWeight: "bold" }}>Grand Total</td>
                                                <td> </td>
                                                <td></td>
                                                <td></td>
                                                <td> </td>
                                                <td style={{ fontSize: "14px", fontWeight: "bold" }}>{sponsorData?.amount}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="text-end">
                                    {isExpired ? <p className="text-inprogess f18 ">Link is expired </p> :
                                        <button className="btn btn-primary f14 w-md-25 w-sm-100" onClick={handlePayment}>Proceed to Payment</button>}
                                </div>
                                <div className=' row my-3 justify-content-md-center '>
                                    <div className='col-12 col-md-7 mb-5'>
                                        <div className='d-flex'>
                                            <input className='form-control form-control-lg f14 mt-4' placeholder=' Coupon Code' type='text' value={couponCodeValue} onChange={(e) => onApplyCouponValue(e)}></input>
                                            <button type="button" className="btn btn-primary cursor-pointer mt-4 pt-1" onClick={applyCoupon}>Apply</button>
                                        </div>
                                        {couponLoading &&
                                            <div className="text-center p-5">
                                                <div className="spinner-border" role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </div>
                                            </div>
                                        }
                                        {!couponLoading && <div className="bg-white mb-5">
                                            {showCouponDiv && <div className=" shadow-sm p-3 mb-5 rounded-3 bg-light mt-3" >
                                                <h3 className="dkpi-hd f22">Payment Information</h3>
                                                <p className="mb-1"><span className='me-1'>Students Count</span><span className="ms-5 ps-5">{couponData?.students_count}</span></p>
                                                <p className="mb-1">Payable Amount<span className="ms-5 ps-5">{couponData?.payable_amount}</span></p>
                                                <p className="mb-1">Discount Percentage<span className="ms-5 ps-3">{couponData?.discount_percentage?.toFixed()}%</span></p>
                                                <p className="mb-1"><span className='me-3'>Discount Amount</span><span className="ms-5 ps-4">{couponData?.discount_amount?.toFixed()}</span></p>
                                                <p className="text-gray mt-2"> <span className="f18 me-5" style={{ fontWeight: 'bold' }}>Final Amount</span> <span className='ms-5 ps-2' style={{ fontWeight: 'bold' }}> {Math.round(couponData?.final_amount)}</span></p>
                                            </div>}
                                        </div>}

                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>}

                </div>}
        </div>
    )
}