import React, { useEffect, useState } from 'react'
import { Link, NavLink, useHistory, useLocation, useParams } from 'react-router-dom'
import { GET_USER_BY_ID, GET_USER_USER_CODE } from '../../../../app/service/users.service';
import NestedStudent from '../../../../routes/insidestudent';
import { ToastContainer, toast } from 'react-toastify';
import { GET_SCHOOL_BY_SCHOOL_CODE } from '../../../../app/service/school.service';
import appConfig from '../../../../config/constant';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import { DateComponent } from '../../../../components/date';
import { Breadcum } from '../../../../components/breadcum';
import ManageSchoolsNestedStudent from '../../../../routes/manageschoolsinsidestudent';

export const StudentInFo = () => {
    const [isActive, setIsActive] = useState(true);
    const [studentInfo, setStudentInfo] = useState<any>({});
    const [schoolsCode, setSchoolCode] = useState<any>();
    const [studentCode, setStudentCode] = useState<any>();
    const [loading, setLoading] = useState(false);
    const [canShowPopup, setCanShowPopup] = useState(false);
    const [studentLogo, setStudentLogo] = useState<any>();
    const [userRole, setUserRole] = useState<any>();
    const [userType, setUserType] = useState<any>();
    const [schoolInfo, setSchoolInfo] = useState<any>({});
    const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'));
    const [viewMoreInfo, setViewMoreInfo] = useState(false);
    const [studentParent, setStudentParent] = useState(false);
    const [breadcumInfo, setBreadcumInfo] = useState<any>([
        {
            label: 'Schools',
            value: 'Schools',
            routerLink: `/dashboard/school/list`,
            isActive: false
        },
        {
            label: 'School Name',
            value: '',
            routerLink: '',
            isActive: false
        },
        {
            label: 'Students',
            value: 'Students',
            routerLink: '',
            isActive: false
        },
        {
            label: 'Student Name',
            value: '',
            routerLink: '',
            isActive: false
        }
    ]);

    let { id, schoolCode, studentId } = useParams<{ id: string, schoolCode: string, studentId: string }>();
    const schoolId = Number(id);
    const SchoolCode = schoolCode;
    const studentsCode = studentId;
    const location = useLocation();
    const history = useHistory();


    //destructuring pathname from location
    const { pathname } = location;

    //Javascript split method to get the name of the path in array
    const splitLocation = pathname.split("/");



    useEffect(() => {
        const userRole = localStorage.getItem("user_role");
        const userType = localStorage.getItem("user_type");
        setUserRole(userRole);
        setUserType(userType);
        setLoading(true);
        GET_SCHOOL_BY_SCHOOL_CODE(SchoolCode).then((response: any) => {
            if (response.status === 'fail') {
                toast.error(response.message);
                setLoading(false);
            } else {
                const data = response.rows[0];
                setSchoolInfo(data);
                getUserDetails(data);
                setLoading(false);
            }
        });

        const accessObjectsData: any = localStorage.getItem("access_objects_data");
        onHandleNav(JSON.parse(accessObjectsData));
    }, []);


    function getUserDetails(schoolData: any) {
        if (schoolId > 0) {
            GET_USER_USER_CODE(studentsCode).then((response: any) => {
                if (response.status === 'fail') {
                    toast.error(response.message);
                    setLoading(false);
                } else {
                    const data = response.rows[0];
                    getBreadcumList(schoolData, data);
                    setLoading(false);
                    if (data.date_of_birth) {
                        const date_of_birth: any = moment(data?.date_of_birth, 'dd/MMM/YYYY').toDate();
                        setStartDate(date_of_birth);
                    }
                    setStudentInfo(data);
                    setSchoolCode(data.institute_school_code);
                    setStudentCode(data.user_code);
                    setStudentLogo(data.profile_img);
                }
            });
        }
    }
    function getBreadcumList(schoolData: any, data: any) {
        const userRole = localStorage.getItem("user_role");
        if (userRole !== "CHAIRMAN" && userRole !== "GENERAL_SECRETARY" && userRole !== "INSTITUTE_ADMIN") {
            setBreadcumInfo([
                {
                    label: 'Schools',
                    value: 'Schools',
                    routerLink: `/dashboard/school/list`,
                    isActive: false
                },
                {
                    label: 'School Name',
                    value: data.name_of_school,
                    routerLink: `/dashboard/school/info/${schoolData.id}/studentslist/${SchoolCode}`,
                    isActive: false
                },
                {
                    label: 'Students',
                    value: 'Students',
                    routerLink: `/dashboard/school/info/${schoolData.id}/studentslist/${SchoolCode}`,
                    isActive: false
                },
                {
                    label: 'Student Name',
                    value: data.name,
                    isActive: true
                }
            ]);
        } else {
            setBreadcumInfo([
                {
                    label: 'Schools',
                    value: 'Schools',
                    routerLink: `/institute-dashboard/school/list`,
                    isActive: false
                },
                {
                    label: 'School Name',
                    value: data.name_of_school,
                    routerLink: `/institute-dashboard/school/info/${schoolData.id}/studentslist/${SchoolCode}`,
                    isActive: false
                },
                {
                    label: 'Students',
                    value: 'Students',
                    routerLink: `/institute-dashboard/school/info/${schoolData.id}/studentslist/${SchoolCode}`,
                    isActive: false
                },
                {
                    label: 'Student Name',
                    value: data.name,
                    isActive: true
                }
            ]);
        }
    }

    function onClickViewDetail() {
        setCanShowPopup(true);
    }

    const handleclose = () => {
        setCanShowPopup(false);
    }

    const handleBack = () => {
        history.goBack();
    }

    function onHandleNav(accessObjectsList: any) {
        const preparedData = accessObjectsList?.filter((el: any) => el.object_code === "SCHOOLS");
        if (preparedData?.length > 0) {
            for (let i = 0; i < preparedData[0].child.length; i++) {
                if (preparedData[0].child[i].object_code === "SCHOOLS-STUDENTS-VIEW-MORE-INFORMATION" && preparedData[0].child[i].p_read === 1) {

                    setViewMoreInfo(true);
                }
                if (preparedData[0].child[i].object_code === "SCHOOLS-STUDENTS-PARENTS" && preparedData[0].child[i].p_read === 1) {

                    setStudentParent(true);
                }
            }
        }
    }

    return (
        <div className="row border-top border-primary py-3">
            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }

            <div className={`modal fade ${canShowPopup ? 'show' : 'false'}`} style={{ display: (canShowPopup ? "block" : 'none') }} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog parent-model-dialog" role="document">
                    <div className="modal-content parent-model">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Student  Details</h5>
                            <button type="button" className="close popup-close cursor-pointer" data-dismiss="modal" aria-label="Close" onClick={handleclose}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body parent-info">
                            <div className="row custom-form">
                                <div className="col-md-4">
                                    <div className="mb-4">
                                        <label className="form-label mb-0">Name</label>
                                        <input className="form-control form-control-lg" type="text" disabled name="name" defaultValue={studentInfo.name} />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="mb-4">
                                        <label className="form-label mb-2">Date of Birth</label>
                                        {studentInfo.date_of_birth &&
                                            <DateComponent yearDiff={0} idDisable={true} defaultData={studentInfo.date_of_birth}></DateComponent>}
                                        {!studentInfo.date_of_birth && <DateComponent idDisable={true} defaultData={startDate} yearDiff={0}></DateComponent>}
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="mb-4">
                                        <label className="form-label mb-4">Gender</label><br />
                                        <input type="radio" name="gender" checked={studentInfo?.gender === "male"} disabled />
                                        <span style={{ margin: '0px 12px' }}>Male</span>
                                        <input type="radio" name="gender" checked={studentInfo?.gender === "female"} disabled />
                                        <span style={{ margin: '0px 12px' }}>Female</span>
                                        <input className="mb-0" type="radio" value="transgender" name="gender" checked={studentInfo?.gender === "transgender"} disabled />
                                        <span style={{ paddingRight: '15px', paddingLeft: '15px' }}>Other</span>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="mb-4">
                                        <label className="form-label mb-0">Standard</label>
                                        <select name="state" className="form-select form-select-lg mb-3" disabled>
                                            <option value="">{studentInfo?.standard}</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="mb-4">
                                        <label className="form-label mb-0">Division</label>
                                        <select name="state" className="form-select form-select-lg mb-3" disabled>
                                            <option value="">{studentInfo?.division}</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="mb-4">
                                        <label className="form-label mb-0">Email ID</label>
                                        <input className="form-control form-control-lg" type="text" disabled name="email_id" defaultValue={studentInfo.email_id} />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="mb-4">
                                        <label className="form-label mb-0">Mobile Number</label>
                                        <input className="form-control form-control-lg" type="number" disabled name="mobile_number" defaultValue={studentInfo.mobile_number} />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="mb-4">
                                        <label className="form-label mb-0">WhatsApp Number</label>
                                        <input className="form-control form-control-lg" type="number" disabled name="whatsapp_number" defaultValue={studentInfo.whatsapp_number} />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="mb-4">
                                        <label className="form-label mb-0">Blood Group</label>
                                        <input className="form-control form-control-lg" type="text" disabled name="blood_group" defaultValue={studentInfo.blood_group} />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="mb-4">
                                        <label className="form-label mb-0">Address</label>
                                        <input className="form-control form-control-lg" type="textarea" disabled defaultValue={studentInfo?.address} name="address" />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="mb-4">
                                        <label className="form-label mb-0">State</label>
                                        <select name="state" className="form-select form-select-lg mb-3" disabled>
                                            <option value="">{studentInfo?.state}</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="mb-4">
                                        <label className="form-label mb-0">District</label>
                                        <select name="district" className="form-select form-select-lg mb-3" disabled>
                                            <option value="">{studentInfo?.district}</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="mb-4">
                                        <label className="form-label mb-0">Block</label>

                                        <input className="form-control form-control-lg" type="text" disabled defaultValue={studentInfo?.taluka} name="taluka" />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="mb-4">
                                        <label className="form-label mb-0">City/Village (Optional)</label>
                                        <input className="form-control form-control-lg" type="text" disabled name="city_village" defaultValue={studentInfo?.city_village} />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="mb-4">
                                        <label className="form-label mb-0">PIN Code</label>
                                        <input className="form-control form-control-lg" type="number" disabled name="pin_code" defaultValue={studentInfo?.pin_code} />
                                    </div>
                                </div>
                                {studentLogo && <div className="col-md-4">
                                    <div className="mb-4">
                                        <label className="form-label mb-0 d-block">Preview Photo</label>
                                        <img src={`${appConfig.FILE_UPLOAD_URL}${studentLogo.charAt(0) === "/" ? studentLogo.substring(1) : studentLogo}`} style={{ height: '100px', width: '100px', objectFit: 'contain' }} />
                                    </div>
                                </div>}
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary cursor-pointer" data-dismiss="modal" onClick={handleclose}>Close</button>
                        </div>
                    </div>
                </div>
            </div>
            {!loading && <div>
                {(userRole === "STUDENT" && userType !== 'individual') && <div>

                    <div>
                        <h2>School Details</h2>
                    </div>

                    <div className="mar-t-20 custom-scroll table-responsive">
                        <table className="table fixed-table-header mb-0">
                            <thead>
                                <tr>
                                    <th>School Name</th>
                                    <th>Contact Number</th>
                                    <th>Email ID</th>
                                    <th>City</th>
                                    <th>State</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{schoolInfo?.name_of_school}</td>
                                    <td>{schoolInfo?.contact_number}</td>
                                    <td>{schoolInfo?.email_id}</td>
                                    <td>{schoolInfo?.taluka}</td>
                                    <td>{schoolInfo?.state}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>}
                <div>
                    {(userRole !== "STUDENT") && <Breadcum breadcumList={breadcumInfo} />}
                    {userRole === "STUDENT" && <h2 className='mt-4'>Student Details</h2>}
                    <div className="mar-t-20 custom-scroll table-responsive">
                        <table className="table fixed-table-header mb-0">
                            <thead>
                                <tr>
                                    <th>Student Name</th>
                                    <th>Mobile Number</th>
                                    <th>Email ID</th>
                                    <th>City</th>
                                    <th>State</th>
                                    {viewMoreInfo && <th>Action</th>}
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{studentInfo.name}</td>
                                    <td>{studentInfo.mobile_number}</td>
                                    <td>{studentInfo.email_id}</td>
                                    <td>{studentInfo.taluka}</td>
                                    <td>{studentInfo.state}</td>
                                    {viewMoreInfo && <td>
                                        <button className="file btn btn-sm btn-primary px-4 rounded-12 d-inline-block cursor-pointer" onClick={() => onClickViewDetail()}>View More Info</button>
                                    </td>}
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="mt-5">
                    {userRole !== "CHAIRMAN" && userRole !== "GENERAL_SECRETARY" && userRole !== "INSTITUTE_ADMIN" &&

                        <ul className="nav nav-tabs custom-tabs">
                            {userRole === "STUDENT" && <li className="nav-item">
                                <NavLink className={splitLocation[6] == "modulelist" ? "nav-link active" : "nav-link"} to={{ pathname: `/dashboard/school/student/info/${schoolId}/modulelist/${schoolCode}/${studentCode}`, state: { data: "" } }}>Modules</NavLink>
                            </li>}
                            {studentParent && <li className="nav-item">
                                <NavLink className={splitLocation[6] === "parentform" ? "nav-link active" : "nav-link"} to={{ pathname: `/dashboard/school/student/info/${schoolId}/parentlist/${schoolCode}/${studentCode}`, state: { data: "" } }}>Parent's</NavLink>
                            </li>}
                        </ul>
                    }

                    {(userRole === "CHAIRMAN" || userRole === "GENERAL_SECRETARY" || userRole === "INSTITUTE_ADMIN") &&
                        <ul className="nav nav-tabs custom-tabs">
                            {userRole === "STUDENT" && <li className="nav-item">
                                <NavLink className={splitLocation[6] == "modulelist" ? "nav-link active" : "nav-link"} to={{ pathname: `/institute-dashboard/school/student/info/${schoolId}/modulelist/${schoolCode}/${studentCode}`, state: { data: "" } }}>Modules</NavLink>
                            </li>}
                            {studentParent && <li className="nav-item">
                                <NavLink className={splitLocation[6] === "parentform" ? "nav-link active" : "nav-link"} to={{ pathname: `/institute-dashboard/school/student/info/${schoolId}/parentlist/${schoolCode}/${studentCode}`, state: { data: "" } }}>Parent's</NavLink>
                            </li>}
                        </ul>
                    }
                    <div>
                        {(userRole === "CHAIRMAN" || userRole === "GENERAL_SECRETARY" || userRole === "INSTITUTE_ADMIN") &&
                            <ManageSchoolsNestedStudent />
                        }
                        <NestedStudent />
                    </div>
                </div>
            </div>}
        </div>
    )
}

