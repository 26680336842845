import React, { useEffect, useRef, useState } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'ckeditor5-classic-with-mathtype';
import { MathJaxContext, MathJax } from 'better-react-mathjax';
import { Breadcum } from '../../../../../components/breadcum';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CREATE_ABHYAS_QUESTION, DELETE_ABHYAS_QUESTION, GET_ABHYAS_QUESTION_LIST, UPDATE_ABHYAS_QUESTION } from '../../../../../app/service/abhyas-question.service';
import moment from 'moment';
import { UPLOAD_LOGO } from '../../../../../app/service/school.service';
import { GET_WORKSHEET_BY_UUID } from '../../../../../app/service/abhyas-worksheet.service';
import { GET_CHAPTER_FILTERS_LIST, GET_TOPIC_FITERS_LIST } from '../../../../../app/service/abhyas-worksheet-filter.service';
import { GET_CHAPTER_FILTER_LIST, GET_TOPIC_FITER_LIST } from '../../../../../app/service/common-module.service';

// import { Image, ImageCaption, ImageResize, ImageStyle, ImageToolbar } from '@ckeditor/ckeditor5-image';
// import { LinkImage } from '@ckeditor/ckeditor5-link';
interface Props {
    isLinked: string;
    display: string;
    boardUuid?: any;
    mediumUuid?: any;
    standardUuid?: any;
    subjectUuid: any;
    chapterUUid?: any;
    sheetUuid: string;
    updateList?: (e: string) => void;
    selectTest?: (e: string) => void;
    setBackQuestionsList?: (e: string) => void;
}
const ParikshaQuestionsList: React.FC<Props> = (props: Props) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [activePage, setActivePage] = useState(0);
    const [questionInfo, setQuestionInfo] = useState<any>({});
    const [questionsList, setQuestionsList] = useState<any>([]);
    const [pageArray, setPageNumbers] = useState([]);
    const [canShowPopup, setCanShowPopup] = useState<boolean>(false);
    const [questionTypeError, setQuestionTypeError] = useState<string>('');
    const [selectedOption, setSelectedOption] = useState<string>('multiple_choice_questions');
    const [questionImg, setQuestionImg] = useState<any[]>([]);
    const [imageLoader, setImageLoader] = useState(false);
    const [questionUuid, setQuestionUuid] = useState<string>('');
    // const [matchRightOptions, setMatchRightOptions] = useState<any[]>([]);
    // const [matchLeftOptions, setMatchLeftOptions] = useState<any[]>([]);
    // const [selectColumOption, setSelectColumOption] = useState<string>('');
    const [questionsData, setQuestionsData] = useState<any>([]);
    const [updateQuestionsData, setUpdateQuestionsData] = useState<any>([]);
    const [canShowCkeditor, setCanShowCkeditor] = useState(false);
    const [inputData, setInputData] = useState<any>();
    const [questionData, setQuestionData] = useState<any>();
    const [canShowCKeditorPopup, setCanShowCKeditorPopup] = useState(false);
    const [canShowCKeditorAddPopup, setCanShowCKeditorAddPopup] = useState(false);
    const [loadingCkeditor, setLoadingCkeditor] = useState(false);
    const [currentQuestionData, setCurrentQuestionData] = useState<any>([]);
    const [questionIndex, setQuestionIndex] = useState<any>(null);
    const [optionIndex, setOptionIndex] = useState<any>(null);
    const [correctAnswer, setCorrectAnswer] = useState<any>('');
    const [editor, setEditor] = useState<any>();
    const [questionNumber, setQuestionNumber] = useState<any>('');
    const [questionLevel, setQuestionLevel] = useState<string>("MEDIUM");
    const [questionExplanation, setQuestionExplanation] = useState<string>("");
    const [selectChapterUuid, setSelectChapterUuid] = useState<string>("");
    const [topicList, setTopicList] = useState<any>([]);
    const [selectTopicUuid, setSelectTopicUuid] = useState<string>("");
    const [topicError, setTopicError] = useState<string>("");
    const [canShowExplanation, setCanShowExplanation] = useState(false);
    const [alphabets, setAlphabets] = useState<any>(["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z"]);
    const history = useHistory();
    const listRef: any = useRef(null);
    const editorRef = useRef(null);
    const [pTagContent, setPTagContent] = useState('');
    const [figureElements, setFigureElements] = useState([]);
    const [chapterList, setChapterList] = useState<any>([]);
    const [chapterError, setChapterError] = useState<string>("");
    let { code, id, BoardUuid, BoardName, MediumUuid, MediumName, StandardUuid, StandardName, SubjectUuid, SubjectName, ChapterUuid, ChapterName, TopicUuid, TopicName, SheetUuid, SheetNo, Type } = useParams<{ code: string, id: string, BoardUuid: string, BoardName: string, MediumUuid: string, MediumName: string, StandardUuid: string, StandardName: string, SubjectUuid: string, SubjectName: string, ChapterUuid: string, ChapterName: string, TopicUuid: string, TopicName: string, SheetUuid: string, SheetNo: string, Type: string }>();

    const moduleCode = code;
    const moduleId = Number(id);
    const boardUuid = BoardUuid ? BoardUuid : props.boardUuid;
    const mediumUuid = MediumUuid ? MediumUuid : props.mediumUuid;
    const standardUuid = StandardUuid ? StandardUuid : props.standardUuid;
    const subjectUuid = SubjectUuid ? SubjectUuid : props.subjectUuid;
    const chapterUuid = ChapterUuid;
    const topicUuid = TopicUuid;
    const sheetUuid = SheetUuid ? SheetUuid : props.sheetUuid;
    const sheetNo = SheetNo;
    const sheetType = Type;
    const parser = new DOMParser();
    const [breadcumInfo, setBreadcumInfo] = useState<any>([
        {
            label: 'Board',
            value: decodeURIComponent(BoardName),
            routerLink: `/dashboard/module/info/ABHYAS/${moduleCode}/${moduleId}/worksheet`,
            subName: "(board)",
            isActive: false
        },
        {
            label: 'Medium',
            value: decodeURIComponent(MediumName),
            routerLink: `/dashboard/module/info/ABHYAS/${moduleCode}/${moduleId}/${boardUuid}/${BoardName}/medium`,
            subName: "(Medium)",
            isActive: false
        },
        {
            label: 'standard',
            value: decodeURIComponent(StandardName),
            routerLink: `/dashboard/module/info/ABHYAS/${moduleCode}/${moduleId}/${boardUuid}/${BoardName}/${MediumUuid}/${MediumName}/standard`,
            subName: "(standard)",
            isActive: false
        },
        {
            label: 'subject',
            value: decodeURIComponent(SubjectName),
            routerLink: `/dashboard/module/info/ABHYAS/${moduleCode}/${moduleId}/${boardUuid}/${BoardName}/${MediumUuid}/${MediumName}/${standardUuid}/${StandardName}/subject`,
            subName: "(subject)",
            isActive: false
        },
        {
            label: 'chapter',
            value: decodeURIComponent(ChapterName),
            routerLink: `/dashboard/module/info/ABHYAS/${moduleCode}/${moduleId}/${boardUuid}/${BoardName}/${MediumUuid}/${MediumName}/${standardUuid}/${StandardName}/${subjectUuid}/${SubjectName}/${moduleCode === "PARIKSHA" ? "pariksha-chapter" : "chapter"}`,
            subName: "(chapter)",
            isActive: false
        },
        {
            label: 'topic',
            value: decodeURIComponent(TopicName),
            routerLink: `/dashboard/module/info/ABHYAS/${moduleCode}/${moduleId}/${boardUuid}/${BoardName}/${MediumUuid}/${MediumName}/${standardUuid}/${StandardName}/${subjectUuid}/${SubjectName}/${chapterUuid}/${ChapterName}/topic`,
            subName: "(topic)",
            isActive: false
        },

        {
            label: 'SHEET',
            value: `${sheetType?.slice(0, -1)} ${sheetNo}`,
            routerLink: `/dashboard/module/info/ABHYAS/${moduleCode}/${moduleId}/${boardUuid}/${BoardName}/${MediumUuid}/${MediumName}/${standardUuid}/${StandardName}/${subjectUuid}/${SubjectName}/${chapterUuid}/${ChapterName}/${topicUuid}/${TopicName}/pariksha-test`,

            // routerLink: `/dashboard/module/info/ABHYAS/${moduleCode}/${moduleId}/${boardUuid}/${BoardName}/${MediumUuid}/${MediumName}/${standardUuid}/${StandardName}/${subjectUuid}/${SubjectName}/pariksha-test`,
            subName: `(${sheetType?.slice(0, - 1)})`,
            isActive: false
        },
        {
            label: 'Questions',
            value: 'Questions',
            nameHighlight: true,
            isActive: true
        },
    ]);


    useEffect(() => {
        getChapterList();
        getQuestionsList();

    }, []);


    const getDangerouslySetInnerHTML = (element: any) => {
        return <div dangerouslySetInnerHTML={onViewQuestion(element?.question?.question)}></div>
    }

    const getQuestionsList = () => {
        setLoading(true);
        GET_ABHYAS_QUESTION_LIST(sheetUuid, {}).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.err);
                    setLoading(false);
                } else {
                    const data = res.rows;
                    data?.forEach((element: any, i: number) => {
                        element.created_dt = moment(element?.created_dt?.split("T")[0]).format('DD-MM-yyyy');
                        if (element?.modified_dt) {
                            element.modified_dt = moment(element?.modified_dt?.split("T")[0]).format('DD-MM-yyyy');
                        }
                        element.data = JSON.parse(element?.data)
                    });
                    setQuestionInfo(data[0])
                    setQuestionsList(data[0]?.data);
                    if (data[0]?.data && data[0]?.data?.length > 0) {
                        setUpdateQuestionsData(data[0]?.data);
                    } else {
                        setUpdateQuestionsData([]);
                    }
                    const pageNumbers = Math.ceil(res.count / 10);
                    const pageArray: any = [];
                    for (let i = 1; i <= pageNumbers; i++) {
                        pageArray.push(i);
                    }
                    setPageNumbers(pageArray);
                    setLoading(false);
                }
            }
        });
    }
    const onSaveAddQuestion = () => {
        const payLoad =
            [{
                "mode": "add",
                "id": questionNumber,
                "uuid": "",
                "question_type": selectedOption,
                "question": getCkeditorValue('question'),
                "options": getCkeditorValue('options'),
                "correct_answer": correctAnswer ? correctAnswer : '',
                "explanation": questionExplanation ? questionExplanation : "",
                "level": questionLevel,
                "chapter_uuid": selectChapterUuid,
                "topic_uuid": selectTopicUuid

            }]
        // return
        if (correctAnswer && selectChapterUuid && selectTopicUuid) {
            CREATE_ABHYAS_QUESTION(payLoad, sheetUuid).then((res: any) => {
                if (res.status === 'fail') {
                    toast.error(res?.err);
                } else {
                    toast.success(res.message);
                    setCanShowCKeditorAddPopup(false);
                    getQuestionsList();
                }
            });
        }
        else {
            if (!selectChapterUuid) {
                setChapterError("Please select chapter name");
            }
            if (!selectTopicUuid) {
                setTopicError("Please select topic name")
            }

        }


    }
    const onUpdate = () => {
        currentQuestionData[0].mode = "update";
        currentQuestionData[0].chapter_uuid = selectChapterUuid;
        currentQuestionData[0].topic_uuid = selectTopicUuid;
        currentQuestionData[0].level = questionLevel;
        // currentQuestionData[0].explanation = questionExplanation ? questionExplanation : "";

        if (selectChapterUuid && selectTopicUuid) {
            UPDATE_ABHYAS_QUESTION([currentQuestionData[0]], sheetUuid).then((res: any) => {
                if (res.status === 'fail') {
                    toast.error(res?.err);
                } else {
                    toast.success(res.message);
                    setCurrentQuestionData([]);
                    setCanShowCKeditorPopup(false);
                    getQuestionsList();
                }
            });
        } else {
            if (!selectChapterUuid) {
                setChapterError("Please select chapter name");
            }
            if (!selectTopicUuid) {
                setTopicError("Please select topic name")
            }
        }
    }


    function onViewQuestion(name: any) {
        return { __html: name };
    }

    function onChange(e: any, editor: any) {
        setInputData(editor.getData());
    };

    function onClickEdit(questions: any, i: number) {
        setQuestionLevel(questions?.level);
        setQuestionExplanation(questionData?.explanation);
        setCurrentQuestionData([]);;
        setQuestionImg([]);
        onChangeChapter(questions?.chapter_uuid);
        setSelectTopicUuid(questions?.topic_uuid);
        setQuestionUuid(questions?.uuid)
        // setQuestionImg(questions?.question?.images);
        setSelectedOption(questions?.question_type)
        setCanShowCKeditorPopup(true);
        setQuestionData(questions.question);
        const data = currentQuestionData;
        data.push(questions);
        setCurrentQuestionData(data);
        questionImg.push(questions)
        setLoadingCkeditor(true);
        setCanShowCkeditor(false);
        setTimeout(() => {
            setLoadingCkeditor(false);
        }, 1000);
    }

    function onEditOptions(question: any, i: number, option: any, j: number) {
        setQuestionIndex(i);
        setOptionIndex(j);
        setQuestionData(option.value);
        setCanShowCkeditor(true);
    }

    function getCorrectAnswer(option: any) {
        for (let i = 0; i < alphabets.length; i++) {
            if (option === alphabets[i]) {
                return alphabets[i - 1];
            }
        }
    }

    function onDeleteOptions(question: any, i: number, option: any, optionIndex: number) {
        setLoadingCkeditor(true);
        const data = currentQuestionData;
        for (let i = 0; i < data.length; i++) {
            for (let j = 0; j < data[i].options.length; j++) {
                if (option.options === data[i].correct_answer) {
                    data[i].correct_answer = "";
                } else {
                    if (data[i].correct_answer === data[i].options[j].option) {

                        if (j > optionIndex) {
                            data[i].correct_answer = getCorrectAnswer(data[i].options[j].option);
                        } else {
                            data[i].correct_answer = data[i].correct_answer;
                        }
                    }
                }
            }
        }
        data[0].options.splice(optionIndex, 1);

        for (let i = 0; i < data[0].options.length; i++) {
            for (let j = 0; j < alphabets.length; j++) {
                if (i === j) {
                    data[0].options[i].option = alphabets[j];
                    data[0].options[i].value = data[0].options[i].value;
                }
            }
        }

        setTimeout(() => {
            setCurrentQuestionData(data);
            setLoadingCkeditor(false);
            setCanShowCkeditor(false);
        }, 1000);

    }
    function onDeleteExplanation(question: any, i: number) {
        setLoadingCkeditor(true);
        const data = currentQuestionData;
        for (let i = 0; i < data.length; i++) {
            data[i].explanation = "";
        }

        setTimeout(() => {
            setCurrentQuestionData(data);
            setLoadingCkeditor(false);
            setCanShowCkeditor(false);
        }, 1000);

    }
    function onEditExplanation(question: any, i: number) {
        setCanShowExplanation(true);
        setQuestionData(question?.explanation);
        setOptionIndex(null);
        setCanShowCkeditor(true);
    }
    function onEditQuestion(question: any, i: number) {
        setQuestionData(question?.question);
        setQuestionIndex(i);
        setOptionIndex(null);
        setCanShowCkeditor(true);
    }

    const handleClose = () => {
        setCanShowCKeditorPopup(false);
        getQuestionsList();
        // getWorksheetQuestions(worksheetNumber);
        setCurrentQuestionData([]);
        setQuestionIndex(null);
        setOptionIndex(null);
        setCanShowPopup(false);
    }

    function getOptionLists(item: any, optionData: any) {
        const answerOptions = item;
        answerOptions.push({
            option: '',
            value: ''
        });
        for (let i = 0; i < answerOptions.length; i++) {
            for (let j = 0; j < alphabets.length; j++) {
                if (i === j && (answerOptions[i].value === '' || answerOptions[i].option === '')) {
                    answerOptions[i].option = alphabets[j];
                    answerOptions[i].value = optionData;
                }
            }
        }

        return answerOptions;
    }

    function onSave() {
        setLoadingCkeditor(true);
        const data = currentQuestionData;
        if (canShowExplanation) {
            setQuestionExplanation(inputData);
            currentQuestionData[0].explanation = inputData;
            setCanShowExplanation(false);
            setTimeout(() => {
                setCurrentQuestionData(data);
                setLoadingCkeditor(false);
                setCanShowCkeditor(false);
            }, 1000);
        }
        else if (questionIndex !== null && optionIndex !== null) {
            data[questionIndex].options[optionIndex].value = inputData;
            setTimeout(() => {
                setCurrentQuestionData(data);
                setLoadingCkeditor(false);
                setCanShowCkeditor(false);
            }, 1000);
        } else if (questionIndex !== null) {
            data[0].question = inputData;
            setTimeout(() => {
                setCurrentQuestionData(data);
                setLoadingCkeditor(false);
                setCanShowCkeditor(false);
            }, 1000);
        } else {
            data[0].options = getOptionLists(data[0].options, inputData);
            setTimeout(() => {
                setCurrentQuestionData(data);
                setLoadingCkeditor(false);
                setCanShowCkeditor(false);
            }, 1000);
        }
        setQuestionData(inputData);
    }

    function handleQuestionOptions(item: any, index: number) {
        const data = currentQuestionData;
        for (let i = 0; i < alphabets.length; i++) {
            if (index === i) {
                data[0].correct_answer = alphabets[i];
            }
        }
        setCurrentQuestionData(data);
    }

    function getUpdateQuestionsData() {
        const result = Object.values(
            [].concat(updateQuestionsData, currentQuestionData)
                .reduce((r: any, c: any) => (r[c.question_number] = Object.assign((r[c.question_number] || {}), c), r), {})
        );
        const updatedOptions = currentQuestionData?.map((element: any) => ({
            ...element,
            uuid: questionUuid
        }));
        return updatedOptions;
    }

    function onAddAnswerOptions() {
        setCanShowExplanation(false);
        setCanShowCkeditor(true);
        setQuestionData('');
        setOptionIndex(null);
        setQuestionIndex(null);
    }


    function capitalizeFirstLetter(string: any) {
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    }

    function onAddQuestion() {
        setQuestionExplanation("")
        if (updateQuestionsData?.length < parseInt(questionInfo?.questions)) {
            setInputData("");
            setQuestionImg([]);
            setSelectedOption('multiple_choice_questions')
            setQuestionTypeError('');
            setCurrentQuestionData([]);
            setLoadingCkeditor(false);
            if (updateQuestionsData.length > 0) {
                setQuestionNumber(updateQuestionsData.length + 1);
            } else {
                setQuestionNumber("1");
            }
            setQuestionsData([]);
            setCanShowCKeditorAddPopup(true);
            setCanShowCkeditor(false);
            getStatusSaveBtn();
            setTopicError("");
            setChapterError("");
            setSelectChapterUuid("");
            setSelectTopicUuid("");
            setTimeout(() => {
                setLoadingCkeditor(false);
            }, 2000);
            setCanShowExplanation(false);
        }
        else {
            toast.error(`The TEST paper do not allow more than ${questionInfo?.questions} questions`);

        }
    }

    function getOptions(index: number) {
        for (let i = 0; i < alphabets.length; i++) {
            if (index === i) {
                return alphabets[i];
            }
        }
    }

    function createMarkup(name: any) {

        return { __html: name };
    }

    function handleAddQuestionOptions(item: any, index: number, e: any) {
        for (let i = 0; i < alphabets.length; i++) {
            if ((index - 1) === i) {
                setCorrectAnswer(alphabets[i]);
            }
        }
    }

    function getStatusSaveBtn(optionType?: string) {
        // if (selectedOption === "match_the_following" && optionType === "right") {
        //     if (matchRightOptions?.length > 1) {
        //         return false;
        //     } else {
        //         return true;

        //     }
        // } else if (selectedOption === "match_the_following" && optionType === "left") {
        //     if (matchLeftOptions?.length > 1) {
        //         return false;
        //     } else {
        //         return true;

        //     }
        // } else 

        if (questionsData.length > 1) {
            return false;
        } else {
            return true;
        }
    }




    function getCkeditorValue(type: any) {
        // if (selectedOption === "match_the_following") {
        //     if (questionsData.length > 0) {
        //         return questionsData[0];
        //     }
        // } else {
        //     const preparedOptions = [];
        //     const data = questionsData.slice(1);
        //     for (let i = 0; i < data.length; i++) {
        //         preparedOptions.push({
        //             option: getOptions(i),
        //             value: data[i]
        //         })
        //     }
        //     return preparedOptions;
        // }


        if (type === 'question') {
            if (questionsData.length > 0) {
                return questionsData[0];
            }
        } else {
            const preparedOptions = [];
            const data = questionsData.slice(1);
            for (let i = 0; i < data.length; i++) {
                preparedOptions.push({
                    option: getOptions(i),
                    value: data[i]
                })
            }
            return preparedOptions;
        }
    }

    function getQuestionsData() {
        const data = [];
        const payloads = {
            "id": "",
            "uuid": "",
            "question_type": selectedOption,
            "question": getCkeditorValue('question'),
            // "images": questionImg,
            "options": getCkeditorValue('options'),
            "correct_answer": correctAnswer ? correctAnswer : ''
        }
        data.push(payloads);
        return data;


    }

    const handleAddClose = () => {
        setCanShowCKeditorAddPopup(false);
        getQuestionsList();
        // getWorksheetQuestions(worksheetNumber);
        setCurrentQuestionData([]);
        setQuestionIndex(null);
        setOptionIndex(null);
        setQuestionImg([]);

    }

    function onAddSave() {
        setCanShowCkeditor(true);
        if (canShowExplanation) {
            setQuestionExplanation(inputData);
            setCanShowExplanation(false);
        }
        else if (editor.getData()) {
            questionsData.push(inputData);
        }
    }

    function addAnswerOptions() {
        setCanShowExplanation(false);
        setCanShowCkeditor(false);
        getStatusSaveBtn();
    }

    function addAnswerExplanation() {
        setCanShowExplanation(true);
        setCanShowCkeditor(false);
        getStatusSaveBtn();
    }

    const onEdit = (question: any, i: number) => {
        onClickEdit(question, i)
    }
    const onDelete = (data: any) => {
        data.mode = "delete";
        setLoading(true);
        UPDATE_ABHYAS_QUESTION([data], sheetUuid).then((res: any) => {
            if (res.status === 'fail') {
                const errorMessage = Array.isArray(res?.err) ? res?.err[0] : res?.err
                toast.error(errorMessage);
                setLoading(false);
            } else {
                setLoading(false);
                toast.success(res.message);
                getQuestionsList();
            }
        });
        // setLoading(true);
        // const id = data.uuid;
        // DELETE_ABHYAS_QUESTION(id, data?.id, sheetUuid).then((res: any) => {
        //     if (res.status === "fail") {
        //         toast.error(res?.err);
        //         setLoading(false);
        //     } else {
        //         setLoading(false);
        //         toast.success(res?.message);
        //         getQuestionsList();

        //     }
        // });

    }
    const handleOptionChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedValue = event.target.value;
        setSelectedOption(selectedValue);
        setQuestionTypeError("");

    }
    function handleUploadLogo(e: any, type: string) {
        if (e.target.files && e.target.files[0]) {
            const fileType = e.target.files[0].name.split('.').pop()
            if (fileType == "jpeg" || fileType == "jpg") {
                const formData = new FormData();
                formData.append('file', e.target.files[0], e.target.files[0].name);
                uploadLogo(formData, type);
            } else {
                toast.error("Valid file type .jpg, .jpeg only");
            }
        }
    }
    function uploadLogo(formdata: any, type: string) {
        setImageLoader(true);
        UPLOAD_LOGO(formdata).then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res?.error);
                setImageLoader(false);
            } else {
                let imgUrl = res.url
                questionImg.push(imgUrl)
                // setQuestionImg(prevState => [...prevState, imgUrl]);
                setImageLoader(false);
            }
        });
    }
    const handleRemoveItem = (index: number) => {
        const updatedItems = [...questionImg];
        updatedItems.splice(index, 1);
        setQuestionImg(updatedItems);
    };

    const uploadAdapter = (loader: any) => {
        return {
            upload: () => {
                return new Promise((resolve, reject) => {
                    const body = new FormData();
                    loader.file.then((file: any) => {
                        body.append("file", file);
                        UPLOAD_LOGO(body).then((res: any) => {
                            if (res.status === 'fail') {
                                toast.error(res?.error);
                            } else {
                                let imgUrl = res.url
                                resolve({ default: `${res?.base_url}${imgUrl}` })
                            }
                        });

                    })
                })
            }
        }
    }

    function extractPTags(htmlString: string) {
        const parsedHtml = JSON.parse(htmlString);
        for (const element of parsedHtml) {
            if (element.type === 'tag' && element.name === 'p' && element.children) {
                const pContent = element.children.map((child: { text: any; }) => child.text || '').join('');
                setPTagContent(pContent);
            }
            if (element.type === 'tag' && element.name === 'figure' && element.children) {
                const figureContent = element.children.map((child: { text: any; }) => child.text || '').join('');
                setFigureElements(figureContent);
            }
        }

    }
    function extractFigureTags(htmlString: string): string[] {
        const parsedHtml = JSON.parse(htmlString);
        const figureTagsContent: string[] = [];
        for (const element of parsedHtml) {
            if (element.type === 'tag' && element.name === 'p' && element.children) {
                const pContent = element.children.map((child: { text: any; }) => child.text || '').join('');
                figureTagsContent.push(pContent);
            }
        }

        return figureTagsContent;
    }
    const testList = () => {
        if (props.selectTest) {
            props.selectTest('');
        }
    }
    const getChapterList = () => {
        GET_CHAPTER_FILTER_LIST(props.subjectUuid).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res?.err);
                } else {
                    const data = res['rows'];
                    if (props.chapterUUid) {
                        const filterData = data.filter((el: any) => el.uuid === props.chapterUUid);
                        setChapterList(filterData);
                    } else {
                        setChapterList(data);
                    }
                }
            }
        });
    };
    const onChangeChapter = (e?: any) => {
        setTopicList([]);
        setSelectTopicUuid("");
        setSelectChapterUuid("");
        if (e) {
            setSelectChapterUuid(e);
            setChapterError("");
            GET_TOPIC_FITER_LIST(e).then((res: any) => {
                if (res) {
                    if (res.status === 'fail') {
                        toast.error(res?.err);

                    } else {
                        const data = res['rows'];
                        setTopicList(data);

                    }
                }
            });
        }
        else {
            setChapterError("Please select chapter name")
        }
    }
    const onChangeTopic = (e: any) => {
        setSelectTopicUuid("");
        if (e) {
            setSelectTopicUuid(e);
            setTopicError("");
        } else {
            setTopicError("Please select topic name")
        }
    }

    const onClickBackTest = (data: any) => {
        if (props?.setBackQuestionsList) {
            props?.setBackQuestionsList('back');
        }
    }

    return (
        <div>
            <div
                className={`modal fade ${canShowPopup ? "show" : "false"}`}
                style={{ display: canShowPopup ? "block" : "none" }}
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                                Question Information
                            </h5>
                            <button
                                type="button"
                                className="close popup-close cursor-pointer"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={handleClose}
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body parent-info ">

                        </div>
                        <div className="modal-footer">

                            <button
                                type="button"
                                className="btn btn-secondary cursor-pointer btn-sm px-4 rounded-12 cursor-pointer py-2"
                                data-dismiss="modal"
                                onClick={handleClose}
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`modal fade parent-modal-fade ${canShowCKeditorPopup ? 'show' : 'false'}`} style={{ display: (canShowCKeditorPopup ? "block" : 'none') }} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog worksheet-quesions-dialog" role="document">
                    <div className="modal-content worksheet-questions-model">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Update Question</h5>
                            <button type="button" className="close popup-close cursor-pointer" data-dismiss="modal" aria-label="Close" onClick={handleClose}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body" style={{ overflowX: 'hidden' }}>
                            <div className='row mt-1 mb-3'>

                                <div className='col-md-4'>
                                    <div className="form-group ">
                                        <label className='control-label  fw-bold mb-1'> Chapter Name</label>
                                        <select name="chapter_uuid" id="chapter_uuid" className="form-select ft-14 " value={selectChapterUuid} onChange={(e) => { onChangeChapter(e.target.value) }}>
                                            <option value="">Select Chapter</option>
                                            {chapterList?.map((name: any, i: number) => (
                                                <option key={i} value={name?.uuid}>
                                                    {name?.name}
                                                </option>
                                            ))}
                                        </select>
                                        {chapterError && <p className='text-danger'>{chapterError}</p>}
                                    </div>
                                </div>
                                <div className='col-md-4'>
                                    <div className="form-group ">
                                        <label className="control-label  fw-bold">Topic Name</label>
                                        <select name="topic_uuid" id="topic_uuid" className="form-select  ft-14 mt-1" value={selectTopicUuid} onChange={(e) => { onChangeTopic(e.target.value) }}>
                                            <option value="">Select Topic</option>
                                            {topicList?.map((name: any, i: number) => (
                                                <option key={i} value={name?.uuid}>
                                                    {name?.name}
                                                </option>
                                            ))}
                                        </select>
                                        {topicError && <p className='text-danger'>{topicError}</p>}
                                    </div>

                                </div>
                                <div className='col-md-3'>
                                    <div className="form-group ">
                                        <label className='control-label  fw-bold mb-1'>Difficult Level</label>
                                        <select name="level" id="level" className="form-select f14" value={questionLevel} onChange={(e) => setQuestionLevel(e.target.value)}>
                                            <option value="LOW">Easy</option>
                                            <option value="MEDIUM">Medium</option>
                                            <option value="HIGH">Hard</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            {loadingCkeditor &&
                                <div className="text-center p-5">
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div>
                            }
                            {!loadingCkeditor && <div>
                                <div className='cdk-question-view'>
                                    {currentQuestionData?.map((question: any, i: number) => (

                                        <div className="mt-1" key={i}>
                                            <div className='row'>
                                                <div className='row'>
                                                    <div className='col-md-1 pt-4'>
                                                        <span className='fw-bold ' style={{ fontSize: '22px' }}>Q{question?.id}:</span>
                                                    </div>
                                                    <div className='col-md-7 mb-3 ' ref={listRef} style={{ paddingTop: '25px' }}>
                                                        <span className='fw-bold' style={{ fontSize: '22px' }}>
                                                            <MathJaxContext>
                                                                <MathJax>
                                                                    <div className="img_ck" dangerouslySetInnerHTML={onViewQuestion(question?.question)}></div>
                                                                </MathJax>
                                                            </MathJaxContext>
                                                        </span>
                                                    </div>

                                                    <div className='col-md-2 px-0 pb-0 pt-4'>
                                                        <i className="bi bi-pencil-square cursor-pointer edit-icon p-2 " onClick={() => onEditQuestion(question, i)}></i>
                                                    </div>

                                                </div>
                                                <div className='row pt-5'>
                                                    <div className='col-md-9'>
                                                        {question?.options?.map((options: any, j: number) => (
                                                            <div className='row' key={j}>
                                                                <div className='col-md-1'>
                                                                    <span>{options.option}.</span>
                                                                </div>
                                                                <div className='col-md-9'>
                                                                    <div className='row' style={{ position: 'relative' }}>
                                                                        <div className='col-md-8'>
                                                                            <MathJaxContext>
                                                                                <MathJax>
                                                                                    <span className='text-break img_ck' dangerouslySetInnerHTML={onViewQuestion(options.value)} ></span>
                                                                                </MathJax>
                                                                            </MathJaxContext>
                                                                        </div>
                                                                        <div className='col-md-4'>
                                                                            {j === 0 && <div style={{ position: 'absolute', top: '-30px', left: '390px' }}>Choose Correct Answer</div>}
                                                                            <input type='radio' name="option" defaultChecked={question.correct_answer === options.option} onChange={(e) => { handleQuestionOptions(options, j) }} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='col-md-2'>
                                                                    <i className="bi bi-pencil-square cursor-pointer edit-icon p-2" onClick={() => onEditOptions(question, i, options, j)}></i>
                                                                    <i className="bi bi-trash cursor-pointer edit-icon p-2" onClick={() => onDeleteOptions(question, i, options, j)}></i>
                                                                </div>
                                                            </div>
                                                        ))}

                                                        <div className='col-md-9 mt-3 mb-2'>
                                                            <h5>Explanation  <i className="bi bi-pencil-square cursor-pointer edit-icon p-2 f14 ms-2" onClick={() => onEditExplanation(question, i)}></i>
                                                                {question.explanation && <i className="bi bi-trash cursor-pointer edit-icon p-2 f14" onClick={() => onDeleteExplanation(question, i)}></i>}
                                                            </h5>
                                                            <div className='f16 text-break' dangerouslySetInnerHTML={createMarkup(question.explanation)} >
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-3 mb-2'>
                                                        <button className='btn btn-primary' onClick={() => onAddAnswerOptions()}>Add Answer Options</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>

                                {canShowCkeditor && <div className='mt-3'>
                                    <MathJaxContext>
                                        <CKEditor
                                            editor={ClassicEditor}
                                            id="editorId"
                                            data={questionData}
                                            config={{
                                                toolbar: {
                                                    items: [
                                                        'heading', 'MathType', 'ChemType',
                                                        '|',
                                                        'bold',
                                                        'italic',
                                                        'link',
                                                        'bulletedList',
                                                        'numberedList',
                                                        'imageUpload',
                                                        'mediaEmbed',
                                                        'insertTable',
                                                        'blockQuote',
                                                        'undo',
                                                        'redo',
                                                        '|',
                                                        'imageStyle:full', 'imageStyle:side',
                                                        'imageTextAlternative',
                                                        'imageResize',
                                                        'imageUploadMultiple',

                                                    ]
                                                },
                                                language: 'en',
                                            }}

                                            onChange={(event: any, editor: any) => {
                                                return onChange(event, editor);
                                            }}
                                            onReady={(editor: any) => {
                                                editorRef.current = editor;
                                                editor.plugins.get('FileRepository').createUploadAdapter = (loader: any) => {
                                                    return uploadAdapter(loader);
                                                };
                                                setEditor(editor);
                                            }}
                                        />
                                        <MathJax>
                                        </MathJax>
                                    </MathJaxContext>
                                    <div className='mt-2'>
                                        <button className='btn btn-primary' disabled={editor && editor.getData() ? false : true} onClick={() => onSave()}>Ok</button>&nbsp;
                                    </div>

                                </div>}
                            </div>}
                        </div>
                        <div className="modal-footer">
                            <div className='questions-save-btn'>
                                <button className='btn btn-primary' onClick={() => onUpdate()}>Update</button>
                            </div>
                            <button type="button" className="btn btn-secondary cursor-pointer" data-dismiss="modal" onClick={handleClose}>Close</button>&nbsp;
                        </div>
                    </div>
                </div>
            </div>
            <div className={`modal fade parent-modal-fade ${canShowCKeditorAddPopup ? 'show' : 'false'}`} style={{ display: (canShowCKeditorAddPopup ? "block" : 'none') }} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog worksheet-quesions-dialog" role="document">
                    <div className="modal-content worksheet-questions-model">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Add Question </h5>
                            <button type="button" className="close popup-close cursor-pointer" data-dismiss="modal" aria-label="Close" onClick={handleAddClose}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body" style={{ overflowX: 'hidden' }}>
                            {loadingCkeditor &&
                                <div className="text-center p-5">
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div>
                            }
                            {!loadingCkeditor && <div>
                                <div className='row mt-1 mb-3'>

                                    <div className='col-md-4'>
                                        <div className="form-group ">
                                            <label className='control-label fw-bold mb-1'> Chapter Name</label>
                                            <select name="chapter_uuid" id="chapter_uuid" className="form-select ft-14 " value={selectChapterUuid} onChange={(e) => { onChangeChapter(e.target.value) }}>
                                                <option value="">Select Chapter</option>
                                                {chapterList?.map((name: any, i: number) => (
                                                    <option key={i} value={name?.uuid}>
                                                        {name?.name}
                                                    </option>
                                                ))}
                                            </select>
                                            {chapterError && <p className='text-danger'>{chapterError}</p>}
                                        </div>
                                    </div>
                                    <div className='col-md-4'>
                                        <div className="form-group ">
                                            <label className="control-label  fw-bold">Topic Name</label>
                                            <select name="topic_uuid" id="topic_uuid" className="form-select  ft-14 mt-1" value={selectTopicUuid} onChange={(e) => { onChangeTopic(e.target.value) }}>
                                                <option value="">Select Topic</option>
                                                {topicList?.map((name: any, i: number) => (
                                                    <option key={i} value={name?.uuid}>
                                                        {name?.name}
                                                    </option>
                                                ))}
                                            </select>
                                            {topicError && <p className='text-danger'>{topicError}</p>}
                                        </div>

                                    </div>
                                    <div className='col-md-3'>
                                        <div className="form-group ">
                                            <label className='control-label fw-bold mb-1'>Difficult Level</label>
                                            <select name="level" id="level" className="form-select ft-14" value={questionLevel} onChange={(e) => setQuestionLevel(e.target.value)}>
                                                <option value="LOW">Easy</option>
                                                <option value="MEDIUM">Medium</option>
                                                <option value="HIGH">Hard</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div className='mt-2'>
                                    <h6>Question:{questionNumber}</h6>
                                </div>
                                {(questionsData && questionsData.length > 0) && <div className='mt-2'>
                                    <div className='cdk-question-view'>
                                        {questionsData && <div className='row' style={{ position: 'relative' }}>
                                            {questionsData?.map((name: any, i: number) => (
                                                <div key={i} className=' col-md-8 row' style={{
                                                    marginTop: i !== 0 ? '15px' : '2px',
                                                    marginBottom: i !== 0 ? '5px' : '25px',
                                                    position: i !== 0 ? 'relative' : 'static'
                                                }}>
                                                    {i !== 0 && <div className='col-md-1'>
                                                        <span>{getOptions(i - 1)}.</span>
                                                    </div>}
                                                    <div className={i !== 0 ? 'col-md-4' : 'col-md-11'}>
                                                        <MathJaxContext>
                                                            <MathJax>
                                                                <div style={{ fontSize: i !== 0 ? '14px' : '25px' }} dangerouslySetInnerHTML={createMarkup(name)} >
                                                                </div>
                                                            </MathJax>
                                                        </MathJaxContext>
                                                    </div>

                                                    {(i !== 0 && questionsData.length >= 3) && <div className='col-md-4'>
                                                        {(i !== 0 && i === 1) && <div className='mt-2' style={{ position: 'absolute', top: '-40px', left: '240px' }}>
                                                            <div>Choose Correct Answer</div>
                                                        </div>}
                                                        <div className='col-md-1'>
                                                            <input type='radio' name="option" onChange={(e) => { handleAddQuestionOptions(name, i, e) }} />
                                                        </div>
                                                    </div>}
                                                </div>
                                            ))}
                                            <div className='col-md-2 mb-2'>
                                                <button className='btn btn-primary' style={{ padding: '6px 6px' }} onClick={() => addAnswerOptions()}>Add Answer Options</button>
                                            </div>
                                            <div className='col-md-2 mb-2'>
                                                <button className='btn btn-primary' style={{ padding: '6px 6px' }} onClick={() => addAnswerExplanation()}>Add Explanation</button>
                                            </div>
                                        </div>}
                                    </div>

                                </div>}
                                {!canShowCkeditor && <div className='mt-3'>
                                    <MathJaxContext>
                                        <CKEditor
                                            editor={ClassicEditor}
                                            id="editorId"
                                            data=''
                                            config={{
                                                // plugins: [Image, ImageToolbar, ImageCaption, ImageStyle, ImageResize],
                                                toolbar: {
                                                    items: [
                                                        'heading', 'MathType', 'ChemType',
                                                        '|',
                                                        'bold',
                                                        'italic',
                                                        'link',
                                                        'bulletedList',
                                                        'numberedList',
                                                        'imageUpload',
                                                        'mediaEmbed',
                                                        'insertTable',
                                                        'blockQuote',
                                                        'undo',
                                                        'redo',
                                                        '|',
                                                        'imageStyle:full', 'imageStyle:side',
                                                        'imageTextAlternative',
                                                        'imageResize',
                                                        'imageUploadMultiple',
                                                    ]
                                                },

                                                image: {
                                                    resizeOptions: [{
                                                        name: 'resizeImage:original',
                                                        value: null,
                                                        label: 'Original Size',
                                                        icon: 'original'
                                                    },
                                                    {
                                                        name: 'resizeImage:25',
                                                        value: '25',
                                                        label: '25%',
                                                        icon: 'small'
                                                    },
                                                    {
                                                        name: 'resizeImage:50',
                                                        value: '50',
                                                        label: '50%',
                                                        icon: 'medium'
                                                    },
                                                    {
                                                        name: 'resizeImage:75',
                                                        value: '75',
                                                        label: '75%',
                                                        icon: 'large'
                                                    }
                                                    ],
                                                    toolbar: [
                                                        'imageStyle:inline',
                                                        'imageStyle:block',
                                                        'imageStyle:side',
                                                        '|',
                                                        'toggleImageCaption',
                                                        'imageTextAlternative',
                                                        'resizeImage:25',
                                                        'resizeImage:50',
                                                        'resizeImage:75',
                                                        'resizeImage:original',
                                                    ],
                                                },
                                                language: 'en',

                                            }}

                                            onChange={(event: any, editor: any) => {
                                                return onChange(event, editor);
                                            }}
                                            onReady={(editor: any) => {
                                                editorRef.current = editor;
                                                editor.plugins.get('FileRepository').createUploadAdapter = (loader: any) => {
                                                    return uploadAdapter(loader);
                                                };
                                                setEditor(editor);
                                            }}

                                        />
                                        <MathJax>
                                        </MathJax>
                                    </MathJaxContext>
                                    <div className='mt-2'>
                                        <button className='btn btn-primary' disabled={editor && editor.getData() ? false : true} onClick={() => onAddSave()}>Ok</button>&nbsp;
                                    </div>

                                </div>
                                }
                                {questionExplanation &&
                                    <div className='col-md-6 mt-3'>
                                        <h5>Explanation</h5>
                                        <div className='f16 text-break' dangerouslySetInnerHTML={createMarkup(questionExplanation)} >
                                        </div>
                                    </div>
                                }
                            </div>}
                        </div>
                        <div className="modal-footer">
                            <div className='questions-save-btn'>
                                <button className='btn btn-primary' disabled={getStatusSaveBtn()} onClick={() => onSaveAddQuestion()}>Save</button>
                            </div>
                            <button type="button" className="btn btn-secondary cursor-pointer" data-dismiss="modal" onClick={handleAddClose}>Close</button>&nbsp;
                        </div>
                    </div>
                </div>
            </div>
            <div className={`mt-3 ${props.display === "none" && "d-none"}`}>
                {/* <Breadcum breadcumList={breadcumInfo} margin={"classic"} /> */}
            </div>
            {props.display === "none" && <nav aria-label="breadcrumb" className="first">
                <ol className={`breadcrumb indigo lighten-6 first mb-4 bg-dark navbar-light  px-md-4`}>
                    <li className="breadcrumb-item font-weight-bold font-italic">
                        <span className='cursor-pointer' onClick={testList}>
                            <span className='breadcum-label mr-md-3 mr-2 black-text text-uppercase active' onClick={onClickBackTest}>TEST {questionInfo?.number}
                            </span> <span className='font-weight-bold f10 text-black ms-1 fw_bold '>( TEST )</span>
                        </span>
                        <i className="bi bi-chevron-right breadcum-arrow"></i>
                    </li>
                    <li className="breadcum-active-label ">
                        <span className='black-text text-uppercase active-1 disabled-link active'>Questions </span>
                    </li>
                </ol>
            </nav>}
            <div className="d-flex  ms-2 justify-content-between mt-1">

                <h5 className="">  Questions</h5>
                <p className="mb-0"><button className="btn btn-primary" onClick={() => onAddQuestion()}>Add Question</button></p>
            </div>
            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading &&


                <div>
                    <MathJaxContext>
                        <MathJax>
                            {questionsList?.map((question: any, i: number) => (
                                <div className="mt-4" key={i}>
                                    <div className='row'>
                                        <div className='col-md-11'>
                                            <div className='row'>
                                                {/* <div className='col-md-1' style={{ verticalAlign: 'middle', margin: 'auto' }}>
                                                    <span className='fw-bold' style={{ fontSize: '22px' }}>Q{question.id}:</span>
                                                </div> */}
                                                <div className='col-12' style={{ paddingTop: '15px' }}>
                                                    <div className='d-flex'>
                                                        <p className='fw-bold mb-0' style={{ fontSize: '22px' }}>
                                                            Q{question.id}:
                                                        </p>
                                                        <div className='ms-4 ps-2'>
                                                            <span className='fw-bold' style={{ fontSize: '22px' }}>
                                                                {/* <MathJaxContext>
                                                        <MathJax> */}
                                                                <div dangerouslySetInnerHTML={onViewQuestion(question.question)}></div>
                                                                {/* </MathJax>
                                                    </MathJaxContext> */}
                                                            </span>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>

                                            {question.options.map((options: any, j: number) => (
                                                <div className='row' key={j}>
                                                    <div className='col-md-1'>
                                                        <span>{options.option}.</span>
                                                    </div>
                                                    <div className='col-md-6'>
                                                        {/* <MathJaxContext>
                                                        <MathJax> */}
                                                        <span dangerouslySetInnerHTML={onViewQuestion(options.value)}></span>
                                                        {/* </MathJax>
                                                    </MathJaxContext> */}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                        <div className='col-md-1' style={{ paddingTop: '15px' }}>
                                            <i className="bi bi-pencil-square cursor-pointer edit-icon p-2" onClick={() => onClickEdit(question, i)}></i>
                                            <i className="bi bi-trash cursor-pointer p-2" onClick={() => onDelete(question)}></i>

                                        </div>
                                    </div>
                                </div>
                            ))}
                        </MathJax>
                    </MathJaxContext>
                </div>

            }

        </div >
    );
};

export default ParikshaQuestionsList;
