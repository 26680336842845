import { DataTableCol } from "../../../../components/data-table/types";

export const PromoCodeListDataGridCols: DataTableCol[] = [
    {
        title: 'Coupon Code',
        control: 'promo_code',
        sortable: true,
        canShowColumn: true

    },

    {
        title: 'Status',
        control: '',
        sortable: true,
        canShowColumn: true

    },
    {
        title: 'Created',
        control: 'created_dt',
        sortable: true,
        canShowColumn: true

    },
    {
        title: 'Modified',
        control: 'modified_dt',
        sortable: true,
        canShowColumn: true

    },
    {
        title: 'Details',
        control: 'coupon_details',
        isViewData: true,
        sortable: false,
        canShowColumn: true

    },

    {
        title: 'Actions',
        control: 'both',
        sortable: false,
        isEdit: true,
        isDelete: false,
        canShowColumn: true
    },
];


