import React, { useEffect, useRef, useState } from 'react'
import { Link, NavLink, useHistory } from 'react-router-dom'
import { GET_MODULES, GET_MODULE_BY_QUESTION } from '../../../app/service/module.service';
import { DataTable } from '../../../components/data-table'
import { ModuleDataGridCols } from './data-grid-cols';
import { ToastContainer, toast } from 'react-toastify';
import moment from 'moment';
import { UPLOAD_QUESTIONS } from '../../../app/service/questions.service';

export const ModulesList = () => {
    const [modulesData, setModulesData] = useState<any[]>([]);
    const [pageArray, setPageNumbers] = useState([]);
    const [activePage, setActivePage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [canShowQuestionsPopup, setCanShowQuestionsPopup] = useState(false);
    const languageForm = useRef<any>({});
    const [canShowQuestions, setCanShowQuestions] = useState(false);
    const [moduleInfo, setModuleInfo] = useState<any>({});
    const [questionsData, setQuestionsData] = useState<any>([]);
    const [languageQuestionsData, setLanguageQuestionsData] = useState<any>([]);
    const [languageType, setLanguageType] = useState<any>("en");
    const [canShowQuesionsUploadPopup, setCanShowQuesionsUploadPopup] = useState(false);
    const [excelFileName, setExcelFileName] = useState<any>();
    const [questionsUpload, setQuestionsUpload] = useState<any>({});
    const [canShowConfirmQuestionsPopup, setCanShowConfirmQuestionsPopup] = useState(false);
    const history = useHistory();
    const [accessObjectsList, setAccessObjectsList] = useState<any>([]);
    const [accessNew, setAccessNew] = useState<any>([]);


    const languages = [{
        "name": "English",
        "code": "en"
    }, {
        "name": "Hindi",
        "code": "hi"
    }, {
        "name": "Marathi",
        "code": "mr"
    },
    {
        "name": "Gujarati",
        "code": "gu"
    }
    ];

    useEffect(() => {
        getModules();
        const accessObjectsData: any = localStorage.getItem("access_objects_data");
        setAccessObjectsList(JSON.parse(accessObjectsData));
        handleModulesCols(JSON.parse(accessObjectsData));
    }, []);

    function getModules() {
        setLoading(true);
        GET_MODULES(0).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    setLoading(false);
                    const data = res.rows;
                    data.forEach((element: any) => {
                        element.created_at = moment(element.created_at.split("T")[0]).format('DD-MM-yyyy');
                    });
                    setModulesData(data);
                    const pageNumbers = Math.ceil(res.count / 10);
                    const pageArray: any = [];
                    for (let i = 1; i <= pageNumbers; i++) {
                        pageArray.push(i);
                    }
                    setPageNumbers(pageArray);
                }
            }
        });
    }

    const onPageChange = (data: any) => {
        setActivePage(data);
        if (data) {
            history.push(`/dashboard/module?page=${data}`);
        }
        const pageNumber = data - 1;
        GET_MODULES(pageNumber).then((res: any) => {
            if (res) {
                const data = res['rows'];
                setModulesData(data);
            }
        });
    }

    const onEditModule = (data: any) => {
        history.push(`/dashboard/module/form/${data.id}`);
    }

    function handleLanguageChange(e: any) {
        const type = e.target.value || "en";
        setLanguageType(type);
        const data = languageQuestionsData;
        if (type == "en") {
            setQuestionsData(data.en ? data.en : []);
        } else if (type == "hi") {
            setQuestionsData(data.hi ? data.hi : []);
        } else if (type == "mr") {
            setQuestionsData(data.mr ? data.mr : []);
        } else if (type == "gu") {
            setQuestionsData(data.gu ? data.gu : []);
        } else {
            setQuestionsData([]);
        }
    }

    const getModuleQuestionAnswers = (data: any) => {
        setModuleInfo(data);
        setCanShowQuestionsPopup(true);
        getModuleQuestions(data);
    }

    function getModuleQuestions(item: any) {
        let payload = {
            module_code: item.module_code,
            sub_module: item.sub_module,
            module_ver: item.module_ver,
            target_std: item.target_std
        }
        setLoading(true);
        GET_MODULE_BY_QUESTION(payload).then(res => {
            if (res.status === 'fail') {
                toast.error(res.message);
                setLoading(false);
            } else {
                setQuestionsData(res.en);
                setLanguageQuestionsData(res);
                setCanShowQuestions(true);
                setLoading(false);
            }
        });
    }

    const handleQuestionsclose = () => {
        setCanShowQuestionsPopup(false);
        languageForm.current.reset();
    }


    const onUploadQuestions = (data: any) => {
        setModuleInfo(data);
        setCanShowQuesionsUploadPopup(true);
    }

    function handleBrowseFile(e: any) {
        if (e.target.files && e.target.files[0]) {
            setExcelFileName(e.target.files[0].name);
            let formData = new FormData();
            formData.append('file', e.target.files[0], e.target.files[0].name);
            formData.append('module_code', moduleInfo.module_code);
            formData.append('sub_module', moduleInfo.sub_module);
            formData.append('target_std', moduleInfo.target_std);
            formData.append('module_ver', moduleInfo.module_ver);
            formData.append('language', languageType);
            setQuestionsUpload(formData);
        }
    }

    const handleQuestionsUploadClose = () => {
        setCanShowQuesionsUploadPopup(false);
        setExcelFileName("");
    }

    function handleUpload() {
        setCanShowConfirmQuestionsPopup(true);
    }

    const handleQuestionsSave = () => {
        setLoading(true);
        UPLOAD_QUESTIONS(questionsUpload).then(res => {
            if (res.status === 'fail') {
                toast.error(res.message);
                setLoading(false);
            } else {
                setLanguageQuestionsData(res);
                setCanShowConfirmQuestionsPopup(false);
                setLoading(false);
                toast.success("Successfully Upload Questions");
            }
        });
    }

    const handleConfirmQuestionsClose = () => {
        setCanShowConfirmQuestionsPopup(false);
    }

    const handleModulesCols = (accessObjectsList: any) => {
        const preparedData = accessObjectsList?.filter((el: any) => el.object_code === "MODULES");
        setAccessNew(preparedData);
        if (preparedData?.length > 0) {
            for (let i = 0; i < preparedData[0].child.length; i++) {
                for (let j = 0; j < ModuleDataGridCols.length; j++) {
                    if (preparedData[0].child[i].object_code === "MODULES-EDIT" && preparedData[0].child[i].p_read === 1) {
                        if (ModuleDataGridCols[j].title == "Actions") {
                            ModuleDataGridCols[j].editModule = true;
                            ModuleDataGridCols[j].canShowColumn = true;

                        }

                    }


                }
            }
        }

    }
    function onHandleNew() {
        if (accessNew.length > 0) {
            for (let i = 0; i < accessNew[0].child.length; i++) {
                if (accessNew[0].child[i].object_code === "MODULES-NEW" && accessNew[0].child[i].p_read === 1) {
                    return true;
                }
                if (accessNew[0].child[i].object_code === "MODULES-ASSIGN-TO-SCHOOL" && accessNew[0].child[i].p_read === 1) {
                    return true;
                }
            }
        }
    }

    return (
        <div className="row border-top border-primary py-3">
            <div className={`modal fade ${canShowQuestionsPopup ? 'show' : 'false'}`} style={{ display: (canShowQuestionsPopup ? "block" : 'none') }} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog questions-model-dialog" role="document">
                    <div className="modal-content questions-model">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel" style={{ display: 'inline-block' }}>CAS Question</h5>
                            <form ref={languageForm}>
                                <select name="language" className="mb-3 form-select" style={{ marginLeft: '15px' }} onChange={(e) => { handleLanguageChange(e) }}>
                                    {languages.map((name: any, i: number) => (
                                        <option key={i} value={name.code}>
                                            {name.name}
                                        </option>
                                    ))}
                                </select>
                            </form>
                            <button type="button" className="close popup-close cursor-pointer" data-dismiss="modal" aria-label="Close" onClick={handleQuestionsclose}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body parent-info">
                            {loading &&
                                <div className="text-center p-5">
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div>
                            }
                            {canShowQuestions && <div>
                                {!loading && <div className="row custom-form">
                                    {questionsData.map((question: any, i: number) => (
                                        <div className="col-md-12" key={i}>
                                            <div className="mb-4">
                                                <label className="form-label mb-3" style={{ fontWeight: 'bold' }}>
                                                    {question.question_type === 'multiple_choice' && <span>{i}. </span>}
                                                    {question.question}
                                                </label><br />
                                                {question.options.map((option: any, j: number) => (
                                                    <div key={j} style={{ display: "inline-block" }}>
                                                        <input className="mb-0" type="radio" disabled />
                                                        <span style={{ paddingRight: '15px', paddingLeft: '15px' }}>{option}</span>
                                                    </div>
                                                ))}
                                                {question.question_type === 'textarea' && <textarea className="form-control" name="option" disabled style={{ width: '50%' }} ></textarea>}
                                                {question.question_type === 'input_text' && <input type="text" className="form-control" disabled name="option" style={{ width: '50%' }} />}
                                            </div>
                                        </div>
                                    ))}
                                    {questionsData.length == 0 && <div style={{ textAlign: 'center' }}>
                                        Not Found
                                    </div>}
                                </div>}
                            </div>}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary cursor-pointer" data-dismiss="modal" onClick={handleQuestionsclose}>Close</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`modal fade parent-modal-fade ${canShowQuesionsUploadPopup ? 'show' : 'false'}`} style={{ display: (canShowQuesionsUploadPopup ? "block" : 'none') }} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Questions Upload</h5>
                            <button type="button" className="close popup-close cursor-pointer" data-dismiss="modal" aria-label="Close" onClick={handleQuestionsUploadClose}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div>
                                {loading &&
                                    <div className="text-center p-5">
                                        <div className="spinner-border" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                }
                                {!loading &&
                                    <div>
                                        <label className="form-label mb-1">Language</label>
                                        <select name="language" className="form-select form-select-lg mb-3" onChange={(e) => { handleLanguageChange(e) }}>
                                            {/* <option value="">Select Language</option> */}
                                            {languages.map((name: any, i: number) => (
                                                <option key={i} value={name.code}>
                                                    {name.name}
                                                </option>
                                            ))}
                                        </select>
                                        <label className="form-label mb-1">Upload Excel File</label><br />
                                        <div className="file btn btn-primary" style={{ marginRight: '10px' }}>Browse
                                            <input type="file" onChange={(e) => handleBrowseFile(e)} />
                                        </div>
                                        {excelFileName}
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="modal-footer">
                            <div className="file btn btn-primary cursor-pointer" style={{ marginRight: '10px' }} onClick={() => handleUpload()}>Upload
                            </div>
                            <button type="button" className="btn btn-secondary cursor-pointer" data-dismiss="modal" onClick={handleQuestionsUploadClose}>Close</button>&nbsp;
                        </div>
                    </div>
                </div>
            </div>
            <div className={`modal fade parent-modal-fade ${canShowConfirmQuestionsPopup ? 'show' : 'false'}`} style={{ display: (canShowConfirmQuestionsPopup ? "block" : 'none') }} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Confirm Message</h5>
                            <button type="button" className="close popup-close cursor-pointer" data-dismiss="modal" aria-label="Close" onClick={handleConfirmQuestionsClose}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            {loading &&
                                <div className="text-center p-5">
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div>
                            }
                            {!loading && <div>
                                Please confirm to upload the new questions list
                            </div>}
                        </div>
                        <div className="modal-footer">
                            <div className="text-center text-lg-start">
                                <a className="btn btn-primary  px-5  fw-light cursor-pointer" onClick={handleQuestionsSave}>
                                    YES
                                </a><br />
                            </div>
                            <button type="button" className="btn btn-secondary cursor-pointer" data-dismiss="modal" onClick={handleConfirmQuestionsClose}>Close</button>&nbsp;
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <h2>Modules</h2>
                </div>
                <div className="border-primary py-3 text-end col-md-6">
                    {/* { onHandleNew() &&  <Link to="/dashboard/module/form/0" className="btn btn-primary btn-sm px-3 rounded-12">New</Link>}&nbsp; */}
                    {onHandleNew() && <Link to="/dashboard/module/assign-school" className="btn btn-primary btn-sm px-3 rounded-12">Assign To School</Link>}
                </div>
            </div>
            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading && <DataTable TableCols={ModuleDataGridCols} tableData={modulesData}
                onChange={getModuleQuestionAnswers} onQuestionUpload={onUploadQuestions}
                editInfo={onEditModule}
                pageNumber={onPageChange} activePageNumber={activePage} pageNumbers={pageArray}></DataTable>}
            {/* <div>
                <Link to={`/dashboard/module/info/ABHYAS/${"ABHYAS"}/${1}/worksheet`} className="btn btn-primary btn-sm px-3 rounded-12">Abhyas</Link>
            </div> */}

        </div>
    )
}
