import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { Link, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { GET_MLA_TWO_DETAILS } from '../../../../app/service/mla-two.service';
import { MNSProgressView } from '../mns/dashboard';

export const MLATwoTestLevelStatusView = () => {
    const [assesmentTestStatus, setAssesmentTestStatus] = useState<any>();
    const [MLADetails, setMLADetails] = useState<any>([]);
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    let { moduleUUID, userCode, schoolCode } = useParams<{ moduleUUID: string, userCode: string, schoolCode: string }>();
    const modules_uuid = moduleUUID;
    const user_code = userCode;
    const school_code = schoolCode;

    useEffect(() => {
        getMLATwoDetails();
    }, []);

    function getMLATwoDetails() {
        setLoading(true);
        GET_MLA_TWO_DETAILS().then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res.message);
                setLoading(false);
            } else {
                if (res) {
                    const data = res;
                    if (data.details.length > 0) {
                        setMLADetails(data.details);
                    }
                }
                setLoading(false);
            }
        });
    }


    function onClickEntryTest() {
        history.push(`/student-dashboard/list/mla-two/questions/${modules_uuid}/${user_code}/${school_code}`);
    }

    return (
        <div>
            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading &&

                <div>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb pb-2 ps-0 pt-0 border-bottom">
                            <li className="breadcrumb-item "><Link to="/student-dashboard/list"><span className='pe-2'>Dashboard</span></Link></li> /

                            <li className="breadcrumb-item "><span>MLA-2</span></li>

                        </ol>
                    </nav>

                    <div className="mt-4 row">
                        <div className='col-md-9'>
                            <h2 className="f22 fw-bold">
                                MLA - 2 <span className="fw-light">(Minimum Learning Ability)</span>
                            </h2>
                            <h2 className='f24'>MLA Tests -1,2,3</h2>
                            <h3 className="fw-medium f14 mt-3 m-0">Introduction</h3>
                            <p className="fw-light f14 m-0">About MLA-2</p>
                            <h3 className="fw-medium f14 mt-3 mb-0">Instructions</h3>

                            <div className='pt-4 pb-1'>
                                {MLADetails.map((detail: any, i: number) => (
                                    <p key={i}>{i + 1}.{detail}</p>
                                ))}
                            </div>
                            <p className="mt-3">
                                <button className="btn btn-primary mb-3 px-5" onClick={() => { onClickEntryTest() }}>Start Test</button>
                            </p>
                        </div>
                        <div className='col-md-3'>
                            <div className="score-card-sticky">
                                <MNSProgressView />

                            </div>
                        </div>

                    </div>

                </div>
            }

        </div>
    )
}