import React, { useEffect, useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import { ASSIGN_MODULES_BY_PARENTS, CREATE_ASSIGN_USER, GET_TOTAL_MODULES } from '../../../../app/service/module.service';
import { GET_TOTAL_SCHOOLS } from '../../../../app/service/school.service';
import { GET_USERS_BY_SCHOOL_CODE, GET_USER_BY_SCHOOL_CODE } from '../../../../app/service/users.service';
import FormBuilder from '../../../../components/form-builder';
import { FormControlError, FormField, FormValidators } from '../../../../components/form-builder/model/form-field';
import { FormValidator, GetControlIsValid } from '../../../../components/form-builder/validations';
import { MultiSelect } from 'react-multi-select-component';

export const AssignUser = () => {
    const [moduleData, setModuleData] = useState<any>([]);
    const [subModuleData, setSubModuleData] = useState<any>([]);
    const [moduleVersionData, setModuleVersionData] = useState<any>([]);
    const [targetData, setTargetData] = useState<any>([]);
    const [schoolData, setSchoolData] = useState<any>([]);
    const [studentList, setStudentList] = useState<any>([]);
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [assignUserValidationErrors, setAssignUserValidationErrors] = useState<FormControlError[]>([]);
    const [assignUserData, setAssignUserData] = useState<any>({});
    const [amount, setAmount] = useState<any>(0);
    const [studentsSelected, setStudentsSelected] = useState([]);
    const [canShowPopup, setCanShowPopup] = useState(false);
    let { id, schoolCode } = useParams<{ id: string, schoolCode: string }>();
    const schoolId = parseInt(id);
    const schoolsCode = schoolCode;
    const history = useHistory();
    const formValidations = [
        new FormField('module_code', [FormValidators.REQUIRED]),
        new FormField('sub_module', [FormValidators.REQUIRED]),
        new FormField('module_ver', [FormValidators.REQUIRED]),
        new FormField('target_std', [FormValidators.REQUIRED]),
        new FormField('school_code', []),
        new FormField('user_code', [FormValidators.REQUIRED])
    ];

    useEffect(() => {
        GET_TOTAL_MODULES().then((res: any) => {
            if (res) {
                const data = res.rows;
                const preparedData = data.filter((el: any) => el.module_code !== "CAS-PARENT");
                setModuleData(preparedData);
            }
        });

        GET_USERS_BY_SCHOOL_CODE(schoolsCode, 'STUDENT').then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                } else {
                    const data = res.rows;
                    const preparedData = [];
                    for (let i = 0; i < data.length; i++) {
                        preparedData.push({
                            label: data[i].name,
                            value: data[i].user_code
                        })

                    }
                    setStudentList(preparedData);
                }
            }
        });

    }, []);

    const handleInput = (data: any) => {
        setAssignUserData(data);
        const errors: any = FormValidator(formValidations, data.value);
        setAssignUserValidationErrors(errors);
    }

    const handleModuleChange = (e: any) => {
        setSubModuleData([]);
        const preparedData = moduleData.filter((el: any) => el.module_code === e.target.value);
        setAmount(preparedData[0].amount ? preparedData[0].amount : 0);
        setSubModuleData(preparedData);
    }

    const handleSubModuleChange = (e: any) => {
        setModuleVersionData([]);
        const preparedData = moduleData.filter((el: any) => el.sub_module === e.target.value);
        setModuleVersionData(preparedData);
    }

    const handleModuleVersionChange = (e: any) => {
        setTargetData([]);
        const preparedData = moduleData.filter((el: any) => el.module_ver === e.target.value);
        setTargetData(preparedData);
    }

    const handleTargetChange = (e: any) => {
    }

    const handleStudentChange = (e: any) => {
    }

    const getInputValid = (control: string) => {
        const value = GetControlIsValid(assignUserValidationErrors, control);
        return value;
    }

    const handleSubmit = () => {
        setIsFormSubmitted(true);
        const assignsSchoolData = { ...assignUserData.value };
        assignsSchoolData.school_code = schoolsCode;
        assignsSchoolData.user_code = studentsSelected;
        assignsSchoolData.amount = amount;
        delete assignsSchoolData[''];
        const errors: FormControlError[] = FormValidator(formValidations, assignsSchoolData);
        setAssignUserValidationErrors(errors);
        if (errors.length < 1) {
            CREATE_ASSIGN_USER(assignsSchoolData).then((res: any) => {
                if (res.status === 'fail') {
                    toast.error(res.message);
                } else {
                    setCanShowPopup(true);
                    toast.success("Assign User Created Successfully");
                }
            });
        }
    }

    const handleclose = () => {
        setCanShowPopup(false);
        history.push(`/dashboard/school/info/${schoolId}/modulelist/${schoolsCode}`);
    }

    function onClickAssign() {
        const payload = {
            school_code: schoolsCode,
            user_code: studentsSelected
        };

        ASSIGN_MODULES_BY_PARENTS(payload).then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res.message);
            } else {
                setCanShowPopup(false);
                toast.success("Module Assign Parent Successfully");
                history.push(`/dashboard/school/info/${schoolId}/modulelist/${schoolsCode}`);
            }
        });
    }

    return (
        <div>
            <div className={`modal fade parent-modal-fade ${canShowPopup ? 'show' : 'false'}`} style={{ display: (canShowPopup ? "block" : 'none') }} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Assign To Parents</h5>
                            <button type="button" className="close popup-close cursor-pointer" data-dismiss="modal" aria-label="Close" onClick={handleclose}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div>
                                Do you want to assign the same module to parents also?
                            </div>
                        </div>
                        <div className="modal-footer">
                            <div className="text-center text-lg-start">
                                <a className="btn btn-primary  px-5  fw-light cursor-pointer" onClick={() => onClickAssign()}>
                                    YES
                                </a><br />
                            </div>
                            <button type="button" className="btn btn-secondary cursor-pointer" data-dismiss="modal" onClick={handleclose}>Close</button>&nbsp;
                        </div>
                    </div>
                </div>
            </div>
            <h2>Assign Student</h2>
            <FormBuilder onUpdate={handleInput}>
                <form>
                    <div className="row custom-form">
                        <div className="col-md-4">
                            <div className="mb-4">
                                <label className="form-label mb-0">Module Name</label>
                                <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>*</span>
                                <select name="module_code" className="form-select form-select-lg mb-3" onChange={(e) => { handleModuleChange(e) }}>
                                    <option value="">Select module</option>
                                    {moduleData.map((name: any, i: number) => (
                                        <option key={i} value={name.module_code}>
                                            {name.module_name}
                                        </option>
                                    ))}
                                </select>
                                {isFormSubmitted && !getInputValid('module_code') && <p className="text-danger">Please select module name</p>}
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="mb-4">
                                <label className="form-label mb-0">Sub Module</label>
                                <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>*</span>
                                <select name="sub_module" className="form-select form-select-lg mb-3" onChange={(e) => { handleSubModuleChange(e) }}>
                                    <option value="">Select sub module</option>
                                    {subModuleData.map((name: any, i: number) => (
                                        <option key={i} value={name.sub_module}>
                                            {name.sub_module}
                                        </option>
                                    ))}
                                </select>
                                {isFormSubmitted && !getInputValid('sub_module') && <p className="text-danger">Please select sub module</p>}
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="mb-4">
                                <label className="form-label mb-0"> Module Version</label>
                                <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>*</span>
                                <select name="module_ver" className="form-select form-select-lg mb-3" onChange={(e) => { handleModuleVersionChange(e) }}>
                                    <option value="">Select module version</option>
                                    {moduleVersionData.map((name: any, i: number) => (
                                        <option key={i} value={name.module_ver}>
                                            {name.module_ver}
                                        </option>
                                    ))}
                                </select>
                                {isFormSubmitted && !getInputValid('module_ver') && <p className="text-danger">Please select module version</p>}
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="mb-4">
                                <label className="form-label mb-0">Target Standard</label>
                                <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>*</span>
                                <select name="target_std" className="form-select form-select-lg mb-3" onChange={(e) => { handleTargetChange(e) }}>
                                    <option value="">Select target standard</option>
                                    {targetData.map((name: any, i: number) => (
                                        <option key={i} value={name.target_std}>
                                            {name.target_std}
                                        </option>
                                    ))}
                                </select>
                                {isFormSubmitted && !getInputValid('target_std') && <p className="text-danger">Please select target standard</p>}
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="mb-4">
                                <label className="form-label mb-0">Student</label>
                                <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>*</span>
                                <MultiSelect
                                    options={studentList}
                                    value={studentsSelected}
                                    onChange={setStudentsSelected}
                                    labelledBy="Select"
                                />

                                {isFormSubmitted && !getInputValid('user_code') && <p className="text-danger">Please select student</p>}
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="mb-4">
                                <label className="form-label mb-0">Amount</label>
                                <input className="form-control form-control-lg" type="text" name="amount" value={amount} />
                            </div>
                        </div>
                    </div>
                </form>
            </FormBuilder>
            <div className="form-footer border-top border-primary py-3 text-end mt-4">
                <a className="btn btn-primary btn-sm px-5 rounded-12 cursor-pointer" onClick={handleSubmit}>Assign</a>
                <Link className="text-dark text-decoration-none ms-2 cursor-pointer" to={`/dashboard/school/info/${schoolId}/modulelist/${schoolsCode}`}>Cancel</Link>
            </div>
        </div>
    )
}
