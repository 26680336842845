import { DataTableCol } from "../../../components/data-table/types";

export const DepartmentDataGridCols: DataTableCol[] = [
    {
        title: 'Department Name',
        control: 'department_name',
        sortable: true,
        canShowColumn: true

    },

    {
        title: 'Department Description',
        control: 'department_description',
        sortable: true,
        canShowColumn: true

    },
    {
        title: 'Date',
        control: 'created_at',
        sortable: true,
        canShowColumn: true

    },
    {
        title: 'Actions',
        control: 'both',
        sortable: false,
        isEdit: false,
        isDelete: false,
        canShowColumn: false
    },
];
