import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { Link, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import rightArrowImg from '../../../../assets/images/Arrow-Right.png';
import downArrowImg from '../../../../assets/images/Arrow-Down.png';
import badgeImg from '../../../../assets/images/Badge.png';
import closeIcon from '../../../../assets/images/close_icon.png';
import SUBJECTCOMPLETE from '../../../../institute-dashboard-assets/images/subj-completed-logo.svg'
import { FETCH_ABHYAS_USER_QUESTION_INFO } from '../../../../app/service/abhyas-module-questions.service';
import { GET_SUBJECT_BY_UUID } from '../../../../app/service/abhyas-subject.service';
import { FETCH_PARIKSHA_USER_QUESTION_INFO } from '../../../../app/service/pariksha-module-questions';
import { GET_MODULES_BY_USER_CODES } from '../../../../app/service/module.service';
import { CREATE_PAYMENT, CREATE_PAYMENT_DETAILS_SCHOOL_MODULE_CODE } from '../../../../app/service/payment-service';
import { UI_BASE_URL } from '../../../../config/constant';
import { CREATE_CCAVENUE_PAYMENT } from '../../../../app/service/ccavenue.service';
import { CREATE_MNS_USER_ASSIGN } from '../../../../app/service/mns-service';
import { ADD_UPDATE_SUBSCRIPTION_PRICE } from '../../../../app/service/subscription.service';

export const AbhyasProgressView = (props: any) => {
    const [loading, setLoading] = useState(false);
    const [topicResult, setTopicResult] = useState<any>([]);
    const [priorityItems, setPriorityItems] = useState<any>([]);
    const [parikshaItems, setParikshaItems] = useState<any>(null);
    const [canShowSubItems, setShowSubItems] = useState(false);
    const [canShowPopup, setCanShowPopup] = useState(false);
    const [currentCycleInfo, setCurrentCycleInfo] = useState<any>(null);
    const [previousCycleInfo, setPreviousCycleInfo] = useState<any>(null);
    const [cycleInfo, setCycleInfo] = useState<any>([]);
    const [subjectData, setSubjectData] = useState<any>({});
    const [MNSInfo, setMNSInfo] = useState(false);
    const [parikshaInfo, setParikshaInfo] = useState<any>({});
    const [canShowPaymentPopup, setCanShowPaymentPopup] = useState<boolean>(false);
    const history = useHistory();
    const [moduleData, setModuleData] = useState<any>({});
    const [paymentInfo, setPaymentInfo] = useState<any>({});
    let { moduleUUID, userCode, schoolCode, BoardUuid, MediumUuid, StandardUuid, SubjectUuid } = useParams<{ moduleUUID: string, userCode: string, schoolCode: string, BoardUuid: string, MediumUuid: string, StandardUuid: string, SubjectUuid: string }>();
    const modules_uuid = moduleUUID;
    const user_code = userCode;
    const school_code = schoolCode;
    const boardUuid = BoardUuid;
    const mediumUuid = MediumUuid;
    const standardUuid = StandardUuid;
    const subjectUuid = SubjectUuid;

    useEffect(() => {
        getModuleData();
        getSubjectInfo()
        getAbhyasCompleteInfo();

        const splitLocation: any = window.location.href.split("?");
        const queryString = splitLocation[1];
        if (queryString) {
            const match = queryString.match(/status=([^&]*)/);
            if (match) {
                const statusValue = match[1];
                const studentInfo: any = JSON.parse(localStorage.getItem('cc_student_info') || '{}');
                const studentPaymentInfo = JSON.parse(localStorage.getItem('cc_user_payment_info') || '{}');
                const txnId = localStorage.getItem("cc_transaction_id");
                const moduleInfo = JSON.parse(localStorage.getItem('cc_module_info') || '{}');
                if (txnId && statusValue === "success") {
                    updateSubscription(txnId, "", studentPaymentInfo, statusValue, studentInfo, moduleInfo);
                } else {
                    removeItemsFromeLocalStorage();
                }
            } else {
            }
        }

    }, []);

    function getAbhyasCompleteInfo() {
        let payload = {
            module_uuid: modules_uuid,
            school_code: school_code,
            user_code: user_code,
            cycle_no: '',
            board_uuid: boardUuid,
            medium_uuid: mediumUuid,
            standard_uuid: standardUuid,
            subject_uuid: subjectUuid
        }
        localStorage.setItem('userSelectedSubjectInfo', JSON.stringify(payload));
        setLoading(true);
        FETCH_ABHYAS_USER_QUESTION_INFO(payload).then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res?.err);
                setLoading(false);
            } else {
                if (res) {
                    const data = res;
                    setCurrentCycleInfo(data);
                    getParikshaCompleteInfo();
                    if (data?.status === 'completed') {
                        setShowSubItems(false);
                    } else {
                        setShowSubItems(true);
                    }
                    let status = data?.tests?.find((x: any) => x.status === 'completed');
                    if (data.cycle_no > 1 && !status) {
                        getRequestedCycleData(data.cycle_no - 1);
                    }
                    if (data && data?.priorities) {
                        data.priorities = data?.priorities?.map((item: any, index: number) => {
                            item.isSelected = false;
                            return item;
                        })
                        setPriorityItems(data?.priorities);
                    }


                }
            }
        });
    }

    function getRequestedCycleData(cycle_no: any) {
        let payload = {
            "module_uuid": modules_uuid,
            "school_code": school_code,
            "user_code": user_code,
            "cycle_no": cycle_no ? cycle_no : '',
            "board_uuid": boardUuid,
            "medium_uuid": mediumUuid,
            "standard_uuid": standardUuid,
            "subject_uuid": subjectUuid
        }

        FETCH_ABHYAS_USER_QUESTION_INFO(payload).then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res.message);
            } else {
                if (res) {
                    const data = res;
                    setPreviousCycleInfo(data);
                    if (data && data?.priorities) {
                        data.priorities = data?.priorities?.map((item: any, index: number) => {
                            item.isSelected = false;
                            return item;
                        })
                        setPriorityItems(data?.priorities);
                    }
                }
            }
        });
    }
    function getModuleData() {
        GET_MODULES_BY_USER_CODES(userCode).then((res: any) => {

            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                } else {
                    const data = res.filter((el: any) => el.module_code === 'PARIKSHA');
                    setModuleData(data[0]);
                }
            }
        });
    }
    const getSubjectInfo = () => {
        GET_SUBJECT_BY_UUID(subjectUuid).then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res?.err);
            } else {
                if (res?.rows?.length > 0) {
                    setSubjectData(res?.rows[0]);
                }
            }
        });
    }
    function getParikshaCompleteInfo() {
        let payload = {
            module_uuid: modules_uuid,
            school_code: school_code,
            user_code: user_code,
            board_uuid: boardUuid,
            medium_uuid: mediumUuid,
            standard_uuid: standardUuid,
            subject_uuid: subjectUuid
        }
        FETCH_PARIKSHA_USER_QUESTION_INFO(payload).then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res?.err);
                setLoading(false);

            } else {
                if (res) {
                    const data = res;
                    setParikshaInfo(data);
                    if (data && data?.tests) {
                        data.tests = data?.tests?.map((item: any, index: number) => {
                            item.isSelected = false;
                            return item;
                        })
                        setParikshaItems(data.tests);
                    }
                    setLoading(false);

                }
            }
        });
    }


    const getAbhyasTestCompletedStatus = (cycle_info: any) => {
        if (cycle_info && cycle_info.tests) {
            let isCompleted = true;
            for (let i = 0; i < cycle_info.tests.length; i++) {
                if (cycle_info.tests[i].status === 'pending') {
                    isCompleted = false;
                }
            }
            return isCompleted
        } else {
            return false;
        }

    }

    const getCompletedCycleInfoToShow = () => {
        if (currentCycleInfo && previousCycleInfo && currentCycleInfo.tests_status === 'pending'
            && previousCycleInfo.tests_status === 'completed') {
            return previousCycleInfo;
        } else {
            return currentCycleInfo;
        }
    }


    function onClickAttemptsInfo(cycleInfo: any) {

        // return
        if (getAbhyasTestCompletedStatus(cycleInfo)) {
            history.push(`/student-dashboard/list/abhyas/ability-result/${modules_uuid}/${user_code}/${school_code}`);
        } else if (cycleInfo && cycleInfo.tests) {
            for (let i = 0; i < cycleInfo.tests.length; i++) {
                cycleInfo.tests[i].subject_name = subjectData?.name?.charAt(0).toUpperCase() + subjectData?.name?.slice(1).toLowerCase();
                if (cycleInfo.tests[i].status === 'pending' && cycleInfo.tests[i]?.question_paper_uuid) {
                    localStorage.setItem("backword_key", "false");
                    localStorage.setItem('abhyas_test_info', JSON.stringify(cycleInfo.tests[i]));
                    localStorage.setItem('abhyas_type', 'TESTS');
                    localStorage.setItem('abhyas_cycle_no', cycleInfo.cycle_no);
                    // localStorage.setItem('abhyas_test_start_date', cycleInfo.test_start_date);
                    history.push(`/student-dashboard/list/abhyas/questions/${modules_uuid}/${user_code}/${school_code}/${subjectUuid}`);
                    break;
                } else {
                    if (cycleInfo.tests[i]?.question_paper_uuid === null || cycleInfo.tests[i]?.question_paper_uuid === "" || cycleInfo.tests[i]?.question_paper_uuid === 'undefined') {
                        // toast.error(`There is no Test-${cycleInfo.tests[i]?.test_no} available in this ${subjectData?.name?.toUpperCase()}`);
                        toast.error(`There is no Tests available in this ${subjectData?.name?.toUpperCase()}`);
                        break;
                    }
                }
            }
        }
    }

    const canShowPriorityView = () => {
        return priorityItems.find((x: any) => x?.priorities?.length > 0);
    }

    const onClickPracticeInfo = (item: any, index: number) => {
        let items = [...priorityItems];
        items[index].isSelected = !item.isSelected;
        setPriorityItems([...items]);
    }
    const onClickParikshaPracticeInfo = (item: any, index: number) => {
        let items = [...parikshaItems];
        items[index].isSelected = !item.isSelected;
        setParikshaItems([...items]);
    }

    const onClickLevelsInfo = (priorty: any, index: number, ability: any, j: number) => {
        let items = [...priorityItems];
        items[index].abilities[j].isSelected = !ability.isSelected;
        setPriorityItems([...items]);
    }

    const onWorkSheetClick = (topic: any, item: any, levelInfo: any, priority: any) => {

        let info = item;
        // info.topic = topic.ability;
        info.priority = priority;
        info.topic_name = topic?.topic_name
        info.topic_uuid = topic?.topic_uuid
        info.subject_name = subjectData?.name?.charAt(0).toUpperCase() + subjectData?.name?.slice(1).toLowerCase()
        info.worksheet_uuid = item?.uuid;
        // info.level = levelInfo.level;
        if (priority > 1 && priorityItems[priority - 2].status === 'pending') {
            return;
        }

        if (item.status === 'pending' && item?.question_paper_uuid) {
            localStorage.setItem("backword_key", "false");
            const testInfo = info;
            localStorage.setItem('abhyas_test_info', JSON.stringify(testInfo));
            localStorage.setItem('abhyas_type', 'WORKSHEETS');
            localStorage.setItem('abhyas_worksheets', JSON.stringify(topic?.worksheets));
            localStorage.setItem('abhyas_cycle_no', currentCycleInfo.cycle_no);
            history.push(`/student-dashboard/list/abhyas/questions/${modules_uuid}/${user_code}/${school_code}/${subjectUuid}`);
        } else {
            if (item?.question_paper_uuid === null || item?.question_paper_uuid === "" || item?.question_paper_uuid === 'undefined') {
                // toast.error(`No  Questions available in this Worksheet-${item?.worksheet_no}`);
                toast.error(`No  tests are available in this ${topic?.topic_name}`);
            }
        }
    }

    function updateSubscription(transactionId: any, transactionData: any, paymentInfo: any, paymentStatus: any, studentInfo?: any, moduleInfo?: any) {
        if (paymentInfo?.subscription_payment_status === 'pending') {
            const payload = {
                data: [
                    {
                        "uuid": "",
                        "school_code": studentInfo?.institute_school_code,
                        "user_code": paymentInfo?.user_code,
                        "transaction_id": transactionId,
                        "amount": parseInt(paymentInfo?.subscription_price),
                        // "transaction_details": JSON.stringify(transactionData),
                        "transaction_details": "",
                        "payment_status": "completed"
                    }
                ]
            }
            if (paymentStatus === "success") {
                ADD_UPDATE_SUBSCRIPTION_PRICE(payload).then((res: any) => {
                    if (res.status === 'fail') {
                        toast.error(res?.message);
                    } else {
                        getAssignModule(paymentInfo, moduleInfo);
                        toast.success('Subscription Added Successfully');
                    }
                });
            }
        } else {
            getAssignModule(paymentInfo, moduleInfo);
        }
    }


    const renderAbhyasTests = (cycleInfo: any) => {
        return (
            <div>
                <div className='mb-2 cursor-pointer' onClick={() => { onClickAttemptsInfo(cycleInfo) }}>
                    <a className='status-cards d-flex justify-content-between align-items-center mb-2 position-relative'>
                        <div>
                            <label className='f20 m-0 text-black fw-medium d-block'>Assessment Test</label>
                            <span className='text-black fw-light line-hieight-normal'>{getAbhyasTestCompletedStatus(cycleInfo) ? 'Completed' : 'Number of Tests'} </span>
                            <ul className='list-inline m-0 attempts cursor-pointer' >
                                {cycleInfo && cycleInfo?.tests?.map((item: any, i: number) => (
                                    <li className='list-inline-item' key={i} style={{ backgroundColor: item.status === 'completed' ? '#8CC63F ' : '#FFFFFF' }}>{item?.test_no}</li>
                                ))}
                            </ul>

                        </div>
                        <div className='ms-2 cursor-pointer'>
                            {!getAbhyasTestCompletedStatus(cycleInfo) ? <div className='row col-md-4' style={{ float: 'right' }}>
                                <img src={rightArrowImg} style={{ width: '70%', height: '70%', objectFit: 'contain' }} />
                            </div> :
                                <div>
                                    {
                                        cycleInfo?.tests?.map((item: any, index: number) => (

                                            <div className='text-center d-inline-block ms-4' key={index}>
                                                <img src={SUBJECTCOMPLETE} />
                                            </div>
                                        ))
                                    }
                                </div>
                            }
                        </div>

                    </a>
                </div>
            </div>
        )
    }

    const onParikshaWorkSheetClick = (testData: any, topic: any, item: any, levelInfo: any, priority: any) => {

        let info = item;
        // info.topic = topic.ability;
        info.priority = priority;
        info.test_uuid = testData?.uuid;
        info.topic_name = topic?.topic_name
        info.topic_uuid = topic?.topic_uuid
        info.subject_name = subjectData?.name?.charAt(0).toUpperCase() + subjectData?.name?.slice(1).toLowerCase()
        info.worksheet_uuid = item?.uuid;
        info.module_code = "PARIKSHA";
        // info.level = levelInfo.level;
        if (priority > 1 && parikshaItems[priority - 2].status === 'pending') {
            return;
        }

        if (item.status === 'pending' && item?.question_paper_uuid) {
            localStorage.setItem("backword_key", "false");
            const testInfo = info;
            localStorage.setItem('abhyas_test_info', JSON.stringify(testInfo));
            localStorage.setItem('abhyas_type', 'WORKSHEETS');
            localStorage.setItem('abhyas_worksheets', JSON.stringify(topic?.worksheets));
            localStorage.setItem('abhyas_cycle_no', currentCycleInfo.cycle_no);
            history.push(`/student-dashboard/list/abhyas/questions/${modules_uuid}/${user_code}/${school_code}/${subjectUuid}`);
            // history.push(`/student-dashboard/list/pariksha/questions/${modules_uuid}/${user_code}/${school_code}/${subjectUuid}`);
        } else {
            if (item?.question_paper_uuid === null || item?.question_paper_uuid === "" || item?.question_paper_uuid === 'undefined') {
                toast.error(`No  tests are available in this ${topic?.topic_name}`);
            }
        }
    }
    const getChapterName = (name: any) => {
        return `(${name})`

    }
    const handleBack = () => {
        history.push(`/student-dashboard/list/abhyas/test-level-status/${moduleUUID}/${userCode}/${schoolCode}`)
    }
    const onClickCancel = () => {
        setCanShowPaymentPopup(false);
    }
    const clickPariksha = () => {
        if (moduleData?.amount_status === "COMPLETED") {
            history.push(`/student-dashboard/list/pariksha/test-level-status/${moduleData?.uuid}/${userCode}/${schoolCode}`);
        } else {
            if (moduleData?.uuid) {
                getPaymentDetailsOfUser();
            }
        }
    }
    function getPaymentDetailsOfUser() {
        const payLoad = {
            "institute_school_code": school_code,
            "standard": "",
            "division": "",
            "students": [{ user_code: `${user_code}` }],
            "module_uuid": moduleData?.uuid
        }
        CREATE_PAYMENT_DETAILS_SCHOOL_MODULE_CODE(payLoad).then((res: any) => {
            if (res.status === "fail") {
                toast.error(res?.message);
            } else {
                if (res) {
                    let data = res?.students;
                    if (data?.length > 0) {
                        setPaymentInfo(data[0]);
                        localStorage.setItem("cc_user_payment_info", JSON.stringify(data[0]));
                        setCanShowPaymentPopup(true);
                    }
                }
            }
        });

    }
    const handlePayment = () => {
        createPayment(moduleData, paymentInfo);
        localStorage.setItem("cc_module_info", JSON.stringify(moduleData));
    }
    function createPayment(moduleInfo: any, paymentInfo: any) {
        const payload = {
            school_code: school_code,
            user_ids: paymentInfo?.user_code,
            sub_amount: paymentInfo?.total_amount
        }
        if (parseInt(paymentInfo?.total_amount) > 0) {
            CREATE_PAYMENT(payload).then((res: any) => {
                if (res) {
                    if (res.status === 'fail') {
                        toast.error(res.message);
                    } else {
                        onOpenPaymentInfo(payload.sub_amount, res.txn_id, moduleInfo, paymentInfo);
                    }
                }
            });
        } else {
            getAssignModule(paymentInfo, moduleInfo);
        }
    }
    function onOpenPaymentInfo(amount: any, txn_id: string, moduleInfo: any, paymentInfo: any) {
        localStorage.setItem("cc_user_payment_info", JSON.stringify(paymentInfo));
        localStorage.setItem("cc_transaction_id", txn_id)
        createCcavenuePayment(amount, txn_id);
    }
    const createCcavenuePayment = (amount: any, txn_id: string) => {
        let payLoad = {
            amount: amount,
            currency: "INR",
            transaction_uuid: txn_id,
            redirect_url: `${UI_BASE_URL}/student-dashboard/list/abhyas/progress-view/${modules_uuid}/${user_code}/${school_code}/${boardUuid}/${mediumUuid}/${standardUuid}/${subjectUuid}`,
            cancel_url: `${UI_BASE_URL}/student-dashboard/list/abhyas/progress-view/${modules_uuid}/${user_code}/${school_code}/${boardUuid}/${mediumUuid}/${standardUuid}/${subjectUuid}`,
        }
        CREATE_CCAVENUE_PAYMENT(payLoad).then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res?.error);
            } else {
                initiatePayment(res);

            }
        });
    }
    const initiatePayment = async (paymentData: any) => {
        window.location.href = paymentData.url;
    };
    function getAssignModule(paymentInfo: any, moduleData: any) {
        const user_code = [];
        user_code.push(paymentInfo?.user_code);
        const payload = {
            module_uuid: moduleData.uuid ? moduleData.uuid : '',
            payment_status: "COMPLETED",
            school_code: schoolCode,
            target_std: moduleData.target_std,
            total_amount_paid: paymentInfo?.module_price,
            user_codes: user_code
        }
        CREATE_MNS_USER_ASSIGN(payload).then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res.message);
            } else {
                toast.success("Successfully assigned  module!");
                getModuleData()
                removeItemsFromeLocalStorage();
                setCanShowPaymentPopup(false);
            }
        });
    }
    const removeItemsFromeLocalStorage = () => {
        const keysToRemove = ['cc_student_info', 'cc_user_payment_info', 'cc_transaction_id', 'cc_module_info'];
        keysToRemove.forEach((key: any) => {
            localStorage.removeItem(key);
        });
    };
    return (
        <div>
            <div
                className={`modal fade ${canShowPaymentPopup ? "show" : "false"}`}
                style={{ display: canShowPaymentPopup ? "block" : "none" }}
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog pariksha-model-dialog" role="document">
                    <div className="modal-content pariksha-model-content">
                        <div className="modal-body  pariksha-model-info">
                            <div className='pariksha-model-border ba bas-dashed'>
                                <div className=' mt-2 justify-content-center'>
                                    <h6 className='blue_text f18'>You do not have to access to <br />PARIKSHA initiative to take a test and <br /> worksheet assinged by PARIKSHA </h6>
                                </div>
                                <p className='text-center blue_text fw-normal'>You need to pay  <i className="fa fa-rupee pt-3"></i> {paymentInfo?.total_amount}.00  to access <br /> PARIKSHA initiative </p>
                                <div className='start-test mt-4 justify-content-center'>
                                    <h6>Do you want to continue ? </h6>
                                </div>
                                <div className='mt-4'>
                                    <div className='mt-3 d-flex' style={{ justifyContent: 'center' }}>
                                        <button className='btn test-btn' onClick={handlePayment}>Pay Now</button>
                                        <button className='btn test-btn' onClick={onClickCancel}>Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading && <div>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb pb-2 ps-0 pt-0 border-bottom">
                        <li className="breadcrumb-item "><Link to="/student-dashboard/list"><span className='pe-2'>Dashboard</span></Link></li> /
                        <li className="breadcrumb-item "><Link to={`/student-dashboard/list/abhyas/test-level-status/${modules_uuid}/${user_code}/${school_code}`}><span>Abhyaas</span></Link></li> /
                        <li className="breadcrumb-item "><span>{subjectData?.name?.toUpperCase()} Dashboard</span></li>
                    </ol>
                </nav>
                <div className=' ps-lg-2 pe-lg-5'>
                    <div className='' style={{ textAlign: 'right' }}>
                        <button className="btn btn-primary btn-sm px-5 rounded-12 cursor-pointer" onClick={handleBack}>Back</button>
                    </div>
                    <h5 className='text-center f20 mb-5'> ABHYAAS : {subjectData?.name?.toUpperCase()} DASHBOARD </h5>
                    <div className={`mb-2 ${moduleData && " border border-dark p-2 rounded border-1"} `}>
                        {renderAbhyasTests(getCompletedCycleInfoToShow())}
                        {/* {currentCycleInfo && previousCycleInfo && currentCycleInfo.cycle_no > 1 && renderRandomTestView()} */}
                        <div className={`collapse show `} id="collapseExample3">

                            {currentCycleInfo && canShowSubItems && priorityItems && priorityItems.length > 0
                                &&
                                <div>
                                    {/* <h2 className="mt-3 mb-2 f20 text-black fw-medium">Practice</h2> */}
                                    {priorityItems?.map((item: any, index: number) => (
                                        <React.Fragment key={index}>

                                            {item?.topics?.length > 0 && <span
                                                className={`status-cards d-flex justify-content-between align-items-center mb-2 position-relative ${item.isSelected ? "" : "collapsed"} `}
                                                data-bs-toggle="collapse"

                                                role="button"
                                                aria-expanded="false"
                                                aria-controls="collapseExample2" onClick={() => { onClickPracticeInfo(item, index) }}
                                            >
                                                <div>
                                                    <label className="f20 m-0 text-black fw-medium d-block"
                                                    >Practice {item?.priority}</label>
                                                    <h6 className='f14 mt-2'>{item?.topics?.length}{item?.topics?.length < 2 ? ' Topic is in ' : ' Topics are in '} practice</h6>
                                                </div>
                                                {item?.topics?.length > 0 &&
                                                    <i className="status-cards-arrow" >
                                                    </i>}
                                            </span>}

                                            {item.isSelected &&
                                                <div>
                                                    {item?.topics && item?.topics.map((topic: any, j: number) => (
                                                        <span
                                                            className={`status-cards d-flex justify-content-between align-items-center mb-2 position-relative ${MNSInfo ? "" : "collapsed"} `}
                                                            data-bs-toggle="collapse"
                                                            aria-expanded="false"
                                                            aria-controls="collapseExample2"
                                                            key={j}>
                                                            <div className='pb-2'>
                                                                <h3 className='f20 mt-2 text-black fw-medium d-block text-mblue'>{topic?.topic_name}</h3>
                                                                <div className='f14 '>Worksheets</div>
                                                                {topic?.worksheets?.map((sheet: any, k: number) => (
                                                                    <div key={k} className="circle cursor-pointer" style={{ backgroundColor: sheet.status === 'completed' ? (sheet.result === "fail" ? '#f15a24' : '#8CC63F') : '#FFFFFF' }} onClick={() => {
                                                                        onWorkSheetClick(topic, sheet, sheet?.level, item.priority)
                                                                    }}>
                                                                        <p>{k + 1}</p>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                            {item.status === 'completed' &&
                                                                <img src={SUBJECTCOMPLETE} />
                                                            }
                                                            <i className='status-cards-arrow small mt-1'>   </i>
                                                        </span>
                                                    ))}

                                                </div>

                                            }
                                        </React.Fragment>
                                    ))}
                                </div>
                            }
                        </div>
                    </div>

                    <h2 className="mt-3 mb-2 f18 mt-4 text-black fw-medium">Practice assigned by PARIKSHA</h2>

                    <div className="mb-2 border border-dark p-2 rounded  border-1">

                        {(moduleData?.amount_status === "PENDING" || parikshaItems?.length == 0) &&
                            <span
                                className={`status-cards d-flex justify-content-between align-items-center mb-2 position-relative collapsed`}
                                data-bs-toggle="collapse"
                                aria-expanded="false"
                                role="button"
                                aria-controls="collapseExample2"
                            >
                                <div>
                                    <h6 className='text-danger m-0 f12 fw-bold'>You do not have an access! </h6>
                                    <p className='m-0 f12 fw-bold'>To Know more please <span className='text-mblue cursor-pointer f18' onClick={clickPariksha}><u>Click Here</u></span></p>

                                </div>


                                <i className="status-cards-arrow" ></i>
                            </span>

                        }
                        <div>
                            {parikshaItems?.map((item: any, index: number) => (
                                <React.Fragment key={index}>
                                    <span
                                        className={`status-cards d-flex justify-content-between align-items-center mb-2 position-relative ${item.isSelected ? "" : "collapsed"} `}
                                        data-bs-toggle="collapse"
                                        aria-expanded="false"
                                        role="button"
                                        aria-controls="collapseExample2"
                                        onClick={() => { onClickParikshaPracticeInfo(item, index) }} >
                                        <div>{
                                            item?.test_details?.exam_type === "UNIT_TEST_1" && <label className="f18 m-0  fw-medium d-block "
                                            >Unit Test - 1 </label>
                                        }
                                            {
                                                item?.test_details?.exam_type === "UNIT_TEST_2" && <label className="f18 m-0  fw-medium d-block "
                                                >Unit Test - 2 </label>
                                            }
                                            {
                                                (item?.test_details?.exam_type !== "UNIT_TEST_1" && item?.test_details?.exam_type !== "UNIT_TEST_2") && <label className="f18 m-0  fw-medium d-block "
                                                >{item?.test_details?.exam_type?.split('_')
                                                    .map((word: any) => word?.charAt(0).toUpperCase() + word?.slice(1).toLowerCase())
                                                    .join(' ')}

                                                    {/* {(item?.test_details?.exam_type === "CHAPTER_WISE_TEST") && (getChapterName(item?.topics[0]?.chapter_name))} */}
                                                    {/* {(item?.test_details?.exam_type === "CHAPTER_WISE_TEST") && <h4 className='ms-2'> {item?.topics[0]?.chapter_name}</h4>} */}

                                                </label>}
                                            {item?.test_details?.exam_type === "CHAPTER_WISE_TEST" && <h5 className='f16 text-black'> {getChapterName(item?.topic_wise_result[0]?.chapter_name)}</h5>}

                                            <h6 className='f14 mt-2'>{item?.topics?.length}{item?.topics?.length < 2 ? ' Topic is in ' : ' Topics are in '} practice</h6>

                                        </div>

                                        {item?.topics?.length > 0 &&
                                            <i className="status-cards-arrow" ></i>
                                        }
                                    </span>

                                    {item.isSelected &&
                                        <div>
                                            {item?.topics && item?.topics?.map((topic: any, j: number) => (
                                                <span
                                                    className={`status-cards d-flex justify-content-between align-items-center mb-2 position-relative ${MNSInfo ? "" : "collapsed"} `}
                                                    data-bs-toggle="collapse"
                                                    aria-expanded="false"
                                                    aria-controls="collapseExample2"
                                                    key={j}>
                                                    <div className='pb-2'>
                                                        <h3 className='f20 mt-2 text-black fw-medium d-block text-mblue'>{topic?.topic_name}
                                                            {/* {(topic?.chapter_name && item?.test_details?.exam_type === "CHAPTER_WISE_TEST") && (getChapterName(topic?.chapter_name))} */}
                                                        </h3>
                                                        <div className='f14 '>{topic?.worksheets?.length > 0 ? "Worksheets" : "No Worksheets"}</div>
                                                        {topic?.worksheets?.map((sheet: any, k: number) => (
                                                            <div key={k} className="circle cursor-pointer" style={{ backgroundColor: sheet.status === 'completed' ? (sheet.result === "fail" ? '#f15a24' : '#8CC63F') : '#FFFFFF' }} onClick={() => {
                                                                onParikshaWorkSheetClick(item, topic, sheet, sheet?.level, item.priority)
                                                            }}>
                                                                <p>{k + 1}</p>
                                                            </div>
                                                        ))}
                                                    </div>
                                                    {(topic.status === 'completed' && topic?.worksheets?.length > 0) &&
                                                        <img src={SUBJECTCOMPLETE} />
                                                    }
                                                    {(topic.status === 'pending') && <i className='status-cards-arrow small mt-1'></i>}
                                                </span>
                                            ))}
                                        </div>
                                    }
                                </React.Fragment>
                            ))}
                        </div>

                    </div>
                </div>
            </div>}
        </div>
    )
}