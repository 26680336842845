import { Switch, Route, Redirect } from "react-router";
import AddSchoolPage from "../school/add-school";
import AddInstitutePage from "./add-institute";
import { InstituteFormPage } from "./form";
import { InstituteInFo } from "./instituteinfo";
import { InstituteList } from "./list";


interface Props {
    match?: any;
    courseId: number;
}
const InstituteRoutes = (props: Props) => {

    let url: string | undefined = props.match?.url;
    if (url?.endsWith('/')) {
        url = url.substr(0, url.length - 1);
    }

    return (
        <Switch>
            <Route exact path={`${url}/list`} >
                <InstituteList></InstituteList>
            </Route>
            <Route exact path={`${url}/form/:id`} >
                <InstituteFormPage></InstituteFormPage>
            </Route>
            <Route path={`${url}/new`} exact>
                <AddInstitutePage></AddInstitutePage>
            </Route>
            <Route path={`${url}/new/:id`} exact>
                <AddInstitutePage></AddInstitutePage>
            </Route>
            <Route path={`${url}/info/:id`} exact>
                <InstituteInFo></InstituteInFo>
            </Route>
            <Route path={`${url}/info/:id/chairmanlist/:schoolCode`} exact>
                <InstituteInFo></InstituteInFo>
            </Route>
            <Route path={`${url}/info/:id/chairmanform/:schoolCode/:userId`} exact>
                <InstituteInFo></InstituteInFo>
            </Route>
            <Route path={`${url}/info/:id/generalsecretaryform/:schoolCode/:userId`} exact>
                <InstituteInFo></InstituteInFo>
            </Route>
            <Route path={`${url}/info/:id/generalsecretarylist/:schoolCode`} exact>
                <InstituteInFo></InstituteInFo>
            </Route>
            <Route path={`${url}/info/:id/adminform/:schoolCode/:userId`} exact>
                <InstituteInFo></InstituteInFo>
            </Route>
            <Route path={`${url}/info/:id/adminlist/:schoolCode`} exact>
                <InstituteInFo></InstituteInFo>
            </Route>
        </Switch>
    );
}
export default InstituteRoutes;