import API from "../utility/axios";
const apiUrl = "/api/v2";
const apiUrlV1 = "/api/v1";

export const GET_SCHOOL_DASHBOARD = (data: String): Promise<any> => {
  return API.get(`${apiUrl}/dashboard/school/stats?school_code=${data}`);
};

export const GET_REGISTERED_STUDENTS = (
  data: String,
  userRole: String,
  pageNumber: number
): Promise<any> => {
  return API.get(
    `${apiUrlV1}/user?institute_school_code=${data}&user_role=${userRole}&limit=${10}&page_num=${pageNumber}`
  );
};

export const GET_REGISTERED_STUDENTS_PAGE_NUMBER = (
  data: any,
  userRole: String,
  pageNumber: number
): Promise<any> => {
  return API.get(
    `${apiUrlV1}/user?institute_school_code=${data}&user_role=${userRole}&limit=${10}&page_num=${pageNumber}`
  );
};
// export const GET_REGISTERED_STUDENTS = (data: String, pageNumber: number): Promise<any> => {
//     return API.get(`${apiUrl}/dashboard/registered/students/cas?school_code=${data}&limit=${10}&page_num=${pageNumber}`);
// }

// export const GET_REGISTERED_STUDENTS_PAGE_NUMBER = (data: any, pageNumber: number): Promise<any> => {
//     return API.get(`${apiUrl}/dashboard/registered/students/cas?school_code=${data}&limit=${10}&page_num=${pageNumber}`);
// }

export const GET_ACTIVE_STUDENTS = (
  data: String,
  pageNumber: number
): Promise<any> => {
  return API.get(
    `${apiUrl}/dashboard/active/students?school_code=${data}&limit=${10}&page_num=${pageNumber}`
  );
};

export const GET_ACTIVE_STUDENTS_PAGE_NUMBER = (
  data: any,
  pageNumber: number
): Promise<any> => {
  return API.get(
    `${apiUrl}/dashboard/active/students?school_code=${data}&limit=${10}&page_num=${pageNumber}`
  );
};
