import React from 'react'
import { Route, Switch } from 'react-router'
import { InstituteAdminForm } from '../pages/dashboard/institute/admin/form'
import { InstituteAdminList } from '../pages/dashboard/institute/admin/list'
import { ChairmanForm } from '../pages/dashboard/institute/chairman/form'
import { ChairmanList } from '../pages/dashboard/institute/chairman/list'
import { GeneralSecretaryForm } from '../pages/dashboard/institute/generalsecretary/form'
import { GeneralSecretaryList } from '../pages/dashboard/institute/generalsecretary/list'

const ProfileNestedInstitute = () => {
    return (
        <Switch>
            <Route exact path="/institute-dashboard/institute/info/:id/adminlist/:instituteCode" component={InstituteAdminList}></Route>
            <Route path="/institute-dashboard/institute/info/:id/adminform/:instituteCode/:userId" component={InstituteAdminForm}></Route>
            <Route path="/institute-dashboard/institute/info/:id/chairmanlist/:instituteCode" component={ChairmanList}></Route>
            <Route path="/institute-dashboard/institute/info/:id/chairmanform/:instituteCode/:userId" component={ChairmanForm}></Route>
            <Route path="/institute-dashboard/institute/info/:id/generalsecretarylist/:instituteCode" component={GeneralSecretaryList}></Route>
            <Route path="/institute-dashboard/institute/info/:id/generalsecretaryform/:instituteCode/:userId" component={GeneralSecretaryForm}></Route>
        </Switch>
    )
}

export default ProfileNestedInstitute;