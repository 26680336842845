import React, { useEffect, useState } from 'react'
import { NavLink, useHistory, useLocation, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { GET_USER_BY_ID, GET_USER_USER_CODE } from '../../../../app/service/users.service';
import appConfig from '../../../../config/constant';
import NestedParent from '../../../../routes/insideparent';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { DateComponent } from '../../../../components/date';
import moment from 'moment';

export const ParentInfo = () => {
    const [isActive, setIsActive] = useState(true);
    const [parentInfo, setParentInfo] = useState<any>({});
    const [schoolCode, setSchoolCode] = useState<any>();
    const [studentCode, setStudentCode] = useState<any>();
    const [loading, setLoading] = useState(false);
    const [canShowPopup, setCanShowPopup] = useState(false);
    const [parentLogo, setParentLogo] = useState<any>();
    const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'));
    let { id, schoolcode, studentId } = useParams<{ id: string, schoolcode: string, studentId: string }>();
    const schoolId = Number(id);
    const schoolsCode = schoolCode;
    const studentsCode = studentId;
    const location = useLocation();
    const history = useHistory();
    const { pathname } = location;
    //Javascript split method to get the name of the path in array
    const splitLocation = pathname.split("/");

    useEffect(() => {
        setLoading(true);
        if (schoolId > 0) {
            GET_USER_USER_CODE(studentsCode).then((response: any) => {
                if (response.status === 'fail') {
                    toast.error(response.message);
                    setLoading(false);
                } else {
                    const data = response.rows[0];
                    setLoading(false);
                    if (data.date_of_birth) {
                        const date_of_birth: any = new Date(data.date_of_birth);
                        setStartDate(date_of_birth);
                    }
                    setParentInfo(data);
                    setSchoolCode(data.institute_school_code);
                    setStudentCode(data.user_code);
                    setParentLogo(data.profile_img);
                }
            });
        }
    }, []);

    function onClickViewDetail() {
        setCanShowPopup(true);
    }

    const handleclose = () => {
        setCanShowPopup(false);
    }
    return (
        <div>

            <div className="row border-top border-primary py-3">
                <div className={`modal fade ${canShowPopup ? 'show' : 'false'}`} style={{ display: (canShowPopup ? "block" : 'none') }} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog parent-model-dialog" role="document">
                        <div className="modal-content parent-model">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Parent  Details</h5>
                                <button type="button" className="close popup-close cursor-pointer" data-dismiss="modal" aria-label="Close" onClick={handleclose}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body parent-info">
                                <div className="row custom-form">
                                    <div className="col-md-4">
                                        <div className="mb-4">
                                            <label className="form-label mb-0">Name</label>
                                            <input className="form-control form-control-lg" type="text" name="name" disabled defaultValue={parentInfo.name} placeholder="Please enter name" />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="mb-4">
                                            <label className="form-label mb-4">Gender</label>
                                            <br />
                                            <input type="radio" name="gender" value="male" disabled checked={parentInfo?.gender === "male"} />
                                            <span style={{ margin: '0px 12px' }}>Male</span>
                                            <input type="radio" name="gender" value="female" disabled checked={parentInfo?.gender === "female"} />
                                            <span style={{ margin: '0px 12px' }}>Female</span>
                                            <input className="mb-0" type="radio" value="transgender" name="gender" disabled checked={parentInfo?.gender === "transgender"} />
                                            <span style={{ paddingRight: '15px', paddingLeft: '15px' }}>Other</span>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="mb-4">
                                            <label className="form-label mb-0">Date of Birth</label>
                                            {parentInfo.date_of_birth &&
                                                <DateComponent yearDiff={0} idDisable={true} defaultData={parentInfo.date_of_birth}></DateComponent>}
                                            {!parentInfo.date_of_birth && <DateComponent idDisable={true} defaultData={startDate} yearDiff={0}></DateComponent>}

                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="mb-4">
                                            <label className="form-label mb-0">City/Village (Optional)</label>
                                            <input className="form-control form-control-lg" type="text" name="city_village" disabled defaultValue={parentInfo.city_village} placeholder="Please enter city/village" />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="mb-4">
                                            <label className="form-label mb-0">Email ID</label>
                                            <input className="form-control form-control-lg" type="text" name="email_id" disabled defaultValue={parentInfo.email_id} placeholder="Please enter email" />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="mb-4">
                                            <label className="form-label mb-0">Blood Group</label>
                                            <select name="blood_group" value={parentInfo.blood_group} disabled className="form-select form-select-lg mb-3">
                                                <option value="">Select blood group</option>
                                                <option value="O+">O+</option>
                                                <option value="O-">O-</option>
                                                <option value="A-">A-</option>
                                                <option value="A+">A+</option>
                                                <option value="B-">B-</option>
                                                <option value="B+">B+</option>
                                                <option value="AB-">AB-</option>
                                                <option value="AB+">AB+</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="mb-4">
                                            <label className="form-label mb-0">Mobile Number</label>
                                            <input className="form-control form-control-lg" type="text" disabled name="mobile_number" defaultValue={parentInfo.mobile_number} placeholder="Please enter mobile number" />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="mb-4">
                                            <label className="form-label mb-0">WhatsApp Number</label>
                                            <input className="form-control form-control-lg" type="text" disabled name="whatsapp_number" defaultValue={parentInfo.whatsapp_number} placeholder="Please enter whatsapp number" />
                                        </div>
                                    </div>
                                    {parentLogo && <div className="col-md-4">
                                        <div className="mb-4">
                                            <label className="form-label mb-0 d-block">Preview Photo</label>
                                            <img src={`${appConfig.FILE_UPLOAD_URL}${parentLogo.charAt(0) === "/" ? parentLogo.substring(1) : parentLogo}`} style={{ height: '100px', width: '100px', objectFit: 'contain' }} />
                                        </div>
                                    </div>}
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary cursor-pointer" data-dismiss="modal" onClick={handleclose}>Close</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div>
                        <div>
                            <h2>Parent Details</h2>
                        </div>
                        <div className="mar-t-20 custom-scroll table-responsive">
                            <table className="table fixed-table-header mb-0">
                                <thead>
                                    <tr>
                                        <th>Parent Name</th>
                                        <th>Mobile Number</th>
                                        <th>Email ID</th>
                                        <th>City</th>
                                        <th>State</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{parentInfo.name}</td>
                                        <td>{parentInfo.mobile_number}</td>
                                        <td>{parentInfo.email_id}</td>
                                        <td>{parentInfo.taluka}</td>
                                        <td>{parentInfo.state}</td>
                                        <td>
                                            <button className="file btn btn-sm btn-primary px-4 rounded-12 d-inline-block cursor-pointer" onClick={() => onClickViewDetail()}>View More Info</button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className="mt-5">
                        <ul className="nav nav-tabs custom-tabs">
                            {schoolCode && <li className="nav-item">
                                <NavLink className={splitLocation[5] === "childform" ? "nav-link active" : "nav-link active"} to={{ pathname: `/dashboard/parent/info/${schoolId}/childlist/${schoolCode}/${studentCode}`, state: { data: "" } }}>Child</NavLink>
                            </li>}
                            {schoolCode === null && <li className="nav-item">
                                <NavLink className={splitLocation[5] === "childform" ? "nav-link active" : "nav-link active"} to={{ pathname: `/dashboard/parent/info/${schoolId}/childlist/1234567/${studentCode}`, state: { data: "" } }}>Child</NavLink>
                            </li>}
                        </ul>
                        <div>
                            <NestedParent />
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}
