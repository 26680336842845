import { UsersListItem } from "../model/users/users-list";
import API from "../utility/axios";
const apiUrl = "/api/v1";
const newApiUrl = "/api/v2";

export const CREATE_USER = (data: any): Promise<any> => {
  return API.post(`${apiUrl}/user`, data);
};

export const UPDATE_USER = (data: any): Promise<any> => {
  return API.put(`${apiUrl}/user`, data);
};

export const DELETE_USER = (id: any): Promise<UsersListItem[]> => {
  return API.delete(`${apiUrl}/user/${id}`);
};

export const GET_USER = (): Promise<UsersListItem[]> => {
  return API.get(`${apiUrl}/user`);
};

export const GET_USER_USER_CODE = (userCode: any): Promise<UsersListItem[]> => {
  return API.get(`${apiUrl}/user?user_code=${userCode}`);
};

export const CREATE_MSB_USER = (data: any): Promise<UsersListItem[]> => {
  return API.post(`${apiUrl}/msb/user`, data);
};

export const UPDATE_MSB_USER = (data: any): Promise<UsersListItem[]> => {
  return API.put(`${apiUrl}/msb/user`, data);
};

export const DELETE_MSB_USER = (id: any): Promise<UsersListItem[]> => {
  return API.delete(`${apiUrl}/msb/user/${id}`);
};

export const GET_MSB_USER = (
  pageNumber: number,
  data: any
): Promise<UsersListItem[]> => {
  return API.get(`${apiUrl}/msb/user?limit=${10}&page_num=${pageNumber}`, {
    params: data,
  });
};

export const GET_USER_BY_SCHOOL_CODE = (
  schoolCode: string,
  user_role: string,
  pageNumber: number,
  data: any
): Promise<UsersListItem[]> => {
  return API.get(
    `${apiUrl}/user?institute_school_code=${schoolCode}&user_role=${user_role}&limit=${10}&page_num=${pageNumber}`,
    { params: data }
  );
};

export const GET_USER_AD_SCHOOL_CODE = (
  schoolCode: string,
  user_role: string,
  pageNumber: number,
  data: any
): Promise<UsersListItem[]> => {
  return API.get(
    `${apiUrl}/user?institute_school_code=${schoolCode}&user_role=${user_role}&limit=${10}&page_num=${pageNumber}`,
    { params: data }
  );
};
export const GET_USER_VP_SCHOOL_CODE = (
  schoolCode: string,
  user_role: string,
  pageNumber: number,
  data: any
): Promise<UsersListItem[]> => {
  return API.get(
    `${apiUrl}/user?institute_school_code=${schoolCode}&user_role=${user_role}&limit=${10}&page_num=${pageNumber}`,
    { params: data }
  );
};

export const GET_USERS_BY_INSTITUTES_CODE = (
  schoolCode: string,
  user_role: string,
  data: any
): Promise<UsersListItem[]> => {
  return API.get(
    `${apiUrl}/user?institute_school_code=${schoolCode}&user_role=${user_role}`,
    { params: data }
  );
};

export const GET_USERS_BY_SCHOOL_CODE = (
  schoolCode: string,
  user_role: string,
  pageNumber?: number
): Promise<UsersListItem[]> => {
  return API.get(
    `${apiUrl}/user?institute_school_code=${schoolCode}&user_role=${user_role}&limit=${50}&page_num=${
      pageNumber ? pageNumber : 0
    }`
  );
};

export const GET_USER_BY_ID = (userId: any): Promise<UsersListItem[]> => {
  return API.get(`${apiUrl}/user?id=${userId}`);
};

export const GET_MSB_USER_BY_ID = (userId: any): Promise<UsersListItem[]> => {
  return API.get(`${apiUrl}/msb/user?id=${userId}`);
};

export const GET_USER_BY_CODE = (userCode: any): Promise<UsersListItem[]> => {
  return API.get(`${apiUrl}/msb/user?user_code=${userCode}`);
};

export const GET_MSB_USER_Filter = (data: any): Promise<any> => {
  // return API.get(`${apiUrl}/msb/user`, { params: data });
  return API.get(`${apiUrl}/msb/user?limit=${10}&page_num=${0}`, {
    params: data,
  });
};

export const GET_USER_BY_AADHAR_NUMBER = (
  Id: any
): Promise<UsersListItem[]> => {
  return API.get(`${apiUrl}/user?aadhar_number=${Id}`);
};

export const GET_PARENT_BY_STUDENT = (
  userIds: any
): Promise<UsersListItem[]> => {
  return API.get(`${apiUrl}/user?guardian=${userIds}`);
};

export const BULK_UPLOAD_STUDENTS = (data: any): Promise<any[]> => {
  return API.post(`${apiUrl}/student/upload`, data);
};
export const BULK_UPLOAD_TEACHERS = (data: any): Promise<any[]> => {
  return API.post(`${apiUrl}/teacher/upload`, data);
};

export const GET_PARENT_BY_CHILDRENS = (
  userId: any,
  pageNumber: number
): Promise<UsersListItem[]> => {
  return API.get(
    `${apiUrl}/user?guardian=${userId}&type=students&limit=${10}&page_num=${pageNumber}&status=${1}`
  );
};

export const GET_USER_Filter = (schoolCode: any, data: any): Promise<any> => {
  return API.get(
    `${apiUrl}/user?institute_school_code=${schoolCode}&user_role=${"STUDENT"}&limit=${10}&page_num=${0}`,
    { params: data }
  );
};

export const DOWNLOAD_STUDENTS = (schoolCode: any): Promise<any[]> => {
  return API.get(
    `${apiUrl}/user?institute_school_code=${schoolCode}&user_role=${"STUDENT"}&download=${true}`
  );
};

export const TEACHER_DOWNLOAD_HIS_STUDENTS = (
  schoolCode: any,
  standard: any,
  division: any
): Promise<any[]> => {
  return API.get(
    `${apiUrl}/user?institute_school_code=${schoolCode}&standard=${standard}&division=${division}&user_role=${"STUDENT"}&download=${true}`
  );
};

export const DOWNLOAD_STUDENT_TEMPLATE = (type: any): Promise<any[]> => {
  return API.get(`${apiUrl}/templates?type=${type}`);
};

export const CREATE_INDIVIDUAL_STUDENT = (
  data: any
): Promise<UsersListItem[]> => {
  return API.post(`${apiUrl}/individual/user`, data);
};

export const UPDATE_INDIVIDUAL_STUDENT = (
  data: any
): Promise<UsersListItem[]> => {
  return API.put(`${apiUrl}/individual/user`, data);
};

export const GET_INDIVIDUAL_USER = (
  userRole: any,
  pageNumber: any,
  data: any
): Promise<UsersListItem[]> => {
  return API.get(
    `${apiUrl}/individual/user?user_role=${userRole}&limit=${10}&page_num=${pageNumber}`,
    { params: data }
  );
};

export const DELETE_INDIVDUAL_STUDENT = (id: any): Promise<UsersListItem[]> => {
  return API.delete(`${apiUrl}/individual/user/${id}`);
};

export const GET_INDIVIDUAL_USER_FILTER = (data: any): Promise<any> => {
  // return API.get(`${apiUrl}/individual/user?&limit=${10}&page_num=${0}`, { params: data });
  return API.get(
    `${apiUrl}/individual/user?user_role=${"STUDENT"}&limit=${10}&page_num=${0}`,
    { params: data }
  );
};

export const GET_INDIVIDUAL_USER_USER_CODE = (userCode: any): Promise<any> => {
  return API.get(`${apiUrl}/individual/user?user_code=${userCode}`);
};

export const GET_PARENT_BY_INDIVIDUAL_STUDENT = (
  userIds: any
): Promise<UsersListItem[]> => {
  return API.get(`${apiUrl}/individual/user?guardian=${userIds}`);
};

export const DELETE_INDIVIDUAL_USER = (id: any): Promise<UsersListItem[]> => {
  return API.delete(`${apiUrl}/individual/user/${id}`);
};

export const GET_MODULES_BY__INDIVIDUAL_USER_CODE = (
  userCode: string
): Promise<any> => {
  return API.get(`${apiUrl}/individual/user/module?user_code=${userCode}`);
};

export const GET_INDIVIDUAL_BY_USER_CODE = (userCode: string): Promise<any> => {
  return API.get(`${apiUrl}/individual/user?user_code=${userCode}`);
};

export const GET_INDIVIDUAL_STUDENT_BY_ID = (id: number): Promise<any> => {
  return API.get(`${apiUrl}/individual/user?id=${id}`);
};

export const VALIDATE_USER_NAME = (data: any): Promise<any> => {
  return API.post(`${apiUrl}/validate/username`, data);
};

export const GET_STUDENTS_BY_PAYMENT_MODULE = (
  school_code: any,
  module_uuid: any
): Promise<any> => {
  return API.get(
    `${apiUrl}/school/user/module?school_code=${school_code}&module_uuid=${module_uuid}&amount_status=${"COMPLETED"}`
  );
};

export const GET_STUDENTS_BY_STANDARD_DIVISION = (
  school_code: any,
  data: any
): Promise<any> => {
  return API.get(
    `${apiUrl}/user?institute_school_code=${school_code}&user_role=${"STUDENT"}`,
    { params: data }
  );
};

export const CHECK_USER_NAME_AVAILABILITY = (userName: any): Promise<any> => {
  return API.get(`${newApiUrl}/username/availability/check/${userName}`);
};

export const DOWNLOAD_STUDENT_FAILED_EXIST = (
  schoolCode: any
): Promise<any[]> => {
  return API.get(
    `${newApiUrl}/user/bulkupload/failed/list?school_code=${schoolCode}`
  );
};
