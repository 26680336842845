import React, { useState } from 'react'
import { useHistory } from 'react-router'
import { LOGIN, REQUEST_OTP, RESET_PASSWORD } from '../../app/service/login.service'
import LogoImg from '../../assets/images/iamsamarth-Logo-Final.svg';
import FormBuilder from '../../components/form-builder'
import { FormControlError, FormField, FormValidators } from '../../components/form-builder/model/form-field'
import { FormValidator, GetControlIsValid } from '../../components/form-builder/validations'
import { ToastContainer, toast } from 'react-toastify';
import Logos from "../../assets/images/eliments.svg";

export const ForgotPasswordPage = () => {
    const [loginValidationErrors, setLoginValidationErrors] = useState<FormControlError[]>([]);
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [loginData, setLoginData] = useState<any>({});
    const [formData, setFormData] = useState<any>({});
    const [errorMessage, setErrorMessage] = useState("");
    const [userNameError, setUserNameError] = useState('');
    const [messageType, setMessageType] = useState("mobile");
    const [passwordError, setPasswordError] = useState('');
    const [confirmPasswordError, setConfirmPasswordError] = useState('');
    const [passwordErrorMessage, setPasswordErrorMessage] = useState(false);
    const [newPassword, setNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isSendOtpSuccess, setIsSendOtpSuccess] = useState(false);
    const [otpResponse, setOtpResponse] = useState<any>();
    const history = useHistory();
    const [minutes, setMinutes] = useState<number>(5);
    const [seconds, setSeconds] = useState<number>(0);
    const [isActiveOTP, setIsActiveOTP] = useState<boolean>(false);
    const [OTPValue, setOTPValue] = useState('');
    const formValidations = [
        new FormField('user_name', [FormValidators.REQUIRED]),
        new FormField('new_password', [FormValidators.REQUIRED]),
        new FormField('confirm_password', [FormValidators.REQUIRED])
    ];


    const handleInput = (data: any) => {
        setLoginData(data);
        setFormData({ ...data.value });
        const errors: any = FormValidator(formValidations, data.value);
        setLoginValidationErrors(errors);
    };

    function onClickLogin() {
        setIsFormSubmitted(true);
        const loginsData = { ...loginData.value };
        const errors: FormControlError[] = FormValidator(formValidations, loginsData);
        setLoginValidationErrors(errors);
        if (errors.length < 1 && loginsData.user_name && !userNameError && onChangePasswordValidation(loginsData.new_password) && onChangeConfirmPasswordValidation(loginsData.confirm_password)) {
            if (!isSendOtpSuccess) {
                if (loginsData.new_password === loginsData.confirm_password) {
                    setLoading(true);
                    const payload = {
                        user_name: loginsData.user_name,
                        otp_type: "resetpassword",
                        message_type: messageType
                    }
                    REQUEST_OTP(payload).then((res: any) => {
                        if (res.status === 'fail') {
                            setErrorMessage(res?.message);
                            setLoading(false);
                        } else {
                            handleStartTimer(5, 0);
                            setLoading(false);
                            const user_info = res;
                            setOtpResponse(res);
                            setIsSendOtpSuccess(true);
                            formValidations.push(new FormField('confirm_password', [FormValidators.REQUIRED]));
                        }
                    });
                } else {
                    setPasswordErrorMessage(true);
                    setConfirmPasswordError("Password not matched")
                    setLoading(false);
                }
            } else {
                const payload = {
                    user_name: loginsData.user_name,
                    password: loginsData.new_password,
                    ref_number: otpResponse.ref_number,
                    pin: OTPValue
                }
                if (loginsData.pin) {
                    setLoading(true);
                    RESET_PASSWORD(payload).then((res: any) => {
                        if (res.status === 'fail') {
                            setErrorMessage(res?.message);
                            setLoading(false);
                        } else {
                            setLoading(false);
                            const user_info = res;
                            history.push("/");
                            toast.success('Password successfully updated');
                        }
                    });
                }
            }

        }
    }

    function onClickBackToLogin() {
        history.push("/");
    }


    const getInputValid = (control: string) => {
        const value = GetControlIsValid(loginValidationErrors, control);
        return value;
    }

    function onClickNewPasswordEyeIcon() {
        setNewPassword(!newPassword);
    }

    function onClickConfirmPasswordEyeIcon() {
        setShowConfirmPassword(!showConfirmPassword);
    }

    const onChangeOtpType = (event: any) => {
        setMessageType(event);
    }
    const onChangePasswordValidation = (event: any) => {
        setPasswordError('');
        const reg = /^(?=.*[0-9])(?=.*[A-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/;

        // const reg = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/;
        if (event) {
            if (!reg.test(event.trim())) {
                setPasswordError('Password between 6 to 16 characters, at least one capital letter, at least one special character, at least one number');
            } else {
                if (event !== formData?.confirm_password && formData?.confirm_password && !confirmPasswordError) {
                    setConfirmPasswordError("Password not matched");
                }
                return true;
            }
        } else {
            if (formData?.confirm_password && confirmPasswordError === "Password not matched") {
                setConfirmPasswordError("");
            }
            setPasswordError('Please enter password');
        }
    }

    const onChangeConfirmPasswordValidation = (event: any) => {
        setConfirmPasswordError('');
        const reg = /^(?=.*[0-9])(?=.*[A-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/;

        if (event) {
            if (!reg.test(event.trim())) {
                setConfirmPasswordError('Confirm password between 6 to 16 characters, at least one capital letter, at least one special character, at least one number');
            } else {
                if (formData?.new_password !== event && formData?.new_password) {
                    setConfirmPasswordError('Password not matched');
                } else {
                    setConfirmPasswordError('');
                }
                return true;
            }
        } else {
            setConfirmPasswordError('Please enter confirm password');
        }
    }
    const onChangeUserName = (event: any) => {
        setErrorMessage("");
    }
    const onChangePin = (event: any) => {
        setErrorMessage("");
        const numericRegex = /^[0-9]+$/;
        if (event === '' || numericRegex.test(event)) {
            setOTPValue(event);
        }
    }
    const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
        event.preventDefault();
        // Prevent pasting
    };
    const handleResendOTP = () => {
        const loginsData = { ...loginData.value };
        const payload = {
            user_name: loginsData.user_name,
            otp_type: "resetpassword",
            message_type: messageType
        }
        REQUEST_OTP(payload).then((res: any) => {
            if (res.status === 'fail') {
                setErrorMessage(res?.message);
            } else {
                handleStartTimer(5, 0);
                setOtpResponse(res);
                setIsSendOtpSuccess(true);
            }
        });
    }
    const handleStartTimer = (minutes: number, seconds: number) => {
        setIsActiveOTP(true);
        let totalSeconds = minutes * 60 + seconds;
        let interval = setInterval(() => {
            if (totalSeconds > 0) {
                totalSeconds--;
                let updatedMinutes = Math.floor(totalSeconds / 60);
                let updatedSeconds = totalSeconds % 60;
                setMinutes(updatedMinutes);
                setSeconds(updatedSeconds);
            } else {
                setSeconds(seconds);
                setMinutes(minutes);
                clearInterval(interval);
                setIsActiveOTP(false);
            }
        }, 1000);
    }
    return (
        <div>
            <section className="d-flex justify-content-md-center align-items-center login-block-wraper forgot-password">
                <div>
                    <div className="bg-white border border-gray rounded-16 p-3 p-lg-5 shadow-sm login-block">
                        <div className="row justify-content-md-center login-row">
                            <div className="col col-md-5 text-center text-lg-start" style={{ paddingTop: '100px' }}>
                                <img src={LogoImg} alt="" className="login-logo" />
                            </div>
                            <div className="col-md-7 login-fileds">
                                <FormBuilder onUpdate={handleInput}>
                                    <form>
                                        <h2 className="login-hd mt-3 mb-1 mb-md-2 text-center text-lg-start">Change Password</h2>
                                        {!isSendOtpSuccess ? <div>
                                            <div className="form-floating">
                                                <input type="text" className="form-control fw-light" name="user_name" placeholder="Please enter user Id" onChange={(e) => onChangeUserName(e.target.value)} />
                                                <label htmlFor="floatingInput">User Name</label>
                                                {isFormSubmitted && !getInputValid('user_name') && <p className="text-danger">Please Enter User Name</p>}
                                            </div>
                                            <div className="form-floating">
                                                <input type={newPassword ? "text" : "password"} className="form-control fw-light" name="new_password" placeholder="New password" onChange={(e) => onChangePasswordValidation(e.target.value)} maxLength={16} />
                                                <span className="eye-icon cursor-pointer" onClick={() => onClickNewPasswordEyeIcon()}>
                                                    <i className={newPassword ? "bi bi-eye-fill" : "bi bi-eye-slash"}></i>
                                                </span>
                                                <label htmlFor="floatingPassword">New Password</label>
                                                {passwordError && <p className="text-danger">{passwordError}</p>}

                                            </div>
                                            <div className="form-floating">
                                                <input type={showConfirmPassword ? "text" : "password"} className="form-control fw-light" name="confirm_password" placeholder="Confirm password" onChange={(e) => onChangeConfirmPasswordValidation(e.target.value)} onPaste={handlePaste} maxLength={16} />
                                                <span className="eye-icon cursor-pointer" onClick={() => onClickConfirmPasswordEyeIcon()}>
                                                    <i className={showConfirmPassword ? "bi bi-eye-fill" : "bi bi-eye-slash"}></i>
                                                </span>
                                                <label htmlFor="floatingPassword">Confirm Password</label>
                                                {confirmPasswordError && <p className="text-danger">{confirmPasswordError}</p>}

                                            </div>
                                            <div className="form-floating mb-3 mt-3">
                                                <span>Please select your desired mode to receive OTP</span>
                                                <div className='mt-2'>
                                                    <input type="radio" value={messageType} className='me-2' checked={messageType === 'mobile'} name='message_type' onChange={() => onChangeOtpType('mobile')} /> <span className='me-2'>Mobile</span>
                                                </div>
                                                <div className='mt-2'>
                                                    <input type="radio" value={messageType} className='me-2' checked={messageType === 'email'} name='message_type' onChange={() => onChangeOtpType('email')} /> <span className='me-2'>Email</span>
                                                </div>
                                            </div>
                                        </div> :
                                            <div>
                                                <div className="form-floating">
                                                    <span>Please enter 4 digit OTP number sent to your registered {messageType === 'mobile' ? 'mobile' : 'email'}</span>

                                                    <input type="text" className="form-control fw-light" name="pin" placeholder="Please enter OTP" autoComplete="off" maxLength={4} onChange={(e) => onChangePin(e.target.value)} value={OTPValue} />
                                                    {isFormSubmitted && !getInputValid('pin') && <p className="text-danger">Please OTP</p>}
                                                </div>
                                            </div>
                                        }
                                    </form>
                                </FormBuilder>
                                {errorMessage && <div className="text-center mb-1 mt-2" style={{ color: 'red' }}>{errorMessage}</div>}
                                {loading ?
                                    <div className='text-center'>
                                        <div className="spinner-border" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    </div> :
                                    <div className="mt-4 mb-3 text-center text-lg-start position-relative">
                                        <a className="btn btn-primary btn-sm px-5 rounded-12 fw-light cursor-pointer" onClick={() => onClickLogin()}>
                                            Submit
                                        </a>
                                        {isSendOtpSuccess && <a className={` ${isActiveOTP ? 'text-decoration-none  rounded-org-12 disabled-link text-secondary f14 ms-3' : 'text-primary text-decoration-none  rounded-org-12 ms-4 cursor-pointer'}`} onClick={handleResendOTP}> Resend OTP</a>}
                                        {isActiveOTP && <p className='mb-0 text-inprogess f12 position-absolute mt-1 ms-5 ps-5'>OTP is valid for {minutes < 10 ? `0${minutes}` : minutes} : {seconds < 10 ? `0${seconds}` : seconds}</p>}
                                        <br />
                                        <a className="d-inline-block ms-2 mt-4  f12 forgot-color cursor-pointer" style={{ paddingLeft: '40px' }} onClick={() => onClickBackToLogin()}>Back to Login</a>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="login-elements text-center" style={{ marginTop: '1rem' }}>
                        <img src={Logos} alt="" className="login-elements-icons" />
                    </div>
                </div>
            </section>
            <footer className="text-center f12 text-dgray"><div className="container">
                &copy; 2022 iamsamarth.com. All Rights Reserved | <a href="http://iamsamarth.com/privacy-policy/" className="text-dgray text-decoration-none">Terms of Use</a> |
                <a href="http://iamsamarth.com/privacy-policy/" className="text-dgray text-decoration-none">Privacy Policy</a>
            </div>
            </footer>
        </div>
    )
}