import { applyMiddleware, combineReducers, createStore } from "redux";
import thunk from "redux-thunk";
import { AppUtilityReducer, UserData } from "./app-utility/reducer";

export const store = createStore(combineReducers({
     AppUtilityReducer,
     UserDataReducer: UserData,
    
    }),
    applyMiddleware(thunk)
);