import { Switch, Route, Redirect } from "react-router";
import { SchoolInFormation } from "../manage-school/schoolinfo";
import Dashboard from "./dashboard";

import RegisteredSchools from "./registered-schools/list";
import RegisteredStudents from "./registered-students/list";


interface Props {
    match?: any;
}
const DashboardRoutes = (props: Props) => {

    let url: string = "/institute-dashboard/dashboard"

    return (
        <Switch>
            <Route path="/institute-dashboard/dashboard" component={Dashboard}></Route>
        </Switch>
    );
}
export default DashboardRoutes;