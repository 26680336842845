import React, { useEffect, useRef, useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { MNS_QUESTIONS_LIST, MNS_SUBMIT_QUESTIONS } from '../../../app/service/mns-service';
import { CREATE_MODULE_ANSWERS, GET_MNS_MODULES_BY_ID, GET_MNS_MODULE_BY_QUESTIONS, GET_MODULES_BY_ID, UPDATE_ASSIGN_USER } from '../../../app/service/module.service';
import FormBuilder from '../../../components/form-builder';
import TimerInfo from './timer/timerinfo';

export const MNSQuestionsForm = () => {
    const [questionsData, setQuestionsData] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    const [userRole, setUserRole] = useState<any>();
    const [canShowCompleteFormBtn, setCanShowCompleteFormBtn] = useState(false);
    const [questionsFormData, setQuestionsFormData] = useState<any>({});
    const [moduleType, setModuleType] = useState<any>();
    const [tempInfo, setTempInfo] = useState<any>({});
    const [minutes, setMinutes] = useState(2);
    const [seconds, setSeconds] = useState(0);
    const [timeUpAlert, setTimeUpalert] = useState(false);
    const [time_remain, settime_remain] = useState<any>();
    const [time, setTime] = useState<any>({});
    const [timeLeft, setTimeLeft] = useState<any>(null);
    const [startTimer, setStartTimer] = useState(false);
    const [canShowHeader, setShowHeader] = useState(false);
    const [mnsInfo, setMnsInfo] = useState<any>(null);
    const [attemptLevels, setAttemptLevels] = useState<any>([1, 2, 3, 4, 5, 6, 7]);
    let timeUp = false;
    let time_remain_storage = '';

    const surveyForm = useRef<any>({});
    const history = useHistory();
    let { moduleUUID, userCode, schoolCode } = useParams<{ moduleUUID: string, userCode: string, schoolCode: string }>();
    const modules_uuid = moduleUUID;
    const user_code = userCode;
    const school_code = schoolCode;
    let timeRemaining = 0;

    useEffect(() => {
        const userRole = localStorage.getItem("user_role");
        setUserRole(userRole);
        getMNSQuestionsList();
    }, []);


    function getMNSQuestionsList() {
        const payload = {
            "module_uuid": modules_uuid,
            "school_code": school_code,
            "user_code": user_code
        }

        setShowHeader(false);
        setLoading(true);
        MNS_QUESTIONS_LIST(payload).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    if (res) {
                        setTempInfo(res);
                        setMnsInfo(res);
                        setMinutes(Number(res.test_time));
                        setStartTimer(true);
                        setQuestionsData(res.questions_list.en);
                        setShowHeader(true);
                        setLoading(false);
                    }

                }
            }
        });
    }


    const handleOptions = (data: any) => {
        setQuestionsFormData(data);
    }

    function handleQuestionAnswers(e: any, item: any, i: number, j: number) {
        let mnsQuestions = questionsData;
        mnsQuestions[i].list[j].student_ans = e.target.value;
        setQuestionsData(mnsQuestions);
    }
    const handleCompleteSubmit = () => {
        let request = tempInfo;
        request['questions_list']['en'] = questionsData;
        request['module_uuid'] = modules_uuid;
        request['school_code'] = school_code;
        request['user_code'] = user_code;
        request['time_taken'] = tempInfo.test_time;
        if (!request.hasOwnProperty('mode')) {
            request['mode'] = 'levels'
        }
        MNS_SUBMIT_QUESTIONS(request).then((res: any) => {
            if (res) {
                const data = res;
                const preparedData = JSON.stringify(data);
                localStorage.setItem("mnsInfo", preparedData);
                history.push(`/dashboard/mns/success-level/${mnsInfo.sub_level}/${mnsInfo.mode}/${modules_uuid}/${user_code}/${school_code}`);
            }
        });
    }

    const getLogo = (index: number) => {
        let currentAttempt = mnsInfo ? mnsInfo?.consecutive_success : 0;

        if (index == currentAttempt) {
            return '#FCEE21';
        }

        if (mnsInfo && mnsInfo?.consecutive_success == 0) {
            return '#FFFFFF';
        }

        if (mnsInfo?.consecutive_success > 0 && mnsInfo?.consecutive_success > index) {
            return '#8CC63F';
        } else {
            return '#ffffff';
        }
    }

    const handleBack = () => {
        history.goBack();
    }

    return (
        <div className="row border-top border-primary py-3">
            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading && <div>
                {canShowHeader && <div className='row questions-grid'>
                    {mnsInfo && mnsInfo?.mode && mnsInfo?.mode === 'assesment_test' ?
                        <div className='col-md-6'>Entry Test</div> :
                        <div className='col-md-6'>
                            <div>Level</div>
                            <div className='fw-bold' style={{ fontSize: '28px' }}>{mnsInfo?.sub_level}</div>
                            <div>Attempts</div>
                            {attemptLevels.map((item: any, i: number) => (
                                <div className="circle" style={{ backgroundColor: getLogo(i) }}>
                                    <p>{item}</p>
                                </div>
                            ))}
                        </div>
                    }
                    <div className='col-md-4'>
                        <div className='fw-bold' style={{ fontSize: '28px' }}>
                            {canShowHeader && <TimerInfo
                                minutes={minutes}
                                seconds={seconds}
                                startTimer={startTimer}
                                time_remain={(value: any) => {
                                    if (value === '') {
                                        time_remain_storage = "5";
                                    } else {
                                        time_remain_storage = value;
                                    }

                                }}
                                timeUp={() => {
                                    setTimeUpalert(true);
                                    timeUp = true;
                                }} />}
                        </div>
                    </div>
                    <div className='col-md-2'>
                        <button className="btn btn-primary btn-sm px-5 rounded-12 cursor-pointer" onClick={handleBack}>Back</button>
                    </div>
                </div>}
                {questionsData.map((question: any, i: number) => (
                    <div className="questions-grid mt-4" key={i}>
                        {question.list.length > 1 && <div className='col-md-12'>
                            <div className='fw-bold' style={{ fontSize: '22px' }}>Q{question.q_number}: </div>
                        </div>}
                        {question.list.map((questionName: any, j: number) => (
                            <div className='row' key={j}>
                                {question.list.length === 1 && <div className='col-md-5'>
                                    <span className='fw-bold' style={{ fontSize: '22px' }}>Q{question.q_number}: </span>
                                    <span className='fw-bold' style={{ fontSize: '22px' }}>
                                        {questionName.question}
                                    </span>
                                </div>}
                                {question.list.length > 1 && <div className='col-md-5 pt-2'>
                                    <span className='fw-bold' style={{ fontSize: '22px' }}>
                                        <span>{j === 0 ? "a:" : ''}</span>
                                        <span>{j === 1 ? "b:" : ''}</span>
                                        <span>{j === 2 ? "c:" : ''}</span>
                                        {questionName.question}
                                    </span>
                                </div>}
                                <div className='col-md-4 pt-2'>
                                    <FormBuilder onUpdate={handleOptions}>
                                        <form ref={surveyForm}>
                                            {questionName.question_type === 'input_text' && <input type="text" className="form-control" name="option" value={question.selectedOption} onChange={(e) => { handleQuestionAnswers(e, question, i, j) }} />}
                                        </form>
                                    </FormBuilder>
                                </div>
                            </div>
                        ))}
                        <br />
                    </div>
                ))}
                {questionsData.length > 0 && <div className="py-3 text-center mt-4">
                    <button className="btn btn-primary px-5 rounded-5 cursor-pointer" onClick={handleCompleteSubmit}>Submit</button>
                </div>}
            </div>}
        </div >
    )
}
