import React from 'react';
import SubscriptionReportRoutes from './routes';

const SubscriptionReportPage = (props: any) => {
    
    return (
        <div>
            <SubscriptionReportRoutes {...props} />
        </div>
    );
};

export default SubscriptionReportPage;