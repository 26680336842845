import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";

function StudentCasInstructions() {
    const history = useHistory();
    let { id, moduleCode } = useParams<{ id: string, moduleCode: string }>();
    const [selectLanguage, setSelectLaguage] = useState<string>('en');
    const chooseLanguage: any = localStorage.getItem("student_select_language");
    const languages = [{
        "name": "English",
        "code": "en"
    }, {
        "name": "हिंदी",
        "code": "hi"
    }, {
        "name": "मराठी",
        "code": "mr"
    },
    {
        "name": "ગુજરાતી",
        "code": "gu"
    }
    ];
    useEffect(() => {
        if (chooseLanguage) {
            setSelectLaguage(chooseLanguage);
        }

    }, [])

    function startSurvey() {
        history.push(`/student-dashboard/list/cas-test/${id}/${moduleCode}/${selectLanguage}`)
    }
    function handleLanguageChange(e: any) {
        const type = e.target.value;
        localStorage.setItem("student_select_language", e.target.value);

    }

    return (
        <div>

            <div>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb pb-2 pt-0 px-0 border-bottom">
                        <li className="breadcrumb-item "><Link to="/student-dashboard/list"><span className='pe-2'>Dashboard</span></Link></li> /
                        <li className="breadcrumb-item "><span>Career Aspiration<sup>TM</sup> Survey</span></li>
                    </ol>
                </nav>
                <div className="mt-3">
                    <div className="border-bottom pb-3">
                        <div className="row">
                            <div className="col-md-3">
                                <label className="f14"
                                >Language for Survey <span className="text-danger">*</span></label>

                                <select
                                    className="form-select f14" name="language"
                                    aria-label="Default select example" value={selectLanguage} onChange={(e) => { setSelectLaguage(e.target.value); handleLanguageChange(e) }}
                                >
                                    {languages.map((name: any, i: number) => (
                                        <option key={i} value={name.code}>
                                            {name.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>
                    {selectLanguage === "en" && <div>
                        <h3 className="fw-medium f22 mt-3 mb-2">Dear Students</h3>
                        <p className="fw-light f14 m-0">
                            The report will be generated based on your responses given to the questionnaire.
                            If you give a response unknowingly or without proper understanding will create/generate a different report.
                            Hence please think thoroughly before you answer the questionnaire.
                        </p>
                        <h3 className="fw-medium f22 mt-3 mb-2">Instruction</h3>
                        <p className="fw-light f14">
                            Survey questions should be answered all at once.
                        </p>
                    </div>}
                    {selectLanguage === "hi" && <div>
                        <h3 className="fw-medium f22 mt-3 mb-2">प्रिय विद्यार्थी</h3>
                        <p className="fw-light f14 m-0">
                            CAS का रिपोर्ट आपके द्वारा चुने गए जवाबों के आधार पर बनेगी । यदि आप बिना समझे किसी भी प्रश्न का उत्तर गलत चुनेंगे तो उसी के आधार पर गलत रिपोर्ट बनेगा । इसीलिए प्रश्न को ध्यान से पढ़कर उसपर सोच समझकर जवाब चुने.
                        </p>
                        <h3 className="fw-medium f22 mt-3 mb-2">निर्देश</h3>
                        <p className="fw-light f14">
                            सर्वेक्षण में सभी प्रश्नों के उत्तर देना अनिवार्य है ।.
                        </p>
                    </div>}
                    {selectLanguage === "mr" && <div>
                        <h3 className="fw-medium f22 mt-3 mb-2">प्रिय विद्यार्थी</h3>
                        <p className="fw-light f14 m-0">
                            CAS या सर्वेक्षण चा अहवाल हा खाली दिलेल्या प्रश्नावली मधील तुमच्या प्रतिसादांवर आधारित असेल. जर तुम्ही नकळत किंवा योग्य प्रकारे समजून न घेता प्रतिसाद दिला तर चुकीचा अहवाल तयार होईल म्हणून तुम्ही प्रश्नावलीचे उत्तर देण्यापूर्वी विचार करा, प्रश्नाचा अर्थ समजुन घ्या मग विचार पूर्वक उत्तरावर क्लीक करा.
                        </p>
                        <h3 className="fw-medium f22 mt-3 mb-2">सूचना</h3>
                        <p className="fw-light f14">
                            सर्वेक्षण मधील सर्व प्रश्नांची उत्तरे देने अनिवार्य आहे.
                        </p>
                    </div>}
                    {selectLanguage === "gu" && <div>
                        <h3 className="fw-medium f22 mt-3 mb-2">વહ્લા વિદ્યાર્થીઓ,</h3>
                        <p className="fw-light f14 m-0">
                            પ્રશ્નાવલીમાં આપેલા તમારા જવાબોના આધારે રિપોર્ટ બનશે. જો તમે અજાણતા અથવા યોગ્ય સમજણ વિના પ્રતિભાવ આપશો, તો તે એક ખામીયુક્ત રિપોર્ટ બનાવશે. તેથી તમે પ્રશ્નોના જવાબ આપતા પહેલા સારી રીતે વિચારો.
                        </p>
                        <h3 className="fw-medium f22 mt-3 mb-2">સૂચના</h3>
                        <p className="fw-light f14">
                            સર્વેક્ષણના તમામ પ્રશ્નોના જવાબ એક જ સમયે આપવાનો છે.
                        </p>
                    </div>}
                    <p className="mt-3">
                        <a className="btn btn-primary mb-3 px-3" onClick={startSurvey}
                        >Start Survey</a>

                        <Link to='/student-dashboard/list' className="btn btn-link mb-3">Cancel</Link>
                    </p>
                </div>
            </div>
        </div>
    )
}
export default StudentCasInstructions;