import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom';
import { GET_MODULES_ID, GET_MODULE_BY_QUESTION } from '../../../app/service/module.service';
import { ToastContainer, toast } from 'react-toastify';
import { UPLOAD_QUESTIONS } from '../../../app/service/questions.service';

export const CASQuestionsList = () => {

    const [questionsData, setQuestionsData] = useState<any>([]);
    const [loading, setLoading] = useState(false);
    const languageForm = useRef<any>({});
    const [languageType, setLanguageType] = useState<any>("en");
    const [currentModulesData, setCurrentModulesData] = useState<any>({});
    const [languageQuestionsData, setLanguageQuestionsData] = useState<any>([]);
    const [canShowQuesionsUploadPopup, setCanShowQuesionsUploadPopup] = useState(false);
    const [excelFileName, setExcelFileName] = useState<any>();
    const [questionsUpload, setQuestionsUpload] = useState<any>({});
    const [canShowConfirmQuestionsPopup, setCanShowConfirmQuestionsPopup] = useState(false);

    let { id } = useParams<{ id: string }>();
    const moduleId = Number(id);

    const languages = [{
        "name": "English",
        "code": "en"
    }, {
        "name": "Hindi",
        "code": "hi"
    }, {
        "name": "Marathi",
        "code": "mr"
    },
    {
        "name": "Gujarati",
        "code": "gu"
    }
    ];

    useEffect(() => {
        setLoading(true);
        GET_MODULES_ID(moduleId).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    setLoading(false);
                    setCurrentModulesData(res.rows[0]);
                    getModuleQuestions(res.rows[0]);
                }
            }
        });
    }, []);

    function getModuleQuestions(item: any) {
        let payload = {
            module_code: item.module_code,
            sub_module: item.sub_module,
            module_ver: item.module_ver,
            target_std: item.target_std
        }
        setLoading(true);
        GET_MODULE_BY_QUESTION(payload).then(res => {
            if (res.status === 'fail') {
                toast.error(res.message);
                setLoading(false);
            } else {
                setQuestionsData(res.en);
                setLanguageQuestionsData(res);
                setLoading(false);
            }
        });
    }

    function handleLanguageChange(e: any) {
        const type = e.target.value || "en";
        setLanguageType(type);
        const data = languageQuestionsData;
        if (type == "en") {
            setQuestionsData(data.en ? data.en : []);
        } else if (type == "hi") {
            setQuestionsData(data.hi ? data.hi : []);
        } else if (type == "mr") {
            setQuestionsData(data.mr ? data.mr : []);
        } else if (type == "gu") {
            setQuestionsData(data.gu ? data.gu : []);
        } else {
            setQuestionsData([]);
        }
    }

    function onClickQuestionsUpload() {
        setCanShowQuesionsUploadPopup(true);
    }

    function handleBrowseFile(e: any) {
        if (e.target.files && e.target.files[0]) {
            setExcelFileName(e.target.files[0].name);
            let formData = new FormData();
            formData.append('file', e.target.files[0], e.target.files[0].name);
            formData.append('module_code', currentModulesData.module_code);
            formData.append('sub_module', currentModulesData.sub_module);
            formData.append('target_std', currentModulesData.target_std);
            formData.append('module_ver', currentModulesData.module_ver);
            formData.append('language', languageType);
            setQuestionsUpload(formData);
        }
    }

    const handleQuestionsUploadClose = () => {
        setCanShowQuesionsUploadPopup(false);
        setExcelFileName("");
    }

    function handleUpload() {
        setCanShowConfirmQuestionsPopup(true);
    }

    const handleQuestionsSave = () => {
        setLoading(true);
        UPLOAD_QUESTIONS(questionsUpload).then(res => {
            if (res.status === 'fail') {
                toast.error(res.message);
                setLoading(false);
            } else {
                setLanguageQuestionsData(res);
                setCanShowConfirmQuestionsPopup(false);
                setLoading(false);
                toast.success("Successfully Upload Questions");
            }
        });
    }

    const handleConfirmQuestionsClose = () => {
        setCanShowConfirmQuestionsPopup(false);
    }


    return (
        <div className="row py-3">
            <div className={`modal fade parent-modal-fade ${canShowQuesionsUploadPopup ? 'show' : 'false'}`} style={{ display: (canShowQuesionsUploadPopup ? "block" : 'none') }} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Questions Upload</h5>
                            <button type="button" className="close popup-close cursor-pointer" data-dismiss="modal" aria-label="Close" onClick={handleQuestionsUploadClose}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div>
                                {loading &&
                                    <div className="text-center p-5">
                                        <div className="spinner-border" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                }
                                {!loading &&
                                    <div>
                                        <label className="form-label mb-1">Language</label>
                                        <select name="language" className="form-select form-select-lg mb-3" onChange={(e) => { handleLanguageChange(e) }}>
                                            {/* <option value="">Select Language</option> */}
                                            {languages.map((name: any, i: number) => (
                                                <option key={i} value={name.code}>
                                                    {name.name}
                                                </option>
                                            ))}
                                        </select>
                                        <label className="form-label mb-1">Upload Excel File</label><br />
                                        <div className="file btn btn-primary" style={{ marginRight: '10px' }}>Browse
                                            <input type="file" onChange={(e) => handleBrowseFile(e)} />
                                        </div>
                                        {excelFileName}
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="modal-footer">
                            <div className="file btn btn-primary cursor-pointer" style={{ marginRight: '10px' }} onClick={() => handleUpload()}>Upload
                            </div>
                            <button type="button" className="btn btn-secondary cursor-pointer" data-dismiss="modal" onClick={handleQuestionsUploadClose}>Close</button>&nbsp;
                        </div>
                    </div>
                </div>
            </div>
            <div className={`modal fade parent-modal-fade ${canShowConfirmQuestionsPopup ? 'show' : 'false'}`} style={{ display: (canShowConfirmQuestionsPopup ? "block" : 'none') }} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Confirm Message</h5>
                            <button type="button" className="close popup-close cursor-pointer" data-dismiss="modal" aria-label="Close" onClick={handleConfirmQuestionsClose}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            {loading &&
                                <div className="text-center p-5">
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div>
                            }
                            {!loading && <div>
                                Please confirm to upload the new questions list
                            </div>}
                        </div>
                        <div className="modal-footer">
                            <div className="text-center text-lg-start">
                                <a className="btn btn-primary  px-5  fw-light cursor-pointer" onClick={handleQuestionsSave}>
                                    YES
                                </a><br />
                            </div>
                            <button type="button" className="btn btn-secondary cursor-pointer" data-dismiss="modal" onClick={handleConfirmQuestionsClose}>Close</button>&nbsp;
                        </div>
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='col-md-2'></div>
                <h5 className='col-md-2 mt-2'>CAS Questions</h5>
                <div className='col-md-3'>
                    <form ref={languageForm}>
                        <select name="language" className="mb-3 form-select" style={{ marginLeft: '15px' }} onChange={(e) => { handleLanguageChange(e) }}>
                            {languages.map((name: any, i: number) => (
                                <option key={i} value={name.code}>
                                    {name.name}
                                </option>
                            ))}
                        </select>
                    </form>
                </div>
                <div className='col-md-3' style={{ textAlign: 'end' }}>
                    <button className="px-3 py-1 mb-2 cursor-pointer msb-btn" onClick={() => onClickQuestionsUpload()}>Upload</button>&nbsp;
                </div>
            </div>
            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading && <div className="row custom-form mt-3">
                {questionsData.map((question: any, i: number) => (
                    <div className="row col-md-12" key={i}>
                        <div className="col-md-2"></div>
                        <div className="col-md-8 mb-4">
                            <label className="form-label mb-3" style={{ fontWeight: 'bold' }}>
                                {question.question_type === 'multiple_choice' && <span>{i}. </span>}
                                {question.question}
                            </label><br />
                            {question.options.map((option: any, j: number) => (
                                <div key={j} style={{ display: "inline-block" }}>
                                    <input className="mb-0" type="radio" disabled />
                                    <span style={{ paddingRight: '15px', paddingLeft: '15px' }}>{option}</span>
                                </div>
                            ))}
                            {question.question_type === 'textarea' && <textarea className="form-control" name="option" disabled style={{ width: '50%' }} ></textarea>}
                            {question.question_type === 'input_text' && <input type="text" className="form-control" disabled name="option" style={{ width: '50%' }} />}
                        </div>
                        <div className="col-md-2"></div>
                    </div>
                ))}
                {questionsData.length == 0 && <div style={{ textAlign: 'center' }}>
                    Not Found
                </div>}
            </div>}
        </div>
    )
}

