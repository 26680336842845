import React, { useEffect, useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import appConfig from '../../../../../../config/constant';
import { PDFViewer } from 'react-view-pdf';
import { GET_STUDENT_MATH_REPORT } from '../../../../../../app/service/mns-service';
import { GET_USER_USER_CODE } from '../../../../../../app/service/users.service';


export const ActiveStudentMathsReportView = () => {
    const [reportData, setReportData] = useState(false);
    const [loading, setLoading] = useState(false);
    const [pdfUrl, setPdfUrl] = useState('');
    const [invalidReport, setInvalidReport] = useState(false);
    const userRole = localStorage.getItem("user_role");
    let { userCode } = useParams<{ userCode: string }>();
    const [studentInfo, setStudentInfo] = useState<any>({});

    const history = useHistory();

    useEffect(() => {
        getUserDetails();
        getStudentMathsReport();
    }, []);


    function getStudentMathsReport() {
        setLoading(true);
        GET_STUDENT_MATH_REPORT(userCode).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                    setInvalidReport(true);
                } else {
                    const data = res;
                    setPdfUrl(`${appConfig.FILE_UPLOAD_URL}${data.url.charAt(0) === "/" ? data.url.substring(1) : data.url}`);
                    setLoading(false);
                    setReportData(true);
                    setInvalidReport(false);
                }
            }
        });
    }


    function onClickReportDownload() {
        window.open(`${pdfUrl}`, "_blank", 'noopener,noreferrer');
    }


    const handleBack = () => {
        history.goBack();
    }
    function getUserDetails() {
        setLoading(true);
        GET_USER_USER_CODE(userCode).then((response: any) => {
            if (response.status === 'fail') {
                toast.error(response.message);
                setLoading(false);
            } else {
                const data = response.rows[0];
                setStudentInfo(data);
                setLoading(false);

            }
        });
    }
    return (
        <div className="row ">
            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading && <div>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb border-bottom ps-0 pt-0 pb-2" style={{ letterSpacing: "0" }}>
                        {userRole === "SUPER_ADMIN" && <li className="breadcrumb-item mt-3"><Link to='/dashboard/view/instituteinfo' style={{ color: '#0d6efd' }}>Dashboard</Link> / <Link to='/dashboard/view/active-students' style={{ color: '#0d6efd' }}>{studentInfo?.name_of_school}</Link> / <Link to={`/institute-dashboard/dashboard/active-studentslist/${studentInfo?.name_of_school}/${studentInfo?.institute_school_code}`} style={{ color: '#0d6efd' }}><span>Active Students</span></Link> / <Link to={`/institute-dashboard/dashboard/active-studentsmoduleslist/${userCode}`} style={{ color: '#0d6efd' }}><span>{studentInfo?.name}</span></Link>/ <span>MATHS</span></li>}
                        {userRole !== "SUPER_ADMIN" && <li className="breadcrumb-item"><Link to='/institute-dashboard/dashboard' style={{ color: '#0d6efd' }}>Dashboard</Link> / <Link to='/institute-dashboard/dashboard/active-students' style={{ color: '#0d6efd' }}>{studentInfo?.name_of_school}</Link> / <Link to={`/institute-dashboard/dashboard/active-studentslist/${studentInfo?.name_of_school}/${studentInfo?.institute_school_code}`} style={{ color: '#0d6efd' }}><span>Active Students</span></Link> / <Link to={`/institute-dashboard/dashboard/active-studentsmoduleslist/${userCode}`} style={{ color: '#0d6efd' }}><span>{studentInfo?.name}</span></Link> / <span>MATHS</span></li>}
                    </ol>
                </nav>
                <div className='row'>
                    <div className='col-md-11'>
                        {invalidReport && <h1 className='' style={{ textAlign: 'center' }}> Report not generated</h1>}
                    </div>

                </div>
                {reportData && <div style={{ backgroundColor: 'white', paddingTop: '50px' }}>
                    <div className="row">
                        <div className='col-md-12'>
                            <button className="btn btn-primary px-3 mb-2 cursor-pointer" style={{ border: 'none', float: 'right', backgroundColor: '#0f70cd', borderRadius: '5px', color: 'white', marginRight: '5px' }} onClick={() => onClickReportDownload()}>Download</button>
                        </div>
                    </div>
                    <div className="row">
                        <div className='col-md-12'>
                            <PDFViewer url={pdfUrl} />
                        </div>
                    </div>
                </div>}
            </div>}
        </div>
    )
}