import API from "../utility/axios";
const apiUrl = '/api/v2';


export const CREATE_OBJECT = (data: any): Promise<any> => {
    return API.post(`${apiUrl}/access/object/create`, data);
}

export const GET_OBJECT = (pageNumber: number): Promise<any> => {
    return API.get(`${apiUrl}/access/objects/fetch?limit=${10}&page=${pageNumber}`);
}

export const GET_OBJECTS = (pageNumber: number): Promise<any> => {
    return API.get(`${apiUrl}/access/objects/fetch`);
}

export const GET_OBJECT_BY_ID = (id: number): Promise<any> => {
    return API.get(`${apiUrl}/access/objects/fetch?id=${id}`);
}

export const UPDATE_OBJECT = (data: any): Promise<any> => {
    return API.post(`${apiUrl}/access/object/create`, data);
}

export const DELETE_OBJECT = (id: number): Promise<any> => {
    return API.delete(`${apiUrl}/access/object/delete/${id}`);
}

