import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import Logo from "../../../assets/images/Logo-final.png";
import { FECTH_SPONSORS_DATA, UPDATE_SPONSORS_DATA } from "../../../app/service/sponsor";
import { toast } from "react-toastify";
import VIEWICONS from '../../../institute-dashboard-assets/images/view-icons.svg'


export const PaymentDetailsView = () => {
    let { Uuid } = useParams<{ Uuid: any }>();
    const [sponsorData, setSponsorData] = useState<any>([]);
    const [loading, setLoading] = useState(false);
    const [canShowStudentsPopup, setCanStudentsPopup] = useState(false);
    const [studentDetails, setStudentDetails] = useState([]);
    const [transactionId, SettransactionId] = useState<any>("");
    const [canShowPaymentDetailsPopup, setCanShowPaymentDetailsPopup] = useState(false);
    const [amount, setAmount] = useState<any>("");
    const history = useHistory();

    useEffect(() => {
        getSponsorData();
    }, [])
    function getSponsorData() {
        setLoading(true);
        FECTH_SPONSORS_DATA(Uuid).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                }
                else {
                    const data = res.rows[0];
                    setSponsorData(data);
                    setLoading(false);
                }
            }
        });
    }
    const handleStudentsClose = () => {
        setCanStudentsPopup(false);
    };
    const getStudentsDetails = (data: any) => {
        setCanStudentsPopup(true);
        setStudentDetails(data?.students);
    }
    return (
        <div className="row border-top border-primary py-3" >

            <div
                className={`modal fade ${canShowStudentsPopup ? "show" : "false"}`}
                style={{ display: canShowStudentsPopup ? "block" : "none" }}
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog parent-model-dialog" role="document">
                    <div className="modal-content parent-model">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                                Students
                            </h5>
                            <button
                                type="button"
                                className="close popup-close cursor-pointer"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={handleStudentsClose}
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body parent-info parent-model">
                            <div className='table-responsive mar-t-20 custom-scroll'>
                                <table className='table table  fixed-table-header mb-0'>
                                    <thead>
                                        <tr>
                                            <th style={{ width: "70px" }}>S.No</th>
                                            <th style={{ width: '120px' }}>Student Name</th>
                                            <th style={{ width: '120px' }}> Standard</th>
                                            <th style={{ width: '90px' }}> Division</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {studentDetails?.map((data: any, i: number) => {
                                            return (
                                                <tr key={i}>
                                                    <td>{i + 1}</td>
                                                    <td> {data.name}</td>
                                                    <td> {data.standard}</td>
                                                    <td>{data.division} </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                                {studentDetails.length == 0 && <p className="f14 text-center">No Data Available</p>}

                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary cursor-pointer"
                                data-dismiss="modal"
                                onClick={handleStudentsClose}
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            </div>


            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading &&
                <div className="">
                    <div style={{ borderBottom: 'solid 1px black', marginTop: '15px', paddingLeft: '10px' }} className="mb-3 d-flex justify-content-between">
                        <h5 className="form-label  d-block">Sponsor Details</h5>
                        <Link to={`/dashboard/sponsor`}><button className="btn btn-primary mb-2">Back</button></Link>
                    </div>
                    <p className="text-gray f14 mb-1">Name : <span className="f16 ms-2">{sponsorData?.sponsor_name}</span></p>
                    {sponsorData?.sponsor_details?.mobile_number && <p className="text-gray f14 mb-1">Mobile No : <span className="f16 ms-2">{sponsorData?.sponsor_details?.mobile_number}</span></p>}
                    <p className="text-gray f14 mb-1">Payment Status : <span className="f18 text-completed mb-3">{sponsorData?.payment_status === "completed" && <span>Completed</span>}</span></p>
                    <div className='table-responsive mar-t-20 custom-scroll'>
                        <table className='table table  fixed-table-header mb-0'>
                            <thead>
                                <tr>
                                    <th style={{ width: "50px" }}>S.No</th>
                                    <th style={{ width: '150px' }}>Institute Name</th>
                                    <th style={{ width: '150px' }}> School Name</th>
                                    <th style={{ width: '90px' }}> Students</th>
                                    <th style={{ width: '100px' }}> Subscription Price</th>
                                    <th style={{ width: '100px' }}>Total Price</th>
                                </tr>
                            </thead>
                            <tbody>
                                {sponsorData?.students?.map((data: any, i: number) => {
                                    return (
                                        <tr key={i}>
                                            <td>{i + 1}</td>
                                            <td> {data._organization.name_of_organization}</td>
                                            <td> {data._school.name_of_school}</td>
                                            <td><img className='eye-icons me-3 cursor-pointer' src={VIEWICONS} onClick={() => getStudentsDetails(data)} />{data.total_students}</td>
                                            <td>{data.subscription_price} </td>
                                            <td>{data.school_payment_amount}</td>
                                        </tr>
                                    )
                                })}
                                <tr>
                                    <td style={{ fontSize: "14px", fontWeight: "bold" }}>Grand Total</td>
                                    <td> </td>
                                    <td></td>
                                    <td></td>
                                    <td> </td>
                                    <td style={{ fontSize: "14px", fontWeight: "bold" }}>{sponsorData?.amount}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                </div>}
        </div>
    )
}