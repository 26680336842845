import React from 'react'
import { HashRouter, Switch, Route } from 'react-router-dom'
import { DashboardView } from '../pages/dashboard/dashboard';
import InstituteDashboardView from '../pages/institute-dashboard/institute-dashboard';
import { ForgotPasswordPage } from '../pages/login/forgot-password';
import { IndividualStudentSignupPage } from '../pages/login/individual-student-signup';
import { IndividualStudentSignupSuccessPage } from '../pages/login/individual-student-signup-success';
import { IndividualUserForm } from '../pages/login/individual-user';
import { LoginPage } from '../pages/login/login-page';
import { MLATwoMobileQuestionsForm } from '../pages/login/mla-two-mobile-questions';
import { OrganisationSignupPage } from '../pages/login/organisation-signup';
import { OrganisationSignupSuccessPage } from '../pages/login/organisation-signup-success';
import { SetPasswordPage } from '../pages/login/set-password';
import ParentDashboardView from '../pages/parent-dashboard/parent-dashboard';
import SchoolDashboardView from '../pages/school-dashboard/school-dashboard';
import StudentDashboardView from '../pages/student-dashboard/student-dashboard';
import { SopnsorPaymentList } from '../pages/dashboard/sponser/payment';
import { AbhyaasMobileQuestionsForm } from '../pages/login/abhyaas-mobile-questions';
import { Ccvenuetest } from '../pages/login/ccvenue-test';
import { ParikshaMobileQuestionsForm } from '../pages/login/pariksha-mobile-questions';
import { CcavenusMobileView } from '../pages/login/ccavenue-mobile-view';

const Routes = () => {
    return (
        <HashRouter>
            <Switch>
                <Route exact path="/" component={LoginPage}></Route>
                <Route exact path="/forgot-password" component={ForgotPasswordPage}></Route>
                <Route exact path="/set-password/:userName" component={SetPasswordPage}></Route>
                <Route exact path="/individual-student-signin" component={IndividualUserForm}></Route>
                <Route exact path="/organisation-signup" component={OrganisationSignupPage}></Route>
                <Route exact path="/organisation-signup-success" component={OrganisationSignupSuccessPage}></Route>
                <Route exact path="/individual-student-signup" component={IndividualStudentSignupPage}></Route>
                <Route exact path="/individual-student-signup-success" component={IndividualStudentSignupSuccessPage}></Route>
                <Route exact path="/home" component={LoginPage}></Route>
                <Route path="/dashboard" component={DashboardView}></Route>
                <Route path="/institute-dashboard" component={InstituteDashboardView}></Route>
                <Route path="/school-dashboard" component={SchoolDashboardView}></Route>
                <Route path="/student-dashboard" component={StudentDashboardView}></Route>
                <Route path="/parent-dashboard" component={ParentDashboardView}></Route>
                <Route exact path="/sponsor/paymentdata/:sponsorUUID" component={SopnsorPaymentList}></Route>
                <Route exact path="/ccvenue" component={Ccvenuetest}></Route>

                {/*Webview for mobile  */}
                <Route exact path="/mla-two/mobile-app/questions" component={MLATwoMobileQuestionsForm}></Route>
                <Route exact path="/abhyaas/mobile-app/questions" component={AbhyaasMobileQuestionsForm}></Route>
                <Route exact path="/pariksha/mobile-app/questions" component={ParikshaMobileQuestionsForm}></Route>
                <Route exact path="/ccavenue/mobile-app/view" component={CcavenusMobileView}></Route>
            </Switch>
        </HashRouter>
    )
}

export default Routes;
