import React from 'react'
import { Switch, Route } from 'react-router-dom';
import CASTestList from '../../pages/student-dashboard/dashboard/cas-test';
import StudentDashboardList from '../../pages/student-dashboard/dashboard/list';
import { MLATwoAbilityResultData } from '../../pages/student-dashboard/dashboard/mla-two/ability-result';
import { MLATwoQuestionsList } from '../../pages/student-dashboard/dashboard/mla-two/questions';
import { MLATwoSuccessPage } from '../../pages/student-dashboard/dashboard/mla-two/success-level';
import { MLATwoTestLevelStatusView } from '../../pages/student-dashboard/dashboard/mla-two/test-level-status';
import { MLAAbilityResultData } from '../../pages/student-dashboard/dashboard/mla/ability-result';
import { MLAQuestionsList } from '../../pages/student-dashboard/dashboard/mla/questions';
import { MLALevelSuccessData } from '../../pages/student-dashboard/dashboard/mla/success-level';
import { MLATestLevelStatusView } from '../../pages/student-dashboard/dashboard/mla/test-level-status';
import { MNSProgressView } from '../../pages/student-dashboard/dashboard/mns/dashboard';
import { MNSQuestionsList } from '../../pages/student-dashboard/dashboard/mns/questions';
import { MnsTestLevelStatus } from '../../pages/student-dashboard/dashboard/mns/test-level';
import TestSuccess from '../../pages/student-dashboard/dashboard/mns/test-success';
import { FamilyReportView } from '../../pages/student-dashboard/dashboard/reports/family';
import { StudentMathsReportView } from '../../pages/student-dashboard/dashboard/reports/maths/student';
import { ParentReportView } from '../../pages/student-dashboard/dashboard/reports/parent';
import { StudentReportView } from '../../pages/student-dashboard/dashboard/reports/student';
import { ReportsDataView } from '../../pages/student-dashboard/dashboard/reports/view';
import StudentCasInstructions from '../../pages/student-dashboard/dashboard/cas-instructions';
import { StudentCasServeySuccessPage } from '../../pages/student-dashboard/dashboard/cas-success';
import { TestResultData } from '../../pages/student-dashboard/dashboard/mns/result-view';
import { StudentProfileInfo } from '../../pages/student-dashboard/profile/view';
import { AbhyasTestLevelStatusView } from '../../pages/student-dashboard/dashboard/abhyas/test-level-status';
import { AbhyasProgressView } from '../../pages/student-dashboard/dashboard/abhyas/view';
import { AbhyasTopicResultData } from '../../pages/student-dashboard/dashboard/abhyas/topic-result';
import { AbhyasLevelSuccessData } from '../../pages/student-dashboard/dashboard/abhyas/success-level';
import { AbhyasUserQuestionsList } from '../../pages/student-dashboard/dashboard/abhyas/questions';
import { ParikshaUserQuestionsList } from '../../pages/student-dashboard/dashboard/pariksha/questions';
import { ParikshaTestLevelStatusView } from '../../pages/student-dashboard/dashboard/pariksha/test-level-status';
import { ParikshaTestResultData } from '../../pages/student-dashboard/dashboard/pariksha/test-result';
import { ParikshaProgressView } from '../../pages/student-dashboard/dashboard/pariksha/view';
import { ParikshaLevelSuccessData } from '../../pages/student-dashboard/dashboard/pariksha/success-level';
import StudentSettings from '../../pages/student-dashboard/settings/view';
import ParikshaInitiative from '../../pages/student-dashboard/dashboard/pariksha/test-initiative';
import ParikshaTestAnalysis from '../../pages/student-dashboard/dashboard/pariksha/test-analysis';
import ParikshaResultExplanation from '../../pages/student-dashboard/dashboard/pariksha/result-explanation';



export const StudentsDashBoardRoutes = () => {
    return (
        <Switch>
            <Route exact path="/student-dashboard/list" component={StudentDashboardList}></Route>
            {/* /* Settings Routing*/}

            <Route exact path="/student-dashboard/settings/view" component={StudentSettings}></Route>

            {/* /* Profile Routing*/}

            <Route exact path="/student-dashboard/profile/view" component={StudentProfileInfo}></Route>

            {/* /* CAS Routing*/}
            <Route exact path="/student-dashboard/list/cas-test/:id/:moduleCode/:languageCode" component={CASTestList}></Route>
            <Route exact path="/student-dashboard/list/cas-test-instructions/:id/:moduleCode" component={StudentCasInstructions}></Route>
            <Route exact path="/student-dashboard/list/cas-success/:id/:studentCode/:languageCode" component={StudentCasServeySuccessPage}></Route>

            {/* /* MNS Routing*/}

            <Route exact path="/student-dashboard/list/mns/result-view/:level/:mode/:moduleUUID/:userCode/:schoolCode/:testNo" component={TestResultData}></Route>
            <Route exact path="/student-dashboard/list/test-success/:level/:mode/:moduleUUID/:userCode/:schoolCode/:testNo" component={TestSuccess}></Route>
            <Route exact path="/student-dashboard/list/mns/view/:moduleUUID/:userCode/:schoolCode" component={MNSProgressView}></Route>
            <Route exact path="/student-dashboard/list/mns/test-level/:moduleUUID/:userCode/:schoolCode" component={MnsTestLevelStatus}></Route>
            <Route exact path="/student-dashboard/list/mns/questions/:moduleUUID/:userCode/:schoolCode" component={MNSQuestionsList}></Route>


            {/* /* MLA  One Routing*/}
            <Route exact path="/student-dashboard/list/mla/test-level-status/:moduleUUID/:userCode/:schoolCode" component={MLATestLevelStatusView}></Route>
            <Route exact path="/student-dashboard/list/mla/ability-result/:moduleUUID/:userCode/:schoolCode" component={MLAAbilityResultData}></Route>
            <Route exact path="/student-dashboard/list/mla/questions/:moduleUUID/:userCode/:schoolCode" component={MLAQuestionsList}></Route>
            <Route exact path="/student-dashboard/list/mla/success-page/:moduleUUID/:userCode/:schoolCode/:testNo" component={MLALevelSuccessData}></Route>


            {/* /* MLA Two Routing*/}

            <Route exact path="/student-dashboard/list/mla-two/test-level-status/:moduleUUID/:userCode/:schoolCode" component={MLATwoTestLevelStatusView}></Route>
            <Route exact path="/student-dashboard/list/mla-two/questions/:moduleUUID/:userCode/:schoolCode" component={MLATwoQuestionsList}></Route>
            <Route exact path="/student-dashboard/list/mla-two/ability-result/:moduleUUID/:userCode/:schoolCode" component={MLATwoAbilityResultData}></Route>
            <Route exact path="/student-dashboard/list/mla-two/success-page/:moduleUUID/:userCode/:schoolCode" component={MLATwoSuccessPage}></Route>


            {/* // Reports Routing */}

            <Route exact path="/student-dashboard/list/reports" component={ReportsDataView}></Route>
            <Route exact path="/student-dashboard/list/reports/student" component={StudentReportView}></Route>
            <Route exact path="/student-dashboard/list/reports/student/:id/:studentCode" component={StudentReportView}></Route>
            <Route exact path="/student-dashboard/list/reports/family" component={FamilyReportView}></Route>
            <Route exact path="/student-dashboard/list/reports/family/:id/:userCode/:studentCode" component={FamilyReportView}></Route>
            <Route exact path="/student-dashboard/list/reports/parent" component={ParentReportView}></Route>
            <Route exact path="/student-dashboard/list/maths/reports/student/:userCode" component={StudentMathsReportView}></Route>
            <Route exact path="/student-dashboard/list/reports/parent/:id/:userCode/:studentCode" component={ParentReportView}></Route>

            {/* Abyas Routing */}

            <Route exact path="/student-dashboard/list/abhyas/test-level-status/:moduleUUID/:userCode/:schoolCode" component={AbhyasTestLevelStatusView}></Route>
            <Route exact path="/student-dashboard/list/abhyas/progress-view/:moduleUUID/:userCode/:schoolCode/:BoardUuid/:MediumUuid/:StandardUuid/:SubjectUuid" component={AbhyasProgressView}></Route>
            <Route exact path="/student-dashboard/list/abhyas/questions/:moduleUUID/:userCode/:schoolCode/:subjectUuid" component={AbhyasUserQuestionsList}></Route>
            <Route exact path="/student-dashboard/list/abhyas/ability-result/:moduleUUID/:userCode/:schoolCode" component={AbhyasTopicResultData}></Route>
            {/* <Route exact path="/student-dashboard/list/abhyas/success-page/:moduleUUID/:userCode/:schoolCode" component={AbhyasLevelSuccessData}></Route> */}
            <Route exact path="/student-dashboard/list/abhyas/success-page/:moduleUUID/:userCode/:schoolCode/:testNo" component={AbhyasLevelSuccessData}></Route>

            {/* Pariksha Routing */}
            <Route exact path="/student-dashboard/list/pariksha/test-analysis/:moduleUUID/:userCode/:schoolCode" component={ParikshaTestAnalysis}></Route>
            <Route exact path="/student-dashboard/list/pariksha/result-explanation/:moduleUUID/:userCode/:schoolCode" component={ParikshaResultExplanation}></Route>
            <Route exact path="/student-dashboard/list/pariksha/test-initiative/:moduleUUID/:userCode/:schoolCode" component={ParikshaInitiative}></Route>
            <Route exact path="/student-dashboard/list/pariksha/test-level-status/:moduleUUID/:userCode/:schoolCode" component={ParikshaTestLevelStatusView}></Route>
            <Route exact path="/student-dashboard/list/pariksha/progress-view/:moduleUUID/:userCode/:schoolCode/:BoardUuid/:MediumUuid/:StandardUuid/:SubjectUuid" component={ParikshaProgressView}></Route>
            <Route exact path="/student-dashboard/list/pariksha/questions/:moduleUUID/:userCode/:schoolCode/:subjectUuid" component={ParikshaUserQuestionsList}></Route>
            <Route exact path="/student-dashboard/list/pariksha/ability-result/:moduleUUID/:userCode/:schoolCode" component={ParikshaTestResultData}></Route>
            {/* <Route exact path="/student-dashboard/list/abhyas/success-page/:moduleUUID/:userCode/:schoolCode" component={AbhyasLevelSuccessData}></Route> */}
            <Route exact path="/student-dashboard/list/pariksha/success-page/:moduleUUID/:userCode/:schoolCode/:testNo" component={ParikshaLevelSuccessData}></Route>

        </Switch>
    )
}
