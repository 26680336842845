import React, { useEffect, useRef, useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { CREATE_MODULE_ANSWERS, GET_MNS_MODULES_BY_ID, GET_MNS_MODULE_BY_QUESTIONS, GET_MODULES_BY_ID, UPDATE_ASSIGN_USER } from '../../../app/service/module.service';
import FormBuilder from '../../../components/form-builder';

export const MNSQuestionForm = () => {
    const [moduleData, setModuleData] = useState<any>({});
    const [questionsData, setQuestionsData] = useState<any[]>([]);
    const [answerData, setAnswerData] = useState<any[]>([]);
    const [currentAnswerData, setCurrentAnswerData] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    const [languageQuestionsData, setLanguageQuestionsData] = useState<any>([]);
    const [userRole, setUserRole] = useState<any>();
    const [canShowPopup, setCanShowPopup] = useState(false);
    const [canShowCompleteFormBtn, setCanShowCompleteFormBtn] = useState(false);
    const [questionsFormData, setQuestionsFormData] = useState<any>({});
    const [moduleType, setModuleType] = useState<any>();
    const surveyForm = useRef<any>({});
    const history = useHistory();
    let { id, moduleCode } = useParams<{ id: string, moduleCode: string }>();
    const user_code = id;
    const modules_code = moduleCode;

    const languages = [{
        "name": "English",
        "code": "en"
    }, {
        "name": "Hindi",
        "code": "hi"
    }, {
        "name": "Marathi",
        "code": "mr"
    },
    {
        "name": "Gujarati",
        "code": "gu"
    }
    ];

    useEffect(() => {
        const userRole = localStorage.getItem("user_role");
        setUserRole(userRole);
        GET_MNS_MODULES_BY_ID(user_code, modules_code).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                } else {
                    if (res) {
                        setModuleData(res.rows[0]);
                        setModuleType(res.rows[0].sub_module);
                        getMNSQuestions(res.rows[0]);
                    }

                }
            }
        });
    }, []);

    function getMNSQuestions(data: any) {
        const payload = {
            user_code: data.user_code,
            school_code: data.school_code,
            module_code: data.module_code,
            sub_module: data.sub_module,
            module_ver: data.module_ver,
            target_std: "10"
        }
        setLoading(true);
        GET_MNS_MODULE_BY_QUESTIONS(payload).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    const data = res.en;
                    const preparedData = [];
                    setLanguageQuestionsData(res);
                    const answers = res.answer;
                    setCurrentAnswerData(answers);
                    setQuestionsData(data);
                    setLoading(false);
                    const selectedQuestions = data.filter((el: any) => el.selectedOption);

                    if (selectedQuestions.length === data.length) {
                        setCanShowCompleteFormBtn(true);
                    } else {
                        setCanShowCompleteFormBtn(false);
                    }
                }
            }
        });
    }

    function handleQuestionOptions(e: any, j: number, index: number) {

        const data = questionsData;
        data[j].checked = true;
        data[j].questionIndex = j;
        data[j].selectedOption = data[j].question_type === 'multiple_choice' ? "" + index : (e.target.value ? e.target.value : null);

        const selectedQuestions = questionsData.filter(el => el.selectedOption);

        if (selectedQuestions.length === questionsData.length) {
            setCanShowCompleteFormBtn(true);
        } else {
            setCanShowCompleteFormBtn(false);
        }
        setQuestionsData(data);
    }

    const handleOptions = (data: any) => {
        setQuestionsFormData(data);
    }

    function onSubmitAnswers(type: any) {
        const answer = [];

        for (let j = 0; j < questionsData.length; j++) {
            if (questionsData[j].selectedOption) {
                let requiredAnswer = "";
                if (questionsData[j].selectedOption === "0") {
                    requiredAnswer = "a";
                } else if (questionsData[j].selectedOption === "1") {
                    requiredAnswer = "b";
                } else if (questionsData[j].selectedOption === "2") {
                    requiredAnswer = "c";
                } else {
                    requiredAnswer = questionsData[j].selectedOption;
                }
                answer.push({
                    "key": questionsData[j].questionIndex + 1,
                    "value": requiredAnswer,
                    'type': questionsData[j].question_type
                });
            }

        }
        const payload = {
            user_code: moduleData.user_code,
            school_code: moduleData.school_code,
            module_code: moduleData.module_code,
            sub_module: moduleData.sub_module,
            module_ver: moduleData.module_ver,
            target_std: "10",
            answer: answer
        }


        setLoading(true);
        CREATE_MODULE_ANSWERS(payload).then(res => {
            if (res.status === 'fail') {
                toast.error(res.message);
                setLoading(false);
            } else {
                setLoading(false);
                if (type === "submit") {
                    setCanShowPopup(true);
                }
            }
        });
    }

    const handleCompleteSubmit = () => {
        onSubmitAnswers("complete");
        moduleUpdate();
    }

    function moduleUpdate() {
        const payload = {
            id: moduleData.id,
            module_status: "completed"
        }
        setLoading(true);
        UPDATE_ASSIGN_USER(payload).then(res => {
            if (res.status === 'fail') {
                toast.error(res.message);
                setLoading(false);
            } else {
                setLoading(false);
                setCanShowPopup(true);
            }
        });
    }

    function handleLanguageChange(e: any) {
        const type = e.target.value;
        const data = languageQuestionsData;
        if (type == "en") {
            setQuestionsData(data.en ? data.en : []);
        } else if (type == "hi") {
            setQuestionsData(data.hi ? data.hi : []);
        } else if (type == "mr") {
            setQuestionsData(data.mr ? data.mr : []);
        } else if (type == "gu") {
            setQuestionsData(data.gu ? data.gu : []);
        } else {
            setQuestionsData([]);
        }
    }

    const handleBack = () => {
        history.goBack();
    }

    const handleclose = () => {
        setCanShowPopup(false);
        history.goBack();
    }

    const handleSave = () => {
        setCanShowPopup(false);
        history.goBack();
    }

    const handleCancel = () => {
        history.goBack();
    }

    return (
        <div className="row border-top border-primary py-3">

            <div className={`modal fade ${canShowPopup ? 'show' : 'false'}`} style={{ display: (canShowPopup ? "block" : 'none') }} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Survey Status</h5>
                            <button type="button" className="close popup-close" data-dismiss="modal" aria-label="Close" onClick={handleclose}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            Thank you for completing the survey.You can access Score sheet & Report now
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary cursor-pointer" data-dismiss="modal" onClick={handleclose}>Close</button>
                            <button type="button" className="btn btn-primary cursor-pointer" onClick={handleSave}>Ok</button>
                        </div>
                    </div>
                </div>
            </div>
            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading && <div>

                <div className="row">
                    <div className="col-md-11">
                        <h2 style={{ display: 'inline-block' }}>MNS Question</h2>
                        <select name="language" className="mb-3 form-select" style={{ marginLeft: '15px', width: '20%', display: 'inline-block' }} onChange={(e) => { handleLanguageChange(e) }}>
                            {languages.map((name: any, i: number) => (
                                <option key={i} value={name.code}>
                                    {name.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="col-md-1" style={{ float: 'right' }}>
                        <button className="btn btn-primary btn-sm px-4  rounded-12 cursor-pointer" onClick={handleBack}>Back</button>
                    </div>
                </div>
                <hr />
                <div className="col-md-12 questions-grid">
                    <p>
                        The report will be generated based on your responses given to the questionnaire. If you give a response unknowingly or without proper understanding will create/generate a different report. Hence please think thoroughly before you answer the questionnaire.
                    </p>
                </div>
                {moduleType === 'C' && <div>
                    {questionsData.map((question: any, i: number) => (
                        <div className="col-md-12 questions-grid" key={i}>
                            <div className="mb-4">
                                <label className="form-label mb-3" style={{ fontWeight: 'bold' }}>
                                    <span>{question.id}. </span>
                                    <div>
                                        {question.list.map((questionName: any, j: number) => (
                                            <div key={j} style={{ marginLeft: '25px' }}>
                                                <div style={{ marginTop: '15px' }}>
                                                    <span>{questionName.id}. </span>
                                                    {questionName.question}
                                                </div>
                                                <div style={{ marginTop: '15px' }}>
                                                    <FormBuilder onUpdate={handleOptions}>
                                                        <form ref={surveyForm}>
                                                            {questionName.options.map((option: any, k: number) => (
                                                                <div key={k} style={{ display: "inline-block" }}>
                                                                    {question.question_type === 'multiple_choice' &&
                                                                        <div>
                                                                            <input className="mb-0" type="radio" name="option" defaultChecked={parseInt(question?.selectedOption) === k} value={option} key={k} onChange={(e) => { handleQuestionOptions(e, i, k) }} />
                                                                            <span style={{ paddingRight: '15px', paddingLeft: '15px' }}>{option}</span>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            ))}
                                                            {questionName.question_type === 'textarea' && <textarea className="form-control" name="option" value={question.selectedOption} onChange={(e) => { handleQuestionOptions(e, i, -1) }}></textarea>}
                                                            {questionName.question_type === 'input_text' && <input type="text" className="form-control" name="option" value={question.selectedOption} onChange={(e) => { handleQuestionOptions(e, i, -1) }} />}
                                                        </form>
                                                    </FormBuilder>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </label><br />
                            </div>
                        </div>
                    ))}
                </div>}
                {(moduleType === 'A' || moduleType === 'B') && <div>
                    {questionsData.map((question: any, i: number) => (
                        <div className="col-md-12 questions-grid" key={i}>
                            <div className="mb-4">
                                <label className="form-label mb-3" style={{ fontWeight: 'bold' }}>
                                    <span>{question.id}. </span>
                                    {question.question}
                                </label><br />
                                <div>
                                    <FormBuilder onUpdate={handleOptions}>
                                        <form ref={surveyForm}>
                                            {question.options.map((option: any, j: number) => (
                                                <div key={j} style={{ display: "inline-block" }}>
                                                    {question.question_type === 'multiple_choice' &&
                                                        <div>
                                                            <input className="mb-0" type="radio" name="option" defaultChecked={parseInt(question?.selectedOption) === j} value={option} key={j} onChange={(e) => { handleQuestionOptions(e, i, j) }} />
                                                            <span style={{ paddingRight: '15px', paddingLeft: '15px' }}>{option}</span>
                                                        </div>
                                                    }
                                                </div>
                                            ))}
                                            {question.question_type === 'textarea' && <textarea className="form-control" name="option" style={{ width: '50%' }} value={question.selectedOption} onChange={(e) => { handleQuestionOptions(e, i, -1) }}></textarea>}
                                            {question.question_type === 'input_text' && <input type="text" className="form-control" name="option" style={{ width: '50%' }} value={question.selectedOption} onChange={(e) => { handleQuestionOptions(e, i, -1) }} />}
                                        </form>
                                    </FormBuilder>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>}
                {questionsData.length == 0 && <div style={{ textAlign: 'center' }}>
                    Not Found
                </div>}
                {questionsData.length > 0 && <div className="form-footer border-top border-primary py-3 text-end mt-4">
                    <p style={{ textAlign: 'center', marginLeft: '11rem' }}>
                        The report is based on the responses given by you. So, if you find some portions are not matching your thoughts or actions. Please review the answers you have given.
                    </p>
                    {(userRole === 'PARENT' || userRole === 'STUDENT') && <button className="btn btn-primary btn-sm px-5 rounded-12 cursor-pointer" disabled={!canShowCompleteFormBtn} onClick={handleCompleteSubmit}>Submit</button>}&nbsp;
                    <a className="text-dark text-decoration-none ms-2 cursor-pointer" onClick={handleCancel}>Cancel</a>
                </div>}
            </div>}
        </div >
    )
}
