import React from 'react'
import { Switch, Route } from 'react-router-dom';
import InstitutePage from '../pages/dashboard/institute';
import SchoolPage from '../pages/dashboard/school';
import { PrincipaList } from '../pages/dashboard/school/principal/list';
import { StudentDashboardView } from '../pages/dashboard/student/view';
import DashboardPage from '../pages/institute-dashboard/dashboard';
import ActiveStudentsSchools from '../pages/institute-dashboard/dashboard/active-students/list';
import ActiveStudentsList from '../pages/institute-dashboard/dashboard/active-students/studentslist';
import Dashboard from '../pages/institute-dashboard/dashboard/dashboard';
import InitiativesList from '../pages/institute-dashboard/dashboard/initiatives-opted/initiativeslist';
import InitiativeOptedSchools from '../pages/institute-dashboard/dashboard/initiatives-opted/list';
import RegisteredSchools from '../pages/institute-dashboard/dashboard/registered-schools/list';
import RegisteredStudents from '../pages/institute-dashboard/dashboard/registered-students/list';
import RegisteredStudentsList from '../pages/institute-dashboard/dashboard/registered-students/studentslist';
import { Feedback } from '../pages/institute-dashboard/feedback/feedback';
import ManageSchoolPage from '../pages/institute-dashboard/manage-school';
import { ManageSchool } from '../pages/institute-dashboard/manage-school/manage-shcool';
import { SchoolInFormation } from '../pages/institute-dashboard/manage-school/schoolinfo';
import { Profile } from '../pages/institute-dashboard/profile/view';
import { Settings } from '../pages/institute-dashboard/settings/settings';
import { ViewReport } from '../pages/institute-dashboard/view-report/view-report';
import { ActiveStudentModuleList } from '../pages/institute-dashboard/dashboard/active-students/modulelist';
import { ActiveStudentReportView } from '../pages/institute-dashboard/dashboard/active-students/report/student';
import { ActiveFamilyReportView } from '../pages/institute-dashboard/dashboard/active-students/report/family';
import { ActiveStudentMathsReportView } from '../pages/institute-dashboard/dashboard/active-students/report/maths/student';
import { ActiveParentReportView } from '../pages/institute-dashboard/dashboard/active-students/report/parent';


export const InstituteDashBoardRoutes = () => {
  return (
    <Switch>
      <Route path="/institute-dashboard/profile" component={Profile}></Route>
      <Route path="/institute-dashboard/institute" component={InstitutePage}></Route>
      <Route path="/institute-dashboard/settings" component={Settings}></Route>
      <Route path="/institute-dashboard/view-report" component={ViewReport}></Route>
      <Route path="/institute-dashboard/manage-school" component={ManageSchoolPage}></Route>
      <Route path="/institute-dashboard/school" component={SchoolPage}></Route>
      <Route path="/institute-dashboard/feedback" component={Feedback}></Route>
      <Route exact path="/institute-dashboard/student/view/:usercode/:studentCode" component={StudentDashboardView}></Route>
      <Route exact path="/institute-dashboard/dashboard/registered-schools" component={RegisteredSchools}></Route>
      <Route exact path="/institute-dashboard/dashboard/registered-students" component={RegisteredStudents}></Route>
      <Route exact path="/institute-dashboard/dashboard/active-students" component={ActiveStudentsSchools}></Route>
      <Route exact path="/institute-dashboard/dashboard/initiatives-opted" component={InitiativeOptedSchools}></Route>
      <Route exact path="/institute-dashboard/initiativeslist/:id/:schoolCode" component={InitiativesList}></Route>
      <Route exact path="/institute-dashboard/dashboard/registered-studentslist/:id/:schoolCode" component={RegisteredStudentsList}></Route>
      <Route exact path="/institute-dashboard/dashboard/active-studentslist/:id/:schoolCode" component={ActiveStudentsList}></Route>
      <Route exact path="/institute-dashboard/dashboard/active-studentsmoduleslist/:user_code" component={ActiveStudentModuleList}></Route>
      <Route exact path="/institute-dashboard/dashboard" component={DashboardPage}></Route>

      {/* // Reports Routing */}

      <Route exact path="/institute-dashboard/dashboard/list/reports/student/:id/:studentCode" component={ActiveStudentReportView}></Route>
      <Route exact path="/institute-dashboard/dashboard/list/reports/family/:id/:userCode/:studentCode" component={ActiveFamilyReportView}></Route>
      <Route exact path="/institute-dashboard/dashboard/list/maths/reports/student/:userCode" component={ActiveStudentMathsReportView}></Route>
      <Route exact path="/institute-dashboard/dashboard/list/reports/parent/:id/:userCode/:studentCode" component={ActiveParentReportView}></Route>
    </Switch>
  )
}
