import { DataTableCol } from "../../../components/data-table/types";

export const PrtomotionListDataGridCols: DataTableCol[] = [
    {
        title: 'Promotion Name',
        control: 'name',
        sortable: true,
        canShowColumn: true

    },

    {
        title: 'Promotion Type',
        control: 'promotion_type',
        sortable: true,
        canShowColumn: true

    },
    {
        title: 'Discount Type',
        control: 'discount_type',
        sortable: true,
        canShowColumn: true

    },
    {
        title: 'Coupon Codes',
        control: 'coupon_code',
        sortable: false,
        canShowColumn: true

    },
    {
        title: 'Created',
        control: 'created_dt',
        sortable: true,
        canShowColumn: true

    },
    {
        title: 'Modified',
        control: 'modified_dt',
        sortable: true,
        canShowColumn: true

    },
    {
        title: 'Actions',
        control: 'both',
        sortable: false,
        isEdit: true,
        isDelete: false,
        canShowColumn: true
    },
];


