import { Switch, Route, Redirect } from "react-router";
import { ParentInfo } from "./parent-info";


interface Props {
    match?: any;
}
const ParentRoutes = (props: Props) => {

    let url: string | undefined = props.match?.url;
    if (url?.endsWith('/')) {
        url = url.substr(0, url.length - 1);
    }


    return (
        <Switch>
            <Route path={`${url}/info/:id`} exact>
                <ParentInfo></ParentInfo>
            </Route>
            <Route path={`${url}/info/:id/childlist/:schoolCode/:studentId`} exact>
                <ParentInfo></ParentInfo>
            </Route>
            <Route path={`${url}/info/:id/childform/:schoolCode/:studentId/:userId`} exact>
                <ParentInfo></ParentInfo>
            </Route>
            <Route path={`${url}/info/:id/modulelist/:schoolCode/:studentId`} exact>
                <ParentInfo></ParentInfo>
            </Route>
        </Switch>
    );
}
export default ParentRoutes;