import React, { useEffect, useRef, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { GET_MLA_TWO_DETAILS } from '../../../../app/service/mla-two.service';

export const MLATwoWorksheet = () => {
    const [loading, setLoading] = useState(false);
    const [abilityList, setAbilityList] = useState<any>([]);
    const [abilityRecords, setAbilityRecords] = useState<any>([]);
    const [activePage, setActivePage] = useState(1);
    const [pageNumbers, setPageNumbers] = useState([]);
    const [worksheetsView, setWorksheetsView] = useState(false);
    let { id, code } = useParams<{ id: string, code: string }>();
    const history = useHistory();

    useEffect(() => {
        getAbilityList();
        const accessObjectsData: any = localStorage.getItem("access_objects_data");
        onHandleAction(JSON.parse(accessObjectsData));
    }, []);

    function getAbilityList() {
        setLoading(true);
        GET_MLA_TWO_DETAILS().then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    setLoading(false);
                    setAbilityList(res.abilities);
                    setAbilityRecords(res.abilities.slice(0, 10));
                    const pageNumbers = Math.ceil(res.abilities.length / 10);
                    const pageArray: any = [];
                    for (let i = 1; i <= pageNumbers; i++) {
                        pageArray.push(i);
                    }
                    setPageNumbers(pageArray);
                    setActivePage(1);
                }
            }
        });
    }

    function onClickActionType(item: any, type: any) {
        history.push(`/dashboard/module/info/MLA-2/${code}/${id}/worksheet-numbers/${item.code}`);
    }


    function onClickPage(pageNumber: number) {
        setActivePage(pageNumber);
        handlePagination(pageNumber);
    }

    function onClickNextPage() {
        setActivePage(activePage + 1);
        handlePagination(activePage + 1);
    }

    function onClickPreviousPage() {
        setActivePage(activePage - 1);
        handlePagination(activePage - 1);
    }

    function handlePagination(pageNumber: any) {
        const itemsPerPage = (pageNumber - 1) * 10;
        const perPageCount = abilityList.slice(itemsPerPage, pageNumber * 10);
        setAbilityRecords(perPageCount);
    }


    function onHandleAction(accessObjectsList: any) {
        const preparedData = accessObjectsList?.filter((el: any) => el.object_code === "MODULES");
        if (preparedData?.length > 0) {
            for (let i = 0; i < preparedData[0].child.length; i++) {
                if (preparedData[0].child[i].object_code === "MODULES-MLA-2-WORKSHEETS-VIEW" && preparedData[0].child[i].p_read === 1) {

                    setWorksheetsView(true);
                }


            }
        }
    }

    return (

        <div className="row py-3">
            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading &&
                <React.Fragment>
                    <div className="table-responsive mt-2">
                        <table className="table fixed-table-header mb-0">
                            <thead>
                                <tr>
                                    <th>Ability Id</th>
                                    <th>Topic</th>
                                    <th>Name</th>
                                    <th>Worksheets Count</th>
                                    {worksheetsView && <th>Action</th>}
                                </tr>
                            </thead>
                            <tbody>
                                {abilityRecords.map((name: any, i: number) => (
                                    <tr key={i}>
                                        <td>{name.ability_id}</td>
                                        <td>{name.topic}</td>
                                        <td>{name.name}</td>
                                        <td>{name.woksheets_count ? name.woksheets_count : 0}</td>
                                        {worksheetsView && <td>
                                            <i className="bi bi-eye-fill cursor-pointer p-2" onClick={() => onClickActionType(name, 'view')}></i>
                                        </td>}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    {abilityList.length === 0 && <div style={{ textAlign: 'center' }}>
                        No records Found
                    </div>}
                </React.Fragment>}

            {abilityList.length > 0 &&
                <div>
                    <nav className='mt-3' style={{ float: 'right' }}>
                        <ul className="pagination">
                            <li className="page-item">
                                <button className={`page-link prev ${activePage === 1 ? 'disabled' : ''}`} onClick={() => onClickPreviousPage()}>Previous</button>
                            </li>
                            {pageNumbers.map((number: number, i: number) => (
                                <li className={activePage === number ? 'active page-item' : 'page-item'} key={i}>
                                    <button className="page-link" onClick={() => onClickPage(number)}>{number}</button>
                                </li>
                            ))}
                            <li className="page-item">
                                <button className={`page-link next ${activePage === pageNumbers.length ? 'disabled' : ''}`} onClick={() => onClickNextPage()}>Next</button>
                            </li>
                        </ul>
                    </nav>
                </div>
            }
        </div>
    )
}

