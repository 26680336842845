import React, { useEffect, useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import appConfig from '../../../../config/constant';
import { PDFViewer } from 'react-view-pdf';
import { GET_STUDENT_MATH_REPORT } from '../../../../app/service/mns-service';


export const StudentMathsReport = () => {
    const [reportData, setReportData] = useState(false);
    const [loading, setLoading] = useState(false);
    const [pdfUrl, setPdfUrl] = useState('');
    const [invalidReport, setInvalidReport] = useState(false);
    let { userCode } = useParams<{ userCode: string }>();
    const history = useHistory();

    useEffect(() => {
        getStudentMathsReport();
    }, []);


    function getStudentMathsReport() {
        setLoading(true);
        GET_STUDENT_MATH_REPORT(userCode).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                    setInvalidReport(true);
                } else {
                    const data = res;
                    setPdfUrl(`${appConfig.FILE_UPLOAD_URL}${data.url.charAt(0) === "/" ? data.url.substring(1) : data.url}`);
                    setLoading(false);
                    setReportData(true);
                    setInvalidReport(false);
                }
            }
        });
    }


    function onClickReportDownload() {
        window.open(`${pdfUrl}`, "_blank", 'noopener,noreferrer');
    }


    const handleBack = () => {
        history.goBack();
    }

    return (
        <div className="row border-top border-primary">
            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading && <div>
                <div className='row'>
                    <div className='col-md-11'>
                        {invalidReport && <h1 className='' style={{ textAlign: 'center' }}> Report not generated</h1>}
                    </div>
                    <div className="col-md-1 mt-2" style={{ float: 'right' }}>
                        <button className="btn btn-primary btn-sm px-4  rounded-12 cursor-pointer" onClick={handleBack}>Back</button>
                    </div>
                </div>
                {reportData && <div style={{ backgroundColor: 'white', paddingTop: '50px' }}>
                    <div className="row">
                        <div className='col-md-12'>
                            <button className="btn btn-primary px-3 mb-2 cursor-pointer" style={{ border: 'none', float: 'right', backgroundColor: '#0f70cd', borderRadius: '5px', color: 'white', marginRight: '5px' }} onClick={() => onClickReportDownload()}>Download</button>
                        </div>
                    </div>
                    <div className="row">
                        <div className='col-md-12'>
                            <PDFViewer url={pdfUrl} />
                        </div>
                    </div>
                </div>}
            </div>}
        </div>
    )
}