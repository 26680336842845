import React, { useEffect, useState } from 'react'
import { Link, NavLink, useHistory, useLocation, useParams } from 'react-router-dom'
import "react-datepicker/dist/react-datepicker.css";
import NestedMLATwoList from '../../../../routes/insidemlatwo';
import { Breadcum } from '../../../../components/breadcum';

export const MLATwoInFo = () => {
    const [loading, setLoading] = useState(false);
    const [userRole, setUserRole] = useState<any>();
    const [accessTests, setAccessTests] = useState(false);
    const [accessWorkSheets, setAccessWorkSheets] = useState(false);
    let { id, code } = useParams<{ id: string, code: string }>();
    // const studentId = Number(id);
    const moduleId = Number(id);
    const moduleCode = code;
    const location = useLocation();
    const history = useHistory();
    //destructuring pathname from location
    const { pathname } = location;
    //Javascript split method to get the name of the path in array
    const splitLocation = pathname.split("/");

    useEffect(() => {
        const userRole = localStorage.getItem("user_role");
        setUserRole(userRole);
        // setBreadcumInfo(breadcumInfo);
        const accessObjectsData: any = localStorage.getItem("access_objects_data");
        onHandleNav(JSON.parse(accessObjectsData));
    }, []);


    function onHandleNav(accessObjectsList: any) {
        const preparedData = accessObjectsList?.filter((el: any) => el.object_code === "MODULES");
        if (preparedData?.length > 0) {
            for (let i = 0; i < preparedData[0].child.length; i++) {
                if (preparedData[0].child[i].object_code === "MODULES-MLA-2-TESTS" && preparedData[0].child[i].p_read === 1) {
                    setAccessTests(true);
                }
                if (preparedData[0].child[i].object_code === "MODULES-MLA-2-WORKSHEETS" && preparedData[0].child[i].p_read === 1) {
                    setAccessWorkSheets(true);
                }
            }
        }
    }
    return (
        <div className="row  py-3">
            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading && <div className='row'>
                <div className=" col-md-12 mt-2">
                    <ul className="nav nav-tabs custom-tabs">
                        <li className="nav-item">
                            {accessWorkSheets && <NavLink className={(splitLocation[7] == "worksheet-numbers" || splitLocation[7] == "worksheet-add"
                                || splitLocation[7] == "worksheet-edit") ? "nav-link active" : "nav-link"} to={{ pathname: `/dashboard/module/info/MLA-2/${moduleCode}/${moduleId}/worksheet`, state: { data: "" } }}>Worksheets</NavLink>}
                        </li>
                        <li className="nav-item">
                            {accessTests && <NavLink className={(splitLocation[7] == "tests-add" || splitLocation[7] == "tests-edit") ? "nav-link active" : "nav-link"} to={{ pathname: `/dashboard/module/info/MLA-2/${moduleCode}/${moduleId}/tests`, state: { data: "" } }}>Tests</NavLink>}
                        </li>
                    </ul>
                    <div>
                        <NestedMLATwoList />
                    </div>
                </div>
            </div>}
        </div>
    )
}

