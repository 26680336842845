import React, { useEffect, useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom';
import MSBLOGO from '../../../assets/images/iamsamarth-Logo-Final.svg';
import HRRLOGO from '../../../assets/images/HRRDC-logo.png';
import { Bar } from 'react-chartjs-2';
import CanvasJSReact from './../../../assets/canvasjs.react';
import { GET_REPORT_BY_USER, GET_STUDENT_REPORT } from '../../../app/service/reports.service';
import { GET_MODULE_BY_UUID, GET_MODULE_STUDENT_BY_ID } from '../../../app/service/module.service';
import { ToastContainer, toast } from 'react-toastify';
import appConfig from '../../../config/constant';
import { PDFViewer } from 'react-view-pdf';

var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

export const FamilyReport = () => {
    const [barchartData, setBarchartData] = useState<any>([]);
    const [tableData, setTableData] = useState<any>([]);
    const [messagesData, setMessagesData] = useState<any>([]);
    const [reportData, setReportData] = useState(false);
    const [chartPreparedData, setChartPreparedData] = useState<any[]>([]);
    const [chartPreparedData1, setChartPreparedData1] = useState<any[]>([]);
    const [chartPreparedData2, setChartPreparedData2] = useState<any[]>([]);
    const [parent1Info, setParent1Info] = useState<any>({});
    const [parent2Info, setParent2Info] = useState<any>({});
    const [urlInfo, setUrlInfo] = useState<any>({});
    const [moduleData, setModuleData] = useState<any>({});
    const [loading, setLoading] = useState(false);
    const [pdfUrl, setPdfUrl] = useState('');
    const [invalidReport, setInvalidReport] = useState(false);

    let { id, userCode, studentCode } = useParams<{ id: string, userCode: string, studentCode: string }>();
    const moduleId = Number(id);
    const usersCode = userCode;
    const studentsCode = studentCode;
    const history = useHistory();

    useEffect(() => {
        getModuleData();
    }, []);

    function getModuleData() {
        GET_MODULE_BY_UUID(usersCode).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                } else {
                    const data = res.rows[0];
                    if (data) {
                        setModuleData(data);
                        getStudentReports(data);
                    }
                }
            }
        });
    }

    function getStudentReports(data: any) {
        const payload = {
            // student_code: studentsCode === "0" ? data.user_code : studentsCode,
            student_code: studentsCode,
            module_code: data.module_code,
            sub_module: data.sub_module,
            module_ver: data.module_ver,
            report_type: "mother",
            // pdf: true
        }
        setLoading(true);
        GET_REPORT_BY_USER(payload).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                    setInvalidReport(true);
                } else {
                    const data = res;
                    setPdfUrl(`${appConfig.FILE_UPLOAD_URL}${data.url.charAt(0) === "/" ? data.url.substring(1) : data.url}`);
                    setLoading(false);
                    setReportData(true);
                    setInvalidReport(false);
                }
            }
        });

    }

    const options = {
        axisX: {
            interval: 1
        },
        axisY: {
            // interlacedColor: "rgba(1,77,101,.2)",
            // gridColor: "rgba(1,77,101,.1)",
            title: ""
        },
        data: [{
            type: "bar",
            color: "#006a4e",
            indexLabel: "{y}",
            yValueFormatString: "#,###'%'",
            dataPoints: chartPreparedData
        },
        {
            type: "bar",
            color: " #000080",
            indexLabel: "{y}",
            yValueFormatString: "#,###'%'",
            dataPoints: chartPreparedData1
        },
        {
            type: "bar",
            color: "#FFBF27",
            indexLabel: "{y}",
            yValueFormatString: "#,###'%'",
            dataPoints: chartPreparedData2
        }]
    }

    function onClickReportDownload() {
        // if (data.url) {
        //     // setUrlInfo(`http://35.171.104.106:8501/${data.url}`);
        // }
        const payload = {
            student_code: studentsCode === "0" ? moduleData.user_code : studentsCode,
            module_code: moduleData.module_code,
            sub_module: moduleData.sub_module,
            module_ver: moduleData.module_ver,
            report_type: "family",
            pdf: true
        }
        GET_STUDENT_REPORT(payload).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                } else {
                    const data = res;
                    if (data.url) {
                        window.open(`${appConfig.BASE_URL}/${data.url}`, "_blank", 'noopener,noreferrer');
                    }
                }
            }
        });
    }

    const handleBack = () => {
        history.goBack();
    }

    return (
        <div className="row border-top border-primary">
            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading && <div>
                <div className='row'>
                    <div className='col-md-11'>
                        {invalidReport && <h1 className='' style={{ textAlign: 'center' }}> Report not generated</h1>}
                    </div>
                    <div className="col-md-1 mt-2" style={{ float: 'right' }}>
                        <button className="btn btn-primary btn-sm px-4  rounded-12 cursor-pointer" onClick={handleBack}>Back</button>
                    </div>
                </div>
                {reportData && <div style={{ backgroundColor: 'white', paddingTop: '50px' }}>
                    <div className="row">
                        <div className='col-md-12'>
                            <button className="btn btn-primary px-3 cursor-pointer" style={{ border: 'none', float: 'right', backgroundColor: '#0f70cd', borderRadius: '5px', color: 'white', marginRight: '5px' }} onClick={() => onClickReportDownload()}>Download</button>
                        </div>
                    </div>
                    <div className="row">
                        <div className='col-md-12'>
                            <PDFViewer url={pdfUrl} />
                        </div>

                    </div>
                </div>}
            </div>}
        </div >
    )
}