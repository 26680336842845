import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import SUBJECTCOMPLETE from '../../../../institute-dashboard-assets/images/subj-completed-logo.svg'


export default function CommonLevelData(props: any) {

    useEffect(() => {

    }, []);

    return (
        <div className="status-cards d-flex align-items-center mb-2">
            <h3 className="f20 m-0 text-black fw-medium flex-100">Level {props.level} </h3>
            <div className='ms-2'>
                {props.data.length > 0 &&
                    props.data.map((item: any, i: number) => (
                        <>
                            {(item?.level === props?.level && item?.status === 'completed') &&
                                <div className='text-center d-inline-block ms-4' key={i}>
                                    <img src={SUBJECTCOMPLETE} className={`${item?.level_skip === "yes" && "image-to-grey"}`} />
                                    <span className='f10 d-block fw-bold'>{item.sub_level}</span>
                                </div>
                            }
                        </>
                    ))}
            </div>
        </div>

    )
};
