import React, { useEffect, useState } from 'react'
import { Link, useHistory, useLocation, useParams } from 'react-router-dom'
import { DELETE_USER, DOWNLOAD_STUDENT_TEMPLATE, GET_USER_BY_SCHOOL_CODE } from '../../../../app/service/users.service'
import { DataTable } from '../../../../components/data-table'
import { AdminDataGridCols } from './data-grid-cols'
import { ToastContainer, toast } from 'react-toastify';
import { BULK_UPLOAD_SCHOOL } from '../../../../app/service/school.service'
import appConfig from '../../../../config/constant'

export const InstituteAdminList = () => {
    let { id, instituteCode } = useParams<{ id: string, instituteCode: string }>();
    const instituteId = parseInt(id);
    const institutesCode = instituteCode;
    const [pageArray, setPageNumbers] = useState([]);
    const [activePage, setActivePage] = useState<any>();
    const [adminList, setAdminList] = useState<any>([]);
    const [loading, setLoading] = useState(false);
    const [userRole, setUserRole] = useState<any>();
    const search = useLocation().search;
    const currentPageNumber: any = new URLSearchParams(search).get('page');
    const history = useHistory();
    const [accessObjectsList, setAccessObjectsList] = useState<any>([]);
    const [admin, setAdmin] = useState(false);
    const [schoolTemplate, setSchoolTemplate] = useState(false);
    const [schoolList, setSchoolList] = useState(false);
    const [apiLoaded, setApiLoaded] = useState<any>(false);


    useEffect(() => {
        const userRole = localStorage.getItem("user_role");
        setUserRole(userRole);
        getAdmins();
        const accessObjectsData: any = localStorage.getItem("access_objects_data");
        setAccessObjectsList(JSON.parse(accessObjectsData));
        handleAdminCols(JSON.parse(accessObjectsData));
    }, []);

    function getAdmins() {
        const activePageNumber = currentPageNumber > 0 ? (currentPageNumber - 1) : 0;
        if (institutesCode) {
            setLoading(true);
            GET_USER_BY_SCHOOL_CODE(institutesCode, 'INSTITUTE_ADMIN', activePageNumber, {}).then((res: any) => {
                setApiLoaded(true);
                if (res) {
                    if (res.status === 'fail') {
                        toast.error(res.message);
                        setLoading(false);
                    } else {
                        setActivePage(currentPageNumber ? currentPageNumber : 1);
                        const data = res.rows;
                        data.forEach((element: any) => {
                            element.date_of_birth = element.date_of_birth.split(" ")[0];
                        });
                        setAdminList(data);
                        const pageNumbers = Math.ceil(res.count / 10);
                        const pageArray: any = [];
                        for (let i = 1; i <= pageNumbers; i++) {
                            pageArray.push(i);
                        }
                        setPageNumbers(pageArray);
                        setLoading(false);
                    }

                }
            });
        }
    }

    const onPageChange = (data: any) => {
        setActivePage(data);
    }

    const onEditAdmin = (data: any) => {
        history.push(`/dashboard/institute/info/${instituteId}/adminform/${institutesCode}/${data.id}`);
        if (userRole === "CHAIRMAN" || userRole === "GENERAL_SECRETARY" || userRole === "INSTITUTE_ADMIN") {
            history.push(`/institute-dashboard/institute/info/${instituteId}/adminform/${institutesCode}/${data.id}`);

        }
    }

    const onDeleteAdmin = (data: any) => {
        setLoading(true);
        const id = data.user_code;
        DELETE_USER(id).then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res.message);
                setLoading(false);
            } else {
                setLoading(false);
                toast.success("Admin Deleted Successfully");
                getAdmins();
            }
        });
    }

    function handleUploadBulkUpload(e: any) {
        if (e.target.files && e.target.files[0]) {
            let formData = new FormData();
            formData.append('file', e.target.files[0], e.target.files[0].name);
            formData.append('organization_code', institutesCode);
            setLoading(true);

            BULK_UPLOAD_SCHOOL(formData).then((res: any) => {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    setLoading(false);
                    toast.success("Bulk Upload Successfully");
                    getAdmins();
                }
            });
        }
    }

    function handleDownloadTemplate() {
        setLoading(true);
        let dataUrl = "/templates/schools_bulk_upload.xls"
        const url = `${appConfig.FILE_UPLOAD_URL}${dataUrl.charAt(0) === "/" ? dataUrl.substring(1) : dataUrl}`;
        window.location.href = url;
        toast.success("Download School Template Successfully");
        setLoading(false);
    }
    const handleAdminCols = (accessObjectsList: any) => {
        const preparedData = accessObjectsList?.filter((el: any) => el.object_code === "INSTITUTES");
        if (preparedData?.length > 0) {
            for (let i = 0; i < preparedData[0].child.length; i++) {
                for (let j = 0; j < AdminDataGridCols.length; j++) {

                    if (preparedData[0].child[i].object_code === "INSTITUTES-INSTITUTE-ADMIN" && preparedData[0].child[i].p_read === 1) {
                        setAdmin(true);
                    }
                    if (preparedData[0].child[i].object_code === "INSTITUTES-INSTITUTE-ADMIN-DOWNLOAD-SCHOOL-LIST-TEMPLATE" && preparedData[0].child[i].p_read === 1) {
                        setSchoolTemplate(true);
                    }
                    if (preparedData[0].child[i].object_code === "INSTITUTES-INSTITUTE-ADMIN-UPLOAD-SCHOOL-LIST" && preparedData[0].child[i].p_read === 1) {
                        setSchoolList(true);
                    }
                    if (preparedData[0].child[i].object_code === "INSTITUTES-INSTITUTE-ADMIN-EDIT" && preparedData[0].child[i].p_read === 1) {
                        if (AdminDataGridCols[j].title == "Actions") {
                            AdminDataGridCols[j].isEdit = true;
                        }
                    }
                    if (preparedData[0].child[i].object_code === "INSTITUTES-INSTITUTE-ADMIN-SET-PASSWORD" && preparedData[0].child[i].p_read === 1) {
                        if (AdminDataGridCols[j].title == "Actions") {
                            AdminDataGridCols[j].setPassword = true;
                        }
                    }
                    if ((preparedData[0].child[i].object_code === "INSTITUTES-INSTITUTE-ADMIN-EDIT" && preparedData[0].child[i].p_read === 1) ||
                        (preparedData[0].child[i].object_code === "INSTITUTES-INSTITUTE-ADMIN-SET-PASSWORD" && preparedData[0].child[i].p_read === 1)) {
                        if (AdminDataGridCols[j].title == "Actions") {
                            AdminDataGridCols[j].canShowColumn = true;
                        }
                    }
                }
            }
        }

    }

    return (
        <div>
            {admin &&
                <>
                    {(userRole === "SUPER_ADMIN" || userRole === "INSTITUTE_ADMIN") && <div className='row'>
                        <div className='col-md-5'></div>
                        <div className='col-md-3 mt-4 mb-4'>
                            {schoolTemplate && <button className="btn btn-sm w-100 btn-primary px-4 rounded-12 cursor-pointer" style={{ marginRight: '10px', color: 'white' }} onClick={() => handleDownloadTemplate()}>Download School List Template
                            </button>}
                        </div>
                        {schoolList && <div className="col-md-2 file btn btn-sm btn-primary px-4 mt-4 mb-4 rounded-12 d-inline-block" style={{ cursor: 'pointer' }}>Upload School List
                            <input type="file" style={{ cursor: 'pointer' }} onChange={(e) => handleUploadBulkUpload(e)} />
                        </div>}
                        {(adminList.length === 0 && apiLoaded) && <div className="col-md-1 border-primary py-3 mt-2 text-end">
                            {userRole !== "CHAIRMAN" && userRole !== "GENERAL_SECRETARY" && userRole !== "INSTITUTE_ADMIN" && <Link to={`/dashboard/institute/info/${instituteId}/adminform/${institutesCode}/0`} className="btn btn-primary btn-sm px-5 rounded-12">New</Link>}

                            {(userRole === "CHAIRMAN" || userRole === "GENERAL_SECRETARY" || userRole === "INSTITUTE_ADMIN") && <Link to={`/institute-dashboard/institute/info/${instituteId}/adminform/${institutesCode}/0`} className="btn btn-primary btn-sm px-5 rounded-12">New</Link>}
                        </div>}
                    </div>}
                    {loading &&
                        <div className="text-center p-5">
                            <div className="spinner-border" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                    }
                    {!loading &&
                        <DataTable TableCols={AdminDataGridCols} tableData={adminList} editInfo={onEditAdmin} deleteInfo={onDeleteAdmin}
                            activePageNumber={activePage} pageNumber={onPageChange} pageNumbers={pageArray}></DataTable>}
                </>
            }
        </div>
    )
}