import React from 'react'
import { Route, Switch } from 'react-router'
import { SchoolProfilemodulelist } from '../../pages/school-dashboard/profile/module/list';
import { SchoolProfilePaymentlist } from '../../pages/school-dashboard/profile/payment/list';
import SchoolProfileStudentscount from '../../pages/school-dashboard/profile/student-count/list';
import { ProfileStudentslist } from '../../pages/school-dashboard/profile/students/list';
import SchoolProfileTeacherlist from '../../pages/school-dashboard/profile/teacher/list';
import { StudentsForm } from '../../pages/dashboard/school/students/form';
import { TeachersForm } from '../../pages/dashboard/school/Teachers/form';



const SchoolProfileInsideRoutings = () => {
    return (
        <Switch>
            <Route path="/school-dashboard/profile/teacher/list/:schoolCode" component={SchoolProfileTeacherlist}></Route>
            <Route path="/school-dashboard/profile/students/list/:id/:schoolCode" component={ProfileStudentslist}></Route>
            <Route exact path="/school-dashboard/profile/student-count/list/:schoolCode" component={SchoolProfileStudentscount}></Route>
            <Route exact path="/school-dashboard/profile/module/list/:schoolCode" component={SchoolProfilemodulelist}></Route>
            <Route exact path="/school-dashboard/profile/payment/list/:schoolCode" component={SchoolProfilePaymentlist}></Route>
            <Route path="/school-dashboard/profile/studentsform/:id/:schoolCode/:userId" component={StudentsForm}></Route>
            <Route path="/school-dashboard/profile/teachersform/:schoolCode/:userId" component={TeachersForm}></Route>

        </Switch>
    )
}

export default SchoolProfileInsideRoutings;