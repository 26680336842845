import React, { useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import { CREATE_SCHOOL_USER, UPLOAD_LOGO } from '../../../app/service/school.service';
import FormBuilder from '../../../components/form-builder';
import { FormControlError, FormField, FormValidators } from '../../../components/form-builder/model/form-field';
import { FormValidator, GetControlIsValid } from '../../../components/form-builder/validations';
import { ToastContainer, toast } from 'react-toastify';
import moment from 'moment';
import appConfig from '../../../config/constant';
import errorMessages from '../../../components/error-messages';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { DateComponent } from '../../../components/date';

interface Props {
    schoolIdNumber: number;
    vicePrincipalName: (value: any) => void;
}

export const VicePrincipalUser = (props: Props) => {
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [vicePrincipalvalidationErrors, setVicePrincipalvalidationErrors] = useState<FormControlError[]>([]);
    const [vicePrincipalData, setVicePrincipalData] = useState<any>({});
    const [vicePrincipalInfo, setVicePrincipalInfo] = useState<any>({});
    const [vicePrincipalLogo, setVicePrincipalLogo] = useState<any>();
    const [canShowPopup, setCanShowPopup] = useState(false);
    const [confirmPopup, setConfirmPopup] = useState(false);
    const [loading, setLoading] = useState(false);
    const [imageLoader, setImageLoader] = useState(false);
    const [currentVicePrincipalData, setCurrentVicePrincipalData] = useState<any>({});
    // const [aadharNumber, setAadharNumber] = useState(false);
    const [mobileNumber, setMobileNumber] = useState(false);
    const [whatsappNumber, setWhatsappNumber] = useState(false);
    const [min, setMin] = useState<any>(moment().subtract(60, "years").format("YYYY-MM-DD"));
    const [max, setMax] = useState<any>(moment().subtract(18, "years").format("YYYY-MM-DD"));
    const [email, setEmail] = useState(false);
    const [canCheckMobileNumber, setCanCheckMobileNumber] = useState(false);
    const [canShowWhatsAppError, setCanShowWhatsAppError] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [isDateError, setIsDateError] = useState<any>({});
    const userRole = localStorage.getItem("user_role");

    let { id } = useParams<{ id: string }>();
    const schoolIdNumber = id;

    const vicePrincipalFormValidations = [
        new FormField('name', [FormValidators.REQUIRED]),
        new FormField('gender', [FormValidators.REQUIRED]),
        new FormField('date_of_birth', []),
        new FormField('blood_group', []),
        new FormField('city_village', []),
        new FormField('email_id', [FormValidators.REQUIRED]),
        new FormField('mobile_number', [FormValidators.REQUIRED]),
        new FormField('whatsapp_number', []),
    ];

    function createVicePrincipal() {
        const vicePrincipalSData = vicePrincipalData.value ? { ...vicePrincipalData.value } : { ...currentVicePrincipalData };
        setIsFormSubmitted(true);
        const errors: FormControlError[] = FormValidator(vicePrincipalFormValidations, vicePrincipalSData);
        setVicePrincipalvalidationErrors(errors);
        vicePrincipalSData.type = "school";
        vicePrincipalSData.user_role = "VICE_PRINCIPAL";
        vicePrincipalSData.institute_school_code = schoolIdNumber;
        vicePrincipalSData.profile_img = vicePrincipalLogo;
        vicePrincipalSData.date_of_birth = startDate ? moment(startDate).format('YYYY-MM-DD') : null;
        delete vicePrincipalSData.sameMobileNumber;
        delete vicePrincipalSData.file;
        delete vicePrincipalSData.checked;
        delete vicePrincipalSData.day;
        delete vicePrincipalSData.month;
        delete vicePrincipalSData.year;
        if (errors.length < 1 && !email && !mobileNumber) {
            vicePrincipalSData.whatsapp_number = vicePrincipalSData.whatsapp_number ? vicePrincipalSData.whatsapp_number : vicePrincipalSData.mobile_number;
            setVicePrincipalData(vicePrincipalSData);
            setConfirmPopup(true);
        }
        else {
            setCanShowWhatsAppError(true);
        }
    }

    const handleVicePrincipalInput = (data: any) => {
        data.value = { ...currentVicePrincipalData, ...data.value };
        setVicePrincipalData(data);
        const errors: any = FormValidator(vicePrincipalFormValidations, data.value);
        setVicePrincipalvalidationErrors(errors);
    };

    const getVicePrincipalInputValid = (control: string) => {
        const value = GetControlIsValid(vicePrincipalvalidationErrors, control);
        return value;
    }

    function handleUploadLogo(e: any, type: string) {
        if (e.target.files && e.target.files[0]) {
            const fileType = e.target.files[0].name.split('.').pop()
            if (fileType == "jpeg" || fileType == "jpg") {
                const formData = new FormData();
                formData.append('file', e.target.files[0], e.target.files[0].name);
                uploadLogo(formData, type);
            } else {
                toast.error("Valid file type .jpg, .jpeg only");
            }
        }
    }

    function uploadLogo(formdata: any, type: string) {
        setImageLoader(true);
        UPLOAD_LOGO(formdata).then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res.message);
                setImageLoader(false);
            } else {
                setVicePrincipalLogo(res.url);
                setImageLoader(false);
            }
        });
    }

    function onCreateVicePrincipal() {
        setLoading(true);
        const data = vicePrincipalData;
        delete data[''];
        CREATE_SCHOOL_USER(data).then((res: any) => {
            if (res.status === 'fail') {
                setConfirmPopup(false);
                toast.error(res.message);
                setLoading(false);
                setCurrentVicePrincipalData(data);
            } else {
                setConfirmPopup(false);
                setCanShowPopup(true);
                setVicePrincipalInfo(res);
                toast.success("Vice Principal Created Successfully");
                setLoading(false);
            }
        });
    }

    const handleclose = () => {
        setCanShowPopup(false);
        props.vicePrincipalName("chandu");
    }

    const handleConfirmclose = () => {
        setConfirmPopup(false);
        setCurrentVicePrincipalData(vicePrincipalData);
    }

    const handleSave = () => {
        onCreateVicePrincipal();
    }

    function onClickSkip() {
        props.vicePrincipalName("chandu");
    }

    const handleBloodGroupChange = (e: any) => {
        const data = { ...vicePrincipalData.value };
        data.blood_group = e.target.value;
        if (currentVicePrincipalData) {
            currentVicePrincipalData.blood_group = e.target.value;
        }
    }

    const handleMobileChange = (e: any) => {
        const data = { ...vicePrincipalData.value };
        const re = /(6|7|8|9)\d{9}/;

        if ((e.target.value === '' || re.test(e.target.value)) && e.target.value.length === 10) {
            data.mobile_number = e.target.value;
            setMobileNumber(false);
        } else {
            data.mobile_number = e.target.value;
            setMobileNumber(true);
        }

        if (currentVicePrincipalData) {
            currentVicePrincipalData.mobile_number = e.target.value.replace(/\D+/g, '');
        }

        if (currentVicePrincipalData.checked) {
            currentVicePrincipalData.whatsapp_number = e.target.value.replace(/\D+/g, '');
        }
    }

    const handleWhatsappChange = (e: any) => {
        const data = { ...vicePrincipalData.value };
        const re = /(6|7|8|9)\d{9}/;

        if ((e.target.value === '' || re.test(e.target.value)) && e.target.value.length === 10) {
            data.whatsapp_number = e.target.value;
            setWhatsappNumber(false);
            setCanShowWhatsAppError(false);
        } else {
            data.whatsapp_number = e.target.value;
            setWhatsappNumber(true);
        }

        if (currentVicePrincipalData) {
            currentVicePrincipalData.whatsapp_number = e.target.value.replace(/\D+/g, '');
        }
    }

    const handleEmailChange = (e: any) => {
        const data = { ...vicePrincipalData.value };
        // const re = /[a-z0-9\._%+!$&*=^|~#%'`?{}/\-]+@([a-z0-9\-]+\.){1,}([a-z]{2,16})/;
        const re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,6})+$/;


        if (e.target.value === '' || re.test(e.target.value)) {
            data.email_id = e.target.value;
            setEmail(false);
        } else {
            data.email_id = e.target.value;
            setEmail(true);
        }

        if (currentVicePrincipalData) {
            currentVicePrincipalData.email_id = e.target.value;
        }
    }

    const handleMobileNumberChange = (e: any) => {
        const data = { ...vicePrincipalData.value };
        if (e.target.checked) {
            if (data.whatsapp_number) {
                data.whatsapp_number = data.mobile_number;
                currentVicePrincipalData.whatsapp_number = data.mobile_number;
                setCanCheckMobileNumber(true);
                setCanShowWhatsAppError(false);
            }
            if (data.mobile_number || currentVicePrincipalData.mobile_number) {
                setCanCheckMobileNumber(true);
                setCanShowWhatsAppError(false);
                currentVicePrincipalData.whatsapp_number = currentVicePrincipalData.mobile_number;
                setCurrentVicePrincipalData(currentVicePrincipalData);
            }
            currentVicePrincipalData.checked = true;
        } else {

            setCanCheckMobileNumber(false);
            currentVicePrincipalData.checked = false;
        }
    }

    const onChange = (dates: any) => {
        setStartDate(null);
        if (dates.error) {
            setIsDateError(dates);
        } else {
            setStartDate(dates.date);
        }
    };

    function addMonths(theDate: any, years: any) {
        return new Date(theDate.getTime() - years * 24 * 60 * 60 * 1000);
    }

    function subMonths(theDate: any, years: any) {
        return new Date(theDate.getTime() - years * 24 * 60 * 60 * 1000);
    }

    const handleGender = (e: any) => {
        const data = { ...vicePrincipalData.value };
        data.gender = e.target.value;
        if (currentVicePrincipalData) {
            currentVicePrincipalData.gender = e.target.value;
        }
    }

    return (
        <div>
            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            <div className={`modal fade ${confirmPopup ? 'show' : 'false'}`} style={{ display: (confirmPopup ? "block" : 'none') }} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Alert</h5>
                            <button type="button" className="close popup-close cursor-pointer" data-dismiss="modal" aria-label="Close" onClick={handleConfirmclose}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <p>
                                “Kindly recheck details on the form. Once saved, they cannot be changed later”
                            </p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary cursor-pointer" data-dismiss="modal" onClick={handleConfirmclose}>Back</button>
                            <button type="button" className="btn btn-primary" onClick={handleSave}>Save</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`modal fade ${canShowPopup ? 'show' : 'false'}`} style={{ display: (canShowPopup ? "block" : 'none') }} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Vice Principal Login Details</h5>
                            <button type="button" className="close popup-close cursor-pointer" data-dismiss="modal" aria-label="Close" onClick={handleclose}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div>Login ID: {vicePrincipalInfo.user_name}</div>
                            <div>Password: {vicePrincipalInfo.password}</div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary cursor-pointer" data-dismiss="modal" onClick={handleclose}>Close</button>
                        </div>
                    </div>
                </div>
            </div>
            {!loading && <div>
                <FormBuilder onUpdate={handleVicePrincipalInput}>
                    <form>
                        <div style={{ borderBottom: 'solid 1px black', marginTop: '15px', paddingLeft: '10px' }} className="mb-4">
                            <h5 className="form-label mb-2 d-block">Personal Details</h5>
                        </div>
                        <div className="row custom-form">
                            <div className="col-md-4">
                                <div className="mb-4">
                                    <label className="form-label mb-0">Mrs./ Mr
                                        <span style={{ paddingLeft: '5px' }}>Name</span></label>
                                    <span style={{ color: 'red', fontSize: '22px', paddingLeft: '5px' }}>*</span>
                                    <input className="form-control form-control-lg" type="text" name="name" defaultValue={currentVicePrincipalData.name} placeholder="Please enter name" />
                                    {isFormSubmitted && !getVicePrincipalInputValid('name') && <p className="text-danger">Please fill the field</p>}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="mb-4">
                                    <label className="form-label mb-4">Gender</label>
                                    <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>*</span><br />
                                    <input className="mb-0" type="radio" value="male" name="gender" checked={currentVicePrincipalData?.gender === "male"} onChange={(e) => { handleGender(e) }} />
                                    <span style={{ paddingRight: '15px', paddingLeft: '15px' }}>Male</span>
                                    &nbsp;
                                    <input className="mb-0" type="radio" value="female" name="gender" checked={currentVicePrincipalData?.gender === "female"} onChange={(e) => { handleGender(e) }} />
                                    <span style={{ paddingRight: '15px', paddingLeft: '15px' }}>Female</span>

                                    <input className="mb-0" type="radio" value="transgender" name="gender" checked={currentVicePrincipalData?.gender === "transgender"} onChange={(e) => { handleGender(e) }} />
                                    <span style={{ paddingRight: '15px', paddingLeft: '15px' }}>Other</span>

                                    {isFormSubmitted && !getVicePrincipalInputValid('gender') && <p className="text-danger">Please fill the field</p>}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="mb-4">
                                    <label className="form-label mb-0">Date of Birth</label>
                                    <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>*</span>
                                    {currentVicePrincipalData.date_of_birth &&
                                        <DateComponent onDate={onChange} yearDiff={18} defaultData={currentVicePrincipalData.date_of_birth}></DateComponent>}
                                    {!currentVicePrincipalData.date_of_birth && <DateComponent onDate={onChange} yearDiff={18}></DateComponent>}
                                    {isFormSubmitted && !startDate && !isDateError?.error && <p className="text-danger">Please select date</p>}
                                    {isDateError?.error && !startDate && <p className="text-danger">{isDateError?.error}</p>}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="mb-4">
                                    <label className="form-label mb-0">Blood Group</label>
                                    <select name="blood_group" value={currentVicePrincipalData.blood_group} onChange={(e) => { handleBloodGroupChange(e) }} className="form-select form-select-lg mb-3">
                                        <option value="">Select blood group</option>
                                        <option value="O+">O+</option>
                                        <option value="O-">O-</option>
                                        <option value="A-">A-</option>
                                        <option value="A+">A+</option>
                                        <option value="B-">B-</option>
                                        <option value="B+">B+</option>
                                        <option value="AB-">AB-</option>
                                        <option value="AB+">AB+</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="mb-4">
                                    <div className="file btn btn-sm btn-primary px-4 rounded-12 mt-2 d-inline-block">Upload Photo
                                        <input type="file" accept="image/*" onChange={(e) => handleUploadLogo(e, "viceprincipal")} />
                                    </div>
                                </div>
                            </div>
                            {imageLoader &&
                                <div className="text-center col-md-1 p-5">
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div>
                            }
                            {vicePrincipalLogo && <div className="col-md-4">
                                <div className="mb-4">
                                    <label className="form-label mb-0 d-block">Preview Photo</label>
                                    <img src={`${appConfig.FILE_UPLOAD_URL}${vicePrincipalLogo.charAt(0) === "/" ? vicePrincipalLogo.substring(1) : vicePrincipalLogo}`} style={{ height: '100px', width: '100px', objectFit: 'contain' }} />
                                </div>
                            </div>}


                            <div style={{ borderBottom: 'solid 1px black' }} className="mb-4">
                                <h5 className="form-label mb-2 d-block">Contact Details</h5>
                            </div>

                            <div className="col-md-4">
                                <div className="mb-4">
                                    <label className="form-label mb-0">Email</label>
                                    <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>*</span>
                                    <input className="form-control form-control-lg" type="text" name="email_id" value={currentVicePrincipalData.email_id} onChange={(e) => { handleEmailChange(e) }} placeholder="Please enter email" />
                                    {email && <p className="text-danger">{errorMessages.email}</p>}
                                    {isFormSubmitted && !getVicePrincipalInputValid('email_id') && <p className="text-danger">Please fill the field</p>}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="mb-4">
                                    <label className="form-label mb-0">Mobile Number</label>
                                    <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>*</span>
                                    <input className="form-control form-control-lg" type="text" name="mobile_number" maxLength={10} onChange={(e) => { handleMobileChange(e) }} value={currentVicePrincipalData.mobile_number} placeholder="Please enter mobile number" />
                                    {mobileNumber && <p className="text-danger">{errorMessages.mobile_number}</p>}
                                    {isFormSubmitted && !getVicePrincipalInputValid('mobile_number') && <p className="text-danger">Please fill the field</p>}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="mb-4">
                                    <label className="form-label mb-0">WhatsApp Number</label>
                                    (  <input type="checkbox" name="sameMobileNumber" checked={currentVicePrincipalData.checked} onChange={(e) => { handleMobileNumberChange(e) }} style={{ paddingLeft: '10px' }} />
                                    <span style={{ paddingLeft: '10px' }}>Same as Mobile Number</span> )
                                    <input className="form-control form-control-lg" disabled={currentVicePrincipalData.checked} type="text" name="whatsapp_number" maxLength={10} onChange={(e) => { handleWhatsappChange(e) }} value={currentVicePrincipalData.whatsapp_number} placeholder="Please enter whatsapp number" />
                                    {(whatsappNumber) && <p className="text-danger">{errorMessages.whatsapp_number}</p>}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="mb-4">
                                    <label className="form-label mb-0">City/Village (Optional)</label>
                                    <input className="form-control form-control-lg" type="text" name="city_village" defaultValue={currentVicePrincipalData.city_village} placeholder="Please enter city" />
                                </div>
                            </div>
                        </div>
                    </form>
                </FormBuilder>
                <div className="form-footer border-top border-primary py-3 text-end mt-4">
                    <a className="btn btn-primary btn-sm px-5 rounded-12 cursor-pointer" onClick={() => createVicePrincipal()}>Save & Next</a>&nbsp;
                    <a className="btn btn-primary btn-sm px-5 rounded-12 cursor-pointer" onClick={() => onClickSkip()}>Skip &Next </a>&nbsp;

                    {userRole !== "CHAIRMAN" && userRole !== "GENERAL_SECRETARY" && userRole !== "INSTITUTE_ADMIN" && <Link className="text-dark text-decoration-none ms-2 cursor-pointer" to="/dashboard/school/list">Cancel</Link>}
                    {(userRole === "CHAIRMAN" || userRole === "GENERAL_SECRETARY" || userRole === "INSTITUTE_ADMIN") && <Link className="text-dark text-decoration-none ms-2 cursor-pointer" to="/institute-dashboard/manage-school/list">Cancel</Link>}
                </div>
            </div>}
        </div>
    )
}
