import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { Link, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { GET_INDIVIDUAL_BY_USER_CODE, GET_USER_USER_CODE } from '../../../../app/service/users.service';
import { MNSProgressView } from '../mns/dashboard';
import SUCCESSIMG from '../../../../institute-dashboard-assets/images/success-img.svg'
import { FETCH_ABHYAS_USER_QUESTION_INFO, FETCH_ABHYAS_USER_QUESTION_PAPER } from '../../../../app/service/abhyas-module-questions.service';

export const AbhyasLevelSuccessData = () => {
    const [userInfo, setUserInfo] = useState<any>({});
    let { moduleUUID, userCode, schoolCode, testNo } = useParams<{ moduleUUID: string, userCode: string, schoolCode: string, testNo: string }>();
    const [loading, setLoading] = useState(false);
    const [testInfo, setTestInfo] = useState<any>({});
    const [type, setType] = useState<any>('');
    const history = useHistory();
    const [currentCycleInfo, setCurrentCycleInfo] = useState<any>(null);
    const [resultData, setResultData] = useState<boolean>(false);
    const [questionsData, setQuestionsData] = useState<any>([]);
    const [currentTestData, setCurentTestData] = useState<any>({});
    const [canShowPopup, setCanShowPopup] = useState(false);
    let selectUuids = JSON.parse(localStorage.getItem('userSelectedSubjectInfo') || '{}');

    useEffect(() => {
        getUserDetails();
        let testInfo: any = localStorage.getItem("abhyas_test_info");
        const type = localStorage.getItem("abhyas_type");
        const cycleNumber = localStorage.getItem("abhyas_cycle_no");
        setTestInfo(JSON.parse(testInfo));
        setType(type);
        getAbhyasInfo()

    }, []);

    function getUserDetails() {
        setLoading(true);
        GET_USER_USER_CODE(userCode).then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res?.message);
                setLoading(false);
            } else {
                if (res) {
                    const data = res?.rows;
                    if (data?.length > 0) {
                        setUserInfo(data[0]);
                    }
                    setLoading(false);
                }
            }
        });
    }
    function getAbhyasInfo() {
        let payload = {
            module_uuid: selectUuids?.module_uuid,
            school_code: selectUuids?.school_code,
            user_code: selectUuids?.user_code,
            cycle_no: '',
            board_uuid: selectUuids?.board_uuid,
            medium_uuid: selectUuids?.medium_uuid,
            standard_uuid: selectUuids?.standard_uuid,
            subject_uuid: selectUuids?.subject_uuid
        }
        localStorage.setItem('userSelectedSubjectInfo', JSON.stringify(payload));
        FETCH_ABHYAS_USER_QUESTION_INFO(payload).then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res?.err);
                setLoading(false);
            } else {
                if (res) {
                    const data = res;
                    setCurrentCycleInfo(data);
                    let status = data?.tests?.every((x: any) => x.status === 'completed');
                    if (data.cycle_no > 1 && !status) {
                        getRequestedCycleData(data.cycle_no - 1);
                    } else {
                        let testStatus = data?.tests?.find((x: any) => x.status === 'pending');
                        if (testStatus?.status === 'pending' && testStatus?.question_paper_uuid) {
                            setCurentTestData(testStatus);
                        } else {
                            if (testStatus?.question_paper_uuid === null || testStatus?.question_paper_uuid === "" || testStatus?.question_paper_uuid === 'undefined') {
                                toast.error(`No Questions available in This Test-${testStatus?.test_no}`);
                            }
                        }
                    }

                }
            }
        });
    }
    function getRequestedCycleData(cycle_no: any) {
        let payload = {
            "module_uuid": selectUuids?.module_uuid,
            "school_code": selectUuids?.school_code,
            "user_code": selectUuids?.user_code,
            "cycle_no": cycle_no ? cycle_no : '',
            "board_uuid": selectUuids?.board_uuid,
            "medium_uuid": selectUuids?.medium_uuid,
            "standard_uuid": selectUuids?.standard_uuid,
            "subject_uuid": selectUuids?.subject_uuid
        }
        FETCH_ABHYAS_USER_QUESTION_INFO(payload).then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res?.err);
            } else {
                if (res) {
                    const data = res;
                    setCurrentCycleInfo(data);
                    let testStatus = data?.tests?.find((x: any) => x.status === 'pending');
                    if (testStatus?.status === 'pending' && testStatus?.question_paper_uuid) {
                        setCurentTestData(testStatus);
                    }
                    else {
                        if (testStatus?.question_paper_uuid === null || testStatus?.question_paper_uuid === "" || testStatus?.question_paper_uuid === 'undefined') {
                            toast.error(`No Questions available in This Test-${testStatus?.test_no}`);
                        }
                    }
                }
            }
        });
    }
    const getAbhyaasQuestionsData = (questionData: any, testData: any) => {
        testData.subject_name = testInfo.subject_name?.charAt(0).toUpperCase() + testInfo.subject_name?.slice(1).toLowerCase();
        localStorage.setItem("backword_key", "false");
        localStorage.setItem('abhyas_test_info', JSON.stringify(testData));
        localStorage.setItem('abhyas_type', 'TESTS');
        localStorage.setItem('abhyas_cycle_no', questionData.cycle_no);
        getAbhyaasQuestionsList(testData, "TESTS")
    }
    function getAbhyaasQuestionsList(testInfo: any, type: any) {
        let payload = {
            "type": type,
            "uuid": testInfo?.question_paper_uuid
        }
        FETCH_ABHYAS_USER_QUESTION_PAPER(payload).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    const errorValue = Array.isArray(res?.err) ? res?.err[0] : res?.err;
                    toast.error(errorValue);
                    setLoading(false);
                } else {
                    if (res) {
                        if (res) {
                            if (Array.isArray(res)) {
                                setQuestionsData(res);
                                setCanShowPopup(true);
                            } else {
                                toast.error("Questions not found");

                            }
                        }
                    }
                }
            }
        });
    }
    function onClickStart() {
        localStorage.setItem("backword_key", "false");

        if (getCompleteLevelStatus()) {
            onTopicwiseReport();
        } else {
            getAbhyaasQuestionsData(currentCycleInfo, currentTestData)

            // history.push(`/student-dashboard/list/abhyas/progress-view/${moduleUUID}/${userCode}/${schoolCode}/${selectUuids?.board_uuid}/${selectUuids?.medium_uuid}/${selectUuids?.standard_uuid}/${selectUuids?.subject_uuid}`)

            // history.push(`/student-dashboard/list/abhyas/questions/${moduleUUID}/${userCode}/${schoolCode}/${selectUuids?.subject_uuid}`);

        }
    }
    const onClickLater = () => {
        history.push(`/student-dashboard/list/abhyas/test-level-status/${moduleUUID}/${userCode}/${schoolCode}`);
    }

    function onTopicwiseReport() {
        history.push(`/student-dashboard/list/abhyas/ability-result/${moduleUUID}/${userCode}/${schoolCode}`);
    }
    function getCompleteLevelStatus() {
        return currentCycleInfo?.tests?.every((item: any) => item.status === 'completed');
    }
    const onClickSave = () => {
        history.push(`/student-dashboard/list/abhyas/questions/${moduleUUID}/${userCode}/${schoolCode}/${selectUuids?.subject_uuid}`);
    }
    const onClickCancel = () => {
        setCanShowPopup(false);
    }
    return (
        <div>
            <div
                className={`modal fade ${canShowPopup ? "show" : "false"}`}
                style={{ display: canShowPopup ? "block" : "none" }}
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog pariksha-model-dialog" role="document">
                    <div className="modal-content pariksha-model-content">
                        <div className="modal-body pariksha-model-info">
                            <div className='pariksha-model-border ba bas-dashed '>
                                <div className='test-header '>
                                    <h5>Test Instructions</h5>
                                </div>
                                <div className='ab_test-info' >
                                    <div>
                                        <span>1.</span>
                                        <span className='ps-3'>Number of questions : {questionsData?.length}</span>
                                    </div>
                                    <div>
                                        <span>2.</span>
                                        <span className='ps-3'>Time limit to solve test : 60 Minutes</span>
                                    </div>
                                    <div>
                                        <span>3.</span>
                                        <span className='ps-3'>Result will be genarated immediately <br />
                                            <span className='ms-4 ps-1'>  after submission of the
                                                Assessment  Test -{currentCycleInfo?.tests[0]?.test_no} <br /> <span className='ms-4 ps-1'> {currentCycleInfo?.tests[1]?.test_no && `and Assessment Test -${currentCycleInfo?.tests[1]?.test_no}`}.</span></span>
                                        </span>
                                    </div>

                                </div>
                                <div className='start-test mt-4 justify-content-center'>
                                    <h6>Do you want to start {testInfo?.subject_name?.toUpperCase()}  Assessment Test -{currentTestData.test_no} ? </h6>
                                </div>
                                <div className='mt-4'>
                                    <div className='mt-3 d-flex' style={{ justifyContent: 'center' }}>
                                        <button className='btn test-btn' onClick={onClickSave}>YES</button>
                                        <button className='btn test-btn' onClick={onClickCancel}>Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading &&

                <div>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb pb-2 ps-0 pt-0 border-bottom">
                            <li className="breadcrumb-item "><Link to="/student-dashboard/list"><span className='pe-2'>Dashboard</span></Link></li> /
                            <li className="breadcrumb-item "><Link to={`/student-dashboard/list/abhyas/test-level-status/${moduleUUID}/${userCode}/${schoolCode}`}><span>Abhyaas</span></Link></li> /
                            {/* <li className="breadcrumb-item "><Link to={`/student-dashboard/list/abhyas/progress-view/${moduleUUID}/${userCode}/${selectUuids?.school_code}/${selectUuids?.board_uuid}/${selectUuids?.medium_uuid}/${selectUuids?.standard_uuid}/${selectUuids?.subject_uuid}`}><span>{testInfo?.subject_name}</span></Link></li> / */}
                            <li className="breadcrumb-item "><Link to={`/student-dashboard/list/abhyas/progress-view/${moduleUUID}/${userCode}/${selectUuids?.school_code}/${selectUuids?.board_uuid}/${selectUuids?.medium_uuid}/${selectUuids?.standard_uuid}/${selectUuids?.subject_uuid}`}><span>Assessment Test {Number(testNo)}</span></Link></li> /
                            <li className="breadcrumb-item "><span>Submission</span></li>
                        </ol>
                    </nav>
                    <div className="row mt-4">
                        <div className='col-md-11 col-12 text-center success-block mt-5'>
                            <div>
                                <div>
                                    <img src={SUCCESSIMG} alt="" />
                                    <h3 className="mt-2 active fw-normal">{userInfo?.name?.charAt(0).toUpperCase() + userInfo?.name?.slice(1).toLowerCase()}</h3>
                                    <h1 className="f22 text-mdgray">  You Have Submitted the</h1>
                                </div>
                                <h2 className='fw-light text-completed'>{type === 'TESTS' ? 'Assessment Test-' + Number(testNo) : ''}</h2>
                            </div>
                            {((Number(testNo) !== 3) && !getCompleteLevelStatus()) && <div>
                                <p className="f18  mt-4 m-0 ">You can now</p>
                                <p className="f22 fw-light m-0 ">Attempt  Assessment  Test-{Number(testNo) + 1}</p>
                            </div>}
                            {getCompleteLevelStatus() && <div>
                                <p className="f18  mt-4 m-0 ">You can check</p>
                                <div style={{ fontSize: '28px', color: '#0F70CD', cursor: 'pointer' }} onClick={() => { onTopicwiseReport() }}>Topics wise results</div>
                            </div>}
                            <button className='btn btn-primary mb-3  px-3 mt-4' onClick={() => { onClickStart() }}>
                                {getCompleteLevelStatus() ? "Show" : "Start Now"}
                            </button>
                            {!getCompleteLevelStatus() && <button className='btn btn-primary mb-3  px-4 mt-4 ms-4' onClick={() => { onClickLater() }}>
                                Later
                            </button>}
                        </div>

                    </div>
                </div>
            }
        </div>
    )
}