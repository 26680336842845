import { DataTableCol } from "../../../../components/data-table/types";

export const SponsorReportViewGridCols: DataTableCol[] = [
    {
        title: "Sponsor Name",
        control: "sponsor_name",
        sortable: true,
        canShowColumn: true,
    },
    {
        title: "Sponsorship Name",
        control: "sponsorship_name",
        sortable: true,
        canShowColumn: true,
    },
    {
        title: "Institute Name",
        control: "name_of_organizations",
        sortable: true,
        canShowColumn: true,
    },
    {
        title: "School Name",
        control: "name_of_schools",
        sortable: true,
        canShowColumn: true,
    },
    {
        title: "Standard",
        control: "standard",
        sortable: true,
        canShowColumn: true,
    },
    {
        title: "Division",
        control: "division",
        sortable: true,
        canShowColumn: true,
    },
    {
        title: "Students Name",
        control: "name",
        sortable: true,
        canShowColumn: true,
    },


];
