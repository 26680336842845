import { Switch, Route, Redirect } from "react-router";
import { SponsorReportList } from "./sponsor-list/list";
import { SponsorReportView } from "./view";
import { SponsoredStudentsReports } from "./report/student-list";




interface Props {
    match?: any;
}
const SponsorReportRoutes = (props: Props) => {

    let url: string | undefined = props.match?.url;
    if (url?.endsWith('/')) {
        url = url.substr(0, url.length - 1);
    }

    return (
        <Switch>
            <Route exact path={`${url}/list`}>
                <SponsorReportView></SponsorReportView>
            </Route>
            <Route exact path={`${url}/report`}>
                <SponsorReportView></SponsorReportView>
            </Route>
            <Route exact path={`${url}/student-list`}>
                <SponsoredStudentsReports></SponsoredStudentsReports>
            </Route>
        </Switch>
    );
}
export default SponsorReportRoutes;