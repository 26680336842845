import React from 'react'
import { Route, Switch } from 'react-router'
import { AbhyasBoardList } from '../pages/dashboard/modules/abhyas/board/list';
import { AbhyasMediumList } from '../pages/dashboard/modules/abhyas/medium/list';
import { AbhyasStandardList } from '../pages/dashboard/modules/abhyas/standard/list';
import { AbhyasSubjectList } from '../pages/dashboard/modules/abhyas/subject/list';
import { AbhyasChapterList } from '../pages/dashboard/modules/abhyas/chapter/list';
import { AbhyasTopicList } from '../pages/dashboard/modules/abhyas/topic/list';
import AbhyasQuestionsList from '../pages/dashboard/modules/abhyas/question/questions-list';
import { AbhyasWorksheetList } from '../pages/dashboard/modules/abhyas/worksheets/list';
import { AbhyasWorksheetDataList } from '../pages/dashboard/modules/abhyas/worksheets/worksheet-list';
import { AbhyasTestDataList } from '../pages/dashboard/modules/abhyas/tests/list';

const NestedAbhyasWorksheetList = () => {
    return (
        <Switch>
            <Route path="/dashboard/module/info/ABHYAS/:code/:id/:BoardUuid/:BoardName/:MediumUuid/:MediumName/:StandardUuid/:StandardName/:SubjectUuid/:SubjectName/chapter" component={AbhyasChapterList}></Route>
            {/* <Route path="/dashboard/module/info/ABHYAS/:code/:id/:BoardUuid/:BoardName/:MediumUuid/:MediumName/:StandardUuid/:StandardName/:SubjectUuid/:SubjectName/:ChapterUuid/:ChapterName/topic" component={AbhyasTopicList}></Route> */}
            {/* <Route path="/dashboard/module/info/ABHYAS/:code/:id/:BoardUuid/:BoardName/:MediumUuid/:MediumName/:StandardUuid/:StandardName/:SubjectUuid/:SubjectName/:ChapterUuid/:ChapterName/:TopicUuid/:TopicName/worksheet-list" component={AbhyasWorksheetDataList}></Route> */}
            <Route path="/dashboard/module/info/ABHYAS/:code/:id/:BoardUuid/:BoardName/:MediumUuid/:MediumName/:StandardUuid/:StandardName/:SubjectUuid/:SubjectName/test" component={AbhyasTestDataList}></Route>
            {/* <Route path="/dashboard/module/info/ABHYAS/:code/:id/:BoardUuid/:BoardName/:MediumUuid/:MediumName/:StandardUuid/:StandardName/:SubjectUuid/:SubjectName/:ChapterUuid/:ChapterName/:TopicUuid/:TopicName/:SheetUuid/:SheetNo/:Type/question" component={AbhyasQuestionsList}></Route> */}
            <Route path="/dashboard/module/info/ABHYAS/:code/:id/:BoardUuid/:BoardName/:MediumUuid/:MediumName/:StandardUuid/:StandardName/:SubjectUuid/:SubjectName/:SheetUuid/:SheetNo/:Type/abhyas-test-question" component={AbhyasQuestionsList}></Route>

        </Switch>
    )
}

export default NestedAbhyasWorksheetList;