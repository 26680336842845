import { DataTableCol } from "../../../components/data-table/types";

export const ObjectDataGridCols: DataTableCol[] = [
    {
        title: 'Object Name',
        control: 'object_name',
        canShowColumn: true

    },
    {
        title: 'Object Code',
        control: 'object_code',
        canShowColumn: true

    },

    {
        title: 'Object Description',
        control: 'description',
        canShowColumn: true

    },

];
