import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { GET_MODULES_BY_USER_CODES } from "../../../../app/service/module.service";
import { GET_USER_USER_CODE } from "../../../../app/service/users.service";
import { Link, useHistory, useParams } from "react-router-dom";
import SUBJECTCOMPLETE from '../../../../institute-dashboard-assets/images/subj-completed-logo.svg'

export const ActiveStudentModuleList = () => {
    const [loading, setLoading] = useState(false);
    const [moduleData, setModuleData] = useState<any>([]);
    const [studentInfo, setStudentInfo] = useState<any>({});
    let { user_code } = useParams<{ user_code: any }>();
    const userRole = localStorage.getItem("user_role");
    const history = useHistory();
    const studentCode = user_code;

    useEffect(() => {

        getUserDetails();

    }, []);

    function getUserDetails() {
        setLoading(true);
        GET_USER_USER_CODE(studentCode).then((response: any) => {
            if (response.status === 'fail') {
                toast.error(response.message);
                setLoading(false);
            } else {
                const data = response.rows[0];
                setStudentInfo(data);
                getModulesByUserCode();
                setLoading(false);

            }
        });
    }

    const getModulesByUserCode = () => {
        setLoading(true);
        GET_MODULES_BY_USER_CODES(studentCode).then((res: any) => {
            if (res) {
                if (res.status === "fail") {
                    toast.error(res.message);
                    setLoading(false);

                } else {
                    const data = res
                    let prepared = data.filter((el: any) => el.module_code !== 'CAS-PARENT');
                    setModuleData(prepared);
                    setLoading(false);

                }
            }
        });
    };
    const viewStudentReports = (data: any) => {
        if (data?.module_code === "CAS") {
            history.push(`/institute-dashboard/dashboard/list/reports/student/${data.uuid}/${data.user_details.user_code}`)

        } else if (data?.module_code === "MATHS") {
            history.push(`/institute-dashboard/dashboard/list/reports/maths/reports/student/${data.user_details.user_code}`);
        }
    }

    const viewParentsReports = (report: any, data: any) => {
        if (report === "father") {
            history.push(`/institute-dashboard/dashboard/list/reports/parent/${data?.uuid}/${data.parent_details?.mother.user_code}/${studentCode}`);

        } else {
            history.push(`/institute-dashboard/dashboard/list/reports/family/${data?.uuid}/${data.parent_details?.mother.user_code}/${studentCode}`);
        }

    }
    return (
        <div>
            {loading &&
                <div className="text-center p-5 mt-5">
                    <div className="spinner-border mt-5" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading && <div>

                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb border-bottom ps-0 pt-0 pb-2" style={{ letterSpacing: "0" }}>
                        {userRole === "SUPER_ADMIN" && <li className="breadcrumb-item mt-3"><Link to='/dashboard/view/instituteinfo' style={{ color: '#0d6efd' }}>Dashboard</Link> / <Link to='/dashboard/view/active-students' style={{ color: '#0d6efd' }}>{studentInfo?.name_of_school}</Link> / <Link to={`/institute-dashboard/dashboard/active-studentslist/${studentInfo?.name_of_school}/${studentInfo?.institute_school_code}`} style={{ color: '#0d6efd' }}><span>Active Students</span></Link> / <span>{studentInfo?.name}</span></li>}
                        {userRole !== "SUPER_ADMIN" && <li className="breadcrumb-item"><Link to='/institute-dashboard/dashboard' style={{ color: '#0d6efd' }}>Dashboard</Link> / <Link to='/institute-dashboard/dashboard/active-students' style={{ color: '#0d6efd' }}>{studentInfo?.name_of_school}</Link> / <Link to={`/institute-dashboard/dashboard/active-studentslist/${studentInfo?.name_of_school}/${studentInfo?.institute_school_code}`} style={{ color: '#0d6efd' }}><span>Active Students</span></Link> / <span>{studentInfo?.name}</span></li>}
                    </ol>
                </nav>
                <div className="row">
                    {moduleData?.map((data: any, i: number) => {
                        return (<div className="col-md-4 col-lg-3 mb-3" key={i}>
                            <div className={`module-card h-100 ${i % 2 == 1 && "card-two"}`}>
                                <h2 className={`module-title  ${data.module_code === "MATHS" ? "mb-4 pb-2" : "mb-0"}`}>{data.module_code}</h2>
                                {data.module_code === "CAS" && <h5 className="mb-3">Career Aspiration<sup>TM</sup> Survey</h5>}

                                <p className="mb-2  ">
                                    Payment - <label >{(data.amount_status === "PENDING" || data.amount_status === null) && <span className="text-inprogess">Pending</span>} {data.amount_status === "COMPLETED" && <span className="text-completed"> Paid</span>}</label>
                                </p>
                                <p className="mb-1">
                                    Student
                                    <label className='px-2'>- {data?.module_status === "completed" ? <span className="btn btn-continue" onClick={() => viewStudentReports(data)}> View Report </span> : <span className="text-inprogess">In Progress</span>}</label>
                                </p>
                                {data.module_code === "CAS" && <p className=" mb-1">
                                    Father
                                    <label className='ps-3'>- {data.parent_details.father?.module_status === "completed" ? <span className="btn btn-continue" onClick={() => viewParentsReports('father', data)}> View Report </span> : <span className="text-inprogess">In Progress</span>}</label>

                                </p>}
                                {data.module_code === "CAS" && <p className="m-0">
                                    Mother
                                    <label className='ps-2'>-{data.parent_details.mother?.module_status === "completed" ? <span className="btn btn-continue ms-1" onClick={() => viewParentsReports('mother', data)}> View Report </span> : <span className="text-inprogess ms-1">In Progress</span>}</label>
                                </p>}
                            </div>
                        </div>)
                    })}
                    {moduleData?.length === 0 && <p className="mt-4 f14">Modules are not Assigned</p>}
                </div>
            </div>}
        </div>
    )

}