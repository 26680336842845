import API from "../utility/axios";
const apiUrl = '/api/v1';

export const LOGIN = (data: any): Promise<any> => {
    return API.post(`${apiUrl}/user/login`, data);
}

// export const FORGOT_PASSWORD = (data: any): Promise<any> => {
//     return API.post(`${apiUrl}/user/resetpassword`, data);
// }

export const REQUEST_OTP = (data: any): Promise<any> => {
    return API.post(`${apiUrl}/request/otp`, data);
}

// export const REQUEST_PASSWORD = (data: any): Promise<any> => {
//     return API.post(`${apiUrl}/user/requestpassword`, data);
// }

export const RESET_PASSWORD = (data: any): Promise<any> => {
    return API.post(`${apiUrl}/user/resetpassword`, data);
}

export const CHANGE_PASSWORD = (data: any): Promise<any> => {
    return API.post(`${apiUrl}/user/changepassword`, data);
}
