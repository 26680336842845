import { ReduxReducerAction } from "../model/redux-reducer-action";
import { AppUtilityState } from "./model/app-utility-state";
import { PopupData } from "./model/popup-data";
import { AppUtilityTypes, UserDataReducerState } from "./types";
import {
    USERDATA
} from "../types";

const initialState: AppUtilityState = {
    popupData: new PopupData(false, '', '', [])
};

const userDataInitialState: UserDataReducerState = {
    userData: {}
}

export const AppUtilityReducer = (state: AppUtilityState = initialState, action: ReduxReducerAction): any => {
    let newState = state;
    switch (action.type) {
        case AppUtilityTypes.UPDATE_POPUP:
            newState = { ...state, popupData: action.data }
            break;

        default:
            break;
    }
    return newState;
}

export const UserData: any = (isLogin: UserDataReducerState = userDataInitialState, action: any) => {
    switch (action.type) {
        case USERDATA:
            return action.data;
        default:
            return isLogin;
    }
}