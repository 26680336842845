import { DataTableCol } from "../../../../components/data-table/types";

export const AdminDataGridCols: DataTableCol[] = [
  {
    title: "Name",
    control: "name",
    canShowColumn: true,
  },
  {
    title: "User Name",
    control: "user_name",
    canShowColumn: true,
  },
  {
    title: "Email",
    control: "email_id",
    canShowColumn: true,
  },
  {
    title: "Mobile Number",
    control: "mobile_number",
    canShowColumn: true,
  },
  {
    title: "City/Village",
    control: "city_village",
    canShowColumn: true,
  },
  {
    title: "Actions",
    control: "one",
    isEdit: false,
    canShowColumn: false,
    setPassword: false,
  },
];
