import React, { useEffect, useState } from 'react'
import EYEICONS from '../../../assets/images/eye-icons.svg'
import { ToastContainer, toast } from 'react-toastify';
import { GET_SCHOOL_DASHBOARD } from '../../../../app/service/school-dashboard.service';
import { Link, useHistory } from 'react-router-dom';

function CasAnalysys() {
    const userRole = localStorage.getItem("user_role");
    const [loading, setLoading] = useState(false);
    const [schoolData, setSchoolData] = useState<any>([]);
    const [aspetctData, setAspectData] = useState<any>([]);
    const history = useHistory();
    useEffect(() => {
        schoolDashboardDetails();

    }, []);

    const schoolDashboardDetails = () => {

        const data: any = localStorage.getItem("institute_code");
        setLoading(true);
        GET_SCHOOL_DASHBOARD(data).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    if (res) {
                        const data = res;
                        let classWiseData: any[] = [];
                        Object.keys(data.cas_data.average_results).map((x: any, i: number) => {
                            let aspectData: any[] = [];
                            for (let j = 0; j < data.cas_data.class_wise_data.length; j++) {
                                let element = data.cas_data.class_wise_data[j].average_results;
                                aspectData.push(element[x] ? element[x] : 0);
                            }
                            classWiseData.push({
                                name: x,
                                class_data: aspectData
                            })
                        });


                        setAspectData(classWiseData);

                        setSchoolData(data);

                    }
                    setLoading(false);
                }
            }
        });
    }






    return (
        <>
            {loading &&
                <div className="text-center p-5 mt-5">
                    <div className="spinner-border mt-5" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading &&
                <div>
                    <div className="d-flex flex-wrap flex-md-nowrap justify-content-between ">
                        <h3 className="d-lg-none" >Dashboard</h3>
                    </div>

                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb border-bottom ps-0 pt-0 pb-2">
                            {userRole === "SUPER_ADMIN" && <li className="breadcrumb-item mt-3"><Link to='/dashboard/view/schoolinfo' style={{ color: '#0d6efd' }}>Dashboard</Link> / <span>CAS Analysis</span></li>}
                            {userRole !== "SUPER_ADMIN" && <li className="breadcrumb-item"> <Link to='/school-dashboard/view' style={{ color: '#0d6efd' }}>Dashboard</Link> / <span>CAS Analysis</span></li>}

                        </ol>
                    </nav>

                    <h3 className='mt-4 mb-0 section-hd'>CAS Initiatives - Attempted</h3>
                    <p className='text-gray f12'>No. of Students Registered in each class</p>
                    <div className='row'>
                        <div className='col-md-7'>
                            {schoolData?.cas_data && (schoolData?.cas_data.class_wise_data.length > 0) ?
                                <div className='table-responsive'>

                                    <table className='table table_new_ui'>
                                        <thead>
                                            <tr>
                                                <th style={{ width: '100px' }}></th>
                                                {schoolData?.cas_data.class_wise_data && schoolData?.cas_data.class_wise_data.map((data: any, index: number) => {
                                                    return (
                                                        <>
                                                            <th style={{ width: '100px' }}>{data.class}th Class</th>
                                                        </>
                                                    )
                                                })}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Boys</td>

                                                {schoolData?.cas_data.class_wise_data && schoolData?.cas_data.class_wise_data.map((data: any, index: number) => {
                                                    return (
                                                        <td key={index}>{data.attempted_boys}</td>
                                                    )
                                                })}
                                            </tr>

                                            <tr>
                                                <td>Girls</td>

                                                {schoolData?.cas_data.class_wise_data && schoolData?.cas_data.class_wise_data.map((data: any, index: number) => {
                                                    return (
                                                        <td key={index}>{data.attempted_girls}</td>
                                                    )
                                                })}
                                            </tr>
                                            <tr>
                                                <td>Total</td>

                                                {schoolData?.cas_data.class_wise_data && schoolData?.cas_data.class_wise_data.map((data: any, index: number) => {
                                                    return (
                                                        <td key={index}>{data.attempted_students}</td>
                                                    )
                                                })}
                                            </tr>
                                        </tbody>

                                    </table>
                                </div>
                                : <div className="ms-2 mb-3 f14">No Data Available</div>}

                        </div>
                    </div>
                    <h3 className='mt-4 mb-0 section-hd'>Class wise Number of Regd / attempted</h3>
                    <p className='text-gray f12'>Students - Father - Mother</p>

                    <div className='row'>
                        <div className='col-md-6'>
                            <div className='table-responsive'>

                                <table className='table table_new_ui'>
                                    <thead>
                                        <tr>
                                            <th>Class/Section</th>
                                            <th style={{ width: '100px' }}>Registered</th>
                                            <th style={{ width: '100px' }}>Student</th>
                                            <th style={{ width: '100px' }}>Father</th>
                                            <th style={{ width: '100px' }}>Mother</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {schoolData?.cas_data && schoolData?.cas_data.class_wise_data.map((data: any, index: number) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{data.class}th</td>

                                                    <td>{data.registered_students}</td>

                                                    <td>{data.attempted_students}</td>

                                                    <td>{data.attempted_father}</td>

                                                    <td>{data.attempted_mother}</td>

                                                </tr>
                                            )
                                        })}
                                    </tbody>

                                </table>
                            </div>
                            {(schoolData?.cas_data && schoolData?.cas_data.class_wise_data.length === 0) && <div className="text-center mb-3 f14">No Data Available</div>}
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-6'>
                            {/* <h3 className='mt-4 mb-0 section-hd'>CAS Analysis</h3>
                                    <p className='text-gray f12'>Class Wise/Section wise</p> */}


                            <div>
                                <h3 className='mt-4 mb-3 section-hd f14'>Average % of each Aspect of CAS</h3>
                                {schoolData?.cas_data && (Object.keys(schoolData?.cas_data.average_results).length > 0) ? Object.keys(schoolData?.cas_data.average_results).map((data: any, index: number) => {
                                    return (
                                        <React.Fragment key={index}>
                                            <p className='my-1 f12'>{data.split(/_/g).map((word: any) => `${word.substring(0, 1).toUpperCase()}${word.substring(1)}`)
                                                .join(" ")}</p>
                                            <div className="progress_bar f12">
                                                <div className="progress_range_cas px-1 " style={{ width: `${schoolData?.cas_data.average_results[data]}%` }}><span>{schoolData?.cas_data.average_results[data]}%</span></div>
                                            </div>
                                        </React.Fragment>
                                    )
                                }) : <p className='mt-3 ms-3 f14'> No Data Available</p>}

                                <h3 className='mt-4 mb-0 section-hd'>CAS Initiatives - Attempted</h3>
                                <p className='text-gray f12'>No. of Students Registered in each class</p>

                                {aspetctData.length > 0 ? <div className='table-responsive'>

                                    <table className='table table_new_ui'>
                                        <thead>
                                            <tr>
                                                <th style={{ width: '360px' }}>Aspect</th>
                                                {schoolData?.cas_data.class_wise_data && schoolData?.cas_data.class_wise_data.map((data: any, index: number) => {
                                                    return (
                                                        <th style={{ width: '70px' }} key={index}>{data.class}th</th>
                                                    )
                                                })}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {aspetctData.length > 0 && aspetctData.map((data: any, index: number) => {

                                                return (<tr key={index}>
                                                    <td>{data.name.split(/_/g).map((word: any) => `${word.substring(0, 1).toUpperCase()}${word.substring(1)}`)
                                                        .join(" ")}</td>
                                                    {data.class_data.map((y: any, j: number) => {
                                                        return (
                                                            <td key={j}>{y}%</td>
                                                        )
                                                    })}
                                                </tr>
                                                )
                                            })}

                                        </tbody>

                                    </table>
                                </div>
                                    : <p className='my-2 ms-3 f14'>No Data Available</p>}



                            </div>
                        </div>
                    </div>

                </div>}
        </>
    )
}
export default CasAnalysys;
