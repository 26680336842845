import API from "../utility/axios";
const apiUrl = '/api/v1';

export const GET_SCHOOL_CAS_REPORTS = (pageNumber: number, data: any): Promise<any> => {
    return API.get(`${apiUrl}/school/cas/reports?limit=${10}&page_num=${pageNumber}`, { params: data });
}

export const GET_STUDENT_CAS_REPORTS_BY_SCHOOL_CODE = (schoolCode: any, pageNumber: number, data: any): Promise<any> => {
    return API.get(`${apiUrl}/school/user/cas/reports?school_code=${schoolCode}&limit=${10}&page_num=${pageNumber}`, { params: data });
}

export const GET_SCHOOL_CAS_REPORT_FILTER = (data: any): Promise<any> => {
    return API.get(`${apiUrl}/school/cas/reports?limit=${10}&page_num=${0}`, { params: data });
}

export const GET_STUDENT_CAS_REPORT_FILTER = (schoolCode: any, data: any): Promise<any> => {
    return API.get(`${apiUrl}/school/user/cas/reports?school_code=${schoolCode}&limit=${10}&page_num=${0}`, { params: data });
}

