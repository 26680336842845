import React, { useEffect, useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import { ParentDataGridCols } from './data-grid-cols';
import { ToastContainer, toast } from 'react-toastify';
import { DELETE_USER, GET_PARENT_BY_STUDENT, GET_USER_BY_SCHOOL_CODE, GET_USER_USER_CODE } from '../../../../../app/service/users.service';
import { DataTable } from '../../../../../components/data-table';
import { UsersListItem } from '../../../../../app/model/users/users-list';

export const ParentList = () => {
    let { id, schoolCode, studentId } = useParams<{ id: string, schoolCode: string, studentId: string }>();
    const schoolId = parseInt(id);
    const schoolsCode = schoolCode;
    const studentsCode = studentId;
    const [pageArray, setPageNumbers] = useState([]);
    const [activePage, setActivePage] = useState(1);
    const [parentList, setParentList] = useState<any>([]);
    const [loading, setLoading] = useState(false);
    const [parentInfo, setParentInfo] = useState<any>({});
    const [userRole, setUserRole] = useState<any>();
    const [studentParent, setStudentParent] = useState(false);
    const history = useHistory();
    const [accessObjectsList, setAccessObjectsList] = useState<any>([]);


    useEffect(() => {
        const userRole = localStorage.getItem("user_role");
        setUserRole(userRole);
        setLoading(true);
        GET_USER_USER_CODE(studentsCode).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    const data = res.rows[0];
                    setParentInfo(data);
                    getParents(data);
                    setLoading(false);
                }
            }
        });

        const accessObjectsData: any = localStorage.getItem("access_objects_data");
        setAccessObjectsList(JSON.parse(accessObjectsData));
        handleSchoolsParentsCols(JSON.parse(accessObjectsData));
    }, []);

    function getParents(data: any) {
        if (data?.guardian) {
            const guardian = data?.guardian ? data.guardian.replace(/[^a-zA-Z0-9,-_]/g, "") : "";
            GET_PARENT_BY_STUDENT(guardian).then((res: any) => {
                if (res) {
                    if (res.status === 'fail') {
                        toast.error(res.message);
                        setLoading(false);
                    } else {
                        const data = res.rows;
                        data.forEach((element: any) => {
                            element.date_of_birth = element.date_of_birth ? element.date_of_birth.split(" ")[0] : '';
                            element.student_code = studentsCode;
                        });
                        setParentList(data);
                        const pageNumbers = Math.ceil(res.count / 10);
                        const pageArray: any = [];
                        for (let i = 1; i <= pageNumbers; i++) {
                            pageArray.push(i);
                        }
                        setPageNumbers(pageArray);
                        setLoading(false);
                    }
                }
            });
        }

    }

    const onPageChange = (data: any) => {
        setActivePage(data);
    }

    const onEditAdmin = (data: any) => {
        history.push(`/dashboard/school/student/info/${schoolId}/parentform/${schoolsCode}/${studentsCode}/${data.id}`);
        if (userRole === "CHAIRMAN" || userRole === "GENERAL_SECRETARY" || userRole === "INSTITUTE_ADMIN") {
            history.push(`/institute-dashboard/school/student/info/${schoolId}/parentform/${schoolsCode}/${studentsCode}/${data.id}`);

        }
    }

    const onDeleteParent = (data: any) => {
        setLoading(true);
        const id = data.user_code;
        DELETE_USER(id).then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res.message);
                setLoading(false);
            } else {
                setLoading(false);
                toast.success(res.message);
                getParents(parentInfo);
            }
        });
    }

    const handleSchoolsParentsCols = (accessObjectsList: any) => {
        const preparedData = accessObjectsList?.filter((el: any) => el.object_code === "SCHOOLS");
        if (preparedData?.length > 0) {
            for (let i = 0; i < preparedData[0].child.length; i++) {
                for (let j = 0; j < ParentDataGridCols.length; j++) {
                    if (preparedData[0].child[i].object_code === "SCHOOLS-STUDENTS-PARENTS-EDIT" && preparedData[0].child[i].p_read === 1) {
                        if (ParentDataGridCols[j].title == "Actions") {
                            ParentDataGridCols[j].isEdit = true;
                        }

                    }

                    if (preparedData[0].child[i].object_code === "SCHOOLS-STUDENTS-PARENTS-DELETE" && preparedData[0].child[i].p_read === 1) {
                        if (ParentDataGridCols[j].title == "Actions") {
                            ParentDataGridCols[j].isDelete = true;
                        }
                    }

                    if (preparedData[0].child[i].object_code === "SCHOOLS-STUDENTS-PARENTS-SET-PASSWORD" && preparedData[0].child[i].p_read === 1) {
                        if (ParentDataGridCols[j].title == "Actions") {
                            ParentDataGridCols[j].setPassword = true;
                        }
                    }


                    if ((preparedData[0].child[i].object_code === "SCHOOLS-STUDENTS-PARENTS-EDIT" && preparedData[0].child[i].p_read === 1) ||
                        (preparedData[0].child[i].object_code === "SCHOOLS-STUDENTS-PARENTS-DELETE" && preparedData[0].child[i].p_read === 1) ||
                        (preparedData[0].child[i].object_code === "SCHOOLS-STUDENTS-PARENTS-SET-PASSWORD" && preparedData[0].child[i].p_read === 1)) {
                        if (ParentDataGridCols[j].title == "Actions") {

                            ParentDataGridCols[j].canShowColumn = true;
                        }
                    }
                    if (preparedData[0].child[i].object_code === "SCHOOLS-STUDENTS-PARENTS" && preparedData[0].child[i].p_read === 1) {
                        setStudentParent(true);
                    }
                }
            }
        }

    }

    return (
        <>
            {studentParent && <div>
                {(parentList.length < 2) && <div className="border-primary py-3 text-end">
                    {userRole !== "CHAIRMAN" && userRole !== "GENERAL_SECRETARY" && userRole !== "INSTITUTE_ADMIN" && <Link to={`/dashboard/school/student/info/${schoolId}/parentform/${schoolsCode}/${studentsCode}/0`} className="btn btn-primary btn-sm px-5 rounded-12">New</Link>}

                    {(userRole === "CHAIRMAN" || userRole === "GENERAL_SECRETARY" || userRole === "INSTITUTE_ADMIN") && <Link to={`/institute-dashboard/school/student/info/${schoolId}/parentform/${schoolsCode}/${studentsCode}/0`} className="btn btn-primary btn-sm px-5 rounded-12">New</Link>}
                </div>}
                {loading &&
                    <div className="text-center p-5">
                        <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                }
                {!loading && <DataTable TableCols={ParentDataGridCols} tableData={parentList}
                    editInfo={onEditAdmin} deleteInfo={onDeleteParent}
                    activePageNumber={activePage} pageNumber={onPageChange} pageNumbers={pageArray}></DataTable>}
            </div>}
        </>
    )
}