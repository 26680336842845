import React from "react";
import SchoolProfileRoutes from "./routes";

const SchoolProfilePage = (props: any) => {

    return (
        <div>
            <SchoolProfileRoutes {...props} />
        </div>
    )


}
export default SchoolProfilePage;