import React, { useEffect, useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom';
export const ReportsDataView = () => {
    let { id, userCode, studentCode } = useParams<{ id: string, userCode: string, studentCode: string }>();
    const [userRole, setUserRole] = useState<any>();
    const moduleId = Number(id);
    const usersCode = userCode;
    const studentsCode = studentCode;
    const history = useHistory();

    useEffect(() => {
        const userRole = localStorage.getItem("user_role");
        setUserRole(userRole);
    }, []);
    const handleReportChange = (e: any) => {
        const type = e.target.value;
        if (type === "student") {
            history.push(`/student-dashboard/list/reports/student/${moduleId}/${usersCode}/${studentsCode}`);
        } else if (type === "father") {
            history.push(`/student-dashboard/list/reports/parent/${moduleId}/${usersCode}/${studentsCode}`);
        } else if (type === "mother") {
            history.push(`/student-dashboard/list/reports/family/${moduleId}/${usersCode}/${studentsCode}`);
        }
    }

    const handleBack = () => {
        history.goBack();
    }

    return (
        <div>
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb pb-2 ps-0 pt-0 border-bottom">
                    <li className="breadcrumb-item "><Link to="/student-dashboard/list"><span className='pe-2'>Dashboard</span></Link></li> /
                    <li className="breadcrumb-item "><span>Report</span></li>

                </ol>
            </nav>

            <div className="row  pb-3 pt-2">
                <div className="col-md-3"><h2 className=''>Reports Info</h2></div>
                {moduleId > 0 && <div className="col-md-3">
                    <div className="mb-4">
                        <select name="type" className="form-select form-select-lg mb-3" onChange={(e) => { handleReportChange(e) }}>
                            <option value="">Select </option>
                            <option value="student">Student Report</option>
                            {userRole !== 'STUDENT' && <option value="father">Father Report</option>}
                            {userRole !== 'STUDENT' && <option value="mother">Mother Report</option>}
                        </select>
                    </div>
                </div>}
            </div>
        </div>
    )
}