import React, { useEffect, useRef, useState } from 'react'
import { Link, NavLink, useHistory, useLocation, useParams } from 'react-router-dom'
import { UsersListItem } from '../../../app/model/users/users-list';
import { LOOK_UP_DATA } from '../../../app/service/lookup.service';
import { DELETE_MSB_USER, GET_MSB_USER, GET_MSB_USER_Filter, GET_USER } from '../../../app/service/users.service';
import { DataTable } from '../../../components/data-table'
import { UsersDataGridCols } from './data-grid-cols';
import { ToastContainer, toast } from 'react-toastify';
import FormBuilder from '../../../components/form-builder';
import { GET_DEPARTMENTS } from '../../../app/service/department.service';
import moment from 'moment';

export const UsersList = () => {
    const [usersData, setUsersData] = useState<UsersListItem[]>([]);
    const [loading, setLoading] = useState(false);
    const [pageArray, setPageNumbers] = useState([]);
    const [activePage, setActivePage] = useState<any>();
    const [statesData, setStatesData] = useState<any>([]);
    const [districtData, setDistrictData] = useState<any>([]);
    const [cityData, setCityData] = useState<any>([]);
    const [departmentData, setDepartmentData] = useState<any>([]);
    const [searchData, setSearchData] = useState<any>({});
    const [preparedSearchData, setPreparedSearchData] = useState<any>({});
    const usersForm = useRef<any>({});
    const [accessObjectsList, setAccessObjectsList] = useState<any>([]);
    const [accessNew, setAccessNew] = useState<any>([]);

    const filteredData = [
        { name: 'Name', value: 'name' },
        { name: 'Email ID', value: 'email_id' },
        // { name: 'Department Name', value: 'department_name' },
        { name: 'Role Name', value: 'user_role' },
        { name: 'Region', value: 'region' },
        { name: 'Date of Registration', value: 'date_of_registration' },
        { name: 'Taluka', value: 'taluka' },
        { name: 'Gender', value: 'gender' },
        { name: 'Pan Number', value: 'pan_number' },
        { name: 'Address', value: 'address' },
        { name: 'Blood Group', value: 'blood_group' },
        // { name: 'District', value: 'district' },
        { name: 'Date of Birth', value: 'date_of_birth' },
    ];


    const search = useLocation().search;
    const currentPageNumber: any = new URLSearchParams(search).get('page');

    const history = useHistory();

    useEffect(() => {
        LOOK_UP_DATA().then((res: any) => {
            if (res) {
                setStatesData(res.states);
            }
        });
        getMSBUsers();
        getDepartments();

        const accessObjectsData: any = localStorage.getItem("access_objects_data");
        setAccessObjectsList(JSON.parse(accessObjectsData));
        handleMSBusersCols(JSON.parse(accessObjectsData));
    }, []);

    function getMSBUsers() {
        const activePageNumber = currentPageNumber > 0 ? (currentPageNumber - 1) : 0;
        setLoading(true);
        GET_MSB_USER(activePageNumber, {}).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    setActivePage(currentPageNumber ? currentPageNumber : 1);
                    const data = res.rows;
                    data.forEach((element: any) => {
                        element.created_at = element.created_at.split("T")[0];
                        element.date_of_registration = moment(element.date_of_registration).format('DD-MM-yyyy');
                    });
                    setUsersData(data);
                    const pageNumbers = Math.ceil(res.count / 10);
                    const pageArray: any = [];
                    for (let i = 1; i <= pageNumbers; i++) {
                        pageArray.push(i);
                    }
                    setPageNumbers(pageArray);
                    setLoading(false);
                }
            }
        });
    }

    function getDepartments() {
        GET_DEPARTMENTS().then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    const data = res.rows;
                    setDepartmentData(data);
                }
            }
        });
    }

    const onPageChange = (data: any) => {
        if (data) {
            history.push(`/dashboard/users?page=${data}`);
        }
        setActivePage(data);
        const pageNumber = data - 1;
        setLoading(true);
        GET_MSB_USER(pageNumber, preparedSearchData ? preparedSearchData : {}).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    const data = res.rows;
                    data.forEach((element: any) => {
                        element.created_at = element.created_at.split("T")[0];
                        element.date_of_registration = moment(element.date_of_registration).format('DD-MM-yyyy');
                    });
                    setUsersData(data);
                    setLoading(false);
                }
            }
        });
    }

    const onEditUser = (data: any) => {
        history.push(`/dashboard/users/form/${data.id}`);
    }

    const onDeleteUser = (data: any) => {
        const id = data.user_code;
        if (data.user_name === "superadmin@mail.com") {
            toast.error("Super admin cannot delete");
        } else {
            setLoading(true);
            DELETE_MSB_USER(id).then((res: any) => {
                setLoading(false);
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    setLoading(false);
                    toast.success("User Deleted Successfully");
                    getMSBUsers();
                }
            });
        }

    }

    const handleStateChange = (e: any) => {
        const preparedData = statesData.filter((el: any) => el.state === e.target.value);
        setDistrictData(preparedData[0].districts);

    }

    const handleDistrictChange = (e: any) => {

    }

    const handleFilterChange = (e: any) => {
        const data = { ...searchData.value };
        setSearchData(data);
    }

    const handleInput = (data: any) => {
        setSearchData(data);
    };

    function handleSearch() {
        const data = { ...searchData.value };
        if (data.searchFilter === 'department_name') {
            data.department_name = data.search_name;
        } else if (data.searchFilter === 'name') {
            data.name = data.search_name;
        } else if (data.searchFilter === 'region') {
            data.region = data.search_name;
        } else if (data.searchFilter === 'taluka') {
            data.taluka = data.search_name;
        } else if (data.searchFilter === 'gender') {
            data.gender = data.search_name;
        } else if (data.searchFilter === 'email_id') {
            data.email_id = data.search_name;
        } else if (data.searchFilter === 'date_of_registration') {
            const date_of_registration = data.search_name;
            data.date_of_registration = getPreapareDate(date_of_registration);
        } else if (data.searchFilter === 'pan_number') {
            data.pan_number = data.search_name;
        } else if (data.searchFilter === 'address') {
            data.address = data.search_name;
        } else if (data.searchFilter === 'blood_group') {
            data.blood_group = data.search_name;
        } else if (data.searchFilter === 'date_of_birth') {
            data.date_of_birth = data.search_name;
        }

        else if (data.searchFilter === 'user_role') {
            data.user_role = data.search_name;
        }
        delete data.search_name;
        delete data.searchFilter;
        if (data) {
            setLoading(true);
            for (const key of Object.keys(data)) {
                if (data[key] === "") {
                    delete data[key];
                }
            }
            setPreparedSearchData(data);
            GET_MSB_USER_Filter(data).then((res: any) => {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    history.push(`/dashboard/users?page=${1}`);
                    setActivePage(1);
                    const data = res.rows;
                    data.forEach((element: any) => {
                        element.created_at = element.created_at.split("T")[0];
                        element.date_of_registration = moment(element.date_of_registration).format('DD-MM-yyyy');
                    });
                    setUsersData(data);
                    const pageNumbers = Math.ceil(res.count / 10);
                    const pageArray: any = [];
                    for (let i = 1; i <= pageNumbers; i++) {
                        pageArray.push(i);
                    }
                    setPageNumbers(pageArray);
                    setLoading(false);
                }
            });
        }
    }

    function getPreapareDate(data: any) {
        if (data) {
            const preparedDate = data.split('-');
            const date = preparedDate[0];
            const month = preparedDate[1];
            const year = preparedDate[2];
            return year + '-' + month + '-' + date;
        }
    }

    function handleRefresh() {
        const data = searchData
        data.value = {};
        setSearchData({ ...data });
        setPreparedSearchData({});
        getMSBUsers();
        usersForm.current.reset();
    }
    const handleMSBusersCols = (accessObjectsList: any) => {
        const preparedData = accessObjectsList?.filter((el: any) => el.object_code === "MSB");
        setAccessNew(preparedData);
        if (preparedData?.length > 0) {
            for (let i = 0; i < preparedData[0].child.length; i++) {
                for (let j = 0; j < UsersDataGridCols.length; j++) {
                    if (preparedData[0].child[i].object_code === "MSB-USERS-EDIT" && preparedData[0].child[i].p_read === 1) {
                        if (UsersDataGridCols[j].title == "Actions") {
                            UsersDataGridCols[j].isEdit = true;
                        }
                    }

                    if (preparedData[0].child[i].object_code === "MSB-USERS-DELETE" && preparedData[0].child[i].p_read === 1) {
                        if (UsersDataGridCols[j].title == "Actions") {
                            UsersDataGridCols[j].isDelete = true;
                        }
                    }
                    if (preparedData[0].child[i].object_code === "MSB-USERS-SET-PASSWORD" && preparedData[0].child[i].p_read === 1) {
                        if (UsersDataGridCols[j].title == "Actions") {
                            UsersDataGridCols[j].setPassword = true;
                        }
                    }

                    if ((preparedData[0].child[i].object_code === "MSB-USERS-SET-PASSWORD" && preparedData[0].child[i].p_read === 1) ||
                        (preparedData[0].child[i].object_code === "MSB-USERS-DELETE" && preparedData[0].child[i].p_read === 1) ||
                        (preparedData[0].child[i].object_code === "MSB-USERS-EDIT" && preparedData[0].child[i].p_read === 1)) {
                        if (UsersDataGridCols[j].title == "Actions") {

                            UsersDataGridCols[j].canShowColumn = true;
                        }
                    }
                }
            }
        }

    }

    function onHandleNew() {
        if (accessNew.length > 0) {
            for (let i = 0; i < accessNew[0].child.length; i++) {
                if (accessNew[0].child[i].object_code === "MSB-USERS-NEW" && accessNew[0].child[i].p_read === 1) {
                    return true;
                }
            }
        }
    }

    return (
        <div>

            <div className="row border-top border-primary py-3">
                <div className="row mb-4 mb-md-0">
                    <div className="col-md-10 col-7">
                        <h2 >MSB Users</h2>
                    </div>
                    {onHandleNew() && <div className="border-primary py-md-3 py-1 text-end col-md-2 col-5 ">
                        <Link to="users/form/0" className="btn btn-primary btn-sm px-5 rounded-12">New</Link>
                    </div>}
                </div>
                <div className="row" style={{ marginBottom: '15px' }}>
                    <div className="col-md-9">
                        <FormBuilder onUpdate={handleInput}>
                            <form ref={usersForm}>
                                <div className="row">
                                    <div className='col-md-12'>
                                        <div className='row'>
                                            <div className="col-md-3 mb-3 mb-md-0 ">
                                                <select className="form-select ft-14" name="department_name">
                                                    <option value="">Select department</option>
                                                    {departmentData.map((name: any, i: number) => (
                                                        <option key={i} value={name.department_code}>
                                                            {name.department_name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="col-md-3 mb-3 mb-md-0 ">
                                                <select name="state" className="form-select ft-14" onChange={(e) => { handleStateChange(e) }}>
                                                    <option value="">Select state</option>
                                                    {statesData.map((name: any, i: number) => (
                                                        <option key={i} value={name.state}>
                                                            {name.state}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="col-md-3 mb-3 mb-md-0 ">
                                                <select name="district" className="form-select ft-14" onChange={(e) => { handleDistrictChange(e) }}>
                                                    <option value="">Select district</option>
                                                    {districtData.map((name: any, i: number) => (
                                                        <option key={i} value={name}>
                                                            {name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="col-md-3 ">
                                                <input type="text" className="form-control ft-14" placeholder="Search mobile number" name="contact_number" />
                                            </div>
                                            <div className="col-md-3 mt-md-2 mt-3">
                                                <input type="text" className="form-control ft-14" placeholder="Custom search" name="search_name" />
                                            </div>
                                            <div className="col-md-3 mt-md-2 mt-3">
                                                <select name="searchFilter" className="form-select ft-14" onChange={(e) => { handleFilterChange(e) }}>
                                                    <option value="">Select filter</option>
                                                    {filteredData.map((name: any, i: number) => (
                                                        <option key={i} value={name.value}>
                                                            {name.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </FormBuilder>
                    </div>
                    <div className="col-md-3 mt-md-5 mt-3 text-md-center text-end">
                        <button className="btn btn-primary px-3 cursor-pointer" type="button" onClick={() => handleSearch()}>Search</button>&nbsp;
                        <button className="btn clear-btn cursor-pointer" type="button" onClick={() => handleRefresh()}>
                            Clear
                        </button>
                    </div>
                </div>
                {loading &&
                    <div className="text-center p-5">
                        <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                }
                {!loading && <DataTable TableCols={UsersDataGridCols}
                    editInfo={onEditUser} deleteInfo={onDeleteUser}
                    tableData={usersData} pageNumber={onPageChange}
                    activePageNumber={activePage} pageNumbers={pageArray}></DataTable>
                }
            </div>
        </div>
    )
}
