import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { GET_INDIVIDUAL_BY_USER_CODE } from '../../../app/service/users.service';

export const MNSLevelSuccessPage = () => {
    const [mnsInfo, setMnsInfo] = useState<any>({});
    const [userInfo, setUserInfo] = useState<any>({});
    let { level, mode, moduleUUID, userCode, schoolCode } = useParams<{ level: string, mode: string, moduleUUID: string, userCode: string, schoolCode: string }>();
    const [completedLevel, setCompletedLevel] = useState(level ? level : '');
    const [loading, setLoading] = useState(false);
    const history = useHistory();


    useEffect(() => {
        const data: any = localStorage.getItem('mnsInfo');
        const preparedData = JSON.parse(data);
        setMnsInfo(preparedData);
        getUserDetails();
    }, []);

    function getUserDetails() {
        setLoading(true);
        GET_INDIVIDUAL_BY_USER_CODE(userCode).then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res.message);
                setLoading(false);
            } else {
                if (res) {
                    const data = res.rows;
                    if (data.length > 0) {
                        setUserInfo(data[0]);
                    }
                    setLoading(false);
                }
            }
        });
    }


    function onClickStart() {
        history.push(`/dashboard/mns/questions/list/${moduleUUID}/${userCode}/${schoolCode}`);
    }

    return (
        <div>
            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading && <div className='text-center'>
                {mnsInfo && mnsInfo.result !== 'fail' && <section className="mb-4 mt-4 px-3 px-md-0 text-center">
                    <div className="bg-white rounded-16 p-3 p-lg-5 container">
                        <div className="row align-items-center">
                            <div className="col-md-12 ps-lg-5">
                                <h1 className="fw-bold mb-0">{userInfo.name}</h1>
                                <h1 className="fw-normal m-0">You have</h1>
                                <h1 className="text-green success-text m-0">Sucessfully</h1>
                                <h3 className="mb-0 fw-light">Completed</h3>
                                <p className="f18 fw-light m-0">
                                    <span className='level-test'>{mode && mode === 'assesment_test' ? 'Entry Test' : `Level ${completedLevel}`} </span>
                                    with
                                    <span className='level-score'>
                                        {mnsInfo && mnsInfo ? mnsInfo?.student_score : ''}
                                    </span>score</p>
                                {mnsInfo && mnsInfo && mnsInfo.consecutive_success && Number(mnsInfo && mnsInfo ? mnsInfo.consecutive_success : 0) !== 7 &&
                                    <div style={{ fontWeight: 'normal' }}>in {(Number(mnsInfo && mnsInfo ? mnsInfo.consecutive_success : '0'))} attempt</div>}
                            </div>
                            {mode && mode === 'assesment_test' ? <div>
                                <div>You can now</div>
                                <div>attempt
                                    <div style={{ fontWeight: 'bold' }}> Level {mnsInfo && mnsInfo ? mnsInfo.next_sub_level : ''}</div>
                                </div>
                            </div> :
                                Number(mnsInfo && mnsInfo && mnsInfo.consecutive_success ? mnsInfo.consecutive_success : 0) == 7 && <div>
                                    <div>You can now</div>
                                    <div>attempt
                                        <div> Level {mnsInfo && mnsInfo ? mnsInfo.next_sub_level : ''}</div>
                                    </div>
                                </div>}
                        </div>
                    </div>
                </section>}
                {mnsInfo && mnsInfo && mnsInfo.result === 'fail' && <section className="mb-4 mt-4 px-3 px-md-0 text-center">
                    <div className="bg-white rounded-16 p-3 p-lg-5 container">
                        <div className="row align-items-center">
                            <div className="col-md-12 ps-lg-5">
                                <h1 className="fw-bold mb-0">{userInfo.name}</h1>
                                <h1 className="fw-normal m-0">You have</h1>
                                <h1 className="success-text m-0" style={{ color: 'red' }}>Failed</h1>
                                <p className="mb-0 fw-bold mt-2" style={{ fontSize: '20px' }}>to score 90% in given time for this attempt</p>
                                <p className="mb-0 fw-bold mt-2" style={{ fontSize: '20px' }}>your consecutive count for this test will be reset</p>
                            </div>
                        </div>
                    </div>
                </section>}
                <button className='start-now-btn mt-5' onClick={() => { onClickStart() }}>
                    <span style={{ paddingLeft: '2rem' }}>{mnsInfo && mnsInfo && mnsInfo.result === 'fail' ? 'Test Again' : 'Start Now'}</span>
                    <span style={{ paddingLeft: '4rem' }}>
                        <i className="bi bi-arrow-right"></i>
                    </span>
                </button>
            </div>}
        </div>
    )
}