import React, { useEffect, useState } from 'react'
import { Link, NavLink, useParams } from 'react-router-dom'
import { GET_MODULES_BY_SCHOOL_ID, GET_MODULES_BY_USER_CODE, GET_MODULES_BY_USER_CODES } from '../../../../../app/service/module.service';
import { DataTable } from '../../../../../components/data-table';
import { ModuleDataGridCols } from './data-grid-cols';

export const StudentModulesList = () => {
    let { id, schoolCode, studentId } = useParams<{ id: string, schoolCode: string, studentId: string }>();
    const schoolId = parseInt(id);
    const schoolsCode = schoolCode;
    const studentsCode = studentId;
    const [modulesData, setModulesData] = useState<any[]>([]);
    const [pageArray, setPageNumbers] = useState([]);
    const [activePage, setActivePage] = useState(1);

    useEffect(() => {
        GET_MODULES_BY_USER_CODES(studentsCode).then((res: any) => {
            if (res) {
                const data = res.filter((el: any) => el.module_code !== 'CAS-PARENT');
                for (let i = 0; i < data.length; i++) {
                    data[i].schoolCode = schoolsCode;
                    data[i].amount_status = data[i].amount_status === null ? 'PENDING' : data[i].amount_status;
                }
                setModulesData(data);
            }
        });

    }, []);

    const onPageChange = (data: any) => {
        setActivePage(data);
    }

    return (
        <div>
            <DataTable TableCols={ModuleDataGridCols} tableData={modulesData}
                pageNumber={onPageChange}></DataTable>
        </div>
    )
}
