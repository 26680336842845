import React, { useEffect, useState } from 'react'
import { NavLink, useHistory } from 'react-router-dom';
import "react-datepicker/dist/react-datepicker.css";

interface Props {
    breadcumList: any;
    margin?: string;
}

export const Breadcum: React.FC<Props> = (props) => {
    const [userRole, setUserRole] = useState<any>();

    const history = useHistory();

    useEffect(() => {
        // setActivePage(props.activePageNumber);
        const userRole = localStorage.getItem("user_role");
        setUserRole(userRole);
    }, []);


    return (
        <div>
            <div className="row">
                <div className="col-auto col-md-12">
                    <div className="bc-icons-2 ">
                        <nav aria-label="breadcrumb" className="first">
                            <ol className={`breadcrumb indigo lighten-6 first ${props.margin === "classic" ? "mb-3" : "mb-5"} bg-dark navbar-light  px-md-4`}>
                                {props.breadcumList.map((item: any, i: number) => (
                                    <li key={i} className={`${item.isActive ? `breadcum-active-label  ${item?.nameHighlight && "fw_bold"}` : 'breadcrumb-item font-weight-bold font-italic'}`}>
                                        < NavLink to={item.isActive ? '' : item.routerLink} className={`${item.isActive ? 'black-text text-uppercase active-1 disabled-link' : 'black-text text-uppercase'}`}>
                                            <span className={`${item.isActive ? 'breadcum-label' : 'breadcum-label mr-md-3 mr-2'}`}>{item.value} </span>{item?.subName && <span className='font-weight-bold f10 text-black ms-1'>{item?.subName}</span>}
                                        </NavLink>
                                        <i className={`${item.isActive ? '' : 'bi bi-chevron-right breadcum-arrow'}`}></i>
                                    </li>
                                ))}
                            </ol>
                        </nav>
                    </div>
                </div>
            </div >
        </div >
    )
}
