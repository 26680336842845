import React from "react";

import { NavLink } from 'react-router-dom';
import CouponReportRoutes from "./routes";

const CouponReportPage = (props: any) => {

    return (
        <div>
            <CouponReportRoutes {...props} />
        </div>
    );
};

export default CouponReportPage;