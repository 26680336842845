import React, { useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { SponsorReportListCols } from "./data-grid-cols";
import { DataTable } from "../../../../components/data-table";
import moment from "moment";
import { toast } from "react-toastify";
import { FECTH_FILTER_SPONSORS_REPORT_LIST, FECTH_SPONSORS_REPORT_LIST } from "../../../../app/service/sponsor";
import FormBuilder from "../../../../components/form-builder";
import VIEWICONS from '../../../../institute-dashboard-assets/images/view-icons.svg'


export const SponsorReportList = () => {
    const [sponsorList, setSponsorList] = useState<any>([]);
    const [loading, setLoading] = useState(false);
    const [activePage, setActivePage] = useState<any>();
    const history = useHistory();
    const [pageArray, setPageNumbers] = useState([]);
    const search = useLocation().search;
    const currentPageNumber: any = new URLSearchParams(search).get('page');
    const sponserForm = useRef<any>({});
    const [searchData, setSearchData] = useState<any>({});
    const [fromDate, setFromDate] = useState<any>("");
    const [toDate, setToDate] = useState<any>("");
    const [paymentStatus, setPaymentStatus] = useState<string>("");
    const [studentDetails, setStudentDetails] = useState<any>({})
    const [canShowPopupData, setCanShowPopupData] = useState<boolean>(false);
    const [viewStudentsData, setViewStudentsData] = useState<any>({})
    const [showStudentPoup, setShowStudentPoup] = useState<boolean>(false);
    const [sponsorName, setSponsorName] = useState<string>("")
    useEffect(() => {
        getSponsorReporList();
    }, []);

    function getSponsorReporList() {
        const activePageNumber = currentPageNumber > 0 ? (currentPageNumber - 1) : 0;
        setLoading(true);
        FECTH_SPONSORS_REPORT_LIST(activePageNumber).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    const data = res.rows;
                    data.forEach((element: any) => {
                        if (element.payment_date) {
                            element.payment_date = moment(element.payment_date.split("T")[0]).format('DD-MM-yyyy');
                        }
                    });
                    setSponsorList(data);
                    const pageNumbers = Math.ceil(res.count / 10);
                    const pageArray: any = [];
                    for (let i = 1; i <= pageNumbers; i++) {
                        pageArray.push(i);
                    }
                    setPageNumbers(pageArray);
                    setLoading(false);
                }
            }
        });
    }

    const onPageChange = (data: any) => {
        if (data) {
            history.push(`/dashboard/sponsor-report/list?page=${data}`);
        }
        setActivePage(data);
        const pageNumber = data - 1;
        setLoading(true);
        FECTH_SPONSORS_REPORT_LIST(pageNumber).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    const data = res.rows;
                    data.forEach((element: any) => {
                        if (element.payment_date) {
                            element.payment_date = moment(element.payment_date.split("T")[0]).format('DD-MM-yyyy');
                        }
                    });
                    setSponsorList(data);
                    const pageNumbers = Math.ceil(res.count / 10);
                    const pageArray: any = [];
                    for (let i = 1; i <= pageNumbers; i++) {
                        pageArray.push(i);
                    }
                    setPageNumbers(pageArray);
                    setLoading(false);
                }
            }
        });

    }
    const handleInput = (data: any) => {
        setSearchData(data);
    };
    function handleSearch() {
        const data = { ...searchData.value };
        const activePageNumber = currentPageNumber > 0 ? (currentPageNumber - 1) : 0;
        const payload = {
            payment_status: paymentStatus,
            payment_dt_from: fromDate ? `${fromDate} 00:00:00` : "",
            payment_dt_to: toDate ? `${toDate} 23:59:59` : "",
            search: sponsorName
        }
        if (paymentStatus || fromDate || toDate || sponsorName) {
            // for (const key of Object.keys(data)) {
            //     if (data[key] === "") {
            //         delete data[key];
            //     }
            // }
            setLoading(true);
            FECTH_FILTER_SPONSORS_REPORT_LIST(activePageNumber, payload).then((res: any) => {
                if (res) {
                    if (res.status === 'fail') {
                        toast.error(res.message);
                        setLoading(false);
                    } else {
                        const data = res.rows;
                        data.forEach((element: any) => {
                            if (element.payment_date) {
                                element.payment_date = moment(element.payment_date.split("T")[0]).format('DD-MM-yyyy');
                            }
                        });
                        setSponsorList(data);
                        const pageNumbers = Math.ceil(res.count / 10);
                        const pageArray: any = [];
                        for (let i = 1; i <= pageNumbers; i++) {
                            pageArray.push(i);
                        }
                        setPageNumbers(pageArray);
                        setLoading(false);
                    }
                }
            });
        }
    }
    function handleRefresh() {
        getSponsorReporList();
        setPaymentStatus("");
        setFromDate("");
        setToDate("");
    }
    function getStudentDetails(data: any) {
        setStudentDetails(data);
        setCanShowPopupData(true);
    }
    function handleStudentClose() {
        setCanShowPopupData(false);
    }
    function openStudentsReport(data: any) {
        setCanShowPopupData(false);
        setShowStudentPoup(true)
        setViewStudentsData(data);
    }
    function handleStudentPopup() {
        setShowStudentPoup(false);
        setCanShowPopupData(true);
    }
    return (
        <div>
            <div
                className={`modal fade ${showStudentPoup ? "show" : "false"}`}
                style={{ display: showStudentPoup ? "block" : "none" }}
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog parent-model-dialog" role="document">
                    <div className="modal-content parent-model">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                                Student Details
                            </h5>
                            <button
                                type="button"
                                className="close popup-close cursor-pointer"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={handleStudentPopup}
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body parent-info parent-model">
                            <h6> Institute Name : - <span className="f18">{viewStudentsData?._organization?.name_of_organization} </span></h6>
                            <h6 className="mb-2">School Name : - <span className="f18">{viewStudentsData?._school?.name_of_school}</span></h6>
                            <div className={`table-responsive mar-t-20 custom-scroll ${viewStudentsData && (viewStudentsData?.students?.length > 10 && "tableFixHead")}`}>
                                <table className='table table  fixed-table-header mb-0'>
                                    <thead>
                                        <tr>
                                            <th style={{ width: "50px" }}>S.No</th>
                                            <th style={{ width: '150px' }}>Student Name</th>
                                            <th style={{ width: '100px' }}> Standard</th>
                                            <th style={{ width: '100px' }}> division</th>
                                            <th style={{ width: '100px' }}> Gender</th>
                                            <th style={{ width: '90px' }}> Email Id</th>
                                            <th style={{ width: '100px' }}> Mobile Number</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {viewStudentsData?.students?.map((data: any, i: number) => {
                                            return (
                                                <tr key={i}>
                                                    <td>{i + 1}</td>
                                                    <td> {data?.name}</td>
                                                    <td>{data?.standard}</td>
                                                    <td>{data?.division}</td>
                                                    <td> {data?.gender}</td>
                                                    <td>{data?.email_id}</td>
                                                    <td>{data?.mobile_number}</td>

                                                </tr>
                                            )
                                        })}

                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary cursor-pointer"
                                data-dismiss="modal"
                                onClick={handleStudentPopup}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className={`modal fade ${canShowPopupData ? "show" : "false"}`}
                style={{ display: canShowPopupData ? "block" : "none" }}
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog parent-model-dialog" role="document">
                    <div className="modal-content parent-model">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                                Sponsorship Details
                            </h5>
                            <button
                                type="button"
                                className="close popup-close cursor-pointer"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={handleStudentClose}
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body parent-info parent-model">
                            <h6> Sponsor Name : - <span className="f18">{studentDetails?.sponsor_name} </span></h6>
                            <h6 className="mb-2">Sponsorship Name : - <span className="f18">{studentDetails?.sponsorship_name}</span></h6>
                            <div className={`table-responsive mar-t-20 custom-scroll ${studentDetails && (studentDetails?.students?.length > 10 && "tableFixHead")}`}>
                                <table className='table table  fixed-table-header mb-0'>
                                    <thead>
                                        <tr>
                                            <th style={{ width: "50px" }}>S.No</th>
                                            <th style={{ width: '150px' }}>Institute Name</th>
                                            <th style={{ width: '150px' }}> School Name</th>
                                            <th style={{ width: '90px' }}> Students</th>
                                            <th style={{ width: '100px' }}> Subscription Price</th>
                                            {/* <th style={{ width: '100px' }}> Paid Amount</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {studentDetails?.students?.map((data: any, i: number) => {
                                            return (
                                                <tr key={i}>
                                                    <td>{i + 1}</td>
                                                    <td> {data?._organization?.name_of_organization}</td>
                                                    <td> {data?._school?.name_of_school}</td>
                                                    <td>{data?.students.length > 0 && <img className='eye-icons ms-1 cursor-pointer me-2' src={VIEWICONS} onClick={() => openStudentsReport(data)} />} <span>{data?.students.length}</span></td>
                                                    <td>{data?.subscription_price}</td>
                                                </tr>
                                            )
                                        })}

                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary cursor-pointer"
                                data-dismiss="modal"
                                onClick={handleStudentClose}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="my-4">

                <div className="row  my-3 my-md-0  ">
                    <div className="col-md-3">
                        <div className="mt-3">
                            <label className="form-label mb-0">Sponsor Name</label>
                            <input type="text" id="name" className="form-control" placeholder="Sponsor name" name="sponsor_name" onChange={(e) => setSponsorName(e.target.value)} value={sponsorName} />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="mt-3">
                            <label className="form-label mb-0">Payment Status</label>
                            <select className="form-select form-select-lg f14" name="payment_status" onChange={(e) => setPaymentStatus(e.target.value)} value={paymentStatus} placeholder="Please select payment status" >
                                <option value="">Select the payment status</option>
                                <option value="completed">Completed</option>
                                <option value="pending">Pending</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-md-3 ">
                        <label className="form-label mb-0 mt-3">Payment From Date</label>
                        <input type="date" className='form-control' name="expiry_dt" value={fromDate} onChange={(e) => setFromDate(e.target.value)} onKeyDown={(event) => {
                            event.preventDefault();
                        }} />
                    </div>
                    <div className="col-md-3">
                        <label className="form-label mb-0 mt-3">Payment To Date</label>
                        <input type="date" className='form-control' name="expiry_dt" value={toDate} onChange={(e) => setToDate(e.target.value)} onKeyDown={(event) => {
                            event.preventDefault();
                        }} />
                    </div>

                </div>
                <div className=" text-end">
                    <button className="btn btn-primary px-3 cursor-pointer mt-3" type="button" onClick={() => handleSearch()}>Search</button>&nbsp;
                    <button className="btn clear-btn cursor-pointer mt-3" type="button" onClick={() => handleRefresh()}>
                        Clear
                    </button>
                </div>

            </div>


            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading && <DataTable TableCols={SponsorReportListCols} tableData={sponsorList} onChange={getStudentDetails}
                activePageNumber={activePage} pageNumber={onPageChange} pageNumbers={pageArray}></DataTable>}
        </div>
    )
}