import React, { useEffect, useState } from 'react'
import { Link, useHistory, useLocation, useParams } from 'react-router-dom'
import { DELETE_USER, GET_USER_BY_SCHOOL_CODE } from '../../../../app/service/users.service';
import { DataTable } from '../../../../components/data-table'
import { GeneralSecretaryDataGridCols } from './data-grid-cols';
import { ToastContainer, toast } from 'react-toastify';

export const GeneralSecretaryList = () => {
    let { id, instituteCode } = useParams<{ id: string, instituteCode: string }>();
    const instituteId = parseInt(id);
    const institutesCode = instituteCode;
    const [pageArray, setPageNumbers] = useState([]);
    const [activePage, setActivePage] = useState<any>();
    const [generalSecretaryList, setGeneralSecretaryList] = useState<any>([]);
    const [loading, setLoading] = useState(false);
    const search = useLocation().search;
    const currentPageNumber: any = new URLSearchParams(search).get('page');
    const history = useHistory();
    const [accessObjectsList, setAccessObjectsList] = useState<any>([]);
    const [generalSecratary, setGeneralSecratary] = useState(false);
    const [apiLoaded, setApiLoaded] = useState<any>(false);
    const userRole = localStorage.getItem("user_role");


    useEffect(() => {
        getGeneralSecreatary();
        const accessObjectsData: any = localStorage.getItem("access_objects_data");
        setAccessObjectsList(JSON.parse(accessObjectsData));
        handleGeneralSecrataryCols(JSON.parse(accessObjectsData));
    }, []);

    function getGeneralSecreatary() {
        const activePageNumber = currentPageNumber > 0 ? (currentPageNumber - 1) : 0;
        if (institutesCode) {
            setLoading(true);
            GET_USER_BY_SCHOOL_CODE(institutesCode, 'GENERAL_SECRETARY', activePageNumber, {}).then((res: any) => {
                setApiLoaded(true);
                if (res) {
                    if (res.status === 'fail') {
                        toast.error(res.message);
                        setLoading(false);
                    } else {
                        setActivePage(currentPageNumber ? currentPageNumber : 1);
                        const data = res.rows;
                        data.forEach((element: any) => {
                            element.date_of_birth = element.date_of_birth.split(" ")[0];
                        });
                        setGeneralSecretaryList(data);
                        const pageNumbers = Math.ceil(res.count / 10);
                        const pageArray: any = [];
                        for (let i = 1; i <= pageNumbers; i++) {
                            pageArray.push(i);
                        }
                        setPageNumbers(pageArray);
                        setLoading(false);
                    }
                }
            });
        }
    }

    const onPageChange = (data: any) => {
        setActivePage(data);
    }

    const onEditGeneralSecretary = (data: any) => {
        history.push(`/dashboard/institute/info/${instituteId}/generalsecretaryform/${institutesCode}/${data.id}`);
        if (userRole === "CHAIRMAN" || userRole === "GENERAL_SECRETARY" || userRole === "INSTITUTE_ADMIN") {
            history.push(`/institute-dashboard/institute/info/${instituteId}/generalsecretaryform/${institutesCode}/${data.id}`);

        }
    }

    const onDeleteGeneralSecretary = (data: any) => {
        setLoading(true);
        const id = data.user_code;
        DELETE_USER(id).then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res.message);
                setLoading(false);
            } else {
                setLoading(false);
                toast.success("General Secreatry Deleted Successfully");
                getGeneralSecreatary();
            }
        });
    }

    const handleGeneralSecrataryCols = (accessObjectsList: any) => {
        const preparedData = accessObjectsList?.filter((el: any) => el.object_code === "INSTITUTES");
        if (preparedData?.length > 0) {
            for (let i = 0; i < preparedData[0].child.length; i++) {
                for (let j = 0; j < GeneralSecretaryDataGridCols.length; j++) {
                    if (preparedData[0].child[i].object_code === "INSTITUTES-GENERAL-SECRETARY" && preparedData[0].child[i].p_read === 1) {
                        setGeneralSecratary(true);
                    }

                    if (preparedData[0].child[i].object_code === "INSTITUTES-GENERAL-SECRETARY-EDIT" && preparedData[0].child[i].p_read === 1) {
                        if (GeneralSecretaryDataGridCols[j].title == "Actions") {
                            GeneralSecretaryDataGridCols[j].isEdit = true;
                        }
                    }
                    if (preparedData[0].child[i].object_code === "INSTITUTES-GENERAL-SECRETARY-DELETE" && preparedData[0].child[i].p_read === 1) {
                        if (GeneralSecretaryDataGridCols[j].title == "Actions") {
                            GeneralSecretaryDataGridCols[j].isDelete = true;
                        }
                    }

                    if (preparedData[0].child[i].object_code === "INSTITUTES-GENERAL-SECRETARY-SET-PASSWORD" && preparedData[0].child[i].p_read === 1) {
                        if (GeneralSecretaryDataGridCols[j].title == "Actions") {
                            GeneralSecretaryDataGridCols[j].setPassword = true;
                        }
                    }
                    if ((preparedData[0].child[i].object_code === "INSTITUTES-GENERAL-SECRETARY-EDIT" && preparedData[0].child[i].p_read === 1) ||
                        (preparedData[0].child[i].object_code === "INSTITUTES-GENERAL-SECRETARY-SET-PASSWORD" && preparedData[0].child[i].p_read === 1) || (preparedData[0].child[i].object_code === "INSTITUTES-GENERAL-SECRETARY-DELETE" && preparedData[0].child[i].p_read === 1)) {
                        if (GeneralSecretaryDataGridCols[j].title == "Actions") {
                            GeneralSecretaryDataGridCols[j].canShowColumn = true;
                        }
                    }

                }
            }
        }
    }

    return (
        <div>

            {generalSecratary &&
                <>
                    {(generalSecretaryList.length === 0 && apiLoaded) && <div className="border-primary py-3 text-end">
                        {userRole !== "CHAIRMAN" && userRole !== "GENERAL_SECRETARY" && userRole !== "INSTITUTE_ADMIN" && <Link to={`/dashboard/institute/info/${instituteId}/generalsecretaryform/${institutesCode}/0`} className="btn btn-primary btn-sm px-5 rounded-12">New</Link>}

                        {(userRole === "CHAIRMAN" || userRole === "GENERAL_SECRETARY" || userRole === "INSTITUTE_ADMIN") && <Link to={`/institute-dashboard/institute/info/${instituteId}/generalsecretaryform/${institutesCode}/0`} className="btn btn-primary btn-sm px-5 rounded-12">New</Link>}
                    </div>}
                    {loading &&
                        <div className="text-center p-5">
                            <div className="spinner-border" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                    }
                    {!loading &&
                        <DataTable TableCols={GeneralSecretaryDataGridCols} tableData={generalSecretaryList}
                            editInfo={onEditGeneralSecretary}
                            deleteInfo={onDeleteGeneralSecretary}
                            activePageNumber={activePage} pageNumber={onPageChange} pageNumbers={pageArray}></DataTable>}
                </>
            }
        </div>
    )
}