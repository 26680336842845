import React from "react";
import { Switch, Route, Redirect } from "react-router";
import { PromotionsReportList } from "./list";
import { CouponReportList } from "./copon-code/list";


interface Props {
    match?: any;
}
const CouponReportRoutes = (props: Props) => {
    let url: string | undefined = props.match?.url;
    if (url?.endsWith('/')) {
        url = url.substr(0, url.length - 1);
    }

    return (
        <Switch>
            <Route exact path={`${url}/list`}>
                <PromotionsReportList></PromotionsReportList>
            </Route>
            <Route exact path={`${url}/coupon-code/:uuId`}>
                <CouponReportList></CouponReportList>
            </Route>

        </Switch>
    );
}
export default CouponReportRoutes;