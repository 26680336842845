import { Switch, Route, Redirect } from "react-router";
// import PromotionView from "./view";
import { PromotionForm } from "./form";
import PromoCodeList from "./promocode/list";
import { PromoCodeForm } from "./promocode/form";
import PromotionList from "./list";



interface Props {
    match?: any;
}
const PromotionRoutes = (props: Props) => {
    let url: string | undefined = props.match?.url;
    if (url?.endsWith('/')) {
        url = url.substr(0, url.length - 1);
    }

    return (
        <Switch>

            <Route path={`${url}/list`} exact>
                <PromotionList></PromotionList>
            </Route>
            <Route path={`${url}/form/:id`} exact>
                <PromotionForm></PromotionForm>
            </Route>
            <Route path={`${url}/promocode/list/:Uuid`} exact>
                <PromoCodeList></PromoCodeList>
            </Route>
            <Route path={`${url}/promocode/form/:promotionUuid/:Uuid`} exact>
                <PromoCodeForm></PromoCodeForm>
            </Route>
        </Switch>
    );
}
export default PromotionRoutes;