import React, { useEffect, useRef, useState } from "react";
import FormBuilder from "../../../components/form-builder";
import { GET_STUDENTS_BY_STANDARD_DIVISION } from "../../../app/service/users.service";
import { toast } from "react-toastify";
import { useHistory, useParams } from "react-router-dom";
import { MultiSelect } from "react-multi-select-component";
import { Breadcum } from "../../../components/breadcum";
import { GET_SCHOOL_BY_ID, GET_STANDARD_DIVISION_BY_SCHOOL_CODE } from "../../../app/service/school.service";
import { CREATE_PAYMENT, CREATE_PAYMENT_DETAILS_SCHOOL_MODULE_CODE } from "../../../app/service/payment-service";
import { GET_MODULE_BY_UUID, UPDATE_ASSIGN_USER } from "../../../app/service/module.service";
import { ADD_UPDATE_SUBSCRIPTION_PRICE } from "../../../app/service/subscription.service";
import { CREATE_MNS_USER_ASSIGN } from "../../../app/service/mns-service";
import { APPLY_COUPON_CODE, FECTH_PROMOCODES_BY_PROMOTIONS_FILTERS, UPDATE_APPLIED_COUPON_CODE, UPDATE_PROMO_CODES } from "../../../app/service/promotions";
import { UI_BASE_URL } from "../../../config/constant";
import { CREATE_CCAVENUE_PAYMENT } from "../../../app/service/ccavenue.service";
import moment from "moment";

export const StudentPayments: React.FC = () => {
    const assignUserForm = useRef<any>({});
    const [studentLoading, setStudentLoading] = useState(false);
    const [couponLoading, setCouponLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [assignUserData, setAssignUserData] = useState<any>({});
    const [standardList, setStandardList] = useState<any>([]);
    const [divisionList, setDivisionList] = useState<any>([]);
    const [studentList, setStudentList] = useState<any[]>([]);
    const [optionStudentsLoading, setOptionStudentsLoading] = useState(false);
    const [studentsSelected, setStudentsSelected] = useState<any>([]);
    const [moduleData, setModuleData] = useState<any>({});
    const [paymentData, setPaymentData] = useState<any>({});
    const [couponData, setCouponData] = useState<any>({});
    const [showCouponDiv, setShowCouponDiv] = useState<boolean>(false);
    let instituteCode: any = "";
    const [insCode, SetInsCode] = useState<any>("");
    const [selectedUsers, setSelectedUsers] = useState<any[]>([]);
    const [transactionId, SettransactionId] = useState<any>("");
    const loginUserCode = localStorage.getItem("user_code");
    const [couponCodeValue, setCouponCodeValue] = useState<any>("");
    const [todayDate, setTodayDate] = useState(moment().format('YYYY-MM-DD'));
    const [schoolInfo, setSchoolInfo] = useState<any>({});
    let { id, schoolCode, moduleUuid } = useParams<{ id: string, schoolCode: string, moduleUuid: string }>();
    const schoolId = Number(id);
    const schoolsCode = schoolCode;
    const history = useHistory();
    const [breadcumInfo, setBreadcumInfo] = useState<any>([
        {
            label: "Schools",
            value: "Schools",
            routerLink: "/dashboard/school/list",
            isActive: false,
        },
        {
            label: "School Name",
            value: "",
            routerLink: `/dashboard/school/info/${schoolId}/paymentlist/${schoolCode}`,
            isActive: false,
        },
        {
            label: "Module Name",
            value: moduleData?.module_code,
            routerLink: "",
            subName: "(Payment)",
            isActive: true,
        },
    ]);

    useEffect(() => {
        // ccvenue//
        const splitLocation: any = window.location.href.split("?");
        const queryString = splitLocation[1];
        if (queryString) {
            const match = queryString.match(/status=([^&]*)/);
            if (match) {
                const statusValue = match[1];
                const preparedSubscriptionData = JSON.parse(localStorage.getItem('cc_subscription_data') || '{}');
                const txnId = localStorage.getItem("cc_transaction_id");
                const paymentInfo = JSON.parse(localStorage.getItem('cc_payment_info') || '{}');
                const couponsData = JSON.parse(localStorage.getItem("cc_coupons_data") || '{}');
                const selectedUsers = JSON.parse(localStorage.getItem('cc_selected_users') || '{}');
                if (txnId && statusValue === "success") {
                    updateSubscription(txnId, "", preparedSubscriptionData, statusValue, paymentInfo, moduleUuid, selectedUsers, couponsData);
                } else {
                    removeItemsFromeLocalStorage();
                }

            } else {
            }
        }
        getStudentsByStandardDivision();
        getModuleInfo();
        getSchoolInfo();

    }, []);

    const getSchoolInfo = () => {
        setLoading(true);
        GET_SCHOOL_BY_ID(schoolId).then((res: any) => {
            if (res.status === "fail") {
                toast.error(res.message);
                setLoading(false);
            } else {
                if (res) {
                    const data = res.rows[0];
                    setSchoolInfo(data);
                    instituteCode = data?.organization_code;
                    SetInsCode(data?.organization_code);
                    breadcumInfo[1].value = data?.name_of_school;
                    getPaymentDetails();
                }
            }
        });
    };
    const getPaymentDetails = (data?: any) => {

        const payLoad = {
            "institute_school_code": schoolsCode,
            "standard": data?.standard ? data?.standard : "",
            "division": data?.division ? data?.division : "",
            "students": data?.students ? data?.students : [],
            "module_uuid": moduleUuid
        }
        setShowCouponDiv(false);
        CREATE_PAYMENT_DETAILS_SCHOOL_MODULE_CODE(payLoad).then((res: any) => {
            if (res.status === "fail") {
                toast.error(res?.message);
                setLoading(false);
                setStudentLoading(false);
            } else {
                if (res) {
                    setPaymentData(res);
                    let data = res?.students;
                    localStorage.setItem("cc_subscription_data", JSON.stringify(data));
                    let preparedData = [];
                    const userCodes = [];
                    for (let k = 0; k < data?.length; k++) {
                        preparedData.push({
                            label: data[k].name,
                            value: data[k].user_code
                        });
                        userCodes.push(data[k].user_code);
                    }
                    // let preUser = userCodes?.slice(0, 500);
                    setSelectedUsers(preparedData);
                    localStorage.setItem("cc_selected_users", JSON.stringify(preparedData));
                    if (payLoad?.students?.length == 0) {
                        setStudentList(preparedData);
                    }
                    if (res?.total_amount > 0) {
                        let preElement = {
                            students_count: res?.total_students,
                            total_amount: res?.total_amount,
                        }
                        getPromoCodes(preElement);
                    } else {
                        setLoading(false);
                    }
                    setStudentLoading(false);
                    // setLoading(false);
                }
            }
        });
    }
    const getModuleInfo = () => {
        GET_MODULE_BY_UUID(moduleUuid).then((res: any) => {
            if (res.status === "fail") {
                toast.error(res?.error);
            } else {
                if (res) {
                    const data = res?.rows[0];
                    localStorage.setItem("cc_payment_info", JSON.stringify(data));
                    setModuleData(data);
                    // getBreadcumList(data);
                    breadcumInfo[2].value = data?.module_code;
                }
            }
        });
    }
    const getStudentsByStandardDivision = () => {
        GET_STANDARD_DIVISION_BY_SCHOOL_CODE(schoolsCode).then((res: any) => {
            if (res.status === "fail") {
                toast.error(res?.err);
                setLoading(false);
            } else {
                if (res) {
                    let data = res;
                    if (Array.isArray(data)) {
                        const { classes, sections } = data.reduce(removeDuplicatesList, { classes: [], sections: [] });
                        sections?.sort((a: string, b: string) => a > b ? 1 : -1);
                        setStandardList(classes);
                        setDivisionList(sections);
                        // data?.forEach((item) => {
                        //     if (!standardList.includes(item?.class)) {
                        //         standardList.push(item.class);
                        //     }
                        //     if (!divisionList.includes(item?.section)) {
                        //         divisionList.push(item.section);
                        //     }
                        // });
                    }
                }
            }
        });
    }

    const removeDuplicatesList = (accumulator: any, currentValue: any) => {
        if (!accumulator.classes.includes(currentValue.class)) {
            accumulator.classes.push(currentValue.class);
        }
        if (!accumulator.sections.includes(currentValue.section)) {
            accumulator.sections.push(currentValue.section);
        }
        return accumulator;
    };
    const getPromoCodes = (payMentInfo: any) => {
        const payLoad = {
            promotion_type: "IAS",
            expand: "promocodes"
        }
        FECTH_PROMOCODES_BY_PROMOTIONS_FILTERS(0, payLoad).then((res: any) => {
            if (res.status === "fail") {
                toast.error(res?.error);
                setStudentLoading(false);
                setLoading(false);
            } else {
                if (res) {
                    const data = res?.rows[0]?._promocodes[0];
                    if (data?.promo_code) {
                        let preparedData = {
                            coupon_code: data?.promo_code,
                            students_count: payMentInfo?.students_count,
                            total_amount: payMentInfo?.total_amount,
                        }
                        applyCoupon(preparedData);
                    } else {
                        setLoading(false);
                    }
                }
            }
        });
    }

    const handleInput = (data: any) => {
        setAssignUserData(data);

    }

    const handleStandardChange = (e: any) => {
        setStudentsSelected([]);
        const data = { ...assignUserData.value };
        data.standard = e.target.value;
        let preparedData = {
            standard: data.standard,
            division: data.division,
        }
        setStudentLoading(true);
        getPaymentDetails(preparedData);

    }

    const handleDivisionChange = (e: any) => {
        setStudentsSelected([]);
        const data = { ...assignUserData.value };
        data.division = e.target.value;
        let preparedData = {
            standard: data.standard,
            division: data.division,
        }
        setStudentLoading(true);
        getPaymentDetails(preparedData);


    }
    const onSelectedStudent = (e: any) => {
        if (e) {

            setStudentsSelected(e);
            localStorage.setItem("cc_selected_users", JSON.stringify(e));
            const data = { ...assignUserData.value };
            const selectUserCodes = [];
            const userCodes = [];
            for (let i = 0; i < e?.length; i++) {
                userCodes.push({ user_code: `${e[i]?.value}` });
                selectUserCodes.push(`${e[i]?.value}`);
            }

            let preparedData = {
                standard: data.standard,
                division: data.division,
                students: userCodes
            }

            setStudentLoading(true);
            getPaymentDetails(preparedData);
        }
    }


    function handleRefresh() {
        const userRole = localStorage.getItem("user_role");
        const institutecCode = localStorage.getItem("institute_code");
        const ftmSchoolCodes = localStorage.getItem("FTM_school_codes");
        const data = assignUserData;
        data.value = {};
        setStudentsSelected([]);
        setAssignUserData({ ...data });
        assignUserForm.current.reset();
        setStudentLoading(true);
        getPaymentDetails();
    }

    const handlePayment = () => {
        // if (selectedUsers.length > 500) {
        //     const selectedUsersCount = selectedUsers.length;
        //     const preparedCount = Math.ceil(selectedUsersCount / 500);

        //     for (let i = 0; i < preparedCount; i++) {
        //         const startIndex = i * 500;
        //         const endIndex = Math.min((i + 1) * 500, selectedUsersCount);
        //         const slicedUsers = selectedUsers.slice(startIndex, endIndex);

        //         createPayment(moduleData, paymentData, slicedUsers);
        //     }
        // } else {
        //     createPayment(moduleData, paymentData, selectedUsers);
        // }

        createPayment(moduleData, paymentData);

    }
    function createPayment(data: any, subscriptionData: any) {
        let subscriptionAmount = paymentData?.total_amount;
        const payload = {
            school_code: schoolsCode,
            // user_ids: selectedUsers,
            user_ids: [],
            sub_amount: couponData?.final_amount ? Math.round(couponData?.final_amount) : subscriptionAmount
        }

        if (subscriptionAmount > 0) {
            CREATE_PAYMENT(payload).then((res: any) => {
                if (res) {
                    if (res.status === 'fail') {
                        toast.error(res.message);
                    } else {
                        if (couponData?.final_amount == 0 && Object.keys(couponData?.rule_applied).length > 0) {
                            // console.log(studentList, "studentList122");
                            if (selectedUsers.length > 500) {
                                const selectedUsersCount = selectedUsers.length;
                                const preparedCount = Math.ceil(selectedUsersCount / 500);
                                for (let i = 0; i < preparedCount; i++) {
                                    const startIndex = i * 500;
                                    const endIndex = Math.min((i + 1) * 500, selectedUsersCount);
                                    const slicedUsers = selectedUsers.slice(startIndex, endIndex);
                                    const lastIndexOfLoop = preparedCount - 1;
                                    assignModuleStudents(moduleData, slicedUsers, lastIndexOfLoop, "more");
                                }
                            } else {
                                assignModuleStudents(moduleData, selectedUsers, "", "less");

                            }

                            // assignModuleStudents(moduleData, selectedUsers);
                            // updateCouponCode(res.txn_id, couponData);
                            updateAppliedCoupon(res.txn_id, couponData);
                        }
                        else {
                            SettransactionId(res.txn_id);
                            localStorage.setItem("cc_transaction_id", res?.txn_id)
                            onOpenPaymentInfo(payload.sub_amount, res.txn_id, data, subscriptionData);
                        }

                    }
                }
            });
        } else {
            // console.log(studentList, "studentList");
            if (selectedUsers.length > 500) {
                const selectedUsersCount = selectedUsers.length;
                const preparedCount = Math.ceil(selectedUsersCount / 500);
                for (let i = 0; i < preparedCount; i++) {
                    const startIndex = i * 500;
                    const endIndex = Math.min((i + 1) * 500, selectedUsersCount);
                    const slicedUsers = selectedUsers.slice(startIndex, endIndex);
                    const lastIndexOfLoop = preparedCount - 1;
                    assignModuleStudents(moduleData, slicedUsers, lastIndexOfLoop, "more");
                }
            } else {
                assignModuleStudents(moduleData, selectedUsers, "", "less");
            }

            // assignModuleStudents(moduleData, selectedUsers);
        }
    }
    function onOpenPaymentInfo(amount: any, txn_id: string, data: any, subscriptionData: any) {
        if (amount > 0) {

            createCcavenuePayment(amount, txn_id);

        } else {
            if (amount > 0) {
                updateSubscription(txn_id, { "message": "SUM AMOUNT ZERO" }, subscriptionData, "success");
            }
        }
    }
    const createCcavenuePayment = (amount: any, txn_id: string) => {
        let payLoad = {
            amount: amount,
            currency: "INR",
            transaction_uuid: txn_id,
            redirect_url: `${UI_BASE_URL}/dashboard/school/${id}/${schoolsCode}/${moduleUuid}/payment/list`,
            cancel_url: `${UI_BASE_URL}/dashboard/school/${id}/${schoolsCode}/${moduleUuid}/payment/list`,

        }
        CREATE_CCAVENUE_PAYMENT(payLoad).then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res?.error);
            } else {
                // return
                initiatePayment(res);

            }
        });
    }
    const initiatePayment = async (paymentData: any) => {
        window.location.href = paymentData.url;
    };
    function updateSubscription(transactionId: any, transactionData: any, subscriptionData: any, paymentStatus: any,
        paymentInfo?: any, moduleUuid?: any, selectedUsers?: any, couponData?: any) {
        let subscriptionStatus = subscriptionData?.filter((ele: any) => ele?.subscription_payment_status === "pending");
        if (subscriptionStatus?.length > 0) {
            const preparedData = [];
            for (let i = 0; i < subscriptionData.length; i++) {
                preparedData.push({
                    "uuid": "",
                    "school_code": schoolsCode,
                    "user_code": subscriptionData[i].user_code,
                    "transaction_id": transactionId,
                    "amount": subscriptionData[i].subscription_price,
                    // "transaction_details": JSON.stringify(transactionData),
                    "transaction_details": "",
                    "payment_status": "completed"
                })
            }
            const payload = {
                data: preparedData
            }
            if (paymentStatus === "success") {
                ADD_UPDATE_SUBSCRIPTION_PRICE(payload).then((res: any) => {
                    if (res.status === 'fail') {
                        toast.error(res.message);
                    } else {
                        moduleUpdate(paymentInfo, moduleUuid, selectedUsers);
                        if (couponData) {
                            // updateCouponCode(transactionId, couponData);
                            updateAppliedCoupon(transactionId, couponData);
                        }
                        toast.success('Subscription Added Successfully');
                    }
                });
            }
        } else {
            moduleUpdate(paymentInfo, moduleUuid, selectedUsers);
            if (couponData) {
                // updateCouponCode(transactionId, couponData);
                updateAppliedCoupon(transactionId, couponData);
            }
        }
    }
    function moduleUpdate(paymentInfo?: any, moduleUuid?: any, selectedUsers?: any) {
        const payload = {
            id: paymentInfo.id,
            amount_status: "COMPLETED",
            payment_at: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
            attempt: 1,
            module_uuid: moduleUuid ? moduleUuid : moduleUuid
        }
        UPDATE_ASSIGN_USER(payload).then(res => {
            if (res.status === 'fail') {
                toast.error(res.message);
            } else {
                getSchoolInfo();
                removeItemsFromeLocalStorage();
                if (selectedUsers.length > 500) {
                    const selectedUsersCount = selectedUsers.length;
                    const preparedCount = Math.ceil(selectedUsersCount / 500);

                    for (let i = 0; i < preparedCount; i++) {
                        const startIndex = i * 500;
                        const endIndex = Math.min((i + 1) * 500, selectedUsersCount);
                        const slicedUsers = selectedUsers.slice(startIndex, endIndex);
                        const lastIndexOfLoop = preparedCount - 1;
                        assignModuleStudents(paymentInfo, slicedUsers, lastIndexOfLoop, "more");
                    }
                } else {
                    assignModuleStudents(paymentInfo, selectedUsers, "", "less");

                }
                // assignModuleStudents(paymentInfo, selectedUsers);
            }
        });
    }
    function assignModuleStudents(paymentInfo?: any, studentsSelected?: any, lastIndexOfLoop?: any, type?: any) {
        const userCodes = [];
        for (let i = 0; i < studentsSelected?.length; i++) {
            userCodes.push(studentsSelected[i]?.value);
        }
        const payload = {
            module_uuid: paymentInfo.module_uuid ? paymentInfo.module_uuid : moduleUuid,
            payment_status: "COMPLETED",
            school_code: schoolsCode,
            target_std: paymentInfo.target_std,
            total_amount_paid: paymentInfo.amount,
            // total_amount_paid: assignsSchoolData.amount ? assignsSchoolData.amount : paymentInfo.amount,
            user_codes: userCodes
        }

        CREATE_MNS_USER_ASSIGN(payload).then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res.message);
            } else {
                // if (lastIndexOfLoop && type === "more") {
                //     history.push(`/dashboard/school/info/${schoolId}/paymentlist/${schoolCode}`);
                // } else if (type === "less") {
                //     history.push(`/dashboard/school/info/${schoolId}/paymentlist/${schoolCode}`);
                // }
                // history.push(`/dashboard/school/info/${schoolId}/paymentlist/${schoolCode}`);

                getSchoolInfo();
                setStudentsSelected([]);
                toast.success("Assign User  Successfully");

            }
        });
    }
    function updateAppliedCoupon(txnId: any, couponData?: any) {
        const payload = {
            promotion_uuid: couponData?.promotion_uuid ? couponData?.promotion_uuid : "",
            promo_code_uuid: couponData?.promocode_uuid ? couponData?.promocode_uuid : "",
            applied_for_user_code: loginUserCode,
            applied_sponsor_uuid: "",
            applied_dt: todayDate,
            applied_rules: couponData,
            status: 2
        }
        if ((couponData?.final_amount > 0 || couponData?.final_amount == 0) && Object.keys(couponData?.rule_applied).length > 0) {
            setLoading(true);
            UPDATE_APPLIED_COUPON_CODE(payload).then((res: any) => {
                if (res.status === 'fail') {
                    toast.error(res?.err);
                    setLoading(false);
                } else {
                    setLoading(false);
                }
            });
        }
    }
    function updateCouponCode(txnId: any, couponData?: any) {
        const payload = {
            txn_id: txnId,
            status: 2,
            applied_for_user_code: loginUserCode,
            applied_dt: todayDate,
            applied_rules: couponData
        }
        if ((couponData?.final_amount > 0 || couponData?.final_amount == 0) && Object.keys(couponData?.rule_applied).length > 0) {
            UPDATE_PROMO_CODES(couponData?.promocode_uuid, payload).then((res: any) => {
                if (res.status === 'fail') {
                    toast.error(res.err);
                } else {
                    setCouponCodeValue("");
                }
            });
        }
    }
    function applyCoupon(data?: any, type?: number) {
        const payload = {
            promo_code: data?.coupon_code ? data?.coupon_code : couponCodeValue?.replace(/\s/g, ''),
            students_count: data?.students_count ? data?.students_count : paymentData?.total_students,
            payable_amount: data?.total_amount ? data?.total_amount : paymentData?.total_amount,
            institute_code: instituteCode ? instituteCode : insCode,
            school_code: schoolsCode
            //  schoolsCode
        }
        if (payload?.promo_code) {
            if (type === 0) {
                setCouponLoading(true);
            }
            setCouponData({});
            setShowCouponDiv(false);
            APPLY_COUPON_CODE(payload).then((res: any) => {
                if (res.status === 'fail') {
                    if (type === 0) {
                        toast.error(res?.err);
                    }
                    setStudentLoading(false);
                    setLoading(false);
                    setCouponLoading(false);
                } else {
                    let data = res
                    if (Object.keys(data?.rule_applied).length > 0) {
                        setShowCouponDiv(true);
                        localStorage.setItem("cc_coupons_data", JSON.stringify(data));
                        data.coupon_code = payload?.promo_code;
                        setCouponData(data);
                    } else {
                        // toast.error("Coupon is not applicable");
                    }
                    setStudentLoading(false);
                    setLoading(false);
                    setCouponLoading(false);
                }
            });
        }

    }
    function onApplyCouponValue(e: any) {
        if (e) {
            setCouponCodeValue(e.target.value);
        } else {
            setCouponData({});
            setCouponCodeValue("");
        }

    }

    const removeCouponData = () => {
        setCouponData({});
        setCouponCodeValue("");
        localStorage.removeItem("cc_coupons_data");
        setShowCouponDiv(false);
    }
    const removeItemsFromeLocalStorage = () => {
        const keysToRemove = ['student_paymet_info', 'subscription_info', 'cc_subscription_data', 'subscription_price', 'cc_transaction_id', 'module_info', 'cc_selected_users', 'cc_coupons_data', 'cc_payment_info', 'cc_module_uuid'];
        keysToRemove.forEach((key: any) => {
            localStorage.removeItem(key);
        });
    };
    return (
        <div className="mt-4">
            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading &&
                <div>
                    <Breadcum breadcumList={breadcumInfo} margin={"classic"} />
                    <FormBuilder onUpdate={handleInput}>
                        <form ref={assignUserForm}>
                            <div className="row ">
                                <div className="col-md-3 ">
                                    <div className="mb-4">
                                        <label className="form-label mb-0">Standard</label>
                                        <select className="form-select" name="standard" onChange={(e) => { handleStandardChange(e) }}>
                                            <option value="">Select Standard</option>
                                            {standardList?.map((item: any) => (
                                                <option value={item}>{item}</option>
                                            ))
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="mb-4 ">
                                        <label className="form-label mb-0">Division</label>
                                        <select className="form-select" name="division" onChange={(e) => { handleDivisionChange(e) }}>
                                            <option value="">Select Division</option>
                                            {divisionList?.map((item: any) => (
                                                <option value={item}>{item}</option>
                                            ))
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className={`col-md-3 `}>
                                    <div className="mb-4 position-relative">
                                        <label className="form-label mb-0">Student</label>
                                        {optionStudentsLoading &&
                                            <div className=" mt-1 pt-1 position-absolute " style={{ right: "8px", zIndex: "9999" }}>
                                                <div className="spinner-border ms-5 ms-md-0" role="status" style={{ height: "23px", width: "23px" }} >
                                                    <span className="sr-only">Loading...</span>
                                                </div>
                                            </div>}
                                        <MultiSelect
                                            options={studentList}
                                            value={studentsSelected}
                                            onChange={(e: any) => onSelectedStudent(e)}
                                            labelledBy="Select"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="mb-4 mt-md-3 ">
                                        <button className="btn clear-btn cursor-pointer mt-2" type="button" onClick={() => handleRefresh()}>
                                            Clear
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </FormBuilder>
                    {studentLoading &&
                        <div className="text-center p-5">
                            <div className="spinner-border" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                    }
                    {!studentLoading &&
                        <div>
                            <div className={`mar-t-20 custom-scroll table-responsive ${paymentData?.students?.length > 10 && "tableFixHead "}`}>
                                <table className="table fixed-table-header mb-0">
                                    <thead className=''>
                                        <tr>
                                            <th>S.No</th>
                                            <th>Student Name</th>
                                            <th className="text-center">Subscription Price</th>
                                            <th className="text-center">Module Price</th>
                                            <th className="text-center">Total Amount </th>
                                        </tr>
                                    </thead>
                                    <tbody className=''>
                                        {paymentData?.students?.map((name: any, i: number) => (
                                            <tr key={i}>
                                                <td>{i + 1}</td>
                                                <td>{name?.name}</td>
                                                <td className="text-center">{name?.subscription_payment_status === "completed" ? "-" : name?.subscription_price}</td>
                                                <td className="text-center">{name?.module_price}</td>
                                                <td className="text-center">{name?.total_amount}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>

                            </div>

                            {paymentData?.students?.length > 0 && <div className='d-flex justify-content-between p-3 ' style={{ backgroundColor: '#e6e6e6', margin: '0px' }}>
                                <div className='' style={{ fontWeight: 'bold' }}>Total Amount to Pay</div>
                                <div className="d-flex justify-content-center">
                                    {/* <div className='' style={{ fontWeight: 'bold' }}>
                                        {paymentData?.total_subscription_amount}
                                    </div>
                                    <div className='' style={{ fontWeight: 'bold' }}>
                                        {paymentData?.total_module_amount}
                                    </div> */}
                                    <div className='' style={{ fontWeight: 'bold' }}>
                                        {paymentData?.total_amount}
                                    </div>
                                </div>
                            </div>}
                            {paymentData?.students?.length > 0 && <div className='d-flex justify-content-center p-3 mb-2 ' >
                                <button type="button" className="btn btn-primary cursor-pointer text-center border border-dark" onClick={handlePayment}>{(paymentData?.total_amount > 0 && couponData?.final_amount !== 0) ? 'Pay Now' : 'Assign'}</button>
                            </div>}
                            {paymentData?.students?.length == 0 && <p className="f16 text-center mt-2">No Data Found</p>}
                            {paymentData?.total_amount > 0 &&
                                <div className={`row mt-1 justify-content-md-center ${Object.keys(couponData).length == 0 && "mb-5"}`} >
                                    <div className='col-md-5 col-12 d-flex'>
                                        <input className='form-control form-control-lg f14 mt-4' placeholder=' Coupon Code' type='text' value={couponCodeValue} onChange={(e) => onApplyCouponValue(e)}></input>
                                        <button type="button" className="btn btn-primary cursor-pointer mt-4 pt-1" onClick={() => applyCoupon("", 0)}>Apply</button>
                                        {Object.keys(couponData).length > 0 && <button type="button" className="btn btn-success cursor-pointer mt-4 pt-1 ms-4" onClick={removeCouponData}>Remove</button>}
                                    </div>
                                </div>
                            }
                            {couponLoading &&
                                <div className="text-center p-5">
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div>
                            }
                            {!couponLoading &&
                                <div>
                                    {(showCouponDiv && parseInt(paymentData?.total_amount) > 0) &&
                                        <div className="row   mt-4 mb-5  justify-content-md-center" >
                                            <div className="col-md-5 col-12  py-3 px-4 rounded-3" style={{ backgroundColor: '#e6e6e6', margin: '0px' }}>
                                                <h3 className="dkpi-hd f18 mb-3 text-center">Coupon Applied</h3>
                                                <div className="row justify-content-between mb-1">
                                                    <div className="col">
                                                        <p className="mb-0">Coupon Code</p>
                                                    </div>
                                                    <div className="col">
                                                        <span className="me-3">:</span>
                                                        {couponData?.coupon_code}
                                                    </div>
                                                </div>
                                                <div className="row justify-content-between mb-1">
                                                    <div className="col">
                                                        <p className="mb-0">Students Count</p>
                                                    </div>
                                                    <div className="col">
                                                        <span className="me-3">:</span>
                                                        {couponData?.students_count}
                                                    </div>
                                                </div>
                                                <div className="row justify-content-between mb-1">
                                                    <div className="col">
                                                        <p className="mb-0">Payable Amount</p>
                                                    </div>
                                                    <div className="col">
                                                        <span className="me-3">:</span>
                                                        {couponData?.payable_amount}
                                                    </div>
                                                </div>
                                                <div className="row justify-content-between mb-1">

                                                    <div className="col ">
                                                        <p className="mb-0">Discount Percentage</p>
                                                    </div>
                                                    <div className="col">
                                                        <span className="me-3">:</span>
                                                        {couponData?.discount_percentage?.toFixed()}%
                                                    </div>
                                                </div>
                                                <div className="row justify-content-between mb-1">
                                                    <div className="col">
                                                        <p className=" mb-0">Discount Amount</p>
                                                    </div>
                                                    <div className="col">
                                                        <span className="me-3">:</span>
                                                        {couponData?.discount_amount?.toFixed()}
                                                    </div>
                                                </div>
                                                <div className="row justify-content-between  mt-2">
                                                    <div className="col">
                                                        <p className="text-gray f18 fw-bold mb-0"> Final Amount  to Pay</p>
                                                    </div>
                                                    <div className="col">
                                                        <span className="me-3">:</span>
                                                        <span className="f16 fw-bold"> {Math.round(couponData?.final_amount)}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            }
                        </div>}
                </div>}
        </div>
    )
};