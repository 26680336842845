import API from "../utility/axios";
const apiUrl = "/api/v1";
const apiNewUrl = "/api/v2";

// export const GET_PAYMENTS = (school_code: any): Promise<any[]> => {
//     return API.get(`${apiUrl}/school/user/module/payment?school_code=${school_code}`);
// }
export const CREATE_PAYMENT = (data: any): Promise<any> => {
  return API.post(`${apiUrl}/school/payment`, data);
};

export const UPDATE_PAYMENT = (data: any): Promise<any> => {
  return API.put(`${apiUrl}/school/payment`, data);
};

export const GET_PAYMENT = (pageNumber: number): Promise<any> => {
  return API.get(`${apiUrl}/school/payment?limit=${10}&page_num=${pageNumber}`);
};
export const CREATE_PAYMENT_DETAILS_SCHOOL_MODULE_CODE = (
  data: any
): Promise<any> => {
  return API.post(`${apiNewUrl}/payment/details`, data);
};
