import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { PrincipalUser } from './principal-user';
import SchoolFormPage from "./form";
import { VicePrincipalUser } from './vice-principal-user';
import MultiStepperNavbar from '../../../components/multi-stepper';
import { AdminUser } from './admin-user';



interface Props {
    schoolId: number;
}

const AddSchoolPage = () => {
    let { id } = useParams<{ id: string }>();
    // const editedschoolId = parseInt(id) || 0;
    const editedschoolId = id || 0;
    const [activeTab, setActiveTab] = useState(0);
    const [schoolId, setschoolId] = useState(0);
    const history = useHistory();
    const [formSteps, setFormSteps] = useState<any>([]);
    const userRole = localStorage.getItem("user_role");

    useEffect(() => {
        const formStepsDefault = [
            { name: 'Details' },
            { name: 'Principal', disabled: editedschoolId === 0 },
            { name: 'Vice Principal', disabled: editedschoolId === 0 },
            { name: 'Admin', disabled: editedschoolId === 0 }
        ];
        setFormSteps(formStepsDefault);
    }, []);

    const getschoolId = (data: any) => {
        if (schoolId === 0) {
            if (userRole === "CHAIRMAN" || userRole === "GENERAL_SECRETARY" || userRole === "INSTITUTE_ADMIN") {
                history.push(`/institute-dashboard/manage-school/list/new/${data}`);
            } else {
                history.push(`/dashboard/school/new/${data}`);
            }
            // return;
        }
        setschoolId(data);
        setActiveTab(1);
        const formStepsDefault = [
            { name: 'Details', disabled: true },
            { name: 'Principal' },
            { name: 'Vice Principal', disabled: true },
            { name: 'Admin', disabled: true }
        ];
        setFormSteps(formStepsDefault);
    }

    const getVicePrincipalPage = (data: any) => {
        setActiveTab(2);
        const formStepsDefault = [
            { name: 'Details', disabled: true },
            { name: 'Principal', disabled: true },
            { name: 'Vice Principal' },
            { name: 'Admin', disabled: true }
        ];
        setFormSteps(formStepsDefault);
    }

    const getAdminPage = (data: any) => {
        setActiveTab(3);
        const formStepsDefault = [
            { name: 'Details', disabled: true },
            { name: 'Principal', disabled: true },
            { name: 'Vice Principal', disabled: true },
            { name: 'Admin' }
        ];
        setFormSteps(formStepsDefault);
    }

    const getListPage = (data: any) => {
        if (userRole === "CHAIRMAN" || userRole === "GENERAL_SECRETARY" || userRole === "INSTITUTE_ADMIN") {
            history.push(`/institute-dashboard/manage-school/list`);
        } else {
            history.push(`/dashboard/school/list`);
        }
    }

    const getCurrentStep = () => {
        let element;
        switch (activeTab) {
            case 0:
                element = <SchoolFormPage schoolId={getschoolId} />
                break;
            case 1:
                element = <PrincipalUser schoolIdNumber={schoolId} principalName={getVicePrincipalPage} />
                break;
            case 2:
                element = <VicePrincipalUser schoolIdNumber={schoolId} vicePrincipalName={getAdminPage} />
                break;
            case 3:
                element = <AdminUser schoolIdNumber={schoolId} adminName={getListPage} />
                break;
        }
        return element;
    }
    return (
        <div className="row border-top border-primary py-3">
            {!editedschoolId && <h2 className='text-center'>Add New School</h2>}
            <MultiStepperNavbar steps={formSteps} updateTab={setActiveTab} selectedTab={activeTab}></MultiStepperNavbar>
            {getCurrentStep()}
        </div>
    );
};

export default AddSchoolPage;