import { DataTableCol } from "../../../../components/data-table/types";

export const SchoolTeachersGridColsData: DataTableCol[] = [
    {
        title: "Name",
        control: "name",
        sortable: true,
        canShowColumn: true,
    },
    {
        title: "User Name",
        control: "user_name",
        sortable: true,
        canShowColumn: true,
    },
    {
        title: "Email",
        control: "email_id",
        sortable: true,
        canShowColumn: true,
    },
    {
        title: "Mobile Number",
        control: "mobile_number",
        sortable: true,
        canShowColumn: true,
    },

    {
        title: "City/Village",
        control: "city_village",
        sortable: true,
        canShowColumn: true,
    },
    {
        title: "Actions",
        control: "both",
        isEdit: false,
        isDelete: false,
        canShowColumn: false,
        setPassword: false,

        // control: 'one'
    },
];
