import React, { useEffect, useState } from "react";
import FormBuilder from "../../../components/form-builder";
import { GET_PARENT_BY_STUDENT, GET_USER_USER_CODE, UPDATE_USER } from "../../../app/service/users.service";
import { toast } from "react-toastify";
import appConfig from "../../../config/constant";
import STUDENTIMG from '../../../institute-dashboard-assets/images/student-img.svg'
import { FormValidator, GetControlIsValid } from "../../../components/form-builder/validations";
import { FormControlError, FormField, FormValidators } from "../../../components/form-builder/model/form-field";
import { DateComponent } from "../../../components/date";
import moment from "moment";
import { UPLOAD_LOGO } from "../../../app/service/school.service";
import errorMessages from "../../../components/error-messages";
import { LOOK_UP_DATA } from "../../../app/service/lookup.service";
import { useHistory } from "react-router-dom";
import { StudentParentList } from "./parent-list";


export const StudentProfileInfo: React.FC = () => {
    const [parentList, setParentList] = useState<any>([]);
    const [studentInfo, setStudentInfo] = useState<any>({});
    const [loading, setLoading] = useState(false);
    const UserCode = localStorage.getItem("user_code");
    const [view, setView] = useState(false);
    const [updateView, setUpdateView] = useState(false);
    const [currentStudentData, setCurrentStudentData] = useState<any>({});
    const [studentsData, setStudentsData] = useState<any>({});
    const [validationErrors, setValidationErrors] = useState<FormControlError[]>([]);
    const [nameError, setNameError] = useState('');
    const [addressError, setAddressError] = useState('');
    const [startDate, setStartDate] = useState(null);
    const [isDateError, setIsDateError] = useState<any>({});
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [todayDate, setTodayDate] = useState(moment().format('YYYY-MM-DD'));
    const [isRegistrationDateError, setIsRegistrationDateError] = useState<any>({});
    const [imageLoader, setImageLoader] = useState(false);
    const [studentLogo, setStudentLogo] = useState<any>();
    const [email, setEmail] = useState(false);
    const [mobileNumber, setMobileNumber] = useState(false);
    const [whatsappNumber, setWhatsappNumber] = useState(false);
    const [pincodeNumber, setPincodeNumber] = useState(false);
    const [statesData, setStatesData] = useState<any>([]);
    const [districtData, setDistrictData] = useState<any>([]);
    const [cityData, setCityData] = useState<any>([]);
    const [allStateBlock, setAllStateBlock] = useState<any>({});
    const [blockData, setBlockData] = useState<any>([]);
    const [canCheckMobileNumber, setCanCheckMobileNumber] = useState(false);
    const [canShowWhatsAppError, setCanShowWhatsAppError] = useState(false);
    const history = useHistory();

    const formValidations = [
        new FormField('name', [FormValidators.REQUIRED]),
        new FormField('date_of_birth', []),
        new FormField('gender', [FormValidators.REQUIRED]),
        new FormField('medium', [FormValidators.REQUIRED]),
        new FormField('standard', [FormValidators.REQUIRED]),
        new FormField('division', [FormValidators.REQUIRED]),
        new FormField('email_id', [FormValidators.REQUIRED]),
        new FormField('mobile_number', [FormValidators.REQUIRED]),
        new FormField('whatsapp_number', []),
        new FormField('blood_group', []),
        new FormField('address', [FormValidators.REQUIRED]),
        new FormField('state', [FormValidators.REQUIRED]),
        new FormField('district', [FormValidators.REQUIRED]),
        new FormField('taluka', []),
        new FormField('city_village', []),
        new FormField('pin_code', [FormValidators.REQUIRED]),
        new FormField('date_of_registration', []),
    ];

    const mediums = [{
        "name": "English",
        "code": "ENGLISH"
    }, {
        "name": "Hindi",
        "code": "HINDI"
    }, {
        "name": "Marathi",
        "code": "MARATHI"
    },
    {
        "name": "Gujarati",
        "code": "GUJARATI"
    }
    ];

    useEffect(() => {
        getUserDataInfo();


    }, []);
    const getUserDataInfo = () => {
        setLoading(true);
        LOOK_UP_DATA().then((data1: any) => {
            if (data1.status === 'fail') {
                toast.error(data1.message);

            } else {
                setStatesData(data1.states);
                getUserDetails(data1);


            }

        });
    }



    function getUserDetails(data1: any) {
        setLoading(true);
        GET_USER_USER_CODE(UserCode).then((response: any) => {
            if (response.status === 'fail') {
                toast.error(response.message);
                setLoading(false);
            } else {
                const data = response.rows[0];
                if (data.date_of_birth) {
                    const date_of_birth: any = new Date(data.date_of_birth);
                    setStartDate(date_of_birth);
                }
                setStudentInfo(data);
                getParents(data);
                setCurrentStudentData(data);
                getStatesData(data1, data);
                setStudentLogo(data?.profile_img);
            }
        });
    }
    function getParents(data: any) {

        if (data?.guardian) {
            const guardian = data?.guardian ? data.guardian.replace(/[^a-zA-Z0-9,-_]/g, "") : "";
            GET_PARENT_BY_STUDENT(guardian).then((res: any) => {
                if (res) {
                    if (res.status === 'fail') {
                        toast.error(res.message);
                    } else {
                        const data = res.rows;
                        setParentList(data);
                        setLoading(false);
                    }
                }
            });
        } else {
            setLoading(false);

        }

    }

    const openSchoolDetails = (show: any) => {
        if (show === "view") {
            setView(!view);
            setUpdateView(false);
        }
        if (show === "update") {
            // setStudentLogo("");
            setUpdateView(!updateView);
            setNameError("")
            setView(false);
            setValidationErrors([]);
        }

    }
    const handleInput = (data: any) => {
        data.value = { ...currentStudentData, ...data.value };
        setStudentsData(data);
        const errors: any = FormValidator(formValidations, data.value);
        setValidationErrors(errors);

    };
    function getStatesData(data1: any, data: any) {
        if (data1.states.length > 0) {
            const preparedData = data1.states.filter((el: any) => el.state === data.state);
            if (preparedData.length > 0) {
                setDistrictData(preparedData[0].districts);
                setCityData(preparedData[0].cities);
                onLoadBlocks(data.district, preparedData[0]?.block);
            }

        }
    }
    const onChangeName = (event: any) => {
        setNameError('');
        const reg = /^[A-Za-z]+(?:[ _-][A-Za-z]+)*$/;
        if (event) {
            if (!reg.test(event.trim())) {
                setNameError('Enter only alphabets');
            } else {
                return true;
            }
        } else {
            setNameError('Please enter name');
        }
    }
    const getInputValid = (control: string) => {
        const value = GetControlIsValid(validationErrors, control);
        return value;
    }
    const handleGender = (e: any) => {
        const data = { ...studentsData.value };
        data.gender = e.target.value;
        if (currentStudentData) {
            currentStudentData.gender = e.target.value;
        }
    }
    const handleMediumChange = (e: any) => {
        const data = { ...studentsData.value };
        data.medium = e.target.value;
        if (currentStudentData) {
            currentStudentData.medium = e.target.value;
        }
    }
    const handleStandardChange = (e: any) => {
        const data = { ...studentsData.value };
        data.standard = e.target.value;
        if (currentStudentData) {
            currentStudentData.standard = e.target.value;
        }
    }
    const handleDivisionChange = (e: any) => {
        const data = { ...studentsData.value };
        data.division = e.target.value;
        if (currentStudentData) {
            currentStudentData.division = e.target.value;
        }
    }
    const onDateChange = (dates: any) => {
        setTodayDate(todayDate);
        if (dates.error) {
            setIsRegistrationDateError(dates);
        } else {
            setTodayDate(dates.date);
        }
    };
    const handleStateChange = (e: any) => {
        currentStudentData.district = "";
        currentStudentData.taluka = "";
        const data = { ...studentsData.value };
        data.state = e.target.value;
        const preparedData = statesData.filter((el: any) => el.state === e.target.value);
        setDistrictData(preparedData[0]?.districts);
        setCityData(preparedData[0]?.cities);
        setAllStateBlock(preparedData[0]?.block);

        if (currentStudentData) {
            currentStudentData.state = e.target.value;
        }
    }

    const handleDistrictChange = (e: any) => {
        currentStudentData.taluka = "";
        setBlockData([]);
        const data = { ...studentsData.value };
        data.district = e.target.value;
        if (currentStudentData) {
            currentStudentData.district = e.target.value;
        }
        onLoadBlocks(e.target.value, allStateBlock);

    }


    const onLoadBlocks = (e: any, blocks: any) => {
        Object.keys(blocks).find(key => {
            if (key === e) {
                setBlockData(blocks[key]);
                return;
            }
        });
    }

    const handleBlockChange = (e: any) => {
        const data = { ...studentsData.value };
        data.taluka = e.target.value;
        if (currentStudentData) {
            currentStudentData.taluka = e.target.value;
        }

    }
    const handleEmailChange = (e: any) => {
        const data = { ...studentsData.value };
        const re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,6})+$/;

        if (e.target.value === '' || re.test(e.target.value)) {
            data.email_id = e.target.value;
            setEmail(false);
        } else {
            data.email_id = e.target.value;
            setEmail(true);
        }

        if (currentStudentData) {
            currentStudentData.email_id = e.target.value;
        }
    }

    const handlePinCodeChange = (e: any) => {
        const data = { ...studentsData.value };
        const re = /^[0-9\b]+$/;

        if ((e.target.value === '' || re.test(e.target.value)) && e.target.value.length === 6) {
            data.pin_code = e.target.value;
            setPincodeNumber(false);
        } else {
            data.pin_code = e.target.value;
            if (e.target.value) {
                setPincodeNumber(true);
            }
        }

        if (currentStudentData) {
            currentStudentData.pin_code = e.target.value.replace(/\D+/g, '');
        }
    }


    const handleMobileNumberChange = (e: any) => {
        const data = { ...studentsData.value };
        if (e.target.checked) {
            if (data.whatsapp_number) {
                data.whatsapp_number = data.mobile_number;
                currentStudentData.whatsapp_number = data.mobile_number;
                setCanCheckMobileNumber(true);
                setCanShowWhatsAppError(false);
            }
            if (data.mobile_number || currentStudentData.mobile_number) {
                setCanCheckMobileNumber(true);
                setCanShowWhatsAppError(false);
                currentStudentData.whatsapp_number = currentStudentData.mobile_number;
                setCurrentStudentData(currentStudentData);
            }
            currentStudentData.checked = true;
        } else {

            setCanCheckMobileNumber(false);
            currentStudentData.checked = false;
        }
    }
    const handleMobileChange = (e: any) => {
        const data = { ...studentsData.value };
        const re = /(6|7|8|9)\d{9}/;

        if ((e.target.value === '' || re.test(e.target.value)) && e.target.value.length === 10) {
            data.mobile_number = e.target.value;
            setMobileNumber(false);
        } else {
            data.mobile_number = e.target.value;
            if (e.target.value) {
                setMobileNumber(true);
            }
        }

        if (currentStudentData) {
            currentStudentData.mobile_number = e.target.value.replace(/\D+/g, '');
        }

        if (currentStudentData.checked) {
            currentStudentData.whatsapp_number = e.target.value.replace(/\D+/g, '');
        }
    }

    const handleWhatsappChange = (e: any) => {
        const data = { ...studentsData.value };
        const re = /(6|7|8|9)\d{9}/;

        if ((e.target.value === '' || re.test(e.target.value)) && e.target.value.length === 10) {
            data.whatsapp_number = e.target.value;
            setWhatsappNumber(false);
            setCanShowWhatsAppError(false);
        } else {
            data.whatsapp_number = e.target.value;
            setWhatsappNumber(true);
        }

        if (currentStudentData) {
            currentStudentData.whatsapp_number = e.target.value.replace(/\D+/g, '');
        }
    }
    const onChangeAddress = (event: any) => {
        setAddressError('');
        if (event) {
            if (event.trim().length <= 2) {
                setAddressError('Address minimum 3 characters');
            } else if (event.trim().length >= 256) {
                setAddressError('Address maximum 256 characters');

            } else {
                return true;
            }
        } else {
            setAddressError('Please enter Address');
        }
    }
    function handleUploadLogo(e: any, type: string) {
        if (e.target.files && e.target.files[0]) {
            const fileType = e.target.files[0].name.split('.').pop()
            if (fileType == "jpeg" || fileType == "jpg") {
                const formData = new FormData();
                formData.append('file', e.target.files[0], e.target.files[0].name);
                uploadLogo(formData, type);
            } else {
                toast.error("Valid file type .jpg, .jpeg only");
            }
        }
    }
    function uploadLogo(formdata: any, type: string) {
        setImageLoader(true);
        UPLOAD_LOGO(formdata).then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res.message);
                setImageLoader(false);
            } else {
                setStudentLogo(res.url);
                setImageLoader(false);
            }
        });
    }
    function updateStudent() {
        setIsFormSubmitted(true);
        const data = studentsData.value ? { ...studentsData.value } : { ...currentStudentData };
        const errors: FormControlError[] = FormValidator(formValidations, data);
        setValidationErrors(errors);

        // setPrincipalValidationErrors(errors);
        data.type = "school";
        data.user_role = "STUDENT";
        data.institute_school_code = studentInfo?.institute_school_code;
        data.id = studentInfo?.id;
        data.profile_img = studentLogo;
        data.date_of_birth = startDate ? moment(startDate).format('YYYY-MM-DD') : null;
        data.date_of_registration = todayDate;
        delete data.sameMobileNumber;
        delete data.file;
        delete data.checked;
        delete data.user_code;
        delete data.teaching_standards;
        delete data.teaching_subjects;
        delete data.address2;
        delete data.status;
        delete data.guardian;
        delete data.occupation;
        delete data.post_in;
        delete data.active;
        delete data.cas_report_view;
        delete data.indv_school_details;
        delete data.created_at;
        delete data.updated_at;
        delete data.role_name;
        delete data.user_name;
        delete data.short_name;
        delete data.name_of_school;
        delete data.name_of_organization;
        delete data.display_name;
        delete data.day;
        delete data.month;
        delete data.year;
        delete data.access;
        data.whatsapp_number = currentStudentData.whatsapp_number;
        if (errors.length < 1 && startDate && !email && !mobileNumber && !pincodeNumber && onChangeAddress(data.address)
            && !nameError) {      // if (errors.length < 1) {
            setLoading(true);
            UPDATE_USER(data).then((res: any) => {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    toast.success(res.message);
                    getUserDataInfo();
                    setUpdateView(false)
                    setLoading(false);

                }
            });
        } else {

            if (!nameError && !onChangeName(data.name)) {
                setNameError('Please enter name');
            }
            if (!addressError && !onChangeAddress(data.address)) {
                setAddressError('Please enter address');
            }
        }
        // }
    }
    function handleCancel() {
        getUserDataInfo();
        setUpdateView(false);
    }

    const onChange = (dates: any) => {
        setStartDate(null);
        if (dates.error) {
            setIsDateError(dates);
        } else {
            setStartDate(dates.date);
        }
    };
    const handleBloodGroupChange = (e: any) => {
        const data = { ...studentsData.value };
        data.blood_group = e.target.value;
        if (currentStudentData) {
            currentStudentData.blood_group = e.target.value;
        }
    }
    const getTimeFormate = (dateTimeString: any) => {
        const formattedDate = new Date(dateTimeString).toLocaleDateString('en-US', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
        });
        return formattedDate;
    }
    return (
        <div>
            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading && <div>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb pb-2 ps-0 pt-0 border-bottom">
                        <li className="breadcrumb-item"><span>Profile</span></li>
                    </ol>
                </nav>
                <div className="border-bottom pb-3">

                    <div className="d-md-flex sc-profile-details">
                        <div className="mb-3 mb-md-0">
                            <div className="sc-profile-img py-0">
                                <img
                                    src={studentInfo?.profile_img ? `${appConfig.FILE_UPLOAD_URL}${studentInfo.profile_img.charAt(0) === "/" ? studentInfo.profile_img.substring(1) : studentInfo.profile_img}` : STUDENTIMG}
                                    alt=""
                                    className="pr-logo"
                                />
                            </div>
                        </div>
                        <div className="sc-profile-text ps-md-4">
                            <h2 className="mt-0 mb-2">{studentInfo?.name}</h2>
                            <p className="m-0 sc-profile-contact">
                                <span>{studentInfo?.mobile_number}</span> {studentInfo?.email_id && <span> | </span>} <span>{studentInfo?.email_id} </span>
                            </p>
                            <p className="m-0 sc-profile-addrs">
                                <span>{studentInfo?.state}</span> {studentInfo?.district && <span> | </span>} <span>{studentInfo?.district}</span>{studentInfo?.city_village && <span> | </span>}
                                <span>{studentInfo?.city_village}</span>
                            </p>
                            <div className="d-flex"> <p className="mt-3">
                                <a className={`btn btn-secondary sc-profile-viewbtn ${!view && "collapsed"}`} data-bs-toggle="collapse"
                                    href="#collapseExample" aria-expanded="false" aria-controls="collapseExample" onClick={() => openSchoolDetails("view")}>View More
                                    Information <i className="view-arrow"></i></a>
                            </p> <p className="ps-3 mt-3">  <a className={`btn btn-secondary sc-profile-viewbtn ${!updateView && "collapsed"}`} data-bs-toggle="collapse"
                                href="#collapseExampleNew" aria-expanded="false" aria-controls="collapseExample" onClick={() => openSchoolDetails("update")}>Edit <i className="view-arrow"></i></a></p>
                            </div>
                        </div>
                    </div>
                    <div className={`collapse ms-lg-5 ps-lg-5 ${view && "show"}`} id="collapseExample">
                        <div className='ps-2   ms-lg-5'>
                            <div className="row">
                                <div className="col-md-4 col-sm-6   mb-4">
                                    <label className="f12 text-mgray">Name</label>
                                    <p className="f18 mb-0">{studentInfo?.name}</p>
                                </div>
                                <div className="col-md-4 col-sm-6   mb-4">
                                    <label className="f12 text-mgray">User ID</label>
                                    <p className="f18 mb-0">{studentInfo?.user_name}</p>
                                </div>
                                <div className="col-md-4 col-sm-6  mb-4">
                                    <label className="f12 text-mgray">Class</label>
                                    <p className="f18 mb-0">{studentInfo?.standard}<sup>th</sup></p>
                                </div>
                                <div className="col-md-4 col-sm-6  mb-4">
                                    <label className="f12 text-mgray">Division</label>
                                    <p className="f18 mb-0">{studentInfo?.division}</p>
                                </div>
                                <div className="col-md-4 col-sm-6  mb-4">
                                    <label className="f12 text-mgray">Date of Birth</label>
                                    <p className="f18 mb-0">{getTimeFormate(studentInfo?.date_of_birth)}</p>
                                </div>

                                <div className="col-md-4 col-sm-6  mb-4">
                                    <label className="f12 text-mgray">Gender</label>
                                    <p className="f18 mb-0">{studentInfo?.gender}</p>
                                </div>

                                <div className="col-md-4 col-sm-6  mb-4">
                                    <label className="f12 text-mgray">Mobile Number</label>
                                    <p className="f18 mb-0">{studentInfo?.mobile_number}</p>
                                </div>
                                <div className="col-md-4 col-sm-6  mb-4">
                                    <label className="f12 text-mgray">WhatsApp Number</label>
                                    <p className="f18 mb-0">{studentInfo?.whatsapp_number}</p>
                                </div>
                                <div className="col-md-4 col-sm-6  mb-4">
                                    <label className="f12 text-mgray">Email ID</label>
                                    <p className="f18 mb-0">{studentInfo?.email_id}</p>
                                </div>
                                <div className="col-md-4 col-sm-6  mb-4">
                                    <label className="f12 text-mgray">Blood Group</label>
                                    <p className="f18 mb-0">{studentInfo?.blood_group}</p>
                                </div>
                                <div className="col-md-4 col-sm-6  mb-4">
                                    <label className="f12 text-mgray">Date of Registration</label>
                                    <p className="f18 mb-0">{getTimeFormate(studentInfo?.date_of_registration)}</p>
                                </div>
                                <div className="col-md-4 col-sm-6  mb-4">
                                    <label className="f12 text-mgray">Address</label>
                                    <p className="f18 mb-0">{studentInfo?.address}</p>
                                </div>
                                <div className="col-md-4 col-sm-6  mb-4">
                                    <label className="f12 text-mgray">State</label>
                                    <p className="f18 mb-0">{studentInfo?.state}</p>
                                </div>
                                <div className="col-md-4 col-sm-6  mb-4">
                                    <label className="f12 text-mgray">District</label>
                                    <p className="f18 mb-0">{studentInfo?.district}</p>
                                </div>
                                <div className="col-md-4 col-sm-6  mb-4">
                                    <label className="f12 text-mgray">City/Village</label>
                                    <p className="f18 mb-0">{studentInfo?.city_village}</p>
                                </div>
                                <div className="col-md-4 col-sm-6  mb-4">
                                    <label className="f12 text-mgray">Pincode</label>
                                    <p className="f18 mb-0">{studentInfo?.pin_code}</p>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className={`collapse ${(updateView) && 'show'}`} id="collapseExampleNew">

                        <FormBuilder onUpdate={handleInput}>
                            <form>
                                <div style={{ borderBottom: 'solid 1px black', marginTop: '15px' }} className="mb-4">
                                    <h5 className="form-label mb-2 d-block sc-profile-text">Personal Details</h5>
                                </div>
                                <div className="row custom-form">
                                    <div className="col-md-4">
                                        <div className="mb-4">
                                            <label className="form-label mb-0 f16">Name <span className="ms-1" style={{ color: 'red' }}>*</span></label>
                                            <input className="form-control form-control-lg f18 mt-md-2" type="text" name="name" onChange={(e) => onChangeName(e.target.value)} defaultValue={currentStudentData.name} placeholder="Please enter name" />
                                            {nameError && <p className="text-danger">{nameError}</p>}
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="mb-4">
                                            <label className="form-label mb-0 pb-0">Date of Birth <span className="ms-1" style={{ color: 'red' }}>*</span></label>
                                            {currentStudentData.date_of_birth &&
                                                <DateComponent onDate={onChange} yearDiff={10} defaultData={currentStudentData.date_of_birth}></DateComponent>}
                                            {!currentStudentData.date_of_birth && <DateComponent onDate={onChange} yearDiff={10}></DateComponent>}
                                            {isFormSubmitted && !startDate && !isDateError?.error && <p className="text-danger">Please select date</p>}
                                            {isDateError?.error && !startDate && <p className="text-danger">{isDateError?.error}</p>}

                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="mb-4">
                                            <label className="form-label mb-4">Gender <span className="ms-1" style={{ color: 'red' }}>*</span></label>
                                            <br />
                                            <input type="radio" name="gender" value="male" checked={currentStudentData?.gender === "male"} onChange={(e) => { handleGender(e) }} />
                                            <span style={{ margin: '0px 12px' }}>Male</span>
                                            <input type="radio" name="gender" value="female" checked={currentStudentData?.gender === "female"} onChange={(e) => { handleGender(e) }} />
                                            <span style={{ margin: '0px 12px' }}>Female</span>

                                            <input className="mb-0" type="radio" value="transgender" name="gender" checked={currentStudentData?.gender === "transgender"} onChange={(e) => { handleGender(e) }} />
                                            <span style={{ paddingRight: '15px', paddingLeft: '15px' }}>Other</span>
                                            {isFormSubmitted && !getInputValid('gender') && <p className="text-danger">Please select gender</p>}
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="mb-4">
                                            <label className="form-label mb-0">Blood Group</label>
                                            <select name="blood_group f18 " value={currentStudentData.blood_group} onChange={(e) => { handleBloodGroupChange(e) }} className="form-select form-select-lg mb-3">
                                                <option value="">Select blood group</option>
                                                <option value="O+">O+</option>
                                                <option value="O-">O-</option>
                                                <option value="A-">A-</option>
                                                <option value="A+">A+</option>
                                                <option value="B-">B-</option>
                                                <option value="B+">B+</option>
                                                <option value="AB-">AB-</option>
                                                <option value="AB+">AB+</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="mb-4">
                                            <label className="form-label mb-0">Medium <span className="ms-1" style={{ color: 'red' }}>*</span></label>
                                            <select className="form-select form-select-lg mb-3 f18 " name="medium" value={currentStudentData.medium} onChange={(e) => { handleMediumChange(e) }}>
                                                <option value="">Select medium</option>
                                                {mediums?.map((medium: any, i: number) => (
                                                    <option value={medium?.code} key={i}>{medium?.name}</option>
                                                ))}
                                            </select>
                                            {isFormSubmitted && !getInputValid('medium') && <p className="text-danger">Please select medium</p>}
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="mb-4">
                                            <label className="form-label mb-0">Standard <span className="ms-1" style={{ color: 'red' }}>*</span></label>
                                            <select className="form-select form-select-lg mb-3 f18 " name="standard" value={currentStudentData.standard} onChange={(e) => { handleStandardChange(e) }}>
                                                <option value="">Select standard</option>
                                                <option value="6">6</option>
                                                <option value="7">7</option>
                                                <option value="8">8</option>
                                                <option value="9">9</option>
                                                <option value="10">10</option>
                                                <option value="11">11</option>
                                                <option value="12">12</option>
                                            </select>
                                            {isFormSubmitted && !getInputValid('standard') && <p className="text-danger">Please select standard</p>}
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="mb-4">
                                            <label className="form-label mb-0">Division <span className="ms-1" style={{ color: 'red' }}>*</span></label>
                                            <select className="form-select form-select-lg mb-3 f18" name="division" value={currentStudentData.division} onChange={(e) => { handleDivisionChange(e) }}>
                                                <option value="">Select division</option>
                                                <option value="A">A</option>
                                                <option value="B">B</option>
                                                <option value="C">C</option>
                                                <option value="D">D</option>
                                                <option value="E">E</option>
                                            </select>
                                            {isFormSubmitted && !getInputValid('division') && <p className="text-danger">Please select division</p>}
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="mb-4">
                                            <div className="file btn btn-sm btn-primary px-4  mt-2 d-inline-block">Upload Photo
                                                <input type="file" name="file" accept="image/*" onChange={(e) => handleUploadLogo(e, "schooladmin")} />
                                            </div>
                                        </div>
                                    </div>
                                    {imageLoader &&
                                        <div className="text-center col-md-1 p-5">
                                            <div className="spinner-border" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                        </div>
                                    }
                                    {studentLogo && <div className="col-md-4">
                                        <div className="mb-4">
                                            <label className="form-label mb-0 d-block f16">Preview Photo</label>
                                            <img src={`${appConfig.FILE_UPLOAD_URL}${studentLogo.charAt(0) === "/" ? studentLogo.substring(1) : studentLogo}`} style={{ height: '100px', width: '100px', objectFit: 'contain' }} />
                                        </div>
                                    </div>}
                                </div>
                                <div style={{ borderBottom: 'solid 1px black' }} className="mb-4">
                                    <h5 className="form-label mb-2 d-block sc-profile-text">Contact Details</h5>
                                </div>
                                <div className="row custom-form">
                                    <div className="col-md-4">
                                        <div className="mb-4">
                                            <label className="form-label mb-0">Email ID <span className="ms-1" style={{ color: 'red' }}>*</span></label>
                                            <input className="form-control form-control-lg f18 " type="text" name="email_id" value={currentStudentData.email_id} onChange={(e) => { handleEmailChange(e) }} placeholder="Please enter email" />
                                            {email && <p className="text-danger">{errorMessages.email}</p>}
                                            {isFormSubmitted && !getInputValid('email_id') && <p className="text-danger">Please enter email</p>}
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="mb-4">
                                            <label className="form-label mb-0">Mobile Number <span className="ms-1" style={{ color: 'red' }}>*</span></label>
                                            <input className="form-control form-control-lg f18 " type="text" name="mobile_number" maxLength={10} onChange={(e) => { handleMobileChange(e) }} value={currentStudentData.mobile_number} placeholder="Please enter mobile number" />
                                            {mobileNumber && <p className="text-danger">{errorMessages.mobile_number}</p>}
                                            {isFormSubmitted && !getInputValid('mobile_number') && <p className="text-danger">Please enter mobile number</p>}
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="mb-4">
                                            <label className="form-label mb-0">WhatsApp Number</label>
                                            (  <input type="checkbox" name="sameMobileNumber" checked={currentStudentData.checked} onChange={(e) => { handleMobileNumberChange(e) }} style={{ paddingLeft: '10px' }} />
                                            <span style={{ paddingLeft: '10px' }}>Same as Mobile Number</span> )
                                            <input className="form-control form-control-lg f18 " type="text" disabled={currentStudentData.checked} name="whatsapp_number" maxLength={10} onChange={(e) => { handleWhatsappChange(e) }} value={currentStudentData.whatsapp_number} placeholder="Please enter whatsapp number" />
                                            {(whatsappNumber) && <p className="text-danger">{errorMessages.whatsapp_number}</p>}
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="mb-4">
                                            <label className="form-label mb-0">Address <span className="ms-1" style={{ color: 'red' }}>*</span></label>
                                            <input className="form-control form-control-lg f18 " type="textarea" onChange={(e) => onChangeAddress(e.target.value)} defaultValue={currentStudentData?.address} name="address" placeholder="Please enter address" />
                                            {addressError && <p className="text-danger">{addressError}</p>}
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="mb-4">
                                            <label className="form-label mb-0">State <span className="ms-1" style={{ color: 'red' }}>*</span></label>
                                            <select name="state" className="form-select form-select-lg mb-3 f18 " value={currentStudentData?.state} onChange={(e) => { handleStateChange(e) }}>
                                                <option value="">Select state</option>
                                                {statesData.map((name: any, i: number) => (
                                                    <option key={i} value={name.state}>
                                                        {name.state}
                                                    </option>
                                                ))}
                                            </select>
                                            {isFormSubmitted && !getInputValid('state') && <p className="text-danger">Please select state</p>}
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="mb-4">
                                            <label className="form-label mb-0">District <span className="ms-1" style={{ color: 'red' }}>*</span></label>
                                            <select name="district" className="form-select form-select-lg mb-3 f18 " value={currentStudentData?.district} onChange={(e) => { handleDistrictChange(e) }}>
                                                <option value="">Select district</option>
                                                {districtData?.map((name: any, i: number) => (
                                                    <option key={i} value={name}>
                                                        {name}
                                                    </option>
                                                ))}
                                            </select>
                                            {isFormSubmitted && !getInputValid('district') && <p className="text-danger">Please select district</p>}
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="mb-4">
                                            <label className="form-label mb-0">Block</label>
                                            <select name="taluka" className="form-select form-select-lg mb-3 f18 " value={currentStudentData?.taluka} onChange={(e) => { handleBlockChange(e) }}>
                                                <option value="">Select block</option>
                                                {blockData?.map((name: any, i: number) => (
                                                    <option key={i} value={name}>
                                                        {name}
                                                    </option>
                                                ))}
                                            </select>
                                            {isFormSubmitted && !getInputValid('taluka') && <p className="text-danger">Please select block</p>}
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="mb-4">
                                            <label className="form-label mb-0">City/Village (Optional)</label>
                                            <input className="form-control form-control-lg f18 " type="text" name="city_village" defaultValue={currentStudentData?.city_village} placeholder="Please enter village" />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="mb-4">
                                            <label className="form-label mb-0">PIN Code <span className="ms-1" style={{ color: 'red' }}>*</span></label>
                                            <input className="form-control form-control-lg f18 " type="text" name="pin_code" maxLength={6} onChange={(e) => { handlePinCodeChange(e) }} value={currentStudentData?.pin_code} placeholder="Please enter PIN code" />
                                            {pincodeNumber && <p className="text-danger">{errorMessages.pincode}</p>}
                                            {isFormSubmitted && !getInputValid('pin_code') && <p className="text-danger">Please enter pin code</p>}
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </FormBuilder>
                        <div className="form-footer py-3 text-end mt-4">
                            <a className="btn btn-primary btn-sm px-5 rounded-12 cursor-pointer" onClick={() => updateStudent()}>Update</a>
                            <span className="cursor-pointer mx-3" onClick={handleCancel}>Cancel</span>
                        </div>
                    </div>
                </div>
                <StudentParentList childData={studentInfo} getUserDataInfo={getUserDataInfo} parentList={parentList} />
            </div>}
        </div>

    )
}