import { ReduxReducerAction } from "../model/redux-reducer-action";
import { PopupData } from "./model/popup-data";
import { AppUtilityTypes } from "./types";
import {
    USERDATA
} from "../types";

export class AppUtilityActions {
    static updatePopup = (data: PopupData): ReduxReducerAction => {
        return {
            type: AppUtilityTypes.UPDATE_POPUP,
            data
        };
    }
}

export const UserData = (data: any) => {
    return {
        type: USERDATA,
        data
    }
}