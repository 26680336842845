import { SchoolListItem } from "../model/school/school-list";
import API from "../utility/axios";
const apiUrl = "/api/v1";
const newApiUrl = "/api/v2";

export const CREATE_SCHOOL = (data: any): Promise<any> => {
  return API.post(`${apiUrl}/school`, data);
};

export const UPDATE_SCHOOL = (data: any): Promise<any> => {
  return API.put(`${apiUrl}/school`, data);
};

export const GET_SCHOOL = (
  pageNumber: number,
  data: any
): Promise<SchoolListItem[]> => {
  return API.get(`${apiUrl}/school?limit=${10}&page_num=${pageNumber}`, {
    params: data,
  });
};

export const GET_TOTAL_SCHOOLS = (): Promise<SchoolListItem[]> => {
  return API.get(`${apiUrl}/school`);
};

export const GET_SCHOOL_BY_ID = (id: number): Promise<SchoolListItem[]> => {
  return API.get(`${apiUrl}/school?id=${id}`);
};

export const UPLOAD_LOGO = (data: any): Promise<any> => {
  return API.post(`${apiUrl}/file`, data);
};

export const CREATE_SCHOOL_USER = (data: any): Promise<any> => {
  return API.post(`${apiUrl}/user`, data);
};

export const DELETE_SCHOOL = (id: number): Promise<any> => {
  return API.delete(`${apiUrl}/school/${id}`);
};

export const GET_School_Filter = (data: any): Promise<any> => {
  return API.get(`${apiUrl}/school?limit=${10}&page_num=${0}`, {
    params: data,
  });
};

export const GET_SCHOOL_INSTITUTE_ID = (
  organization_code: any,
  pageNumber: number,
  data: any
): Promise<SchoolListItem[]> => {
  return API.get(
    `${apiUrl}/school?organization_code=${organization_code}&limit=${10}&page_num=${pageNumber}`,
    { params: data }
  );
};

export const GET_SCHOOL_INSTITUTE_ID_FILTER = (
  organization_code: any,
  pageNumber: number,
  data: any
): Promise<SchoolListItem[]> => {
  return API.get(
    `${apiUrl}/school?organization_code=${organization_code}&limit=${10}&page_num=${pageNumber}`,
    { params: data }
  );
};

export const GET_SCHOOL_BY_SCHOOL_CODE = (
  school_code: any
): Promise<SchoolListItem[]> => {
  return API.get(`${apiUrl}/school?school_code=${school_code}`);
};

export const GET_FTM_SCHOOLS_BY_SCHOOL_CODE = (
  school_code: any,
  pageNumber: any,
  data: any
): Promise<SchoolListItem[]> => {
  return API.get(
    `${apiUrl}/school?school_code=${school_code}&limit=${10}&page_num=${pageNumber}`,
    { params: data }
  );
};

export const GET_BULK_UPLOAD_STATUS = (
  school_code: any
): Promise<SchoolListItem[]> => {
  return API.get(`${apiUrl}/user/bulkupload?school_code=${school_code}`);
};

export const BULK_UPLOAD_SCHOOL = (data: any): Promise<any[]> => {
  return API.post(`${apiUrl}/school/upload`, data);
};

export const CREATE_INDIVIDUAL_STUDENT_PARENT = (data: any): Promise<any> => {
  return API.post(`${newApiUrl}/user/parent`, data);
};

export const GET_ALL_SCHOOL_LIST = (data: any): Promise<SchoolListItem[]> => {
  return API.get(`${apiUrl}/school?limit=${100000}`, {
    params: data,
  });
};

export const GET_STANDARD_DIVISION_BY_SCHOOL_CODE = (
  schoolCode: any
): Promise<SchoolListItem[]> => {
  return API.get(
    `${newApiUrl}/app/configs/school/standards?school_code=${schoolCode}`
  );
};
