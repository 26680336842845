
import React, { useEffect, useRef, useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { CREATE_MODULE_ANSWERS, GET_MNS_MODULES_BY_ID, GET_MNS_MODULES_BY_ID_NEW, GET_MNS_MODULES_BY_ID_NEW_STUDENt, GET_MODULES_BY_ID, GET_MODULE_BY_QUESTIONS, UPDATE_ASSIGN_USER } from '../../../app/service/module.service';
import FormBuilder from '../../../components/form-builder';

function CASTestList() {
    const [moduleData, setModuleData] = useState<any>({});
    const [questionsData, setQuestionsData] = useState<any[]>([]);
    const [answerData, setAnswerData] = useState<any[]>([]);
    const [currentAnswerData, setCurrentAnswerData] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    const [languageQuestionsData, setLanguageQuestionsData] = useState<any>([]);
    const [userRole, setUserRole] = useState<any>();
    const [canShowPopup, setCanShowPopup] = useState(false);
    const [canShowCompleteFormBtn, setCanShowCompleteFormBtn] = useState(false);
    const [questionsFormData, setQuestionsFormData] = useState<any>({});
    const surveyForm = useRef<any>({});
    const history = useHistory();
    let { id, moduleCode, languageCode } = useParams<{ id: string, moduleCode: string, languageCode: string }>();
    const user_code = id;
    const languagesCode = languageCode;
    const modules_code = moduleCode;
    const languages = [{
        "name": "English",
        "code": "en"
    }, {
        "name": "Hindi",
        "code": "hi"
    }, {
        "name": "Marathi",
        "code": "mr"
    },
    {
        "name": "Gujarati",
        "code": "gu"
    }
    ];
    useEffect(() => {
        const userRole = localStorage.getItem("user_role");
        setUserRole(userRole);
        const childUserCode = sessionStorage.getItem('childUserCode') || '';
        if (userRole === 'PARENT') {
            GET_MNS_MODULES_BY_ID_NEW(user_code, modules_code, childUserCode).then((res: any) => {
                if (res) {
                    if (res.status === 'fail') {
                        toast.error(res.message);
                    } else {
                        setModuleData(res.rows[0]);
                        getQuestions(res.rows[0]);
                    }
                }
            });
        } else {
            setLoading(true);
            GET_MNS_MODULES_BY_ID_NEW_STUDENt(user_code, modules_code).then((res: any) => {
                if (res) {
                    if (res.status === 'fail') {
                        toast.error(res.message);
                    } else {
                        let data = res.rows.filter((el: any) => el.module_code === 'CAS');
                        if (data[0]?.module_status === "completed") {
                            history.push(`/student-dashboard/list`)
                        }
                        setModuleData(data[0]);
                        getQuestions(data[0]);
                    }
                }
            });
        }

    }, []);

    function getQuestions(data: any) {
        const payload = {
            user_code: data.user_code,
            school_code: data.school_code,
            module_code: data.module_code,
            sub_module: data.sub_module,
            module_ver: data.module_ver,
            target_std: "10"
        }
        GET_MODULE_BY_QUESTIONS(payload).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    const data = res.en;
                    setLanguageQuestionsData(res);
                    const answers = res.answer;
                    setCurrentAnswerData(answers);
                    for (let i = 0; i < data.length; i++) {
                        for (let j = 0; j < answers.length; j++) {
                            if (i + 1 === answers[j].key) {

                                data[i].questionIndex = i;
                                if (answers[j].value === "a") {
                                    data[i].selectedOption = "" + 0;

                                } else if (answers[j].value === "b") {
                                    data[i].selectedOption = "" + 1;

                                } else if (answers[j].value === "c") {
                                    data[i].selectedOption = "" + 2;
                                } else {
                                    data[i].selectedOption = answers[j].value ? answers[j].value : null;
                                }
                            }
                        }
                    }
                    if (languagesCode === "en") {
                        setQuestionsData(res.en);

                    } else if (languagesCode === "hi") {
                        setQuestionsData(res.hi);

                    } else if (languagesCode === "mr") {
                        setQuestionsData(res.mr);

                    } else if (languagesCode === "gu") {
                        setQuestionsData(res.gu);

                    } else {
                        setQuestionsData(data);
                    }
                    setLoading(false);
                    const selectedQuestions = data.filter((el: any) => el.selectedOption);
                    if (selectedQuestions.length === data.length) {
                        setCanShowCompleteFormBtn(true);
                    } else {
                        setCanShowCompleteFormBtn(false);
                    }
                }
            }
        });
    }

    function handleQuestionOptions(e: any, j: number, index: number) {
        const data = questionsData;
        data[j].checked = true;
        data[j].questionIndex = j;
        data[j].selectedOption = data[j].question_type === 'multiple_choice' ? "" + index : (e.target.value ? e.target.value : null);
        const selectedQuestions = questionsData.filter(el => el.selectedOption);
        if (selectedQuestions.length === questionsData.length) {
            setCanShowCompleteFormBtn(true);
        } else {
            setCanShowCompleteFormBtn(false);
        }
        setQuestionsData(data);
    }

    const handleOptions = (data: any) => {
        setQuestionsFormData(data);
    }

    const handleSubmit = () => {
    }

    function onSubmitAnswers(type: any) {
        const answer = [];
        for (let j = 0; j < questionsData.length; j++) {
            if (questionsData[j].selectedOption) {
                let requiredAnswer = "";
                if (questionsData[j].selectedOption === "0") {
                    requiredAnswer = "a";
                } else if (questionsData[j].selectedOption === "1") {
                    requiredAnswer = "b";
                } else if (questionsData[j].selectedOption === "2") {
                    requiredAnswer = "c";
                } else {
                    requiredAnswer = questionsData[j].selectedOption;
                }
                answer.push({
                    "key": questionsData[j].questionIndex + 1,
                    "value": requiredAnswer,
                    'type': questionsData[j].question_type
                });
            }

        }
        let payload = {
            user_code: moduleData.user_code,
            school_code: moduleData.school_code,
            module_code: moduleData.module_code,
            sub_module: moduleData.sub_module,
            module_ver: moduleData.module_ver,
            target_std: "10",
            answer: answer,
            role_code: userRole,
        }
        if (userRole === 'PARENT') {
            payload = {
                ...payload, ...{ child_user_code: moduleData?.child_user_code ? moduleData?.child_user_code : '' }
            }
        }


        setLoading(true);
        CREATE_MODULE_ANSWERS(payload).then(res => {
            if (res.status === 'fail') {
                toast.error(res.message);
                setLoading(false);
            } else {
                setLoading(false);
                if (type === "submit") {
                    setCanShowPopup(true);
                }
            }
        });
    }

    const handleCompleteSubmit = () => {
        onSubmitAnswers("complete");
        moduleUpdate();
    }

    function moduleUpdate() {
        const payload = {
            id: moduleData.id,
            module_status: "completed"
        }
        setLoading(true);
        UPDATE_ASSIGN_USER(payload).then(res => {
            if (res.status === 'fail') {
                toast.error(res.message);
                setLoading(false);
            } else {
                setLoading(false);
                toast.success("Thank you for completing the survey.You can access Score sheet & Report now");
                history.push(`/student-dashboard/list/cas-success/${moduleData?.module_uuid}/${user_code}/${languagesCode}`);

                setCanShowPopup(true);
            }
        });
    }

    function handleLanguageChange(e: any) {
        const type = e.target.value;
        const data = languageQuestionsData;
        if (type == "en") {
            setQuestionsData(data.en ? data.en : []);
        } else if (type == "hi") {
            setQuestionsData(data.hi ? data.hi : []);
        } else if (type == "mr") {
            setQuestionsData(data.mr ? data.mr : []);
        } else if (type == "gu") {
            setQuestionsData(data.gu ? data.gu : []);
        } else {
            setQuestionsData([]);
        }
    }

    const handleBack = () => {
        history.goBack();
    }

    const handleclose = () => {
        setCanShowPopup(false);
        history.goBack();
    }

    const handleSave = () => {
        setCanShowPopup(false);
        history.goBack();
    }

    const handleCancel = () => {
        history.goBack();
    }

    return (
        <div>
            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading && <div>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb pb-2 ps-0 pt-0 border-bottom">
                        <li className="breadcrumb-item "><Link to="/student-dashboard/list"><span className='pe-2'>Dashboard</span></Link></li> /

                        <li className="breadcrumb-item "><span>CAS Test</span></li>

                    </ol>
                </nav>
                <div className="row question-block mt-4">
                    <div className="">
                        {questionsData?.map((question: any, i: number) => (
                            <div className="ps-md-3 mb-3" key={i}>
                                <h3>  {question.question_type === 'multiple_choice' && <span>{i}. </span>}
                                    {question.question}</h3>

                                <FormBuilder onUpdate={handleOptions}>
                                    <form ref={surveyForm}>
                                        {question.options.map((option: any, j: number) => (
                                            <div key={j} style={{ display: "inline-block" }}>
                                                {question.question_type === 'multiple_choice' &&
                                                    <div>
                                                        <input className="ms-3 my-3" type="radio" name="option" defaultChecked={parseInt(question?.selectedOption) === j} value={option} key={j} onChange={(e) => { handleQuestionOptions(e, i, j) }} />
                                                        <label className='ms-2'>{option}</label>
                                                    </div>
                                                }
                                            </div>
                                        ))}
                                        {question.question_type === 'textarea' && <textarea className="form-control " name="option" style={{ width: '50%' }} value={question.selectedOption} onChange={(e) => { handleQuestionOptions(e, i, -1) }}></textarea>}
                                        {question.question_type === 'input_text' && <input type="text" className="form-control " name="option" style={{ width: '50%' }} value={question.selectedOption} onChange={(e) => { handleQuestionOptions(e, i, -1) }} />}
                                    </form>
                                </FormBuilder>
                            </div>
                        ))}
                    </div>
                    {questionsData.length == 0 && <div style={{ textAlign: 'center' }}>
                        Not Found
                    </div>}
                </div>

                {questionsData.length > 0 && <div className="border-top py-3 text-center">
                    <p style={{ textAlign: 'center', marginLeft: '11rem' }}>
                        The report is based on the responses given by you. So, if you find some portions are not matching your thoughts or actions. Please review the answers you have given.
                    </p>
                    <button className="btn btn-primary mb-3 px-5" disabled={!canShowCompleteFormBtn} onClick={handleCompleteSubmit}>Submit Test</button>

                    <button type="submit" className="btn btn-link mb-3"><Link to="/student-dashboard/list">Cancel</Link></button>
                </div>}

            </div>}
        </div>
    )

}
export default CASTestList; 