import { Switch, Route } from "react-router";
import { SchooolSubscriptionReportList } from "./school-report-list";
import { StudentSubscriptionReportList } from "./student/list";


interface Props {
    match?: any;
    courseId: number;
}
const SubscriptionReportRoutes = (props: Props) => {

    let url: string | undefined = props.match?.url;
    
    if (url?.endsWith('/')) {
        url = url.substr(0, url.length - 1);
    }

    return (
        <Switch>
            <Route exact path={`${url}/school/list`} >
                <SchooolSubscriptionReportList></SchooolSubscriptionReportList>
            </Route>
            <Route exact path={`${url}/school/info/:id/studentlist`} >
                <StudentSubscriptionReportList></StudentSubscriptionReportList>
            </Route>
        </Switch>
    );
}
export default SubscriptionReportRoutes;