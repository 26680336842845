import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  DELETE_USER,
  GET_USER_BY_SCHOOL_CODE,
} from "../../../../app/service/users.service";
import { DataTable } from "../../../../components/data-table";
import { VicePrincipalDataGridCols } from "./data-grid-cols";

export const VicePrincipalList = () => {
  let { id, schoolCode } = useParams<{ id: string; schoolCode: string }>();
  const schoolId = parseInt(id);
  const schoolsCode = schoolCode;
  const [pageArray, setPageNumbers] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [vicePrincipalList, setVicePrincipalList] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [userRole, setUserRole] = useState<any>();
  const [accessObjectsList, setAccessObjectsList] = useState<any>([]);
  const [vicePrincipal, setVicePrincipal] = useState(false);
  const [apiloaded, setApiloaded] = useState<any>(false);
  const history = useHistory();

  useEffect(() => {
    const userRole = localStorage.getItem("user_role");
    setUserRole(userRole);

    if (schoolsCode) {
      setLoading(true);
      GET_USER_BY_SCHOOL_CODE(schoolsCode, "VICE_PRINCIPAL", 0, {}).then(
        (res: any) => {
          setApiloaded(true);
          if (res) {
            if (res.status === "fail") {
              toast.error(res.message);
              setLoading(false);
            } else {
              setLoading(false);
              const data = res.rows;
              if (data.length > 0) {
                setVicePrincipalList(data);
                const pageNumbers = Math.ceil(res.count / 10);
                const pageArray: any = [];
                for (let i = 1; i <= pageNumbers; i++) {
                  pageArray.push(i);
                }
                setPageNumbers(pageArray);
              }
            }
          }
        }
      );
    }

    const accessObjectsData: any = localStorage.getItem("access_objects_data");
    setAccessObjectsList(JSON.parse(accessObjectsData));
    handleSchoolsVicePrincipalCols(JSON.parse(accessObjectsData));
  }, [schoolsCode]);

  const onPageChange = (data: any) => {
    setActivePage(data);
  };

  const onEditVicePrincipal = (data: any) => {
    history.push(
      `/dashboard/school/info/${schoolId}/viceprincipalform/${schoolsCode}/${data.id}`
    );
    if (userRole === "CHAIRMAN" || userRole === "GENERAL_SECRETARY" || userRole === "INSTITUTE_ADMIN") {
      history.push(
        `/institute-dashboard/school/info/${schoolId}/viceprincipalform/${schoolsCode}/${data.id}`
      );
    }

  };

  const handleSchoolsVicePrincipalCols = (accessObjectsList: any) => {
    const preparedData = accessObjectsList?.filter(
      (el: any) => el.object_code === "SCHOOLS"
    );
    if (preparedData?.length > 0) {
      for (let i = 0; i < preparedData[0].child.length; i++) {
        for (let j = 0; j < VicePrincipalDataGridCols.length; j++) {
          if (
            preparedData[0].child[i].object_code === "SCHOOLS-VICE-PRINCIPAL" &&
            preparedData[0].child[i].p_read === 1
          ) {
            setVicePrincipal(true);
          }
          if (
            preparedData[0].child[i].object_code ===
            "SCHOOLS-VICE-PRICIPAL-EDIT" &&
            preparedData[0].child[i].p_read === 1
          ) {
            if (VicePrincipalDataGridCols[j].title == "Actions") {
              VicePrincipalDataGridCols[j].isEdit = true;
            }
          }

          if (
            preparedData[0].child[i].object_code ===
            "SCHOOLS-VICE-PRINCIPAL-SET-PASSWORD" &&
            preparedData[0].child[i].p_read === 1
          ) {
            if (VicePrincipalDataGridCols[j].title == "Actions") {
              VicePrincipalDataGridCols[j].setPassword = true;
            }
          }

          if (
            (preparedData[0].child[i].object_code ===
              "SCHOOLS-VICE-PRINCIPAL-SET-PASSWORD" &&
              preparedData[0].child[i].p_read === 1) ||
            (preparedData[0].child[i].object_code ===
              "SCHOOLS-VICE-PRICIPAL-EDIT" &&
              preparedData[0].child[i].p_read === 1)
          ) {
            if (VicePrincipalDataGridCols[j].title == "Actions") {
              VicePrincipalDataGridCols[j].canShowColumn = true;
            }
          }
        }
      }
    }

  };

  return (
    <div>
      {vicePrincipal &&
        <>
          {(vicePrincipalList.length === 0 && apiloaded) &&
            (userRole === "SUPER_ADMIN" ||
              userRole === "INSTITUTE_ADMIN" ||
              userRole === "SCHOOL_ADMIN") && (
              <div className="border-primary py-3 text-end">
                {userRole !== "CHAIRMAN" && userRole !== "GENERAL_SECRETARY" && userRole !== "INSTITUTE_ADMIN" && <Link
                  to={`/dashboard/school/info/${schoolId}/viceprincipalform/${schoolsCode}/0`}
                  className="btn btn-primary btn-sm px-5 rounded-12"
                >
                  New
                </Link>}

                {(userRole === "CHAIRMAN" || userRole === "GENERAL_SECRETARY" || userRole === "INSTITUTE_ADMIN") && <Link
                  to={`/institute-dashboard/school/info/${schoolId}/viceprincipalform/${schoolsCode}/0`}
                  className="btn btn-primary btn-sm px-5 rounded-12"
                >
                  New
                </Link>}
              </div>
            )}
          {loading && (
            <div className="text-center p-5">
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          )}
          {!loading && (
            <DataTable
              TableCols={VicePrincipalDataGridCols}
              tableData={vicePrincipalList}
              editInfo={onEditVicePrincipal}
              activePageNumber={activePage}
              pageNumber={onPageChange}
              pageNumbers={pageArray}
            ></DataTable>

          )}
        </>
      }
    </div>
  );
};
