import API from "../utility/axios";
const apiUrl = "/api/v2";

export const GET_STANDARD_LIST = (
  pageNumber: number,
  boardUUid: string,
  mediumUuid: string,
  data: any
): Promise<any> => {
  return API.get(
    `${apiUrl}/abhyaas/standards?limit=${10}&page=${pageNumber}&board_uuid=${boardUUid}&medium_uuid=${mediumUuid}`,
    {
      params: data,
    }
  );
};
export const GET_STANDARD_BY_UUID = (Uuid: string): Promise<any> => {
  return API.get(`${apiUrl}/abhyaas/standards?uuid=${Uuid}`);
};

export const UPDATE_STANDARD = (data: any, Uuid: string): Promise<any> => {
  return API.post(`${apiUrl}/abhyaas/standards/${Uuid}`, data);
};

export const CREATE_STANDARD = (data: any): Promise<any> => {
  return API.post(`${apiUrl}/abhyaas/standards`, data);
};

export const GET_STANDARD_FILTER = (
  data: any,
  boardUUid: string,
  mediumUuid: string
): Promise<any> => {
  return API.get(
    `${apiUrl}/abhyaas/standards?limit=${10}&page=${0}&board_uuid=${boardUUid}&medium_uuid=${mediumUuid}`,
    {
      params: data,
    }
  );
};
export const DELETE_STANDARD = (id: number): Promise<any> => {
  return API.delete(`${apiUrl}/abhyaas/standards/${id}`);
};
export const GET_STANDARD_LIST_BY_MEDIUM = (data: any): Promise<any> => {
  return API.get(`${apiUrl}/abhyaas/standards`, {
    params: data,
  });
};
