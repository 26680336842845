import React, { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { DataTable } from "../../../../../components/data-table";
import { FormControlError, FormField, FormValidators } from "../../../../../components/form-builder/model/form-field";
import { toast } from "react-toastify";
import { FormValidator } from "../../../../../components/form-builder/validations";
import FormBuilder from "../../../../../components/form-builder";
import { Breadcum } from "../../../../../components/breadcum";
import moment from "moment";
import { CREATE_ABHYAS_TEST, DELETE_ABHYAS_TEST, GET_ABHYAS_TEST_BY_UUID, GET_ABHYAS_TEST_FILTER, GET_ABHYAS_TEST_LIST, UPDATE_ABHYAS_TEST } from "../../../../../app/service/anhyas-test.service";
import { AbhyasTestDataGridCols } from "./data-grid-cols";
import { MathJaxContext, MathJax } from "better-react-mathjax";


export const AbhyasTestDataList = () => {
    const [breadCrumLoading, setBreadCrumLoading] = useState<boolean>(false);
    const [testList, setTestList] = useState<any>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [pageArray, setPageNumbers] = useState([]);
    const [activePage, setActivePage] = useState(0);
    const [canShowPopup, setCanShowPopup] = useState<boolean>(false);
    const [testUuid, setTestUuid] = useState<string>("0");
    const [formLoading, setFormLoading] = useState<boolean>(false);
    const [currentFormData, setCurrentFormData] = useState<any>({});
    const [formData, setFormData] = useState<any>([]);
    const [formValidationErrors, setFormValidationErrors] = useState<FormControlError[]>([]);
    const medimForm = useRef<any>({});
    const searchForm = useRef<any>({});
    const [searchData, setSearchData] = useState<any>({});
    const [typeError, setTypeError] = useState<boolean>(false);
    const [levelError, setLevelError] = useState<boolean>(false);
    const [numberError, setNumberError] = useState<string>("");
    const [showQuestionPopUp, setShowQuestionPopUp] = useState<boolean>(false);
    const [questionsList, setQuestionsList] = useState<any>([]);
    const [loadingWorksheetQuestions, setLoadingWorksheetQuestions] = useState(false);
    let { code, id, BoardUuid, BoardName, MediumUuid, MediumName, StandardUuid, StandardName, SubjectUuid, SubjectName } = useParams<{ code: string, id: string, BoardUuid: string, BoardName: string, MediumUuid: string, MediumName: string, StandardUuid: string, StandardName: string, SubjectUuid: string, SubjectName: string }>();
    const moduleCode = code;
    const moduleId = Number(id);
    const boardUuid = BoardUuid;
    const mediumUuid = MediumUuid;
    const standardUuid = StandardUuid;
    const subjectUuid = SubjectUuid;
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const FormValidations = [
        new FormField('type', [FormValidators.REQUIRED]),
        new FormField('level', [FormValidators.REQUIRED]),
    ];
    const history = useHistory();
    const [preparedSearchData, setPreparedSearchData] = useState<any>({
        mode: moduleCode,
        sorting_field: "number",
        sorting_type: "asc",
    });
    const [breadcumInfo, setBreadcumInfo] = useState<any>([
        {
            label: 'Board',
            value: decodeURIComponent(BoardName),
            routerLink: `/dashboard/module/info/ABHYAS/${moduleCode}/${moduleId}/worksheet`,
            subName: "(board)",
            isActive: false
        },
        {
            label: 'Medium',
            value: decodeURIComponent(MediumName),
            routerLink: `/dashboard/module/info/ABHYAS/${moduleCode}/${moduleId}/${boardUuid}/${BoardName}/medium`,
            subName: "(Medium)",
            isActive: false
        },
        {
            label: 'standard',
            value: decodeURIComponent(StandardName),
            routerLink: `/dashboard/module/info/ABHYAS/${moduleCode}/${moduleId}/${boardUuid}/${BoardName}/${MediumUuid}/${MediumName}/standard`,
            subName: "(standard)",
            isActive: false
        },
        {
            label: 'subject',
            value: decodeURIComponent(SubjectName),
            routerLink: `/dashboard/module/info/ABHYAS/${moduleCode}/${moduleId}/${boardUuid}/${BoardName}/${MediumUuid}/${MediumName}/${standardUuid}/${StandardName}/subject`,
            subName: "(subject)",
            isActive: false
        },
        // {
        //     label: 'chapter',
        //     value: ChapterName,
        //     routerLink: `/dashboard/module/info/ABHYAS/${moduleCode}/${moduleId}/${boardUuid}/${BoardName}/${MediumUuid}/${MediumName}/${standardUuid}/${StandardName}/${subjectUuid}/${SubjectName}/chapter`,
        //     subName: "(chapter)",
        //     isActive: false
        // },
        // {
        //     label: 'topic',
        //     value: TopicName,
        //     routerLink: `/dashboard/module/info/ABHYAS/${moduleCode}/${moduleId}/${boardUuid}/${BoardName}/${MediumUuid}/${MediumName}/${standardUuid}/${StandardName}/${subjectUuid}/${SubjectName}/${chapterUuid}/${ChapterName}/topic`,
        //     subName: "(topic)",
        //     isActive: false
        // },
        {
            label: 'Tests',
            value: 'Tests',
            nameHighlight: true,
            isActive: true
        }
    ]);

    useEffect(() => {

        if (moduleCode === "PARIKSHA") {
            const updatedBreadcumInfo = breadcumInfo.filter((item: any) => item.label !== 'topic');
            setBreadcumInfo(updatedBreadcumInfo);
        }
        getWorksheetData();

    }, [])


    const getWorksheetData = () => {
        setLoading(true);

        GET_ABHYAS_TEST_LIST(activePage == 0 ? activePage : activePage - 1, boardUuid, mediumUuid, standardUuid, subjectUuid, "TESTS", preparedSearchData).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res?.err);
                    setLoading(false);

                } else {
                    const data = res?.rows;
                    data?.forEach((element: any) => {
                        element.created_dt = moment(element?.created_dt?.split("T")[0]).format('DD-MM-yyyy');
                        if (element?.modified_dt) {
                            element.modified_dt = moment(element?.modified_dt?.split("T")[0]).format('DD-MM-yyyy');
                        }
                    });
                    setTestList(data);
                    const pageNumbers = Math.ceil(res.count / 10);
                    const pageArray: any = [];
                    for (let i = 1; i <= pageNumbers; i++) {
                        pageArray.push(i);
                    }
                    setPageNumbers(pageArray);
                    setLoading(false);

                }
            }
        });
    }


    const onPageChange = (data: any) => {
        setActivePage(data);
        const pageNumber = data - 1;
        setLoading(true);
        GET_ABHYAS_TEST_LIST(pageNumber, boardUuid, mediumUuid, standardUuid, subjectUuid, "TESTS", preparedSearchData ? preparedSearchData : {}).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res?.err);
                    setLoading(false);
                } else {
                    const data = res.rows;
                    data?.forEach((element: any) => {
                        element.created_dt = moment(element?.created_dt?.split("T")[0]).format('DD-MM-yyyy');
                        if (element?.modified_dt) {
                            element.modified_dt = moment(element?.modified_dt?.split("T")[0]).format('DD-MM-yyyy');
                        }
                    });
                    setTestList(data);
                    setLoading(false);
                }
            }
        });
    }
    const onDelete = (data: any) => {
        setLoading(true);
        const id = data.uuid;
        DELETE_ABHYAS_TEST(id).then((res: any) => {
            if (res.status === "fail") {
                toast.error(res?.err);
                setLoading(false);
            } else {
                setLoading(false);
                toast.success(res.message);
                getWorksheetData();
            }
        });

    }
    const onEdit = (data: any) => {
        setTestUuid(data?.uuid);
        setFormLoading(false);
        setNumberError("");
        setTypeError(false);
        setCanShowPopup(true);
        if (data?.uuid) {
            setFormLoading(true);
            GET_ABHYAS_TEST_BY_UUID(data?.uuid).then((res: any) => {
                if (res.status === 'fail') {
                    toast.error(res?.err);
                    setFormLoading(false);

                } else {
                    if (res.rows.length > 0) {
                        setCurrentFormData(res.rows[0]);
                        setFormLoading(false);

                    }
                }
            });
        }
    }
    const handleClose = () => {
        setFormData({});
        setCurrentFormData({});
        setCanShowPopup(false);
        setShowQuestionPopUp(false);

    }
    const addTest = (data: any) => {
        setNumberError("");
        setFormLoading(false);
        setTypeError(false);
        setLevelError(false);
        setCanShowPopup(true);
        setTestUuid(data);
        const datafrom = {
            level: '',
            type: "",
            number: '',
            name: ''
        };
        setFormData({ ...datafrom });
        setCurrentFormData({ ...datafrom });
        medimForm?.current?.reset();
    }
    const handleFormInput = (data: any) => {
        data.value = { ...currentFormData, ...data.value };
        setFormData(data);
        const errors: any = FormValidator(FormValidations, data.value);
        setFormValidationErrors(errors);
    };



    const handleTypeChange = (e: any) => {
        const data = { ...formData.value };
        data.type = e.target.value;
        setLevelError(false);
        if (e.target.value) {
            setTypeError(false);
        }
        if (currentFormData) {
            currentFormData.type = e.target.value;
        }
    }
    const handleLevelChange = (e: any) => {
        const data = { ...formData.value };
        data.level = e.target.value;
        if (e.target.value) {
            setLevelError(false);
        }
        if (currentFormData) {
            currentFormData.level = e.target.value;
        }
    }
    const createTest = () => {
        const formsData = formData.value ? { ...formData.value } : { ...currentFormData };
        setIsFormSubmitted(true);
        const errors: FormControlError[] = FormValidator(FormValidations, formsData);
        setFormValidationErrors(errors);
        Object.keys(formsData).forEach(function (key) {
            if (typeof formsData[key] === 'string') {
                formsData[key] = formsData[key].trim();
            }
        });
        const payLoad = {
            board_uuid: boardUuid,
            medium_uuid: mediumUuid,
            standard_uuid: standardUuid,
            subject_uuid: subjectUuid,
            chapter_uuid: "",
            topic_uuid: "",
            uuid: "",
            mode: moduleCode,
            type: "TESTS",
            exam_type: formsData.exam_type ? formsData.exam_type : "",
            level: " ",
            number: formsData?.number,
            name: formsData?.name,

        }
        if (!numberError && formsData?.number) {
            setFormLoading(true);
            CREATE_ABHYAS_TEST(payLoad).then((res: any) => {
                if (res.status === 'fail') {
                    const errorValue = Array.isArray(res?.err) ? res?.err[0] : res?.err;
                    toast.error(errorValue);
                    setFormLoading(false);
                } else {
                    toast.success(res.message);
                    getWorksheetData();
                    setFormLoading(false);
                    setCanShowPopup(false);
                    setFormData({});
                    setCurrentFormData({});
                }
            });
        }
        else {

            if (!formsData?.number) {
                setNumberError("Please enter test number");

            } else {
                setNumberError(numberError);
            }
        }

    }
    const updateTest = () => {
        const formsData = formData.value ? { ...formData.value } : { ...currentFormData };
        setIsFormSubmitted(true);
        const errors: FormControlError[] = FormValidator(FormValidations, formsData);
        const payLoad = {
            uuid: testUuid,
            board_uuid: boardUuid,
            medium_uuid: mediumUuid,
            standard_uuid: standardUuid,
            subject_uuid: subjectUuid,
            chapter_uuid: "",
            topic_uuid: "",
            mode: moduleCode,
            type: "TESTS",
            exam_type: formsData.exam_type ? formsData.exam_type : "",
            level: " ",
            number: formsData?.number,
            name: formsData?.name,
        }
        setFormValidationErrors(errors);
        if (!numberError && formsData?.number) {
            setFormLoading(true);
            UPDATE_ABHYAS_TEST(payLoad).then((res: any) => {
                if (res.status === 'fail') {
                    const errorValue = Array.isArray(res?.err) ? res?.err[0] : res?.err;
                    toast.error(errorValue);
                    setFormLoading(false);
                } else {
                    toast.success(res.message);
                    getWorksheetData();
                    setFormLoading(false);
                    setCanShowPopup(false);
                    setFormData({});
                    setCurrentFormData({});
                }
            });
        }
        else {
            if (!formsData?.number) {
                setNumberError("Please enter test number");

            } else {
                setNumberError(numberError);
            }
        }
    }
    const handleSearchInput = (data: any) => {
        setSearchData(data);
    };
    function handleSearch() {
        const data = { ...searchData.value };
        if (data?.search) {
            setLoading(true);
            preparedSearchData.search = data?.search;
            GET_ABHYAS_TEST_FILTER(preparedSearchData, boardUuid, mediumUuid, standardUuid, subjectUuid, "TESTS").then((res: any) => {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    const data = res.rows;
                    data?.forEach((element: any) => {
                        element.created_dt = moment(element?.created_dt?.split("T")[0]).format('DD-MM-yyyy');
                        if (element?.modified_dt) {
                            element.modified_dt = moment(element?.modified_dt?.split("T")[0]).format('DD-MM-yyyy');
                        }
                    });
                    setActivePage(0);
                    setTestList(data);
                    const pageNumbers = Math.ceil(res.count / 10);
                    const pageArray: any = [];
                    for (let i = 1; i <= pageNumbers; i++) {
                        pageArray.push(i);
                    }
                    setPageNumbers(pageArray);
                    setLoading(false);
                }
            });
        }
    }
    function handleRefresh() {
        const data = searchData;
        data.value = {};
        setSearchData({ ...data });
        preparedSearchData.search = "";
        setPreparedSearchData(preparedSearchData);
        getWorksheetData();
        searchForm.current.reset();
    }
    const onActions = (data: any) => {
        if (data.type === "link") {
            history.push(
                `/dashboard/module/info/ABHYAS/${moduleCode}/${moduleId}/${boardUuid}/${BoardName}/${MediumUuid}/${MediumName}/${standardUuid}/${StandardName}/${subjectUuid}/${SubjectName}/${data?.data?.uuid}/${data?.data?.number}/${data?.data?.type}/abhyas-test-question`
            );

        }
    };
    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleSearch();
        }
    };
    function onViewQuestion(name: any) {
        return { __html: name };
    }
    const viewQuestion = (data: any) => {
        setShowQuestionPopUp(true);
        setLoadingWorksheetQuestions(true);
        let preparedData = JSON.parse(data?.data)
        setQuestionsList(preparedData);
        setLoadingWorksheetQuestions(false);
    }
    const handleExamType = (e: any) => {
        const data = { ...formData.value };
        data.exam_type = e.target.value;
        setTypeError(false);
        if (e.target.value) {
            setTypeError(false);
        }
        if (currentFormData) {
            currentFormData.exam_type = e.target.value;
        }
    }
    const handleTestNumber = (value: any) => {
        const data = { ...formData.value };
        data.number = value;
        const re = /^[0-9\b]+$/;
        if (value) {
            if (re.test(value)) {
                if (value.length <= 7) {
                    setNumberError("");

                } else {
                    setNumberError("Upto 7 digits only")
                }
            }
            else {
                setNumberError("Please enter only numbers ")
            }
        } else {
            setNumberError("Please enter test number ")
        }
        if (currentFormData) {
            currentFormData.number = value;
        }
    }
    return (
        <div>
            <div
                className={`modal fade ${showQuestionPopUp ? "show" : "false"}`}
                style={{ display: showQuestionPopUp ? "block" : "none" }}
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog worksheet-quesions-dialog" role="document">
                    <div className="modal-content worksheet-questions-model">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                                Questions List
                            </h5>
                            <button
                                type="button"
                                className="close popup-close cursor-pointer"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={handleClose}
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body parent-info parent-model ">
                            {loadingWorksheetQuestions &&
                                <div className="text-center p-5">
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div>
                            }
                            {!loadingWorksheetQuestions && <div>
                                <MathJaxContext>
                                    <MathJax>
                                        {questionsList?.map((question: any, i: number) => (
                                            <div className="mt-4" key={i}>
                                                <div className='row col-md-12'>
                                                    <div className='row'>
                                                        <div className='col-md-1' style={{ alignSelf: 'center' }}>
                                                            <span className='fw-bold' style={{ fontSize: '22px' }}>Q{question.id}:</span>
                                                        </div>
                                                        <div className='col-md-11' style={{ paddingTop: '15px' }}>
                                                            <span className='fw-bold' style={{ fontSize: '22px' }}>
                                                                {/* <MathJaxContext> */}
                                                                {/* <MathJax> */}
                                                                <div dangerouslySetInnerHTML={onViewQuestion(question.question)}></div>
                                                                {/* </MathJax> */}
                                                                {/* </MathJaxContext> */}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    {question.options.map((options: any, j: number) => (
                                                        <div className='row' key={j}>
                                                            <div className='col-md-1'>
                                                                <span>{options.option}.</span>
                                                            </div>
                                                            <div className='col-md-6'>
                                                                {/* <MathJaxContext> */}
                                                                {/* <MathJax> */}
                                                                <span dangerouslySetInnerHTML={onViewQuestion(options.value)}></span>
                                                                {/* </MathJax> */}
                                                                {/* </MathJaxContext> */}
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        ))}
                                        {questionsList.length == 0 && <h6 className="text-center mt-3">No Data Found</h6>}

                                    </MathJax>
                                </MathJaxContext>
                            </div>}
                        </div>
                        <div className="modal-footer">

                            <button
                                type="button"
                                className="btn btn-secondary cursor-pointer btn-sm px-4 rounded-12 cursor-pointer py-2"
                                data-dismiss="modal"
                                onClick={handleClose}
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className={`modal fade ${canShowPopup ? "show" : "false"}`}
                style={{ display: canShowPopup ? "block" : "none" }}
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog " role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                                {testUuid === "0" ?
                                    (<span>Add Test</span>)
                                    : (<span> Update Test</span>)
                                }
                            </h5>

                            <button
                                type="button"
                                className="close popup-close cursor-pointer"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={handleClose}
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body parent-info ">
                            {formLoading && (
                                <div className="text-center p-5">
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div>
                            )}
                            {!formLoading && (
                                <>
                                    <FormBuilder onUpdate={handleFormInput}>
                                        <form ref={medimForm}>
                                            <div className="row custom-form">
                                                <div className="col-md-12 ">
                                                    <div className="mb-4">
                                                        <label className="form-label mb-1">Test Number <sup className="text-danger f14">*</sup></label>
                                                        <input className="form-control form-control-lg f18" type="text" name="number" defaultValue={currentFormData?.number} placeholder="Please enter  test number" onChange={(e) => { handleTestNumber(e.target.value) }} />
                                                        {numberError && <p className="text-danger">{numberError}</p>}
                                                    </div>
                                                </div>
                                                {moduleCode === "PARIKSHA" && <div className="col-md-12">
                                                    <div className="mb-4">
                                                        <label className="form-label ">Exam Type  <sup className="text-danger f14">*</sup></label>
                                                        <select name="exam_type" id="exam_type" className="form-select f18" onChange={(e) => { handleExamType(e) }} value={currentFormData?.exam_type} >
                                                            <option value="">Select Type</option>
                                                            <option value="UNIT_TESTS">UNIT TESTS</option>
                                                            <option value="QUARTERLY">QUARTERLY</option>
                                                            <option value="ANNUALLY">ANNUALLY</option>
                                                        </select>
                                                        {typeError && <p className="text-danger">Please select Type</p>}
                                                    </div>
                                                </div>}
                                                <div className="col-md-12 ">
                                                    <div className="mb-4">
                                                        <label className="form-label mb-1">Name</label>
                                                        <input className="form-control form-control-lg f18" type="text" name="name" defaultValue={currentFormData?.name} placeholder="Please enter  test name" />
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </FormBuilder>
                                </>
                            )}
                        </div>
                        <div className="modal-footer">
                            {testUuid === "0" && < a className="btn btn-primary btn-sm px-4 rounded-12 cursor-pointer py-2" onClick={() => createTest()}>Add </a>}
                            {testUuid !== "0" && <a className="btn btn-primary btn-sm px-4 rounded-12 cursor-pointer py-2" onClick={() => updateTest()}>Update</a>}
                            <button
                                type="button"
                                className="btn btn-secondary cursor-pointer btn-sm px-4 rounded-12 cursor-pointer py-2"
                                data-dismiss="modal"
                                onClick={handleClose}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`mt-3`}>
                <Breadcum breadcumList={breadcumInfo} margin={"classic"} />
            </div>

            <h5>Tests</h5>
            <div className="row  " style={{ marginBottom: '15px' }}>
                <div className="col-md-4">
                    <FormBuilder onUpdate={handleSearchInput}>
                        <form ref={searchForm}>
                            <input type="text" id="search" className="form-control ft-14" placeholder="search" name="search" onKeyDown={(e) => handleKeyDown(e)} />
                        </form>
                    </FormBuilder>
                </div>
                <div className="col-md-4 pt-3 pt-md-0 ">
                    <button className="btn btn-primary px-3 cursor-pointer" type="button" onClick={() => handleSearch()}>Search</button>&nbsp;
                    <button className="btn clear-btn cursor-pointer" type="button" onClick={() => handleRefresh()}>
                        Clear
                    </button>
                </div>
                <div className="col-md-4 pt-3 pt-md-0 text-end ">
                    <p className="mb-0"><button className="btn btn-primary" onClick={() => addTest("0")}>Add Test</button></p>
                </div>
            </div>
            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading &&
                <div className="mt-3">
                    <DataTable TableCols={AbhyasTestDataGridCols} tableData={testList}
                        onActions={(e) => onActions(e)}
                        onChange={viewQuestion}
                        editInfo={onEdit}
                        deleteInfo={onDelete}
                        pageNumber={onPageChange} activePageNumber={activePage} pageNumbers={pageArray}></DataTable>
                </div>}

        </div>
    )
}

