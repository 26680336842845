const errorMessages = {
    email: 'Enter proper email address',
    mobile_number: 'Length of mobile number is 10 digits, Enter proper value',
    whatsapp_number: 'Length of whatsapp number is 10 digits, Enter proper value',
    pincode: 'Length of pincode is 6 digits, Enter proper value',
    date_of_birth: 'Invalid Date, Age Should be more than 18',
    student_date_of_birth: 'Invalid Date, Age Should be more than 10',
    bank_name: 'Enter Only Alphabets ',
    branch_name: 'Enter Only Alphabets ',
    branch_account_number: 'Enter only numeric values ',
    ifsc_code: 'The length of IFSC code is 11 digits, Enter a proper value',
    upload_photo: 'Image Size should be between 20 kb to 50 kb,Maximum Dimentions : 200px *230 px,Valid file type .jpg, .jpeg',
    pancard: 'PAN number is 10 characters long, Enter proper value',
    UDISE_number: 'The length of UDISE code is 11 digits, Enter a proper value',
    number_of_primary_school: 'Please enter 0 or any positive number',
    number_of_secondary_school: 'Please enter 0 or any positive number',
    number_of_junior_colleges: 'Please enter 0 or any positive number',
    number_of_senior_colleges: 'Please enter 0 or any positive number',
    number_of_professional_colleges: 'Please enter 0 or any positive number',
    driving_license_number: 'The length of the DL number is 16 digits, Enter a proper value',
    fax_number: 'The length of the FAX number is 10 digits, Enter a proper value',
    contact_number: 'Enter only numeric values ',
    landline_number: 'Length of Landline number is 11 digits, Enter proper value '

};
export default errorMessages;



