import React, { useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import { CREATE_SCHOOL_USER, UPLOAD_LOGO } from '../../../app/service/school.service';
import FormBuilder from '../../../components/form-builder'
import { FormControlError, FormField, FormValidators } from '../../../components/form-builder/model/form-field';
import { FormValidator, GetControlIsValid } from '../../../components/form-builder/validations';
import { ToastContainer, toast } from 'react-toastify';
import moment from 'moment';
import appConfig from '../../../config/constant';
import errorMessages from '../../../components/error-messages';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { DateComponent } from '../../../components/date';

interface Props {
    instituteIdNumber: number;
    generalSecretaryName: (value: any) => void;
}


export const GeneralSecretary = (props: Props) => {
    const [generalSecretaryErrors, setGeneralSecretaryErrors] = useState<FormControlError[]>([]);
    const [generalSecretaryData, setGeneralSecretaryData] = useState<any>({});
    const [generalSecretaryInfo, setGeneralSecretaryInfo] = useState<any>({});
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [generalSecretaryLogo, setGeneralSecretaryLogo] = useState<any>();
    const [canShowPopup, setCanShowPopup] = useState(false);
    const [confirmPopup, setConfirmPopup] = useState(false);
    const [loading, setLoading] = useState(false);
    const [imageLoader, setImageLoader] = useState(false);
    const [currentGeneralSecretaryData, setCurrentGeneralSecretaryData] = useState<any>({});
    const [email, setEmail] = useState(false);
    const [mobileNumber, setMobileNumber] = useState(false);
    const [whatsappNumber, setWhatsappNumber] = useState(false);
    const [canCheckMobileNumber, setCanCheckMobileNumber] = useState(false);
    const [canShowWhatsAppError, setCanShowWhatsAppError] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [nameError, setNameError] = useState('');
    const [isDateError, setIsDateError] = useState<any>({});
    let { id } = useParams<{ id: string }>();
    const instituteIdNumber = id;
    const generalSecretaryFormValidations = [
        new FormField('name', [FormValidators.REQUIRED]),
        new FormField('gender', [FormValidators.REQUIRED]),
        new FormField('date_of_birth', []),
        new FormField('blood_group', []),
        new FormField('city_village', []),
        new FormField('email_id', [FormValidators.REQUIRED]),
        new FormField('mobile_number', [FormValidators.REQUIRED]),
        new FormField('whatsapp_number', []),
    ];

    const getGeneralSecretaryInputValid = (control: string) => {
        const value = GetControlIsValid(generalSecretaryErrors, control);
        return value;
    }

    const handleVicePrincipalInput = (data: any) => {
        data.value = { ...currentGeneralSecretaryData, ...data.value };
        setGeneralSecretaryData(data);
        const errors: any = FormValidator(generalSecretaryFormValidations, data.value);
        setGeneralSecretaryErrors(errors);
    };

    function handleUploadLogo(e: any, type: string) {
        if (e.target.files && e.target.files[0]) {
            const fileType = e.target.files[0].name.split('.').pop()
            if (fileType == "jpeg" || fileType == "jpg") {
                const formData = new FormData();
                formData.append('file', e.target.files[0], e.target.files[0].name);
                uploadLogo(formData, type);
            } else {
                toast.error("Valid file type .jpg, .jpeg only");
            }
        }
    }

    function uploadLogo(formdata: any, type: string) {
        setImageLoader(true);
        UPLOAD_LOGO(formdata).then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res.message);
                setImageLoader(false);
            } else {
                setGeneralSecretaryLogo(res.url);
                setImageLoader(false);
            }
        });
    }

    function createGeneralSecretary() {
        const generalSecretarysData = generalSecretaryData.value ? { ...generalSecretaryData.value } : { ...currentGeneralSecretaryData };
        setIsFormSubmitted(true);
        const errors: FormControlError[] = FormValidator(generalSecretaryFormValidations, generalSecretarysData);
        setGeneralSecretaryErrors(errors);
        generalSecretarysData.type = "institute";
        generalSecretarysData.user_role = "GENERAL_SECRETARY";
        generalSecretarysData.institute_school_code = instituteIdNumber;
        generalSecretarysData.profile_img = generalSecretaryLogo;
        generalSecretarysData.date_of_birth = startDate ? moment(startDate).format('YYYY-MM-DD') : null;
        delete generalSecretarysData.sameMobileNumber;
        delete generalSecretarysData.file;
        delete generalSecretarysData.checked;
        delete generalSecretarysData[''];
        delete generalSecretarysData.day;
        delete generalSecretarysData.month;
        delete generalSecretarysData.year;
        if (errors.length < 1 && !email && !mobileNumber && onChangeName(generalSecretarysData.name)) {
            generalSecretarysData.whatsapp_number = generalSecretarysData.whatsapp_number ? generalSecretarysData.whatsapp_number : generalSecretarysData.mobile_number;
            setGeneralSecretaryData(generalSecretarysData);
            setConfirmPopup(true);
        }
        else {

            if (!nameError && !onChangeName(generalSecretarysData.name)) {
                setNameError('Please enter name');
            }
        }
    }

    function onCreateGeneralSecretary() {
        setLoading(true);
        const data = generalSecretaryData;
        CREATE_SCHOOL_USER(data).then((res: any) => {
            if (res.status === 'fail') {
                setConfirmPopup(false);
                toast.error(res.message);
                setLoading(false);
                setCurrentGeneralSecretaryData(data);
            } else {
                setConfirmPopup(false);
                setCanShowPopup(true);
                setGeneralSecretaryInfo(res);
                toast.success("General Secretary Created Successfully");
                setLoading(false);
            }
        });
    }

    const handleclose = () => {
        setCanShowPopup(false);
        props.generalSecretaryName("madhu");
    }

    const handleConfirmclose = () => {
        setConfirmPopup(false);
        setCurrentGeneralSecretaryData(generalSecretaryData);
    }

    const handleSave = () => {
        onCreateGeneralSecretary();
    }

    const handleBloodGroupChange = (e: any) => {
        const data = { ...generalSecretaryData.value };
        data.blood_group = e.target.value;
        if (currentGeneralSecretaryData) {
            currentGeneralSecretaryData.blood_group = e.target.value;
        }
    }


    const handleMobileChange = (e: any) => {
        const data = { ...generalSecretaryData.value };
        const re = /(6|7|8|9)\d{9}/;

        if ((e.target.value === '' || re.test(e.target.value)) && e.target.value.length === 10) {
            data.mobile_number = e.target.value;
            setMobileNumber(false);
        } else {
            data.mobile_number = e.target.value;
            setMobileNumber(true);
        }

        if (currentGeneralSecretaryData) {
            currentGeneralSecretaryData.mobile_number = e.target.value.replace(/\D+/g, '');
        }
        if (currentGeneralSecretaryData.checked) {
            currentGeneralSecretaryData.whatsapp_number = e.target.value.replace(/\D+/g, '');
        }
    }

    const handleWhatsappChange = (e: any) => {
        const data = { ...generalSecretaryData.value };
        const re = /(6|7|8|9)\d{9}/;

        if ((e.target.value === '' || re.test(e.target.value)) && e.target.value.length === 10) {
            data.whatsapp_number = e.target.value;
            setWhatsappNumber(false);
            setCanShowWhatsAppError(false);
        } else {
            data.whatsapp_number = e.target.value;
            setWhatsappNumber(true);
        }

        if (currentGeneralSecretaryData) {
            currentGeneralSecretaryData.whatsapp_number = e.target.value.replace(/\D+/g, '');
        }
    }

    const handleEmailChange = (e: any) => {
        const data = { ...generalSecretaryData.value };
        // const re = /[a-z0-9\._%+!$&*=^|~#%'`?{}/\-]+@([a-z0-9\-]+\.){1,}([a-z]{2,16})/;
        const re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,6})+$/;

        if (e.target.value === '' || re.test(e.target.value)) {
            data.email_id = e.target.value;
            setEmail(false);
        } else {
            data.email_id = e.target.value;
            setEmail(true);
        }

        if (currentGeneralSecretaryData) {
            currentGeneralSecretaryData.email_id = e.target.value;
        }
    }

    const handleMobileNumberChange = (e: any) => {
        const data = { ...generalSecretaryData.value };
        if (e.target.checked) {
            if (data.whatsapp_number) {
                data.whatsapp_number = data.mobile_number;
                currentGeneralSecretaryData.whatsapp_number = data.mobile_number;
                setCanCheckMobileNumber(true);
                setCanShowWhatsAppError(false);
            }
            if (data.mobile_number || currentGeneralSecretaryData.mobile_number) {
                setCanCheckMobileNumber(true);
                setCanShowWhatsAppError(false);
                currentGeneralSecretaryData.whatsapp_number = currentGeneralSecretaryData.mobile_number;
                setCurrentGeneralSecretaryData(currentGeneralSecretaryData);
            }
            currentGeneralSecretaryData.checked = true;
        } else {
            setCanCheckMobileNumber(false);
            currentGeneralSecretaryData.checked = false;
        }
    }

    const onChange = (dates: any) => {
        setStartDate(null);
        if (dates.error) {
            setIsDateError(dates);
        } else {
            setStartDate(dates.date);
        }
    };

    function addMonths(theDate: any, years: any) {
        return new Date(theDate.getTime() - years * 24 * 60 * 60 * 1000);
    }

    function subMonths(theDate: any, years: any) {
        return new Date(theDate.getTime() - years * 24 * 60 * 60 * 1000);
    }

    const handleGender = (e: any) => {
        const data = { ...generalSecretaryData.value };
        data.gender = e.target.value;
        if (currentGeneralSecretaryData) {
            currentGeneralSecretaryData.gender = e.target.value;
        }
    }

    const onChangeName = (event: any) => {
        setNameError('');
        const reg = /^[A-Za-z]+(?:[ _-][A-Za-z]+)*$/;
        if (event) {
            if (!reg.test(event.trim())) {
                setNameError('Enter only alphabets');
            } else {
                return true;
            }
        } else {
            setNameError('Please enter name');
        }
    }

    return (
        <div>
            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            <div className={`modal fade ${confirmPopup ? 'show' : 'false'}`} style={{ display: (confirmPopup ? "block" : 'none') }} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Alert</h5>
                            <button type="button" className="close popup-close cursor-pointer" data-dismiss="modal" aria-label="Close" onClick={handleConfirmclose}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <p>
                                “Kindly recheck details on the form. Once saved, they cannot be changed later”
                            </p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary cursor-pointer" data-dismiss="modal" onClick={handleConfirmclose}>Back</button>
                            <button type="button" className="btn btn-primary" onClick={handleSave}>Save</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`modal fade ${canShowPopup ? 'show' : 'false'}`} style={{ display: (canShowPopup ? "block" : 'none') }} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">General Secretary Login Details</h5>
                            <button type="button" className="close popup-close cursor-pointer" data-dismiss="modal" aria-label="Close" onClick={handleclose}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div>Login ID: {generalSecretaryInfo.user_name}</div>
                            <div>Password: {generalSecretaryInfo.password}</div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary cursor-pointer" data-dismiss="modal" onClick={handleclose}>Close</button>
                        </div>
                    </div>
                </div>
            </div>
            {!loading && <div>
                <FormBuilder onUpdate={handleVicePrincipalInput}>
                    <form>
                        <div className="row custom-form">
                            <div className="row" style={{ paddingLeft: '15px', fontWeight: 'bold', fontSize: '18px' }}>
                                General Secretary
                            </div>
                            <hr />
                            <div className="col-md-4">
                                <div className="mb-4">
                                    <label className="form-label mb-0">Mrs./ Mr
                                        <span style={{ paddingLeft: '5px' }}>Name</span></label>
                                    <span style={{ color: 'red', fontSize: '22px', paddingLeft: '5px' }}>*</span>
                                    <input className="form-control form-control-lg" type="text" name="name" onChange={(e) => onChangeName(e.target.value)} defaultValue={currentGeneralSecretaryData.name} placeholder="Please enter name" />
                                    {nameError && <p className="text-danger">{nameError}</p>}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="mb-4">
                                    <label className="form-label mb-4">Gender</label>
                                    <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>*</span>
                                    <br />
                                    <input className="mb-0" type="radio" value="male" name="gender" checked={currentGeneralSecretaryData?.gender === "male"} onChange={(e) => { handleGender(e) }} />
                                    <span style={{ paddingRight: '15px', paddingLeft: '15px' }}>Male</span>
                                    &nbsp;
                                    <input className="mb-0" type="radio" value="female" name="gender" checked={currentGeneralSecretaryData?.gender === "female"} onChange={(e) => { handleGender(e) }} />
                                    <span style={{ paddingRight: '15px', paddingLeft: '15px' }}>Female</span>

                                    <input className="mb-0" type="radio" value="transgender" name="gender" checked={currentGeneralSecretaryData?.gender === "transgender"} onChange={(e) => { handleGender(e) }} />
                                    <span style={{ paddingRight: '15px', paddingLeft: '15px' }}>Other</span>

                                    {isFormSubmitted && !getGeneralSecretaryInputValid('gender') && <p className="text-danger">Please select gender</p>}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="mb-4">
                                    <label className="form-label mb-0">Date of Birth</label>
                                    <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>*</span>
                                    {currentGeneralSecretaryData.date_of_birth &&
                                        <DateComponent onDate={onChange} yearDiff={18} defaultData={currentGeneralSecretaryData.date_of_birth}></DateComponent>}
                                    {!currentGeneralSecretaryData.date_of_birth && <DateComponent onDate={onChange} yearDiff={18}></DateComponent>}
                                    {isFormSubmitted && !startDate && !isDateError?.error && <p className="text-danger">Please select date</p>}
                                    {isDateError?.error && !startDate && <p className="text-danger">{isDateError?.error}</p>}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="mb-4">
                                    <label className="form-label mb-0">Blood Group</label>
                                    <select name="blood_group" value={currentGeneralSecretaryData.blood_group} onChange={(e) => { handleBloodGroupChange(e) }} className="form-select form-select-lg mb-3">
                                        <option value="">Select blood group</option>
                                        <option value="O+">O+</option>
                                        <option value="O-">O-</option>
                                        <option value="A-">A-</option>
                                        <option value="A+">A+</option>
                                        <option value="B-">B-</option>
                                        <option value="B+">B+</option>
                                        <option value="AB-">AB-</option>
                                        <option value="AB+">AB+</option>
                                    </select>
                                </div>
                            </div>

                            <div className="col-md-4">
                                <div className="mb-4">
                                    <div className="file btn btn-sm btn-primary px-4 rounded-12 mt-2 d-inline-block">Upload Photo
                                        <input type="file" accept="image/*" onChange={(e) => handleUploadLogo(e, "viceprincipal")} />
                                    </div>
                                </div>
                            </div>
                            {imageLoader &&
                                <div className="text-center col-md-1 p-5">
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div>
                            }
                            {generalSecretaryLogo && <div className="col-md-4">
                                <div className="mb-4">
                                    <label className="form-label mb-0 d-block">Preview Photo</label>
                                    <img src={`${appConfig.FILE_UPLOAD_URL}${generalSecretaryLogo.charAt(0) === "/" ? generalSecretaryLogo.substring(1) : generalSecretaryLogo}`} style={{ height: '100px', width: '100px', objectFit: 'contain' }} />
                                </div>
                            </div>}

                            <div style={{ borderBottom: 'solid 1px black' }} className="mb-4">
                                <h5 className="form-label mb-2 d-block">Contact Details</h5>
                            </div>

                            <div className="col-md-4">
                                <div className="mb-4">
                                    <label className="form-label mb-0">Email</label>
                                    <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>*</span>
                                    <input className="form-control form-control-lg" type="text" name="email_id" value={currentGeneralSecretaryData.email_id} onChange={(e) => { handleEmailChange(e) }} placeholder="Please enter email" />
                                    {email && <p className="text-danger">{errorMessages.email}</p>}
                                    {isFormSubmitted && !getGeneralSecretaryInputValid('email_id') && <p className="text-danger">Please enter email</p>}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="mb-4">
                                    <label className="form-label mb-0">Mobile Number</label>
                                    <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>*</span>
                                    <input className="form-control form-control-lg" type="text" name="mobile_number" maxLength={10} value={currentGeneralSecretaryData.mobile_number} onChange={(e) => { handleMobileChange(e) }} placeholder="Please enter mobile number" />
                                    {mobileNumber && <p className="text-danger">{errorMessages.mobile_number}</p>}
                                    {isFormSubmitted && !getGeneralSecretaryInputValid('mobile_number') && <p className="text-danger">Please enter mobile number</p>}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="mb-4">
                                    <label className="form-label mb-0">WhatsApp Number</label>
                                    (  <input type="checkbox" name="sameMobileNumber" checked={currentGeneralSecretaryData.checked} onChange={(e) => { handleMobileNumberChange(e) }} style={{ paddingLeft: '10px' }} />
                                    <span style={{ paddingLeft: '10px' }}>Same as Mobile Number</span> )
                                    <input className="form-control form-control-lg" type="text" disabled={currentGeneralSecretaryData.checked} name="whatsapp_number" maxLength={10} value={currentGeneralSecretaryData.whatsapp_number} onChange={(e) => { handleWhatsappChange(e) }} placeholder="Please enter whatsapp number" />
                                    {(whatsappNumber) && <p className="text-danger">{errorMessages.whatsapp_number}</p>}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="mb-4">
                                    <label className="form-label mb-0">City/Village (Optional)</label>
                                    <input className="form-control form-control-lg" type="text" name="city_village" defaultValue={currentGeneralSecretaryData.city_village} placeholder="Please enter city" />
                                </div>
                            </div>
                        </div>
                    </form>
                </FormBuilder>
                <div className="form-footer border-top border-primary py-3 text-end mt-4">
                    <a className="btn btn-primary btn-sm px-5 rounded-12 cursor-pointer" onClick={() => createGeneralSecretary()}>Save & Next</a>
                    <Link className="text-dark text-decoration-none ms-2 cursor-pointer" to="/dashboard/institute/list">Cancel</Link>
                </div>
            </div>}
        </div>
    )
}
