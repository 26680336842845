import { Switch, Route, Redirect } from "react-router";
import AddSchoolPage from "./add-school";
import { SchoolList } from "./list";
import { SchoolInFo } from "./schoolinfo";
import SchoolFormPage from "./form";
import { StudentInFo } from "./students/student-info";
import StudentRoutes from "./students/routes";
import { StudentPayments } from "./payment-list";


interface Props {
    match?: any;
}
const SchoolRoutes = (props: Props) => {
    let url: string | undefined = props.match?.url;
    if (url?.endsWith('/')) {
        url = url.substr(0, url.length - 1);
    }

    return (
        <Switch>
            <Route exact path={`${url}/list`} >
                <SchoolList></SchoolList>
            </Route>
            <Route exact path={`${url}/form/:id`} >
                <SchoolFormPage></SchoolFormPage>
            </Route>
            <Route path={`${url}/new`} exact>
                <AddSchoolPage></AddSchoolPage>
            </Route>
            <Route path={`${url}/new/:id`} exact>
                <AddSchoolPage></AddSchoolPage>
            </Route>
            <Route path={`${url}/info/:id`} exact>
                <SchoolInFo></SchoolInFo>
            </Route>
            <Route path={`${url}/info/:id/principallist/:schoolCode`} exact>
                <SchoolInFo></SchoolInFo>
            </Route>
            <Route path={`${url}/info/:id/principalform/:schoolCode/:userId`} exact>
                <SchoolInFo></SchoolInFo>
            </Route>
            <Route path={`${url}/info/:id/viceprincipalform/:schoolCode/:userId`} exact>
                <SchoolInFo></SchoolInFo>
            </Route>
            <Route path={`${url}/info/:id/viceprincipallist/:schoolCode`} exact>
                <SchoolInFo></SchoolInFo>
            </Route>
            <Route path={`${url}/info/:id/adminform/:schoolCode/:userId`} exact>
                <SchoolInFo></SchoolInFo>
            </Route>
            <Route path={`${url}/info/:id/adminlist/:schoolCode`} exact>
                <SchoolInFo></SchoolInFo>
            </Route>
            <Route path={`${url}/info/:id/modulelist/:schoolCode`} exact>
                <SchoolInFo></SchoolInFo>
            </Route>
            <Route path={`${url}/info/:id/module/assign-parent/:schoolCode`} exact>
                <SchoolInFo></SchoolInFo>
            </Route>
            <Route path={`${url}/info/:id/module/assign-user/:schoolCode`} exact>
                <SchoolInFo></SchoolInFo>
            </Route>
            <Route path={`${url}/info/:id/teachersform/:schoolCode/:userId`} exact>
                <SchoolInFo></SchoolInFo>
            </Route>
            <Route path={`${url}/info/:id/teacherslist/:schoolCode`} exact>
                <SchoolInFo></SchoolInFo>
            </Route>
            <Route path={`${url}/info/:id/student-count-list/:schoolCode`} exact>
                <SchoolInFo></SchoolInFo>
            </Route>
            <Route path={`${url}/info/:id/studentsform/:schoolCode/:userId`} exact>
                <SchoolInFo></SchoolInFo>
            </Route>
            <Route path={`${url}/info/:id/studentslist/:schoolCode`} exact>
                <SchoolInFo></SchoolInFo>
            </Route>

            <Route path={`${url}/info/:id/paymentlist/:schoolCode`} exact>
                <SchoolInFo></SchoolInFo>
            </Route>

            {/* student Routes */}
            <Route path={`${url}/student/info/:id`} exact>
                <StudentRoutes {...props}></StudentRoutes>
            </Route>
            <Route path={`${url}/student/info/:id`} exact>
                <StudentInFo></StudentInFo>
            </Route>
            <Route path={`${url}/student/info/:id/parentlist/:schoolCode/:studentId`} exact>
                <StudentInFo></StudentInFo>
            </Route>
            <Route path={`${url}/student/info/:id/parentform/:schoolCode/:studentId/:userId`} exact>
                <StudentInFo></StudentInFo>
            </Route>
            <Route path={`${url}/student/info/:id/modulelist/:schoolCode/:studentId`} exact>
                <StudentInFo></StudentInFo>
            </Route>
            <Route path={`${url}/:id/:schoolCode/:moduleUuid/payment/list`} exact>
                <StudentPayments></StudentPayments>
            </Route>
        </Switch>
    );
}
export default SchoolRoutes;