import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import rightArrowImg from '../../../../assets/images/Arrow-Right.png';
import { GET_MNS_STUDENT_STATUS } from '../../../../app/service/mns-service';
import { MNSCardsData } from '../mns/card/mns-card';
import { MLACardData } from '../mla/card/mla-card';


export const MNSProgressView = () => {
    const history = useHistory();
    const [moduleData, setModuleData] = useState<any[]>([]);
    let { usercode, studentCode } = useParams<{ usercode: string, studentCode: string }>();
    const [userRole, setUserRole] = useState<any>();
    const [userCodes, setUserCodes] = useState<any>();
    const [instituteCode, setInstituteCode] = useState<any>();
    const [userInfo, setUserInfo] = useState<any>({});
    const [MNSLevelList, setMNSLevelList] = useState<any[]>([]);
    const [MNSStudentStatus, setMNSStudentStatus] = useState<any>({});
    const [MNSInfo, setMNSInfo] = useState(false);
    const [attemptLevels, setAttemptLevels] = useState<any>([1, 2, 3, 4, 5, 6, 7]);
    let { moduleUUID, userCode, schoolCode } = useParams<{ moduleUUID: string, userCode: string, schoolCode: string }>();
    const modules_uuid = moduleUUID;
    const user_code = userCode;
    const school_code = schoolCode;

    useEffect(() => {
        localStorage.removeItem('setTimer');
        const userRole = localStorage.getItem("user_role");
        const user_code = localStorage.getItem("user_code");
        const institute_code = localStorage.getItem("institute_code");
        setUserCodes(user_code);
        setInstituteCode(institute_code);
        setUserRole(userRole);
        getMNSStudentStatus();

    }, []);

    function getMNSStudentStatus() {
        let payload = {
            module_uuid: modules_uuid,
            school_code: school_code,
            user_code: user_code
        }
        GET_MNS_STUDENT_STATUS(payload).then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res.message);
            } else {
                if (res) {
                    const data = res;
                    setMNSStudentStatus(data);
                    const status = data.status;
                    setMNSLevelList(status);
                }
            }

        });
    }


    const getLogo = (index: number) => {
        let currentAttempt = MNSStudentStatus && MNSStudentStatus?.status?.length > 0 ?
            (MNSStudentStatus?.status[MNSStudentStatus.status.length - 1].attempt ? MNSStudentStatus?.status[MNSStudentStatus.status.length - 1].attempt : 0) : 0;

        if (index == currentAttempt) {
            return '#FCEE21';
        }

        if (currentAttempt == 0) {
            return '#FFFFFF'
        }

        if (currentAttempt > 0 && currentAttempt > index) {
            return '#8CC63F';
        } else {
            return '#ffffff';
        }
    }

    function onClickAttemptsInfo() {
        localStorage.setItem("backword_key", "false");
        history.push(`/student-dashboard/list/mns/questions/${modules_uuid}/${user_code}/${school_code}`);
    }

    const getPendingLevel = () => {
        let level = MNSStudentStatus && MNSStudentStatus?.status?.length > 0 ? MNSStudentStatus?.status?.find((x: any) => x.status === 'pending') : null;
        return level;
    }

    const handleBack = () => {
        history.goBack();
    }


    return (
        <div>

            <div
                className="status-cards d-flex justify-content-between align-items-center mb-2"
            >
                <h3 className="f20 m-0 text-black fw-medium">Entry Test</h3>
                <div className="text-center">
                    <label className="f14 text-black">Score</label>
                    <p className="text-score fw-bold m-0 text-black">{MNSStudentStatus?.assesment_test?.student_score}</p>
                </div>
            </div>

            <MNSCardsData data={MNSStudentStatus} />

            {MNSStudentStatus && MNSStudentStatus?.assesment_test?.status == 'pending'
                && MNSStudentStatus?.status?.length == 0 && <div className="mb-2 " onClick={() => { onClickAttemptsInfo() }}>
                    <span
                        className="status-cards d-flex justify-content-between align-items-center mb-2 position-relative collapsed"

                    >
                        <div>
                            <label className="f20 m-0 text-black fw-medium d-block"
                            >{"A1"}</label>

                            <span
                                className="text-black fw-normal line-hieight-normal f12"
                            >Attempts completed</span>

                            <ul className="list-inline m-0 attempts" >
                                {attemptLevels.map((item: any, i: number) => (
                                    <li className="list-inline-item" key={i} style={{ backgroundColor: "#FFFFFF" }}>{item}</li>

                                ))}

                            </ul>
                        </div>
                        <i className="status-cards-arrow small"></i>
                    </span>
                </div>}

            {getPendingLevel() && <div className="mb-2 cursor-pointer" onClick={() => { onClickAttemptsInfo() }}>
                <span
                    className="status-cards d-flex justify-content-between align-items-center mb-2 position-relative collapsed"

                >
                    <div>
                        <label className="f20 m-0 text-black fw-medium d-block"
                        >{getPendingLevel().sub_level}</label>

                        <span
                            className="text-black fw-normal line-hieight-normal f12"
                        >Attempts completed</span>

                        <ul className="list-inline m-0 attempts">
                            {attemptLevels.map((item: any, i: number) => (
                                <li className="list-inline-item" style={{ backgroundColor: getLogo(i) }} key={i}>{item}</li>

                            ))}

                        </ul>
                    </div>
                    <i className="status-cards-arrow small"></i>
                </span>
            </div>}
            {MNSStudentStatus?.status?.length > 0 && !getPendingLevel() &&
                <MLACardData data={MNSStudentStatus} />
            }
        </div>
    )
}
