import { DataTableCol } from "../../../../components/data-table/types";

export const StudentsDataGridCols: DataTableCol[] = [
  {
    title: "Name",
    control: "name",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "Email",
    control: "email_id",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "Mobile Number",
    control: "mobile_number",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "Standard",
    control: "standard",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "Division",
    control: "division",
    sortable: true,
    canShowColumn: true,
  },
];
