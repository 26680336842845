import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import rightArrowImg from '../../../../assets/images/Arrow-Right.png';
import downArrowImg from '../../../../assets/images/Arrow-Down.png';
import CommonLevelData from '../common-level';

export const MNSCardsData = (props: any) => {
    const status = props.data.status;
    const [assesmentTestStatus, setAssesmentTestStatus] = useState<any>();
    const [MNSDetails, setMNSDetails] = useState<any>([]);
    const [MNSInfo, setMNSInfo] = useState(false);
    const history = useHistory();
    let { moduleUUID, userCode, schoolCode } = useParams<{ moduleUUID: string, userCode: string, schoolCode: string }>();
    const modules_uuid = moduleUUID;
    const user_code = userCode;
    const school_code = schoolCode;

    useEffect(() => {
        const status = props.data.status;
    }, []);

    function onClickMNSInfo() {
        setMNSInfo(!MNSInfo);

    }
    const getPendingLevel = () => {
        let level = status && status?.length > 0 ? status?.find((x: any) => x.status === 'pending') : null;
        return level;
    }

    return (
        <div>

            <div className="mb-2">
                <a
                    className={`status-cards d-flex justify-content-between align-items-center mb-2 position-relative ${MNSInfo ? "" : "collapsed"} `}
                    data-bs-toggle="collapse"

                    role="button"
                    aria-expanded="false"
                    aria-controls="collapseExample"
                >
                    <div>
                        <label className="f20 m-0 text-black fw-medium d-block"
                        >MNS</label>

                        <span className="text-black fw-light line-hieight-normal"
                        >Minimum Numerical Skills</span>

                    </div>
                    <i className="status-cards-arrow" onClick={onClickMNSInfo}></i>
                </a>
                <div className={`collapse ${MNSInfo && "show"}`} id="collapseExample">

                    {status && status.length == 0 &&
                        <CommonLevelData
                            data={status}
                            level={"A"}
                        />}
                    {status && status.find((x: any) => x.level === 'A') &&
                        <CommonLevelData
                            data={status}
                            level={"A"}
                        />}
                    {status && status.find((x: any) => x.level === 'B') &&
                        <CommonLevelData
                            data={status}
                            level={"B"}
                        />}
                    {status && status.find((x: any) => x.level === 'C') &&
                        <CommonLevelData
                            data={status}
                            level={"C"} />}
                </div>
            </div>
        </div >
    )
}