import React, { useEffect, useState } from 'react'
import appConfig from '../../../../config/constant';
import { GET_INSTITUTE_DASHBOARD } from '../../../../app/service/institute-dashboard.service';
import { ToastContainer, toast } from 'react-toastify';
import { Breadcum } from "../../../../components/breadcum";
import { Link, useHistory } from 'react-router-dom';
import IMGLOGO from '../../../../institute-dashboard-assets/images/bala-bavan-logo.svg'

function RegisteredStudents() {
    const userRole = localStorage.getItem("user_role");
    const history = useHistory();
    const [schooldData, setSchoolData] = useState<any>([]);
    const [loading, setLoading] = useState(false);
    const [breadcumInfo, setBreadcumInfo] = useState<any>([
        {
            label: "Dashboard",
            value: "Dashboard",
            routerLink: "/institute-dashboard/dashboard",
            isActive: false,
        },
        {
            label: "School Name",
            value: "",
            routerLink: "",
            isActive: false,
        },
    ]);
    useEffect(() => {
        instituteSchoolDetails();
        for (let i = 0; i < breadcumInfo.length; i++) {
            const element = breadcumInfo[i];
            element.routerLink = "/institute-dashboard/dashboard";
        }

    }, []);

    const instituteSchoolDetails = () => {

        const data: any = localStorage.getItem('organization_code');
        setLoading(true);
        GET_INSTITUTE_DASHBOARD(data).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    if (res) {
                        const data = res;

                        getBreadcumList(data);

                        setSchoolData(data);
                    }
                    setLoading(false);
                }
            }
        });
    }
    function getBreadcumList(data: any) {
        breadcumInfo.splice(1, breadcumInfo.length - 1, {
            label: "Registered School",
            value: data.name_of_school,
            isActive: true,
        });
        setBreadcumInfo(breadcumInfo);
    }

    function openStdentsList(data: any) {
        if (userRole === "SUPER_ADMIN") {
            history.push(`/dashboard/view/registered-studentslist/${data.name_of_school}/${data.school_code}`)
        } else {
            history.push(`/institute-dashboard/dashboard/registered-studentslist/${data.name_of_school}/${data.school_code}`)
        }
    }


    return (
        <div >
            {loading &&
                <div className="text-center p-5 mt-5">
                    <div className="spinner-border mt-5" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading && <div>
                <div className="d-flex flex-wrap flex-md-nowrap justify-content-between ">
                    <h3 className="d-lg-none" >Dashboard</h3>
                </div>

                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb pb-2 border-bottom ps-0 pt-0" style={{ letterSpacing: "0" }}>
                        {userRole === "SUPER_ADMIN" && <li className="breadcrumb-item mt-3"><Link to='/dashboard/view/instituteinfo' style={{ color: '#0d6efd' }}>Dashboard</Link> / <span>Registered Students</span></li>}
                        {userRole !== "SUPER_ADMIN" && <li className="breadcrumb-item"><Link to='/institute-dashboard/dashboard' style={{ color: '#0d6efd' }}>Dashboard</Link> / <span className='ps-1'>Registered Students</span></li>}
                    </ol>
                </nav>

                <div className='row'>
                    {schooldData?.schools_list && schooldData?.schools_list.length > 0 ? schooldData?.schools_list.map((data: any, index: number) => {
                        return (
                            <div className="col-md-6 col-lg-4 mb-4 school-card cursor-pointer" key={index} onClick={() => openStdentsList(data)}>
                                <span className="sc-card-content d-flex align-items-center h-100">
                                    <div className="sc-card-img">
                                        <img src={data.logo_url ? `${appConfig.FILE_UPLOAD_URL}${data.logo_url.charAt(0) === "/" ? data.logo_url.substring(1) : data.logo_url}` : IMGLOGO} className='schoolpic' alt="" />

                                    </div>
                                    <div className="sc-card-text">
                                        <h3 className="m-0">{data.name_of_school}</h3>
                                        <h6 className="m-0">{data.city_village}</h6>
                                        <p className="m-0">Students : <span style={{ fontWeight: "bold" }} className='f12'>{data?.total_students}</span></p>

                                    </div>
                                </span>
                            </div>
                        )
                    })
                        : <p className='mt-3'> No Data Available</p>}

                </div>
            </div>
            }
        </div>
    )

}

export default RegisteredStudents;