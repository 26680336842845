import React, { useEffect, useState } from "react";
import { toast } from 'react-toastify';
import { GET_MODULES_BY_USER_CODES } from "../../../app/service/module.service";
import { GET_SCHOOL_BY_SCHOOL_CODE } from "../../../app/service/school.service";
import { GET_PARENT_BY_STUDENT, GET_USER_USER_CODE } from "../../../app/service/users.service";
import appConfig, { UI_BASE_URL } from '../../../config/constant';
import STUDENTIMG from '../../../institute-dashboard-assets/images/student-img.svg'
import SUBJECTCOMPLETE from '../../../institute-dashboard-assets/images/subj-completed-logo.svg'
import FATHERIMG from '../../../institute-dashboard-assets/images/father-icon.svg'
import MOTHERIMG from '../../../institute-dashboard-assets/images/mother-icon.svg'
import MNSLOGO from '../../../institute-dashboard-assets/images/mns_logo.png'
import ABHYAASLOGO from '../../../institute-dashboard-assets/images/Abyas-logo.svg'
import PARIKSHALOGO from '../../../institute-dashboard-assets/images/Pariksha-logo.svg'
import { Link, useHistory } from "react-router-dom";
import { GET_SUBSCRIPTION_BY_USER_CODES } from "../../../app/service/user-subscription";
import { ADD_UPDATE_SUBSCRIPTION_PRICE, GET_SUBSCRIPTION_PRICE_STATUS_BY_STUDENT } from "../../../app/service/subscription.service";
import { CREATE_PAYMENT, CREATE_PAYMENT_DETAILS_SCHOOL_MODULE_CODE, UPDATE_PAYMENT } from "../../../app/service/payment-service";
import { CREATE_MNS_USER_ASSIGN, GET_MNS_STUDENT_STATUS } from "../../../app/service/mns-service";
import useRazorpay, { RazorpayOptions } from "react-razorpay";
import { CREATE_CCAVENUE_PAYMENT } from "../../../app/service/ccavenue.service";
import { slice } from "lodash";

function StudentDashboardList() {
    const Razorpay = useRazorpay();
    const [loading, setLoading] = useState(false);
    const [studentInfo, setStudentInfo] = useState<any>({});
    const UserCode = localStorage.getItem("user_code");
    const [parentList, setParentList] = useState<any>([]);
    const userRole = localStorage.getItem("user_role");
    const [moduleData, setModuleData] = useState<any>([]);
    const [subscription, setSubscription] = useState<any>({});
    const history = useHistory();
    const [moduleInfo, setModuleInfo] = useState<any>({});
    const [moduleName, setModuleName] = useState<any>("");
    const [canShowPopup, setCanShowPopup] = useState(false);
    const [paymentDetailsLoading, setPaymentDetailsLoading] = useState(false);
    const [amount, setAmount] = useState<any>("");
    const [subscriptionInfo, setSubscriptionInfo] = useState<any>({});
    const [transactionId, SettransactionId] = useState<any>("");
    const [modulesData, setModulesData] = useState<any>([]);
    const [isExpired, setIsExpired] = useState(false);
    const [paymentInfo, setPaymentInfo] = useState<any>({});
    const RAZORPAY_OPTIONS: RazorpayOptions = {
        key: appConfig.paymentKey,
        amount: (amount + "000"),
        currency: "INR",
        name: "IAMSAMARTH",
        description: "Subscription Fee",
        image: "",
        order_id: "",
        prefill: {
            name: moduleName,
            email: "",
            contact: "9999999999",
        },
        notes: {
            address: "Razorpay Corporate Office",
        },
        theme: {
            color: "#3399cc",
        },
    };
    useEffect(() => {


        // ccavenue //
        const splitLocation: any = window.location.href.split("?");
        const queryString = splitLocation[1];
        if (queryString) {
            const match = queryString.match(/status=([^&]*)/);
            if (match) {
                const statusValue = match[1];
                const studentInfo: any = JSON.parse(localStorage.getItem('cc_student_info') || '{}');
                const studentPaymentInfo = JSON.parse(localStorage.getItem('cc_user_payment_info') || '{}');
                const txnId = localStorage.getItem("cc_transaction_id");
                const moduleInfo = JSON.parse(localStorage.getItem('cc_module_info') || '{}');
                if (txnId && statusValue === "success") {
                    updateSubscription(txnId, "", studentPaymentInfo, statusValue, studentInfo, moduleInfo);
                } else {
                    removeItemsFromeLocalStorage();
                }
            } else {
            }
        }
        getUserDetails();
        getModulesByUserCode(UserCode, userRole);
        getSubscriptionDetails(UserCode);

    }, []);

    function getSubscriptionDetails(data: any) {
        GET_SUBSCRIPTION_BY_USER_CODES(data).then((response: any) => {
            if (response.status === 'fail') {
                toast.error(response.message);
            } else {
                const data = response.data[0];
                if (new Date(data?.subscription_to) < new Date()) {
                    setIsExpired(true);
                }
                setSubscription(data);
            }
        });
    }
    function getUserDetails() {
        setLoading(true);
        GET_USER_USER_CODE(UserCode).then((response: any) => {
            if (response.status === 'fail') {
                toast.error(response.message);
                setLoading(false);
            } else {
                const data = response.rows[0];
                setStudentInfo(data);
                getParents(data);
                setLoading(false);

            }
        });
    }
    function getParents(data: any) {

        if (data?.guardian) {
            const guardian = data?.guardian ? data.guardian.replace(/[^a-zA-Z0-9,-_]/g, "") : "";
            GET_PARENT_BY_STUDENT(guardian).then((res: any) => {
                if (res) {
                    if (res.status === 'fail') {
                        toast.error(res.message);
                    } else {
                        const data = res.rows;
                        setParentList(data);

                    }
                }
            });
        }

    }
    const getModulesByUserCode = (userCode: any, userRole: any) => {
        GET_MODULES_BY_USER_CODES(userCode).then((res: any) => {
            if (res) {
                if (res.status === "fail") {
                    toast.error(res.message);
                } else {
                    if (userRole === "PARENT") {
                        const data = res;
                        setModuleData(data);
                    } else {
                        const data = res.filter((el: any) => el.module_code !== 'CAS-PARENT');
                        setModuleData(data);

                    }
                }
            }
        });
    };

    function openTest(data: any) {
        if (data.module_code === "MATHS") {
            localStorage.removeItem('setTimer');
            localStorage.setItem("backword_key", "false");
            getMNSStudentStatus(data);
        }
        else if (data?.module_code === "CAS") {
            history.push(`/student-dashboard/list/cas-test-instructions/${data.user_details['user_code']}/${data.module_code}`)
        }
        else if (data?.module_code === "ABHYAAS") {
            history.push(`/student-dashboard/list/abhyas/test-level-status/${data.uuid}/${data?.user_details?.user_code}/${studentInfo?.institute_school_code}`);
        } else if (data?.module_code === "PARIKSHA") {
            // history.push(`/student-dashboard/list/pariksha/test-level-status/${data.uuid}/${data?.user_details?.user_code}/${studentInfo?.institute_school_code}`);
            history.push(`/student-dashboard/list/pariksha/test-initiative/${data.uuid}/${data?.user_details?.user_code}/${studentInfo?.institute_school_code}`);

        }
    }

    function onClickPay(data: any) {
        setPaymentInfo({});
        setModuleInfo(data);
        setModuleName(data.module_name);
        getPaymentDetailsOfUser(data);
        setCanShowPopup(true);
    }

    function getPaymentDetailsOfUser(data: any) {
        const payLoad = {
            "institute_school_code": studentInfo?.institute_school_code,
            "standard": "",
            "division": "",
            "students": [{ user_code: `${studentInfo?.user_code}` }],
            "module_uuid": data?.uuid
        }
        setPaymentDetailsLoading(true);
        CREATE_PAYMENT_DETAILS_SCHOOL_MODULE_CODE(payLoad).then((res: any) => {
            if (res.status === "fail") {
                toast.error(res?.message);
                setPaymentDetailsLoading(false);
            } else {
                if (res) {
                    let data = res?.students;
                    if (data?.length > 0) {
                        setPaymentInfo(data[0]);
                        localStorage.setItem("cc_user_payment_info", JSON.stringify(data[0]));
                        setAmount(data[0]?.total_amount);
                    }
                    setPaymentDetailsLoading(false);
                }
            }
        });
        // setPaymentDetailsLoading(true);
        // GET_SUBSCRIPTION_PRICE_STATUS_BY_STUDENT(studentInfo.user_code).then((res: any) => {
        //     if (res) {
        //         if (res.status === 'fail') {
        //             toast.error(res.message);
        //             setPaymentDetailsLoading(false);
        //         } else {
        //             const usersData = res;
        //             let perparedPrice = res;
        //             if (usersData?.students && usersData['students'].length > 0) {
        //                 localStorage.setItem("cc_subscription_price", usersData.subscription_price_1);
        //                 setSubscriptionPrice(usersData.subscription_price_1);
        //                 setAmount(data.amount + res.subscription_price_1);
        //             }

        //             perparedPrice?.students?.map((ele: any) => {
        //                 if (ele.payment_status === 'completed') {
        //                     localStorage.setItem("cc_subscription_price", res?.subscription_price_2);
        //                     setSubscriptionPrice(res.subscription_price_2);
        //                     setAmount(data.amount + res.subscription_price_2);
        //                 }
        //             });

        //             let preparedStudents = usersData?.students?.filter((ele: any) => (ele.user_code === studentInfo.user_code));
        //             usersData.students = preparedStudents;

        //             if (usersData['guardian'] === null) {
        //                 localStorage.setItem("cc_subscription_info", JSON.stringify(usersData));
        //                 setSubscriptionInfo(usersData);
        //                 localStorage.setItem("cc_subscription_price", usersData.subscription_price);
        //                 setSubscriptionPrice(usersData.subscription_price);
        //                 setAmount(usersData.subscription_price);
        //             }
        //             else if (usersData['students'].length > 0) {
        //                 localStorage.setItem("cc_subscription_info", JSON.stringify(usersData));
        //                 setSubscriptionInfo(usersData);
        //                 if (usersData['students'][0].payment_status === 'not-completed') {
        //                 } else {
        //                     setAmount(data.amount);
        //                 }
        //             }
        //             setPaymentDetailsLoading(false);

        //         }
        //     }
        // });
    }
    const handleclose = () => {
        setCanShowPopup(false);
    }
    const handlePayment = () => {
        createPayment(moduleInfo, paymentInfo);
        localStorage.setItem("cc_module_info", JSON.stringify(moduleInfo));
        localStorage.setItem("cc_student_info", JSON.stringify(studentInfo));

    }
    function createPayment(moduleInfo: any, paymentInfo: any) {
        const payload = {
            school_code: studentInfo?.institute_school_code,
            user_ids: paymentInfo?.user_code,
            sub_amount: paymentInfo?.total_amount
        }
        if (parseInt(paymentInfo?.total_amount) > 0) {
            CREATE_PAYMENT(payload).then((res: any) => {
                if (res) {
                    if (res.status === 'fail') {
                        toast.error(res.message);
                    } else {
                        SettransactionId(res.txn_id);
                        onOpenPaymentInfo(payload.sub_amount, res.txn_id, moduleInfo, paymentInfo);
                    }
                }
            });
        } else {
            getAssignModule(paymentInfo, moduleInfo, studentInfo);
        }
    }

    function onOpenPaymentInfo(amount: any, txn_id: string, moduleInfo: any, paymentInfo: any) {
        localStorage.setItem("cc_user_payment_info", JSON.stringify(paymentInfo));
        localStorage.setItem("cc_transaction_id", txn_id)

        if (amount > 0) {
            createCcavenuePayment(amount, txn_id);

            // RAZORPAY_OPTIONS.amount = amount + "00";
            // RAZORPAY_OPTIONS.handler = (response) => {
            //     onUpdatePayment(txn_id, response, "success", data);
            //     if (amount > 0) {
            //         updateSubscription(txn_id, response, subscriptionData, "success");
            //     }
            // }
            // let razorpay = new Razorpay(RAZORPAY_OPTIONS);

            // razorpay.open();

            // razorpay.on("payment.failed", function (response: any) {
            //     onUpdatePayment(txn_id, response, "failed", data);
            //     if (amount > 0) {
            //         updateSubscription(txn_id, response, subscriptionData, "failed");
            //     }
            // });

        } else {
            onUpdatePayment(txn_id, { "message": "SUM AMOUNT ZERO" }, "success", moduleInfo);
            if (amount > 0) {
                updateSubscription(txn_id, { "message": "SUM AMOUNT ZERO" }, paymentInfo, "success");
            }
        }
    }

    function getAssignModule(paymentInfo: any, moduleData: any, studentInfo?: any) {

        const user_code = [];
        user_code.push(paymentInfo?.user_code);
        const payload = {
            module_uuid: moduleData.uuid ? moduleData.uuid : '',
            payment_status: "COMPLETED",
            school_code: studentInfo?.institute_school_code,
            target_std: moduleData.target_std,
            total_amount_paid: paymentInfo?.module_price,
            user_codes: user_code
        }
        CREATE_MNS_USER_ASSIGN(payload).then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res.message);
            } else {
                toast.success("Successfully assigned  module!");
                setLoading(true);
                getModulesByUserCode(UserCode, userRole);
                removeItemsFromeLocalStorage();
                setCanShowPopup(false);
                setLoading(false);
            }
        });
    }
    function getModules() {
        setLoading(true);
        GET_MODULES_BY_USER_CODES(studentInfo?.user_code).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    const data = res.filter((el: any) => el.module_code === 'CAS' || el.module_code === 'MATHS');
                    setModulesData(data);
                    setLoading(false);
                }
            }
        });
    }
    function onUpdatePayment(transactionId: string, data: any, status: string, moduleData: any) {

        const payload = {
            txn_id: transactionId,
            "module_code": moduleData.module_code ? moduleData.module_code : '',
            "module_uuid": '',
            "txn_details": data,
            "status": status
        }

        UPDATE_PAYMENT(payload).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                } else {
                    SettransactionId(res.txn_id);
                    if (status === "success") {
                        toast.success('Payment Updated Successfully');
                        getAssignModule(paymentInfo, moduleData, studentInfo);
                        setCanShowPopup(false);
                    }
                }
            }
        });
    }
    function updateSubscription(transactionId: any, transactionData: any, paymentInfo: any, paymentStatus: any, studentInfo?: any, moduleInfo?: any) {
        if (paymentInfo?.subscription_payment_status === 'pending') {
            const payload = {
                data: [
                    {
                        "uuid": "",
                        "school_code": studentInfo?.institute_school_code,
                        "user_code": paymentInfo?.user_code,
                        "transaction_id": transactionId,
                        "amount": parseInt(paymentInfo?.subscription_price),
                        // "transaction_details": JSON.stringify(transactionData),
                        "transaction_details": "",
                        "payment_status": "completed"
                    }
                ]
            }
            if (paymentStatus === "success") {
                ADD_UPDATE_SUBSCRIPTION_PRICE(payload).then((res: any) => {
                    if (res.status === 'fail') {
                        toast.error(res?.message);
                    } else {
                        getSubscriptionDetails(UserCode);
                        getAssignModule(paymentInfo, moduleInfo, studentInfo);
                        toast.success('Subscription Added Successfully');
                    }
                });
            }
        } else {
            getAssignModule(paymentInfo, moduleInfo, studentInfo);

        }
    }

    function getMNSStudentStatus(data: any) {

        let payload = {
            module_uuid: data.uuid,
            school_code: studentInfo?.institute_school_code,
            user_code: data.user_details['user_code']
        }
        setLoading(true);
        GET_MNS_STUDENT_STATUS(payload).then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res.message);
                setLoading(false);
            } else {
                if (res) {
                    const data = res;
                    let status = Array.isArray(data.assesment_test) ? data.assesment_test.length > 0 : Object.keys(data.assesment_test).length !== 0;
                    if (status) {
                        history.push(`/student-dashboard/list/mns/view/${payload.module_uuid}/${payload.user_code}/${studentInfo?.institute_school_code}`);
                    } else {
                        history.push(`/student-dashboard/list/mns/test-level/${payload.module_uuid}/${payload.user_code}/${studentInfo?.institute_school_code}`);
                    }
                }
            }
        });
    }

    // function getSubscriptionTotal(price: any, amount: any) {
    //     let subscriptionPrice: any = price > 0 ? parseInt(price) : '';
    //     let moduleAmount: any = amount > 0 ? parseInt(amount) : '';
    //     if ((subscriptionInfo.students && subscriptionInfo['students'][0].payment_status === 'not-completed') || (subscriptionInfo.payment_status && subscriptionInfo.payment_status === "not-completed")) {
    //         return `${subscriptionPrice + moduleAmount}`
    //     } else {
    //         return `${moduleAmount}`
    //     }

    // }

    function onClickViewReports(item: any) {
        if (item.module_code === "CAS") {
            history.push(`/student-dashboard/list/reports/student/${item.uuid}/${item.user_details.user_code}`)
        }

        if (item.module_code === "MATHS") {
            history.push(`/student-dashboard/list/maths/reports/student/${item.user_details.user_code}`);

        }
    }
    const createCcavenuePayment = (amount: any, txn_id: string) => {
        let payLoad = {
            amount: amount,
            currency: "INR",
            transaction_uuid: txn_id,
            redirect_url: `${UI_BASE_URL}/student-dashboard/list`,
            cancel_url: `${UI_BASE_URL}/student-dashboard/list`,
        }
        CREATE_CCAVENUE_PAYMENT(payLoad).then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res?.error);
            } else {
                initiatePayment(res);

            }
        });
    }
    const initiatePayment = async (paymentData: any) => {
        window.location.href = paymentData.url;
    };
    const removeItemsFromeLocalStorage = () => {
        const keysToRemove = ['cc_student_info', 'cc_user_payment_info', 'cc_transaction_id', 'cc_module_info'];
        keysToRemove.forEach((key: any) => {
            localStorage.removeItem(key);
        });
    };

    return (
        <div>

            <div className={`modal fade ${canShowPopup ? 'show' : 'false'}`} style={{ display: (canShowPopup ? "block" : 'none') }} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog subscription-payment-dialog" role="document">
                    <div className="modal-content subscription-payment-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Payment Details</h5>
                            <button type="button" className="close popup-close" data-dismiss="modal" aria-label="Close" onClick={handleclose}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            {paymentDetailsLoading &&
                                <div className="text-center p-5">
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div>
                            }
                            {!paymentDetailsLoading && <div className=" custom-scroll table-responsive">
                                <table className="table table_new_ui fixed-table-header mb-0">
                                    <thead>
                                        <tr>
                                            <th>Student Name</th>
                                            <th>Module Name</th>
                                            <th>Subscription Price</th>
                                            <th>Module Price</th>
                                            <th>Total</th>
                                        </tr>
                                    </thead>
                                    {Object.keys(paymentInfo).length > 0 && <tbody>
                                        <tr>
                                            <td>{paymentInfo?.name}</td>
                                            <td>{(moduleInfo.module_code === "CAS" || moduleInfo.module_code === "MATHS") ? moduleInfo?.module_code : moduleInfo?.module_code?.charAt(0).toUpperCase() + moduleInfo?.module_code?.slice(1).toLowerCase()}</td>
                                            <td>{(paymentInfo?.subscription_payment_status === "completed") ? <span>-</span> : <span>{parseInt(paymentInfo?.subscription_price)}</span>}</td>
                                            <td>{paymentInfo?.module_price}</td>
                                            <td>{paymentInfo?.total_amount}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontWeight: 'bold' }}>Total Amount to Pay</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td style={{ fontWeight: 'bold' }}>{paymentInfo?.total_amount}</td>
                                        </tr>
                                    </tbody>}
                                </table>
                                {Object.keys(paymentInfo).length == 0 && <p className="text-center f12 mt-3">No Data Found</p>}
                            </div>}
                        </div>
                        <div className="modal-footer">
                            {(Object.keys(paymentInfo).length > 0) && <button type="button" className="btn btn-primary cursor-pointer" onClick={handlePayment}>{(parseInt(paymentInfo?.total_amount)) > 0 ? 'Pay Now' : 'Assign'}</button>}
                            <button type="button" className="btn btn-secondary cursor-pointer" data-dismiss="modal" onClick={handleclose}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading && <div>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb pb-2 ps-0 pt-0 border-bottom">
                        <li className="breadcrumb-item"><span>Dashboard</span></li>
                    </ol>
                </nav>
                <div className="border-bottom pb-3">
                    <div className="d-md-flex sc-profile-details">
                        <div className="mb-3 mb-md-0">
                            <div className="sc-profile-img py-0">
                                <img
                                    src={studentInfo?.profile_img ? `${appConfig.FILE_UPLOAD_URL}${studentInfo.profile_img.charAt(0) === "/" ? studentInfo.profile_img.substring(1) : studentInfo.profile_img}` : STUDENTIMG}
                                    alt=""
                                    className="pr-logo"
                                />
                            </div>
                        </div>
                        <div className="sc-profile-text ps-md-4">
                            <ol className="breadcrumb border-0 mb-0 p-0">
                                {subscription?.payment_status === "completed" ? (isExpired ? <li className="breadcrumb-item text-notsubc pe-1">Subscription Expired</li> : <li className="breadcrumb-item text-subc pe-1">Subscribed</li>) :
                                    <li className="breadcrumb-item text-notsubc">Not Yet Subscribed</li>}
                            </ol>
                            <h2 className="mt-0 mb-2">{studentInfo?.name?.charAt(0).toUpperCase() + studentInfo?.name?.slice(1)}</h2>
                            <p className="m-0 sc-profile-contact">
                                <span>{studentInfo?.mobile_number}</span> {studentInfo?.email_id && <span> | </span>} <span>{studentInfo?.email_id} </span>
                            </p>
                            <p className="m-0 sc-profile-addrs">
                                <span>{studentInfo?.state}</span> {studentInfo?.district && <span> | </span>} <span>{studentInfo?.district}</span>{studentInfo?.city_village && <span> | </span>}
                                <span>{studentInfo?.city_village}</span>
                            </p>
                        </div>
                    </div>
                </div>
                {studentInfo?.guardian && <h3 className="mt-4 mb-3 section-hd">Parent Details</h3>}
                <div className={`${studentInfo?.guardian && "border-bottom"} pb-3 mb-4`}>
                    <div className="row">
                        {parentList?.map((data: any) => {
                            return (
                                <div className="col-md-6">
                                    <div className="d-flex sc-profile-details small mb-4">
                                        <div className="sc-profile-img pt-0">
                                            {data?.gender === "female" ? <img
                                                src={data.profile_img ? `${appConfig.FILE_UPLOAD_URL}${data.profile_img.charAt(0) === "/" ? data.profile_img.substring(1) : data.profile_img}` : MOTHERIMG}
                                                alt=""
                                                className="pr-image"
                                            /> : <img
                                                src={data.profile_img ? `${appConfig.FILE_UPLOAD_URL}${data.profile_img.charAt(0) === "/" ? data.profile_img.substring(1) : data.profile_img}` : FATHERIMG}
                                                alt=""
                                                className="pr-image"
                                            />}
                                        </div>
                                        <div className="sc-profile-text ps-4">
                                            <p className="sc-profile-deg mb-1">{data.gender === "female" ? <span>Mother</span> : <span>Father</span>}</p>
                                            <h2 className="m-0">{data?.name?.charAt(0).toUpperCase() + data?.name?.slice(1)}</h2>
                                            <p className="m-0 sc-profile-contact">
                                                <span>{data?.mobile_number}</span> {data?.email_id && <span> | </span>} <span>{data?.email_id} </span>
                                            </p>
                                            <p className="m-0 sc-profile-addrs">
                                                <span>{data?.state}</span> {data?.district && <span> | </span>} <span>{data?.district}</span>{(data?.city_village && data?.district) && <span> | </span>}
                                                <span>{data?.city_village}</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>)
                        })}

                    </div>
                </div>
                <div className="row">
                    {moduleData?.map((data: any, i: number) => {
                        return (<div className="col-md-4 col-lg-3 mb-3" key={i}>
                            <div className={`module-card h-100 ${data?.module_code !== "CAS" && "card-two"}`}>
                                {/* <h2 className={`module-title  ${data.module_code !== "CAS" ? "mb-4" : "mb-0"}`}>{data?.module_code}</h2> */}
                                <div className="d-flex justify-content-between ">
                                    <div>
                                        {(data.module_code === "CAS" || data.module_code === "MATHS") && <h2 className={`module-title mb-0`}>{(data.module_code === "CAS") ? data?.module_code : data?.module_code?.charAt(0).toUpperCase() + data?.module_code?.slice(1).toLowerCase()}</h2>}
                                        {data.module_code === "ABHYAAS" && <h2 className={`module-title mb-0`}>Abhyās</h2>}
                                        {data?.module_code === "PARIKSHA" && <h2 className={`module-title mb-0`}>Parikshā</h2>}
                                        {data.module_code === "CAS" && <h5 className="mb-3">Career Aspiration<sup>TM</sup> Survey</h5>}
                                        {data.module_code === "MATHS" && <h5 className="mb-3">गणित</h5>}
                                        {data.module_code === "ABHYAAS" && <h5 className="mb-3">अभ्यास</h5>}
                                        {data?.module_code === "PARIKSHA" && <h5 className="mb-3">परीक्षा</h5>}
                                    </div>

                                    <div>
                                        {data.module_code === "MATHS" && <img className=" ms-4 position-relative module_logo_img" src={MNSLOGO} alt="" />}
                                        {data.module_code === "ABHYAAS" && <img className=" ms-4 position-relative module_logo_img" src={ABHYAASLOGO} alt="" />}
                                        {data.module_code === "PARIKSHA" && <img className="ms-4 position-relative module_logo_img" src={PARIKSHALOGO} alt="" />}
                                    </div>
                                </div>

                                <p className="mb-2 ">
                                    Payment - <label >{(data.amount_status === "PENDING" || data.amount_status === null) && <span className="text-inprogess">Pending</span>} {data.amount_status === "COMPLETED" && <span className="text-completed"> Paid</span>}</label>
                                </p>
                                {(data.amount_status === "PENDING" || data.amount_status === null) ? <p className="m-0"><span className="btn btn-pay" onClick={() => onClickPay(data)}>Pay Now</span></p> : <p className="m-0">
                                    Status - <label>{(data.module_status === "inprogress" || data.module_status === null) && <span className="text-inprogess">In Progress <span onClick={() => openTest(data)} className="btn btn-continue ms-3">
                                        Continue test</span></span>}{data.module_status === "completed" && <span className="text-completed"> Completed</span>}</label>
                                </p>

                                }
                                {/* <span onClick={() => openTest(data)}>jjjj</span> */}
                                {data?.module_status === "completed" && <img
                                    src={SUBJECTCOMPLETE}
                                    alt=""
                                    className="module-completed-logo"
                                />}
                                {data?.module_status === "completed" && <p className="m-0"> <span className="btn btn-continue mt-2" onClick={() => onClickViewReports(data)}> View Report </span></p>}

                            </div>
                        </div>)
                    })}
                    {moduleData?.length === 0 && <p className="mt-4 f14">Modules are not Assigned</p>}
                </div>

            </div>}

        </div>
    )

}
export default StudentDashboardList;