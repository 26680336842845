import React from 'react';
import { NavLink } from 'react-router-dom';
import ParentRoutes from './routes';

const ParentsPage = (props: any) => {
    
    return (
        <div>
            <ParentRoutes {...props} />
        </div>
    );
};

export default ParentsPage;