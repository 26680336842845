import React, { useEffect, useState } from 'react'
import appConfig from '../../../config/constant';
import { ToastContainer, toast } from 'react-toastify';
import { Link, useHistory } from 'react-router-dom';
import { GET_SCHOOL_LISTS } from '../../../app/service/dashboard.service';
import IMGLOGO from '../../../institute-dashboard-assets/images/bala-bavan-logo.svg'


function SchoolsList() {
    const [schoolList, setSchoolList] = useState<any>([]);
    const [loading, setLoading] = useState(false);
    const history = useHistory();

    useEffect(() => {
        schoolDetails();


    }, []);


    const schoolDetails = () => {
        setLoading(true);
        GET_SCHOOL_LISTS({}).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    if (res) {
                        const data = res;
                        setSchoolList(data.rows);
                    }
                    setLoading(false);
                }
            }
        });
    }

    function openInstituteDashboard(data: any) {
        localStorage.setItem(
            "institute_code",
            data.school_code
        );
        localStorage.setItem(
            "name_of_school",
            data.name_of_school
        );
        history.push(`/dashboard/view/schoolinfo`)
        localStorage.setItem(
            "navigation",
            "schools"
        );

    }



    return (
        <div >
            {loading &&
                <div className="text-center p-5 mt-5">
                    <div className="spinner-border mt-5" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading && <div>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb border-bottom ps-0 pt-0 pb-2" style={{ letterSpacing: "0" }}>
                        <li className="breadcrumb-item mt-3"><Link to='/dashboard/view' style={{ color: '#0d6efd' }}>Dashboard</Link> / <span>Schools</span></li>
                    </ol>
                </nav>
                <div className='row'>
                    {schoolList && schoolList.length > 0 ? schoolList.map((data: any) => {
                        return (
                            <div className="col-md-6 col-lg-4 mb-4 school-card cursor-pointer" onClick={() => openInstituteDashboard(data)}>
                                <span className="sc-card-content d-flex align-items-center h-100">
                                    <div className="sc-card-img">
                                        <img src={data.logo_url ? `${appConfig.FILE_UPLOAD_URL}${data.logo_url.charAt(0) === "/" ? data.logo_url.substring(1) : data.logo_url}` : IMGLOGO} className='schoolpic' alt="" />
                                    </div>
                                    <div className="sc-card-text">
                                        <h3 className="m-0">{data.name_of_school}</h3>
                                        <h6 className="m-0">{data.city_village}</h6>
                                        <p className="m-0">{data.address}</p>
                                    </div>
                                </span>
                            </div>)
                    })
                        : <p className='mt-3'> No Data Available</p>}
                </div>
            </div>
            }
        </div>
    )

}

export default SchoolsList;