import API from "../utility/axios";
const apiUrl = "/api/v1";
const newApiUrl = "/api/v2";

export const GET_MODULES = (pageNumber: number): Promise<any> => {
  return API.get(`${apiUrl}/module?limit=${10}&page_num=${pageNumber}`);
};

export const GET_TOTAL_MODULES = (): Promise<any> => {
  return API.get(`${apiUrl}/module`);
};

export const GET_MODULES_BY_SCHOOL_ID = (schoolCode: string): Promise<any> => {
  return API.get(`${apiUrl}/school/module?school_code=${schoolCode}`);
};

export const CREATE_ASSIGN_SCHOOL = (data: any): Promise<any> => {
  return API.post(`${apiUrl}/school/module`, data);
};

export const UPDATE_SCHOOL_MODULE = (data: any): Promise<any> => {
  return API.put(`${apiUrl}/school/module`, data);
};

export const CREATE_ASSIGN_USER = (data: any): Promise<any> => {
  return API.post(`${apiUrl}/school/user/module`, data);
};

export const UPDATE_ASSIGN_USER = (data: any): Promise<any> => {
  return API.put(`${apiUrl}/school/user/module`, data);
};

export const CREATE_MODULE = (data: any): Promise<any> => {
  return API.post(`${apiUrl}/module`, data);
};

export const UPDATE_MODULE = (data: any): Promise<any> => {
  return API.put(`${apiUrl}/module`, data);
};

export const GET_MODULES_ID = (id: number): Promise<any> => {
  return API.get(`${apiUrl}/module?id=${id}`);
};

export const DELETE_MODULE = (id: number): Promise<any> => {
  return API.delete(`${apiUrl}/module/${id}`);
};

export const GET_MODULE_QUESTIONS = (data: any): Promise<any> => {
  return API.post(`${apiUrl}/school/user/module/question`, data);
};

export const GET_MODULES_BY_USER_CODE = (userCode: string): Promise<any> => {
  return API.get(`${apiUrl}/school/user/module?user_code=${userCode}`);
};

export const GET_MODULES_BY_ID = (id: string): Promise<any> => {
  return API.get(`${apiUrl}/school/user/module?user_code=${id}`);
};

export const GET_MODULE_BY_QUESTIONS = (data: any): Promise<any> => {
  return API.post(`${apiUrl}/school/user/module/question`, data);
};

export const CREATE_MODULE_ANSWERS = (data: any): Promise<any> => {
  return API.post(`${apiUrl}/school/user/module/answer`, data);
};

export const GET_MODULE_BY_QUESTION = (data: any): Promise<any> => {
  return API.post(`${apiUrl}/module/question/list`, data);
};

export const GET_SCHOOL_FILTER = (data: any): Promise<any[]> => {
  return API.get(`${apiUrl}/school`, { params: data });
};

export const GET_MODULE_STUDENT_BY_ID = (
  id: number,
  userCode: any
): Promise<any[]> => {
  return API.get(`${apiUrl}/school/user/module?user_code=${userCode}&id=${id}`);
};

export const ASSIGN_MODULES_BY_PARENTS = (data: any): Promise<any> => {
  return API.post(`${apiUrl}/school/parent/module`, data);
};

export const GET_MNS_MODULE_BY_QUESTIONS = (data: any): Promise<any> => {
  return API.post(`${apiUrl}/school/user/mns/module/question`, data);
};

export const GET_MNS_MODULES_BY_ID = (
  id: string,
  mns_code: string
): Promise<any> => {
  return API.get(
    `${apiUrl}/school/user/module?user_code=${id}&module_code=${mns_code}`
  );
};

export const GET_MNS_MODULES_BY_ID_NEW = (
  id: string,
  mns_code: string,
  childUserCode: string
): Promise<any> => {
  return API.get(
    `${apiUrl}/school/user/module?user_code=${id}&module_code=${mns_code}&child_user_code=${childUserCode}`
  );
};
// export const GET_MNS_MODULES_BY_ID_NEW = (
//   id: string,
//   mns_code: string,
//   childUserCode: string
// ): Promise<any> => {
//   return API.get(
//     `${apiUrl}/school/user/module?user_code=${id}&module_code=${mns_code}&child_user_code=${childUserCode}`
//   );
// };
export const GET_MNS_MODULES_BY_ID_NEW_STUDENt = (
  id: string,
  mns_code: string
): Promise<any> => {
  return API.get(
    `${apiUrl}/school/user/module?user_code=${id}&module_code=${mns_code}`
  );
};

export const GET_MODULES_BY_USER_CODES = (userCode: string): Promise<any> => {
  // return API.get(`${newApiUrl}/user/modules?user_code=${"USER_7a6adb5a-318e-492b-ab75-f7ce35983c25"}`);
  return API.get(`${newApiUrl}/user/modules?user_code=${userCode}`);
};

export const GET_MODULE_BY_UUID = (UUID: any): Promise<any> => {
  // return API.get(`${newApiUrl}/user/modules?user_code=${"USER_7a6adb5a-318e-492b-ab75-f7ce35983c25"}`);
  return API.get(`${apiUrl}/module?uuid=${UUID}`);
};

export const GET_RESET_MODULE_BY_STUDENT = (
  user_code: any,
  school_code: any
): Promise<any> => {
  return API.get(
    `${newApiUrl}/reset/student/modules?user_code=${user_code}&school_code=${school_code}`
  );
};

export const GET_SCHOOLS_MODULE_BY_CODE = (
  module_code: any,
  module_uuid: any
): Promise<any> => {
  return API.get(
    `${newApiUrl}/school/modules?module_code=${module_code}&module_uuid=${module_uuid}&expand=school`
  );
};

export const UPDATE_SCHOOLS_MODULE_BY_CODE = (payload: any): Promise<any> => {
  return API.put(`${newApiUrl}/school/module/bulk/update`, payload);
};
