import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { GET_MLA_TWO_QUESTIONS, MLA_TWO_ANSWERS_UPDATE, MLA_TWO_TIME_UPDATE } from '../../../../app/service/mla-two.service';
import { MathJaxContext, MathJax } from 'better-react-mathjax';
import Countdown from 'react-countdown';


export const MLATwoQuestionsList = () => {
    const [questionsData, setQuestionsData] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    const [startTimer, setStartTimer] = useState(false);
    const [canShowHeader, setShowHeader] = useState(false);
    const [testInfo, setTestInfo] = useState<any>({});
    const type: any = localStorage.getItem("mla_two_type");
    const [cycleNumber, setCycleNumber] = useState<any>('');
    const [worksheets, setWorksheets] = useState<any[]>([]);
    const [timeRemain, setTimeRemain] = useState<any>('');
    const [startTime, setStartTime] = useState(new Date());
    const [alphabets, setAlphabets] = useState<any>(["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z"]);
    const [canShowPopup, setCanShowPopup] = useState(false);
    const [test_start_date, setTestStartDate] = useState<any>(getTimeDetails());
    let { moduleUUID, userCode, schoolCode } = useParams<{ moduleUUID: string, userCode: string, schoolCode: string }>();
    const history = useHistory();
    const [hoursValue, setHoursValue] = useState<any>();
    const [canShowAlertPopup, setCanShowAlertPopup] = useState<boolean>(false);
    const testBeginDate: any = localStorage.getItem("mla_two_test_start_date");

    useEffect(() => {
        getMLATwoInfo();
        // getTimeInfo();
        if (type === 'TESTS') {
            checkTestStartDate();
        }
    }, []);
    useEffect(() => {
        if (startTimer) {
            const timer = setInterval(() => {
                getTimeInfo();
            }, 1000);

            return () => {
                clearInterval(timer);
            };
        }
    }, [startTimer]);

    function getTimeDetails() {
        const testStartDate: any = localStorage.getItem("mla_two_test_start_date");

        return testStartDate ? testStartDate : null;
    }


    function getTimeInfo() {
        const testStartDate: any = localStorage.getItem("mla_two_test_start_date");
        let time = getTime(testStartDate);
        let timeMillis = moment(time, 'YYYY-MM-DD hh:mm:ss').toDate().getTime();
        let reqTimeMillis = 2 * 24 * 60 * 60 * 1000;
        let actualTimeToComplete = timeMillis + reqTimeMillis;
        let x = new Date(actualTimeToComplete)
        // setTimeRemain(x);
        let currentTime = new Date().getTime();
        let timerResult = x.getTime() - currentTime;
        let tSeconds = Math.floor(timerResult / 1000);
        let hours = Math.floor(tSeconds / 3600); // get hours
        let minutes = Math.floor((tSeconds - (hours * 3600)) / 60); // get minutes
        let seconds = tSeconds - (hours * 3600) - (minutes * 60); //  get seconds
        setHoursValue((hours < 10 ? "0" + hours : hours) + ":" + (minutes < 10 ? "0" + minutes : minutes) + ":" + (seconds < 10 ? "0" + seconds : seconds));
        setTimeRemain((hours < 10 ? "0" + hours : hours) + ":" + (minutes < 10 ? "0" + minutes : minutes) + ":" + (seconds < 10 ? "0" + seconds : seconds));
        if (canShowAlertPopup && type === 'TESTS') {
            setTimeRemain("00:00:00");
            setHoursValue("00:00:00");
        }
        if (hours == 0 && minutes == 0 && seconds == 0 && type === 'TESTS') {
            setTimeRemain("00:00:00");
            setCanShowAlertPopup(true);
        }
    }
    const checkTestStartDate = () => {
        const twoDaysAfterTestStartDate = new Date(testBeginDate);
        twoDaysAfterTestStartDate.setDate(twoDaysAfterTestStartDate.getDate() + 2);
        // Get the current date and time
        const currentDate = new Date();
        // Compare current date with two days after test start date
        if (currentDate.getTime() >= twoDaysAfterTestStartDate.getTime()) {
            setCanShowAlertPopup(true);
            setStartTimer(false);
            setTimeRemain("00:00:00");
        }
    }
    function getTime(time: any) {
        if (time !== 'null') {
            return time;
        } else {
            return time ? time : startTime;
        }
    }


    function getMLATwoInfo() {
        const testInfo: any = localStorage.getItem("mla_two_test_info");
        const type = localStorage.getItem("mla_two_type");
        const cycleNumber = localStorage.getItem("mla_two_cycle_no");
        const worksheets: any = localStorage.getItem("mla_two_worksheets");
        const testStartDate: any = localStorage.getItem("mla_two_test_start_date");
        setTestInfo(JSON.parse(testInfo));
        setCycleNumber(cycleNumber);
        getMLAQuestionsList(JSON.parse(testInfo), type, testStartDate, cycleNumber);
        setWorksheets(JSON.parse(worksheets));
    }


    function getMLAQuestionsList(testInfo: any, type: any, testStartDate: any, cycleNumber: any) {
        let payload = {
            "mode": type,
            "test_no": testInfo.question_paper_id ? testInfo?.question_paper_id : '',
            "ability": testInfo.ability ? testInfo?.ability : '',
            "worksheet_no": testInfo.question_paper_id ? testInfo?.question_paper_id : ''
        }

        setShowHeader(false);
        setLoading(true);
        GET_MLA_TWO_QUESTIONS(payload).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    if (res) {
                        if (res.questions && res.questions.length > 0) {
                            setQuestionsData(res.questions);
                            setShowHeader(true);
                            setStartTimer(true);
                            // setLoading(false);
                            console.log((type === 'TESTS' && (!test_start_date || test_start_date === '' || test_start_date === 'undefined' || test_start_date == '' || test_start_date === 'null' || test_start_date === null)), "time");
                            if (type === 'TESTS' && (!test_start_date || test_start_date === '' || test_start_date === 'undefined' || test_start_date == '' || test_start_date === 'null' || test_start_date === null)) {
                                // setTimeRemain("48:00:00 hrs");
                                setTestStartDate(new Date());
                                storeInitialTimeForTest(cycleNumber);

                            }
                            setLoading(false);

                        } else {
                            setQuestionsData([]);
                            setShowHeader(true);
                            setStartTimer(true);
                            setLoading(false);
                        }

                    }

                }
            }
        });
    }

    function storeInitialTimeForTest(cycleNumber: any) {
        let payload = {
            "user_code": userCode,
            "school_code": schoolCode,
            "cycle_no": cycleNumber ? parseInt(cycleNumber) : 1,
            "test_start_date": moment(startTime).format('YYYY-MM-DD HH:mm:ss')
        }

        MLA_TWO_TIME_UPDATE(payload).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                } else {
                    if (res) {
                    }
                }
            }
        });
    }

    function onViewQuestion(name: any) {
        return { __html: name };
    }


    function handleQuestionOptions(item: any, index: number, j: number) {
        const data = questionsData;
        for (let i = 0; i < alphabets.length; i++) {
            if (j === i) {
                data[index].answer = alphabets[i];
            }
        }
        setQuestionsData(data);
    }


    const handleCompleteSubmit = () => {
        let payload = {
            "user_code": userCode,
            "school_code": schoolCode,
            "cycle_no": cycleNumber ? parseInt(cycleNumber) : 1,
            "mode": type,
            "test_no": testInfo && testInfo.test_no ? testInfo.test_no : 1,
            "question_paper_id": type === 'TESTS' ? (testInfo?.question_paper_id ? testInfo?.question_paper_id : '') : (testInfo?.id ? testInfo?.id : ''),
            "question_paper_level": testInfo.level ? testInfo.level : '',
            "ability": testInfo.ability ? testInfo.ability : '',
            "priority_no": testInfo?.priority ? testInfo?.priority : '',
            "time_taken": 10,
            "test_start_date": moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
            "questions": questionsData
        }
        setLoading(true);
        MLA_TWO_ANSWERS_UPDATE(payload).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    setLoading(false);
                    const data = res;
                    if (type === 'WORKSHEETS') {
                        setCanShowPopup(true);
                        return;
                    }
                    localStorage.setItem('time_remain', hoursValue);
                    history.push(`/student-dashboard/list/mla-two/success-page/${moduleUUID}/${userCode}/${schoolCode}`);
                }

            }
        });
    }

    const getCircleColor = (item: any) => {
        if (item.id === testInfo.id) {
            return '#FCEE21';
        } else if (item.status === 'completed') {
            return '#8CC63F';
        } else {
            return '#FFFFFF';
        }
    }

    const handleBack = () => {
        history.push(`/student-dashboard/list/mns/view/${moduleUUID}/${userCode}/${schoolCode}`);
    }

    const handleclose = () => {
        setCanShowPopup(false);
        history.push(`/student-dashboard/list/mns/view/${moduleUUID}/${userCode}/${schoolCode}`)
    }

    const handleSubmit = () => {
        setCanShowPopup(false);
        history.push(`/student-dashboard/list/mns/view/${moduleUUID}/${userCode}/${schoolCode}`);
    }

    // Renderer callback with condition
    const renderer = ({
        days,
        hours,
        minutes,
        seconds,
        completed,
    }: {
        days: any;
        hours: any;
        minutes: any;
        seconds: any;
        completed: any;
    }) => {
        if (completed) {
            return <div></div>
            // Render a completed state
        } else {
            // Render a countdown
            return (
                <span style={{ fontSize: "1rem", paddingTop: "10px" }}>
                    {hours} Hr - {minutes} Min - {seconds} Sec
                </span>
            );
        }
    };

    return (
        <div className="row  py-3">
            <div
                className={`modal fade ${canShowAlertPopup ? "show" : "false"}`}
                style={{ display: canShowAlertPopup ? "block" : "none" }}
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                                Times up!!!
                            </h5>
                            <button
                                type="button"
                                className="close popup-close cursor-pointer"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={handleCompleteSubmit}
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className='modal-body'>
                            <p>Attention!</p>
                            <p className='mb-0'> You have exceeded the allotted time to take the MLA test.</p>
                            <p >Please note that you can only access the test within the designated <span className='fw-bold'>48-hours</span> window from the time of initiation  <br /> <span className='fw-bold'>  {testBeginDate}</span>.
                                If you believe this is an error or if you have any questions, please contact support for assistance.</p>
                            <p>Thank you for your understanding.</p>
                            {/* Your inputs submitted successfully */}
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-primary cursor-pointer"
                                onClick={handleCompleteSubmit}
                            >
                                Ok
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`modal fade ${canShowPopup ? 'show' : 'false'}`} style={{ display: (canShowPopup ? "block" : 'none') }} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">You have successfully completed worksheet</h5>
                            <button type="button" className="close popup-close cursor-pointer" data-dismiss="modal" aria-label="Close" onClick={handleclose}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary cursor-pointer" onClick={handleSubmit}>OK</button>
                            <button type="button" className="btn btn-secondary cursor-pointer" data-dismiss="modal" onClick={handleclose}>CANCEL</button>
                        </div>
                    </div>
                </div>
            </div>
            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading && <div>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb pb-2 ps-0 pt-0 border-bottom">
                        <li className="breadcrumb-item "><Link to="/student-dashboard/list"><span className='pe-2'>Dashboard</span></Link></li> /
                        <li className="breadcrumb-item "><span>MLA-2</span></li>
                    </ol>
                </nav>

                {canShowHeader &&
                    <div id='navbar--fixed-top' className={`${!canShowPopup && "z_index_ni"}`}>
                        <div className=' p-3 time-block  mt-3 d-md-flex justify-content-between align-items-end'>
                            {type === 'TESTS' && <div>
                                <h2 className="m-0"> Test - 0{testInfo?.test_no ? testInfo?.test_no : ''}</h2>
                                <h3 className="m-0"> Time Limit -  48 hours for all 3 tests</h3>
                            </div>}
                            {type === 'WORKSHEETS' && <div>
                                <h2 className="m-0">Priority - {testInfo?.priority}</h2>
                                <h3 className="m-0"> {testInfo?.name}</h3>
                                <h3 className="m-0"> Worksheets </h3>
                                {worksheets?.map((item: any, i: number) => (
                                    <div key={i} className="circle cursor-pointer" style={{ backgroundColor: getCircleColor(item) }}>
                                        <p>{i + 1}</p>
                                    </div>
                                ))}
                            </div>}
                            {type !== 'WORKSHEETS' && <div>
                                <p className='mt-3 mb-0 m-md-0 f12'>Remaining Time</p>
                                <h1 className='m-0 f14'>{timeRemain} <small>hrs</small></h1>
                                {/* <h1 className='m-0'>{timeRemain && <Countdown
                                    date={timeRemain}
                                    renderer={renderer}
                                />} <small></small></h1> */}
                            </div>}

                        </div>
                    </div>}
                {/* {canShowHeader && <div className="row mt-4">
                    {type === 'TESTS' && < div className='col-md-8'>
                        <div style={{ fontSize: '14px' }}>MLA</div>
                        <div style={{ fontSize: '25px', fontStyle: 'italic', fontWeight: 'bold' }}>Test -0{testInfo.test_no ? testInfo.test_no : ''}</div>
                    </div>}
                    {type === 'WORKSHEETS' && <div className='col-7 col-md-10'>
                        <div style={{ fontSize: '14px' }}>Priority - {testInfo.priority}</div>
                        <div style={{ fontSize: '25px', fontWeight: 'bold' }}>{testInfo.name}</div>
                        <div style={{ fontSize: '12px' }}>Worksheets</div>
                        <div className='col-md-8'>
                            {worksheets.map((item: any, i: number) => (
                                <div key={i} className="circle" style={{ backgroundColor: getCircleColor(item) }}>
                                    <p>{i + 1}</p>
                                </div>
                            ))}
                        </div>
                    </div>}
                    {type === 'TESTS' && <div className='col-md-2'>
                        <div>Time Limit</div>
                        <div>

                            {timeRemain && <Countdown
                                date={timeRemain}
                                renderer={renderer}
                            />}
                        </div>
                    </div>}
                    <div className='col-5 col-md-2 text-end'>
                        <button className="btn btn-primary btn-sm px-5 rounded-12 cursor-pointer" onClick={handleBack}>Back</button>
                    </div>
                </div>} */}
                <div>
                    <MathJaxContext>
                        <MathJax>
                            {questionsData.map((question: any, i: number) => (
                                <div className="mt-4" key={i}>
                                    <div className='row'>
                                        <div className='col-2 col-md-1' style={{ alignSelf: 'center' }}>
                                            <span className='fw-bold' style={{ fontSize: '22px' }}>
                                                Q{question.question_number}:
                                            </span>
                                        </div>
                                        <div className='col-10 col-md-11' style={{ paddingTop: '15px' }}>
                                            <span className='fw-bold' style={{ fontSize: '22px' }}>
                                                <div dangerouslySetInnerHTML={onViewQuestion(question.question)}></div>
                                            </span>
                                        </div>
                                    </div>
                                    <form>
                                        {question.options.map((options: any, j: number) => (
                                            <div className='row pt-3' key={j}>
                                                <div className='col-2 col-md-1'>
                                                    <span>
                                                        <input className="mb-0" type="radio" name="option" onChange={(e) => { handleQuestionOptions(options, i, j) }} />
                                                    </span>
                                                </div>
                                                <div className='col-10 col-md-6'>
                                                    <span dangerouslySetInnerHTML={onViewQuestion(options.value)}></span>
                                                </div>
                                            </div>
                                        ))}
                                    </form>
                                </div>
                            ))}
                        </MathJax>
                    </MathJaxContext>
                </div>
                {questionsData.length > 0 && <div className="text-center mt-4">
                    <button className=" btn btn-primary px-5 rounded-5 cursor-pointer" onClick={handleCompleteSubmit}>Submit</button>
                    <Link to={`/student-dashboard/list/mns/view/${moduleUUID}/${userCode}/${schoolCode}`}><button className='btn btn-link ms-2'>Cancel</button></Link>

                </div>}
            </div>}
        </div >
    )
}
