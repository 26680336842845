import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import rightArrowImg from '../../../assets/images/Arrow-Right.png';
import { GET_MNS_STUDENT_STATUS } from '../../../app/service/mns-service';
import { MNSCard } from './card/mns-card';
import { MLACard } from '../mla/card/mla-card';

export const MNSDashboardView = () => {
    const history = useHistory();
    const [moduleData, setModuleData] = useState<any[]>([]);
    let { usercode, studentCode } = useParams<{ usercode: string, studentCode: string }>();
    const [userRole, setUserRole] = useState<any>();
    const [userCodes, setUserCodes] = useState<any>();
    const [instituteCode, setInstituteCode] = useState<any>();
    const [userInfo, setUserInfo] = useState<any>({});
    const [MNSLevelList, setMNSLevelList] = useState<any[]>([]);
    const [MNSStudentStatus, setMNSStudentStatus] = useState<any>({});
    const [MNSInfo, setMNSInfo] = useState(false);
    const [attemptLevels, setAttemptLevels] = useState<any>([1, 2, 3, 4, 5, 6, 7]);
    let { moduleUUID, userCode, schoolCode } = useParams<{ moduleUUID: string, userCode: string, schoolCode: string }>();
    const modules_uuid = moduleUUID;
    const user_code = userCode;
    const school_code = schoolCode;

    useEffect(() => {
        const userRole = localStorage.getItem("user_role");
        const user_code = localStorage.getItem("user_code");
        const institute_code = localStorage.getItem("institute_code");
        setUserCodes(user_code);
        setInstituteCode(institute_code);
        setUserRole(userRole);
        getMNSStudentStatus();
    }, []);

    function getMNSStudentStatus() {
        let payload = {
            module_uuid: modules_uuid,
            school_code: school_code,
            user_code: user_code
        }
        GET_MNS_STUDENT_STATUS(payload).then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res.message);
            } else {
                if (res) {
                    const data = res;
                    setMNSStudentStatus(data);
                    const status = data.status;
                    setMNSLevelList(status);
                }
            }

        });
    }


    const getLogo = (index: number) => {
        let currentAttempt = MNSStudentStatus && MNSStudentStatus?.status?.length > 0 ?
            MNSStudentStatus?.status[MNSStudentStatus.status.length - 1].consecutive_success : 0;

        if (index == currentAttempt) {
            return '#FCEE21';
        }

        if (currentAttempt == 0) {
            return '#FFFFFF'
        }

        if (currentAttempt > 0 && currentAttempt > index) {
            return '#8CC63F';
        } else {
            return '#ffffff';
        }
    }

    function onClickAttemptsInfo() {
        history.push(`/dashboard/mns/questions/list/${modules_uuid}/${user_code}/${school_code}`);
    }

    const getPendingLevel = () => {
        let level = MNSStudentStatus && MNSStudentStatus?.status?.length > 0 ? MNSStudentStatus?.status?.find((x: any) => x.status === 'pending') : null;
        return level;
    }

    const handleBack = () => {
        history.goBack();
    }


    return (
        <div className="border-top border-primary py-3">
            <div className="row mb-3">
                <div className='col-md-10'>
                    <h2 style={{ paddingLeft: '30px' }}>Maths Dashboard</h2>
                </div>
                <div className='col-md-2'>
                    <button className="btn btn-primary btn-sm px-5 rounded-12 cursor-pointer" onClick={handleBack}>Back</button>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row">
                    <div className='row'>
                        <div className="col-md-5">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card info-card-2 shadow  mb-5 rounded mb-4">
                                        <img className="card-img-top" />
                                        <div className="card-body" style={{ padding: '14px' }}>
                                            <div className='row'>
                                                <div className='col-md-9 pt-4 px-3'>
                                                    <h3>Entry Test</h3>
                                                </div>
                                                <div className='col-md-3'>
                                                    <div>Score</div>
                                                    <div>
                                                        <span className='fw-bold' style={{ fontSize: '42px' }}>{MNSStudentStatus?.assesment_test?.student_score}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-5">
                            <div className="row">
                                <div className="col-md-12">
                                    <MNSCard data={MNSStudentStatus} />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-5">
                            <div className="row">
                                <div className='col-md-12'>
                                    {MNSStudentStatus && MNSStudentStatus?.assesment_test?.status == 'pending'
                                        && MNSStudentStatus?.status?.length == 0 && <div className="card info-card-2 shadow  mb-5 rounded mb-4 cursor-pointer" onClick={() => { onClickAttemptsInfo() }}>
                                            <img className="card-img-top" />
                                            <div className="card-body p-3">
                                                <div className='row'>
                                                    <div className='col-md-9 px-3'>
                                                        <div className="">
                                                            <h3 className='m-0'>{"A1"}</h3>
                                                            <h6 className='m-0'>Attempts completed</h6>
                                                            {attemptLevels.map((item: any, i: number) => (
                                                                <div key={i} className="circle" style={{ backgroundColor: "#FFFFFF" }}>
                                                                    <p>{item}</p>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                    <div className='col-md-3'>
                                                        <div className=''>
                                                            <img src={rightArrowImg} style={{ width: '50%', height: '50%', objectFit: 'contain' }} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>}
                                    {getPendingLevel() && <div className="card info-card-2 shadow  mb-5 rounded mb-4 cursor-pointer" onClick={() => { onClickAttemptsInfo() }}>
                                        <img className="card-img-top" />
                                        <div className="card-body p-3">
                                            <div className='row'>
                                                <div className='col-md-9 px-3'>
                                                    <div className="">
                                                        <h3 className='m-0'>{getPendingLevel().sub_level}</h3>
                                                        <h6 className='m-0'>Attempts completed</h6>
                                                        {attemptLevels.map((item: any, i: number) => (
                                                            <div key={i} className="circle" style={{ backgroundColor: getLogo(i) }}>
                                                                <p>{item}</p>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                                <div className='col-md-3'>
                                                    <div className=''>
                                                        <img src={rightArrowImg} style={{ width: '50%', height: '50%', objectFit: 'contain' }} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>}
                                </div>
                            </div>
                        </div>
                        {MNSStudentStatus?.status?.length > 0 && !getPendingLevel() && <div className="col-md-5">
                            <div className="row">
                                <div className="col-md-12">
                                    <MLACard data={MNSStudentStatus} />
                                </div>
                            </div>
                        </div>}
                    </div>
                </div>
            </div>
        </div>
    )
}
