import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { GET_INDIVIDUAL_BY_USER_CODE } from '../../../app/service/users.service';

export const MLALevelSuccessPage = () => {
    const [userInfo, setUserInfo] = useState<any>({});
    const [timeRemain, setTimeRemain] = useState<any>();
    let { moduleUUID, userCode, schoolCode } = useParams<{ moduleUUID: string, userCode: string, schoolCode: string }>();
    const [loading, setLoading] = useState(false);
    const [testInfo, setTestInfo] = useState<any>({});
    const [type, setType] = useState<any>('');
    const history = useHistory();


    useEffect(() => {
        getUserDetails();
        const testInfo: any = localStorage.getItem("test_info");
        const type = localStorage.getItem("type");
        const cycleNumber = localStorage.getItem("cycleNumber");
        const timeRemain = localStorage.getItem("time_remain");
        setTimeRemain(timeRemain);
        setTestInfo(JSON.parse(testInfo));
        setType(type);
    }, []);

    function getUserDetails() {
        setLoading(true);
        GET_INDIVIDUAL_BY_USER_CODE(userCode).then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res.message);
                setLoading(false);
            } else {
                if (res) {
                    const data = res.rows;
                    if (data.length > 0) {
                        setUserInfo(data[0]);
                    }
                    setLoading(false);
                }
            }
        });
    }

    function onClickStart() {
        history.push(`/dashboard/mns/dashboard/${moduleUUID}/${userCode}/${schoolCode}`);
    }

    function onAbilitywiseReport() {
        history.push(`/dashboard/mla/ability-result/${moduleUUID}/${userCode}/${schoolCode}`);
    }

    return (
        <div>
            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading && <div className='text-center'>
                <section className="mb-4 mt-4 px-3 px-md-0 text-center">
                    <div className="bg-white rounded-16 p-3 p-lg-5 container">
                        <div className="row align-items-center">
                            <div className="col-md-12 ps-lg-5">
                                <h1 className="fw-bold mb-0">{userInfo.name}</h1>
                                <h1 className="fw-normal m-0">You have</h1>
                                <h1 className="text-green success-text m-0">Sucessfully</h1>
                                <h3 className="mb-0 fw-light">Completed</h3>
                                <h5 style={{ fontSize: '35px' }}>{type === 'TESTS' ? 'MLA Test-' + testInfo.test_no : ''}</h5>
                            </div>
                            {testInfo.test_no !== 3 && <div>
                                <div style={{ fontSize: '25px' }}>You can now</div>
                                <div style={{ fontSize: '25px' }}>attempt
                                    <div style={{ fontSize: '40px', fontWeight: 'bold', color: '#0F70CD' }}> MLA Test-{testInfo.test_no + 1}</div>
                                </div>
                                <div style={{ fontSize: '25px' }}>
                                    remaining time {timeRemain}
                                </div>
                            </div>}
                            {testInfo.test_no === 3 && <div>
                                <div style={{ fontSize: '25px', marginTop: '-10px' }}>You can now view</div>
                                <div style={{ fontSize: '35px', color: '#0F70CD', cursor: 'pointer' }} onClick={() => { onAbilitywiseReport() }}>Abilities wise results</div>
                            </div>}
                        </div>
                    </div>
                </section>
                <button className='start-now-btn mt-5' onClick={() => { onClickStart() }}>
                    <span style={{ paddingLeft: '2rem' }}>{'Start Now'}</span>
                    <span style={{ paddingLeft: '4rem' }}>
                        <i className="bi bi-arrow-right"></i>
                    </span>
                </button>
            </div>}
        </div>
    )
}