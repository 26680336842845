import { DataTableCol } from "../../../components/data-table/types";

export const SponsorDataGridCols: DataTableCol[] = [
  {
    title: "Sponsor Name",
    control: "sponsor_name",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "Sponsorship Name",
    control: "name",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "Expiry Date",
    control: "expiry_dt",
    sortable: true,
    canShowColumn: true,
  },

  {
    title: "Payment link",
    control: "payments_link",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "Actions",
    control: "both",
    sortable: false,
    isViewDetails: true,
    isEdit: true,
    isDelete: true,
    canShowColumn: true,
  },
];
