import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import rightArrowImg from '../../../../../assets/images/Arrow-Right.png';
import downArrowImg from '../../../../../assets/images/Arrow-Down.png';
import { GET_MLA_DETAILS, GET_MLA_INFO } from '../../../../../app/service/mla.service';
import badgeImg from '../../../../../assets/images/Badge.png';
import closeIcon from '../../../../../assets/images/close_icon.png';
import { MLATwoCardData } from '../../mla-two/card/mla-two-card';
import SUBJECTCOMPLETE from '../../../../../institute-dashboard-assets/images/subj-completed-logo.svg'

export const MLACardData = (props: any) => {
    const [MNSInfo, setMNSInfo] = useState(false);
    const [assesmentTestStatus, setAssesmentTestStatus] = useState<any>();
    const [MNSDetails, setMNSDetails] = useState<any>([]);
    const [attemptLevels, setAttemptLevels] = useState<any>([1, 2, 3]);
    const [badgeLevels, setBadgeLevels] = useState<any>([1, 2, 3]);
    const [MLADetails, setMLADetails] = useState<any>([]);
    const [topicResult, setTopicResult] = useState<any>([]);
    const [topics, setTopics] = useState<any>([]);
    const [priorityItems, setPriorityItems] = useState<any>(null);
    const [canShowSubItems, setShowSubItems] = useState(true);
    const [canShowPopup, setCanShowPopup] = useState(false);
    const [currentCycleInfo, setCurrentCycleInfo] = useState<any>(null);
    const [previousCycleInfo, setPreviousCycleInfo] = useState<any>(null);
    const [cycleInfo, setCycleInfo] = useState<any>([]);
    const [isMlaOneCompleted, setMlaOneCompleted] = useState(false);
    let { moduleUUID, userCode, schoolCode } = useParams<{ moduleUUID: string, userCode: string, schoolCode: string }>();
    const modules_uuid = moduleUUID;
    const user_code = userCode;
    const school_code = schoolCode;
    const history = useHistory();

    useEffect(() => {
        getMLADetails();
        getMLAInfo();
    }, []);

    function getMLADetails() {
        GET_MLA_DETAILS().then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res.message);
            } else {
                if (res) {
                    const data = res;
                    if (data.details.length > 0) {
                        setMLADetails(data.details);
                        let topicList = [];

                        for (let i = 0; i < data.config.abilities.length; i++) {
                            topicList.push(data.config.abilities[i].topic);
                        }

                        topicList = topicList.filter(function (item, index, inputArray) {
                            return inputArray.indexOf(item) == index;
                        });

                        setTopics(topicList);
                    }
                }
            }
        });
    }


    function getMLAInfo() {
        let payload = {
            "module_uuid": modules_uuid,
            "school_code": school_code,
            "user_code": user_code,
            "cycle_no": ''
        }

        GET_MLA_INFO(payload).then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res.message);
            } else {
                if (res) {
                    const data = res;
                    setCurrentCycleInfo(data);
                    let mlaOneStatus = data.status;
                    if (mlaOneStatus === 'completed') {
                        setMlaOneCompleted(true);
                        setShowSubItems(false);
                    } else {
                        setMlaOneCompleted(false);
                        setShowSubItems(true);
                    }

                    let status = data.tests.find((x: any) => x.status === 'completed');
                    if (data.cycle_no > 1 && !status) {
                        getRequestedCycleData(data.cycle_no - 1);
                    }
                    if (data && data.priorities) {
                        data.priorities = data.priorities.map((item: any, index: number) => {
                            item.isSelected = false;
                            return item;
                        })
                        setPriorityItems(data.priorities);
                    }
                }
            }
        });
    }

    function getRequestedCycleData(cycle_no: any) {
        let payload = {
            "module_uuid": modules_uuid,
            "school_code": school_code,
            "user_code": user_code,
            "cycle_no": cycle_no ? cycle_no : ''
        }

        GET_MLA_INFO(payload).then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res.message);
            } else {
                if (res) {
                    const data = res;
                    setPreviousCycleInfo(data);

                    if (data && data.priorities) {
                        data.priorities = data.priorities.map((item: any, index: number) => {
                            item.isSelected = false;
                            return item;
                        })
                        setPriorityItems(data.priorities);
                    }
                }
            }
        });
    }


    function onClickMLAInfo() {
        setShowSubItems(!canShowSubItems);
    }

    const getMlaTestCompletedStatus = (cycle_info: any) => {
        if (cycle_info && cycle_info.tests) {
            let isCompleted = true;
            for (let i = 0; i < cycle_info.tests.length; i++) {
                if (cycle_info.tests[i].status === 'pending') {
                    isCompleted = false;
                }
            }
            return isCompleted
        } else {
            return false;
        }

    }

    const getCompletedCycleInfoToShow = () => {
        if (currentCycleInfo && previousCycleInfo && currentCycleInfo.tests_status === 'pending'
            && previousCycleInfo.tests_status === 'completed') {
            return previousCycleInfo;
        } else {
            return currentCycleInfo;
        }
    }


    function onClickAttemptsInfo(cycleInfo: any) {
        localStorage.setItem("backword_key", "false");

        if (getMlaTestCompletedStatus(cycleInfo)) {
            history.push(`/student-dashboard/list/mla/ability-result/${modules_uuid}/${user_code}/${school_code}`);
        } else if (cycleInfo && cycleInfo.tests) {
            for (let i = 0; i < cycleInfo.tests.length; i++) {
                if (cycleInfo.tests[i].status === 'pending') {
                    localStorage.setItem('test_info', JSON.stringify(cycleInfo.tests[i]));
                    localStorage.setItem('type', 'TESTS');
                    localStorage.setItem('cycleNumber', cycleInfo.cycle_no);
                    localStorage.setItem('test_start_date', cycleInfo.test_start_date);
                    // history.push(`/student-dashboard/list/mla/questions/${modules_uuid}/${user_code}/${school_code}`);

                    history.push(`/student-dashboard/list/mla/test-level-status/${modules_uuid}/${user_code}/${school_code}`);
                    break;
                }
            }
        }
    }

    const canShowPriorityView = () => {
        return priorityItems.find((x: any) => x.abilities.length > 0);
    }

    const onClickPracticeInfo = (item: any, index: number) => {
        let items = [...priorityItems];
        items[index].isSelected = !item.isSelected;
        setPriorityItems([...items]);
    }

    const onWorkSheetClick = (ability: any, item: any, priority: any) => {
        localStorage.setItem("backword_key", "false");

        let info: any = item;
        info.ability = ability.ability;
        info.priority = priority;
        info.name = ability.name;
        if (priority > 1 && priorityItems[priority - 2].status === 'pending') {
            return;
        }

        if (item.status === 'pending') {
            const testInfo = info;
            localStorage.setItem('test_info', JSON.stringify(testInfo));
            localStorage.setItem('type', 'WORKSHEETS');
            localStorage.setItem('WORKSHEETS', JSON.stringify(ability.worksheets));
            localStorage.setItem('cycleNumber', currentCycleInfo.cycle_no);
            history.push(`/student-dashboard/list/mla/questions/${modules_uuid}/${user_code}/${school_code}`);

            // history.push(`/student-dashboard/list/mla/test-level-status/${modules_uuid}/${user_code}/${school_code}`);

        }
    }

    const onClickTopic = (item: any) => {

        if (currentCycleInfo.test_results.length == 0) {
            return;
        }
        let result = currentCycleInfo.test_results;
        let data = result.filter((x: any, i: number) => x.topic == item);

        setTopicResult(data);
        setCanShowPopup(true);
    }

    const handleclose = () => {
        setCanShowPopup(false);
    }

    const getFieldColor = (value: any) => {
        if (value === 0) {
            return 'red';
        } else if (value === 1 || value === 2) {
            return '#ED7D31';
        } else if (value === 3) {
            return '#8cc63f';
        } else {
            return '#333333';
        }
    }
    function onClickMNSInfo() {
        setMNSInfo(!MNSInfo);

    }
    const renderMLATestCard = (cycleInfo: any) => {
        return (
            <div>
                <div className='mb-2' onClick={() => { onClickAttemptsInfo(cycleInfo) }}>
                    <a className='status-cards d-flex justify-content-between align-items-center mb-2 position-relative'>
                        <div>
                            <label className='f20 m-0 text-black fw-medium d-block'>Tests</label>
                            <span className='text-black fw-light line-hieight-normal'>{getMlaTestCompletedStatus(cycleInfo) ? 'Completed' : 'Number of Tests'} </span>
                            <ul className='list-inline m-0 attempts cursor-pointer' >
                                {cycleInfo && cycleInfo.tests.map((item: any, i: number) => (
                                    <li className='list-inline-item' key={i} style={{ backgroundColor: item.status === 'completed' ? '#8CC63F ' : '#FFFFFF' }}>{item.test_no}</li>
                                ))}
                            </ul>

                        </div>
                        <div className='ms-2 cursor-pointer'>
                            {!getMlaTestCompletedStatus(cycleInfo) ? <div className='row col-md-4' style={{ float: 'right' }}>
                                <img src={rightArrowImg} style={{ width: '70%', height: '70%', objectFit: 'contain' }} />
                            </div> :
                                <div>
                                    {
                                        badgeLevels.map((item: any, index: number) => (

                                            <div className='text-center d-inline-block ms-4' key={index}>
                                                <img src={SUBJECTCOMPLETE} />
                                            </div>
                                        ))
                                    }
                                </div>
                            }
                        </div>

                    </a>
                </div>
            </div>
        )
    }

    const renderRandomTestView = () => {
        return (
            <div>
                <div style={{ marginTop: '10px' }}>Random New Test</div>
                {renderMLATestCard(currentCycleInfo)}
            </div>
        )
    }

    return (
        <div>
            <div className={`modal fade  ${canShowPopup ? 'show' : 'false'}`} style={{ display: (canShowPopup ? "block" : 'none') }} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog mla-reports-model-dialog" role="document">
                    <div className="modal-content topic-result-model">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">{topicResult && topicResult.length > 0 ? topicResult[0].topic : ''}</h5>
                            <div>
                                <img src={closeIcon} style={{ width: '50%', height: '50%', objectFit: 'contain', cursor: 'pointer' }} onClick={handleclose} />
                            </div>
                        </div>
                        <div className="modal-body">
                            <div className="mt-3 custom-scroll table-responsive">
                                <table className="table ability-table mb-0">
                                    <thead>
                                        <tr>
                                            <th style={{ minWidth: '200px' }}>Ability</th>
                                            <th>Test1 marks</th>
                                            <th>Test2 marks</th>
                                            <th>Test3 marks</th>
                                            <th>Combine Score</th>
                                            <th>Solved worksheet</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {topicResult && topicResult.map((item: any, index: number) => (
                                            <tr key={index}>
                                                <td style={{ minWidth: '200px' }}>{item.name}</td>
                                                <td style={{ fontSize: 11, fontWeight: '600', color: getFieldColor(item.test_1) }}>{item.test_1}</td>
                                                <td style={{ fontSize: 11, fontWeight: '600', color: getFieldColor(item.test_2) }}>{item.test_2}</td>
                                                <td style={{ fontSize: 11, fontWeight: '600', color: getFieldColor(item.test_3) }}>{item.test_3}</td>
                                                <td style={{ fontSize: 11, fontWeight: '600', color: getFieldColor(item.average) }}>{item.average}</td>
                                                <td style={{ fontSize: 11, fontWeight: '600', color: getFieldColor(item.worksheets) }}>{item.worksheets}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="mb-2">
                <span
                    className={`status-cards d-flex justify-content-between align-items-center mb-2 position-relative ${MNSInfo ? "" : "collapsed"} `}
                    data-bs-toggle="collapse"

                    role="button"
                    aria-expanded="false"
                    aria-controls="collapseExample2"
                >
                    <div>
                        <label className="f20 m-0 text-black fw-medium d-block"
                        >MLA-1</label>

                        <span className="text-black fw-light line-hieight-normal"
                        >Minimum Learning Ability</span>

                    </div>
                    <i className="status-cards-arrow" onClick={onClickMNSInfo}></i>
                </span>
                <div className={`collapse ${MNSInfo && "show"}`} id="collapseExample2">
                    <ul className="list-inline result-list-tps mt-3 mb-0">
                        {topics.map((item: any, i: number) => (
                            <li className="list-inline-item cursor-pointer mb-3" key={i} onClick={() => { onClickTopic(item) }}><span className='tps'>{item}</span></li>
                        ))}
                    </ul>
                    {/* <div className='status-cards d-flex align-items-center mb-2'>
                        <div className='py-2'>
                            {topics.map((item: any, i: number) => (
                                <button key={i} className='mla-ability-btn mt-2' onClick={() => { onClickTopic(item) }}>{item}</button>
                            ))}
                        </div>
                    </div> */}
                    {currentCycleInfo && canShowSubItems && renderMLATestCard(getCompletedCycleInfoToShow())}

                    {currentCycleInfo && previousCycleInfo && currentCycleInfo.cycle_no > 1 && renderRandomTestView()}
                    {currentCycleInfo && canShowSubItems && priorityItems && priorityItems.length > 0
                        && canShowPriorityView() &&

                        <div>

                            <h2 className='mt-3 mb-2 f20 text-black fw-medium '>Practice</h2>
                            {priorityItems.map((item: any, index: number) => (
                                <React.Fragment>
                                    {item?.abilities.length > 0 &&
                                        <span
                                            className={`status-cards d-flex justify-content-between align-items-center mb-2 position-relative ${MNSInfo ? "" : "collapsed"} `}
                                            data-bs-toggle="collapse"

                                            role="button"
                                            aria-expanded="false"
                                            aria-controls="collapseExample2" onClick={() => { onClickPracticeInfo(item, index) }}
                                        >
                                            <div>
                                                <label className="f20 m-0 text-black fw-medium d-block"
                                                >Priority {index + 1}</label>
                                                <h6 className='f14 mt-2'>{item.abilities.length} Abilities are In practice</h6>
                                            </div>
                                            <i className={`${item.isSelected ? "status-cards-arrow medium " : "status-cards-arrow medium tr"}`} ></i>
                                        </span>
                                    }
                                    {item.isSelected &&
                                        <div>
                                            {item.abilities && item.abilities.map((ability: any, j: number) => (
                                                <span
                                                    className={`status-cards d-flex justify-content-between align-items-center mb-2 position-relative ${MNSInfo ? "" : "collapsed"} `}
                                                    data-bs-toggle="collapse"
                                                    aria-expanded="false"
                                                    aria-controls="collapseExample2"
                                                >
                                                    <div className='pb-2'>
                                                        {/* <h3 className='f20 mt-2 text-black fw-medium d-block text-mblue'>{ability?.topic} - {ability.name}</h3> */}
                                                        <h3 className='f18 mt-2 text-black fw-medium d-block '>{ability?.topic} - {ability.name}</h3>
                                                        <div className='f14 '>Worksheets</div>
                                                        {ability.worksheets.map((sheet: any, k: number) => (
                                                            <div key={k} className="circle cursor-pointer" style={{ backgroundColor: sheet.status === 'completed' ? '#8CC63F' : '#FFFFFF' }} onClick={() => { onWorkSheetClick(ability, sheet, item.priority) }}>
                                                                <p>{k + 1}</p>
                                                            </div>
                                                        ))}
                                                    </div>
                                                    {item.status === 'completed' &&
                                                        <img src={SUBJECTCOMPLETE} />
                                                    }
                                                    <i className='status-cards-arrow small mt-2'>   </i>

                                                </span>
                                            ))}

                                        </div>
                                    }
                                </React.Fragment>
                            ))}
                        </div>
                    }
                </div>

                {isMlaOneCompleted && <MLATwoCardData />}

            </div>

        </div>
    )
}