import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { Link, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { GET_INDIVIDUAL_BY_USER_CODE } from '../../../../app/service/users.service';
import { MNSProgressView } from '../mns/dashboard';
import SUCCESSIMG from '../../../../institute-dashboard-assets/images/success-img.svg'

export const MLATwoSuccessPage = () => {
    const [userInfo, setUserInfo] = useState<any>({});
    const [timeRemain, setTimeRemain] = useState<any>();
    let { moduleUUID, userCode, schoolCode } = useParams<{ moduleUUID: string, userCode: string, schoolCode: string }>();
    const [loading, setLoading] = useState(false);
    const [testInfo, setTestInfo] = useState<any>({});
    const [type, setType] = useState<any>('');
    const history = useHistory();


    useEffect(() => {
        getUserDetails();
        const testInfo: any = localStorage.getItem("mla_two_test_info");
        const type = localStorage.getItem("mla_two_type");
        const cycleNumber = localStorage.getItem("mla_two_cycle_no");
        const timeRemain = localStorage.getItem("time_remain");
        setTimeRemain(timeRemain);
        setTestInfo(JSON.parse(testInfo));
        setType(type);
    }, []);

    function getUserDetails() {
        setLoading(true);
        GET_INDIVIDUAL_BY_USER_CODE(userCode).then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res.message);
                setLoading(false);
            } else {
                if (res) {
                    const data = res.rows;
                    if (data.length > 0) {
                        setUserInfo(data[0]);
                    }
                    setLoading(false);
                }
            }
        });
    }

    function onClickStart() {
        // history.push(`/dashboard/mns/dashboard/${moduleUUID}/${userCode}/${schoolCode}`);
        history.push(`/student-dashboard/list/mns/view/${moduleUUID}/${userCode}/${schoolCode}`);
    }

    function onAbilitywiseReport() {
        history.push(`/student-dashboard/list/mla-two/ability-result/${moduleUUID}/${userCode}/${schoolCode}`);
    }

    return (
        <div>
            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading &&
                <div>
                    <div>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb pb-2 ps-0 pt-0 border-bottom">
                                <li className="breadcrumb-item "><Link to="/student-dashboard/list"><span className='pe-2'>Dashboard</span></Link></li> /

                                <li className="breadcrumb-item "><span>MLA-2</span></li>
                            </ol>
                        </nav>
                        <div className="mt-3">
                            <h2 className="f22 fw-bold">
                                MLA-2 <span className="fw-light">(Minimum Learning Ability)</span>
                            </h2>
                        </div>
                        <div className='p-3 time-block mt-3 d-md-flex justify-content-between align-items-end'>
                            <div>
                                <h2 className='m-0'>Test - 01</h2>
                                <h3 className='m-0'>Time Limit : 48 hours for all 3 tests</h3>
                            </div>
                            <div>
                                <p className='mt-3 mb-0 m-md-0 f12'>Remaining Time</p>
                                <h1 className='m-0'>{timeRemain} <small>hrs</small></h1>

                            </div>
                        </div>
                        <div className="row">
                            <div className='col-md-9 text-center success-block mt-5'>
                                <div>
                                    <img src={SUCCESSIMG} alt="" />
                                    <h2 className="mt-3">{userInfo.name}</h2>
                                    <h1 className="text-completed fw-light">
                                        <small className="f22 text-mdgray">You have </small>Sucessfully
                                        Completed
                                    </h1>
                                    <h1 className='fw-light'>{type === 'TESTS' ? 'MLA Test-' + testInfo.test_no : ''}</h1>

                                </div>
                                {testInfo.test_no !== 3 && <div>
                                    <p className="f22 fw-light mt-5 m-0">You can now</p>
                                    <p className="lelvel-text fw-light m-0">Attempt Level MLA Test-{testInfo.test_no + 1}</p>
                                    <p className='f22 fw-light m-0'> Remaining Time {timeRemain}</p>
                                </div>}
                                {testInfo.test_no === 3 && <div>
                                    <p className="f22 fw-light mt-5 m-0">You can now</p>
                                    <div style={{ fontSize: '35px', color: '#0F70CD', cursor: 'pointer' }} onClick={() => { onAbilitywiseReport() }}>Abilities wise results</div>
                                </div>}


                                <button className='btn btn-primary mb-3  px-3 mt-5' onClick={() => { onClickStart() }}>
                                    Start Now
                                </button>


                            </div>
                            <div className="col-md-3">
                                <div className="score-card-sticky">
                                    <MNSProgressView />

                                </div>
                            </div>
                        </div>
                    </div>

                </div>}
        </div>
    )
}