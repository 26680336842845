import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import rightArrowImg from '../../../../assets/images/Arrow-Right.png';
import downArrowImg from '../../../../assets/images/Arrow-Down.png';
import CommonLevel from '../comon-level';

export const MNSCard = (props: any) => {
    const status = props.data.status;
    const [assesmentTestStatus, setAssesmentTestStatus] = useState<any>();
    const [MNSDetails, setMNSDetails] = useState<any>([]);
    const [MNSInfo, setMNSInfo] = useState(false);
    const history = useHistory();
    let { moduleUUID, userCode, schoolCode } = useParams<{ moduleUUID: string, userCode: string, schoolCode: string }>();
    const modules_uuid = moduleUUID;
    const user_code = userCode;
    const school_code = schoolCode;

    useEffect(() => {
        const status = props.data.status;
    }, []);

    function onClickMNSInfo(type: any) {
        if (type === 'right') {
            setMNSInfo(true);
        } else if (type === 'down') {
            setMNSInfo(false);
        }
    }

    return (
        <div>
            <div className="card info-card-2 shadow rounded mb-4 cursor-pointer">
                <img className="card-img-top" />
                <div className={MNSInfo ? 'card-body p-3 mns-card-info' : 'card-body p-3'}>
                    < div className='row'>
                        <div className='col-md-9 px-3'>
                            <div className="">
                                <h3>MNS</h3>
                                <h6>Minimum Numerical Skills</h6>
                            </div>
                        </div>
                        <div className='col-md-3'>
                            <div className=''>
                                {!MNSInfo && <img className='cursor-pointer' src={rightArrowImg} style={{ width: '50%', height: '50%', objectFit: 'contain' }} onClick={() => { onClickMNSInfo('right') }} />}
                                {MNSInfo && <img className='cursor-pointer' src={downArrowImg} style={{ width: '80%', height: '80%', objectFit: 'contain' }} onClick={() => { onClickMNSInfo('down') }} />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                MNSInfo &&
                <div>
                    {status && status.length == 0 &&
                        <CommonLevel
                            data={status}
                            level={"A"}
                        />}
                    {status && status.find((x: any) => x.level === 'A') &&
                        <CommonLevel
                            data={status}
                            level={"A"}
                        />}
                    {status && status.find((x: any) => x.level === 'B') &&
                        <CommonLevel
                            data={status}
                            level={"B"}
                        />}
                    {status && status.find((x: any) => x.level === 'C') &&
                        <CommonLevel
                            data={status}
                            level={"C"} />}
                </div>
            }
        </div >
    )
}