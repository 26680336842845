import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router'
import { Link, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { GET_MNS_STUDENT_STATUS } from '../../../../app/service/mns-service';
import { GET_INDIVIDUAL_BY_USER_CODE, GET_USER_USER_CODE } from '../../../../app/service/users.service';
import SUCCESSIMG from '../../../../institute-dashboard-assets/images/success-img.svg'
import { MNSCardsData } from './card/mns-card';
import { MNSProgressView } from './dashboard';
import NOWORRIES from '../../../../assets/audioclips/Noworries.mp3'
import FANTASTIC from '../../../../assets/audioclips/Fantastic.mp3'
import AWESOME from '../../../../assets/audioclips/Awesome.mp3'
import FAILEDGIF from '../../../../assets/animated-gif/failedgif.gif'
import PASSGIF from '../../../../assets/animated-gif/passgif.gif'
import FAILEDTUNE from '../../../../assets/audioclips/failed-tune.mp3'
import PASSTUNE from '../../../../assets/audioclips/pass-tune.mp3'
import TESTSUBMIT from '../../../../assets/audioclips/submit-test-tune.mp3'
import ROADMAPMUSIC from '../../../../assets/audioclips/roadmap-music.mp3'
import MNSLEVELCOMPLETE from '../../../../assets/animated-gif/mns-level-complete.gif'
import LOCATIONPOINT from '../../../../assets/animated-gif/location-point.gif'
import SUCCESSFLAGE from '../../../../assets/images/success-flag.png'
import ROADMAP from '../../../../assets/images/road-map.png'
import NEXTLEVELBTN from '../../../../assets/animated-gif/next-level-btn.gif'


function TestSuccess() {
    const [mnsInfo, setMnsInfo] = useState<any>({});
    const [userInfo, setUserInfo] = useState<any>({});
    let { level, mode, moduleUUID, userCode, schoolCode, testNo } = useParams<{ level: string, mode: string, moduleUUID: string, userCode: string, schoolCode: string, testNo: string }>();
    const [completedLevel, setCompletedLevel] = useState(level ? level : '');
    const [loading, setLoading] = useState(false);
    const [MNSStudentStatus, setMNSStudentStatus] = useState<any>({});
    const history = useHistory();
    const [showAudio, setShowAudio] = useState(false);
    const audioRef = useRef<HTMLAudioElement | null>(null);
    const [levels, setLevels] = useState<any>([]);
    const [mapLoader, setMapLoader] = useState<boolean>(false);
    const [audioFiles, setAudioFiles] = useState<any>('');
    let foundPass: boolean = false;
    const [count, setCount] = useState<number>(0);
    const [responseStatus, setResponseStatus] = useState<any>({});

    useEffect(() => {

        sessionStorage.removeItem("questionsData")
        sessionStorage.removeItem('tempInfo')
        sessionStorage.removeItem("setTime")
        getMNSStudentStatus();
        localStorage.removeItem('setTimer');
        const data: any = localStorage.getItem('mnsInfo');
        const preparedData = JSON.parse(data);
        setTimeout(() => {
            setShowAudio(true);
        }, 2000);
        setMnsInfo(preparedData);
        getUserDetails();

        if (mode === "assesment_test") {
            setTimeout(() => {
                setMapLoader(true);
                setCount(1);
                setMapLoader(false);
            }, 6000);
        }
    }, []);


    function getMNSStudentStatus() {
        let payload = {
            module_uuid: moduleUUID,
            school_code: schoolCode,
            user_code: userCode
        }
        setLoading(true);

        GET_MNS_STUDENT_STATUS(payload).then((res: any) => {
            setResponseStatus(res);
            if (res.status === 'fail') {
                toast.error(res.message);
                setLoading(false);

            } else {
                if (res) {
                    const data = res;
                    setMNSStudentStatus(data);
                    for (let x = 0; x < data.status.length; x++) {
                        if (data.status[x].status === "completed" && data.status[x].sub_level === completedLevel) {
                            setCount(0);
                            setTimeout(() => {
                                setCount(1);
                            }, 5000);
                        }
                    }

                    var configData: any = [];
                    Object.keys(data?.config).forEach(element => {
                        data?.config[element].forEach((innerElement: any) => {
                            configData.push(innerElement)
                        });
                    });
                    configData.forEach((element: any, index: number) => {
                        const isLavelStatus = data?.status.find((value: any) => element?.level === value?.sub_level);
                        if (isLavelStatus) {
                            element.status = isLavelStatus?.status;
                            // if (isLavelStatus?.level_skip) {
                            //     element.level_skip = isLavelStatus?.level_skip;

                            // }
                        }
                    })
                    configData[0].positionLeft = '108px';
                    configData[1].positionLeft = '61px';
                    configData[1].positionbottom = '26px';
                    configData[2].positionLeft = '-25px';
                    configData[2].positionbottom = '5px';
                    configData[3].positionLeft = '-127px';
                    configData[3].positionbottom = '-31px';
                    configData[4].positionLeft = '-148px';
                    configData[4].positionbottom = '-12px';
                    configData[5].positionLeft = '-57px';
                    configData[5].positionbottom = '-11px';
                    configData[5].size = '18px';
                    configData[6].positionLeft = '-118px';
                    configData[6].positionbottom = '-20px';
                    configData[6].size = '18px';
                    configData[7].positionLeft = '-27px';
                    configData[7].positionbottom = '-27px';
                    configData[7].size = '18px';
                    configData[8].positionLeft = '-82px';
                    configData[8].positionbottom = '-48px';
                    configData[8].size = '18px';
                    let preparedElemets: any = [];
                    let preparedData: any = [...configData.reverse()]
                    for (let x = 0; x < preparedData.length; x++) {

                        if (preparedData[x]?.status) {
                            foundPass = true;
                            preparedElemets.push(preparedData[x]);
                        } else if (foundPass) {
                            preparedData[x].status = "completed"
                            preparedElemets.push(preparedData[x]);

                        } else {
                            preparedElemets.push(preparedData[x]);
                        }
                    }
                    setLevels(preparedElemets);
                    setLoading(false);

                }

            }

        });
    }


    function getUserDetails() {
        GET_USER_USER_CODE(userCode).then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res.message);
            } else {
                if (res) {
                    const data = res.rows;
                    if (data.length > 0) {
                        setUserInfo(data[0]);
                    }
                }
            }
        });
    }

    const getPendingLevel = () => {
        let level = MNSStudentStatus && MNSStudentStatus?.status?.length > 0 ? MNSStudentStatus?.status?.find((x: any) => x.status === 'pending') : null;
        return level;
    }

    function onClickStart() {
        localStorage.setItem("backword_key", "false");
        if (getPendingLevel()) {
            history.push(`/student-dashboard/list/mns/questions/${moduleUUID}/${userCode}/${schoolCode}`);
        }
        else {
            history.push(`/student-dashboard/list/mns/view/${moduleUUID}/${userCode}/${schoolCode}`);

        }

    }
    function getAudio() {

        if (mnsInfo?.result === "fail") {

            return NOWORRIES
        }
        if (mnsInfo?.student_percentage == 100 && mnsInfo?.result === "pass") {
            return FANTASTIC
        }
        if (mnsInfo?.student_percentage != 100 && mnsInfo?.result === "pass") {
            return AWESOME
        }
    }
    function getAudioTune() {

        if (mnsInfo?.result === "fail") {
            return FAILEDTUNE
        } else {
            return PASSTUNE
        }
    }
    const getLogo = (data: any) => {

        if (data?.status === 'pending') {
            return '#FF9933';
        } else if (data.status === "completed") {
            return '#74B72E';
            // return '#696969';
        }
        //  else if (data.status === "completed" && data?.level_skip === "yes") {
        //     return '#e3e3e3';
        // }
        else {
            return '#ffffff';
        }
    }
    const testResults = () => {
        history.push(`/student-dashboard/list/mns/result-view/${level}/${mode}/${moduleUUID}/${userCode}/${schoolCode}/${testNo}`);
    }

    return (
        <div>

            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading &&
                <div>
                    <div className=''>
                        <audio autoPlay>
                            <source src={getAudioTune()} type="audio/mpeg" />
                        </audio>
                        {showAudio &&
                            <audio autoPlay>
                                <source src={getAudio()} type="audio/mpeg" />
                            </audio>
                        }
                    </div>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb pb-2 ps-0 pt-0 border-bottom">
                            <li className="breadcrumb-item "><Link to="/student-dashboard/list"><span className='pe-2'>Dashboard</span></Link></li> /

                            <li className="breadcrumb-item "><span>MNS  Test</span></li>
                        </ol>
                    </nav>
                    <div className="mt-3">
                        <h2 className="f22 fw-bold">
                            MNS <span className="fw-light">(Minimum Numerical Skills)</span>
                        </h2>
                    </div>
                    <div className="row">
                        <div className='col-lg-9 text-center'>
                            {mapLoader &&
                                <div className="text-center p-5">
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div>
                            }
                            {!mapLoader && <div className="success-block">

                                {(count == 1) &&
                                    <div>

                                        <audio autoPlay loop>
                                            <source src={ROADMAPMUSIC} type="audio/mpeg" />
                                        </audio>

                                        <p className='mb-0 me-5 pe-5  position-relative' style={{ top: "80px" }}>
                                            <img
                                                src={SUCCESSFLAGE}
                                                alt="GIF"
                                                style={{ width: '50px', height: '50px' }}
                                                className="gif-image me-5"
                                            />
                                        </p>
                                        <p className='mb-0 position-relative' style={{ position: "relative" }}>
                                            <img
                                                src={ROADMAP}
                                                alt="GIF"
                                                style={{ width: '400px', height: '400px' }}
                                                className="gif-image"
                                            />
                                            <ul className="list-block m-0 attempts list-unstyled position-absolute" style={{
                                                top: "30px",
                                                left: '50%'
                                            }}>
                                                {levels?.map((item: any, i: number) => (
                                                    <li className={`list-item list-style-none p-1 mb-3 f12 ms-1 border-0 ${i == 1 && "ms-2"}}`}
                                                        style={{ backgroundColor: getLogo(item), fontWeight: "bold", position: 'relative', left: item?.positionLeft, bottom: item?.positionbottom, fontSize: i < 4 ? "8px" : "10px", width: item?.size, height: item?.size }} key={i}>
                                                        {item.level}
                                                        {(item?.status === 'pending') && <img
                                                            src={LOCATIONPOINT}
                                                            alt="GIF"
                                                            style={{ width: '60px', height: '60px', left: '-21px', bottom: '0px' }}
                                                            className="gif-image position-absolute"
                                                        />}
                                                    </li>
                                                ))}
                                            </ul>
                                            <p className='position-relative' style={{ bottom: "112px", right: "25px" }}>
                                                <span className='f18 font-weight-bold text-white' style={{ fontWeight: "bold" }}>Next Level</span>
                                                <img
                                                    src={NEXTLEVELBTN}
                                                    alt="GIF"
                                                    style={{ width: '140px', height: '120px' }}
                                                    className="gif-image cursor-pointer"
                                                    onClick={() => { onClickStart() }}
                                                />
                                            </p>
                                        </p>

                                    </div>}
                            </div>}
                            {mnsInfo && mnsInfo.result !== 'fail' && <div className="success-block">

                                <audio ref={audioRef}>
                                    <source src={TESTSUBMIT} type="audio/mpeg" />
                                </audio>

                                {(mnsInfo.result === 'pass' && count == 0) && <>
                                    <img
                                        src={PASSGIF}
                                        alt="GIF"
                                        style={{ width: '170px', height: '170px' }}
                                        className="gif-image"
                                    />
                                    <div className='block_text'>
                                        <div className='animated-text'>
                                            <h2 className="mt-3">{userInfo.name}</h2>
                                            <h1 className="text-completed fw-light">
                                                <small className="f22 text-mdgray">You have </small>successfully
                                                completed
                                            </h1>
                                            <h1 className="text-completed fw-light">
                                                <small className="f22 text-mdgray">{mode && mode === 'assesment_test' ? 'Entry Test' : `Level ${completedLevel} - Test ${testNo}`} - </small> <span className='f22 text-black'>with  score as</span> {mnsInfo && mnsInfo ? mnsInfo?.student_score : ''}
                                            </h1>
                                            {mode && mode === 'assesment_test' ? <div>
                                                <p className="f22 fw-light mt-5 m-0">You can now</p>
                                                <p className="lelvel-text fw-light m-0">Attempt Level {mnsInfo && mnsInfo ? mnsInfo.next_sub_level : ''}</p>
                                            </div> : Number(mnsInfo && mnsInfo && mnsInfo.consecutive_success ? mnsInfo.consecutive_success : 0) == 7 && <div>
                                                <p className="f22 fw-light mt-5 m-0">You can now</p>
                                                {MNSStudentStatus && MNSStudentStatus?.assesment_test?.status === 'pending' ? <p className="lelvel-text fw-light m-0">Attempt Level {mnsInfo && mnsInfo ? mnsInfo.next_sub_level : ''}</p> : <p className="lelvel-text fw-light m-0">Attempt MLA</p>}
                                            </div>}
                                        </div>
                                    </div>
                                </>}
                            </div>}

                            {(mnsInfo.result === 'fail' && count == 0) && <div className="success-block">
                                <img
                                    src={FAILEDGIF}
                                    alt="GIF"
                                    style={{ width: '135px', height: '135px' }}
                                    className="gif-image"
                                />
                                <div className='block_text'>
                                    <div className='animated-text'>
                                        <h2 className="mt-3">{userInfo.name}</h2>
                                        <h1 className="text-completed fw-light">
                                            <small className="f22 text-mdgray">You have </small> <span className='text-inprogess'> not successfully completed</span>
                                        </h1>
                                        <h1 className="text-completed fw-light">
                                            <small className="f22 text-mdgray">{mode && mode === 'assesment_test' ? 'Entry Test' : `Level ${completedLevel} - Test ${testNo}`} - </small>   <span className='f22 text-black'>your score was</span> {mnsInfo && mnsInfo ? mnsInfo?.student_score : ''}
                                        </h1>
                                    </div>
                                </div>
                            </div>}

                            {(count == 0) && <div className='block_text'>
                                <button className='btn btn-primary mb-3  px-3 mt-1 animated-text' onClick={() => { onClickStart() }}>
                                    {mnsInfo && mnsInfo && mnsInfo.result === 'fail' ? 'Test Again ' : 'Start Now '}<span><i className="fa fa-arrow-right ms-3" style={{ fontSize: "17px" }}></i></span>
                                </button>
                            </div>}
                            {responseStatus.status === 'fail' && < div className="success-block">
                                <h6 className='text-inprogess'>Technical Issue</h6>
                            </div>}
                        </div>
                        <div className="col-lg-3">
                            <div className="score-card-sticky">
                                {mode === "assesment_test" &&
                                    <span
                                        className="status-cards d-flex justify-content-between align-items-center mb-2 position-relative collapsed cursor-pointer" onClick={testResults}

                                    >
                                        <h3 className="f20 m-0 text-black fw-medium">Check Entry Test Results</h3>
                                        <i className="status-cards-arrow"></i>

                                    </span>
                                }
                                <MNSProgressView />
                            </div>
                        </div>
                    </div>
                </div>}
        </div>

    )
}
export default TestSuccess;