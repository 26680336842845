import API from "../utility/axios";
const apiUrl = "/api/v2";

// export const GET_PARIKSHA_TEST_LIST = (
//   pageNumber: number,
//   boardUuid: string,
//   mediumUuid: string,
//   standardUuid: string,
//   subjectUuid: string,

//   Type: string,
//   data: any
// ): Promise<any> => {
//   return API.get(
//     `${apiUrl}/abhyaas/pariksha?limit=${10}&page=${pageNumber}&board_uuid=${boardUuid}&medium_uuid=${mediumUuid}&standard_uuid=${standardUuid}&subject_uuid=${subjectUuid}&type=${Type}`,
//     {
//       params: data,
//     }
//   );
// };

export const GET_PARIKSHA_TEST_LIST = (
  pageNumber: number,
  Type: string,
  data: any
): Promise<any> => {
  return API.get(
    `${apiUrl}/abhyaas/pariksha?limit=${10}&page=${pageNumber}&type=${Type}&mode=PARIKSHA&sorting_field=number&sorting_type=asc`,
    {
      params: data,
    }
  );
};

export const GET_PARIKSHA_TEST_BY_UUID = (Uuid: string): Promise<any> => {
  return API.get(`${apiUrl}/abhyaas/pariksha?uuid=${Uuid}`);
};

export const UPDATE_PARIKSHA_TEST = (data: any): Promise<any> => {
  return API.post(`${apiUrl}/abhyaas/pariksha`, data);
};

export const CREATE_PARIKSHA_TEST = (data: any): Promise<any> => {
  return API.post(`${apiUrl}/abhyaas/pariksha`, data);
};

export const GET_PARIKSHA_TEST_FILTER = (
  data: any,
  Type: string
): Promise<any> => {
  return API.get(
    `${apiUrl}/abhyaas/pariksha?limit=${10}&page=${0}&type=${Type}`,
    { params: data }
  );
};
export const DELETE_PARIKSHA_TEST = (id: number): Promise<any> => {
  return API.delete(`${apiUrl}/abhyaas/pariksha/${id}`);
};

export const GET_PARIKSHA_SELECT_TEST_LIST = (
  boardUuid: string,
  mediumUuid: string,
  standardUuid: string,
  subjectUuid: string,
  Type: string,
  mode: string
): Promise<any> => {
  return API.get(
    `${apiUrl}/abhyaas/pariksha?board_uuid=${boardUuid}&medium_uuid=${mediumUuid}&standard_uuid=${standardUuid}&subject_uuid=${subjectUuid}&type=${Type}&mode=${mode}`
  );
};
