import React from 'react'
import { Route, Switch } from 'react-router'
import { AbhyasBoardList } from '../pages/dashboard/modules/abhyas/board/list';
import { AbhyasMediumList } from '../pages/dashboard/modules/abhyas/medium/list';
import { AbhyasStandardList } from '../pages/dashboard/modules/abhyas/standard/list';
import { AbhyasSubjectList } from '../pages/dashboard/modules/abhyas/subject/list';
import { AbhyasChapterList } from '../pages/dashboard/modules/abhyas/chapter/list';
import { AbhyasTopicList } from '../pages/dashboard/modules/abhyas/topic/list';
import AbhyasQuestionsList from '../pages/dashboard/modules/abhyas/question/questions-list';
import { AbhyasWorksheetList } from '../pages/dashboard/modules/abhyas/worksheets/list';
import { AbhyasWorksheetDataList } from '../pages/dashboard/modules/abhyas/worksheets/worksheet-list';
import { AbhyasWorksheetInfo } from '../pages/dashboard/modules/abhyas/worksheets/worksheet-info';
import { AbhyasTestDataList } from '../pages/dashboard/modules/abhyas/tests/list';
import AbhyasInfoPage from '../pages/dashboard/modules/abhyas';
import { ParikshaTestDataList } from '../pages/dashboard/modules/pariksha/test/list';
import ParikshaQuestionsList from '../pages/dashboard/modules/pariksha/question/list';

const NestedAbhyasList = () => {
    return (
        <Switch>
            <Route path="/dashboard/module/info/ABHYAS/:code/:id/worksheet" component={AbhyasBoardList}></Route>
            <Route path="/dashboard/module/info/ABHYAS/:code/:id/:BoardUuid/:BoardName/medium" component={AbhyasMediumList}></Route>
            <Route path="/dashboard/module/info/ABHYAS/:code/:id/:BoardUuid/:BoardName/:MediumUuid/:MediumName/standard" component={AbhyasStandardList}></Route>
            <Route path="/dashboard/module/info/ABHYAS/:code/:id/:BoardUuid/:BoardName/:MediumUuid/:MediumName/:StandardUuid/:StandardName/subject" component={AbhyasSubjectList}></Route>
            <Route path="/dashboard/module/info/ABHYAS/:code/:id/:BoardUuid/:BoardName/:MediumUuid/:MediumName/:StandardUuid/:StandardName/:SubjectUuid/:SubjectName/pariksha-chapter" component={AbhyasChapterList}></Route>
            <Route path="/dashboard/module/info/ABHYAS/:code/:id/:BoardUuid/:BoardName/:MediumUuid/:MediumName/:StandardUuid/:StandardName/:SubjectUuid/:SubjectName/chapter" component={AbhyasWorksheetInfo}></Route>
            <Route path="/dashboard/module/info/ABHYAS/:code/:id/:BoardUuid/:BoardName/:MediumUuid/:MediumName/:StandardUuid/:StandardName/:SubjectUuid/:SubjectName/:ChapterUuid/:ChapterName/topic" component={AbhyasTopicList}></Route>
            <Route path="/dashboard/module/info/ABHYAS/:code/:id/:BoardUuid/:BoardName/:MediumUuid/:MediumName/:StandardUuid/:StandardName/:SubjectUuid/:SubjectName/:ChapterUuid/:ChapterName/:TopicUuid/:TopicName/:SheetUuid/:SheetNo/:Type/question" component={AbhyasQuestionsList}></Route>
            <Route path="/dashboard/module/info/ABHYAS/:code/:id/:BoardUuid/:BoardName/:MediumUuid/:MediumName/:StandardUuid/:StandardName/:SubjectUuid/:SubjectName/:SheetUuid/:SheetNo/:Type/abhyas-test-question" component={AbhyasWorksheetInfo}></Route>
            <Route path="/dashboard/module/info/ABHYAS/:code/:id/:BoardUuid/:BoardName/:MediumUuid/:MediumName/:StandardUuid/:StandardName/:SubjectUuid/:SubjectName/:ChapterUuid/:ChapterName/:TopicUuid/:TopicName/worksheet-list" component={AbhyasWorksheetDataList}></Route>
            <Route path="/dashboard/module/info/ABHYAS/:code/:id/:BoardUuid/:BoardName/:MediumUuid/:MediumName/:StandardUuid/:StandardName/:SubjectUuid/:SubjectName/test" component={AbhyasWorksheetInfo}></Route>
            {/* pariksha */}
            <Route path="/dashboard/module/info/ABHYAS/:code/:id/:BoardUuid/:BoardName/:MediumUuid/:MediumName/:StandardUuid/:StandardName/:SubjectUuid/:SubjectName/:ChapterUuid/:ChapterName/:TopicUuid/:TopicName/pariksha-test" component={ParikshaTestDataList}></Route>
            <Route path="/dashboard/module/info/ABHYAS/:code/:id/:BoardUuid/:BoardName/:MediumUuid/:MediumName/:StandardUuid/:StandardName/:SubjectUuid/:SubjectName/:ChapterUuid/:ChapterName/:TopicUuid/:TopicName/:SheetUuid/:SheetNo/:Type/test-question" component={ParikshaQuestionsList}></Route>
            <Route path="/dashboard/module/info/ABHYAS/:code/:id/worksheet-list" component={AbhyasWorksheetList}></Route>

        </Switch>
    )
}

export default NestedAbhyasList;