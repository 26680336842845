import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { GET_MLA_TWO_QUESTIONS, MLA_TWO_ANSWERS_UPDATE, MLA_TWO_TIME_UPDATE } from '../../../app/service/mla-two.service';
import { MathJaxContext, MathJax } from 'better-react-mathjax';
import Countdown from 'react-countdown';


export const MLATwoQuestionsForm = () => {
    const [questionsData, setQuestionsData] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    const [startTimer, setStartTimer] = useState(false);
    const [canShowHeader, setShowHeader] = useState(false);
    const [testInfo, setTestInfo] = useState<any>({});
    const [type, setType] = useState<any>('');
    const [cycleNumber, setCycleNumber] = useState<any>('');
    const [worksheets, setWorksheets] = useState<any[]>([]);
    const [timeRemain, setTimeRemain] = useState<any>('');
    const [startTime, setStartTime] = useState(new Date());
    const [alphabets, setAlphabets] = useState<any>(["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z"]);
    const [canShowPopup, setCanShowPopup] = useState(false);
    const [test_start_date, setTestStartDate] = useState<any>(getTimeDetails());
    let { moduleUUID, userCode, schoolCode } = useParams<{ moduleUUID: string, userCode: string, schoolCode: string }>();

    const history = useHistory();

    useEffect(() => {
        getMLATwoInfo();
        getTimeInfo();
    }, []);

    function getTimeDetails() {
        const testStartDate: any = localStorage.getItem("mla_two_test_start_date");

        return testStartDate ? testStartDate : null;
    }

    function getTimeInfo() {
        const testStartDate: any = localStorage.getItem("mla_two_test_start_date");
        let time = getTime(testStartDate);
        let timeMillis = moment(time, 'YYYY-MM-DD hh:mm:ss').toDate().getTime();
        let reqTimeMillis = 2 * 24 * 60 * 60 * 1000;
        let actualTimeToComplete = timeMillis + reqTimeMillis;
        let x = new Date(actualTimeToComplete)
        setTimeRemain(x);
    }

    function getTime(time: any) {
        if (time !== 'null') {
            return time;
        } else {
            return time ? time : startTime;
        }
    }


    function getMLATwoInfo() {
        const testInfo: any = localStorage.getItem("mla_two_test_info");
        const type = localStorage.getItem("mla_two_type");
        const cycleNumber = localStorage.getItem("mla_two_cycle_no");
        const worksheets: any = localStorage.getItem("mla_two_worksheets");
        const testStartDate: any = localStorage.getItem("mla_two_test_start_date");
        setTestInfo(JSON.parse(testInfo));
        setType(type);
        setCycleNumber(cycleNumber);
        getMLAQuestionsList(JSON.parse(testInfo), type, testStartDate, cycleNumber);
        setWorksheets(JSON.parse(worksheets));
    }


    function getMLAQuestionsList(testInfo: any, type: any, testStartDate: any, cycleNumber: any) {
        let payload = {
            "mode": type,
            "test_no": testInfo.question_paper_id ? testInfo?.question_paper_id : '',
            "ability": testInfo.ability ? testInfo?.ability : '',
            "worksheet_no": testInfo.question_paper_id ? testInfo?.question_paper_id : ''
        }

        setShowHeader(false);
        setLoading(true);
        GET_MLA_TWO_QUESTIONS(payload).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    if (res) {
                        if (res.questions && res.questions.length > 0) {
                            setQuestionsData(res.questions);

                            // ///hack///
                            // const data = res.questions;
                            // for (let i = 0; i < data.length; i++) {
                            //     data[i].answer = data[i].correct_answer;
                            // }
                            // setQuestionsData(data);
                            // ///hack///
                            setShowHeader(true);
                            setStartTimer(true);
                            setLoading(false);
                            if (type === 'TESTS' && (!test_start_date || test_start_date == '' || test_start_date === 'null')) {
                                // setTimeRemain("48:00:00 hrs");
                                setTestStartDate(new Date());
                                storeInitialTimeForTest(cycleNumber);
                            }
                        } else {
                            setQuestionsData([]);
                            setShowHeader(true);
                            setStartTimer(true);
                            setLoading(false);
                        }

                    }

                }
            }
        });
    }

    function storeInitialTimeForTest(cycleNumber: any) {
        let payload = {
            "user_code": userCode,
            "school_code": schoolCode,
            "cycle_no": cycleNumber ? parseInt(cycleNumber) : 1,
            "test_start_date": moment(startTime).format('YYYY-MM-DD HH:mm:ss')
        }

        MLA_TWO_TIME_UPDATE(payload).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                } else {
                    if (res) {
                    }
                }
            }
        });
    }

    function onViewQuestion(name: any) {
        return { __html: name };
    }


    function handleQuestionOptions(item: any, index: number, j: number) {
        const data = questionsData;
        for (let i = 0; i < alphabets.length; i++) {
            if (j === i) {
                data[index].answer = alphabets[i];
            }
        }
        setQuestionsData(data);
    }


    const handleCompleteSubmit = () => {
        let payload = {
            "user_code": userCode,
            "school_code": schoolCode,
            "cycle_no": cycleNumber ? parseInt(cycleNumber) : 1,
            "mode": type,
            "test_no": testInfo && testInfo.test_no ? testInfo.test_no : 1,
            // "question_paper_id": testInfo?.question_paper_id ? testInfo?.question_paper_id : '',
            "question_paper_id": type === 'TESTS' ? (testInfo?.question_paper_id ? testInfo?.question_paper_id : '') : (testInfo?.id ? testInfo?.id : ''),
            "question_paper_level": testInfo.level ? testInfo.level : '',
            "ability": testInfo.ability ? testInfo.ability : '',
            "priority_no": testInfo?.priority ? testInfo?.priority : '',
            "time_taken": 10,
            "test_start_date": moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
            "questions": questionsData
        }
        setLoading(true);
        MLA_TWO_ANSWERS_UPDATE(payload).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    setLoading(false);
                    const data = res;
                    if (type === 'WORKSHEETS') {
                        setCanShowPopup(true);
                        // toast.success('You have successfully completed worksheet');
                        return;
                    }
                    localStorage.setItem('time_remain', timeRemain);
                    history.push(`/dashboard/mla-two/success-page/${moduleUUID}/${userCode}/${schoolCode}`);
                }

            }
        });
    }

    const getCircleColor = (item: any) => {
        // if (item.question_paper_id === testInfo.question_paper_id) {
        if (item.id === testInfo.id) {
            return '#FCEE21';
        } else if (item.status === 'completed') {
            return '#8CC63F';
        } else {
            return '#FFFFFF';
        }
    }

    const handleBack = () => {
        history.push(`/dashboard/mns/dashboard/${moduleUUID}/${userCode}/${schoolCode}`);
    }

    const handleclose = () => {
        setCanShowPopup(false);
        history.push(`/dashboard/mns/dashboard/${moduleUUID}/${userCode}/${schoolCode}`)
    }

    const handleSubmit = () => {
        setCanShowPopup(false);
        history.push(`/dashboard/mns/dashboard/${moduleUUID}/${userCode}/${schoolCode}`);
    }

    // Renderer callback with condition
    const renderer = ({
        days,
        hours,
        minutes,
        seconds,
        completed,
    }: {
        days: any;
        hours: any;
        minutes: any;
        seconds: any;
        completed: any;
    }) => {
        if (completed) {
            return <div></div>
            // Render a completed state
        } else {
            // Render a countdown
            return (
                <span style={{ fontSize: "1rem", paddingTop: "10px" }}>
                    {hours} Hr - {minutes} Min - {seconds} Sec
                </span>
            );
        }
    };

    return (
        <div className="row border-top border-primary py-3">
            <div className={`modal fade ${canShowPopup ? 'show' : 'false'}`} style={{ display: (canShowPopup ? "block" : 'none') }} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">You have successfully completed worksheet</h5>
                            <button type="button" className="close popup-close cursor-pointer" data-dismiss="modal" aria-label="Close" onClick={handleclose}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary cursor-pointer" onClick={handleSubmit}>OK</button>
                            <button type="button" className="btn btn-secondary cursor-pointer" data-dismiss="modal" onClick={handleclose}>CANCEL</button>
                        </div>
                    </div>
                </div>
            </div>
            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading && <div>
                {canShowHeader && <div className="row mt-4">
                    {type === 'TESTS' && < div className='col-md-8'>
                        <div style={{ fontSize: '14px' }}>MLA</div>
                        <div style={{ fontSize: '25px', fontStyle: 'italic', fontWeight: 'bold' }}>Test -0{testInfo.test_no ? testInfo.test_no : ''}</div>
                    </div>}
                    {type === 'WORKSHEETS' && <div className='col-7 col-md-10'>
                        <div style={{ fontSize: '14px' }}>Priority - {testInfo.priority}</div>
                        <div style={{ fontSize: '25px', fontWeight: 'bold' }}>{testInfo.name}</div>
                        <div style={{ fontSize: '12px' }}>Worksheets</div>
                        <div className='col-md-8'>
                            {worksheets.map((item: any, i: number) => (
                                <div key={i} className="circle" style={{ backgroundColor: getCircleColor(item) }}>
                                    <p>{i + 1}</p>
                                </div>
                            ))}
                        </div>
                    </div>}
                    {type === 'TESTS' && <div className='col-md-2'>
                        <div>Time Limit</div>
                        <div>
                            {/* {timeRemain} */}
                            {timeRemain && <Countdown
                                date={timeRemain}
                                renderer={renderer}
                            />}
                        </div>
                    </div>}
                    <div className='col-5 col-md-2 text-end'>
                        <button className="btn btn-primary btn-sm px-5 rounded-12 cursor-pointer" onClick={handleBack}>Back</button>
                    </div>
                </div>}
                <div>
                    <MathJaxContext>
                        <MathJax>
                            {questionsData.map((question: any, i: number) => (
                                <div className="mt-4" key={i}>
                                    <div className='row'>
                                        <div className='col-2 col-md-1' style={{ alignSelf: 'center' }}>
                                            <span className='fw-bold' style={{ fontSize: '22px' }}>
                                                Q{question.question_number}:
                                            </span>
                                        </div>
                                        <div className='col-10 col-md-11' style={{ paddingTop: '15px' }}>
                                            <span className='fw-bold' style={{ fontSize: '22px' }}>
                                                <div dangerouslySetInnerHTML={onViewQuestion(question.question)}></div>
                                            </span>
                                        </div>
                                    </div>
                                    <form>
                                        {question.options.map((options: any, j: number) => (
                                            <div className='row pt-3' key={j}>
                                                <div className='col-2 col-md-1'>
                                                    <span>
                                                        <input className="mb-0" type="radio" name="option" onChange={(e) => { handleQuestionOptions(options, i, j) }} />
                                                    </span>
                                                </div>
                                                <div className='col-10 col-md-6'>
                                                    <span dangerouslySetInnerHTML={onViewQuestion(options.value)}></span>
                                                </div>
                                            </div>
                                        ))}
                                    </form>
                                </div>
                            ))}
                        </MathJax>
                    </MathJaxContext>
                </div>
                {questionsData.length > 0 && <div className="text-center mt-4">
                    <button className="col-12 col-md-2 btn btn-primary px-5 rounded-5 cursor-pointer" onClick={handleCompleteSubmit}>Submit</button>
                </div>}
            </div>}
        </div >
    )
}
