import React, { useEffect, useState } from 'react'
import { Link, NavLink, useHistory, useLocation, useParams } from 'react-router-dom'
import "react-datepicker/dist/react-datepicker.css";
import { toast } from 'react-toastify';
import moment from 'moment';
import NestedMLAOneList from '../../../../routes/insidemlaone';

export const MLAOneInFo = () => {
    const [loading, setLoading] = useState(false);
    const [canShowPopup, setCanShowPopup] = useState(false);
    const [userRole, setUserRole] = useState<any>();
    const [accessTests, setAccessTests] = useState(false);
    const [accessWorkSheets, setAccessWorkSheets] = useState(false);
    let { id, code } = useParams<{ id: string, code: string }>();
    const moduleId = Number(id);
    const moduleCode = code;

    const [breadcumInfo, setBreadcumInfo] = useState<any>([
        {
            label: 'Modules',
            value: 'Modules',
            routerLink: '/dashboard/module/list',
            isActive: false
        },
        {
            label: 'Module Name',
            value: moduleCode,
            routerLink: '',
            isActive: true
        }
    ]);

    const location = useLocation();
    const history = useHistory();
    //destructuring pathname from location
    const { pathname } = location;

    //Javascript split method to get the name of the path in array
    const splitLocation = pathname.split("/");

    useEffect(() => {
        const userRole = localStorage.getItem("user_role");
        setUserRole(userRole);
        setBreadcumInfo(breadcumInfo);

        const accessObjectsData: any = localStorage.getItem("access_objects_data");
        onHandleNav(JSON.parse(accessObjectsData));
    }, []);


    function onClickViewDetail() {
        setCanShowPopup(true);
    }

    const handleclose = () => {
        setCanShowPopup(false);
    }
    function onHandleNav(accessObjectsList: any) {
        const preparedData = accessObjectsList?.filter((el: any) => el.object_code === "MODULES");
        if (preparedData?.length > 0) {
            for (let i = 0; i < preparedData[0].child.length; i++) {
                if (preparedData[0].child[i].object_code === "MODULES-MLA-1-TESTS" && preparedData[0].child[i].p_read === 1) {

                    setAccessTests(true);
                }

                if (preparedData[0].child[i].object_code === "MODULES-MLA-1-WORKSHEETS" && preparedData[0].child[i].p_read === 1) {

                    setAccessWorkSheets(true);
                }


            }
        }
    }

    return (
        <div className="row  py-3">
            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading && <div className='row'>
                <div className=" col-md-12 mt-2">
                    <ul className="nav nav-tabs custom-tabs">

                        <li className="nav-item">
                            {accessWorkSheets && <NavLink className={splitLocation[7] == "worksheet" ? "nav-link active" : "nav-link"} to={{ pathname: `worksheet`, state: { data: "" } }}>Worksheets</NavLink>}
                        </li>
                        <li className="nav-item">
                            {accessTests && <NavLink className={splitLocation[7] == "tests" ? "nav-link active" : "nav-link"} to={{ pathname: `tests`, state: { data: "" } }}>Tests</NavLink>}
                        </li>
                    </ul>
                    <div>
                        <NestedMLAOneList />
                    </div>
                </div>
            </div>}
        </div>
    )
}

