import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { GET_MODULES_BY_USER_CODES } from "../../../app/service/module.service";

export const StudentDashboardView = () => {
  const history = useHistory();
  const [moduleData, setModuleData] = useState<any>([]);
  let { usercode, studentCode } = useParams<{
    usercode: string;
    studentCode: string;
  }>();
  const [userRole, setUserRole] = useState<any>();
  const userCode = usercode;
  const studentsCode = studentCode;

  useEffect(() => {
    const userRole = localStorage.getItem("user_role");
    setUserRole(userRole);
    getModulesByUserCode(userCode, userRole);
  }, []);

  const getModulesByUserCode = (userCode: any, userRole: any) => {
    GET_MODULES_BY_USER_CODES(userCode).then((res: any) => {
      if (res) {
        if (res.status === "fail") {
          toast.error(res.message);
        } else {
          if (userRole === "PARENT") {
            const data = res;
            setModuleData(data);
          } else {
            const data = res.filter(
              (el: any) => el.amount_status === "COMPLETED"
            );
            setModuleData(data);
          }
        }
      }
    });
  };

  function onClickDetails(data: any) {

    if (data.module_status !== "completed") {
      history.push(`/dashboard/questions/${data.uuid}/${data.module_code}`);
      sessionStorage.setItem("childUserCode", studentsCode);
    } else {
      toast.error("Survey is Completed");
    }
  }

  function onClickDetailsParent(data: any, isType: string) {
    if (isType === "Father") {
      if (
        (data?.parent_details?.father?.module_status === "completed"
          ? false
          : true) &&
        data?.parent_details?.father?.user_code === userCode
      ) {
        sessionStorage.setItem("childUserCode", studentsCode);
        history.push(
          `/dashboard/questions/${data?.parent_details?.father?.user_code}/${data.module_code}`
        );
      } else {
        if (data?.parent_details?.father?.module_status === "completed") {
          toast.success("Survey is completed");
        } else {
          toast.error("Survey is not completed");
        }
      }
    } else if (isType === "Mother") {
      if (
        (data?.parent_details?.mother?.module_status === "completed"
          ? false
          : true) &&
        data?.parent_details?.mother?.user_code === userCode
      ) {
        sessionStorage.setItem("childUserCode", studentsCode);
        history.push(
          `/dashboard/questions/${data?.parent_details?.mother?.user_code}/${data.module_code}`
        );
      } else {
        if (data?.parent_details?.mother?.module_status === "completed") {
          toast.success("Survey is completed");
        } else {
          toast.error("Survey is not completed");
        }
      }
    } else {
      if (
        (data?.module_status === "completed" ? false : true) &&
        data?.user_code === userCode
      ) {
        sessionStorage.setItem("childUserCode", studentsCode);
        history.push(
          `/dashboard/questions/${data?.user_code}/${data.module_code}`
        );
      } else {
        if (data?.module_status === "completed") {
          toast.success("Survey is completed");
        } else {
          toast.error("Survey is not completed");
        }
      }
    }
  }

  function onClickViewReports(item: any) {
    history.push(`/dashboard/reports/${item.id}/${item.uuid}/${userCode}`);
  }

  function onClickMathsViewReport(item: any) {
    history.push(`/dashboard/maths/reports/student/${userCode}`);
  }

  const handleBack = () => {
    history.goBack();

    if (userRole === "CHAIRMAN" || userRole === "GENERAL_SECRETARY" || userRole === "INSTITUTE_ADMIN") {

      history.goBack();

    }

  };

  return (
    <div className="border-top border-primary py-3">
      <div className="row mb-3">
        <div className="col-md-10">
          <h2 style={{ paddingLeft: "30px" }}>Module Reports</h2>
        </div>
        <div className="col-md-1">
          <button
            className="btn btn-primary btn-sm px-5  rounded-12 cursor-pointer"
            onClick={handleBack}
          >
            Back
          </button>
        </div>
      </div>
      <div className="container-fluid">
        {moduleData.length === 0 && (
          <h2 style={{ textAlign: "center" }}>Modules are not Assigned</h2>
        )}
        <div className="row">
          {moduleData.map((module: any, i: number) => (
            <div className="row">
              <div className="col-md-5" key={i}>
                <div className="row">
                  <div className="col-md-12">
                    <div className="card info-card-1 shadow  mb-5 rounded mb-4">
                      <img className="card-img-top" />
                      <div className="card-body text-center">
                        <div className="row">
                          <div className="col-md-6">
                            <div
                              className="card-title"
                              style={{ fontWeight: "bold" }}
                            >
                              Module Name: {module.module_code}
                            </div>
                            <div className="card-title">
                              Student Name: {module?.user_details?.name}
                            </div>
                            <div className="card-title">
                              School Name:{" "}
                              {module?.user_details?.name_of_school}
                            </div>
                            <div className="card-title">
                              Status : {module.module_status}
                            </div>
                          </div>
                          {(module.module_code === "CAS" || module.module_code === "CAS-PARENT") && (
                            <div className="col-md-6">
                              <div className="mb-2">
                                <a
                                  className="text-secondary fw-bold text-decoration-none ms-2  rounded-org-12"
                                  style={{
                                    color: "black",
                                    width: "155px",
                                    fontSize: "20px",
                                  }}
                                >
                                  Survey status
                                </a>
                              </div>
                              {userRole === "PARENT" &&
                                module.module_code === "CAS-PARENT" && (
                                  <a
                                    className="btn btn-primary text-white cursor-pointer"
                                    style={{ backgroundColor: "black" }}
                                    onClick={() => {
                                      onClickDetails(module);
                                    }}
                                  >
                                    Take Survey
                                  </a>
                                )}
                              {module?.user_details?.cas_report_view === 1 &&
                                module.module_code !== "CAS-PARENT" &&
                                module.module_status !== "inprogress" &&
                                module?.user_details?.type !== "individual" &&
                                userRole !== "SUPER_ADMIN" && (
                                  <a
                                    className="btn btn-primary text-white cursor-pointer"
                                    style={{
                                      backgroundColor: "#0d6efd",
                                      width: "135px",
                                    }}
                                    onClick={() => {
                                      onClickViewReports(module);
                                    }}
                                  >
                                    Survey Reports
                                  </a>
                                )}
                              {userRole === "SUPER_ADMIN" &&
                                module?.user_details?.type !== "individual" &&
                                module.module_status === "completed" && (
                                  <a
                                    className="btn btn-primary text-white cursor-pointer"
                                    style={{
                                      backgroundColor: "#0d6efd",
                                      width: "135px",
                                    }}
                                    onClick={() => {
                                      onClickViewReports(module);
                                    }}
                                  >
                                    Survey Reports
                                  </a>
                                )}
                              {module?.user_details?.type === "individual" &&
                                module.module_status === "completed" && (
                                  <a
                                    className="btn btn-primary text-white cursor-pointer"
                                    style={{
                                      backgroundColor: "#0d6efd",
                                      width: "135px",
                                    }}
                                    onClick={() => {
                                      onClickViewReports(module);
                                    }}
                                  >
                                    Survey Reports
                                  </a>
                                )}
                              <div className="mt-2">
                                <a
                                  className="btn btn-primary text-white cursor-pointer"
                                  style={{
                                    background:
                                      module?.module_status === "completed"
                                        ? "green"
                                        : "red",
                                    color:
                                      module?.module_status === "completed"
                                        ? "black"
                                        : "white",
                                    width: "135px",
                                    border: "none",
                                  }}
                                  onClick={() => {
                                    onClickDetailsParent(module, "student");
                                  }}
                                >
                                  Student
                                </a>
                              </div>
                              <div className="mt-2">
                                <a
                                  className="btn btn-primary text-white cursor-pointer"
                                  style={{
                                    background:
                                      module?.parent_details?.father
                                        ?.module_status === "completed"
                                        ? "green"
                                        : "red",
                                    color:
                                      module?.parent_details?.father
                                        ?.module_status === "completed"
                                        ? "black"
                                        : "white",
                                    width: "135px",
                                    border: "none",
                                  }}
                                  onClick={() => {
                                    onClickDetailsParent(module, "Father");
                                  }}
                                >
                                  Father
                                </a>
                              </div>
                              <div className="mt-2">
                                <a
                                  className="btn btn-primary text-white cursor-pointer"
                                  style={{
                                    background:
                                      module?.parent_details?.mother
                                        ?.module_status === "completed"
                                        ? "green"
                                        : "red",
                                    color:
                                      module?.parent_details?.mother
                                        ?.module_status === "completed"
                                        ? "black"
                                        : "white",
                                    width: "135px",
                                    border: "none",
                                  }}
                                  onClick={() => {
                                    onClickDetailsParent(module, "Mother");
                                  }}
                                >
                                  Mother
                                </a>
                              </div>
                            </div>
                          )}
                          {(module.module_code !== "CAS-PARENT" && module.module_status === "completed") && (
                            <div className="col-md-6">
                              <div className="pt-5">
                                <button
                                  className="btn btn-primary text-white cursor-pointer"
                                  style={{
                                    background: "#0d6efd",
                                    border: "none",
                                  }}
                                  onClick={() => {
                                    onClickMathsViewReport(module);
                                  }}
                                >
                                  View Report
                                </button>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
