import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { GET_MODULES_BY_SCHOOL_ID } from "../../../../app/service/module.service";

export const SchoolProfilemodulelist = () => {
    let { schoolCode } = useParams<{ schoolCode: string }>();
    const schoolsCode = schoolCode;
    const [modulesData, setModulesData] = useState<any[]>([]);
    const userRole = localStorage.getItem("user_role");
    const [activePage, setActivePage] = useState(1);
    const [loading, setLoading] = useState(false);
    const search = useLocation().search;
    const currentPageNumber: any = new URLSearchParams(search).get("page");
    const [pageNumbers, setPageNumbers] = useState<any>([]);
    let totalPage = pageNumbers.length

    useEffect(() => {
        getModules();


    }, []);

    function getModules() {
        setLoading(true);
        GET_MODULES_BY_SCHOOL_ID(schoolsCode).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    const data = res.rows;

                    setModulesData(data);
                    const pageNumbers = Math.ceil(res.count / 10);
                    const pageArray: any = [];
                    for (let i = 1; i <= pageNumbers; i++) {
                        pageArray.push(i);
                    }
                    setPageNumbers(pageArray);
                    setActivePage(currentPageNumber ? currentPageNumber : 1);

                    setLoading(false);
                }
            }
        });
    }
    const onPageChange = (data: any) => {
        setActivePage(data);
    }
    function onClickPage(number: number) {
        setActivePage(number);
        onPageChange(number);
    }
    function onClickNextPage() {
        setActivePage(activePage + 1);
        onPageChange(activePage + 1);

    }
    function onClickPreviousPage() {
        setActivePage(activePage - 1);
        onPageChange(activePage - 1);

    }
    const onClickDoubbleArrows = (arrow: any) => {
        if (arrow === "&raquo;") {
            setActivePage(totalPage);
        } else {
            setActivePage(1);
        }
    }

    return (
        <div>
            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading &&
                <div>

                    <div className="table-responsive">
                        <table className="table table_new_ui">
                            <thead>
                                <tr>
                                    <th>Module Code</th>
                                    <th style={{ width: "180px" }} >Sub Module</th>
                                    <th style={{ width: "300px" }} >Target Standard</th>
                                    <th style={{ width: "220px" }} >Version</th>
                                    <th>Amount</th>
                                    <th style={{ width: "220px" }}>Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                {modulesData?.map((data: any, index: number) => {
                                    return (<tr key={index}>
                                        <td>{data.module_code}</td>
                                        <td>{data.sub_module}</td>
                                        <td>{data.target_std}</td>
                                        <td>{data.module_ver}</td>
                                        <td>{data.amount}</td>
                                        <td>{data.created_at}</td>
                                    </tr>)
                                })}


                            </tbody>
                        </table>
                        {modulesData?.length === 0 && <p className="text-center f14">No Data Available</p>}
                    </div>
                    <div>

                        {
                            modulesData?.length > 0 && (
                                <nav className="mt-3" style={{ float: "right" }}>
                                    {pageNumbers && (
                                        <ul className="pagination">
                                            <li className="page-item" onClick={() => onClickDoubbleArrows("&laquo;")}><span className={`page-link prev ${activePage == 1 ? "disabled" : ""}`}>&laquo;</span></li>

                                            <li className="page-item">
                                                <button className={`page-link prev ${activePage == 1 ? "disabled" : ""}`} onClick={() => onClickPreviousPage()}>
                                                    {/* Previous */}
                                                    &lsaquo;
                                                </button>
                                            </li>
                                            {pageNumbers &&
                                                pageNumbers.map((number: number, i: number) => (
                                                    <li className={activePage == number ? "active page-item" : "page-item"} key={i}>
                                                        <button className="page-link" onClick={() => onClickPage(number)}>
                                                            {number}
                                                        </button>
                                                    </li>
                                                ))}
                                            <li className="page-item">
                                                <button className={`page-link next ${activePage == pageNumbers.length ? "disabled" : ""}`} onClick={() => onClickNextPage()}>
                                                    {/* Next */}
                                                    &rsaquo;
                                                </button>
                                            </li>
                                            <li className="page-item" onClick={() => onClickDoubbleArrows("&raquo;")}><span className={`page-link next ${activePage == pageNumbers.length ? "disabled" : ""}`}>&raquo;</span></li>

                                        </ul>
                                    )}
                                </nav>
                            )
                        }
                    </div>
                </div>
            }
        </div>
    )
}