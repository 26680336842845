import { DataTableCol } from "../../../../../components/data-table/types";

export const StudentsDataGridCols: DataTableCol[] = [
  {
    title: "Name",
    control: "name",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "School Name",
    control: "name_of_school",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "Email",
    control: "email_id",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "Standard",
    control: "standard",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "Division",
    control: "division",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "Modules",
    control: "show_cards",
    canShowColumn: true,
  },
];
