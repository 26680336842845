import React, { useEffect, useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import { CREATE_ASSIGN_USER, GET_TOTAL_MODULES } from '../../../../app/service/module.service';
import { GET_TOTAL_SCHOOLS } from '../../../../app/service/school.service';
import { GET_USERS_BY_SCHOOL_CODE, GET_USER_BY_SCHOOL_CODE } from '../../../../app/service/users.service';
import FormBuilder from '../../../../components/form-builder';
import { FormControlError, FormField, FormValidators } from '../../../../components/form-builder/model/form-field';
import { FormValidator, GetControlIsValid } from '../../../../components/form-builder/validations';

export const AssignParent = () => {
    const [moduleData, setModuleData] = useState<any>([]);
    const [subModuleData, setSubModuleData] = useState<any>([]);
    const [moduleVersionData, setModuleVersionData] = useState<any>([]);
    const [targetData, setTargetData] = useState<any>([]);
    const [schoolData, setSchoolData] = useState<any>([]);
    const [parentList, setParentList] = useState<any>([]);
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [assignUserValidationErrors, setAssignUserValidationErrors] = useState<FormControlError[]>([]);
    const [assignUserData, setAssignUserData] = useState<any>({});
    const [amount, setAmount] = useState<any>(0);
    const history = useHistory();
    let { id, schoolCode } = useParams<{ id: string, schoolCode: string }>();
    const schoolId = parseInt(id);
    const schoolsCode = schoolCode;
    const formValidations = [
        new FormField('module_code', [FormValidators.REQUIRED]),
        new FormField('sub_module', [FormValidators.REQUIRED]),
        new FormField('module_ver', [FormValidators.REQUIRED]),
        new FormField('target_std', [FormValidators.REQUIRED]),
        new FormField('school_code', []),
        new FormField('user_code', [FormValidators.REQUIRED])
    ];

    useEffect(() => {
        GET_TOTAL_MODULES().then((res: any) => {
            if (res) {
                const data = res.rows;
                const preparedData = data.filter((el: any) => el.module_code === "CAS-PARENT");
                setModuleData(preparedData);
            }
        });

        GET_USERS_BY_SCHOOL_CODE(schoolsCode, 'PARENT').then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                } else {
                    const data = res.rows;
                    setParentList(data);
                }
            }
        });

    }, []);

    const handleInput = (data: any) => {
        setAssignUserData(data);
        const errors: any = FormValidator(formValidations, data.value);
        setAssignUserValidationErrors(errors);
    }

    const handleModuleChange = (e: any) => {
        setSubModuleData([]);
        const preparedData = moduleData.filter((el: any) => el.module_code === e.target.value);
        setAmount(preparedData[0].amount ? preparedData[0].amount : 0);
        setSubModuleData(preparedData);
    }

    const handleSubModuleChange = (e: any) => {
        setModuleVersionData([]);
        const preparedData = moduleData.filter((el: any) => el.sub_module === e.target.value);
        setAmount(preparedData[0].amount ? preparedData[0].amount : 0);
        setModuleVersionData(preparedData);
    }

    const handleModuleVersionChange = (e: any) => {
        setTargetData([]);
        const preparedData = moduleData.filter((el: any) => el.module_ver === e.target.value);
        setTargetData(preparedData);
    }

    const handleTargetChange = (e: any) => {
    }

    const handleStudentChange = (e: any) => {

    }

    const getInputValid = (control: string) => {
        const value = GetControlIsValid(assignUserValidationErrors, control);
        return value;
    }

    const handleSubmit = () => {
        setIsFormSubmitted(true);
        const assignsSchoolData = { ...assignUserData.value };
        assignsSchoolData.user_code = assignsSchoolData.user_code ? [{ label: 'parent', value: assignsSchoolData.user_code }] : [];
        assignsSchoolData.school_code = schoolsCode;
        assignsSchoolData.amount = amount;
        const errors: FormControlError[] = FormValidator(formValidations, assignsSchoolData);
        setAssignUserValidationErrors(errors);
        if (errors.length < 1) {
            CREATE_ASSIGN_USER(assignsSchoolData).then((res: any) => {
                if (res.status === 'fail') {
                    toast.error(res.message);
                } else {
                    toast.success("Assign Parent Created Successfully");
                    history.push(`/dashboard/school/info/${schoolId}/modulelist/${schoolsCode}`);
                }
            });
        }
    }

    return (
        <div>
            <h2>Assign Parent</h2>
            <FormBuilder onUpdate={handleInput}>
                <form>
                    <div className="row custom-form">
                        <div className="col-md-4">
                            <div className="mb-4">
                                <label className="form-label mb-0">Module Name</label>
                                <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>*</span>
                                <select name="module_code" className="form-select form-select-lg mb-3" onChange={(e) => { handleModuleChange(e) }}>
                                    <option value="">Select module</option>
                                    {moduleData.map((name: any, i: number) => (
                                        <option key={i} value={name.module_code}>
                                            {name.module_name}
                                        </option>
                                    ))}
                                </select>
                                {isFormSubmitted && !getInputValid('module_code') && <p className="text-danger">Please select module name</p>}
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="mb-4">
                                <label className="form-label mb-0">Sub Module</label>
                                <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>*</span>
                                <select name="sub_module" className="form-select form-select-lg mb-3" onChange={(e) => { handleSubModuleChange(e) }}>
                                    <option value="">Select sub module</option>
                                    {subModuleData.map((name: any, i: number) => (
                                        <option key={i} value={name.sub_module}>
                                            {name.sub_module}
                                        </option>
                                    ))}
                                </select>
                                {isFormSubmitted && !getInputValid('sub_module') && <p className="text-danger">Please select sub module</p>}
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="mb-4">
                                <label className="form-label mb-0"> Module Version</label>
                                <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>*</span>
                                <select name="module_ver" className="form-select form-select-lg mb-3" onChange={(e) => { handleModuleVersionChange(e) }}>
                                    <option value="">Select module version</option>
                                    {moduleVersionData.map((name: any, i: number) => (
                                        <option key={i} value={name.module_ver}>
                                            {name.module_ver}
                                        </option>
                                    ))}
                                </select>
                                {isFormSubmitted && !getInputValid('module_ver') && <p className="text-danger">Please select module version</p>}
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="mb-4">
                                <label className="form-label mb-0">Target Standard</label>
                                <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>*</span>
                                <select name="target_std" className="form-select form-select-lg mb-3" onChange={(e) => { handleTargetChange(e) }}>
                                    <option value="">Select target standard</option>
                                    {targetData.map((name: any, i: number) => (
                                        <option key={i} value={name.target_std}>
                                            {name.target_std}
                                        </option>
                                    ))}
                                </select>
                                {isFormSubmitted && !getInputValid('target_std') && <p className="text-danger">Please select target standard</p>}
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="mb-4">
                                <label className="form-label mb-0">Parent</label>
                                <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>*</span>
                                <select name="user_code" className="form-select form-select-lg mb-3" onChange={(e) => { handleStudentChange(e) }}>
                                    <option value="">Select parent</option>
                                    {parentList.map((name: any, i: number) => (
                                        <option key={i} value={name.user_code}>
                                            {name.name}
                                        </option>
                                    ))}
                                </select>
                                {isFormSubmitted && !getInputValid('user_code') && <p className="text-danger">Please select parent</p>}
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="mb-4">
                                <label className="form-label mb-0">Amount</label>
                                <input className="form-control form-control-lg" type="text" name="amount" value={amount} />
                            </div>
                        </div>
                    </div>
                </form>
            </FormBuilder>
            <div className="form-footer border-top border-primary py-3 text-end mt-4">
                <a className="btn btn-primary btn-sm px-5 rounded-12 cursor-pointer" onClick={handleSubmit}>Assign</a>
                <Link className="text-dark text-decoration-none ms-2 cursor-pointer" to={`/dashboard/school/info/${schoolId}/modulelist/${schoolsCode}`}>Cancel</Link>
            </div>
        </div>
    )
}
