import { DataTableCol } from "../../../../components/data-table/types";

export const SponsorReportListCols: DataTableCol[] = [
    {
        title: "Sponsorship Name",
        control: "sponsorship_name",
        sortable: true,
        canShowColumn: true,
    },
    {
        title: "Sponsor Name",
        control: "sponsor_name",
        sortable: true,
        canShowColumn: true,
    },
    {
        title: "No.of Institutes",
        control: "total_institutes",
        sortable: true,
        canShowColumn: true,
    },
    {
        title: "No.of Schools",
        control: "total_schools",
        sortable: true,
        canShowColumn: true,
    },
    {
        title: "Students",
        control: "total_students",
        sortable: true,
        canShowColumn: true,
    },
    {
        title: "Payment Date",
        control: "payment_date",
        sortable: true,
        canShowColumn: true,
    },
    {
        title: "Total Amount",
        control: "total_amount",
        sortable: true,
        canShowColumn: true,
    },
    {
        title: "Paid Amount",
        control: "paid_amount",
        sortable: true,
        canShowColumn: true,
    },
    {
        title: "Payment Status",
        control: "payment_status",
        sortable: true,
        canShowColumn: true,
    },

];
