import React, { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { GET_MODULES_BY_SCHOOL_ID, GET_TOTAL_MODULES, UPDATE_ASSIGN_USER } from "../../../../app/service/module.service";
import FormBuilder from "../../../../components/form-builder";
import { FormControlError, FormField } from "../../../../components/form-builder/model/form-field";
import moment from "moment";
import { GET_STUDENTS_BY_PAYMENT_MODULE, GET_STUDENTS_BY_STANDARD_DIVISION, GET_USERS_BY_SCHOOL_CODE } from "../../../../app/service/users.service";
import { GET_SCHOOL_BY_SCHOOL_CODE } from "../../../../app/service/school.service";
import { FormValidator } from "../../../../components/form-builder/validations";
import { CREATE_MNS_USER_ASSIGN } from "../../../../app/service/mns-service";
import { ADD_UPDATE_SUBSCRIPTION_PRICE, GET_SUBSCRIPTION_PRICE_STATUS_BY_SCHOOL_STUDENTS } from "../../../../app/service/subscription.service";
import { CREATE_PAYMENT, UPDATE_PAYMENT } from "../../../../app/service/payment-service";
import useRazorpay, { RazorpayOptions } from "react-razorpay";
import appConfig, { UI_BASE_URL } from "../../../../config/constant";
import { MultiSelect } from "react-multi-select-component";
import { CREATE_CCAVENUE_PAYMENT } from "../../../../app/service/ccavenue.service";

export const SchoolProfilePaymentlist = () => {
    const Razorpay = useRazorpay();
    const [paymentList, setPaymentList] = useState<any>([]);
    let { schoolCode } = useParams<{ schoolCode: string }>();
    const schoolsCode = schoolCode;
    const [transactionId, SettransactionId] = useState<any>("");
    const [moduleName, setModuleName] = useState<any>("");
    const [amount, setAmount] = useState<any>("");
    const [loading, setLoading] = useState(false);
    const [assignStudentsloading, setAssignStudentsloading] = useState(false);
    const [studentsLoading, setStudentsLoading] = useState(false);
    const [paymentDetailsLoading, setPaymentDetailsLoading] = useState(false);
    const userRole: any = localStorage.getItem("user_role");
    const [moduleData, setModuleData] = useState<any>([]);
    const [assignUserData, setAssignUserData] = useState<any>({});
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [assignUserValidationErrors, setAssignUserValidationErrors] = useState<FormControlError[]>([]);
    const [subModuleData, setSubModuleData] = useState<any>([]);
    const [canShowPopup, setCanShowPopup] = useState(false);
    const [canShowStudentPopup, setCanShowStudentPopup] = useState(false);
    const [canShowPaymentDetailsPopup, setCanShowPaymentDetailsPopup] = useState(false);
    const [studentsSelected, setStudentsSelected] = useState<any>([]);
    const [studentList, setStudentList] = useState<any[]>([]);
    const [paymentCompletedList, setPaymentCompletedList] = useState<any>([]);
    const [paymentInfo, setPaymentInfo] = useState<any>({});
    const [activePage, setActivePage] = useState<any>(1);
    const [pageArray, setPageNumbers] = useState([]);
    const [subscriptionData, setSubscriptionData] = useState<any[]>([]);
    const [selectedUsers, setSelectedUsers] = useState<any[]>([]);
    const [currentPriceStudent, setCurrentPriceStudent] = useState<any>("");
    const [subscriptionDataCount, setSubscriptionDataCount] = useState<any>(0);
    const [payment, setPayment] = useState(false);
    const [paymentStudentList, setPaymentStudentList] = useState(false);
    const assignUserForm = useRef<any>({});
    const [moduleUUID, setModuleUUID] = useState<any>('');
    const [totalAmount, setTotalAmount] = useState<any>("");
    const [finalAmountValue, setFinalAmountValue] = useState<any>();
    const [organizationCode, setOrganizationCode] = useState<any>("");
    const [todayDate, setTodayDate] = useState(moment().format('YYYY-MM-DD'));
    const loginUserCode = localStorage.getItem("user_code");
    const [studentErorr, setStudentErorr] = useState<boolean>(false);
    const [pageListOptions, setPageListOptions] = useState<any>([]);
    const [activePageOption, setActivePageOption] = useState<number>(1);
    const [optionStudentsLoading, setOptionStudentsLoading] = useState(false);
    const history = useHistory();
    const formValidations = [
        new FormField('user_code', []),
        new FormField('division', []),
        new FormField('standard', [])

    ];
    const RAZORPAY_OPTIONS: RazorpayOptions = {
        key: appConfig.paymentKey,
        amount: (amount + "000"),
        currency: "INR",
        name: "IAMSAMARTH",
        description: "Subscription Fee",
        image: "",//MSB LOGO
        order_id: "",
        prefill: {
            name: moduleName,//SCHOOL CODE OR MODULE NAME
            email: "",
            contact: "9999999999",
        },
        notes: {
            address: "Razorpay Corporate Office",
        },
        theme: {
            color: "#3399cc",
        },
    };
    useEffect(() => {
        const userRole = localStorage.getItem("user_role");
        getPayments();
        getSchoolList('', activePageOption - 1);
        getModulesList();
        // ccavenue //
        const splitLocation: any = window.location.href.split("?");
        const queryString = splitLocation[1];
        if (queryString) {
            const match = queryString.match(/status=([^&]*)/);
            if (match) {
                const statusValue = match[1];
                const preparedSubscriptionData = JSON.parse(localStorage.getItem('cc_subscription_data') || '{}');
                const txnId = localStorage.getItem("cc_transaction_id");
                const paymentInfo = JSON.parse(localStorage.getItem('cc_payment_info') || '{}');
                const moduleUuid = localStorage.getItem('cc_module_uuid');
                const assignUserData = JSON.parse(localStorage.getItem('cc_assign_user_data') || '{}');
                const selectedUsers = JSON.parse(localStorage.getItem('cc_selected_users') || '{}');
                if (txnId && statusValue === "success") {
                    updateSubscription(txnId, "", preparedSubscriptionData, statusValue, paymentInfo, moduleUuid, assignUserData, selectedUsers);
                }
                else {
                    removeItemsFromeLocalStorage();
                }
            } else {
            }
        }
    }, []);

    function getPayments() {
        setLoading(true);
        GET_MODULES_BY_SCHOOL_ID(schoolsCode).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    const data = res.rows;
                    const moduleData = data.filter((el: any) => el.module_code !== 'CAS-PARENT');
                    setPaymentList(moduleData);
                    setLoading(false);
                }
            }
        });
    }
    function getSchoolInfo() {
        GET_SCHOOL_BY_SCHOOL_CODE(schoolsCode).then((res: any) => {
            if (res.status === "fail") {
                toast.error(res.message);
                setLoading(false);
            } else {
                if (res) {
                    const data = res.rows[0];
                    setOrganizationCode(data?.organization_code);

                }
            }
        });
    }
    function getModulesList() {
        GET_TOTAL_MODULES().then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res.message);
            } else {
                if (res) {
                    const data = res.rows;
                    const preparedData = data.filter((el: any) => el.module_code !== "CAS-PARENT");
                    setModuleData(preparedData);
                }
            }
        });

    }

    function getSchoolList(moduleUUID: any, pageNo?: number) {
        GET_USERS_BY_SCHOOL_CODE(schoolsCode, 'STUDENT', pageNo).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setAssignStudentsloading(false);
                    setOptionStudentsLoading(false);

                } else {
                    const data = res.rows;
                    const pageNumbers = Math.ceil(res.count / 50);
                    const pageArray: any = [];
                    for (let i = 1; i <= pageNumbers; i++) {
                        pageArray.push(i);
                    }
                    setPageListOptions(pageArray);
                    const preparedData = [];
                    for (let i = 0; i < data.length; i++) {
                        preparedData.push({
                            label: data[i].name,
                            value: data[i].user_code
                        })

                    }
                    setStudentList(preparedData);
                    setAssignStudentsloading(false);
                    getStudentsByPayment(preparedData, moduleUUID);

                }
            }
        });
    }

    const handleInput = (data: any) => {
        localStorage.setItem("cc_assign_user_data", JSON.stringify(data));
        setAssignUserData(data);
        const errors: any = FormValidator(formValidations, data.value);
        setAssignUserValidationErrors(errors);
    }


    const handleSubmit = () => {
        setAmount("");
        setTotalAmount("");
        setIsFormSubmitted(true);
        const assignsSchoolData = { ...assignUserData.value };
        delete assignsSchoolData[''];
        const errors: FormControlError[] = FormValidator(formValidations, assignsSchoolData);
        setAssignUserValidationErrors(errors);
        if (errors.length < 1) {
            delete assignsSchoolData.division;
            delete assignsSchoolData.standard;
            const userCodes = [];
            for (let i = 0; i < studentsSelected.length; i++) {
                userCodes.push(studentsSelected[i].value);
            }

            const payload = {
                module_uuid: paymentInfo.module_uuid ? paymentInfo.module_uuid : '',
                school_code: schoolsCode,
                target_std: paymentInfo.target_std,
                total_amount_paid: assignsSchoolData.amount ? assignsSchoolData.amount : paymentInfo.amount,
                user_codes: userCodes
            }
            setSelectedUsers(payload.user_codes);
            if (studentsSelected.length > 0) {
                getSubscriptionStatusByUsers(payload.total_amount_paid, payload.user_codes);
                setCanShowPopup(false);
            } else {
                setStudentErorr(true);
            }


        }

    }

    function assignModuleStudents(paymentInfo?: any, assignUserData?: any, studentsSelected?: any) {
        const assignsSchoolData = { ...assignUserData.value };
        delete assignsSchoolData[''];
        const userCodes = [];
        for (let i = 0; i < studentsSelected.length; i++) {
            userCodes.push(studentsSelected[i].value);
        }
        const payload = {
            module_uuid: paymentInfo.module_uuid ? paymentInfo.module_uuid : '',
            payment_status: "COMPLETED",
            school_code: schoolsCode,
            target_std: paymentInfo.target_std,
            // total_amount_paid: paymentInfo.amount,
            total_amount_paid: assignsSchoolData.amount ? assignsSchoolData.amount : paymentInfo.amount,
            user_codes: userCodes
        }
        CREATE_MNS_USER_ASSIGN(payload).then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res.message);
            } else {
                toast.success("Assign User  Successfully");
                // setCanShowPopup(false);
                if (!(subscriptionData[0].subscription_price + (currentPriceStudent ? currentPriceStudent : paymentInfo.amount) > 0)) {
                    toast.success("Assign User  Successfully");
                }
            }
        });
    }

    function getSubscriptionStatusByUsers(amount: any, usersCodes: any) {
        setCanShowPaymentDetailsPopup(true);
        setPaymentDetailsLoading(true);
        GET_SUBSCRIPTION_PRICE_STATUS_BY_SCHOOL_STUDENTS(0, usersCodes).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setPaymentDetailsLoading(false);
                } else {
                    const data = res.data;
                    if (data.length > 0) {
                        let sum = 0;
                        for (let i = 0; i < data.length; i++) {
                            if (data[i].subscription_payment_status === "completed") {
                                sum += (currentPriceStudent ? parseInt(currentPriceStudent) : parseInt(paymentInfo.amount));

                            } else {
                                sum += parseInt(data[i].subscription_price) + (currentPriceStudent ? parseInt(currentPriceStudent) : parseInt(paymentInfo.amount));
                            }
                        }
                        setAmount(sum);
                        setTotalAmount(sum);
                        setSubscriptionDataCount(res.count);
                        setSubscriptionData(data);
                        const pageNumbers = Math.ceil(res.count / 10);
                        const pageArray: any = [];
                        for (let i = 1; i <= pageNumbers; i++) {
                            pageArray.push(i);
                        }
                        setPageNumbers(pageArray);

                    }
                    setPaymentDetailsLoading(false);
                }
            }
        });
    }

    const handleclose = () => {
        setCanShowPopup(false);
        assignUserForm.current.reset();
        setIsFormSubmitted(false);
        if (studentList.length > 0) {
            for (let i = 0; i < studentList.length; i++) {
                studentList[i].disabled = false;
            }
        }
    }

    const handleView = () => {
        setCanShowStudentPopup(true);
        setStudentsLoading(true);
        setTimeout(() => {
            setStudentsLoading(false);
        }, 1000);
    }

    const handleStudentClose = () => {
        setCanShowStudentPopup(false);
    }


    const handleStandardChange = (e: any) => {
        const data = { ...assignUserData.value };
        data.standard = e.target.value;
        let preparedData = {
            standard: data.standard,
            division: data.division,
        }
        getStudentsByStandardDivision(preparedData);
    }

    const handleDivisionChange = (e: any) => {
        const data = { ...assignUserData.value };
        data.division = e.target.value;
        let preparedData = {
            standard: data.standard,
            division: data.division,
        }
        getStudentsByStandardDivision(preparedData);
    }

    function getStudentsByStandardDivision(data: any) {

        for (const key of Object.keys(data)) {
            if (data[key] === "") {
                delete data[key];
            }
        }
        GET_STUDENTS_BY_STANDARD_DIVISION(schoolsCode, data).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    const data = res.rows;
                    const preparedData = [];

                    if (data.length > 0) {
                        for (let i = 0; i < data.length; i++) {
                            for (let j = 0; j < paymentCompletedList.length; j++) {
                                if (data[i].user_code === paymentCompletedList[j].user_code) {
                                    data[i].completedStatus = true;
                                }
                            }
                        }
                        for (let k = 0; k < data.length; k++) {
                            if (data[k].completedStatus) {
                                preparedData.push({
                                    label: data[k].name,
                                    value: data[k].user_code,
                                    disabled: true
                                });
                            } else {
                                preparedData.push({
                                    label: data[k].name,
                                    value: data[k].user_code
                                });
                            }
                        }

                        setStudentList(preparedData);
                    } else {
                        setStudentList([]);
                    }
                }
            }
        });
    }
    function onClickPay(data: any) {
        history.push(`/school-dashboard/profile/payment-list/${schoolsCode}/${data.module_uuid}`)
        // history.push(`/school-dashboard/profile/${schoolsCode}/${data.module_uuid}/payment/list`);
        setStudentErorr(false)
        setCurrentPriceStudent("");
        localStorage.setItem("cc_payment_info", JSON.stringify(data));
        localStorage.setItem("cc_module_uuid", data.module_uuid);
        assignUserForm.current.reset();
        setStudentsSelected([]);
        setCanShowPopup(true);
        setPaymentInfo(data);
        setModuleUUID(data.module_uuid);
        setAssignStudentsloading(true);
        getSchoolList(data.module_uuid, activePageOption - 1);

    }
    function getStudentsByPayment(studentList: any, moduleUUID: any) {
        GET_STUDENTS_BY_PAYMENT_MODULE(schoolsCode, moduleUUID).then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res.message);
                setOptionStudentsLoading(false);
                setAssignStudentsloading(false);
            } else {
                if (res) {
                    const data = res.rows;
                    const preparedData = data.filter((el: any) => el.amount_status === 'COMPLETED');
                    setPaymentCompletedList(preparedData);
                    if (preparedData.length > 0) {
                        for (let i = 0; i < studentList.length; i++) {
                            for (let j = 0; j < preparedData.length; j++) {
                                if (studentList[i].value === preparedData[j].user_code) {
                                    studentList[i].disabled = true;
                                }
                            }
                        }
                        setStudentList(studentList);
                    } else {
                        setStudentList(studentList);
                    }
                    setAssignStudentsloading(false);
                    setOptionStudentsLoading(false);
                }
            }
        });

    }

    function createPayment(data: any, subscriptionData: any) {
        setModuleName(data.module_code);
        let subscriptionAmount = totalAmount;
        const payload = {
            school_code: schoolsCode,
            user_ids: selectedUsers,
            sub_amount: subscriptionAmount
        }

        if (subscriptionAmount > 0) {
            CREATE_PAYMENT(payload).then((res: any) => {
                if (res) {
                    if (res.status === 'fail') {
                        toast.error(res.message);
                    } else {

                        SettransactionId(res.txn_id);
                        onOpenPaymentInfo(payload.sub_amount, res.txn_id, data, subscriptionData);

                    }
                }
            });
        } else {
            setCanShowPaymentDetailsPopup(false);
            assignModuleStudents();
        }
    }

    function onOpenPaymentInfo(amount: any, txn_id: string, data: any, subscriptionData: any) {
        localStorage.setItem("cc_subscription_data", JSON.stringify(subscriptionData));
        localStorage.setItem("cc_transaction_id", txn_id);
        if (amount > 0) {
            createCcavenuePayment(amount, txn_id);

        } else {
            onUpdatePayment(txn_id, { "message": "SUM AMOUNT ZERO" }, "success");
            if (amount > 0) {
                updateSubscription(txn_id, { "message": "SUM AMOUNT ZERO" }, subscriptionData, "success");
            }
        }
    }


    function onUpdatePayment(transactionId: string, data: any, status: string) {

        const payload = {
            txn_id: transactionId,
            "txn_details": data,
            "module_code": paymentInfo.module_code ? paymentInfo.module_code : '',
            "module_uuid": '',
            "status": status,
            "payment_dt": todayDate
        }

        UPDATE_PAYMENT(payload).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                } else {
                    SettransactionId(res.txn_id);
                    setCanShowPaymentDetailsPopup(false);
                    if (status === "success") {
                        moduleUpdate();
                    }
                }
            }
        });
    }

    function updateSubscription(transactionId: any, transactionData: any, subscriptionData: any, paymentStatus: any,
        paymentInfo?: any, moduleUuid?: any, assignUserData?: any, selectedUsers?: any) {
        const preparedData = [];
        for (let i = 0; i < subscriptionData.length; i++) {
            preparedData.push({
                "uuid": "",
                "school_code": schoolsCode,
                "user_code": subscriptionData[i].user_code,
                "transaction_id": transactionId,
                "amount": subscriptionData[i].subscription_price,
                // "transaction_details": JSON.stringify(transactionData),
                "transaction_details": "",
                "payment_status": "completed"
            })
        }
        const payload = {
            data: preparedData
        }
        if (paymentStatus === "success") {
            ADD_UPDATE_SUBSCRIPTION_PRICE(payload).then((res: any) => {
                if (res.status === 'fail') {
                    toast.error(res.message);
                } else {
                    setCanShowPaymentDetailsPopup(false);
                    moduleUpdate(paymentInfo, moduleUuid, assignUserData, selectedUsers);
                    toast.success('Subscription Added Successfully');
                }
            });
        }

    }

    function moduleUpdate(paymentInfo?: any, moduleUuid?: any, assignUserData?: any, selectedUsers?: any) {
        const payload = {
            id: paymentInfo.id,
            amount_status: "COMPLETED",
            payment_at: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
            attempt: 1,
            module_uuid: moduleUUID ? moduleUUID : moduleUuid
        }
        setLoading(true);
        UPDATE_ASSIGN_USER(payload).then(res => {
            if (res.status === 'fail') {
                toast.error(res.message);
            } else {
                getPayments();
                removeItemsFromeLocalStorage();
                assignModuleStudents(paymentInfo, assignUserData, selectedUsers);

            }
        });
    }

    function onClickDelete(item: any, index: number) {
        setStudentsLoading(true);
        const preparedData = studentsSelected;
        preparedData.splice(index, 1);
        setStudentsSelected(preparedData);
        setTimeout(() => {
            setStudentsLoading(false);
        }, 1000);
    }

    const handlePaymentDetailsClose = () => {

        setCanShowPaymentDetailsPopup(false);

    }

    const handlePayment = () => {
        createPayment(paymentInfo, subscriptionData);

    }


    function getTotalPrice(data: any) {

        if (data.subscription_payment_status === "completed") {
            return (currentPriceStudent ? parseInt(currentPriceStudent) : parseInt(paymentInfo.amount));

        } else {
            return (parseInt(data.subscription_price) + (currentPriceStudent ? parseInt(currentPriceStudent) : parseInt(paymentInfo.amount)));

        }

    }
    function onChangeModuleAmount(e: any) {
        if (e.target.value) {
            setCurrentPriceStudent(e.target.value);
        } else {
            setCurrentPriceStudent("");
        }
    }
    const onSelectedStudent = (e: any) => {
        setStudentErorr(false);
        if (e) {
            setStudentsSelected(e);
            localStorage.setItem("cc_selected_users", JSON.stringify(e));
        } else {
            setStudentErorr(true);
        }
    }
    const createCcavenuePayment = (amount: any, txn_id: string) => {
        let payLoad = {
            amount: amount,
            currency: "INR",
            transaction_uuid: txn_id,
            redirect_url: `${UI_BASE_URL}/school-dashboard/profile/payment/list/${schoolsCode}`,
            cancel_url: `${UI_BASE_URL}/school-dashboard/profile/payment/list/${schoolsCode}`,
        }
        CREATE_CCAVENUE_PAYMENT(payLoad).then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res?.error);
            } else {
                initiatePayment(res);
            }
        });
    }
    const initiatePayment = async (paymentData: any) => {
        window.location.href = paymentData.url;
    };
    const removeItemsFromeLocalStorage = () => {
        const keysToRemove = ['student_paymet_info', 'subscription_info', 'cc_subscription_data', 'subscription_price', 'cc_transaction_id', 'module_info', 'cc_assign_user_data', 'cc_selected_users', 'cc_payment_info', 'cc_module_uuid'];
        keysToRemove.forEach((key: any) => {
            localStorage.removeItem(key);
        });
    };
    const handleStudentsPerPageChange = (pageNo: any) => {
        setStudentsSelected([]);
        setActivePageOption(pageNo);
        let pageNumber = parseInt(pageNo) - 1;
        setOptionStudentsLoading(true);
        getSchoolList(moduleUUID, pageNumber);
    }
    return (
        <div className="mb-5">
            <div className={`modal fade ${canShowPopup ? 'show' : 'false'}`} style={{ display: (canShowPopup ? "block" : 'none') }} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog assign-user-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Assign Student</h5>
                            <button type="button" className="close popup-close cursor-pointer" data-dismiss="modal" aria-label="Close" onClick={handleclose}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            {assignStudentsloading &&
                                <div className="text-center p-5">
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div>
                            }
                            {!assignStudentsloading && <FormBuilder onUpdate={handleInput}>
                                <form ref={assignUserForm}>
                                    <div className="row ">
                                        <div className="col-md-6  custom-form">
                                            <div className="mb-4">
                                                <label className="form-label mb-0">Module Name</label>
                                                <input className="form-control form-control-lg" type="text" name="module_code" disabled value={paymentInfo.module_code} />
                                            </div>
                                        </div>
                                        <div className="col-md-6  custom-form">
                                            <div className="mb-4">
                                                <label className="form-label mb-0">Standard</label>
                                                <select className="form-select mt-2" name="standard" onChange={(e) => { handleStandardChange(e) }}>
                                                    <option value="">Select Standard</option>
                                                    <option value="6">6</option>
                                                    <option value="7">7</option>
                                                    <option value="8">8</option>
                                                    <option value="9">9</option>
                                                    <option value="10">10</option>
                                                    <option value="11">11</option>
                                                    <option value="12">12</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-6 custom-form">
                                            <div className="mb-4 mt-md-2">
                                                <label className="form-label mb-0">Division</label>
                                                <select className="form-select" name="division" onChange={(e) => { handleDivisionChange(e) }}>
                                                    <option value="">Select Division</option>
                                                    <option value="A">A</option>
                                                    <option value="B">B</option>
                                                    <option value="C">C</option>
                                                    <option value="D">D</option>
                                                    <option value="E">E</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className={`col-md-4 custom-form`}>
                                            <div className="mb-4 position-relative">
                                                <label className="form-label mb-0">Student</label>
                                                <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>*</span>
                                                {optionStudentsLoading &&
                                                    <div className=" mt-1 pt-1 position-absolute " style={{ right: "8px", zIndex: "9999" }}>
                                                        <div className="spinner-border ms-5 ms-md-0" role="status" style={{ height: "23px", width: "23px" }} >
                                                            <span className="sr-only">Loading...</span>
                                                        </div>
                                                    </div>}
                                                <MultiSelect
                                                    options={studentList}
                                                    value={studentsSelected}
                                                    onChange={(e: any) => onSelectedStudent(e)}
                                                    labelledBy="Select"
                                                />

                                                {studentErorr && <p className="text-inprogess">Please select student</p>}
                                            </div>
                                            {studentsSelected.length > 0 && <div>
                                                <span style={{ paddingRight: '30px' }}>count:{studentsSelected.length}</span>
                                                <button type="button" className='btn btn-sm btn-primary px-4 rounded-12 d-inline-block cursor-pointer' onClick={handleView}>View</button>
                                            </div>}
                                        </div>
                                        <div className="col-md-2">
                                            <div className="mb-4 mt-2 ">
                                                <label htmlFor="form-label mb-0"> Page</label>
                                                <select
                                                    className='form-select'
                                                    value={activePageOption}
                                                    onChange={(e) => handleStudentsPerPageChange(e.target.value)}
                                                >
                                                    {pageListOptions?.map((option: any) => (
                                                        <option key={option} value={option}>
                                                            {option}
                                                        </option>
                                                    ))}
                                                </select>

                                            </div>
                                        </div>
                                        <div className="col-md-6 custom-form">
                                            <div className="mb-4">
                                                <label className="form-label mb-0">Module price per student</label>
                                                <input className="form-control form-control-lg" type="text" name="amount" defaultValue={paymentInfo.amount} onChange={(e) => { onChangeModuleAmount(e) }} />
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </FormBuilder>}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary cursor-pointer" data-dismiss="modal" onClick={handleclose}>Close</button>
                            <button type="button" className="btn btn-primary cursor-pointer" onClick={handleSubmit}>Next</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`modal fade ${canShowStudentPopup ? 'show' : 'false'}`} style={{ display: (canShowStudentPopup ? "block" : 'none') }} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog assign-user-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Selected Student List</h5>
                            <button type="button" className="close popup-close cursor-pointer" data-dismiss="modal" aria-label="Close" onClick={handleStudentClose}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            {studentsLoading &&
                                <div className="text-center p-5">
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div>
                            }
                            {!studentsLoading && <div className="mar-t-20 custom-scroll table-responsive">
                                <table className="table table_new_ui mb-0">
                                    <thead className='assign-student-head'>
                                        <tr>
                                            <th>Student Name</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody className='assign-student-body'>
                                        {studentsSelected.length > 0 && studentsSelected.map((name: any, i: number) => (
                                            <tr key={i}>
                                                <td>{name.label}</td>
                                                <td>
                                                    <i className="bi bi-trash cursor-pointer p-2" onClick={() => onClickDelete(name, i)}></i>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary cursor-pointer" data-dismiss="modal" onClick={handleStudentClose}>Close</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`modal fade ${canShowPaymentDetailsPopup ? 'show' : 'false'}`} style={{ display: (canShowPaymentDetailsPopup ? "block" : 'none') }} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog subscription-payment-dialog" role="document">
                    <div className="modal-content subscription-payment-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Payment Details</h5>
                            <button type="button" className="close popup-close" data-dismiss="modal" aria-label="Close" onClick={handlePaymentDetailsClose}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body  ">
                            {paymentDetailsLoading &&
                                <div className="text-center p-5">
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div>
                            }
                            {!paymentDetailsLoading && <div className={`mar-t-20 custom-scroll table-responsive ${subscriptionData?.length > 10 && "tableFixHead"}`}>
                                <table className=" table table_new_ui fixed-table-header">
                                    <thead >
                                        <tr>
                                            <th>S.No</th>
                                            <th>Student Name</th>
                                            <th>Module Name</th>
                                            <th>Subscription Price</th>
                                            <th>Module Price</th>
                                            <th>Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {subscriptionData?.map((name: any, i: number) => (
                                            <tr key={i}>
                                                <td>{i + 1}</td>
                                                <td>{name.name}</td>
                                                <td>{paymentInfo.module_code}</td>
                                                <td>{name.subscription_payment_status === "completed" ? <span>-</span> : <span>{name.subscription_price}</span>}</td>
                                                <td>
                                                    {currentPriceStudent ? currentPriceStudent : paymentInfo.amount}
                                                </td>
                                                <td>
                                                    {getTotalPrice(name)}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>

                            </div>}
                            {subscriptionData.length > 0 &&
                                <div className='d-flex justify-content-between p-3' style={{ backgroundColor: '#f2f2f2', margin: '0px' }}>
                                    <div className='' style={{ fontWeight: 'bold' }}>Total Amount to Pay</div>
                                    <div className='' style={{ fontWeight: 'bold' }}>
                                        {totalAmount}
                                    </div>
                                </div>}

                        </div>
                        <div className="modal-footer">

                            {subscriptionData.length > 0 && <button type="button" className="btn btn-primary cursor-pointer" onClick={handlePayment}>{(subscriptionData.length * (subscriptionData[0].subscription_price + (currentPriceStudent ? parseInt(currentPriceStudent) : paymentInfo.amount)) > 0) ? 'Pay Now' : 'Assign'}</button>}
                            <button type="button" className="btn btn-secondary cursor-pointer" data-dismiss="modal" onClick={handlePaymentDetailsClose}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
            {loading &&
                <div className="text-center p-5 mt-5">
                    <div className="spinner-border mt-5" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading &&
                <div className="table-responsive">
                    <table className="table table_new_ui">
                        <thead>
                            <tr>
                                <th>Sr.No</th>
                                <th>Module Name</th>
                                <th>Price / Student</th>
                                {(userRole === 'SCHOOL_ADMIN' || userRole === 'PRINCIPAL' || userRole === "INSTITUTE_ADMIN" || userRole === "CHAIRMAN" || userRole === "GENERAL_SECRETARY") && <th>Action</th>}
                            </tr>
                        </thead>
                        <tbody>
                            {paymentList?.map((name: any, i: number) => (
                                <tr key={i}>
                                    <td>{i + 1}</td>
                                    <td>{name.module_code}</td>
                                    <td>{name.amount}</td>
                                    {(userRole === 'SCHOOL_ADMIN' || userRole === 'PRINCIPAL' || userRole === "INSTITUTE_ADMIN" || userRole === "CHAIRMAN" || userRole === "GENERAL_SECRETARY") && <td>
                                        <button className="file btn btn-sm btn-primary px-4 rounded-12 d-inline-block cursor-pointer" onClick={() => onClickPay(name)}>Select Student List</button>
                                    </td>}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {paymentList?.length === 0 && <p className="f14 text-center mt-2">No Data Available</p>}
                </div>}
        </div>
    )
}