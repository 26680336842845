import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { GET_MODULES_BY_SCHOOL_ID } from "../../../../app/service/module.service";

export const PaymentlistData = () => {
    const [paymentList, setPaymentList] = useState<any>([]);
    let { id, schoolCode } = useParams<{ id: string, schoolCode: string }>();
    const schoolsCode = schoolCode;
    const [loading, setLoading] = useState(false);
    const userRole: any = localStorage.getItem("user_role");
    const history = useHistory();



    useEffect(() => {
        const userRole = localStorage.getItem("user_role");
        getPayments();

    }, []);

    function getPayments() {
        setLoading(true);
        GET_MODULES_BY_SCHOOL_ID(schoolsCode).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    const data = res.rows;
                    const moduleData = data.filter((el: any) => el.module_code !== 'CAS-PARENT');
                    setPaymentList(moduleData);
                    setLoading(false);
                }
            }
        });
    }
    function onClickPay(data: any) {
        history.push(`/institute-dashboard/manage-school/list/${id}/payment-list/${schoolsCode}/${data.module_uuid}`)
    }

    return (
        <div>
            {loading &&
                <div className="text-center p-5 mt-5">
                    <div className="spinner-border mt-5" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading &&
                <div className="table-responsive">
                    <table className="table table_new_ui">
                        <thead>
                            <tr>
                                <th>Sr.No</th>
                                <th>Module Name</th>
                                <th>Price / Student</th>
                                {(userRole === 'INSTITUTE_ADMIN' || userRole === "CHAIRMAN") && <th>Action</th>}

                            </tr>
                        </thead>
                        <tbody>
                            {paymentList?.map((name: any, i: number) => (
                                <tr key={i}>
                                    <td>{i + 1}</td>
                                    <td>{name.module_code}</td>
                                    <td>{name.amount}</td>
                                    {(userRole === 'INSTITUTE_ADMIN' || userRole === "CHAIRMAN") && <td>
                                        <button className="file btn btn-sm btn-primary px-4 rounded-12 d-inline-block cursor-pointer" onClick={() => onClickPay(name)}>Select Student List</button>
                                    </td>}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {paymentList?.length === 0 && <p className="f14 text-center mt-2">No Data Available</p>}
                </div>}
        </div>
    )
}