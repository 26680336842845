import React, { useEffect, useState } from 'react'
import { Link, NavLink, useHistory, useLocation, useParams } from 'react-router-dom'
import { toast } from 'react-toastify';
import { Breadcum } from '../../../../../components/breadcum';
import { GET_MLA_TWO_QUESTIONS, GET_MLA_TWO_WORKSHEETS, MLA_TWO_ABILITY_UPDATE } from '../../../../../app/service/mla-two.service';
import { MathJaxContext, MathJax } from 'better-react-mathjax';

export const MLATwoWorksheetInFo = () => {
    const [loading, setLoading] = useState(false);
    const [worksheetNumbers, setWorksheetNumbers] = useState([]);
    const [canShowQuestionsPopup, setCanShowQuestionsPopup] = useState(false);
    const [loadingWorksheetQuestions, setLoadingWorksheetQuestions] = useState(false);
    const [questionsData, setQuestionsData] = useState<any[]>([]);
    const [worksheetNumber, setWorksheetNumber] = useState<any>('');
    const [canShowWorksheetActionsPopup, setCanShowWorksheetActionsPopup] = useState(false);
    let { id, code, ability_code } = useParams<{ id: string, code: string, ability_code: string }>();
    // const studentId = Number(id);
    const moduleId = Number(id);
    const moduleCode = code;
    const location = useLocation();
    const history = useHistory();
    const [breadcumInfo, setBreadcumInfo] = useState<any>([
        {
            label: 'Worksheets',
            value: 'Worksheets',
            routerLink: `/dashboard/module/info/MLA-2/${code}/${id}/worksheet`,
            isActive: false
        },
        {
            label: 'Module Name',
            value: ability_code.replace(/_/g, ' '),
            routerLink: '',
            isActive: true
        }
    ]);

    //destructuring pathname from location
    const { pathname } = location;

    //Javascript split method to get the name of the path in array
    const splitLocation = pathname.split("/");

    useEffect(() => {
        getWorksheets(ability_code);
        setBreadcumInfo(breadcumInfo);
    }, []);

    function getWorksheets(code: any) {
        setLoading(true);
        GET_MLA_TWO_WORKSHEETS(code).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    setLoading(false);
                    setWorksheetNumbers(res.worksheets);
                }
            }
        });
    }

    function onCreateWorksheet() {
        history.push(`/dashboard/module/info/MLA-2/${code}/${id}/worksheet-add/${worksheetNumbers.length + 1}/${ability_code}`);
    }

    const handleQuestionsClose = () => {
        setCanShowQuestionsPopup(false);
    }

    const onWorkSheetClick = (item: any) => {
        setCanShowQuestionsPopup(true);
        getWorksheetQuestions(item.worksheet_no);
    }

    function getWorksheetQuestions(worksheet_no: number) {
        let payload = {
            "mode": 'WORKSHEETS',
            "test_no": '',
            "ability": ability_code ? ability_code : '',
            "worksheet_no": worksheet_no
        }
        setLoadingWorksheetQuestions(true);
        GET_MLA_TWO_QUESTIONS(payload).then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res.message);
                setLoadingWorksheetQuestions(false);
            } else {
                setLoadingWorksheetQuestions(false);
                setQuestionsData(res.questions);
            }
        })
    }

    function onWorkSheetEditClick(item: any) {
        history.push(`/dashboard/module/info/MLA-2/${code}/${id}/worksheet-edit/${item.worksheet_no}/${ability_code}`);
    }

    function onWorkSheetDelete(item: any) {
        setCanShowWorksheetActionsPopup(true);
        setWorksheetNumber(item.worksheet_no);
    }

    const handleWorksheetTypeClose = () => {
        setCanShowWorksheetActionsPopup(false);
    }

    function onViewQuestion(name: any) {
        return { __html: name };
    }

    function handleDelete() {
        setCanShowWorksheetActionsPopup(false);
        const payload = {
            "mode": "WORKSHEETS",
            "status": 'DELETE',
            "ability_code": ability_code,
            "url": '',
            "worksheet_no": worksheetNumber ? worksheetNumber : '',
            data: []
        }
        setLoading(true);
        MLA_TWO_ABILITY_UPDATE(payload).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    getWorksheets(ability_code);
                    setLoading(false);
                }
            }
        });
    }

    return (
        <div className="row  py-3">
            <div className={`modal fade parent-modal-fade ${canShowQuestionsPopup ? 'show' : 'false'}`} style={{ display: (canShowQuestionsPopup ? "block" : 'none') }} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog worksheet-quesions-dialog" role="document">
                    <div className="modal-content worksheet-questions-model">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Questions List</h5>
                            <button type="button" className="close popup-close cursor-pointer" data-dismiss="modal" aria-label="Close" onClick={handleQuestionsClose}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            {loadingWorksheetQuestions &&
                                <div className="text-center p-5">
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div>
                            }
                            {!loadingWorksheetQuestions && <div>
                                <MathJaxContext>
                                    <MathJax>
                                        {questionsData.map((question: any, i: number) => (
                                            <div className="mt-4" key={i}>
                                                <div className='row col-md-12'>
                                                    <div className='row'>
                                                        <div className='col-md-1' style={{ alignSelf: 'center' }}>
                                                            <span className='fw-bold' style={{ fontSize: '22px' }}>Q{question.question_number}:</span>
                                                        </div>
                                                        <div className='col-md-11' style={{ paddingTop: '15px' }}>
                                                            <span className='fw-bold' style={{ fontSize: '22px' }}>
                                                                {/* <MathJaxContext> */}
                                                                {/* <MathJax> */}
                                                                <div dangerouslySetInnerHTML={onViewQuestion(question.question)}></div>
                                                                {/* </MathJax> */}
                                                                {/* </MathJaxContext> */}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    {question.options.map((options: any, j: number) => (
                                                        <div className='row' key={j}>
                                                            <div className='col-md-1'>
                                                                <span>{options.options}.</span>
                                                            </div>
                                                            <div className='col-md-6'>
                                                                {/* <MathJaxContext> */}
                                                                {/* <MathJax> */}
                                                                <span dangerouslySetInnerHTML={onViewQuestion(options.value)}></span>
                                                                {/* </MathJax> */}
                                                                {/* </MathJaxContext> */}
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        ))}
                                    </MathJax>
                                </MathJaxContext>
                            </div>}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary cursor-pointer" data-dismiss="modal" onClick={handleQuestionsClose}>Close</button>&nbsp;
                        </div>
                    </div>
                </div>
            </div>
            <div className={`modal fade parent-modal-fade ${canShowWorksheetActionsPopup ? 'show' : 'false'}`} style={{ display: (canShowWorksheetActionsPopup ? "block" : 'none') }} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">{`Are you sure want to delete  Worksheet Number ${worksheetNumber}`}</h5>
                            <button type="button" className="close popup-close cursor-pointer" data-dismiss="modal" aria-label="Close" onClick={handleWorksheetTypeClose}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary cursor-pointer" onClick={() => handleDelete()}>Yes</button>
                            <button type="button" className="btn btn-secondary cursor-pointer" data-dismiss="modal" onClick={handleWorksheetTypeClose}>Cancel</button>&nbsp;
                        </div>
                    </div>
                </div>
            </div>
            <Breadcum breadcumList={breadcumInfo} />
            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading && <div className='row'>
                <React.Fragment>
                    <div>
                        <button className="px-4 py-1 cursor-pointer msb-btn" style={{ float: 'right' }} onClick={() => onCreateWorksheet()}>Add Worksheet</button>&nbsp;
                    </div>
                    <div className="table-responsive mt-4">
                        <table className="table fixed-table-header mb-0">
                            <thead>
                                <tr>
                                    <th>Worksheet Number</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {worksheetNumbers.map((name: any, i: number) => (
                                    <tr key={i}>
                                        <td>{name.worksheet_no}</td>
                                        <td>
                                            <i className="bi bi-eye-fill cursor-pointer p-2" onClick={() => onWorkSheetClick(name)}></i>
                                            <i className="bi bi-pencil-square cursor-pointer edit-icon p-2" onClick={() => onWorkSheetEditClick(name)}></i>
                                            <i className="bi bi-trash cursor-pointer p-2" onClick={() => onWorkSheetDelete(name)}></i>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    {worksheetNumbers.length === 0 && <div className='pt-2' style={{ textAlign: 'center' }}>
                        No records Found
                    </div>}
                </React.Fragment>
            </div>}
        </div>
    )
}

