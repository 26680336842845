

import React, { useEffect, useState } from 'react'
import EYEICONS from '../../../../assets/images/eye-icons.svg'
import { ToastContainer, toast } from 'react-toastify';
import { GET_SCHOOL_DASHBOARD } from '../../../../app/service/school-dashboard.service';
import { Link, useParams } from 'react-router-dom';

function CasClassWiseAnalysys() {

    const [loading, setLoading] = useState(false);
    const [averageData, setAvarageData] = useState<any>([])
    const [schoolData, setSchoolData] = useState<any>([]);
    const [classWise, setClassWise] = useState<any>([]);
    const [casSectionData, setCasSectionData] = useState<any>([]);
    const [casAspetctData, setCasAspectData] = useState<any>([]);
    const userRole = localStorage.getItem("user_role");
    let { className } = useParams<{ className: string }>();


    useEffect(() => {
        schoolDashboardDetails();


    }, []);
    const schoolDashboardDetails = () => {

        const data: any = localStorage.getItem("institute_code");

        setLoading(true);
        GET_SCHOOL_DASHBOARD(data).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    if (res) {
                        const data = res;
                        setSchoolData(data);

                        let selectedClassData = data.cas_data.class_wise_data.filter((ele: any) => ele.class == className)
                        const preparedData = data.cas_data.section_wise_data.filter((ele: any) => ele.class == className);
                        selectedClassData = selectedClassData.concat(preparedData)
                        setClassWise(selectedClassData);

                        let eachAspect = data.cas_data.class_wise_data.find((data: any) => data.class == parseInt(className));
                        setAvarageData(eachAspect.average_results);

                        let sectionAspect = data.cas_data.section_wise_data.filter((data: any) => data.class == parseInt(className));
                        setCasSectionData(sectionAspect);

                        let classWiseCasData: any[] = [];
                        Object.keys(data.cas_data.average_results).map((x: any, i: number) => {
                            let aspectData: any[] = [];
                            for (let j = 0; j < sectionAspect.length; j++) {
                                let element = sectionAspect[j].average_results;
                                aspectData.push(element[x] ? element[x] : 0);
                            }
                            classWiseCasData.push({
                                name: x,
                                class_data: aspectData
                            })
                        });
                        setCasAspectData(classWiseCasData);

                    }

                    setLoading(false);
                }
            }
        });
    }



    return (
        <>
            {loading &&
                <div className="text-center p-5 mt-5">
                    <div className="spinner-border mt-5" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading &&
                <div>
                    <div className="d-flex flex-wrap flex-md-nowrap justify-content-between ">
                        <h3 className="d-lg-none" >Dashboard</h3>
                    </div>

                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb border-bottom ps-0 pt-0 pb-2">
                            {userRole === "SUPER_ADMIN" && <li className="breadcrumb-item mt-3"><Link to='/dashboard/view/schoolinfo' style={{ color: '#0d6efd' }}>Dashboard</Link> / <span>{className}<sup>th</sup> Class</span></li>}
                            {userRole !== "SUPER_ADMIN" && <li className="breadcrumb-item"> <Link to='/school-dashboard/view' style={{ color: '#0d6efd' }}>Dashboard</Link> / <span>{className}<sup>th</sup> Class</span></li>}
                        </ol>
                    </nav>
                    <h3 className='mt-4 mb-0 section-hd'>CAS Initiatives - Attempted</h3>
                    <p className='text-gray f12'>Class Wise/Section wise</p>

                    <div className='table-responsive'>
                        <table className='table table_new_ui'>
                            <thead>
                                <tr>
                                    <th style={{ width: "120px" }}>Class/Section</th>
                                    <th style={{ width: '120px' }}>Registered</th>
                                    <th style={{ width: '100px' }}> Girls </th>
                                    <th style={{ width: '100px' }}> Boys </th>
                                    <th style={{ width: '100px' }}>Father</th>
                                    <th style={{ width: '100px' }}>Mother</th>
                                    <th style={{ width: "100px" }}>Total Attempted</th>

                                </tr>
                            </thead>
                            <tbody >
                                {schoolData?.cas_data && classWise.map((data: any, index: number) => {
                                    return (
                                        <tr key={index}>
                                            <td
                                            >{data.class_section ? data.section : data.class}
                                            </td>

                                            <td> {data.registered_students}</td>

                                            <td>{data.attempted_girls}</td>

                                            <td>{data.attempted_boys}</td>

                                            <td>{data.attempted_father}</td>

                                            <td>{data.attempted_mother}</td>

                                            <td>{data.attempted_students}</td>

                                        </tr>

                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                    {(schoolData?.cas_data && schoolData?.cas_data.class_wise_data.length === 0) && <div className="text-center mb-3 f14">No Data Available</div>}
                    <div className='row mb-4'>
                        <div className='col-md-5'>
                            <h3 className='mt-4 mb-0 section-hd f14'>Average % of each Aspect of CAS</h3>
                            <p className='text-gray f12'> For Complete Class</p>
                            {schoolData?.cas_data && (Object.keys(averageData).length > 0) ? Object.keys(averageData).map((data: any, index: number) => {
                                return (
                                    <React.Fragment key={index}>
                                        <p className='my-1 f12'>{data.split(/_/g).map((word: any) => `${word.substring(0, 1).toUpperCase()}${word.substring(1)}`)
                                            .join(" ")}</p>
                                        <div className="progress_bar f12">
                                            <div className="progress_range_cas px-1 " style={{ width: `${averageData[data]}%` }}><span>{averageData[data]}%</span></div>
                                        </div>
                                    </React.Fragment>
                                )
                            }) : <p className='mt-3 ms-3 f14'> No Data Available</p>}

                            <h3 className='mt-4 mb-0 section-hd'>CAS Initiatives - Attempted</h3>
                            <p className='text-gray f12'>No. of Students Registered in each Section</p>

                            {(Object.keys(averageData).length > 0) ? <div className='table-responsive'>

                                <table className='table table_new_ui'>
                                    <thead>
                                        <tr>
                                            <th style={{ width: '360px' }}>Aspect</th>
                                            {schoolData?.cas_data && casSectionData.map((data: any, index: number) => {
                                                return (
                                                    <th style={{ width: '70px' }} key={index}>{data.section}</th>
                                                )
                                            })}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {casAspetctData.length > 0 && casAspetctData.map((data: any, index: number) => {

                                            return (<tr key={index}>
                                                <td>{data.name.split(/_/g).map((word: any) => `${word.substring(0, 1).toUpperCase()}${word.substring(1)}`)
                                                    .join(" ")}</td>
                                                {data.class_data.map((y: any, j: number) => {
                                                    return (<>
                                                        <td key={j}>{y}%</td>
                                                    </>)
                                                })}
                                            </tr>
                                            )
                                        })}

                                    </tbody>

                                </table>
                            </div>
                                : <p className='my-2 ms-3 f14'>No Data Available</p>}
                        </div>
                    </div>
                </div>}
        </>
    )
}
export default CasClassWiseAnalysys;
