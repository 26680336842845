import React, { useEffect, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import { GET_DASHBOARD } from '../../../app/service/dashboard.service';
import Moment from 'react-moment';
import { Link, useHistory } from 'react-router-dom';

export const DashboardView = () => {
    const [loading, setLoading] = useState(false);
    const [dashboardData, setDashboardData] = useState<any>({});
    const history = useHistory();
    useEffect(() => {
        setLoading(true);
        GET_DASHBOARD().then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    const data = res;
                    setDashboardData(data);
                    setLoading(false);
                }
            }
        });
    }, []);

    return (
        <div>
            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading && <div>
                <div className="row border-top border-primary py-3">
                </div>
                <div className="row">
                    <div className="col-md-3 col-12 d-md-flex d-xxl-block" >
                        <div className="card info-card shadow  mb-5 rounded " style={{ backgroundColor: '#488A99' }}>
                            <Link to="/dashboard/module/list" >
                                <div className="card-body " >
                                    <h5 className="card-title text-center text-white">Total Modules: {dashboardData?.modules?.count ? dashboardData?.modules?.count : 0}</h5>
                                    <div className="text-white text-center" style={{ fontSize: '14px', paddingLeft: '15px' }}>Last Modified at:

                                        <Moment format="DD/MM/YYYY HH:MM A">
                                            {dashboardData?.modules?.last_updated_date}
                                        </Moment>
                                    </div>
                                </div></Link>
                        </div>
                    </div>

                    <div className="col-md-3 col-12 d-md-flex d-xxl-block">

                        <div className="card info-card shadow  mb-5 rounded " style={{ backgroundColor: '#EA6A47' }}>
                            <Link to="/dashboard/view/instituteslist" >  <div className="card-body" >
                                <h5 className="card-title text-center text-white">Total Institutes: {dashboardData?.institutes?.count ? dashboardData?.institutes?.count : 0}</h5>
                                <div className="text-white text-center" style={{ fontSize: '14px', paddingLeft: '15px' }}>Last Modified at:
                                    <Moment format="DD/MM/YYYY HH:MM A">
                                        {dashboardData?.institutes?.last_updated_date}
                                    </Moment>
                                </div>
                            </div></Link>
                        </div>
                    </div>
                    <div className="col-md-3 col-12 d-md-flex d-xxl-block">

                        <div className="card info-card shadow   mb-5 rounded" style={{ backgroundColor: '#DBAE58' }}>
                            <Link to="/dashboard/view/schoolslist" ><div className="card-body">
                                <h5 className="card-title text-center text-white">Affiliated Schools: {dashboardData?.schools?.count ? dashboardData?.schools?.count : 0}</h5>
                                <div className="text-white text-center" style={{ fontSize: '14px', paddingLeft: '15px' }}>Last Modified at:
                                    <Moment format="DD/MM/YYYY HH:MM A">
                                        {dashboardData?.schools?.last_updated_date}
                                    </Moment>
                                </div>
                            </div></Link>
                        </div>
                    </div>

                    <div className="col-md-3 col-12 d-md-flex d-xxl-block">
                        <div className="card info-card shadow  mb-5 rounded" style={{ backgroundColor: '#F39D31' }}>
                            <div className="card-body">
                                <h5 className="card-title text-center text-white">Registered Students: {dashboardData?.students?.count ? dashboardData?.students?.count : 0}</h5>
                                <div className="text-white text-center" style={{ fontSize: '14px', paddingLeft: '15px' }}>Last Modified at:
                                    <Moment format="DD/MM/YYYY HH:MM A">
                                        {dashboardData?.students?.last_updated_date}
                                    </Moment>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 col-12 d-md-flex d-xxl-block">
                        <div className="card info-card shadow mb-5  rounded" style={{ backgroundColor: '#F39D31' }}>
                            <div className="card-body " >
                                <h5 className="card-title text-center text-white">Student Completed CAS: {dashboardData?.completed_cas?.count ? dashboardData?.completed_cas?.count : 0}</h5>
                                <div className="text-white text-center" style={{ fontSize: '14px', paddingLeft: '15px' }}>Last Modified at:
                                    <Moment format="DD/MM/YYYY HH:MM A">
                                        {dashboardData?.completed_cas?.last_updated_date}
                                    </Moment>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>}
        </div>
    )
}