import React, { useEffect, useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import { BULK_UPLOAD_TEACHERS, DELETE_USER, GET_USER_BY_SCHOOL_CODE } from '../../../../app/service/users.service'
import { DataTable } from '../../../../components/data-table'
import { TeachersDataGridCols } from './data-grid-cols'
import { ToastContainer, toast } from 'react-toastify';
import appConfig from '../../../../config/constant'

export const TeachersList = () => {
    let { id, schoolCode } = useParams<{ id: string, schoolCode: string }>();
    const schoolId = parseInt(id);
    const schoolsCode = schoolCode;
    const [pageArray, setPageNumbers] = useState([]);
    const [activePage, setActivePage] = useState(1);
    const [teachersList, setTeachersList] = useState<any>([]);
    const [loading, setLoading] = useState(false);
    const [userRole, setUserRole] = useState<any>();
    const [accessObjectsList, setAccessObjectsList] = useState<any>([]);
    const [schoolTeacher, setSchoolTeacher] = useState(false);
    const history = useHistory();

    useEffect(() => {
        const userRole = localStorage.getItem("user_role");
        setUserRole(userRole);
        getTeachers();
        getActionsInfo(userRole);

        const accessObjectsData: any = localStorage.getItem("access_objects_data");
        setAccessObjectsList(JSON.parse(accessObjectsData));
        handleSchoolsTeacherCols(JSON.parse(accessObjectsData));
    }, []);


    function getActionsInfo(userRole: any) {
        for (let i = 0; i < TeachersDataGridCols.length; i++) {
            if (TeachersDataGridCols[i].title === "Actions") {
                if (userRole === 'INSTITUTE_ADMIN') {
                    TeachersDataGridCols.splice(5, 1);
                } else if (userRole === 'SCHOOL_ADMIN') {
                    TeachersDataGridCols[i].control = "one";
                }
            }
        }
    }


    function getTeachers() {
        if (schoolsCode) {
            setLoading(true);
            GET_USER_BY_SCHOOL_CODE(schoolsCode, 'TEACHER', 0, {}).then((res: any) => {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    const data = res.rows;
                    setTeachersList(data);
                    const pageNumbers = Math.ceil(res.count / 10);
                    const pageArray: any = [];
                    for (let i = 1; i <= pageNumbers; i++) {
                        pageArray.push(i);
                    }
                    setPageNumbers(pageArray);
                    setLoading(false);
                }
            });
        }
    }


    const onPageChange = (data: any) => {
        setActivePage(data);
        const pageNumber = data - 1;
        setLoading(true);
        GET_USER_BY_SCHOOL_CODE(schoolsCode, 'TEACHER', pageNumber, {}).then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res.message);
                setLoading(false);
            } else {
                const data = res.rows;
                setTeachersList(data);
                const pageNumbers = Math.ceil(res.count / 10);
                const pageArray: any = [];
                for (let i = 1; i <= pageNumbers; i++) {
                    pageArray.push(i);
                }
                setPageNumbers(pageArray);
                setLoading(false);
            }
        });

    }

    const onEditTeacher = (data: any) => {
        history.push(`/dashboard/school/info/${schoolId}/teachersform/${schoolsCode}/${data.id}`);
        if (userRole === "CHAIRMAN" || userRole === "GENERAL_SECRETARY" || userRole === "INSTITUTE_ADMIN") {
            history.push(`/institute-dashboard/school/info/${schoolId}/teachersform/${schoolsCode}/${data.id}`);

        }
    }

    const onDeleteTeacher = (data: any) => {
        setLoading(true);
        const id = data.user_code;
        DELETE_USER(id).then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res.message);
                setLoading(false);
            } else {
                setLoading(false);
                toast.success("Teachers Deleted Successfully");
                getTeachers();
            }
        });
    }

    const handleSchoolsTeacherCols = (accessObjectsList: any) => {
        const preparedData = accessObjectsList?.filter((el: any) => el.object_code === "SCHOOLS");
        if (preparedData?.length > 0) {
            for (let i = 0; i < preparedData[0].child.length; i++) {
                for (let j = 0; j < TeachersDataGridCols.length; j++) {
                    if (
                        preparedData[0].child[i].object_code === "SCHOOLS-TEACHER" &&
                        preparedData[0].child[i].p_read === 1
                    ) {
                        setSchoolTeacher(true);
                    }
                    if (preparedData[0].child[i].object_code === "SCHOOLS-TEACHER-EDIT" && preparedData[0].child[i].p_read === 1) {
                        if (TeachersDataGridCols[j].title == "Actions") {
                            TeachersDataGridCols[j].isEdit = true;
                        }

                    }

                    if (preparedData[0].child[i].object_code === "SCHOOLS-TEACHER-DELETE" && preparedData[0].child[i].p_read === 1) {
                        if (TeachersDataGridCols[j].title == "Actions") {
                            TeachersDataGridCols[j].isDelete = true;
                        }
                    }
                    if (preparedData[0].child[i].object_code === "SCHOOLS-TEACHER-SET-PASSWORD" && preparedData[0].child[i].p_read === 1) {
                        if (TeachersDataGridCols[j].title == "Actions") {
                            TeachersDataGridCols[j].setPassword = true;
                        }
                    }

                    if ((preparedData[0].child[i].object_code === "SCHOOLS-TEACHER-SET-PASSWORD" && preparedData[0].child[i].p_read === 1) ||
                        (preparedData[0].child[i].object_code === "SCHOOLS-TEACHER-DELETE" && preparedData[0].child[i].p_read === 1) ||
                        (preparedData[0].child[i].object_code === "SCHOOLS-TEACHER-EDIT" && preparedData[0].child[i].p_read === 1)) {
                        if (TeachersDataGridCols[j].title == "Actions") {

                            TeachersDataGridCols[j].canShowColumn = true;
                        }
                    }
                }
            }
        }

    }
    function handleUploadBulkUpload(e: any) {
        if (e.target.files && e.target.files[0]) {
            let formData = new FormData();
            formData.append('file', e.target.files[0], e.target.files[0].name);
            formData.append('school_code', schoolsCode);
            setLoading(true);

            BULK_UPLOAD_TEACHERS(formData).then((res: any) => {
                if (res.status === 'fail') {
                    toast.error(res?.error);
                    setLoading(false);
                } else {
                    setLoading(false);
                    toast.success(res?.message);
                    getTeachers();
                }
            });
        }

    }
    function handleDownloadTemplate() {
        setLoading(true);
        const data = '/templates/teachers_bulk_upload.xlsx';
        if (data) {
            const url = `${appConfig.FILE_UPLOAD_URL}${data.charAt(0) === "/" ? data.substring(1) : data}`;
            window.location.href = url;
            toast.success("Download Teacher Template Successfully");

        }
        setLoading(false);


    }
    return (
        <div>
            {schoolTeacher &&
                <>
                    {(userRole === 'SUPER_ADMIN' || userRole === 'SCHOOL_ADMIN') &&
                        <div className="border-primary py-3 text-end">
                            <button className="btn btn-sm btn-primary px-4 rounded-12 cursor-pointer" style={{ marginRight: '10px', color: 'white' }} onClick={() => handleDownloadTemplate()}>Teacher List Template Download
                            </button>
                            <div className="file btn btn-sm btn-primary px-4 rounded-12 d-inline-block" style={{ marginRight: '10px', cursor: 'pointer' }}>Teacher list upload
                                <input type="file" style={{ cursor: 'pointer' }} onChange={(e) => handleUploadBulkUpload(e)} />
                            </div>
                            {userRole !== "CHAIRMAN" && userRole !== "GENERAL_SECRETARY" && userRole !== "INSTITUTE_ADMIN" && <Link to={`/dashboard/school/info/${schoolId}/teachersform/${schoolsCode}/0`} className="btn btn-primary btn-sm px-5 rounded-12">New</Link>}
                            {(userRole === "CHAIRMAN" || userRole === "GENERAL_SECRETARY" || userRole === "INSTITUTE_ADMIN") && <Link to={`/institute-dashboard/school/info/${schoolId}/teachersform/${schoolsCode}/0`} className="btn btn-primary btn-sm px-5 rounded-12">New</Link>}

                        </div>
                    }
                    {loading &&
                        <div className="text-center p-5">
                            <div className="spinner-border" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                    }
                    {!loading &&
                        <DataTable TableCols={TeachersDataGridCols}
                            editInfo={onEditTeacher} deleteInfo={onDeleteTeacher}
                            tableData={teachersList} activePageNumber={activePage}
                            pageNumber={onPageChange} pageNumbers={pageArray}></DataTable>}
                </>
            }
        </div>
    )
}