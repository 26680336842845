import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { MathJaxContext, MathJax } from 'better-react-mathjax';
import TimerInfo from '../dashboard/mns/timer/timerinfo';
import { FETCH_ABHYAS_USER_QUESTION_PAPER, UPDATE_ABHYAS_USER_QUESTION_PAPER } from '../../app/service/abhyas-module-questions.service';
import { UPDATE_PARIKSHA_USER_QUESTION_PAPER } from '../../app/service/pariksha-module-questions';

declare const window: any;


export const AbhyaasMobileQuestionsForm = () => {
    const [questionsData, setQuestionsData] = useState<any>([]);
    const [loading, setLoading] = useState(false);
    const [startTimer, setStartTimer] = useState(false);
    const [canShowHeader, setShowHeader] = useState(false);
    const [timeRemain, setTimeRemain] = useState<any>('');
    const [startTime, setStartTime] = useState(new Date());
    const [alphabets, setAlphabets] = useState<any>(["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z"]);
    const [canShowPopup, setCanShowPopup] = useState(false);
    const [test_start_date, setTestStartDate] = useState<any>('');
    let { moduleUUID, userCode, schoolCode, subjectUuid } = useParams<{ moduleUUID: string, userCode: string, schoolCode: string, subjectUuid: string }>();
    const history = useHistory();
    const [minutes, setMinutes] = useState(60);
    const [seconds, setSeconds] = useState(0);
    let completedTime: string = "";
    let time_remain_storage = '';
    let timeUp = false;
    const [isActive, setIsActive] = useState<boolean>(false);
    let selectUuids = JSON.parse(sessionStorage.getItem('userSelectedSubjectInfo') || '{}');
    const questionRefs = useRef<(HTMLInputElement | null)[]>([]);
    // const type = sessionStorage.getItem("abhyas_type");
    // const testInfo: any = JSON.parse(sessionStorage.getItem("abhyas_test_info") || '{}');
    // const cycleNumber = sessionStorage.getItem("abhyas_cycle_no");
    // const worksheets: any = JSON.parse(sessionStorage.getItem('abhyas_worksheets') || '{}');
    const [appData, setAppData] = useState<any>({});
    const [timeRemainForApp, setTimeRemainForApp] = useState<string>('');
    const [webviewInitialized, setWebviewInitialized] = useState<boolean>(false);


    // useEffect(() => {

    //     document.addEventListener("message", (message: any) => {
    //         let data = JSON.parse(message.data);
    //         if (data?.key && data.key === 'init') {
    //             setAppData(data);
    //             getTimeDetails(data);
    //             localStorage.setItem("token", data.token);
    //             getAbhyaasQuestionsList(data);
    //         }
    //     });

    // }, []);
    useEffect(() => {
        document.addEventListener("message", handleNativeMessage);

        return () => {
            window.removeEventListener('message', handleNativeMessage);
        };
    }, []);


    useEffect(() => {
        if (!webviewInitialized) {
            setWebviewInitialized(true);
            sendMessageToApp({ action: 'init_webview', type: '', data: 'initialized' });
        }

    }, [webviewInitialized]);
    useEffect(() => {
        let interval: NodeJS.Timeout;

        if (isActive) {
            interval = setInterval(() => {
                setSeconds((prevSeconds) => prevSeconds + 1);
            }, 1000);
        }

        return () => clearInterval(interval);
    }, [isActive]);

    function handleNativeMessage(message: any) {
        let data = JSON.parse(message.data);
        if (data?.key && data.key === 'init') {
            setAppData(data);
            getTimeDetails(data);
            localStorage.setItem("token",
                data.token);
            setTimeout(() => {
                getAbhyaasQuestionsList(data);
            }, 100);
        } else {

        }
    }

    function getTimeDetails(data: any) {
        // const testStartDate: any = sessionStorage.getItem("abhyas_test_start_date");

        // return testStartDate ? testStartDate : null;
        const testStartDate: any = data.abhyas_test_start_date;
        setTestStartDate(testStartDate ? testStartDate : null);

        let time = getTime(testStartDate);
        let timeMillis = moment(time, 'YYYY-MM-DD hh:mm:ss').toDate().getTime();
        let reqTimeMillis = 2 * 24 * 60 * 60 * 1000;
        let actualTimeToComplete = timeMillis + reqTimeMillis;
        let x = new Date(actualTimeToComplete)
        setTimeRemain(x);

        let currentTime = new Date().getTime();
        let timerResult = x.getTime() - currentTime;
        let tSeconds = Math.floor(timerResult / 1000);
        let hours = Math.floor(tSeconds / 3600);
        let minutes = Math.floor((tSeconds - (hours * 3600)) / 60); // get minutes
        let seconds = tSeconds - (hours * 3600) - (minutes * 60); //  get seconds
        setTimeRemainForApp((hours < 10 ? "0" + hours : hours) + ":" + (minutes < 10 ? "0" + minutes : minutes) + ":" + (seconds < 10 ? "0" + seconds : seconds) + ' hrs');

    }

    // function getTimeInfo() {
    //     // const testStartDate: any = sessionStorage.getItem("abhyas_test_start_date");
    //     // let time = getTime(testStartDate);
    //     // let timeMillis = moment(time, 'YYYY-MM-DD hh:mm:ss').toDate().getTime();
    //     // let reqTimeMillis = 2 * 24 * 60 * 60 * 1000;
    //     // let actualTimeToComplete = timeMillis + reqTimeMillis;
    //     // let x = new Date(actualTimeToComplete)
    //     // setTimeRemain(x);
    //     // let currentTime = new Date().getTime();
    //     // let timerResult = x.getTime() - currentTime;
    //     // let tSeconds = Math.floor(timerResult / 1000);
    //     // let hours = Math.floor(tSeconds / 3600);
    //     // setHoursValue(hours);
    // }



    function getTime(time: any) {
        if (time !== 'null') {
            return time;
        } else {
            return time ? time : startTime;
        }
    }


    function getAbhyaasQuestionsList(data: any) {
        let payload = {
            "type": data.type,
            "uuid": data?.question_paper_uuid ? data.question_paper_uuid : ''
        }
        setShowHeader(false);
        setLoading(true);
        FETCH_ABHYAS_USER_QUESTION_PAPER(payload).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    const errorValue = Array.isArray(res?.err) ? res?.err[0] : res?.err;
                    toast.error(errorValue);
                    setLoading(false);
                } else {
                    if (res) {
                        if (res) {
                            // if (res.hasOwnProperty("data") ) {
                            if (Array.isArray(res)) {
                                setQuestionsData(res);
                                setShowHeader(true);
                                setStartTimer(true);
                                if (data.type === 'WORKSHEETS') {
                                    setIsActive(true);
                                }
                                setLoading(false);
                            } else {
                                toast.error("Questions not found");
                                setLoading(false);

                            }

                        } else {
                            setShowHeader(true);
                            setStartTimer(true);
                            setLoading(false);
                        }

                    }

                }
            }
        });
    }






    function handleQuestionOptions(item: any, index: number, j: number) {
        const data = questionsData;
        for (let i = 0; i < alphabets.length; i++) {
            if (j === i) {
                data[index].answer = alphabets[i];
            }
        }
        sessionStorage.setItem("questionsData", JSON.stringify(data));

        setQuestionsData(data);
    }


    const handleCompleteSubmit = () => {
        if (appData?.module_code === "PARIKSHA") {
            const payload = {
                "user_code": appData?.user_code,
                "school_code": appData?.school_code,
                "subject_uuid": appData?.subject_uuid,
                "type": appData?.type,
                "test_uuid": appData?.test_uuid,
                "topic_uuid": appData?.topic_uuid,
                "worksheet_uuid": appData?.worksheet_uuid,
                "question_paper_uuid": appData?.question_paper_uuid,
                "time_taken": appData?.type === "TESTS" ? (calculateTimeDifference() ? calculateTimeDifference() : sessionStorage.getItem("setTime")) : (getTimeTaken(seconds) ? getTimeTaken(seconds) : sessionStorage.getItem("setTime")),
                "questions": questionsData.length > 0 ? questionsData : JSON.parse(sessionStorage.getItem("questionsData") || '{}'),
                "test_time": `${minutes}.0`,
            }

            setLoading(true);
            UPDATE_PARIKSHA_USER_QUESTION_PAPER(payload).then((res: any) => {
                if (res) {
                    if (res.status === 'fail') {
                        const errorValue = Array.isArray(res?.err) ? res?.err[0] : res?.err;
                        toast.error(errorValue);
                        setLoading(false);

                    } else {
                        setLoading(false);
                        const data = res;
                        if (appData?.type === 'WORKSHEETS') {
                            sendMessageToApp({ action: 'show_popup', type: 'worksheet_submitted', data: '' });
                            return;
                        }
                        sendMessageToApp({ action: 'show_popup', type: 'test_submitted', timeRemain: timeRemainForApp });

                    }

                }
            });
        } else {
            const payload = {
                "user_code": appData?.user_code,
                "school_code": appData?.school_code,
                "subject_uuid": appData?.subject_uuid,
                "cycle_no": appData?.cycle_no ? parseInt(appData?.cycle_no) : 1,
                "type": appData?.type,
                "test_no": appData && appData?.test_no ? appData?.test_no : 1,
                "question_paper_uuid": appData?.type === 'TESTS' ? (appData?.question_paper_uuid ? appData?.question_paper_uuid : '') : (appData?.question_paper_uuid ? appData?.question_paper_uuid : ''),
                "question_paper_level": appData?.level ? appData?.level : '',
                "priority_no": appData?.priority ? appData?.priority : '',
                "time_taken": appData?.type === "TESTS" ? (calculateTimeDifference() ? calculateTimeDifference() : sessionStorage.getItem("setTime")) : (getTimeTaken(seconds) ? getTimeTaken(seconds) : sessionStorage.getItem("setTime")),
                "questions": questionsData?.length > 0 ? questionsData : JSON.parse(sessionStorage.getItem("questionsData") || '{}'),
                "test_time": `${minutes}.${seconds}`,
            }
            if (appData?.type === "WORKSHEETS") {
                let addPayLoad = {
                    "topic_uuid": appData?.topic_uuid,
                    "worksheet_uuid": appData?.worksheet_uuid,
                }
                Object.assign(payload, { ...addPayLoad })
            }

            // return
            setLoading(true);
            UPDATE_ABHYAS_USER_QUESTION_PAPER(payload).then((res: any) => {
                if (res) {
                    if (res.status === 'fail') {
                        const errorValue = Array.isArray(res?.err) ? res?.err[0] : res?.err;
                        toast.error(errorValue);
                        setLoading(false);
                    } else {
                        setLoading(false);
                        const data = res;
                        if (appData?.type === 'WORKSHEETS') {
                            sendMessageToApp({ action: 'show_popup', type: 'worksheet_submitted', data: '' });
                            return;
                        }
                        sendMessageToApp({ action: 'show_popup', type: 'test_submitted', timeRemain: timeRemainForApp });
                    }

                }
            });
        }

    }

    const getCircleColor = (item: any) => {
        if (item?.status === "pending") {
            return '#FCEE21';
        } else if (item?.status === 'completed') {
            if (item.result === "fail") {
                return '#f15a24';

            } else {
                return '#8CC63F';

            }
        } else {
            return '#FFFFFF';
        }
    }

    const sendMessageToApp = (data: any) => {
        if (window?.ReactNativeWebView) {
            try {
                window?.ReactNativeWebView.postMessage(JSON.stringify(data));
            } catch (error) {

            }
        }
    }
    const handleclose = () => {
        setCanShowPopup(false);
        handleBack();
        // let prepredData = { ...selectUuids };
        // history.push(`/student-dashboard/list/abhyas/progress-view/${moduleUUID}/${userCode}/${prepredData?.school_code}/${prepredData?.board_uuid}/${prepredData?.medium_uuid}/${prepredData?.standard_uuid}/${prepredData?.subject_uuid}`)
    }

    const handleBack = () => {
        sendMessageToApp({ action: 'back_click' });
    }


    // Renderer callback with condition
    const renderer = ({
        days,
        hours,
        minutes,
        seconds,
        completed,
    }: {
        days: any;
        hours: any;
        minutes: any;
        seconds: any;
        completed: any;
    }) => {
        if (completed) {
            return <div></div>
            // Render a completed state
        } else {
            // Render a countdown
            return (
                <span style={{ fontSize: "1rem", paddingTop: "10px" }}>
                    {hours} Hr - {minutes} Min - {seconds} Sec
                </span>
            );
        }
    };
    const calculateTimeDifference = () => {
        const initialTime = `${"59"}:${"59"}`
        const [initialMinutes, initialSeconds] = initialTime.split(':').map(Number);
        const [finalMinutes, finalSeconds] = completedTime.split(':').map(Number);
        const initialTimeInSeconds = Number(initialMinutes) * 60 + Number(initialSeconds);
        const finalTimeInSeconds = Number(finalMinutes) * 60 + finalSeconds ? Number(finalSeconds) : 0;
        const differenceInSeconds = Number(initialTimeInSeconds * 100) - Number(finalTimeInSeconds * 100);
        if (differenceInSeconds >= 60) {
            var mins = moment.utc(moment(initialTime, "mm:ss").diff(moment(completedTime, "mm:ss"))).format("mm.ss")
            sessionStorage.setItem("setTime", mins === 'Invalid date' ? `${minutes}.${seconds}` : mins.replace('00', '0'));
            return mins === 'Invalid date' ? `${minutes}.${seconds}` : mins.replace('00', '0')
        } else {
            sessionStorage.setItem("setTime", `0.${differenceInSeconds}`);
            return `0.${differenceInSeconds}`;
        }

    };
    const getTimeTaken = (totalSeconds: number) => {
        const minutes = Math.floor(totalSeconds / 60);
        const seconds = totalSeconds % 60;
        let totalTime = `${minutes.toString().padStart(2, '0')}.${seconds.toString().padStart(2, '0')}`
        let finalTime = totalTime?.replace('00', '0')
        sessionStorage.setItem("setTime", `${Number(finalTime)}`);

        return Number(finalTime);
    }
    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>, currentIndex: number) => {
        if (event.key === 'Enter') {
            event.preventDefault(); // Prevent page reload
            const nextIndex = currentIndex + 1;
            if (nextIndex < questionRefs.current.length) {
                questionRefs.current[nextIndex]?.focus();
            }
        }
    };
    // const calculateDifference = () => {
    //     const initialTime = `${minutes}:${seconds}`
    //     const [initialMinutes, initialSeconds] = initialTime.split(':').map(Number);
    //     const [finalMinutes, finalSeconds] = completedTime.split(':').map(Number);
    //     const parsedTime1 = initialMinutes;
    //     const parsedTime2 = finalMinutes;

    //     // Calculate the difference
    //     const diffMinutes = parsedTime1.minutes - parsedTime2.minutes;
    //     const diffSeconds = parsedTime1.seconds - parsedTime2.seconds;

    //     setDifference({
    //         minutes: diffMinutes,
    //         seconds: diffSeconds,
    //     });
    // };
    function onViewQuestion(name: any) {
        // let preElement = `<p class="mb-0">${name}</p>`
        return { __html: name };
    }
    const getDomElements = (htmlString: any, tag: string) => {
        const parsedContent = new DOMParser().parseFromString(htmlString, 'text/html');
        const pElements = parsedContent.querySelectorAll('p');
        const imgElement: any = parsedContent.querySelector('img');
        if (tag === "p") {
            const paragraphs: any[] = [];
            pElements.forEach((pElement) => {
                paragraphs.push(`<p class="mb-0">${pElement.innerHTML}</p>`);
            });
            return <div dangerouslySetInnerHTML={{ __html: paragraphs?.join('') }} />
        }
        if (tag === "img") {
            if (imgElement) {
                return imgElement ? imgElement.getAttribute('src') : ''
            } else {
                return "";
            }

        }
        return "";
    }
    return (
        <div className="row  py-3">
            <div className={`modal fade ${canShowPopup ? 'show' : 'false'}`} style={{ display: (canShowPopup ? "block" : 'none') }} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">You have successfully Submitted worksheet</h5>
                            <button type="button" className="close popup-close cursor-pointer" data-dismiss="modal" aria-label="Close" onClick={handleclose}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary cursor-pointer" onClick={handleclose}>OK</button>
                            <button type="button" className="btn btn-secondary cursor-pointer" data-dismiss="modal" onClick={handleclose}>CANCEL</button>
                        </div>
                    </div>
                </div>
            </div>
            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading && <div>

                {canShowHeader &&
                    <div className='abhyas-fixed-top q_zindex'>
                        <div className='p-2 abhyas-logo'>
                            <i className="nav_logo-icon"></i>
                        </div>
                        <div className='row p-2 time-block-app d-md-flex justify-content-between align-items-end'>
                            {appData?.type === 'TESTS' && <div className='col-6'>
                                <h2 className="m-0"> Test - {appData?.test_no < 9 && <span>0</span>}{appData?.test_no ? appData?.test_no : ''}</h2>
                                <h3 className="m-0"> Time Limit -  {minutes} : {seconds < 9 ? `0${seconds}` : seconds}  mins</h3>
                            </div>}
                            {appData?.type === 'WORKSHEETS' && <div className='col-12'>
                                {appData?.topic_name && <h2 className="m-0"> {appData?.topic_name}</h2>}
                                {appData?.name && <h3 className="m-0"> {appData?.name}</h3>}
                                <h3 className="m-0 fw-normal"> Worksheets </h3>
                                {appData?.worksheets?.map((item: any, i: number) => (
                                    <div key={i} className="circle cursor-pointer" style={{ backgroundColor: getCircleColor(item) }}>
                                        <p>{i + 1}</p>
                                    </div>
                                ))}
                            </div>}
                            {appData?.type !== 'WORKSHEETS' && <div className='col-6'>
                                {/* <p className='mt-3 mb-0 m-md-0 f12'>Remaining Time</p> */}
                                <TimerInfo
                                    minutes={minutes}
                                    seconds={seconds}
                                    startTimer={startTimer}
                                    time_remain={(value: any) => {
                                        if (value === '') {
                                            time_remain_storage = "5";
                                        } else {
                                            time_remain_storage = value;
                                            completedTime = time_remain_storage;
                                        }

                                    }}
                                    timeUp={() => {
                                        if (appData?.type === "TESTS") {
                                            handleCompleteSubmit();
                                        }
                                        timeUp = true;
                                    }} />
                            </div>}

                        </div>
                    </div>}

                <div className='px-3 pb-3 mt-2'>
                    <MathJaxContext>
                        <MathJax>
                            {questionsData?.map((question: any, i: number) => (
                                <div className="" key={i}>
                                    <div className='d-flex'>
                                        <div>
                                            <p className='fw-bold mb-0' style={{ fontSize: '22px' }}>
                                                Q{question?.id}:
                                            </p>
                                        </div>
                                        <div className='ms-3'>
                                            <span className='fw-bold' style={{ fontSize: '22px' }}>
                                                <div className='img_ck' dangerouslySetInnerHTML={onViewQuestion(question.question)}></div>
                                            </span>
                                        </div>
                                    </div>
                                    {/* <div className='row'>
                                        <div className='col-2 col-md-1' style={{ alignSelf: 'center' }}>
                                            <div>
                                                <p className='fw-bold mb-0' style={{ fontSize: '22px' }}>
                                                    Q{question?.id}:
                                                </p>
                                            </div>
                                        </div>
                                        <div className={`${getDomElements(question?.question, "img") ? "col-7 me-2" : "col-8"}`} style={{ paddingTop: '15px' }}>
                                            {getDomElements(question?.question, "p") && <span className='fw-bold' style={{ fontSize: '22px' }}>
                                                <div className='text-break'>
                                                    {getDomElements(question?.question, "p")}
                                                </div>
                                            </span>}
                                            {getDomElements(question?.question, "img") && <div>
                                                <img className="img_mathjx_q" src={getDomElements(question.question, "img")} alt="" />
                                            </div>}
                                        </div>
                                    </div> */}
                                    <form>
                                        {question?.options?.map((options: any, j: number) => (
                                            <div className='row pt-3' key={j}>
                                                <div className='col-2 col-md-1'>
                                                    <span className={`${getDomElements(options.value, "img") && "mt-5 "}`}>
                                                        <input className={`mb-0 cursor-pointer  ${getDomElements(options?.value, "img") && "mt-5 "}`} type="radio" name="option" onChange={(e) => { handleQuestionOptions(options, i, j) }}
                                                            onKeyDown={(e) => handleKeyDown(e, i)}
                                                        // value={question.answer = question?.correct_answer}
                                                        />
                                                    </span>
                                                </div>
                                                <div className={`col-9`}>
                                                    {/* {getDomElements(options?.value, "p") && <span>
                                                        <div className='text-break mb-0'>
                                                            {getDomElements(options?.value, "p")}
                                                        </div>
                                                    </span>}
                                                    {getDomElements(options.value, "img") && <div>
                                                        <img className="w-75" src={getDomElements(options?.value, "img")} alt="" />
                                                    </div>} */}
                                                    <span className='img_ck_otp' dangerouslySetInnerHTML={onViewQuestion(options.value)}></span>
                                                </div>
                                            </div>
                                        ))}
                                    </form>
                                </div>
                            ))}
                        </MathJax>
                    </MathJaxContext>
                </div>
                {questionsData.length > 0 &&
                    <div className="py-3 text-center mt-4 border-top">
                        <button className="btn btn-primary mb-3 px-5 cursor-pointer" onClick={handleCompleteSubmit}>Submit</button>
                        {/* <Link to={`/student-dashboard/list/abhyas/progress-view/${moduleUUID}/${userCode}/${selectUuids?.school_code}/${selectUuids?.board_uuid}/${selectUuids?.medium_uuid}/${selectUuids?.standard_uuid}/${selectUuids?.subject_uuid}`}><button className='btn btn-link mb-3'>Cancel</button></Link> */}
                    </div>
                }
            </div>}
        </div >
    )
}
