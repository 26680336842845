import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { GET_STUDENT_COUNT } from "../../../../app/service/student-count-service";

function StudentscountData() {
    const [studentCountList, setStudentCountList] = useState<any>([]);
    const [loading, setLoading] = useState(false);
    const [totalGirls, setTotalGirls] = useState<number>();
    const [totalBoys, setTotalBoys] = useState<number>();
    const [schoolStudentsCount, setSchoolStudentsCount] = useState(false);
    let { id, schoolCode } = useParams<{ id: string, schoolCode: string }>();
    const schoolsCode = schoolCode;
    const [standardArrow, setStandardArrow] = useState(true);
    const [sortingStandard, setSortingStandard] = useState(true);
    const [divisionArrow, setDivisionArrow] = useState(false);
    const [sortingDivision, setSortingDivision] = useState(true);
    const [divisionIcon, setDivisionIcon] = useState(true);
    const [standardIcon, setStandardIcon] = useState(true);

    useEffect(() => {
        getStudentCount();

    }, []);

    function getStudentCount() {
        setLoading(true);
        GET_STUDENT_COUNT(schoolsCode).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    const data = res.rows;
                    let maleCount = 0;
                    let femaleCount = 0;
                    for (let i = 0; i < data.length; i++) {
                        maleCount += data[i].male;
                        femaleCount += data[i].female;
                    }
                    const strAscending = [...data].sort((a, b) =>
                        a.division > b.division ? 1 : -1,
                    );
                    setStudentCountList(strAscending);
                    setTotalBoys(maleCount);
                    setTotalGirls(femaleCount);
                    setLoading(false);
                }
            }
        });
    }
    const onSortingField = (data: any) => {
        if (sortingStandard) {
            const numAscending = [...data].sort((a, b) => a.standard - b.standard);
            setStudentCountList(numAscending);
            setSortingStandard(false);
        } else {
            const numDescending = [...data].sort((a, b) => b.standard - a.standard);
            setStudentCountList(numDescending);
            setSortingStandard(true);
        }
        setDivisionArrow(false);
        setStandardArrow(true);
        setStandardIcon(!standardIcon)
    }
    const onSortingDivision = (data: any) => {
        setDivisionArrow(true);
        setStandardArrow(false);
        if (sortingDivision) {
            const strAscending = [...data].sort((a, b) =>
                a.division > b.division ? 1 : -1,
            );
            setStudentCountList(strAscending);
            setSortingDivision(false);
        } else {
            const strDescending = [...data].sort((a, b) =>
                a.division > b.division ? -1 : 1,
            );
            setStudentCountList(strDescending);
            setSortingDivision(true);
        }
        setDivisionIcon(!divisionIcon);
    }

    return (
        <div>
            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading &&
                <div className="table-responsive">
                    <table className="table table_new_ui">
                        <thead>
                            <tr>
                                <th style={{ width: "150px" }} onClick={() => onSortingField(studentCountList)}>Standard  {standardArrow && <i className={`${standardIcon ? "bi bi-arrow-down cursor-pointer p-2"
                                    : "bi bi-arrow-up cursor-pointer p-2"}`}>
                                </i>}</th>
                                <th style={{ width: "150px" }} onClick={() => onSortingDivision(studentCountList)}>Division {divisionArrow && <i className={`${divisionIcon ? "bi bi-arrow-down cursor-pointer p-2"
                                    : "bi bi-arrow-up cursor-pointer p-2"}`}>
                                </i>}</th>
                                <th style={{ width: "150px" }}>Girls</th>
                                <th style={{ width: "150px" }}>Boys</th>
                            </tr>
                        </thead>
                        <tbody>
                            {studentCountList?.map((name: any, i: number) => (
                                <tr key={i}>
                                    <td>{name.standard}</td>
                                    <td>{name.division}</td>
                                    <td>{name.female}</td>
                                    <td>{name.male}</td>
                                </tr>
                            ))}
                            {studentCountList?.length > 0 &&
                                <tr>
                                    <td>Total</td>
                                    <td></td>
                                    <td>{totalGirls}</td>
                                    <td>{totalBoys}</td>
                                </tr>}

                        </tbody>
                    </table>
                    {studentCountList.length === 0 && <p className="f14 mt-3 text-center">No Data Available</p>}
                </div>}
        </div>
    )
}

export default StudentscountData;