import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { GET_MLA_DETAILS } from '../../../app/service/mla.service';
import { GET_MNS_DETAILS, GET_MNS_STUDENT_STATUS } from '../../../app/service/mns-service';

export const MLATestLevelStatusPage = () => {
    const [assesmentTestStatus, setAssesmentTestStatus] = useState<any>();
    const [MLADetails, setMLADetails] = useState<any>([]);
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    let { moduleUUID, userCode, schoolCode } = useParams<{ moduleUUID: string, userCode: string, schoolCode: string }>();
    const modules_uuid = moduleUUID;
    const user_code = userCode;
    const school_code = schoolCode;

    useEffect(() => {
        getMLADetails();
    }, []);

    function getMLADetails() {
        setLoading(true);
        GET_MLA_DETAILS().then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res.message);
                setLoading(false);
            } else {
                if (res) {
                    const data = res;
                    if (data.details.length > 0) {
                        setMLADetails(data.details);
                    }
                }
                setLoading(false);
            }
        });
    }


    function onClickEntryTest() {
        history.push(`/dashboard/mla/questions/${modules_uuid}/${user_code}/${school_code}`);
    }

    return (
        <div>
            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading && <section className="mb-4 mt-4 px-3 px-md-0 text-center">
                <div className="bg-white rounded-16 p-3 p-lg-5 container">
                    <div className="row align-items-center">
                        <div className="col-md-12 ps-lg-5">
                            <div className="mb-0">
                                <span className='fw-bold' style={{ fontSize: '40px' }}>MLA-1</span>
                            </div>
                            <div className="mb-0">
                                <span style={{ fontSize: '20px' }}>MLA Tests -1,2,3</span>
                            </div>
                            <div className='pt-4'>
                                <h5 className="m-0">Introduction</h5>
                            </div>
                            <div className='pt-4'>
                                {MLADetails.map((detail: any, i: number) => (
                                    <p key={i}>{detail}</p>
                                ))}
                            </div>
                            <div className='pt-5'>
                                <button className='start-now-btn' onClick={() => { onClickEntryTest() }}>
                                    <span>Begin Entry Test</span>
                                    <span style={{ paddingLeft: '1rem' }}>
                                        <i className="bi bi-arrow-right"></i>
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>}
        </div>
    )
}