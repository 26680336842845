import React, { useEffect, useState } from 'react'
import { Link, NavLink, useHistory, useLocation, useParams } from 'react-router-dom'
import appConfig from '../../../config/constant';
import "react-datepicker/dist/react-datepicker.css";
import NestedIndividualStudent from '../../../routes/insideindividualstudent';
import { toast } from 'react-toastify';
import { GET_INDIVIDUAL_USER_USER_CODE } from '../../../app/service/users.service';
import moment from 'moment';
import { DateComponent } from '../../../components/date';
import { Breadcum } from '../../../components/breadcum';
import NestedModuleList from '../../../routes/insidemodules';

export const ModulesInFo = () => {
    const [isActive, setIsActive] = useState(true);
    const [studentInfo, setStudentInfo] = useState<any>({});
    const [schoolsCode, setSchoolCode] = useState<any>();
    const [studentCode, setStudentCode] = useState<any>();
    const [loading, setLoading] = useState(false);
    const [canShowPopup, setCanShowPopup] = useState(false);
    const [studentLogo, setStudentLogo] = useState<any>();
    const [userRole, setUserRole] = useState<any>();
    const [schoolInfo, setSchoolInfo] = useState<any>({});
    const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'));
    const [accessSurvay, setAccessSurvay] = useState(false);
    const [accesMns, setAccesMns] = useState(false);
    const [accessMlaOne, setAccessMlaOne] = useState(false);
    const [accessMlaTwo, setAccessMlaTwo] = useState(false);
    let { code, id } = useParams<{ code: string, id: string }>();
    const moduleId = Number(id);
    const moduleCode = code;
    const [breadcumInfo, setBreadcumInfo] = useState<any>([
        {
            label: 'Modules',
            value: 'Modules',
            routerLink: '/dashboard/module/list',
            isActive: false
        },
        {
            label: 'Module Name',
            value: moduleCode,
            routerLink: '',
            isActive: true
        }
    ]);

    const location = useLocation();
    const history = useHistory();


    //destructuring pathname from location
    const { pathname } = location;

    //Javascript split method to get the name of the path in array
    const splitLocation = pathname.split("/");

    useEffect(() => {
        const userRole = localStorage.getItem("user_role");
        setUserRole(userRole);
        setBreadcumInfo(breadcumInfo);

        const accessObjectsData: any = localStorage.getItem("access_objects_data");
        onHandleNav(JSON.parse(accessObjectsData));
    }, []);

    function onHandleNav(accessObjectsList: any) {
        const preparedData = accessObjectsList?.filter((el: any) => el.object_code === "MODULES");
        if (preparedData?.length > 0) {
            for (let i = 0; i < preparedData[0].child.length; i++) {
                if (preparedData[0].child[i].object_code === "MODULES-SURVAY-QUESTIONS" && preparedData[0].child[i].p_read === 1) {

                    setAccessSurvay(true);
                }

                if (preparedData[0].child[i].object_code === "MODULES-MNS" && preparedData[0].child[i].p_read === 1) {

                    setAccesMns(true);
                }
                if (preparedData[0].child[i].object_code === "MODULES-MLA-1" && preparedData[0].child[i].p_read === 1) {

                    setAccessMlaOne(true);
                }
                if (preparedData[0].child[i].object_code === "MODULES-MLA-2" && preparedData[0].child[i].p_read === 1) {

                    setAccessMlaTwo(true);
                }

            }
        }
    }
    return (
        <div className="row border-top border-primary py-3">
            <Breadcum breadcumList={breadcumInfo} />
            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading && <div>
                <div className="mt-2">
                    <ul className="nav nav-tabs custom-tabs">
                        <li className="nav-item">
                            <NavLink className={splitLocation[4] == "form" ? "nav-link active" : "nav-link"} to={{ pathname: `/dashboard/module/info/form/${moduleCode}/${moduleId}`, state: { data: "" } }}>Module</NavLink>
                        </li>
                        {(moduleCode === "CAS-PARENT" || moduleCode === "CAS") && <li className="nav-item">
                            {accessSurvay && <NavLink className={splitLocation[4] == "questions" ? "nav-link active" : "nav-link"} to={{ pathname: `/dashboard/module/info/questions/${moduleCode}/${moduleId}`, state: { data: "" } }}>Survey Questions</NavLink>}
                        </li>}
                        {moduleCode === "MATHS" && <li className="nav-item">
                            {accesMns && <NavLink className={splitLocation[4] == "MATHS" ? "nav-link active" : "nav-link"} to={{ pathname: `/dashboard/module/info/MATHS/${moduleCode}/${moduleId}`, state: { data: "" } }}>MNS</NavLink>}
                        </li>}
                        {moduleCode === "MATHS" && <li className="nav-item">
                            {/* <NavLink className={splitLocation[4] == "MLA-1" ? "nav-link active" : "nav-link"} to={{ pathname: `/dashboard/module/info/MLA-1/${moduleCode}/${moduleId}/ability`, state: { data: "" } }}>MLA-1</NavLink> */}
                            {accessMlaOne && <NavLink className={splitLocation[4] == "MLA-1" ? "nav-link active" : "nav-link"} to={{ pathname: `/dashboard/module/info/MLA-1/${moduleCode}/${moduleId}/worksheet`, state: { data: "" } }}>MLA-1</NavLink>}
                        </li>}
                        {moduleCode === "MATHS" && <li className="nav-item">
                            {accessMlaTwo && <NavLink className={splitLocation[4] == "MLA-2" ? "nav-link active" : "nav-link"} to={{ pathname: `/dashboard/module/info/MLA-2/${moduleCode}/${moduleId}/worksheet`, state: { data: "" } }}>MLA-2</NavLink>}
                        </li>}
                        {(moduleCode === "ABHYAAS" || moduleCode === "PARIKSHA") && <li className="nav-item">
                            <NavLink className={(splitLocation[4] == "ABHYAS" && splitLocation[7] !== "worksheet-list") ? "nav-link active" : "nav-link"} to={{ pathname: `/dashboard/module/info/ABHYAS/${moduleCode}/${moduleId}/worksheet`, state: { data: "" } }}>Boards</NavLink>
                        </li>}
                        {moduleCode === "ABHYAAS" && <li className="nav-item">
                            <NavLink className={splitLocation[7] == "worksheet-list" ? "nav-link active" : "nav-link"} to={{ pathname: `/dashboard/module/info/ABHYAS/${moduleCode}/${moduleId}/worksheet-list`, state: { data: "" } }}> Worksheets</NavLink>
                        </li>}
                        {moduleCode === "PARIKSHA" && <li className="nav-item">
                            <NavLink className={splitLocation[7] == "worksheet-list" ? "nav-link active" : "nav-link"} to={{ pathname: `/dashboard/module/info/PARIKSHA/${moduleCode}/${moduleId}/test-info-list`, state: { data: "" } }}> Tests</NavLink>
                        </li>}
                    </ul>
                    <div>
                        <NestedModuleList />
                    </div>
                </div>
            </div>}
        </div>
    )
}

