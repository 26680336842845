import API from "../utility/axios";
const apiUrl = "/api/v2";

export const GET_TOPIC_LIST = (
  pageNumber: number,
  boardUuid: string,
  mediumUuid: string,
  standardUuid: string,
  subjectUuid: string,
  chapterUuid: string,
  data: any
): Promise<any> => {
  return API.get(
    `${apiUrl}/abhyaas/topics?limit=${10}&page=${pageNumber}&board_uuid=${boardUuid}&medium_uuid=${mediumUuid}&standard_uuid=${standardUuid}&subject_uuid=${subjectUuid}&chapter_uuid=${chapterUuid}`,
    {
      params: data,
    }
  );
};
export const GET_TOPIC_BY_UUID = (Uuid: string): Promise<any> => {
  return API.get(`${apiUrl}/abhyaas/topics?uuid=${Uuid}`);
};

export const UPDATE_TOPIC = (data: any, Uuid: string): Promise<any> => {
  return API.post(`${apiUrl}/abhyaas/topics/${Uuid}`, data);
};

export const CREATE_TOPIC = (data: any): Promise<any> => {
  return API.post(`${apiUrl}/abhyaas/topics`, data);
};

export const GET_TOPIC_FILTER = (
  data: any,
  boardUuid: string,
  mediumUuid: string,
  standardUuid: string,
  sujectUuid: string,
  chapterUuid: string
): Promise<any> => {
  return API.get(
    `${apiUrl}/abhyaas/topics?limit=${10}&page=${0}&board_uuid=${boardUuid}&medium_uuid=${mediumUuid}&standard_uuid=${standardUuid}&subject_uuid=${sujectUuid}&chapter_uuid=${chapterUuid}`,
    {
      params: data,
    }
  );
};
export const DELETE_TOPIC = (id: number): Promise<any> => {
  return API.delete(`${apiUrl}/abhyaas/topics/${id}`);
};

export const GET_TOPIC_TEST_LIST = (
  pageNumber: number,

  data: any
): Promise<any> => {
  return API.get(`${apiUrl}/abhyaas/topics?limit=${10}&page=${pageNumber}`, {
    params: data,
  });
};
