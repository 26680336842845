import React, { useEffect, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import { Breadcum } from "../../../../components/breadcum";
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { GET_REGISTERED_STUDENTS, GET_REGISTERED_STUDENTS_PAGE_NUMBER } from '../../../../app/service/school-dashboard.service';
import { GET_SCHOOL_BY_ID } from "../../../../app/service/school.service";
import { returnPaginationRange } from '../../../../app/utility/appUtils';
import { DataTable } from '../../../../components/data-table';
import { InstituteRegisteredStudentsDataGridCols } from './data-grid-cols';

function RegisteredStudentsList() {
    const userRole = localStorage.getItem("user_role");
    const [activePage, setActivePage] = useState<any>();
    const [schoolName, setSchoolName] = useState<any>([]);
    let { id, schoolCode } = useParams<{ id: string, schoolCode: string }>();
    const schoolId = id;
    const schoolsCode = schoolCode;
    const search = useLocation().search;
    const currentPageNumber: any = new URLSearchParams(search).get("page");
    const [pageNumbers, setPageNumbers] = useState<any>([]);
    let totalPage = pageNumbers.length
    const [studentsList, setStudentsList] = useState<any>([]);
    const [loading, setLoading] = useState(false);
    const [breadcumInfo, setBreadcumInfo] = useState<any>([
        {
            label: "Dashboard",
            value: "Dashboard",
            routerLink: "/institute-dashboard/dashboard",
            isActive: false,
        },
        {
            label: "School Name",
            value: "",
            routerLink: "",
            isActive: false,
        },
    ]);
    const history = useHistory();
    const arrayOfPages = returnPaginationRange(pageNumbers.length, activePage, 10, 1)

    useEffect(() => {
        registeredStudentsdetails(schoolsCode);
        for (let i = 0; i < breadcumInfo.length; i++) {
            const element = breadcumInfo[i];
            element.routerLink = "/institute-dashboard/dashboard";
        }

    }, []);



    const registeredStudentsdetails = (data: string) => {
        setLoading(true);
        GET_REGISTERED_STUDENTS(data, 'STUDENT', 0).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    if (res) {
                        const data = res;
                        getBreadcumList(data);
                        setStudentsList(data.rows);
                        const pageNumbers = Math.ceil(res.count / 10);
                        const pageArray: any = [];
                        for (let i = 1; i <= pageNumbers; i++) {
                            pageArray.push(i);
                        }
                        setPageNumbers(pageArray);
                        setActivePage(data);
                        setActivePage(currentPageNumber ? currentPageNumber : 1);
                    }
                    setLoading(false);
                }
            }
        });
    }
    function getBreadcumList(data: any) {
        breadcumInfo.splice(1, breadcumInfo.length - 1, {
            label: "Registered School",
            value: data.name_of_school,
            isActive: true,
        });
        setBreadcumInfo(breadcumInfo);
    }
    function onClickPage(number: number) {
        let dots: any = "..."

        if (activePage !== 1 && number === dots) {
            setActivePage(1);
            onPageChange(1);

        } else if (activePage !== pageNumbers.length && number === dots) {
            setActivePage(totalPage - 1);
            onPageChange(totalPage - 1);
        }
        else {
            setActivePage(number);
            onPageChange(number);
        }
    }
    function onClickPreviousPage() {
        setActivePage(activePage - 1);
        onPageChange(activePage - 1);
    }

    function onClickNextPage() {
        setActivePage(activePage + 1);
        onPageChange(activePage + 1);

    }

    const onPageChange = (data: any) => {

        if (data) {
            if (userRole === "SUPER_ADMIN") {
                history.push(`/dashboard/view/registered-studentslist/${schoolId}/${schoolsCode}?page=${data}`);

            } else {
                history.push(`/institute-dashboard/dashboard/registered-studentslist/${schoolId}/${schoolsCode}?page=${data}`);
            }
        }
        setActivePage(data);
        const pageNumber = data - 1;
        setLoading(true);
        GET_REGISTERED_STUDENTS_PAGE_NUMBER(schoolsCode, 'STUDENT', pageNumber).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    const data = res['rows'];
                    setStudentsList(data);
                    setLoading(false);
                }

            }
        });
    }


    return (
        <div >
            {loading &&
                <div className="text-center p-5 mt-5">
                    <div className="spinner-border mt-5" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>

            }
            {!loading &&
                <div>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb border-bottom ps-0 pt-0 pb-2" style={{ letterSpacing: "0" }}>
                            {userRole === "SUPER_ADMIN" && <li className="breadcrumb-item mt-3"><Link to='/dashboard/view/instituteinfo' style={{ color: '#0d6efd' }}>Dashboard</Link> / <Link to='/dashboard/view/registered-students' style={{ color: '#0d6efd' }}>{schoolId}</Link> / <span>Registered Students</span></li>}
                            {userRole !== "SUPER_ADMIN" && <li className="breadcrumb-item"><Link to='/institute-dashboard/dashboard' style={{ color: '#0d6efd' }}>Dashboard</Link> / <Link to='/institute-dashboard/dashboard/registered-students' style={{ color: '#0d6efd' }}>{schoolId}</Link> / <span>Registered Students</span></li>}
                        </ol>
                    </nav>
                    <DataTable TableCols={InstituteRegisteredStudentsDataGridCols}
                        type={"classic"}
                        tableData={studentsList} pageNumber={onPageChange}
                        activePageNumber={activePage} pageNumbers={pageNumbers}></DataTable>
                </div>}
        </div>

    )

}

export default RegisteredStudentsList;