import React from 'react'
import { Route, Switch } from 'react-router'
import { StudentsForm } from '../pages/dashboard/school/students/form'
import { SchoolmodulelistData } from '../pages/institute-dashboard/manage-school/modules/list'
import { PaymentlistData } from '../pages/institute-dashboard/manage-school/payments/list'
import StudentscountData from '../pages/institute-dashboard/manage-school/students-count/list'
import { StudentslistData } from '../pages/institute-dashboard/manage-school/students/list'
import TeacherlistData from '../pages/institute-dashboard/manage-school/teacher/list'
import { ManagePrincipalForm } from '../pages/institute-dashboard/manage-school/principal/form'
import { TeachersForm } from '../pages/dashboard/school/Teachers/form'


const SchoolinsideRoutings = () => {
    return (
        <Switch>
            <Route path="/institute-dashboard/manage-school/list/info/:id/teacherslist/:schoolCode" component={TeacherlistData}></Route>
            <Route path="/institute-dashboard/manage-school/list/info/:id/studentslist/:schoolCode" component={StudentslistData}></Route>
            <Route exact path="/institute-dashboard/manage-school/list/info/:id/student-count-list/:schoolCode" component={StudentscountData}></Route>
            <Route exact path="/institute-dashboard/manage-school/list/info/:id/modulelist/:schoolCode" component={SchoolmodulelistData}></Route>
            <Route exact path="/institute-dashboard/manage-school/list/info/:id/paymentlist/:schoolCode" component={PaymentlistData}></Route>
            <Route path="/institute-dashboard/manage-school/list/info/:id/studentsform/:schoolCode/:userId" component={StudentsForm}></Route>
            <Route path="/institute-dashboard/manage-school/list/info/:id/teachersform/:schoolCode/:userId" component={TeachersForm}></Route>
        </Switch>
    )
}

export default SchoolinsideRoutings