import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import NestedSponsorReport from "../../../routes/sponsor-report";


export const SponsorReportView = () => {

    const location = useLocation();

    //destructuring pathname from location
    const { pathname } = location;

    //Javascript split method to get the name of the path in array
    const splitLocation = pathname.split("/");
    return (
        <div>
            <div className=" border-top border-primary py-3">
                <div>
                    <h2 className='mt-1 mt-md-0'>Sponsorship Reports</h2>
                </div>
                <div className="mt-5">
                    <ul className="nav nav-tabs custom-tabs scrolling_nav">
                        <li className="nav-item">
                            <NavLink className={"nav-link"} to={{ pathname: `/dashboard/sponsor-report/list`, state: { data: "" } }}>Sponsorship Details Report</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className={"nav-link"} to={{ pathname: `/dashboard/sponsor-report/report`, state: { data: "" } }}>Sponsorship Usage Report</NavLink>
                        </li>
                    </ul>
                </div>
                <div>
                    < NestedSponsorReport />
                </div>
            </div>
        </div>
    )
}