import React, { useEffect, useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom';
import { CREATE_SCHOOL_USER, UPLOAD_LOGO } from '../../../../app/service/school.service';
import { GET_USER_BY_ID, UPDATE_USER } from '../../../../app/service/users.service';
import FormBuilder from '../../../../components/form-builder';
import { FormControlError, FormField, FormValidators } from '../../../../components/form-builder/model/form-field';
import { FormValidator, GetControlIsValid } from '../../../../components/form-builder/validations';
import { ToastContainer, toast } from 'react-toastify';
import moment from 'moment';
import appConfig from '../../../../config/constant';
import errorMessages from '../../../../components/error-messages';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { DateComponent } from '../../../../components/date';

interface Props {
    userId: any;
    schoolsCode: any;
    adminUpdateCancel: () => void;
    setAdminView: any
}

export const ManageAdminForm = (props: Props) => {
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [adminData, setAdminData] = useState<any>({});
    const [adminvalidationErrors, setAdminvalidationErrors] = useState<FormControlError[]>([]);
    const [schoolAdminLogo, setSchoolAdminLogo] = useState<any>();
    const [currentAdminData, setCurrentAdminData] = useState<any>({});
    const [loading, setLoading] = useState(false);
    const [imageLoader, setImageLoader] = useState(false);
    const [mobileNumber, setMobileNumber] = useState(false);
    const [whatsappNumber, setWhatsappNumber] = useState(false);
    const [min, setMin] = useState<any>(moment().subtract(60, "years").format("YYYY-MM-DD"));
    const [max, setMax] = useState<any>(moment().subtract(18, "years").format("YYYY-MM-DD"));
    const [email, setEmail] = useState(false);
    const [canCheckMobileNumber, setCanCheckMobileNumber] = useState(false);
    const [canShowWhatsAppError, setCanShowWhatsAppError] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [nameError, setNameError] = useState('');
    const [postInSchoolError, setPostInSchool] = useState('');
    const [checkedUserMechanismStatus, setCheckedUserMechanismStatus] = useState<any>();
    let { id, schoolCode, userId } = useParams<{ id: string, schoolCode: string, userId: string }>();
    const schoolId = parseInt(id);
    const schoolsCode = props.schoolsCode;
    const usersId: number = props.userId ? props.userId : 0;
    const [isDateError, setIsDateError] = useState<any>({});
    const history = useHistory();
    const schoolAdminFormValidations = [
        new FormField('name', [FormValidators.REQUIRED]),
        new FormField('gender', [FormValidators.REQUIRED]),
        new FormField('date_of_birth', []),
        new FormField('blood_group', []),
        new FormField('city_village', []),
        new FormField('email_id', [FormValidators.REQUIRED]),
        new FormField('mobile_number', [FormValidators.REQUIRED]),
        new FormField('whatsapp_number', []),
        new FormField('post_in', [FormValidators.REQUIRED])
    ];
    const userRole = localStorage.getItem("user_role");
    const [canShowFormPopup, setCanFormPopup] = useState(true);


    useEffect(() => {
        if (usersId > 0) {
            setLoading(true);
            GET_USER_BY_ID(usersId).then((res: any) => {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    setLoading(false);
                    const data = res.rows[0];
                    if (data.date_of_birth) {
                        const date_of_birth: any = new Date(data.date_of_birth);
                        setStartDate(date_of_birth);
                    }
                    if (data.mobile_number === data.whatsapp_number) {
                        data.checked = true;
                    }

                    if (data.access) {
                        setCheckedUserMechanismStatus(data.access);
                    }

                    setSchoolAdminLogo(data.profile_img);
                    setCurrentAdminData(data);
                }
            });
        }
    }, []);

    function createSchoolAdmin() {
        const adminsData = adminData.value ? { ...adminData.value } : { ...currentAdminData };
        setIsFormSubmitted(true);
        const errors: FormControlError[] = FormValidator(schoolAdminFormValidations, adminsData);
        setAdminvalidationErrors(errors);
        adminsData.type = "school";
        adminsData.user_role = "SCHOOL_ADMIN";
        adminsData.institute_school_code = schoolsCode;
        adminsData.profile_img = schoolAdminLogo;
        adminsData.date_of_birth = startDate ? moment(startDate).format('YYYY-MM-DD') : null;
        delete adminsData.sameMobileNumber;
        delete adminsData.file;
        delete adminsData.checked;
        delete adminsData[''];
        delete adminsData.day;
        delete adminsData.month;
        delete adminsData.year;
        Object.keys(adminsData).forEach(function (key) {
            if (typeof adminsData[key] === 'string') {
                adminsData[key] = adminsData[key].trim();
            }
        });
        if (errors.length < 1 && !email && !mobileNumber && onChangeName(adminsData.name) && onChangePostInSchool(adminsData.post_in)) {
            adminsData.whatsapp_number = adminsData.whatsapp_number ? adminsData.whatsapp_number : adminsData.mobile_number;
            CREATE_SCHOOL_USER(adminsData).then((res: any) => {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                    setCurrentAdminData(adminsData);
                } else {
                    toast.success("Admin Created Successfully");
                    props.adminUpdateCancel();
                    setCanFormPopup(!canShowFormPopup);
                    setLoading(false);

                }
            });
        }
        else {

            if (!nameError && !onChangeName(adminsData.name)) {
                setNameError('Please enter name');
            }
            if (!postInSchoolError && !onChangePostInSchool(adminsData.post_in)) {
                setPostInSchool('Please enter post in school');
            }
        }
    }

    function updateSchoolAdmin() {
        const adminsData = adminData.value ? { ...adminData.value } : { ...currentAdminData };
        setIsFormSubmitted(true);
        const errors: FormControlError[] = FormValidator(schoolAdminFormValidations, adminsData);
        setAdminvalidationErrors(errors);
        adminsData.type = "school";
        adminsData.user_role = "SCHOOL_ADMIN";
        adminsData.institute_school_code = schoolsCode;
        adminsData.id = usersId;
        adminsData.profile_img = schoolAdminLogo;
        adminsData.date_of_birth = startDate ? moment(startDate).format('YYYY-MM-DD') : null;
        delete adminsData.sameMobileNumber;
        delete adminsData.file;
        delete adminsData.checked;
        delete adminsData[''];
        delete adminsData.date_of_registration;
        delete adminsData.user_code;
        delete adminsData.address;
        delete adminsData.address2;
        delete adminsData.status;
        delete adminsData.guardian;
        delete adminsData.state;
        delete adminsData.taluka;
        delete adminsData.pin_code;
        delete adminsData.district;
        delete adminsData.standard;
        delete adminsData.division;
        delete adminsData.occupation;
        delete adminsData.active;
        delete adminsData.cas_report_view;
        delete adminsData.indv_school_details;
        delete adminsData.created_at;
        delete adminsData.updated_at;
        delete adminsData.user_name;
        delete adminsData.role_name;
        delete adminsData.short_name;
        delete adminsData.name_of_school;
        delete adminsData.name_of_organization;
        delete adminsData.display_name;
        delete adminsData.day;
        delete adminsData.month;
        delete adminsData.year;
        delete adminsData.access;
        Object.keys(adminsData).forEach(function (key) {
            if (typeof adminsData[key] === 'string') {
                adminsData[key] = adminsData[key].trim();
            }
        });
        adminsData.whatsapp_number = currentAdminData.whatsapp_number;
        if (errors.length < 1 && !email && !mobileNumber && onChangeName(adminsData.name) && onChangePostInSchool(adminsData.post_in)) {
            adminsData.access = checkedUserMechanismStatus;
            UPDATE_USER(adminsData).then((res: any) => {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    toast.success("Admin Updated Successfully");
                    props.adminUpdateCancel()
                    setCanFormPopup(!canShowFormPopup);
                    setLoading(false);

                }
            });
        }
        else {

            if (!nameError && !onChangeName(adminsData.name)) {
                setNameError('Please enter name');
            }
            if (!postInSchoolError && !onChangePostInSchool(adminsData.post_in)) {
                setPostInSchool('Please enter post in school');
            }
        }
    }

    const getAdminInputValid = (control: string) => {
        const value = GetControlIsValid(adminvalidationErrors, control);
        return value;
    }

    const handleSchoolAdminInput = (data: any) => {
        data.value = { ...currentAdminData, ...data.value };
        setAdminData(data);
        const errors: any = FormValidator(schoolAdminFormValidations, data.value);
        setAdminvalidationErrors(errors);
    };

    function handleUploadLogo(e: any, type: string) {
        if (e.target.files && e.target.files[0]) {
            const fileType = e.target.files[0].name.split('.').pop()
            if (fileType == "jpeg" || fileType == "jpg") {
                const formData = new FormData();
                formData.append('file', e.target.files[0], e.target.files[0].name);
                uploadLogo(formData, type);
            } else {
                toast.error("Valid file type .jpg, .jpeg only");
            }
        }
    }

    function uploadLogo(formdata: any, type: string) {
        setImageLoader(true);
        UPLOAD_LOGO(formdata).then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res.message);
                setImageLoader(false);
            } else {
                setSchoolAdminLogo(res.url);
                setImageLoader(false);
            }
        });
    }

    const handleGender = (e: any) => {
        const data = { ...adminData.value };
        data.gender = e.target.value;
        if (currentAdminData) {
            currentAdminData.gender = e.target.value;
        }
    }

    const handleBloodGroupChange = (e: any) => {
        const data = { ...adminData.value };
        data.blood_group = e.target.value;
        if (currentAdminData) {
            currentAdminData.blood_group = e.target.value;
        }
    }


    const handleMobileChange = (e: any) => {
        const data = { ...adminData.value };
        const re = /(6|7|8|9)\d{9}/;

        if ((e.target.value === '' || re.test(e.target.value)) && e.target.value.length === 10) {
            data.mobile_number = e.target.value;
            setMobileNumber(false);
        } else {
            data.mobile_number = e.target.value;
            setMobileNumber(true);
        }

        if (currentAdminData) {
            currentAdminData.mobile_number = e.target.value.replace(/\D+/g, '');
        }
        if (currentAdminData.checked) {
            currentAdminData.whatsapp_number = e.target.value.replace(/\D+/g, '');
        }
    }

    const handleWhatsappChange = (e: any) => {
        const data = { ...adminData.value };
        const re = /(6|7|8|9)\d{9}/;

        if ((e.target.value === '' || re.test(e.target.value)) && e.target.value.length === 10) {
            data.whatsapp_number = e.target.value;
            setWhatsappNumber(false);
            setCanShowWhatsAppError(false);
        } else {
            data.whatsapp_number = e.target.value;
            setWhatsappNumber(true);
        }

        if (currentAdminData) {
            currentAdminData.whatsapp_number = e.target.value.replace(/\D+/g, '');
        }
    }

    const handleEmailChange = (e: any) => {
        const data = { ...adminData.value };
        const re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,6})+$/;

        if (e.target.value === '' || re.test(e.target.value)) {
            data.email_id = e.target.value;
            setEmail(false);
        } else {
            data.email_id = e.target.value;
            setEmail(true);
        }

        if (currentAdminData) {
            currentAdminData.email_id = e.target.value;
        }
    }

    const handleMobileNumberChange = (e: any) => {
        const data = { ...adminData.value };
        if (e.target.checked) {
            if (data.whatsapp_number) {
                data.whatsapp_number = data.mobile_number;
                currentAdminData.whatsapp_number = data.mobile_number;
                setCanCheckMobileNumber(true);
                setCanShowWhatsAppError(false);
            }
            if (data.mobile_number || currentAdminData.mobile_number) {
                setCanCheckMobileNumber(true);
                setCanShowWhatsAppError(false);
                currentAdminData.whatsapp_number = currentAdminData.mobile_number;
                setCurrentAdminData(currentAdminData);
            }
            currentAdminData.checked = true;
        } else {

            setCanCheckMobileNumber(false);
            currentAdminData.checked = false;
        }
    }

    const onChange = (dates: any) => {
        setStartDate(null);
        if (dates.error) {
            setIsDateError(dates);
        } else {
            setStartDate(dates.date);
        }
    };

    function addMonths(theDate: any, years: any) {
        return new Date(theDate.getTime() - years * 24 * 60 * 60 * 1000);
    }

    function subMonths(theDate: any, years: any) {
        return new Date(theDate.getTime() - years * 24 * 60 * 60 * 1000);
    }

    const onChangeName = (event: any) => {
        setNameError('');
        const reg = /^[A-Za-z]+(?:[ _-][A-Za-z]+)*$/;
        if (event) {
            if (!reg.test(event.trim())) {
                setNameError('Enter only alphabets');
            } else {
                return true;
            }
        } else {
            setNameError('Please enter name');
        }
    }
    const onChangePostInSchool = (event: any) => {
        setPostInSchool('');
        const reg = /^[A-Za-z0-9]+(?:[ _-][A-Za-z0-9]+)*$/;
        if (event) {
            if (!reg.test(event.trim())) {
                setPostInSchool('Post in school can`t contain spaces and special characters');
            } else {
                return true;
            }
        } else {
            setPostInSchool('Please enter post in school');
        }
    }

    const handleUserMechanism = (e: any) => {
        if (currentAdminData) {
            const status = e.target.checked === true ? "ENABLED" : "DISABLED";
            currentAdminData.access = status;
            setCheckedUserMechanismStatus(status);
        }
    }
    const handleFormClose = () => {
        setCanFormPopup(!canShowFormPopup);
        props.setAdminView(false);
    };
    return (
        <div>
            <div
                className={`modal fade ${canShowFormPopup ? "show" : "false"}`}
                style={{ display: canShowFormPopup ? "block" : "none" }}
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog parent-model-dialog" role="document">
                    <div className="modal-content parent-model">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                                School Admin
                            </h5>
                            <button
                                type="button"
                                className="close popup-close cursor-pointer"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={handleFormClose}
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body py-2 mx-2 parent-model">
                            {loading &&
                                <div className="text-center p-5">
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div>
                            }
                            {!loading && <div>
                                <FormBuilder onUpdate={handleSchoolAdminInput}>
                                    <form>
                                        <div style={{ borderBottom: 'solid 1px black', marginTop: '15px', paddingLeft: '10px' }} className="mb-4">
                                            <h5 className="form-label mb-2 d-block">Personal Details</h5>
                                        </div>
                                        <div className="row custom-form">
                                            <div className="col-md-4">
                                                <div className="mb-4">
                                                    <label className="f12 text-mgray mb-0">Mrs./ Mr
                                                        <span style={{ paddingLeft: '5px' }}>Name</span></label>
                                                    <span style={{ color: 'red', fontSize: '17px', paddingLeft: '5px' }}>*</span>
                                                    <input className="form-control form-control-lg f18" type="text" name="name" onChange={(e) => onChangeName(e.target.value)} defaultValue={currentAdminData.name} placeholder="Please enter name" />
                                                    {nameError && <p className="text-danger">{nameError}</p>}

                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="mb-4">
                                                    <label className="f12 text-mgray mb-4">Gender</label>
                                                    <span style={{ color: 'red', fontSize: '17px', paddingLeft: '10px' }}>*</span>
                                                    <br />
                                                    <input className="mb-0" type="radio" value="male" name="gender" checked={currentAdminData?.gender === "male"} onChange={(e) => { handleGender(e) }} />
                                                    <span style={{ paddingRight: '15px', paddingLeft: '15px' }} className='f18'>Male</span>
                                                    &nbsp;
                                                    <input className="mb-0" type="radio" value="female" name="gender" checked={currentAdminData?.gender === "female"} onChange={(e) => { handleGender(e) }} />
                                                    <span style={{ paddingRight: '15px', paddingLeft: '15px' }} className='f18'>Female</span>

                                                    <input className="mb-0" type="radio" value="transgender" name="gender" checked={currentAdminData?.gender === "transgender"} onChange={(e) => { handleGender(e) }} />
                                                    <span style={{ paddingRight: '15px', paddingLeft: '15px' }} className='f18'>Other</span>

                                                    {isFormSubmitted && !getAdminInputValid('gender') && <p className="text-danger">Please select gender</p>}
                                                </div>
                                            </div>

                                            <div className="col-md-4">
                                                <div className="mb-4">
                                                    <label className="f12 text-mgray mb-0">Date of Birth</label>
                                                    <span style={{ color: 'red', fontSize: '17px', paddingLeft: '10px' }}>*</span>
                                                    {currentAdminData.date_of_birth &&
                                                        <DateComponent onDate={onChange} yearDiff={18} defaultData={currentAdminData.date_of_birth}></DateComponent>}
                                                    {!currentAdminData.date_of_birth && <DateComponent onDate={onChange} yearDiff={18}></DateComponent>}
                                                    {isFormSubmitted && !startDate && !isDateError?.error && <p className="text-danger">Please select date</p>}
                                                    {isDateError?.error && !startDate && <p className="text-danger">{isDateError?.error}</p>}

                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="mb-4">
                                                    <label className="f12 text-mgray mb-0">Blood Group</label>
                                                    <select name="blood_group f18" value={currentAdminData.blood_group} onChange={(e) => { handleBloodGroupChange(e) }} className="form-select form-select-lg mb-3">
                                                        <option value="">Select blood group</option>
                                                        <option value="O+">O+</option>
                                                        <option value="O-">O-</option>
                                                        <option value="A-">A-</option>
                                                        <option value="A+">A+</option>
                                                        <option value="B-">B-</option>
                                                        <option value="B+">B+</option>
                                                        <option value="AB-">AB-</option>
                                                        <option value="AB+">AB+</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="mb-4">
                                                    <div className="file btn btn-sm btn-primary px-4 rounded-12 mt-2 d-inline-block">Upload Photo
                                                        <input type="file" accept="image/*" onChange={(e) => handleUploadLogo(e, "schooladmin")} />
                                                    </div>
                                                </div>
                                            </div>
                                            {imageLoader &&
                                                <div className="text-center col-md-1 p-5">
                                                    <div className="spinner-border" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </div>
                                                </div>
                                            }
                                            {schoolAdminLogo && <div className="col-md-4">
                                                <div className="mb-4">
                                                    <label className="f12 text-mgray mb-0 d-block">Preview Photo</label>
                                                    <img src={`${appConfig.FILE_UPLOAD_URL}${schoolAdminLogo.charAt(0) === "/" ? schoolAdminLogo.substring(1) : schoolAdminLogo}`} style={{ height: '100px', width: '100px', objectFit: 'contain' }} />
                                                </div>
                                            </div>}

                                            {usersId > 0 && <div className="col-md-4">
                                                <div className="mb-4">
                                                    <label className="f12 text-mgray">User Access</label><br />
                                                    <label className="switch">
                                                        <input type="checkbox" name="access" value={currentAdminData?.access} checked={currentAdminData?.access === "ENABLED"} onChange={(e) => { handleUserMechanism(e) }} />
                                                        <span className="slider round"></span>
                                                    </label>
                                                </div>
                                            </div>}

                                            <div style={{ borderBottom: 'solid 1px black' }} className="mb-4">
                                                <h5 className="form-label mb-2 d-block f18">Contact Details</h5>
                                            </div>

                                            <div className="col-md-4">
                                                <div className="mb-4">
                                                    <label className="f12 text-mgray mb-0">Email</label>
                                                    <span style={{ color: 'red', fontSize: '17px', paddingLeft: '10px' }}>*</span>
                                                    <input className="form-control form-control-lg f18" type="text" name="email_id" onChange={(e) => { handleEmailChange(e) }} value={currentAdminData.email_id} placeholder="Please enter email" />
                                                    {email && <p className="text-danger">{errorMessages.email}</p>}
                                                    {isFormSubmitted && !getAdminInputValid('email_id') && <p className="text-danger">Please enter email</p>}
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="mb-4">
                                                    <label className="f12 text-mgray mb-0">Mobile Number</label>
                                                    <span style={{ color: 'red', fontSize: '17px', paddingLeft: '10px' }}>*</span>
                                                    <input className="form-control form-control-lg f18" type="text" name="mobile_number" maxLength={10} onChange={(e) => { handleMobileChange(e) }} value={currentAdminData.mobile_number} placeholder="Please enter mobile number" />
                                                    {mobileNumber && <p className="text-danger">{errorMessages.mobile_number}</p>}
                                                    {isFormSubmitted && !getAdminInputValid('mobile_number') && <p className="text-danger">Please enter mobile number</p>}
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="mb-4">
                                                    <label className="f12 text-mgray mb-0 me-2">WhatsApp Number</label>
                                                    (  <input type="checkbox" name="sameMobileNumber" checked={currentAdminData.checked} onChange={(e) => { handleMobileNumberChange(e) }} style={{ paddingLeft: '10px' }} />
                                                    <span style={{ paddingLeft: '10px' }} className='f12 text-mgray'>Same as Mobile Number</span> )
                                                    <input className="form-control form-control-lg" disabled={currentAdminData.checked} type="text" name="whatsapp_number" maxLength={10} onChange={(e) => { handleWhatsappChange(e) }} value={currentAdminData.whatsapp_number} placeholder="Please enter whatsapp number" />
                                                    {(whatsappNumber) && <p className="text-danger">{errorMessages.whatsapp_number}</p>}
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="mb-4">
                                                    <label className="f12 text-mgray mb-0">City/Village (Optional)</label>
                                                    <input className="form-control form-control-lg" type="text" name="city_village" defaultValue={currentAdminData.city_village} placeholder="Please enter city" />
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="mb-4">
                                                    <label className="f12 text-mgray mb-0">Post In School</label>
                                                    <span style={{ color: 'red', fontSize: '17px', paddingLeft: '10px' }}>*</span>
                                                    <input className="form-control form-control-lg" type="text" name="post_in" onChange={(e) => onChangePostInSchool(e.target.value)} defaultValue={currentAdminData.post_in} placeholder="Please enter post in school" />
                                                    {postInSchoolError && <p className="text-danger">{postInSchoolError}</p>}
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </FormBuilder>
                            </div>}
                        </div>
                        <div className="modal-footer">
                            {usersId === 0 && <a className="btn btn-primary btn-sm px-5 rounded-12 cursor-pointer" onClick={() => createSchoolAdmin()}>Create</a>}
                            {usersId > 0 && <a className="btn btn-primary btn-sm px-5 rounded-12 cursor-pointer" onClick={() => updateSchoolAdmin()}>Update</a>}
                            <span className='mb-0 mx-3 cursor-pointer' onClick={handleFormClose}>Cancel</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}