import React from 'react';
import InstituteRoutes from './routes';

const InstitutePage = (props: any) => {
    
    return (
        <div>
            <InstituteRoutes {...props} />
        </div>
    );
};

export default InstitutePage;