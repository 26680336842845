import React, { useEffect, useState } from 'react'
import { Link, NavLink, useHistory, useLocation, useParams } from 'react-router-dom'
import appConfig from '../../../config/constant';
import "react-datepicker/dist/react-datepicker.css";
import NestedIndividualStudent from '../../../routes/insideindividualstudent';
import { toast } from 'react-toastify';
import { GET_INDIVIDUAL_USER_USER_CODE } from '../../../app/service/users.service';
import moment from 'moment';
import { DateComponent } from '../../../components/date';
import { Breadcum } from '../../../components/breadcum';

export const IndividualStudentInFo = () => {
    const [studentInfo, setStudentInfo] = useState<any>({});
    const [loading, setLoading] = useState(false);
    const [canShowPopup, setCanShowPopup] = useState(false);
    const [studentLogo, setStudentLogo] = useState<any>();
    const [userRole, setUserRole] = useState<any>();
    const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'));
    const [breadcumInfo, setBreadcumInfo] = useState<any>([
        {
            label: 'Individual Students',
            value: 'Individual Students',
            routerLink: '/dashboard/individual-student/list',
            isActive: false
        },
        {
            label: 'Student Name',
            value: '',
            routerLink: '',
            isActive: false
        }
    ]);

    let { id, userCode, schoolName } = useParams<{ id: string, userCode: string, schoolName: string }>();
    const studentId = Number(id);
    const usersCode = userCode;
    const schoolsName = schoolName;
    const location = useLocation();
    const history = useHistory();
    const [studentsParents, setStudentsParents] = useState(false);
    const [studentsModules, setStudentsModuless] = useState(false);
    const [viewMoreInfo, setViewMoreInfo] = useState(false);
    const { pathname } = location;

    //Javascript split method to get the name of the path in array
    const splitLocation = pathname.split("/");

    useEffect(() => {
        const userRole = localStorage.getItem("user_role");
        setUserRole(userRole);
        setLoading(true);
        if (studentId > 0) {
            GET_INDIVIDUAL_USER_USER_CODE(usersCode).then((response: any) => {
                if (response.status === 'fail') {
                    toast.error(response.message);
                    setLoading(false);
                } else {
                    const data = response.rows[0];
                    setLoading(false);
                    if (data.date_of_birth) {
                        const date_of_birth: any = moment(data?.date_of_birth, 'dd/MMM/YYYY').toDate();
                        setStartDate(date_of_birth);
                    }
                    setStudentInfo(data);
                    getBreadcumList(data)
                    setStudentLogo(data.profile_img);
                }
            });
        }
        const accessObjectsData: any = localStorage.getItem("access_objects_data");
        onHandleNav(JSON.parse(accessObjectsData));
    }, []);

    function getBreadcumList(data: any) {
        breadcumInfo.splice(1, breadcumInfo.length - 1, {
            label: 'Student Name',
            value: data.name,
            isActive: true
        });
        setBreadcumInfo(breadcumInfo);
    }

    function onClickViewDetail() {
        setCanShowPopup(true);
    }

    const handleclose = () => {
        setCanShowPopup(false);
    }

    const handleBack = () => {
        history.push('/dashboard/individual-student/list');
    }
    function onHandleNav(accessObjectsList: any) {
        const preparedData = accessObjectsList?.filter((el: any) => el.object_code === "INDIVIDUAL");
        if (preparedData?.length > 0) {
            for (let i = 0; i < preparedData[0].child.length; i++) {
                if (preparedData[0].child[i].object_code === "INDIVIDUAL-STUDENTS-PARENTS" && preparedData[0].child[i].p_read === 1) {

                    setStudentsParents(true);
                }

                if (preparedData[0].child[i].object_code === "INDIVIDUAL-STUDENTS-MODULES" && preparedData[0].child[i].p_read === 1) {

                    setStudentsModuless(true);
                }
                if (preparedData[0].child[i].object_code === "INDIVIDUAL-STUDENTS-VIEW-MORE-INFORMATION" && preparedData[0].child[i].p_read === 1) {

                    setViewMoreInfo(true);
                }


            }
        }
    }
    return (
        <div className="row border-top border-primary py-3">
            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }

            <div className={`modal fade ${canShowPopup ? 'show' : 'false'}`} style={{ display: (canShowPopup ? "block" : 'none') }} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog parent-model-dialog" role="document">
                    <div className="modal-content parent-model">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Student  Details</h5>
                            <button type="button" className="close popup-close cursor-pointer" data-dismiss="modal" aria-label="Close" onClick={handleclose}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body parent-info">
                            <div className="row custom-form">
                                <div className="col-md-4">
                                    <div className="mb-4">
                                        <label className="form-label mb-0">Name</label>
                                        <input className="form-control form-control-lg" type="text" disabled name="name" defaultValue={studentInfo.name} />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="mb-4">
                                        <label className="form-label mb-2">Date of Birth</label>
                                        {studentInfo.date_of_birth &&
                                            <DateComponent yearDiff={0} idDisable={true} defaultData={studentInfo.date_of_birth}></DateComponent>}
                                        {!studentInfo.date_of_birth && <DateComponent idDisable={true} defaultData={startDate} yearDiff={0}></DateComponent>}

                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="mb-4">
                                        <label className="form-label mb-4">Gender</label><br />
                                        <input type="radio" name="gender" checked={studentInfo?.gender === "male"} disabled />
                                        <span style={{ margin: '0px 12px' }}>Male</span>
                                        <input type="radio" name="gender" checked={studentInfo?.gender === "female"} disabled />
                                        <span style={{ margin: '0px 12px' }}>Female</span>
                                        <input className="mb-0" type="radio" value="transgender" name="gender" checked={studentInfo?.gender === "transgender"} disabled />
                                        <span style={{ paddingRight: '15px', paddingLeft: '15px' }}>Other</span>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="mb-4">
                                        <label className="form-label mb-0">Standard</label>
                                        <select name="state" className="form-select form-select-lg mb-3" disabled>
                                            <option value="">{studentInfo?.standard}</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="mb-4">
                                        <label className="form-label mb-0">Division</label>
                                        <select name="state" className="form-select form-select-lg mb-3" disabled>
                                            <option value="">{studentInfo?.division}</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="mb-4">
                                        <label className="form-label mb-0">Email ID</label>
                                        <input className="form-control form-control-lg" type="text" disabled name="email_id" defaultValue={studentInfo.email_id} />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="mb-4">
                                        <label className="form-label mb-0">Mobile Number</label>
                                        <input className="form-control form-control-lg" type="number" disabled name="mobile_number" defaultValue={studentInfo.mobile_number} />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="mb-4">
                                        <label className="form-label mb-0">WhatsApp Number</label>
                                        <input className="form-control form-control-lg" type="number" disabled name="whatsapp_number" defaultValue={studentInfo.whatsapp_number} />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="mb-4">
                                        <label className="form-label mb-0">Blood Group</label>
                                        <input className="form-control form-control-lg" type="text" disabled name="blood_group" defaultValue={studentInfo.blood_group} />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="mb-4">
                                        <label className="form-label mb-0">Address</label>
                                        <input className="form-control form-control-lg" type="textarea" disabled defaultValue={studentInfo?.address} name="address" />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="mb-4">
                                        <label className="form-label mb-0">State</label>
                                        <select name="state" className="form-select form-select-lg mb-3" disabled>
                                            <option value="">{studentInfo?.state}</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="mb-4">
                                        <label className="form-label mb-0">District</label>
                                        <select name="district" className="form-select form-select-lg mb-3" disabled>
                                            <option value="">{studentInfo?.district}</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="mb-4">
                                        <label className="form-label mb-0">Block</label>
                                        <input className="form-control form-control-lg" type="text" disabled defaultValue={studentInfo?.taluka} name="taluka" />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="mb-4">
                                        <label className="form-label mb-0">City/Village (Optional)</label>
                                        <input className="form-control form-control-lg" type="text" disabled name="city_village" defaultValue={studentInfo?.city_village} />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="mb-4">
                                        <label className="form-label mb-0">PIN Code</label>
                                        <input className="form-control form-control-lg" type="number" disabled name="pin_code" defaultValue={studentInfo?.pin_code} />
                                    </div>
                                </div>
                                {studentLogo && <div className="col-md-4">
                                    <div className="mb-4">
                                        <label className="form-label mb-0 d-block">Preview Photo</label>
                                        <img src={`${appConfig.FILE_UPLOAD_URL}${studentLogo.charAt(0) === "/" ? studentLogo.substring(1) : studentLogo}`} style={{ height: '100px', width: '100px', objectFit: 'contain' }} />
                                    </div>
                                </div>}
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary cursor-pointer" data-dismiss="modal" onClick={handleclose}>Close</button>
                        </div>
                    </div>
                </div>
            </div>
            {!loading && <div>
                <div>
                    {(userRole !== "STUDENT" && userRole !== "PARENT") && <Breadcum breadcumList={breadcumInfo} />}
                    <div className="row">
                        <div className="col-md-10">
                            {userRole === "STUDENT" && <h2>Student Details</h2>}
                        </div>
                    </div>
                    <div className="mar-t-20 custom-scroll table-responsive">
                        <table className="table fixed-table-header mb-0">
                            <thead>
                                <tr>
                                    <th>Student Name</th>
                                    <th>Mobile Number</th>
                                    <th>Email ID</th>
                                    <th>City</th>
                                    <th>State</th>
                                    {viewMoreInfo && <th>Action</th>}
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{studentInfo.name}</td>
                                    <td>{studentInfo.mobile_number}</td>
                                    <td>{studentInfo.email_id}</td>
                                    <td>{studentInfo.taluka}</td>
                                    <td>{studentInfo.state}</td>
                                    {viewMoreInfo && <td>
                                        <button className="file btn btn-sm btn-primary px-4 rounded-12 d-inline-block cursor-pointer" onClick={() => onClickViewDetail()}>View More Info</button>
                                    </td>}
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="mt-5">
                    <ul className="nav nav-tabs custom-tabs">
                        <li className="nav-item">
                            {studentsParents && <NavLink className={splitLocation[5] == "parentform" ? "nav-link active" : "nav-link"} to={{ pathname: `/dashboard/individual-student/info/${studentId}/parentlist/${usersCode}/${schoolsName}`, state: { data: "" } }}>Parent's</NavLink>}
                        </li>
                        <li className="nav-item">
                            {studentsModules && <NavLink className={splitLocation[5] == "modulelist" ? "nav-link active" : "nav-link"} to={{ pathname: `/dashboard/individual-student/info/${studentId}/modulelist/${usersCode}/${schoolsName}`, state: { data: "" } }}>Modules</NavLink>}
                        </li>
                    </ul>
                    <div>
                        <NestedIndividualStudent />
                    </div>
                </div>
            </div>}
        </div>
    )
}

