import React, { useEffect, useState } from 'react'
import EYEICONS from '../../../../assets/images/eye-icons.svg'
import { ToastContainer, toast } from 'react-toastify';
import { GET_SCHOOL_DASHBOARD } from '../../../../app/service/school-dashboard.service';
import { Link, useHistory, useParams } from 'react-router-dom';
import { MathsClassWiseMnsAnalysis } from './classwise-mns';
import { MathsClassWiseMlaOneAnalysis } from './classwise-mla1';
import { MathsClassWiseMlaTwoAnalysis } from './classwise-mla2';

function MathsClassWiseAnalysys() {
    const [activeTab, setActiveTab] = React.useState(1);
    const [loading, setLoading] = useState(false);
    const [schoolData, setSchoolData] = useState<any>([]);
    const [classWise, setClassWise] = useState<any>([]);
    let { className } = useParams<{ className: string }>();
    const userRole = localStorage.getItem("user_role");
    const [sectionWise, setSectionWise] = useState<any>([]);

    useEffect(() => {
        schoolDashboardDetails();


    }, []);
    const schoolDashboardDetails = () => {

        const data: any = localStorage.getItem("institute_code");
        setLoading(true);
        GET_SCHOOL_DASHBOARD(data).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    if (res) {
                        const data = res;
                        setSchoolData(data);
                        let selectedClassData = data.maths_data.class_wise_data.filter((ele: any) => ele.class == className)
                        let preparedData = data.maths_data.section_wise_data.filter((ele: any) => ele.class == className);
                        setSectionWise(preparedData);
                        setClassWise(selectedClassData);

                    }
                    setLoading(false);
                }
            }
        });
    }

    return (
        <>
            {loading &&
                <div className="text-center p-5 mt-5">
                    <div className="spinner-border mt-5" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading && <div>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb border-bottom pt-0 ps-0 pb-2 ">
                        {userRole === "SUPER_ADMIN" && <li className="breadcrumb-item mt-3"><Link to='/dashboard/view/schoolinfo' style={{ color: '#0d6efd' }}>Dashboard</Link> / <span>{className}<sup>th</sup> Class</span></li>}
                        {userRole !== "SUPER_ADMIN" && <li className="breadcrumb-item"> <Link to='/school-dashboard/view' style={{ color: '#0d6efd' }}>Dashboard</Link> / <span>{className}<sup>th</sup> Class</span></li>}
                    </ol>
                </nav>

                <h3 className="mt-4 mb-0 section-hd">Maths Initiatives - Attempted <small>Class Wise/Section wise</small></h3>
                {classWise?.length > 0 ?
                    <div className="table-responsive">
                        <table className="table table_new_ui">
                            <thead>
                                <tr>
                                    <th style={{ width: "150px" }} >Gender</th>
                                    {sectionWise?.map((data: any, index: number) => {
                                        return (
                                            <>
                                                <th style={{ width: "150px" }} key={index}>{data.section} Section</th>

                                            </>
                                        )
                                    })}
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Boys</td>

                                    {sectionWise?.map((data: any, index: number) => {
                                        return (
                                            <>
                                                <td key={index}>{data.attempted_boys}</td>
                                            </>
                                        )
                                    })}
                                </tr>
                                <tr>
                                    <td>Girls</td>

                                    {sectionWise?.map((data: any, index: number) => {
                                        return (
                                            <>
                                                <td key={index}>{data.attempted_girls}</td>
                                            </>
                                        )
                                    })}
                                </tr>
                                <tr>
                                    <td>Total</td>

                                    {sectionWise?.map((data: any, index: number) => {
                                        return (
                                            <>
                                                <td key={index}>{data.attempted_students}</td>
                                            </>
                                        )
                                    })}
                                </tr>
                            </tbody>
                        </table>
                    </div> : <div className="ms-2 mb-3 f14">No Data Available</div>}
                <h3 className="mt-4 mb-0 section-hd">Section wise Number of Regd / attempted</h3>
                <p className='text-gray f12 mb-2'>Students</p>
                {schoolData?.maths_data && (schoolData?.maths_data.class_wise_data.length > 0) ?
                    <div className="table-responsive">
                        <table className="table table_new_ui">
                            <thead>
                                <tr>
                                    <th style={{ width: "200px" }}></th>
                                    {sectionWise?.map((data: any, index: number) => {
                                        return (
                                            <>
                                                <th style={{ width: "150px" }} key={index}>{data.section} Section</th>

                                            </>
                                        )
                                    })}

                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Registered Students</td>
                                    {sectionWise?.map((data: any, index: number) => {
                                        return (
                                            <>
                                                <td key={index}>{data.registered_students}</td>
                                            </>

                                        )
                                    })}
                                </tr>
                                <tr>
                                    <td>Attempted Students</td>
                                    {sectionWise?.map((data: any, index: number) => {
                                        return (
                                            <>
                                                <td key={index}>{data.attempted_students}</td>
                                            </>
                                        )
                                    })}
                                </tr>
                            </tbody>
                        </table>
                    </div> : <div className="ms-2 mb-3 f14">No Data Available</div>}
                <ul className="nav nav-tabsnewui custom-tabsnewui mt-4" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation">
                        <button className="nav-link active" id="mns-tab" data-bs-toggle="tab" data-bs-target="#mns" type="button" role="tab" aria-controls="mns" aria-selected="false">MNS</button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className="nav-link " id="mla1-tab" data-bs-toggle="tab" data-bs-target="#mla1" type="button" role="tab" aria-controls="mla1" aria-selected="true">MLA 1</button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className="nav-link" id="mla2-tab" data-bs-toggle="tab" data-bs-target="#mla2" type="button" role="tab" aria-controls="mla2" aria-selected="false">MLA 2</button>
                    </li>
                </ul>
                <div className="tab-content pt-3" id="myTabContent">
                    <div className="tab-pane fade show active" id="mns" >
                        <MathsClassWiseMnsAnalysis classWise={classWise} sectionWise={sectionWise} />
                    </div>
                    <div className="tab-pane fade " id="mla1">
                        <MathsClassWiseMlaOneAnalysis classWise={classWise} sectionWise={sectionWise} />
                    </div>
                    <div className="tab-pane fade" id="mla2" >
                        <MathsClassWiseMlaTwoAnalysis classWise={classWise} sectionWise={sectionWise} />
                    </div>
                </div>
            </div>}
        </>
    )
}
export default MathsClassWiseAnalysys