import { DataTableCol } from "../../../../components/data-table/types";

export const ModuleDataGridCols: DataTableCol[] = [
    {
        title: 'Module Code',
        control: 'module_code',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Sub Module',
        control: 'sub_module',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Target Standard',
        control: 'target_std',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Version',
        control: 'module_ver',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Amount',
        control: 'amount',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Date',
        control: 'created_at',
        sortable: true,
        canShowColumn: true
    },
    
    {
        title: 'Actions',
        control: 'school_modules',
        sortable: false,
        isEdit: false,
        isDelete: false,
        canShowColumn: false
    }
];
