import API from "../utility/axios";
// const apiUrl = '/api/v1';
const apiUrl = "/api/v2";
export const GET_ROLES = (): Promise<any> => {
  return API.get(`${apiUrl}/roles`);
};

export const GET_ROLES_PAGE_NUMBER = (pageNumber: number): Promise<any> => {
  return API.get(`${apiUrl}/roles?limit=${10}&page=${pageNumber}`);
};

export const CREATE_ROLE = (data: any): Promise<any> => {
  return API.post(`${apiUrl}/roles`, data);
};

export const UPDATE_ROLE = (data: any): Promise<any> => {
  return API.post(`${apiUrl}/roles`, data);
};

export const GET_ROLE_BY_ID = (id: number): Promise<any> => {
  return API.get(`${apiUrl}/roles?id=${id}`);
};

export const DELETE_ROLE = (id: number): Promise<any> => {
  return API.delete(`${apiUrl}/roles/${id}`);
};
