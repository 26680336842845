import { DataTableCol } from "../../../components/data-table/types";

export const SchoolDataGridCols: DataTableCol[] = [
  {
    title: "Name",
    control: "school_name",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "Contact Number",
    control: "contact_number",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "Official Email",
    control: "email_id",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "UDISE Code",
    control: "udise_code",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "Complete Count",
    control: "complete_count",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "Pending Count",
    control: "pending_count",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "Action",
    control: "cas_report-complete_count",
    isEdit: false,
    isDelete: false,
    canShowColumn: true,
  },
];
