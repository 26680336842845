import API from "../utility/axios";
const apiUrl = "/api/v2";

export const GET_SUBJECT_LIST = (
  pageNumber: number,
  boardUuid: string,
  mediumUuid: string,
  standardUuid: string,
  data: any
): Promise<any> => {
  return API.get(
    `${apiUrl}/abhyaas/subjects?limit=${10}&page=${pageNumber}&board_uuid=${boardUuid}&medium_uuid=${mediumUuid}&standard_uuid=${standardUuid}`,
    {
      params: data,
    }
  );
};
export const GET_SUBJECT_BY_UUID = (Uuid: string): Promise<any> => {
  return API.get(`${apiUrl}/abhyaas/subjects?uuid=${Uuid}`);
};

export const UPDATE_SUBJECT = (data: any, Uuid: string): Promise<any> => {
  return API.post(`${apiUrl}/abhyaas/subjects/${Uuid}`, data);
};

export const CREATE_SUBJECT = (data: any): Promise<any> => {
  return API.post(`${apiUrl}/abhyaas/subjects`, data);
};

export const GET_SUBJECT_FILTER = (
  data: any,
  boardUuid: string,
  mediumUuid: string,
  standardUuid: string
): Promise<any> => {
  return API.get(
    `${apiUrl}/abhyaas/subjects?limit=${10}&page=${0}&board_uuid=${boardUuid}&medium_uuid=${mediumUuid}&standard_uuid=${standardUuid}`,
    {
      params: data,
    }
  );
};
export const DELETE_SUBJECT = (id: number): Promise<any> => {
  return API.delete(`${apiUrl}/abhyaas/subjects/${id}`);
};
export const GET_SUBJECT_LIST_BY_STANDARD = (data: any): Promise<any> => {
  return API.get(`${apiUrl}/abhyaas/subjects`, {
    params: data,
  });
};
