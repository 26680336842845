import React, { useEffect } from "react";
import axios from 'axios';
import { useHistory } from "react-router-dom";


export const Ccvenuetest = () => {
    const history = useHistory();
    const splitLocation: any = window.location.href.split("?");
    const previousURL = localStorage.getItem("payment_url");


    useEffect(() => {

        const queryString = splitLocation[1]; // Replace this with your actual query string
        if (queryString) {
            // Use a regular expression to extract the value of the 'status' parameter
            const match = queryString.match(/status=([^&]*)/);
            if (match) {
                const statusValue = match[1];
                if (statusValue === "success") {
                    const newURL = `${previousURL}?status=success`;
                    history.push(newURL);
                }
                else {
                    history.goBack();
                }
            } else {
            }
        }

    }, []);

    return (
        <div>
            ccvenue
        </div>
    )
}