import { DataTableCol } from "../../../../../components/data-table/types";

export const ModuleDataGridCols: DataTableCol[] = [
  {
    title: "Module Name",
    control: "module_code",
    canShowColumn: true,
  },
  {
    title: "Sub Module",
    control: "sub_module",
    canShowColumn: true,
  },
  {
    title: "Version",
    control: "module_ver",
    canShowColumn: true,
  },
  {
    title: "Status",
    control: "module_status",
    canShowColumn: true,
  },

  {
    title: "Reports Info",
    control: "module_status",
    canShowColumn: true,
  },
  {
    title: "Payment Status",
    control: "amount_status",
    canShowColumn: true,
  },
];
