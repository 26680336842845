import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { GET_COUPON_CODES_REPORT_BY_PROMOTION_UUID_PAGE_NUMBER, GET_PROMOTIONS_BY_UUID } from "../../../../app/service/promotions";
import { Breadcum } from "../../../../components/breadcum";
import moment from "moment";
import VIEWICONS from '../../../../institute-dashboard-assets/images/view-icons.svg'
import { returnPaginationRange } from "../../../../app/utility/appUtils";

export const CouponReportList = () => {
    const [loading, setLoading] = useState(false);
    const [pageLoading, setPageLoading] = useState(false);
    const [activePage, setActivePage] = useState<any>();
    const [couponCodeList, setCouponCodeList] = useState<any>([]);
    const search = useLocation().search;
    const currentPageNumber: any = new URLSearchParams(search).get('page');
    const [couponPopup, setCouponPopup] = useState(false);
    let { uuId } = useParams<{ uuId: any }>();
    const promoUuid = uuId;
    const [couponDetails, setCouponDetails] = useState<any>({});
    const [couponRules, setCouponRules] = useState<any>({});
    const [promotionData, setPromotionData] = useState<any>({});
    const [pageNumbers, setPageNumbers] = useState<any>([]);
    let totalPage = pageNumbers.length
    const arrayOfPages = returnPaginationRange(pageNumbers.length, activePage, 10, 1);
    const [page, setPage] = useState<number>(1);
    const [limit, setLimit] = useState<number>(10);
    const [breadcumInfo, setBreadcumInfo] = useState<any>([
        {
            label: "Coupon Reports",
            value: "Coupon Reports",
            routerLink: "/dashboard/coupon-report/list",
            isActive: false,
        },
        {
            label: "Promotion Name",
            value: "",
            routerLink: "",
            isActive: false,
        },
    ]);

    useEffect(() => {
        getPromotionData();
        getCouponCodeData();

    }, []);
    function getPromotionData() {
        setLoading(true);
        GET_PROMOTIONS_BY_UUID(promoUuid).then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res.message);
                setLoading(false);
            } else {
                const data = res.rows[0];
                if (data.end_at) {
                    data.end_at = moment(data.end_at.split("T")[0]).format('DD-MM-yyyy');
                }
                if (data.start_at) {
                    data.start_at = moment(data.start_at.split("T")[0]).format('DD-MM-yyyy');
                }
                setPromotionData(data);

                setLoading(false);

            }
        });

    }
    function getCouponCodeData() {
        const activePageNumber = currentPageNumber > 0 ? (currentPageNumber - 1) : 0;
        setLoading(true);
        GET_COUPON_CODES_REPORT_BY_PROMOTION_UUID_PAGE_NUMBER(promoUuid, activePageNumber).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    setActivePage(currentPageNumber ? currentPageNumber : 1);
                    getBreadcumList(res);
                    const data = res.promotions[0].promocodes;
                    data.forEach((element: any) => {
                        if (element.applied_dt) {
                            element.applied_dt = moment(element.applied_dt.split("T")[0]).format('DD-MM-yyyy');
                        }
                    });
                    data?.map((ele: any, index: number) => (
                        ele.index = index + 1
                    ))
                    let array: any = [];
                    for (let i = (page - 1) * limit; i < (page * limit); i++) {
                        if (data[i]) {
                            array.push(data[i]);
                        }

                    }
                    setCouponCodeList(array);
                    const pageNumbers = Math.ceil(data.length / limit);
                    const pageArray: any = [];
                    for (let i = 1; i <= pageNumbers; i++) {
                        pageArray.push(i);
                    }
                    setPageNumbers(pageArray);
                    setLoading(false);
                }
            }
        });

    }
    function getBreadcumList(data: any) {
        breadcumInfo.splice(1, breadcumInfo.length - 1, {
            label: "Promotion Name",
            value: data.promotions[0].promotion_name,
            isActive: true,
        });
        setBreadcumInfo(breadcumInfo);
    }
    const onPageChange = (data: any) => {
        setActivePage(data);
        const pageNumber = data;
        setPageLoading(true);
        GET_COUPON_CODES_REPORT_BY_PROMOTION_UUID_PAGE_NUMBER(promoUuid, pageNumber).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setPageLoading(false);
                } else {
                    setActivePage(currentPageNumber ? currentPageNumber : 1);
                    const data = res.promotions[0].promocodes;
                    data.forEach((element: any) => {
                        if (element.applied_dt) {
                            element.applied_dt = moment(element.applied_dt.split("T")[0]).format('DD-MM-yyyy');
                        }
                    });
                    data?.map((ele: any, index: number) => (
                        ele.index = index + 1
                    ))
                    let array: any = [];
                    for (let i = (pageNumber - 1) * limit; i < (pageNumber * limit); i++) {
                        if (data[i]) {
                            array.push(data[i]);
                        }

                    }
                    setCouponCodeList(array);
                    const pageNumbers = Math.ceil(data.length / limit);
                    const pageArray: any = [];
                    for (let i = 1; i <= pageNumbers; i++) {
                        pageArray.push(i);
                    }
                    setPageNumbers(pageArray);
                    setPageLoading(false);
                }
            }
        });
    }
    const getCodeDetails = (data: any) => {
        setCouponDetails(data);
        let preparedObject = JSON.parse(data?.applied_rule)
        setCouponRules(preparedObject)
        setCouponPopup(true);
    };
    const handleCouponClose = () => {
        setCouponPopup(false);
    };
    const onClickDoubbleArrows = (arrow: any) => {
        if (arrow === "&raquo;") {
            setActivePage(totalPage);
            setPage(totalPage);
            onPageChange(totalPage);

        } else {
            setActivePage(1);
            setPage(1);
            onPageChange(1);

        }
    }
    function onClickPreviousPage() {
        setActivePage(activePage - 1);
        setPage(page - 1);
        onPageChange(page - 1);

    }
    function onClickPage(number: number) {
        let dots: any = "..."

        if (page !== 1 && number === dots) {
            setActivePage(1);
            setPage(1);
            onPageChange(1);

        } else if (activePage !== pageNumbers.length && number === dots) {
            setActivePage(totalPage - 1);
            setPage(totalPage - 1);
            onPageChange(totalPage - 1);
        }
        else {
            setActivePage(number);
            setPage(number);
            onPageChange(number);
        }
    }
    function onClickNextPage() {
        setActivePage(activePage + 1);
        setPage(page + 1);
        onPageChange(page + 1);

    }
    return (
        <div>
            <div
                className={`modal fade ${couponPopup ? "show" : "false"}`}
                style={{ display: couponPopup ? "block" : "none" }}
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog parent-model-dialog" role="document">
                    <div className="modal-content parent-model">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                                Coupon Details
                            </h5>
                            <button
                                type="button"
                                className="close popup-close cursor-pointer"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={handleCouponClose}
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body parent-info parent-model">
                            {Object.keys(couponDetails).length > 0 && <div className=" ">
                                <h5 className="border-bottom pb-2">Coupon Code Details</h5>

                                <div className='table-responsive mar-t-20 custom-scroll mb-4'>
                                    <table className='table table  fixed-table-header mb-0'>
                                        <thead>
                                            <tr>
                                                <th style={{ width: "50px" }}>Coupon Code</th>
                                                {promotionData?.discount_type === "FLAT_VALUE_DISCOUNT" && <th style={{ width: '100px' }}>Min Amount</th>}
                                                {promotionData?.discount_type === "FLAT_VALUE_DISCOUNT" && <th style={{ width: '100px' }}>Max Amount</th>}
                                                {promotionData?.discount_type !== "FLAT_VALUE_DISCOUNT" && <th style={{ width: '100px' }}>Min Students</th>}
                                                {promotionData?.discount_type !== "FLAT_VALUE_DISCOUNT" && <th style={{ width: '100px' }}>Max Students</th>}
                                                <th style={{ width: '100px' }}> Applied Students</th>
                                                <th style={{ width: '100px' }}> Payable Amount</th>
                                                <th style={{ width: '100px' }}> Discount Amount</th>
                                                <th style={{ width: '100px' }}> Discount Percentage</th>
                                                <th style={{ width: '100px' }}> Final Amount</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{couponDetails?.promo_code}</td>
                                                <td> {couponRules?.rule_applied?.min}</td>
                                                <td>{couponRules?.rule_applied?.max}</td>
                                                <td>{couponRules?.students_count}</td>
                                                <td>{couponRules?.payable_amount}</td>
                                                <td>{couponRules?.discount_amount?.toFixed()}</td>
                                                <td>{couponRules?.discount_percentage?.toFixed()} %</td>
                                                <td> {couponRules?.final_amount?.toFixed()}</td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                                {Object.keys(couponDetails?.user_details).length > 0 && <div className="mt-3 mb-4 ">
                                    <h5 className="border-bottom pb-2">Coupon Used By User </h5>

                                    <div className='table-responsive mar-t-20 custom-scroll'>
                                        <table className='table table  fixed-table-header mb-0'>
                                            <thead>
                                                <tr>
                                                    <th style={{ width: "50px" }}> Name</th>
                                                    <th style={{ width: '100px' }}>Gender</th>
                                                    <th style={{ width: '100px' }}> User Role</th>
                                                    {couponDetails?.user_details?.standard && <th style={{ width: '100px' }}> Standard</th>}
                                                    {couponDetails?.user_details?.division && <th style={{ width: '100px' }}>Division</th>}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>{couponDetails?.user_details?.name}</td>
                                                    <td> {couponDetails?.user_details.gender}</td>
                                                    <td>{couponDetails?.user_details?.user_role?.replace(/_/g, " ")}</td>
                                                    {couponDetails?.user_details?.standard && <td>{couponDetails?.user_details?.standard}</td>}
                                                    {couponDetails?.user_details?.division && <td>{couponDetails?.user_details?.division}</td>}
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>}
                                {Object.keys(couponDetails?.sponsor_details).length > 0 && <div className="my-2">
                                    <h5 className="border-bottom pb-2">Coupon Used By Sponsor </h5>
                                    <div className='table-responsive mar-t-20 custom-scroll mt-2'>
                                        <table className='table table  fixed-table-header mb-0'>
                                            <thead>
                                                <tr>
                                                    <th style={{ width: "50px" }}>Sponsorship Name</th>
                                                    <th style={{ width: '100px' }}>Sponsor Name</th>
                                                    <th style={{ width: '100px' }}> Amount</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>{couponDetails?.sponsor_details?.name}</td>
                                                    <td>{couponDetails?.sponsor_details?.sponsor_name}</td>
                                                    <td>{couponDetails?.sponsor_details?.amount}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>}
                            </div>}

                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary cursor-pointer"
                                data-dismiss="modal"
                                onClick={handleCouponClose}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading &&
                <div>
                    <div className="mt-3">
                        <Breadcum breadcumList={breadcumInfo} />
                    </div>
                    <div className=" mb-3">
                        <h5 className="border-bottom pb-2">Promotion Details</h5>
                        <div className='table-responsive mar-t-20 custom-scroll my-3'>
                            <table className='table   fixed-table-header mb-0'>
                                <thead>
                                    <tr>
                                        <th style={{ width: "100px" }}>Promotion Name</th>
                                        <th style={{ width: '100px' }}>Promotion Type </th>
                                        <th style={{ width: '100px' }}>Discount Type </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr >
                                        <td>{promotionData?.name}</td>
                                        <td> {promotionData?.promotion_type}</td>
                                        <td>{promotionData?.discount_type?.replace(/_/g, " ")}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <h5 className="mb-0 border-bottom pb-2">Coupon Codes</h5>
                    </div>
                    {pageLoading &&
                        <div className="text-center p-5">
                            <div className="spinner-border" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                    }
                    {!pageLoading &&
                        <div className='table-responsive mar-t-20 custom-scroll my-3'>
                            <table className='table   fixed-table-header mb-0'>
                                <thead>
                                    <tr>
                                        <th style={{ width: "70px" }}>S.No</th>
                                        <th style={{ width: "150px" }}>Coupon Code</th>
                                        <th style={{ width: '100px' }}>Status </th>
                                        <th style={{ width: '100px' }}>Applied Date </th>
                                        <th style={{ width: '100px' }}>Details </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {couponCodeList?.map((data: any, i: number) => {
                                        return (
                                            <tr key={i}>
                                                <td> {data?.index}</td>
                                                <td> {data?.promo_code}</td>
                                                <td> {data.status == 1 ? <p className="f16 mb-0 text-completed" style={{ fontWeight: 'bold' }}>Active</p> :
                                                    (data.status == 2 ? <p className="f16 mb-0 active" style={{ fontWeight: 'bold' }}> Applied </p> : <p className="f16 mb-0 text-inprogess" style={{ fontWeight: 'bold' }}>InActive</p>)}</td>
                                                <td>{data?.applied_dt}</td>
                                                <td><img className='eye-icons me-3 cursor-pointer' src={VIEWICONS} onClick={() => getCodeDetails(data)} />{data.total_students}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>}
                    {couponCodeList.length === 0 && <p className='text-center f16 mt-2'>No Data Available</p>}
                    {
                        couponCodeList.length > 0 && (
                            <nav className="mt-3" style={{ float: "right" }}>
                                {pageNumbers && (
                                    <ul className="pagination">
                                        <li className="page-item" onClick={() => onClickDoubbleArrows("&laquo;")}><span className={`page-link prev ${page == 1 ? "disabled" : ""}`}>&laquo;</span></li>

                                        <li className="page-item">
                                            <button className={`page-link prev ${page == 1 ? "disabled" : ""}`} onClick={() => onClickPreviousPage()}>
                                                &lsaquo;
                                            </button>
                                        </li>
                                        {pageNumbers.length > 5 &&
                                            arrayOfPages.map((value: any, i: number) => (
                                                <li key={i} className={page == value ? "active page-item" : "page-item"} onClick={() => onClickPage(value)}><span className="page-link">{value}</span></li>

                                            ))}
                                        {pageNumbers.length <= 5 &&
                                            pageNumbers.map((number: number, i: number) => (
                                                <li className={page == number ? "active page-item" : "page-item"} key={i}>
                                                    <button className="page-link" onClick={() => onClickPage(number)}>
                                                        {number}
                                                    </button>
                                                </li>
                                            ))}
                                        <li className="page-item">
                                            <button className={`page-link next ${page == pageNumbers.length ? "disabled" : ""}`} onClick={() => onClickNextPage()}>
                                                &rsaquo;
                                            </button>
                                        </li>
                                        <li className="page-item" onClick={() => onClickDoubbleArrows("&raquo;")}><span className={`page-link next ${page == pageNumbers.length ? "disabled" : ""}`}>&raquo;</span></li>
                                    </ul>
                                )}
                            </nav>
                        )
                    }

                    {/* <DataTable TableCols={CouponCodesByPromotinGridCols} tableData={couponCodeList} onChange={getCodeDetails}
                        activePageNumber={activePage} pageNumber={onPageChange} pageNumbers={pageArray}></DataTable> */}
                </div>
            }

        </div>
    )
}