import React, { useState, useEffect } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { GET_MODULES_BY_USER_CODES } from "../../../app/service/module.service";
import { GET_USER_USER_CODE } from "../../../app/service/users.service";
import SUCCESSIMG from '../../../institute-dashboard-assets/images/success-img.svg'

export const ParentServeySuccessPage = () => {
    const [studentInfo, setStudentInfo] = useState<any>({});
    const [loading, setLoading] = useState(false);
    const [checkedOption, setCheckedOption] = useState('')
    const [disabled, setDisabled] = useState(true)
    const [accessChild, setAccessChild] = useState(true);
    const [accessFather, setAccessFather] = useState(true);
    const [accessMother, setAccessMother] = useState(true);
    const history = useHistory();
    let { id, userCode, studentCode } = useParams<{ id: string, userCode: string, studentCode: string }>();

    useEffect(() => {
        getModulesByUserCode(studentCode)
        getUserDetails();

    }, []);

    const getModulesByUserCode = (userCode: any) => {
        setLoading(true);
        GET_MODULES_BY_USER_CODES(userCode).then((res: any) => {
            if (res) {
                if (res.status === "fail") {
                    toast.error(res.message);
                    setLoading(false);

                } else {
                    let data = res.filter(
                        (el: any) => el.uuid === id
                    );
                    if (data[0]?.module_status === "completed") {
                        setAccessChild(!accessChild);
                    }
                    if (data[0]?.parent_details?.father?.module_status === "completed" && data[0]?.module_status === "completed") {
                        setAccessFather(!accessFather);
                    }
                    if (data[0]?.parent_details?.mother?.module_status === "completed" && data[0]?.module_status === "completed") {
                        setAccessMother(!accessMother);
                    }
                    // setLoading(false);
                }
            }
        });
    };
    function getUserDetails() {
        setLoading(true);
        GET_USER_USER_CODE(studentCode).then((response: any) => {
            if (response.status === 'fail') {
                toast.error(response.message);
                setLoading(false);
            } else {
                const data = response.rows[0];
                setStudentInfo(data);
                setLoading(false);
            }
        });
    }
    const handleOptionChange = (event: any) => {
        setCheckedOption(event.target.value);


    }
    const handleRoutes = () => {
        if (checkedOption === "student") {
            history.push(`/parent-dashboard/view/reports/student/${id}/${userCode}/${studentCode}`);
        } else if (checkedOption === "parent") {
            history.push(`/parent-dashboard/view/reports/parent/${id}/${userCode}/${studentCode}`);
        } else if (checkedOption === "family") {
            history.push(`/parent-dashboard/view/reports/family/${id}/${userCode}/${studentCode}`);
        }

    }
    return (
        <div>
            {loading &&
                <div className="text-center p-5 mt-5">
                    <div className="spinner-border mt-5" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {
                !loading &&
                <div>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb pb-2 pt-0 px-0 border-bottom">
                            <li className="breadcrumb-item pe-2"><Link to='/parent-dashboard/view'>Dashboard</Link></li> /
                            <li className="breadcrumb-item"><a>Career AspirationTM Survey</a></li>
                        </ol>
                    </nav>
                    <div className="mt-3">
                        <p className="mb-0 text-success">Parent Survey for</p>
                        <h2 className="f22 fw-light">{studentInfo?.name} | {studentInfo?.standard}<sup>th</sup> Class</h2>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-md-9 text-center success-block">
                            <img src={SUCCESSIMG} alt="" />
                            <h1 className="text-completed fw-light f60 m-0">Thank You</h1>
                            <p className="f18">for Completing the Survey.</p>

                            <p className="f18 mt-5 mb-0">Now you can access your</p>
                            <h1 className="fw-light m-0">Children reports and also yours</h1>

                            <p className="f18 mt-5 mb-2">Select below to view reports</p>
                            <div>
                                <div className="form-check form-check-inline">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="inlineRadioOptions"
                                        value='student'
                                        disabled={accessChild}
                                        onChange={handleOptionChange}
                                    />
                                    <label className="form-check-label" htmlFor="inlineRadio1">Student</label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="inlineRadioOptions"
                                        value='parent'
                                        disabled={accessFather}
                                        onChange={handleOptionChange}
                                    />
                                    <label className="form-check-label" htmlFor="inlineRadio2"
                                    >Father</label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="inlineRadioOptions"
                                        value='family'
                                        disabled={accessMother}
                                        onChange={handleOptionChange}
                                    />
                                    <label className="form-check-label" htmlFor="inlineRadio3">
                                        Mother</label>

                                </div>
                            </div>
                            <button className="btn btn-primary mb-3 mt-2 px-3" onClick={() => handleRoutes()} disabled={accessChild}>View Report</button>
                            {accessChild && <h6 className='text-inprogess'>You cannot download the survey report if the children do not complete their test.</h6>}

                        </div>
                    </div>
                </div>}

        </div>
    )
}