import React, { useState, useEffect } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { GET_MODULES_BY_USER_CODES } from "../../../app/service/module.service";
import { GET_USER_USER_CODE } from "../../../app/service/users.service";
import SUCCESSIMG from '../../../institute-dashboard-assets/images/success-img.svg'

export const StudentCasServeySuccessPage = () => {
    const history = useHistory();
    let { id, studentCode, languageCode } = useParams<{ id: any, studentCode: any, languageCode: string }>();

    useEffect(() => {


    }, []);

    function viewReport() {
        history.push(`/student-dashboard/list/reports/student/${id}/${studentCode}`)

    }
    return (
        <div>

            <div>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb pb-2 pt-0 px-0 border-bottom">
                        <li className="breadcrumb-item pe-2"><Link to='/student-dashboard/list'>Dashboard</Link></li> /
                        <li className="breadcrumb-item"><a>Career AspirationTM Survey</a></li>
                    </ol>
                </nav>

                <div className="row justify-content-center mt-3">
                    <div className="col-md-9 text-center success-block">
                        <img src={SUCCESSIMG} alt="" />
                        <h1 className="text-completed fw-light f60 m-0">Thank You</h1>
                        <p className="f18">for Completing the Survey.</p>
                        <h1 className="fw-light m-0 "> Your parents can now access score sheet & reports</h1>
                        <button className="btn btn-primary mb-3 mt-2 px-3" onClick={() => viewReport()}>View Report</button>
                    </div>
                </div>
            </div>

        </div>
    )
}