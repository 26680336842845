import React from 'react';
import MathsReportRoutes from './routes';

const MathsReportPage = (props: any) => {
    
    return (
        <div>
            <MathsReportRoutes {...props} />
        </div>
    );
};

export default MathsReportPage;