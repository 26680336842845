import React, { useEffect, useRef, useState } from 'react'
import FormBuilder from '../../../components/form-builder'
import { FormValidator, GetControlIsValid } from '../../../components/form-builder/validations';
import { FormControlError, FormField, FormValidators } from '../../../components/form-builder/model/form-field';
import { Link, useHistory, useParams } from 'react-router-dom';
import { CREATE_USER } from '../../../app/service/users.service';
import { PopupData } from '../../../redux/app-utility/model/popup-data';
import { AppUtilityActions } from '../../../redux/app-utility/action';
import { connect } from 'react-redux';
import { CREATE_MODULE, GET_MODULES_ID, GET_SCHOOLS_MODULE_BY_CODE, UPDATE_MODULE, UPDATE_SCHOOLS_MODULE_BY_CODE } from '../../../app/service/module.service';
import { ToastContainer, toast } from 'react-toastify';
import { LOOK_UP_DATA } from '../../../app/service/lookup.service';
import { YAxis } from 'recharts';
import { log } from 'console';


interface Props {
    updatePopup?: (data: PopupData) => void;
}

export const ModuleFormPage: React.FC<any> = (props: Props) => {
    const [modulesData, setModulesData] = useState<any>({});
    const [currentModulesData, setCurrentModulesData] = useState<any>({});
    const [loading, setLoading] = useState(false);
    const [moduleloading, setModuleLoading] = useState(false);
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [validationErrors, setValidationErrors] = useState<FormControlError[]>([]);
    const [moduleCodeData, setModuleCodeData] = useState<any>([]);
    const [canShowPopup, setCanShowPopup] = useState(false);
    const [canShowSchoolPopup, setCanShowSchoolPopup] = useState(false);
    const [schoolModuleList, setSchoolModuleList] = useState<any>([]);
    const history = useHistory();
    let { code, id } = useParams<{ code: string, id: string }>();
    const moduleForm = useRef<any>({});
    const moduleId = Number(id);
    const [moduleNameError, setModueleNameError] = useState('');
    const [moduleDescriptionError, setModuleDescriptionError] = useState('');
    const [subModulenError, setSubModuleError] = useState('');
    const [moduleVersionError, setModuleVersionError] = useState('');
    const [targetStandardError, setTargetStandardError] = useState('');
    const [amountError, setAmountError] = useState('');
    const [offerAmountError, setOfferAmountError] = useState('');
    const [listPriceError, setListPriceError] = useState('');
    const [searchData, setSearchData] = useState<any>({});
    const formValidations = [
        new FormField('module_name', [FormValidators.REQUIRED]),
        new FormField('module_code', [FormValidators.REQUIRED]),
        new FormField('module_description', [FormValidators.REQUIRED]),
        new FormField('sub_module', [FormValidators.REQUIRED]),
        new FormField('module_ver', [FormValidators.REQUIRED]),
        new FormField('target_std', [FormValidators.REQUIRED]),
        new FormField('amount', []),
        new FormField('offer_amount', []),
        new FormField('list_price', []),
    ];

    useEffect(() => {
        LOOK_UP_DATA().then((res: any) => {
            if (res) {
                setModuleCodeData(res.module_code);
            }
        });
        if (moduleId > 0) {
            getModuleData();
        }
    }, []);
    function getModuleData() {
        setLoading(true);
        GET_MODULES_ID(moduleId).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    setLoading(false);
                    setCurrentModulesData(res.rows[0]);

                }
            }
        });
    }

    const getInputValid = (control: string) => {
        const value = GetControlIsValid(validationErrors, control);
        return value;
    }

    const handleSubmit = () => {
        setIsFormSubmitted(true);
        // const data = { ...modulesData.value };
        const data = modulesData.value ? { ...modulesData.value } : { ...currentModulesData };
        const errors: FormControlError[] = FormValidator(formValidations, data);
        data.offer_amount = Number(data.offer_amount);
        data.list_price = Number(data.list_price);
        setValidationErrors(errors);
        if (errors.length < 1 && onChangeModuleName(data.module_name)
            && onChangeModuleDescription(data.module_description)
            && onChangeSubModule(data.sub_module)
            && onChangeModuleVersion(data.module_ver)
            && onChangeTargetStandard(data.target_std)
            && onChangeAmount(data.amount)
            && onChangeOfferAmount(data.offer_amount)
            && onChangeListPrice(data.list_price)) {
            setLoading(true);
            data.module_name = data.module_name.replace(/\s/g, '');
            data.sub_module = data.sub_module.replace(/\s/g, '');
            data.auto_assign = 1;
            // data.module_code = data.module_code.replace(/\s/g, '');
            CREATE_MODULE(data).then(res => {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                    setCurrentModulesData(data);
                } else {
                    toast.success("Module Created Successfully");
                    history.push("/dashboard/module/list");
                    setLoading(false);
                }
            });
        } else {
            setLoading(false);
            if (!moduleNameError && !onChangeModuleName(data.module_name)) {
                setModueleNameError('Please enter module name');
            }
            if (!moduleDescriptionError && !onChangeModuleDescription(data.module_description)) {
                setModuleDescriptionError('Please enter module description');
            }
            if (!subModulenError && !onChangeSubModule(data.sub_module)) {
                setSubModuleError('Please enter sub module');
            }
            if (!moduleVersionError && !onChangeModuleVersion(data.module_ver)) {
                setModuleVersionError('Please enter module version');
            }
            if (!targetStandardError && !onChangeTargetStandard(data.target_std)) {
                setTargetStandardError('Please enter target standard');
            }
            if (!amountError && !onChangeAmount(data.amount)) {
                setAmountError('Please enter amount');
            }
            if (!offerAmountError && !onChangeOfferAmount(data.offer_amount)) {
                setOfferAmountError('Please enter additional child discount');
            }
            if (!listPriceError && !onChangeListPrice(data.list_price)) {
                setListPriceError('Please enter list price');
            }
        }
    }

    function updateModule() {
        const data = modulesData.value ? { ...modulesData.value } : { ...currentModulesData };
        const errors: FormControlError[] = FormValidator(formValidations, data);
        data.id = moduleId;
        setLoading(true);
        setValidationErrors(errors);
        delete data.created_at;
        delete data.updated_at;
        delete data.status;
        data.offer_amount = Number(data.offer_amount);
        data.list_price = Number(data.list_price);

        if (errors.length < 1 && onChangeModuleName(data.module_name)
            && onChangeModuleDescription(data.module_description)
            && onChangeSubModule(data.sub_module)
            && onChangeModuleVersion(data.module_ver)
            && onChangeTargetStandard(data.target_std)
            && onChangeAmount(data.amount)
            && onChangeOfferAmount(data.offer_amount)
            && onChangeListPrice(data.list_price)) {
            UPDATE_MODULE(data).then((res: any) => {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    const data = res;
                    toast.success("Module Updated Successfully");
                    history.push("/dashboard/module/list");
                    // setCanShowPopup(true);
                    setLoading(false);
                }
            })
        } else {
            setLoading(false);
            if (!moduleNameError && !onChangeModuleName(data.module_name)) {
                setModueleNameError('Please enter module name');
            }
            if (!moduleDescriptionError && !onChangeModuleDescription(data.module_description)) {
                setModuleDescriptionError('Please enter module description');
            }
            if (!subModulenError && !onChangeSubModule(data.sub_module)) {
                setSubModuleError('Please enter sub module');
            }
            if (!moduleVersionError && !onChangeModuleVersion(data.module_ver)) {
                setModuleVersionError('Please enter module version');
            }
            if (!targetStandardError && !onChangeTargetStandard(data.target_std)) {
                setTargetStandardError('Please enter target standard');
            }
            if (!amountError && !onChangeAmount(data.amount)) {
                setAmountError('Please enter amount');
            }
            if (!offerAmountError && !onChangeOfferAmount(data.offer_amount)) {
                setOfferAmountError('Please enter additional child discount');
            }
            if (!listPriceError && !onChangeListPrice(data.list_price)) {
                setListPriceError('Please enter list price');
            }
        }
    }

    const handleInput = (data: any) => {
        data.value = { ...currentModulesData, ...data.value };
        setModulesData(data);
        const errors: any = FormValidator(formValidations, data.value);
        setValidationErrors(errors);
    }

    function handlemModuleCodeChange(e: any) {
        const data = { ...modulesData.value };
        if (e.target.value) {
            data.module_code = e.target.value;
        }
        if (currentModulesData) {
            currentModulesData.module_code = e.target.value;
        }
    }

    const onChangeModuleName = (event: any) => {
        setModueleNameError('');
        const reg = /^[A-Za-z0-9]+(?:[ _-][A-Za-z0-9]+)*$/;
        if (event) {
            if (!reg.test(event.trim())) {
                setModueleNameError('Module name can`t contain spaces and special characters');
            } else {
                return true;
            }
        } else {
            setModueleNameError('Please enter module name');
        }
    }

    const onChangeModuleDescription = (event: any) => {
        setModuleDescriptionError('');
        if (event) {
            if (event.trim().length <= 2) {
                setModuleDescriptionError('Module description minimum 3 characters');
            } else if (event.trim().length >= 256) {
                setModuleDescriptionError('Module description maximum 256 characters');
            } else {
                return true;
            }
        } else {
            setModuleDescriptionError('Please enter module description');
        }
    }

    const onChangeSubModule = (event: any) => {
        setSubModuleError('');
        const reg = /^[A-Za-z0-9]+(?:[ _-][A-Za-z0-9]+)*$/;
        if (event) {
            if (!reg.test(event.trim())) {
                setSubModuleError('Sub module can`t contain spaces and special characters');
            } else {
                return true;
            }
        } else {
            setSubModuleError('Please enter sub module');
        }
    }

    const onChangeModuleVersion = (event: any) => {
        setModuleVersionError('');
        const reg = /^[A-Za-z0-9]+(?:[ _-][A-Za-z0-9]+)*$/;
        if (event) {
            if (!reg.test(event.trim())) {
                setModuleVersionError('Module version can`t contain spaces and special characters');
            } else {
                return true;
            }
        } else {
            setModuleVersionError('Please enter module version');
        }
    }

    const onChangeTargetStandard = (event: any) => {
        setTargetStandardError('');
        const reg = /^[A-Za-z0-9]+(?:[ _-][A-Za-z0-9]+)*$/;
        if (event) {
            if (!reg.test(event.trim())) {
                setTargetStandardError('Target standard can`t contain spaces and special characters');
            } else {
                return true;
            }
        } else {
            setTargetStandardError('Please enter target standard');
        }
    }

    const onChangeAmount = (event: any) => {
        setAmountError('');
        const reg = /^[0-9]*$/;
        if (event !== 0 || event !== '') {
            if (!reg.test(event)) {
                setAmountError('Amount only numbers');
            } else {
                return true;
            }
        } else {
            setAmountError('Please enter amount');
        }
    }

    const onChangeOfferAmount = (event: any) => {
        setOfferAmountError('');
        const reg = /^[0-9]*$/;
        if (event !== 0 || event !== '') {
            if (!reg.test(event)) {
                setOfferAmountError('Additional child discount only numbers');
            } else {
                return true;
            }
        } else {
            setOfferAmountError('Please enter additional child discount');
        }
    }

    const onChangeListPrice = (event: any) => {
        setListPriceError('');
        const reg = /^[0-9]*$/;

        if (event !== 0 || event !== '') {
            if (!reg.test(event)) {
                setListPriceError('List price only numbers');
            } else {
                return true;
            }
        } else {
            setListPriceError('Please enter list price');
        }
    }
    const handleclose = () => {
        setCanShowPopup(false);
    };

    function showSchoolsPrice() {
        setCanShowSchoolPopup(true);
        getSchoolsList();

    }

    function getSchoolsList() {
        setModuleLoading(true);
        GET_SCHOOLS_MODULE_BY_CODE(currentModulesData.module_code, currentModulesData.uuid).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                } else {
                    if (res) {
                        setModuleLoading(false);
                        let data = res;
                        data.forEach((data: any) => {
                            data.isChanged = false;
                        })
                        setSchoolModuleList(data);
                    }
                }
            }
        });
    }
    function updateSchoolsPrice() {
        const data = schoolModuleList;
        const updatedList = schoolModuleList.filter((m: any) => m.isChanged);
        let payload: any[] = [];
        for (let j = 0; j < updatedList.length; j++) {
            payload.push({
                id: updatedList[j].id,
                amount: updatedList[j].amount
            })
        }
        if (payload.length > 0) {
            UPDATE_SCHOOLS_MODULE_BY_CODE({ data: payload }).then((res: any) => {
                if (res.status === 'fail') {
                    toast.error(res.message);
                } else {
                    toast.success("Module Updated Successfully");
                    setCanShowSchoolPopup(false);
                    history.push('/dashboard/module/list');
                }
            });
        }

    }

    const handlecloseSchoolPopup = () => {
        setCanShowSchoolPopup(false);
    };
    const handleSearchInput = (data: any) => {
        setSearchData(data);

    };

    function handleSearch() {
        const data = { ...searchData.value };
        if (data) {
            let seacrchData = schoolModuleList?.filter((ele: any) => ele?._school["name_of_school"]?.toLowerCase().includes(data["name_of_school"].toLowerCase()));
            setSchoolModuleList(seacrchData);
        }
    }

    function handleRefresh() {
        const data = searchData;
        data.value = {};
        setSearchData({ ...data });
        // setSearchData({});
        getSchoolsList();
        moduleForm.current.reset();
    }
    function priceChange(index: number, value: any) {
        const data = schoolModuleList;
        data[index].isChanged = true;
        data[index].amount = value;
        setSchoolModuleList(data);
    }

    return (
        <div>
            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading && <div>
                <div className="row  py-3">
                    {moduleId === 0 && <h2>Create Module</h2>}
                    {moduleId > 0 && <h2>Update Module</h2>}
                </div>
                <div
                    className={`modal fade ${canShowPopup ? "show" : "false"}`}
                    style={{ display: canShowPopup ? "block" : "none" }}
                    role="dialog"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                >
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5></h5>
                                <button
                                    type="button"
                                    className="close popup-close cursor-pointer"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                    onClick={handleclose}
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className='modal-body'>
                                <h6 className="modal-title" id="exampleModalLabel">
                                    Changing the price here will not change the price for individual schools
                                </h6>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-primary cursor-pointer"
                                    onClick={() => showSchoolsPrice()}
                                >
                                    Update Schools Price
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-secondary cursor-pointer"
                                    data-dismiss="modal"
                                    onClick={handleclose}
                                >
                                    Ok
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className={`modal fade ${canShowSchoolPopup ? "show" : "false"}`}
                    style={{ display: canShowSchoolPopup ? "block" : "none" }}
                    role="dialog"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                >
                    <div className="modal-dialog parent-model-dialog" role="document" >
                        <div className="modal-content " >
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">
                                    Update Schools Module
                                </h5>
                                <button
                                    type="button"
                                    className="close popup-close cursor-pointer"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                    onClick={handlecloseSchoolPopup}
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body parent-info">
                                <div className="row" style={{ marginBottom: '15px' }}>
                                    <div className="col-md-3 my-3 my-md-0">
                                        <FormBuilder onUpdate={handleSearchInput}>
                                            <form ref={moduleForm}>
                                                <div className="row">
                                                    <div className='col-md-12'>
                                                        <div className='row'>
                                                            <div className="col-md-12">
                                                                <input type="text" className="form-control ft-14" placeholder="Search with school name" name="name_of_school" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </FormBuilder>
                                    </div>
                                    <div className="col-md-4  text-end text-md-start">
                                        <button className="btn btn-primary px-3 cursor-pointer" type="button" onClick={() => handleSearch()}>Search</button>&nbsp;
                                        <button className="btn clear-btn cursor-pointer" type="button" onClick={() => handleRefresh()}>
                                            Clear
                                        </button>
                                    </div>
                                </div>
                                {moduleloading && (
                                    <div className="text-center p-5">
                                        <div className="spinner-border" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    </div>)
                                }
                                {!moduleloading &&
                                    (<div className="mar-t-20 custom-scroll table-responsive tableFixHead">
                                        <table className="table fixed-table-header mb-0 ">
                                            <thead>
                                                <tr>
                                                    <th style={{ width: "180px" }}>Name</th>
                                                    <th style={{ width: "180px" }}>Code</th>
                                                    <th style={{ width: "180px" }}>Price </th>
                                                </tr>
                                            </thead>
                                            <tbody  >
                                                {schoolModuleList.length > 0 && schoolModuleList.map((data: any, index: number) => {
                                                    return (
                                                        <tr key={data.id} >
                                                            <td>{data._school && data._school["name_of_school"]}</td>
                                                            <td>{data.module_code}</td>
                                                            <td><input className='rounded-3' type="text" name="amount" defaultValue={data.amount} onChange={(event) => priceChange(index, event?.target.value)} /></td>
                                                        </tr>)
                                                })}
                                            </tbody>
                                        </table>
                                        {schoolModuleList.length === 0 && <p className='text-center f14 mt-2'>No Data Available</p>}
                                    </div>)}
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-primary cursor-pointer"
                                    onClick={updateSchoolsPrice}
                                >
                                    Update
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-secondary cursor-pointer"
                                    data-dismiss="modal"
                                    onClick={handlecloseSchoolPopup}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <FormBuilder onUpdate={handleInput}>
                    <form>
                        <div className="row custom-form">
                            <div className="col-md-4">
                                <div className="mb-4">
                                    <label className="form-label mb-0">Module Name
                                        <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>*</span>
                                    </label>
                                    <input className="form-control form-control-lg" type="text" name="module_name" onChange={(e) => onChangeModuleName(e.target.value)} defaultValue={currentModulesData.module_name} placeholder="Please enter module name" disabled style={{ backgroundColor: 'hsl(0,0%,96.5%)' }} />
                                    {moduleNameError && <p className="text-danger">{moduleNameError}</p>}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="mb-4">
                                    <label className="form-label mb-0">Module Code
                                        <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>*</span>
                                    </label>
                                    <select name="module_code" className="form-select form-select-lg mb-3" onChange={(e) => { handlemModuleCodeChange(e) }} value={currentModulesData.module_code} disabled style={{ backgroundColor: 'hsl(0,0%,96.5%)' }}>
                                        <option value="">Select module code</option>
                                        {moduleCodeData.map((name: any, i: number) => (
                                            <option key={i} value={name}>
                                                {name}
                                            </option>
                                        ))}
                                    </select>
                                    {isFormSubmitted && !getInputValid('module_code') && <p className="text-danger">Please select module code</p>}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="mb-4">
                                    <label className="form-label mb-0">Module Description
                                        <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>*</span>
                                    </label>
                                    <input className="form-control form-control-lg" type="text" name="module_description" onChange={(e) => onChangeModuleDescription(e.target.value)} defaultValue={currentModulesData.module_description} placeholder="Please enter module description" disabled style={{ backgroundColor: 'hsl(0,0%,96.5%)' }} />
                                    {moduleDescriptionError && <p className="text-danger">{moduleDescriptionError}</p>}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="mb-4">
                                    <label className="form-label mb-0">Sub Module
                                        <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>*</span>
                                    </label>
                                    <input className="form-control form-control-lg" type="text" name="sub_module" onChange={(e) => onChangeSubModule(e.target.value)} defaultValue={currentModulesData.sub_module} placeholder="Please enter sub module" disabled style={{ backgroundColor: 'hsl(0,0%,96.5%)' }} />
                                    {subModulenError && <p className="text-danger">{subModulenError}</p>}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="mb-4">
                                    <label className="form-label mb-0">Module Version
                                        <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>*</span>
                                    </label>
                                    <input className="form-control form-control-lg" type="text" name="module_ver" onChange={(e) => onChangeModuleVersion(e.target.value)} defaultValue={currentModulesData.module_ver} placeholder="Please enter module version" disabled style={{ backgroundColor: 'hsl(0,0%,96.5%)' }} />
                                    {moduleVersionError && <p className="text-danger">{moduleVersionError}</p>}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="mb-4">
                                    <label className="form-label mb-0">Target Standard
                                        <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>*</span>
                                    </label>
                                    <input className="form-control form-control-lg" type="text" name="target_std" onChange={(e) => onChangeTargetStandard(e.target.value)} defaultValue={currentModulesData.target_std} placeholder="Please enter target standard" disabled style={{ backgroundColor: 'hsl(0,0%,96.5%)' }} />
                                    {targetStandardError && <p className="text-danger">{targetStandardError}</p>}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="mb-4">
                                    <label className="form-label mb-0">Amount
                                        <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>*</span>
                                    </label>
                                    <input className="form-control form-control-lg" type="text" name="amount" onChange={(e) => onChangeAmount(e.target.value)} defaultValue={currentModulesData.amount} placeholder="Please enter amount" />
                                    {amountError && <p className="text-danger">{amountError}</p>}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="mb-4">
                                    <label className="form-label mb-0">Additional Child Discount
                                        <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>*</span>
                                    </label>
                                    <input className="form-control form-control-lg" type="text" name="offer_amount" onChange={(e) => onChangeOfferAmount(e.target.value)} defaultValue={currentModulesData.offer_amount} placeholder="Please enter offer amount" />
                                    {offerAmountError && <p className="text-danger">{offerAmountError}</p>}
                                </div>
                            </div>

                            <div className="col-md-4">
                                <div className="mb-4">
                                    <label className="form-label mb-0">List Price
                                        <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>*</span>
                                    </label>
                                    <input className="form-control form-control-lg" type="text" name="list_price" onChange={(e) => onChangeListPrice(e.target.value)} defaultValue={currentModulesData.list_price} placeholder="Please enter list price" />
                                    {listPriceError && <p className="text-danger">{listPriceError}</p>}
                                </div>
                            </div>
                        </div>
                    </form>
                </FormBuilder>
                <div className="form-footer border-top border-primary py-3 text-end mt-4 me-3">
                    {moduleId === 0 && <a className="btn btn-primary btn-sm px-5 rounded-12 cursor-pointer" onClick={handleSubmit}>Create</a>}
                    {moduleId > 0 && <a className="btn btn-primary btn-sm px-5 rounded-12 cursor-pointer me-3" onClick={() => showSchoolsPrice()}>View Schools Price</a>}
                    {moduleId > 0 && <a className="btn btn-primary btn-sm px-5 rounded-12 cursor-pointer" onClick={() => updateModule()}>Update</a>}
                    <Link className="text-dark text-decoration-none ms-2 cursor-pointer" to="/dashboard/module/list">Cancel</Link>
                </div>
            </div>}
        </div >
    )
}


const mapStateToProps = (state: any) => {
    return {
        UtilityRed: state.AppUtilityReducer
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        updatePopup: (data: PopupData) => dispatch(AppUtilityActions.updatePopup(data))
    }
}
//  export default SchoolFormPage;
export default connect(mapStateToProps, mapDispatchToProps)(ModuleFormPage);