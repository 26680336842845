import React, { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { DataTable } from "../../../../../components/data-table";
import { FormControlError, FormField, FormValidators } from "../../../../../components/form-builder/model/form-field";
import { toast } from "react-toastify";
import { FormValidator } from "../../../../../components/form-builder/validations";
import { CREATE_STANDARD, DELETE_STANDARD, GET_STANDARD_BY_UUID, GET_STANDARD_FILTER, GET_STANDARD_LIST, UPDATE_STANDARD } from "../../../../../app/service/abhyas-standard.service";
import FormBuilder from "../../../../../components/form-builder";
import { Breadcum } from "../../../../../components/breadcum";
import { AbhyasStandardDataGridCols } from "./data-grid-cols";
import moment from "moment";
interface Props {
    isLinked: string;
    display: string;
    boardUuid: any;
    mediumUuid: any;
    updateList?: (e: string) => void;
}
export const AbhyasStandardList: React.FC<Props> = (props) => {

    const [standardList, setStandardList] = useState<any>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [pageArray, setPageNumbers] = useState([]);
    const [activePage, setActivePage] = useState(0);
    const [canShowPopup, setCanShowPopup] = useState<boolean>(false);
    const [standardId, setStandardId] = useState<string>("0");
    const [formLoading, setFormLoading] = useState<boolean>(false);
    const [currentFormData, setCurrentFormData] = useState<any>({});
    const [formData, setFormData] = useState<any>([]);
    const [formValidationErrors, setFormValidationErrors] = useState<FormControlError[]>([]);
    const [nameError, setNameError] = useState('');
    const [preparedSearchData, setPreparedSearchData] = useState<any>({});
    const [codeError, setCodeError] = useState('');
    const standardForm = useRef<any>({});
    const searchForm = useRef<any>({});
    const [searchData, setSearchData] = useState<any>({});
    let { code, id, BoardUuid, BoardName, MediumUuid, MediumName } = useParams<{ code: string, id: string, BoardUuid: string, BoardName: string, MediumUuid: string, MediumName: string }>();
    const moduleCode = code;
    const moduleId = id;
    const boardUuid = BoardUuid ? BoardUuid : props.boardUuid;
    const mediumUuid = MediumUuid ? MediumUuid : props.mediumUuid;
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const FormValidations = [
        new FormField('name', [FormValidators.REQUIRED]),
        new FormField('code', [FormValidators.REQUIRED]),
    ];
    const history = useHistory();
    const [breadcumInfo, setBreadcumInfo] = useState<any>([
        {
            label: 'Board',
            value: decodeURIComponent(BoardName),
            routerLink: `/dashboard/module/info/ABHYAS/${moduleCode}/${moduleId}/worksheet`,
            subName: "(board)",
            isActive: false
        },
        {
            label: 'Medium',
            value: decodeURIComponent(MediumName),
            routerLink: `/dashboard/module/info/ABHYAS/${moduleCode}/${moduleId}/${boardUuid}/${BoardName}/medium`,
            subName: "(Medium)",
            isActive: false
        },
        {
            label: 'Standards',
            value: 'Standards',
            nameHighlight: true,
            isActive: true
        }
    ]);

    useEffect(() => {
        for (let j = 0; j < AbhyasStandardDataGridCols.length; j++) {
            if (String(AbhyasStandardDataGridCols[j].isLink) === "true" || String(AbhyasStandardDataGridCols[j].isLink) === "false") {
                AbhyasStandardDataGridCols[j].isLink = props.isLinked === "false" ? false : true;
            }

        }
        getStandards();

    }, [])


    const getStandards = () => {
        setLoading(true);
        GET_STANDARD_LIST(activePage == 0 ? activePage : activePage - 1, boardUuid, mediumUuid, {}).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res?.err);
                    setLoading(false);
                } else {
                    const data = res['rows'];
                    data?.forEach((element: any) => {
                        element.created_dt = moment(element?.created_dt?.split("T")[0]).format('DD-MM-yyyy');
                        if (element?.modified_dt) {
                            element.modified_dt = moment(element?.modified_dt?.split("T")[0]).format('DD-MM-yyyy');
                        }
                    });
                    setStandardList(data);
                    const pageNumbers = Math.ceil(res.count / 10);
                    const pageArray: any = [];
                    for (let i = 1; i <= pageNumbers; i++) {
                        pageArray.push(i);
                    }
                    setPageNumbers(pageArray);
                    setLoading(false);
                }
            }
        });
    }


    const onPageChange = (data: any) => {
        setActivePage(data);
        const pageNumber = data - 1;
        setLoading(true);
        GET_STANDARD_LIST(pageNumber, boardUuid, mediumUuid, preparedSearchData ? preparedSearchData : {}).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res?.err);
                    setLoading(false);
                } else {
                    const data = res.rows;
                    data?.forEach((element: any) => {
                        element.created_dt = moment(element?.created_dt?.split("T")[0]).format('DD-MM-yyyy');
                        if (element?.modified_dt) {
                            element.modified_dt = moment(element?.modified_dt?.split("T")[0]).format('DD-MM-yyyy');
                        }
                    });
                    setStandardList(data);
                    setLoading(false);
                }
            }
        });
    }
    const onDelete = (data: any) => {
        setLoading(true);
        const id = data.uuid;
        DELETE_STANDARD(id).then((res: any) => {
            if (res.status === "fail") {
                toast.error(res?.err);
                setLoading(false);
            } else {
                setLoading(false);
                toast.success(res.message);
                getStandards();
                if (props.mediumUuid && props.updateList) {
                    props.updateList(mediumUuid);
                }
            }
        });

    }
    const onEdit = (data: any) => {
        setStandardId(data?.uuid);
        setFormLoading(false);
        setCodeError('');
        setNameError('');
        setCanShowPopup(true);
        if (data?.uuid) {
            setFormLoading(true);
            GET_STANDARD_BY_UUID(data?.uuid).then((res: any) => {
                if (res.status === 'fail') {
                    toast.error(res?.err);
                    setFormLoading(false);

                } else {
                    if (res.rows.length > 0) {
                        setCurrentFormData(res.rows[0]);
                        setFormLoading(false);

                    }
                }
            });
        }
    }
    const handleClose = () => {
        setFormData({});
        setCurrentFormData({});
        setCanShowPopup(false);
    }
    const addStandard = (data: any) => {
        setFormLoading(false);
        setCodeError('');
        setNameError('');
        setCanShowPopup(true);
        setStandardId(data);
        const datafrom = {
            standard: '',
            display_text: '',
        };
        setFormData({ ...datafrom });
        setCurrentFormData({ ...datafrom });
        standardForm?.current.reset();
    }
    const handleFormInput = (data: any) => {
        data.value = { ...currentFormData, ...data.value };
        setFormData(data);
        const errors: any = FormValidator(FormValidations, data.value);
        setFormValidationErrors(errors);
    };
    const onChangeCode = (event: any) => {
        setCodeError('');

        const reg = /^[0-9]+$/;

        if (event) {
            if (!reg.test(event)) {
                setCodeError('Please enter only numbers');
            } else {
                return true;
            }
        } else {
            setCodeError('Please enter standard number');
        }
    }

    const onChangeName = (event: any) => {
        setNameError('');
        // if (currentFormData) {
        //     currentFormData.name = event;
        // }
        if (event) {
            return true;
        } else {
            setNameError('Please enter standard name');
        }
    }
    const createStandard = () => {

        const formsData = formData.value ? { ...formData.value } : { ...currentFormData };
        setIsFormSubmitted(true);
        const errors: FormControlError[] = FormValidator(FormValidations, formsData);
        setFormValidationErrors(errors);
        Object.keys(formsData).forEach(function (key) {
            if (typeof formsData[key] === 'string') {
                formsData[key] = formsData[key].trim();
            }
        });
        if (onChangeName(formsData?.display_text) && onChangeCode(formsData?.standard)) {
            formsData.board_uuid = boardUuid;
            formsData.medium_uuid = mediumUuid;
            setFormLoading(true);
            CREATE_STANDARD([formsData]).then((res: any) => {
                if (res.status === 'fail') {
                    const errorValue = Array.isArray(res?.err) ? res?.err[0] : res?.err;
                    toast.error(errorValue);
                    setFormLoading(false);
                } else {
                    toast.success(res.message);
                    getStandards();
                    setFormLoading(false);
                    setCanShowPopup(false);
                    setFormData({});
                    setCurrentFormData({});
                    if (props.mediumUuid && props.updateList) {
                        props.updateList(mediumUuid);
                    }
                }
            });
        } else {
            if (!nameError && !onChangeName(formsData?.display_text)) {
                setNameError('Please enter standard name');
            }
            if (!codeError && !onChangeCode(formsData?.standard)) {
                setCodeError('Please enter standard number');
            }

        }
    }
    const updateStandard = () => {
        const formsData = formData.value ? { ...formData.value } : { ...currentFormData };
        setIsFormSubmitted(true);
        const errors: FormControlError[] = FormValidator(FormValidations, formsData);
        const payLoad = {
            medium_uuid: mediumUuid,
            board_uuid: boardUuid,
            display_text: formsData?.display_text,
            standard: formsData?.standard,
            uuid: standardId
        }
        setFormValidationErrors(errors);
        if (onChangeName(formsData?.display_text) && onChangeCode(formsData?.standard)) {

            setFormLoading(true);
            CREATE_STANDARD([payLoad]).then((res: any) => {
                if (res.status === 'fail') {
                    const errorValue = Array.isArray(res?.err) ? res?.err[0] : res?.err;
                    toast.error(errorValue);
                    setFormLoading(false);
                } else {
                    toast.success(res.message);
                    getStandards();
                    setFormLoading(false);
                    setCanShowPopup(false);
                    setFormData({});
                    setCurrentFormData({});
                    if (props.mediumUuid && props.updateList) {
                        props.updateList(mediumUuid);
                    }
                }
            });
        } else {
            if (!nameError && !onChangeName(formsData?.display_text)) {
                setNameError('Please enter standard name');
            }
            if (!codeError && !onChangeCode(formsData?.standard)) {
                setCodeError('Please enter standard number');
            }

        }
    }
    const handleSearchInput = (data: any) => {
        setSearchData(data);
    };
    function handleSearch() {
        const data = { ...searchData.value };
        if (data?.search) {
            setLoading(true);
            for (const key of Object.keys(data)) {
                if (data[key] === "") {
                    delete data[key];
                }
            }
            setPreparedSearchData(data);
            GET_STANDARD_FILTER(data, boardUuid, mediumUuid).then((res: any) => {
                if (res.status === 'fail') {
                    toast.error(res?.err);
                    setLoading(false);
                } else {
                    const data = res.rows;
                    data?.forEach((element: any) => {
                        element.created_dt = moment(element?.created_dt?.split("T")[0]).format('DD-MM-yyyy');
                        if (element?.modified_dt) {
                            element.modified_dt = moment(element?.modified_dt?.split("T")[0]).format('DD-MM-yyyy');
                        }
                    });
                    setActivePage(0);
                    setStandardList(data);
                    const pageNumbers = Math.ceil(res.count / 10);
                    const pageArray: any = [];
                    for (let i = 1; i <= pageNumbers; i++) {
                        pageArray.push(i);
                    }
                    setPageNumbers(pageArray);
                    setLoading(false);
                }
            });
        }
    }
    function handleRefresh() {
        const data = searchData;
        data.value = {};
        setSearchData({ ...data });
        setPreparedSearchData({});
        getStandards();
        searchForm.current.reset();
    }
    const onActions = (data: any) => {
        if (data.type === "link") {

            history.push(
                `/dashboard/module/info/ABHYAS/${moduleCode}/${moduleId}/${boardUuid}/${BoardName}/${MediumUuid}/${MediumName}/${data?.data?.uuid}/${encodeURIComponent(data?.data?.display_text)}/subject`
            );

        }
    };
    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleSearch();
        }
    };
    return (
        <div>
            <div
                className={`modal fade ${canShowPopup ? "show" : "false"}`}
                style={{ display: canShowPopup ? "block" : "none" }}
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                                {standardId === "0" ? "Add Standard" : "Update Standard"}
                            </h5>
                            <button
                                type="button"
                                className="close popup-close cursor-pointer"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={handleClose}
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body parent-info ">
                            {formLoading && (
                                <div className="text-center p-5">
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div>
                            )}
                            {!formLoading && (
                                <>
                                    <FormBuilder onUpdate={handleFormInput}>
                                        <form ref={standardForm}>
                                            <div className="row custom-form">
                                                <div className="col-md-12">
                                                    <div className="mb-4">
                                                        <label className="form-label">Standard  <sup style={{ color: 'red', fontSize: '14px', }}>*</sup></label>
                                                        <input className="form-control form-control-lg f18" type="text" name="standard" defaultValue={currentFormData?.standard} onChange={(e) => onChangeCode(e.target.value)} placeholder="Please enter standard number" />
                                                        {codeError && <p className="text-danger">{codeError}</p>}
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="mb-4">
                                                        <label className="form-label"> Name <sup style={{ color: 'red', fontSize: '14px', }}>*</sup></label>
                                                        <input className="form-control form-control-lg f18" type="text" name="display_text" defaultValue={currentFormData?.display_text} onChange={(e) => onChangeName(e.target.value)} placeholder="Please enter standard name" />
                                                        {nameError && <p className="text-danger">{nameError}</p>}
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </FormBuilder>
                                </>
                            )}
                        </div>
                        <div className="modal-footer">
                            {standardId === "0" && < a className="btn btn-primary btn-sm px-4 rounded-12 cursor-pointer py-2" onClick={() => createStandard()}>Add</a>}
                            {standardId !== "0" && <a className="btn btn-primary btn-sm px-4 rounded-12 cursor-pointer py-2" onClick={() => updateStandard()}>Update</a>}
                            <button
                                type="button"
                                className="btn btn-secondary cursor-pointer btn-sm px-4 rounded-12 cursor-pointer py-2"
                                data-dismiss="modal"
                                onClick={handleClose}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`mt-3 ${props.display === "none" && "d-none"}`}>
                <Breadcum breadcumList={breadcumInfo} />
            </div>

            <div className="row  mt-2" style={{ marginBottom: '15px' }}>
                <div className="col-md-4">
                    <FormBuilder onUpdate={handleSearchInput}>
                        <form ref={searchForm}>
                            <input type="text" id="search" className="form-control ft-14" placeholder="Standard name" name="search" onKeyDown={(e) => handleKeyDown(e)} />
                        </form>
                    </FormBuilder>
                </div>
                <div className="col-md-4 pt-3 pt-md-0 ">
                    <button className="btn btn-primary px-3 cursor-pointer" type="button" onClick={() => handleSearch()}>Search</button>&nbsp;
                    <button className="btn clear-btn cursor-pointer" type="button" onClick={() => handleRefresh()}>
                        Clear
                    </button>
                </div>
                <div className="col-md-4 pt-3 pt-md-0 text-end ">
                    <p className="mb-0"><button className="btn btn-primary" onClick={() => addStandard("0")}>Add Standard</button></p>

                </div>

            </div>
            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading &&
                <div className="mt-3">
                    <DataTable TableCols={AbhyasStandardDataGridCols} tableData={standardList}
                        onActions={(e) => onActions(e)}
                        editInfo={onEdit}
                        deleteInfo={onDelete}
                        pageNumber={onPageChange} activePageNumber={activePage} pageNumbers={pageArray}></DataTable>
                </div>}

        </div>
    )
}