import { DataTableCol } from "../../../components/data-table/types";

export const RolesDataGridCols: DataTableCol[] = [
    {
        title: 'Role Name',
        control: 'role_name',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Role Code',
        control: 'role_code',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Role Description',
        control: 'role_description',
        canShowColumn: true
    },
    {
        title: 'Actions',
        control: 'both',
        isEdit: false,
        isDelete: false,
        canShowColumn: false,
        isPrivileges: false
    },
];
