import React from 'react'
import { Route, Switch } from 'react-router'
import { AdminForm } from '../pages/dashboard/school/admin/form'
import { AdminLIst } from '../pages/dashboard/school/admin/list'
import { AssignParent } from '../pages/dashboard/school/module/assign-parent'
import { AssignUser } from '../pages/dashboard/school/module/assign-user'
import { SchoolModulesList } from '../pages/dashboard/school/module/list'
import { PaymentList } from '../pages/dashboard/school/payment/list'
import { PrincipalForm } from '../pages/dashboard/school/principal/form'
import { PrincipaList } from '../pages/dashboard/school/principal/list'
import { StudentCountList } from '../pages/dashboard/school/student-count/list'
import { StudentsForm } from '../pages/dashboard/school/students/form'
import { StudentsList } from '../pages/dashboard/school/students/list'
import { TeachersForm } from '../pages/dashboard/school/Teachers/form'
import { TeachersList } from '../pages/dashboard/school/Teachers/list'
import { VicePrincipalForm } from '../pages/dashboard/school/vice-principal/form'
import { VicePrincipalList } from '../pages/dashboard/school/vice-principal/list'

const NestedDashboard = () => {
    return (
        <Switch>
            <Route exact path="/dashboard/school/info/:id/adminlist/:schoolCode" component={AdminLIst}></Route>
            <Route path="/dashboard/school/info/:id/adminform/:schoolCode/:userId" component={AdminForm}></Route>
            <Route exact path="/dashboard/school/info/:id/modulelist/:schoolCode" component={SchoolModulesList}></Route>
            <Route exact path="/dashboard/school/info/:id/module/assign-parent/:schoolCode" component={AssignParent}></Route>
            <Route exact path="/dashboard/school/info/:id/module/assign-user/:schoolCode" component={AssignUser}></Route>
            <Route exact path="/dashboard/school/info/:id/student-count-list/:schoolCode" component={StudentCountList}></Route>
            <Route path="/dashboard/school/info/:id/teacherslist/:schoolCode" component={TeachersList}></Route>
            <Route path="/dashboard/school/info/:id/teachersform/:schoolCode/:userId" component={TeachersForm}></Route>
            <Route path="/dashboard/school/info/:id/studentslist/:schoolCode" component={StudentsList}></Route>
            <Route path="/dashboard/school/info/:id/studentsform/:schoolCode/:userId" component={StudentsForm}></Route>
            <Route path="/dashboard/school/info/:id/principallist/:schoolCode" component={PrincipaList}></Route>
            <Route path="/dashboard/school/info/:id/principalform/:schoolCode/:userId" component={PrincipalForm}></Route>
            <Route path="/dashboard/school/info/:id/viceprincipalform/:schoolCode/:userId" component={VicePrincipalForm}></Route>
            <Route path="/dashboard/school/info/:id/viceprincipallist/:schoolCode" component={VicePrincipalList}></Route>
            <Route exact path="/dashboard/school/info/:id/paymentlist/:schoolCode" component={PaymentList}></Route>
        </Switch>
    )
}

export default NestedDashboard