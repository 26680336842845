import React, { useEffect, useState } from 'react'
import { Link, useHistory, useLocation, useParams } from 'react-router-dom'
import { StudentsDataGridCols } from './data-grid-cols'
import { ToastContainer, toast } from 'react-toastify';
import { BULK_UPLOAD_STUDENTS, DELETE_USER, GET_PARENT_BY_CHILDRENS, GET_USER_BY_SCHOOL_CODE } from '../../../../../app/service/users.service';
import { DataTable } from '../../../../../components/data-table';

export const ChildList = () => {
    let { id, schoolCode, studentId } = useParams<{ id: string, schoolCode: string, studentId: string }>();
    const schoolId = parseInt(id);
    const schoolsCode = schoolCode;
    const studentsId = studentId;
    const [pageArray, setPageNumbers] = useState([]);
    const [activePage, setActivePage] = useState<any>();
    const [studentList, setStudentList] = useState<any>([]);
    const [loading, setLoading] = useState(false);
    const search = useLocation().search;
    const currentPageNumber: any = new URLSearchParams(search).get('page');
    const history = useHistory();

    useEffect(() => {
        getSchools();
    }, []);

    function getSchools() {
        const activePageNumber = currentPageNumber > 0 ? (currentPageNumber - 1) : 0;
        if (schoolsCode) {
            setLoading(true);
            GET_PARENT_BY_CHILDRENS(studentsId, activePageNumber).then((res: any) => {
                if (res) {
                    if (res.status === 'fail') {
                        toast.error(res.message);
                        setLoading(false);
                    } else {
                        setActivePage(currentPageNumber ? currentPageNumber : 1);
                        const data = res.rows;
                        data.forEach((element: any) => {
                            element.student_code = studentsId;
                            if (element.type === 'individual') {
                                element.name_of_school = element?.indv_school_details?.school_name;
                            }
                        });
                        setStudentList(data);
                        const pageNumbers = Math.ceil(res.count / 10);
                        const pageArray: any = [];
                        for (let i = 1; i <= pageNumbers; i++) {
                            pageArray.push(i);
                        }
                        setPageNumbers(pageArray);
                        setLoading(false);
                    }
                }
            });
        }
    }

    const onPageChange = (data: any) => {
        if (data) {
            history.push(`/dashboard/parent/info/${schoolId}/childlist/${schoolsCode}/${studentsId}?page=${data}`);
        }
        setActivePage(data);
        const pageNumber = data - 1;
        GET_USER_BY_SCHOOL_CODE(schoolsCode, 'STUDENT', pageNumber, {}).then((res: any) => {
            if (res) {
                const data = res['rows'];
                setStudentList(data);
            }
        });
    }

    const onEditStudent = (data: any) => {
        history.push(`/dashboard/school/info/${schoolId}/studentsform/${schoolsCode}/${data.id}`);
    }

    const onDeleteStudent = (data: any) => {
        setLoading(true);
        const id = data.id;
        DELETE_USER(id).then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res.message);
                setLoading(false);
            } else {
                setLoading(false);
                toast.success("Students Deleted Successfully");
                getSchools();
            }
        });
    }

    return (
        <div>
            <div className="border-primary py-3 text-end">
            </div>
            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading && <DataTable TableCols={StudentsDataGridCols}
                editInfo={onEditStudent} deleteInfo={onDeleteStudent}
                tableData={studentList} pageNumber={onPageChange}
                activePageNumber={activePage} pageNumbers={pageArray}></DataTable>}
        </div>
    )
}
