

import _ from "lodash";



export const returnPaginationRange = (totalPage: any, page: any, limit: number, siblings: number) => {
    let dots = "...";
    let totalPageNoInArray = 7 + siblings;
    if (totalPageNoInArray >= totalPage) {
        _.range(1, totalPage + 1)
    }
    const leftSiblingsIndex = Math.max(page - siblings, 1);
    const rightSiblingsIndex = Math.min(page + siblings, totalPage);

    const showLeftDots = leftSiblingsIndex > 2;
    const showRightDots = rightSiblingsIndex < totalPage - 2;

    if (!showLeftDots && showRightDots) {
        let leftItemsCount = 3 + 2 * siblings;
        let leftRange = _.range(1, leftItemsCount + 1);
        return [...leftRange, "...", totalPage];
    } else if (showLeftDots && !showRightDots) {
        let rightItemsCount = 3 + 2 * siblings;
        let rightRange = _.range(totalPage - rightItemsCount + 1, totalPage + 1);
        return [1, "...", ...rightRange];
    }
    else if (showLeftDots && showRightDots) {
        let middleRange = _.range(leftSiblingsIndex, rightSiblingsIndex);
        return [1, dots, ...middleRange, dots, totalPage];
    }

    else {
        let middleRange = _.range(leftSiblingsIndex, rightSiblingsIndex + 1);
        return [1, "...", ...middleRange, "...", totalPage];
    }

}