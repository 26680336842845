import React, { useState } from "react";

interface Props {
    schoolData: any;

}

export const MathsMlaOneAnalysys = (props: Props) => {
    const [schoolData, setSchoolData] = useState<any>(props.schoolData);

    return (
        <div>
            <h3 className="mt-3 mb-2 section-hd">Students Status For MLA-1 - Total School</h3>
            <div className="row">
                <div className="col-md-6 col-lg accordion-collapse mb-4 dkpi-block">
                    <div className="dkpi-block-content dkpi-green-bg no-hover" >
                        <h3 className="dkpi-hd mb-0"><small className="mb-0">Number of Students</small>Completed</h3>
                        <p className="mt-1 mb-0 dkpi-num">{schoolData?.maths_data && schoolData?.maths_data.complete_data.average_results.mla1['students_completed']}</p>
                    </div>
                </div>
                <div className="col-md-6 col-lg mb-4 dkpi-block">
                    <div className="dkpi-block-content dkpi-blue-bg no-hover">
                        <h3 className="dkpi-hd mb-0"><small className="mb-0">Students</small>Average Cycle</h3>
                        <p className="mt-1 mb-0 dkpi-num">{schoolData?.maths_data && schoolData?.maths_data.complete_data.average_results.mla1['students_average_cycle']}</p>
                    </div>
                </div>
                <div className="col-md-6 col-lg mb-4 dkpi-block">
                    <div className="dkpi-block-content dkpi-yellow-bg no-hover">
                        <h3 className="dkpi-hd mb-0"><small className="mb-0">Number of Students</small>Completed - Test 1</h3>
                        <p className="mt-1 mb-0 dkpi-num">{schoolData?.maths_data && schoolData?.maths_data.complete_data.average_results.mla1['students_completed_test_1']}</p>
                    </div>
                </div>
                <div className="col-md-6 col-lg mb-4 dkpi-block">
                    <div className="dkpi-block-content dkpi-pink-bg no-hover">
                        <h3 className="dkpi-hd mb-0"><small className="mb-0">Number of Students</small>Completed - Test 2</h3>
                        <p className="mt-1 mb-0 dkpi-num">{schoolData?.maths_data && schoolData?.maths_data.complete_data.average_results.mla1['students_completed_test_2']}</p>
                    </div>
                </div>
                <div className="col-md-6 col-lg mb-4 dkpi-block">
                    <div className="dkpi-block-content dkpi-red-bg no-hover">
                        <h3 className="dkpi-hd mb-0"><small className="mb-0">Number of Students</small>Completed - Test 3</h3>
                        <p className="mt-1 mb-0 dkpi-num">{schoolData?.maths_data && schoolData?.maths_data.complete_data.average_results.mla1['students_completed_test_3']}</p>
                    </div>
                </div>
            </div>
            <h3 className="mt-4 mb-2 section-hd">Students Status For MLA-1<small>Class Wise</small></h3>
            <div className="table-responsive">
                <table className="table table_new_ui">
                    <thead>
                        <tr>
                            <th style={{ width: "200px" }}>Status</th>
                            {schoolData?.maths_data && schoolData?.maths_data.class_wise_data.map((data: any, index: number) => {
                                return (
                                    <>
                                        <th style={{ width: "150px" }}>{data.class}<sup>th</sup> Class</th>
                                    </>)
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Students Completed</td>
                            {schoolData?.maths_data && schoolData?.maths_data.class_wise_data.map((data: any, index: number) => {
                                return (
                                    <>
                                        <td>{data.average_results.mla1['students_completed']}</td>

                                    </>)
                            })}
                        </tr>
                        <tr>
                            <td>Students Average Cycle</td>
                            {schoolData?.maths_data && schoolData?.maths_data.class_wise_data.map((data: any, index: number) => {
                                return (
                                    <>
                                        <td>{data.average_results.mla1['students_average_cycle']}</td>

                                    </>)
                            })}
                        </tr>
                        <tr>
                            <td>Students Completed Test 1 </td>
                            {schoolData?.maths_data && schoolData?.maths_data.class_wise_data.map((data: any, index: number) => {
                                return (
                                    <>
                                        <td>{data.average_results.mla1['students_completed_test_1']}</td>
                                    </>)
                            })}
                        </tr>
                        <tr>
                            <td>Students Completed Test 2 </td>
                            {schoolData?.maths_data && schoolData?.maths_data.class_wise_data.map((data: any, index: number) => {
                                return (
                                    <>
                                        <td>{data.average_results.mla1['students_completed_test_2']}</td>
                                    </>)
                            })}
                        </tr>
                        <tr>
                            <td>Students Completed Test 3</td>
                            {schoolData?.maths_data && schoolData?.maths_data.class_wise_data.map((data: any, index: number) => {
                                return (
                                    <>
                                        <td>{data.average_results.mla1['students_completed_test_3']}</td>
                                    </>)
                            })}
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}