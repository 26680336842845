import API from "../utility/axios";
const apiUrl = "/api/v2";

export const GET_ABHYAS_TEST_LIST = (
  pageNumber: number,
  boardUuid: string,
  mediumUuid: string,
  standardUuid: string,
  subjectUuid: string,
  Type: string,
  data: any
): Promise<any> => {
  return API.get(
    `${apiUrl}/abhyaas/pariksha?limit=${10}&page=${pageNumber}&board_uuid=${boardUuid}&medium_uuid=${mediumUuid}&standard_uuid=${standardUuid}&subject_uuid=${subjectUuid}&type=${Type}`,
    {
      params: data,
    }
  );
};
export const GET_ABHYAS_TEST_BY_UUID = (Uuid: string): Promise<any> => {
  return API.get(`${apiUrl}/abhyaas/pariksha?uuid=${Uuid}`);
};

export const UPDATE_ABHYAS_TEST = (data: any): Promise<any> => {
  return API.post(`${apiUrl}/abhyaas/pariksha`, data);
};

export const CREATE_ABHYAS_TEST = (data: any): Promise<any> => {
  return API.post(`${apiUrl}/abhyaas/pariksha`, data);
};

export const GET_ABHYAS_TEST_FILTER = (
  data: any,
  boardUuid: string,
  mediumUuid: string,
  standardUuid: string,
  sujectUuid: string,
  Type: string
): Promise<any> => {
  return API.get(
    `${apiUrl}/abhyaas/pariksha?limit=${10}&page=${0}&board_uuid=${boardUuid}&medium_uuid=${mediumUuid}&standard_uuid=${standardUuid}&subject_uuid=${sujectUuid}&type=${Type}`,
    {
      params: data,
    }
  );
};
export const DELETE_ABHYAS_TEST = (id: number): Promise<any> => {
  return API.delete(`${apiUrl}/abhyaas/pariksha/${id}`);
};

export const GET_ABHYAS_TEST_TEST_LIST = (
  pageNumber: number,

  data: any
): Promise<any> => {
  return API.get(`${apiUrl}/abhyaas/pariksha?limit=${10}&page=${pageNumber}`, {
    params: data,
  });
};

export const GET_ABHYAS_TEST_FILTER_LIST = (
  boardUuid: string,
  mediumUuid: string,
  standardUuid: string,
  subjectUuid: string,
  chapterUuid: string,
  topicUuid: string,
  Type: string,
  data: any
): Promise<any> => {
  return API.get(
    `${apiUrl}/abhyaas/pariksha?board_uuid=${boardUuid}&medium_uuid=${mediumUuid}&standard_uuid=${standardUuid}&subject_uuid=${subjectUuid}&chapter_uuid=${chapterUuid}&topic_uuid=${topicUuid}&type=${Type}`,
    {
      params: data,
    }
  );
};
