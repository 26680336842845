import React, { useEffect, useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom';
import FormBuilder from "../../../components/form-builder";
import { FormControlError, FormField, FormValidators } from '../../../components/form-builder/model/form-field';
import { FormValidator } from '../../../components/form-builder/validations';
import { toast } from 'react-toastify';
import { CREATE_OBJECT, GET_OBJECT_BY_ID, UPDATE_OBJECT } from '../../../app/service/access-object.service';



export const AccesObjectForm = () => {
    const [objectData, setObjectData] = useState<any>({});
    const [currentObjectData, setCurrentObjectData] = useState<any>({});
    const [loading, setLoading] = useState(false);
    const [nameError, setNameError] = useState('');
    const [codeError, setCodeError] = useState('');
    const [descriptionError, setDescriptionError] = useState('');
    const [disabled, setDisabled] = useState(false);
    const history = useHistory();
    let { id } = useParams<{ id: string }>();
    const objectId = parseInt(id);
    const objectFormValidations = [
        new FormField('object_name', [FormValidators.REQUIRED]),
        new FormField('description', [FormValidators.REQUIRED]),
        new FormField('object_code', [FormValidators.REQUIRED]),

    ];

    useEffect(() => {
        if (objectId > 0) {
            setLoading(true);
            GET_OBJECT_BY_ID(objectId).then((res: any) => {

                if (res) {
                    if (res.status === 'fail') {
                        toast.error(res.message);
                        setLoading(false);
                    } else {
                        setLoading(false);
                        setCurrentObjectData(res.rows[0]);
                        setDisabled(!disabled);
                    }
                }
            });
        }
    }, [objectId]);


    function createObject() {
        const objectsData = objectData.value ? { ...objectData.value } : { ...currentObjectData };
        const errors: FormControlError[] = FormValidator(objectFormValidations, objectsData);
        Object.keys(objectsData).forEach(function (key) {
            if (typeof objectsData[key] === 'string') {
                objectsData[key] = objectsData[key].trim();
            }
        });
        if (onChangeName(objectsData.object_name) && onChangeDescription(objectsData.description) && onChangeCode(objectsData.object_code)) {
            setLoading(true);
            objectsData.status = 1;
            CREATE_OBJECT(objectsData).then((res: any) => {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                    setCurrentObjectData(objectsData);
                } else {
                    toast.success("Object Created Successfully");
                    history.push('/dashboard/access-objects');
                    setLoading(false);
                }
            });
        } else {
            if (!nameError && !onChangeName(objectsData.object_name)) {
                setNameError('Please enter object name');
            }
            if (!descriptionError && !onChangeDescription(objectsData.description)) {
                setDescriptionError('Please enter object description');
            }
            if (!codeError && !onChangeCode(objectsData.object_code)) {
                setCodeError('Please enter object code');
            }

        }
    }


    function updateObject() {
        const objectsData = objectData.value ? { ...objectData.value } : { ...currentObjectData };
        const errors: FormControlError[] = FormValidator(objectFormValidations, objectsData);
        delete objectsData.id;

        if (onChangeName(objectsData.object_name) && onChangeDescription(objectsData.description) && onChangeCode(objectsData.object_code)) {
            setLoading(true);
            objectsData.status = 1;
            UPDATE_OBJECT(objectsData).then((res: any) => {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    toast.success("Object Updated Successfully");
                    history.push('/dashboard/access-objects');
                    setLoading(false);
                }
            });
        } else {
            if (!nameError && !onChangeName(objectsData.object_name)) {
                setNameError('Please enter object name');
            }
            if (!descriptionError && !onChangeDescription(objectsData.description)) {
                setDescriptionError('Please enter object description');
            }
            if (!codeError && !onChangeCode(objectsData.object_code)) {
                setCodeError('Please enter object code');
            }
        }
    }

    const handleObjectInput = (data: any) => {
        data.value = { ...currentObjectData, ...data.value };
        setObjectData(data);
        const errors: any = FormValidator(objectFormValidations, data.value);
    };


    const onChangeName = (event: any) => {
        setNameError('');
        const reg = /^[A-Za-z0-9'? ,_-]+$/;

        if (event) {
            if (!reg.test(event.trim())) {
                setNameError('Object name can`t contain numbers and special characters');
            } else {
                return true;
            }
        } else {
            setNameError('Please enter object name');
        }
    }

    const onChangeCode = (event: any) => {
        setCodeError('');
        const reg = /^[A-Z0-9?,_-]+$/;

        if (event) {
            if (!reg.test(event.trim())) {
                setCodeError('Object code can contain only Capital letters and Without Space');
            } else {
                return true;
            }
        } else {
            setCodeError('Please enter object code');
        }
    }

    const onChangeDescription = (event: any) => {
        setDescriptionError('');
        if (event) {
            if (event.trim().length <= 2) {
                setDescriptionError('Object description minimum 3 characters');
            } else if (event.trim().length >= 256) {
                setDescriptionError('Object description maximum 256 characters');
            } else {
                return true;
            }
        } else {
            setDescriptionError('Please enter object description');
        }
    }
    return (
        <div>
            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }

            {!loading && <div>
                <div className="row border-top border-primary py-3">
                    {objectId === 0 && <h2>Create Object</h2>}
                    {objectId > 0 && <h2>Update Object</h2>}
                </div>
                <FormBuilder onUpdate={handleObjectInput}>
                    <form>
                        <div className="row custom-form">
                            <div className="col-md-4">
                                <div className="mb-4">
                                    <label className="form-label">Object Name</label>
                                    <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>*</span>
                                    <input className="form-control form-control-lg" type="text" name="object_name" onChange={(e) => onChangeName(e.target.value)} defaultValue={currentObjectData.object_name} placeholder="Please enter object name" />
                                    {nameError && <p className="text-danger">{nameError}</p>}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="mb-4">
                                    <label className="form-label">Object Code</label>
                                    <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>*</span>
                                    <input className="form-control form-control-lg" type="text" name="object_code" onChange={(e) => onChangeCode(e.target.value)} defaultValue={currentObjectData.object_code} placeholder="Please enter object code" disabled={disabled} style={{ backgroundColor: disabled ? 'hsl(0,0%,96.5%)' : 'none' }} />
                                    {codeError && <p className="text-danger">{codeError}</p>}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="mb-4">
                                    <label className="form-label">Object Description</label>
                                    <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>*</span>
                                    <br />
                                    <input className="form-control form-control-lg" type="text" name="description" onChange={(e) => onChangeDescription(e.target.value)} defaultValue={currentObjectData.description} placeholder="Please enter object description" />
                                    {descriptionError && <p className="text-danger">{descriptionError}</p>}

                                </div>
                            </div>
                        </div>
                    </form>
                </FormBuilder>
                <div className="form-footer border-top border-primary py-3 text-end mt-4">
                    {objectId === 0 && < a className="btn btn-primary btn-sm px-5 rounded-12 cursor-pointer" onClick={() => createObject()}>Create</a>}
                    {objectId > 0 && <a className="btn btn-primary btn-sm px-5 rounded-12 cursor-pointer" onClick={() => updateObject()}>Update</a>}
                    <Link className="text-dark text-decoration-none ms-2" to={`/dashboard/access-objects`}>Cancel</Link>
                </div>

            </div>}
        </div>
    )
}
