import API from "../utility/axios";
const apiUrl = "/api/v1";
const newApiUrl = "/api/v2";

export const GET_PROMOTIONS_DATA = (pageNumber: number): Promise<any> => {
  return API.get(
    `${newApiUrl}/promotions/fetch?page=${pageNumber}&limit=${10}`
  );
};
export const GET_PROMOTIONS_LIST = (): Promise<any> => {
  return API.get(`${newApiUrl}/promotions/fetch`);
};

export const GET_PROMOTIONS_BY_UUID = (Id: any): Promise<any> => {
  return API.get(`${newApiUrl}/promotions/fetch?uuid=${Id}`);
};

export const CREATE_PROMOTIONS = (data: any): Promise<any> => {
  return API.post(`${newApiUrl}/promotions/create`, data);
};

export const UPDATE_PROMOTIONS = (Uuid: any, data: any): Promise<any> => {
  return API.put(`${newApiUrl}/promotions/update/${Uuid}`, data);
};
export const DELETE_PROMOTIONS = (Uuid: any): Promise<any> => {
  return API.delete(`${newApiUrl}/promotions/delete/${Uuid}`);
};
export const FECTH_FILTER_PROMOTION_DATA_LIST = (
  pageNumber: number,
  data: any
): Promise<any> => {
  return API.get(
    `${newApiUrl}/promotions/fetch?page=${pageNumber}&limit=${10}`,
    {
      params: data,
    }
  );
};
export const FECTH_PROMOCODES_BY_PROMOTIONS_FILTERS = (
  pageNumber: number,
  data: any
) => {
  return API.get(
    `${newApiUrl}/promotions/fetch?page=${pageNumber}&limit=${10}`,
    {
      params: data,
    }
  );
};

// Pomo Codes End Points

export const GET_PROMO_CODES_LIST_DATA = (pageNumber: number): Promise<any> => {
  return API.get(
    `${newApiUrl}/promocodes/fetch?page=${pageNumber}&limit=${10}`
  );
};
export const GET_PROMO_CODES_BY_UUID = (Id: any): Promise<any> => {
  return API.get(`${newApiUrl}/promocodes/fetch?uuid=${Id}`);
};
export const GET_PROMO_CODES_BY_PROMOTION_UUID_PAGE_NUMBER = (
  Id: any,
  pageNumber: number
): Promise<any> => {
  return API.get(
    `${newApiUrl}/promocodes/fetch?promotion_uuid=${Id}&page=${pageNumber}&limit=${10}`
  );
};

export const CREATE_PROMO_CODES = (data: any): Promise<any> => {
  return API.post(`${newApiUrl}/promocodes/create`, data);
};

export const UPDATE_PROMO_CODES = (Uuid: any, data: any): Promise<any> => {
  return API.put(`${newApiUrl}/promocodes/update/${Uuid}`, data);
};

export const DELETE_PROMO_CODES_DATA = (Uuid: any): Promise<any> => {
  return API.delete(`${newApiUrl}/promocodes/delete/${Uuid}`);
};

//  Apply Coupon or Promo Code
export const APPLY_COUPON_CODE = (data: any): Promise<any> => {
  return API.post(`${newApiUrl}/apply/promocode`, data);
};

export const UPDATE_APPLIED_COUPON_CODE = (data: any): Promise<any> => {
  return API.post(`${newApiUrl}/promocodes/applied/create`, data);
};

// Coupon Code Report end points

export const GET_PROMOTION_WITH_COUPON_REPORT_LIST = (
  pageNumber: number
): Promise<any> => {
  return API.get(`${newApiUrl}/coupons/report?page=${pageNumber}&limit=${10}`);
};

export const GET_COUPON_CODES_REPORT_BY_PROMOTION_UUID_PAGE_NUMBER = (
  Id: any,
  pageNumber: number
): Promise<any> => {
  return API.get(
    `${newApiUrl}/coupons/report?promotion_uuid=${Id}&page=${pageNumber}&limit=${10}`
  );
};

export const FECTH_FILTER_PROMOTION_WITH_COUPON_REPORT_LIST = (
  pageNumber: number,
  data: any
): Promise<any> => {
  return API.get(`${newApiUrl}/coupons/report?page=${pageNumber}&limit=${10}`, {
    params: data,
  });
};
