import API from "../utility/axios";
const apiUrl = "/api/v2";

export const GET_MEDIUM_LIST = (
  pageNumber: number,
  boardUuid: string,
  data: any
): Promise<any> => {
  return API.get(
    `${apiUrl}/abhyaas/mediums?limit=${10}&page=${pageNumber}&board_uuid=${boardUuid}`,
    {
      params: data,
    }
  );
};
export const GET_MEDIUM_BY_UUID = (Uuid: string): Promise<any> => {
  return API.get(`${apiUrl}/abhyaas/mediums?uuid=${Uuid}`);
};

export const UPDATE_MEDIUM = (data: any, Uuid: string): Promise<any> => {
  return API.post(`${apiUrl}/abhyaas/mediums/${Uuid}`, data);
};

export const CREATE_MEDIUM = (data: any): Promise<any> => {
  return API.post(`${apiUrl}/abhyaas/mediums`, data);
};

export const GET_MEDIUM_FILTER = (
  data: any,
  boardUuid: string
): Promise<any> => {
  return API.get(
    `${apiUrl}/abhyaas/mediums?limit=${10}&page=${0}&board_uuid=${boardUuid}`,
    {
      params: data,
    }
  );
};
export const DELETE_MEDIUM = (Uuid: number): Promise<any> => {
  return API.delete(`${apiUrl}/abhyaas/mediums/${Uuid}`);
};
