import React, { useEffect, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import { Breadcum } from "../../../../components/breadcum";
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { GET_MODULES_BY_SCHOOL_ID } from '../../../../app/service/module.service';
import { GET_SCHOOL_BY_ID } from "../../../../app/service/school.service";


function InitiativesList() {
    const userRole = localStorage.getItem("user_role");
    const [activePage, setActivePage] = useState<any>(1);
    let { id, schoolCode } = useParams<{ id: string, schoolCode: string }>();
    const schoolId = id;
    const schoolsCode = schoolCode;
    const search = useLocation().search;
    const currentPageNumber: any = new URLSearchParams(search).get("page");
    const [pageNumbers, setPageNumbers] = useState<any>([]);
    const [perpage, setPerpage] = useState<any>([]);
    const [moduleList, setModuleList] = useState<any>([]);
    const [loading, setLoading] = useState(false);
    const [breadcumInfo, setBreadcumInfo] = useState<any>([
        {
            label: "Dashboard",
            value: "Dashboard",
            routerLink: "/school-dashboard/view",
            isActive: false,
        },
        {
            label: "School Name",
            value: "",
            routerLink: "",
            isActive: false,
        },
    ]);
    const history = useHistory();

    useEffect(() => {
        initiativeListdetails(schoolsCode);
        for (let i = 0; i < breadcumInfo.length; i++) {
            const element = breadcumInfo[i];
            element.routerLink = "/school-dashboard/view";


        }

    }, []);

    const initiativeListdetails = (data: string) => {
        const activePageNumber = currentPageNumber > 0 ? (currentPageNumber - 1) : 0;

        setLoading(true);
        GET_MODULES_BY_SCHOOL_ID(data).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    if (res) {
                        const data = res;
                        getBreadcumList(data);
                        setModuleList(data.rows);
                        const pageNumbers = Math.ceil(res.count / 10);
                        const pageArray: any = [];
                        for (let i = 1; i <= pageNumbers; i++) {
                            pageArray.push(i);
                        }
                        setPageNumbers(pageArray);
                        setActivePage(data);
                        setActivePage(currentPageNumber ? currentPageNumber : 1);

                    }
                    setLoading(false);
                }
            }
        });
    }
    function getBreadcumList(data: any) {
        breadcumInfo.splice(1, breadcumInfo.length - 1, {
            label: "Registered School",
            value: data.name_of_school,
            isActive: true,
        });
        setBreadcumInfo(breadcumInfo);
    }
    function onClickPage(number: number) {
        setActivePage(number);
        onPageChange(number);
    }
    function onClickPreviousPage() {
        setActivePage(activePage - 1);
        onPageChange(activePage - 1);

    }

    function onClickNextPage() {
        setActivePage(activePage + 1);
        onPageChange(activePage + 1);

    }
    const onPageChange = (data: any) => {
        setActivePage(data);
    }

    return (
        <div >
            {loading &&
                <div className="text-center p-5 mt-5">
                    <div className="spinner-border mt-5" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading &&
                <div>
                    <div className="d-flex flex-wrap flex-md-nowrap justify-content-between ">
                        <h3 className="d-lg-none" >Dashboard</h3>
                    </div>
                    <div className="content-body">
                        <div className="container-fluid">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb border-bottom ps-0 pt-0 pb-2" style={{ letterSpacing: "0" }}>
                                    {userRole === "SUPER_ADMIN" && <li className="breadcrumb-item mt-3"><Link to='/dashboard/view/instituteinfo' style={{ color: '#0d6efd' }}>Dashboard</Link> / <Link to='/dashboard/view/initiatives-opted' style={{ color: '#0d6efd' }}>{schoolId}</Link> / <span>Module List</span></li>}
                                    {userRole !== "SUPER_ADMIN" && <li className="breadcrumb-item"><Link to='/institute-dashboard/dashboard' style={{ color: '#0d6efd' }}>Dashboard</Link> / <Link to='/institute-dashboard/dashboard/initiatives-opted' style={{ color: '#0d6efd' }}>{schoolId}</Link> / <span>Module List</span></li>}
                                </ol>
                            </nav>

                            <div>
                                <div className='table-responsive'>
                                    <table className='table table_new_ui'>
                                        <thead>
                                            <tr>
                                                <th style={{ width: "120px" }}>Module Code</th>
                                                <th style={{ width: '120px' }}>Sub Module</th>
                                                <th style={{ width: '100px' }}> Target Standard </th>
                                                <th style={{ width: '100px' }}> Version</th>
                                                <th style={{ width: "100px" }}>	Amount</th>
                                                <th style={{ width: '100px' }}>Date	</th>
                                            </tr>
                                        </thead>
                                        <tbody >
                                            {moduleList.map((data: any, i: number) => {
                                                return (<tr key={i}>
                                                    <td>{data.module_code}</td>
                                                    <td>{data.sub_module}</td>
                                                    <td>{data.target_std}</td>
                                                    <td>{data.module_ver}</td>
                                                    <td>{data.amount}</td>
                                                    <td>{data.created_at}</td>
                                                </tr>
                                                )
                                            })}

                                        </tbody>
                                    </table>
                                </div>
                                <div>

                                    {
                                        moduleList.length > 0 && (
                                            <nav className="mt-3" style={{ float: "right" }}>
                                                {pageNumbers && (
                                                    <ul className="pagination">
                                                        <li className="page-item">
                                                            <button className={`page-link prev ${activePage == 1 ? "disabled" : ""}`} onClick={() => onClickPreviousPage()}>
                                                                Previous
                                                            </button>
                                                        </li>
                                                        {pageNumbers &&
                                                            pageNumbers.map((number: number, i: number) => (
                                                                <li className={activePage == number ? "active page-item" : "page-item"} key={i}>
                                                                    <button className="page-link" onClick={() => onClickPage(number)}>
                                                                        {number}
                                                                    </button>
                                                                </li>
                                                            ))}
                                                        <li className="page-item">
                                                            <button className={`page-link next ${activePage == pageNumbers.length ? "disabled" : ""}`} onClick={() => onClickNextPage()}>
                                                                Next
                                                            </button>
                                                        </li>
                                                    </ul>
                                                )}
                                            </nav>
                                        )
                                    }
                                </div>
                                {moduleList.length === 0 && <p className='mt-2 text-center f14'>No Data Available</p>}
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    )

}

export default InitiativesList;