import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { Link, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { GET_MNS_DETAILS, GET_MNS_STUDENT_STATUS } from '../../../../app/service/mns-service';

export const MnsTestLevelStatus = () => {
    const [assesmentTestStatus, setAssesmentTestStatus] = useState<any>();
    const [MNSDetails, setMNSDetails] = useState<any>([]);
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    let { moduleUUID, userCode, schoolCode } = useParams<{ moduleUUID: string, userCode: string, schoolCode: string }>();
    const modules_uuid = moduleUUID;
    const user_code = userCode;
    const school_code = schoolCode;

    useEffect(() => {
        getMNSStudentStatus();
        getMNSDetails();
    }, []);

    function getMNSDetails() {
        GET_MNS_DETAILS().then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res.message);
            } else {
                if (res) {
                    const data = res;
                    setMNSDetails(data);
                }
            }
        });
    }

    function getMNSStudentStatus() {
        let payload = {
            module_uuid: modules_uuid,
            school_code: school_code,
            user_code: user_code
        }
        setLoading(true);
        GET_MNS_STUDENT_STATUS(payload).then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res.message);
                setLoading(false);
            } else {
                if (res) {
                    const data = res;
                    let status = Array.isArray(data.assesment_test) ? data.assesment_test.length > 0 : Object.keys(data.assesment_test).length !== 0;
                    if (status) {
                        history.push(`/student-dashboard/list/mns/view/${modules_uuid}/${user_code}/${school_code}`);
                    } else {
                        history.push(`/student-dashboard/list/mns/test-level/${modules_uuid}/${user_code}/${school_code}`);
                    }
                    setAssesmentTestStatus(status);
                    setLoading(false);
                }
            }
        });
    }

    function onClickEntryTest() {
        history.push(`/student-dashboard/list/mns/questions/${modules_uuid}/${user_code}/${school_code}`);
    }

    const handleBack = () => {
        history.goBack();
    }

    return (
        <div>
            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading &&
                <div>

                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb pb-2 ps-0 pt-0 border-bottom">
                            <li className="breadcrumb-item "><Link to="/student-dashboard/list"><span className='pe-2'>Dashboard</span></Link></li> /
                            <li className="breadcrumb-item "><span>Maths Entry Test</span></li>
                        </ol>
                    </nav>
                    <div className="mt-3">
                        <h2 className="f22 fw-bold">
                            MNS <span className="fw-light">(Minimum Numerical Skills)</span>
                        </h2>
                        <h3 className="fw-medium f14 mt-3 m-0">Introduction</h3>
                        <p className="fw-light f14 m-0">About MNS</p>
                        <h3 className="fw-medium f14 mt-3 mb-0">Instructions</h3>
                        <p className="fw-light f14 m-0">
                            Details on how to navigate MNS assessments
                        </p>
                        <div className='pt-4 pb-1'>
                            {MNSDetails.map((detail: any, i: number) => (
                                <p key={i}>{i + 1}.{detail}</p>
                            ))}
                        </div>
                        <p className="mt-3">
                            <button className="btn btn-primary mb-3 px-5" onClick={() => { onClickEntryTest() }} >Start Test</button>
                        </p>
                    </div>
                </div>
            }
        </div>
    )
}