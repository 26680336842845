import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import rightArrowImg from '../../../../assets/images/Arrow-Right.png';
import downArrowImg from '../../../../assets/images/Arrow-Down.png';
import badgeImg from '../../../../assets/images/Badge.png';
import closeIcon from '../../../../assets/images/close_icon.png';
import { GET_MLA_TWO_COMPLETE_INFO, GET_MLA_TWO_DETAILS } from '../../../../app/service/mla-two.service';

export const MLATwoCard = (props: any) => {
    const [assesmentTestStatus, setAssesmentTestStatus] = useState<any>();
    const [MNSDetails, setMNSDetails] = useState<any>([]);
    const [attemptLevels, setAttemptLevels] = useState<any>([1, 2, 3]);
    const [badgeLevels, setBadgeLevels] = useState<any>([1, 2, 3]);
    const [MLADetails, setMLADetails] = useState<any>([]);
    const [topicResult, setTopicResult] = useState<any>([]);
    const [topics, setTopics] = useState<any>([]);
    // const [mlaInfo, setMlaInfo] = useState<any>(null);
    const [priorityItems, setPriorityItems] = useState<any>(null);
    const [canShowSubItems, setShowSubItems] = useState(false);
    const [canShowPopup, setCanShowPopup] = useState(false);
    const [currentCycleInfo, setCurrentCycleInfo] = useState<any>(null);
    const [previousCycleInfo, setPreviousCycleInfo] = useState<any>(null);
    const [cycleInfo, setCycleInfo] = useState<any>([]);

    const history = useHistory();
    let { moduleUUID, userCode, schoolCode } = useParams<{ moduleUUID: string, userCode: string, schoolCode: string }>();
    const modules_uuid = moduleUUID;
    const user_code = userCode;
    const school_code = schoolCode;

    useEffect(() => {
        getMLATwoDetails();
        getMLATwoCompleteInfo();
    }, []);

    function getMLATwoDetails() {
        GET_MLA_TWO_DETAILS().then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res.message);
            } else {
                if (res) {
                    const data = res;
                    if (data.details.length > 0) {
                        setMLADetails(data.details);
                        let topicList = [];

                        for (let i = 0; i < data.abilities.length; i++) {
                            topicList.push(data.abilities[i].topic);
                        }

                        topicList = topicList.filter(function (item, index, inputArray) {
                            return inputArray.indexOf(item) == index;
                        });

                        setTopics(topicList);
                    }
                }
            }
        });
    }


    function getMLATwoCompleteInfo() {
        let payload = {
            "module_uuid": modules_uuid,
            "school_code": school_code,
            "user_code": user_code,
            "cycle_no": ''
        }

        GET_MLA_TWO_COMPLETE_INFO(payload).then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res.message);
            } else {
                if (res) {
                    const data = res;
                    setCurrentCycleInfo(data);

                    let status = data.tests.find((x: any) => x.status === 'completed');
                    if (data.cycle_no > 1 && !status) {
                        getRequestedCycleData(data.cycle_no - 1);
                    }
                    if (data && data.priorities) {
                        data.priorities = data.priorities.map((item: any, index: number) => {
                            item.isSelected = false;
                            return item;
                        })
                        setPriorityItems(data.priorities);
                    }
                }
            }
        });
    }

    function getRequestedCycleData(cycle_no: any) {
        let payload = {
            "module_uuid": modules_uuid,
            "school_code": school_code,
            "user_code": user_code,
            "cycle_no": cycle_no ? cycle_no : ''
        }

        GET_MLA_TWO_COMPLETE_INFO(payload).then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res.message);
            } else {
                if (res) {
                    const data = res;
                    setPreviousCycleInfo(data);

                    if (data && data.priorities) {
                        data.priorities = data.priorities.map((item: any, index: number) => {
                            item.isSelected = false;
                            return item;
                        })
                        setPriorityItems(data.priorities);
                    }
                }
            }
        });
    }


    function onClickMLATwoInfo() {
        setShowSubItems(!canShowSubItems);
    }

    const getMlaTestCompletedStatus = (cycle_info: any) => {
        if (cycle_info && cycle_info.tests) {
            let isCompleted = true;
            for (let i = 0; i < cycle_info.tests.length; i++) {
                if (cycle_info.tests[i].status === 'pending') {
                    isCompleted = false;
                }
            }
            return isCompleted
        } else {
            return false;
        }

    }

    const getCompletedCycleInfoToShow = () => {
        if (currentCycleInfo && previousCycleInfo && currentCycleInfo.tests_status === 'pending'
            && previousCycleInfo.tests_status === 'completed') {
            return previousCycleInfo;
        } else {
            return currentCycleInfo;
        }
    }


    function onClickAttemptsInfo(cycleInfo: any) {
        if (getMlaTestCompletedStatus(cycleInfo)) {
            history.push(`/dashboard/mla-two/ability-result/${modules_uuid}/${user_code}/${school_code}`);
        } else if (cycleInfo && cycleInfo.tests) {
            for (let i = 0; i < cycleInfo.tests.length; i++) {
                if (cycleInfo.tests[i].status === 'pending') {
                    localStorage.setItem('mla_two_test_info', JSON.stringify(cycleInfo.tests[i]));
                    localStorage.setItem('mla_two_type', 'TESTS');
                    localStorage.setItem('mla_two_cycle_no', cycleInfo.cycle_no);
                    localStorage.setItem('mla_two_test_start_date', cycleInfo.test_start_date);
                    history.push(`/dashboard/mla-two/test-level-status/${modules_uuid}/${user_code}/${school_code}`);
                    break;
                }
            }
        }
    }

    const canShowPriorityView = () => {
        return priorityItems.find((x: any) => x.abilities.length > 0);
    }

    const onClickPracticeInfo = (item: any, index: number) => {
        let items = [...priorityItems];
        items[index].isSelected = !item.isSelected;
        setPriorityItems([...items]);
    }



    const onClickLevelsInfo = (priorty: any, index: number, ability: any, j: number) => {
        let items = [...priorityItems];
        items[index].abilities[j].isSelected = !ability.isSelected;
        setPriorityItems([...items]);
    }

    const onWorkSheetClick = (ability: any, item: any, levelInfo: any, priority: any) => {

        let info = item;
        info.ability = ability.ability;
        info.priority = priority;
        info.name = ability.name;
        info.level = levelInfo.level;
        // delete info.priority;
        if (priority > 1 && priorityItems[priority - 2].status === 'pending') {
            return;
        }

        if (item.status === 'pending') {
            const testInfo = info;
            localStorage.setItem('mla_two_test_info', JSON.stringify(testInfo));
            localStorage.setItem('mla_two_type', 'WORKSHEETS');
            localStorage.setItem('mla_two_worksheets', JSON.stringify(levelInfo.worksheets));
            localStorage.setItem('mla_two_cycle_no', currentCycleInfo.cycle_no);
            history.push(`/dashboard/mla-two/questions/${modules_uuid}/${user_code}/${school_code}`);
            // history.push(`/dashboard/mla-two/mobile/questions/${modules_uuid}/${user_code}/${school_code}`);
        }
    }

    const onClickTopic = (item: any) => {

        if (currentCycleInfo.test_results.length == 0) {
            return;
        }

        let result = currentCycleInfo.test_results;

        let data = result.filter((x: any, i: number) => x.topic == item);

        setTopicResult(data);
        setCanShowPopup(true);
    }

    const handleclose = () => {
        setCanShowPopup(false);
    }

    const getFieldColor = (value: any) => {
        if (value === 0) {
            return 'red';
        } else if (value === 1 || value === 2) {
            return '#ED7D31';
        } else if (value === 3) {
            return '#8cc63f';
        } else {
            return '#333333';
        }
    }

    const renderMLATestCard = (cycleInfo: any) => {
        return (
            <div className="card info-card-2 shadow  mb-5 rounded mb-4 cursor-pointer" onClick={() => { onClickAttemptsInfo(cycleInfo) }}>
                <img className="card-img-top" />
                <div className="card-body p-3">
                    <div className='row'>
                        <div className='col-md-4 px-3'>
                            <div className="">
                                <h3 className='m-0'>MLA Tests</h3>
                                <div className='m-0'>{getMlaTestCompletedStatus(cycleInfo) ? 'Completed' : 'Number of Tests'}</div>
                                {cycleInfo && cycleInfo.tests.map((item: any, i: number) => (
                                    <div key={i} className="circle" style={{ backgroundColor: item.status === 'completed' ? '#8CC63F' : '#FFFFFF' }}>
                                        <p>{item.test_no}</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className='col-md-8'>
                            {!getMlaTestCompletedStatus(cycleInfo) ? <div className='row col-md-4' style={{ float: 'right' }}>
                                <img src={rightArrowImg} style={{ width: '70%', height: '70%', objectFit: 'contain' }} />
                            </div> :
                                <div className='row'>
                                    {
                                        badgeLevels.map((item: any, index: number) => (
                                            <div className='col-md-4' key={index}>
                                                <img src={badgeImg} />
                                            </div>
                                        ))
                                    }
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const renderRandomTestView = () => {
        return (
            <div>
                <div style={{ marginTop: '10px' }}>Random New Test</div>
                {renderMLATestCard(currentCycleInfo)}
            </div>
        )
    }

    function getSolvedWorkSheetNumbers(count: any) {
        if (count.length > 0) {
            return count[0].worksheets;
        } else {
            return '';
        }
    }

    return (
        <div>
            <div className={`modal fade ${canShowPopup ? 'show' : 'false'}`} style={{ display: (canShowPopup ? "block" : 'none') }} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog mla-reports-model-dialog" role="document">
                    <div className="modal-content topic-result-model">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">{topicResult && topicResult.length > 0 ? topicResult[0].topic : ''}</h5>
                            <div>
                                <img src={closeIcon} style={{ width: '50%', height: '50%', objectFit: 'contain', cursor: 'pointer' }} onClick={handleclose} />
                            </div>
                        </div>
                        <div className="modal-body">
                            <div className="mt-3 custom-scroll table-responsive">
                                <table className="table ability-table mb-0">
                                    <thead>
                                        <tr>
                                            <th style={{ minWidth: '200px' }}>Ability</th>
                                            <th>Test1 marks</th>
                                            <th>Test2 marks</th>
                                            <th>Test3 marks</th>
                                            <th>Combine Score</th>
                                            <th>Solved worksheet</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {topicResult && topicResult.map((item: any, index: number) => (
                                            <tr key={index}>
                                                <td style={{ minWidth: '200px' }}>{item.name}</td>
                                                <td style={{ fontSize: 11, fontWeight: '600', color: getFieldColor(item.test_1) }}>{item.test_1}</td>
                                                <td style={{ fontSize: 11, fontWeight: '600', color: getFieldColor(item.test_2) }}>{item.test_2}</td>
                                                <td style={{ fontSize: 11, fontWeight: '600', color: getFieldColor(item.test_3) }}>{item.test_3}</td>
                                                <td style={{ fontSize: 11, fontWeight: '600', color: getFieldColor(item.average) }}>{item.average}</td>
                                                {/* <td style={{ fontSize: 11, fontWeight: '600', color: getFieldColor(item.worksheets) }}>{item.worksheets}</td> */}
                                                <td style={{ fontSize: 11, fontWeight: '600', color: getFieldColor(getSolvedWorkSheetNumbers(item.worksheets)) }}>{getSolvedWorkSheetNumbers(item.worksheets)}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card info-card-2 shadow  mb-5 rounded mb-4 cursor-pointer">
                <img className="card-img-top" />
                <div className="card-body p-3">
                    <div className='row'>
                        <div className='col-md-9 px-3'>
                            <div className="">
                                <h3>MLA-2</h3>
                                <h6>Minimum Learning Ability</h6>
                            </div>
                        </div>
                        <div className='col-md-3'>
                            <div className=''>
                                <img className='cursor-pointer' src={canShowSubItems ? downArrowImg : rightArrowImg}
                                    style={{ width: canShowSubItems ? '80%' : '50%', height: canShowSubItems ? '80%' : '50%', objectFit: 'contain' }}
                                    onClick={() => { onClickMLATwoInfo() }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {canShowSubItems &&
                <div>
                    <div className='pt-2 m-4'>
                        {topics.map((item: any, i: number) => (
                            <button key={i} className='mla-ability-btn mt-2' onClick={() => { onClickTopic(item) }}>{item}</button>
                        ))}
                    </div>
                    {currentCycleInfo && canShowSubItems && renderMLATestCard(getCompletedCycleInfoToShow())}

                    {currentCycleInfo && canShowSubItems && priorityItems && priorityItems.length > 0
                        && canShowPriorityView() &&
                        <div>
                            <h2 className='mt-2 mb-2'>Practice</h2>
                            {priorityItems.map((item: any, index: number) => (
                                <React.Fragment key={index}>
                                    <div className="card info-card-2 shadow  mb-5 rounded mb-4 cursor-pointer" onClick={() => { onClickPracticeInfo(item, index) }}>
                                        <img className="card-img-top" />
                                        <div className="card-body p-3">
                                            <div className='row'>
                                                <div className='col-md-6 px-3'>
                                                    <div className="">
                                                        <h3>Priority {index + 1}</h3>
                                                        <h6>{item.abilities.length}{item.abilities.length < 2 ? ' Ability is in ' : ' Abilities are in '} practice</h6>
                                                    </div>
                                                </div>
                                                <div className='col-md-6'>
                                                    <div className='row'>
                                                        {item.status === 'completed' && <div className='col-md-6'>
                                                            <img src={badgeImg} />
                                                        </div>}
                                                        {item.status !== 'completed' && <div className='col-md-6'>
                                                        </div>}
                                                        <div className='col-md-6'>
                                                            <img className='cursor-pointer' src={item.isSelected ? downArrowImg : rightArrowImg}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {item.isSelected &&
                                        <div>
                                            {item.abilities && item.abilities.map((ability: any, j: number) => (
                                                <React.Fragment key={j}>
                                                    <div className="card info-card-2 shadow rounded mb-3 cursor-pointer" onClick={() => { onClickLevelsInfo(item, index, ability, j) }}>
                                                        <img className="card-img-top" />
                                                        <div className="card-body p-3">
                                                            <div className='row'>
                                                                <div className='col-md-6 px-3'>
                                                                    <h3 className='m-0'>{ability.name}</h3>
                                                                    <div className='m-0' style={{ fontSize: '12px', fontStyle: 'italic' }}>Levels</div>
                                                                </div>
                                                                <div className='col-md-6'>
                                                                    <div className='row'>
                                                                        {ability.status === 'completed' && <div className='col-md-6'>
                                                                            <img src={badgeImg} />
                                                                        </div>}
                                                                        {ability.status !== 'completed' && <div className='col-md-6'>
                                                                        </div>}
                                                                        <div className='col-md-6'>
                                                                            <img className='cursor-pointer' src={ability.isSelected ? downArrowImg : rightArrowImg}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {ability.isSelected && <div className='worksheet-cards  rounded mb-5 cursor-pointer'>
                                                        {ability.worksheet_levels && ability.worksheet_levels.map((levels: any, k: number) => (
                                                            <div key={k} className="pt-4">
                                                                <div style={{ fontSize: '24px', fontWeight: 'bold' }}>Level {levels.level.toString().toUpperCase()}</div>
                                                                <div style={{ fontSize: '12px' }}>Worksheets</div>
                                                                {levels.worksheets && levels.worksheets.map((sheet: any, l: number) => (
                                                                    <div key={l} className="circle" style={{ backgroundColor: sheet.status === 'completed' ? '#8CC63F' : '#FFFFFF' }} onClick={() => { onWorkSheetClick(ability, sheet, levels, item.priority) }}>
                                                                        <p>{l + 1}</p>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        ))}
                                                    </div>}
                                                </React.Fragment>
                                            ))}
                                        </div>
                                    }
                                </React.Fragment>
                            ))}
                        </div>
                    }
                    {currentCycleInfo && previousCycleInfo && currentCycleInfo.cycle_no > 1 && renderRandomTestView()}
                </div>
            }
        </div>
    )
}