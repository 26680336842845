import { Switch, Route, Redirect } from "react-router";
import { ModulesList } from "./list";
import { ModulesInFo } from "./module-info";


interface Props {
    match?: any;
}
const ModulesInfoRoutes = (props: Props) => {

    let url: string | undefined = props.match?.url;
    if (url?.endsWith('/')) {
        url = url.substr(0, url.length - 1);
    }



    return (
        <Switch>
            <Route exact path={`${url}/list`} >
                <ModulesList></ModulesList>
            </Route>
            <Route path={`${url}/info/form/:code/:id`} exact>
                <ModulesInFo></ModulesInFo>
            </Route>
            <Route path={`${url}/info/questions/:code/:id`} exact>
                <ModulesInFo></ModulesInFo>
            </Route>
            <Route path={`${url}/info/MATHS/:code/:id`} exact>
                <ModulesInFo></ModulesInFo>
            </Route>
            <Route path={`${url}/info/MLA-1/:code/:id/:name`} exact>
                <ModulesInFo></ModulesInFo>
            </Route>
            <Route path={`${url}/info/MLA-2/:code/:id/worksheet`} exact>
                <ModulesInFo></ModulesInFo>
            </Route>
            <Route path={`${url}/info/MLA-2/:code/:id/worksheet-numbers/:ability_code`} exact>
                <ModulesInFo></ModulesInFo>
            </Route>
            <Route path={`${url}/info/MLA-2/:code/:id/worksheet-add/:worksheetNumber/:ability_code`} exact>
                <ModulesInFo></ModulesInFo>
            </Route>
            <Route path={`${url}/info/MLA-2/:code/:id/worksheet-edit/:worksheetNumber/:ability_code`} exact>
                <ModulesInFo></ModulesInFo>
            </Route>
            <Route path={`${url}/info/MLA-2/:code/:id/tests`} exact>
                <ModulesInFo></ModulesInFo>
            </Route>
            <Route path={`${url}/info/MLA-2/:code/:id/tests-add/:testNumber`} exact>
                <ModulesInFo></ModulesInFo>
            </Route>
            <Route path={`${url}/info/MLA-2/:code/:id/tests-edit/:testNumber`} exact>
                <ModulesInFo></ModulesInFo>
            </Route>
            <Route path={`${url}/info/ABHYAS/:code/:id/worksheet-add/:worksheetNumber/:ability_code`} exact>
                <ModulesInFo></ModulesInFo>
            </Route>
            <Route path={`${url}/info/ABHYAS/:code/:id`} exact>
                <ModulesInFo></ModulesInFo>
            </Route>
            <Route path={`${url}/info/ABHYAS/:code/:id/:name`} exact>
                <ModulesInFo></ModulesInFo>
            </Route>

            {/* /Abhyas routes */}
            <Route path={`${url}/info/ABHYAS/:code/:id/worksheet-list`} exact>
                <ModulesInFo></ModulesInFo>
            </Route>
            <Route path={`${url}/info/ABHYAS/:code/:id/worksheet`} exact>
                <ModulesInFo></ModulesInFo>
            </Route>
            <Route path={`${url}/info/ABHYAS/:code/:id/:BoardUuid/:BoardName/medium`} exact>
                <ModulesInFo></ModulesInFo>
            </Route>
            <Route path={`${url}/info/ABHYAS/:code/:id/:BoardUuid/:BoardName/:MediumUuid/:MediumdName/standard`} exact>
                <ModulesInFo></ModulesInFo>
            </Route>

            <Route path={`${url}/info/ABHYAS/:code/:id/:BoardUuid/:BoardName/:MediumUuid/:MediumdName/:StandardUuid/:StandardName/subject`} exact>
                <ModulesInFo></ModulesInFo>
            </Route>
            <Route path={`${url}/info/ABHYAS/:code/:id/:BoardUuid/:BoardName/:MediumUuid/:MediumdName/:StandardUuid/:StandardName/:SubjectUuid/:SubjectName/chapter`} exact>
                <ModulesInFo></ModulesInFo>
            </Route>
            <Route path={`${url}/info/ABHYAS/:code/:id/:BoardUuid/:BoardName/:MediumUuid/:MediumdName/:StandardUuid/:StandardName/:SubjectUuid/:SubjectName/:ChapterUuid/:ChapterName/topic`} exact>
                <ModulesInFo></ModulesInFo>
            </Route>
            <Route path={`${url}/info/ABHYAS/:code/:id/:BoardUuid/:BoardName/:MediumUuid/:MediumdName/:StandardUuid/:StandardName/:SubjectUuid/:SubjectName/:ChapterUuid/:ChapterName/:TopicUuid/:TopicName/worksheet-list`} exact>
                <ModulesInFo></ModulesInFo>
            </Route>
            <Route path={`${url}/info/ABHYAS/:code/:id/:BoardUuid/:BoardName/:MediumUuid/:MediumdName/:StandardUuid/:StandardName/:SubjectUuid/:SubjectName/test`} exact>
                <ModulesInFo></ModulesInFo>
            </Route>
            <Route path={`${url}/info/ABHYAS/:code/:id/:BoardUuid/:BoardName/:MediumUuid/:MediumdName/:StandardUuid/:StandardName/:SubjectUuid/:SubjectName/:ChapterUuid/:ChapterName/:TopicUuid/:TopicName/:SheetUuid/:SheetNo/:Type/question`} exact>
                <ModulesInFo></ModulesInFo>
            </Route>
            <Route path={`${url}/info/ABHYAS/:code/:id/:BoardUuid/:BoardName/:MediumUuid/:MediumdName/:StandardUuid/:StandardName/:SubjectUuid/:SubjectName/:SheetUuid/:SheetNo/:Type/abhyas-test-question`} exact>
                <ModulesInFo></ModulesInFo>
            </Route>
            <Route path={`${url}/info/ABHYAS/:code/:id/:BoardUuid/:BoardName/:MediumUuid/:MediumName/:StandardUuid/:StandardName/:SubjectUuid/:SubjectName/:ChapterUuid/:ChapterName/:TopicUuid/:TopicName/pariksha-test`} exact>
                <ModulesInFo></ModulesInFo>
            </Route>
            <Route path={`${url}/info/ABHYAS/:code/:id/:BoardUuid/:BoardName/:MediumUuid/:MediumName/:StandardUuid/:StandardName/:SubjectUuid/:SubjectName/:ChapterUuid/:ChapterName/:TopicUuid/:TopicName/:SheetUuid/:SheetNo/:Type/test-question`} exact>
                <ModulesInFo></ModulesInFo>
            </Route>
            {/* pariksha */}
            <Route path={`${url}/info/PARIKSHA/:code/:id/test-info-list`} exact>
                <ModulesInFo></ModulesInFo>
            </Route>
            <Route path={`${url}/info/ABHYAS/:code/:id/:BoardUuid/:BoardName/:MediumUuid/:MediumdName/:StandardUuid/:StandardName/:SubjectUuid/:SubjectName/pariksha-chapter`} exact>
                <ModulesInFo></ModulesInFo>
            </Route>
        </Switch>
    );
}
export default ModulesInfoRoutes;