import { SchoolListItem } from "../model/school/school-list";
import { UsersListItem } from "../model/users/users-list";
import API from "../utility/axios";
import appConfig from "../../config/constant";

const apiUrl = "/api/v1";
const newApiUrl = "/api/v2";

export const GET_SPONSOR_SCHOOL_INSTITUTE_ID = (
  organization_code: any,
  pageNumber: number,
  data: any
): Promise<SchoolListItem[]> => {
  return API.get(
    `${apiUrl}/school?organization_code=${organization_code}&page_num=${pageNumber}`,
    { params: data }
  );
};

export const GET_STUDNETS_DATA_BY_SCHOOL_CODE = (
  schoolCode: string,
  user_role: string,
  pageNumber: number,
  data: any
): Promise<UsersListItem[]> => {
  return API.get(
    `${apiUrl}/user?institute_school_code=${schoolCode}&user_role=${user_role}&page_num=${pageNumber}`,
    { params: data }
  );
};

export const GENERATE_PAYMENT_LINK = (data: any): Promise<any> => {
  return API.post(`${newApiUrl}/sponsors/generate/link`, data);
};

export const FECTH_SPONSORS_LIST = (
  pageNumber: number,
  data: any
): Promise<any> => {
  return API.get(
    `${newApiUrl}/sponsors/fetch?page=${pageNumber}&limit=${10}&token=${
      appConfig.TXN_URL
    }`,
    { params: data }
  );
};
export const FECTH_SPONSORS_BY_UUID = (Uuid: any): Promise<any> => {
  return API.get(
    `${newApiUrl}/sponsors/fetch?uuid=${Uuid}&token=${appConfig.TXN_URL}`
  );
};
export const DELETE_SPONSOR = (id: number): Promise<any> => {
  return API.delete(`${newApiUrl}/sponsors/delete/${id}`);
};

export const FECTH_SPONSORS_DATA = (Uuid: any): Promise<any> => {
  return API.get(
    `${newApiUrl}/sponsors/fetch?uuid=${Uuid}&token=${appConfig.TXN_URL}`
  );
};
export const UPDATE_SPONSORS_DATA = (Uuid: any, data: any): Promise<any> => {
  return API.put(`${newApiUrl}/sponsors/update/${Uuid}`, data);
};
export const GET_SPONSORS_SEARCH = (data: any): Promise<any> => {
  return API.get(
    `${newApiUrl}/sponsors/fetch?search=${data}&limit=${10}&page=${0}&token=${
      appConfig.TXN_URL
    }`
  );
};

// Sponsor Report End Points

export const FECTH_SPONSORS_REPORT_LIST = (
  pageNumber: number
): Promise<any> => {
  return API.get(`${newApiUrl}/sponsors/report?page=${pageNumber}&limit=${10}`);
};

export const FETCH_SPONSOR_REPORT_FILTER_LIST = (
  // pageNumber: number,
  data: any
): Promise<any> => {
  return API.get(`${newApiUrl}/sponsors/students/report`, {
    params: data,
  });
};

export const GET_SPONSOR_REPORT_DATA_LIST = (): Promise<any> => {
  return API.get(`${newApiUrl}/sponsors/students/report`);
};

export const FECTH_FILTER_SPONSORS_REPORT_LIST = (
  pageNumber: number,
  data: any
): Promise<any> => {
  return API.get(
    `${newApiUrl}/sponsors/report?page=${pageNumber}&limit=${10}`,
    {
      params: data,
    }
  );
};

export const GET_STUDENTS_BY_SPONSOR_REPORT_LIST = (
  pageNumber: number,
  data: any
): Promise<any> => {
  return API.get(
    `${newApiUrl}/sponsored/students?page=${pageNumber}&limit=${10}`,
    {
      params: data,
    }
  );
};
