import API from "../utility/axios";
const apiUrl = "/api/v2";

export const FETCH_ABHYAS_USER_QUESTION_INFO = (data: any): Promise<any> => {
  return API.post(`${apiUrl}/module/abhyaas/user/questions/list`, data);
};

export const FETCH_ABHYAS_USER_QUESTION_PAPER = (data: any): Promise<any> => {
  return API.post(`${apiUrl}/module/abhyaas/fetch/paper`, data);
};
export const UPDATE_ABHYAS_USER_QUESTION_PAPER = (data: any): Promise<any> => {
  return API.post(`${apiUrl}/module/abhyaas/user/answers/update`, data);
};
