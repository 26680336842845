import API from "../utility/axios";
const apiUrl = '/api/v2';

export const GET_ACCESS_PERMISSIONS = (manager: any): Promise<any> => {
    return API.get(`${apiUrl}/access/permissions?role_code=${manager}`);
}

export const UPDATE_PERMISSIONS = (data: any): Promise<any> => {
    return API.post(`${apiUrl}/access/permissions`, data);
}
