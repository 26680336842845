import React, { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { DataTable } from "../../../../../components/data-table";
import { FormControlError, FormField, FormValidators } from "../../../../../components/form-builder/model/form-field";
import { toast } from "react-toastify";
import { FormValidator } from "../../../../../components/form-builder/validations";
import FormBuilder from "../../../../../components/form-builder";
import { Breadcum } from "../../../../../components/breadcum";
import moment from "moment";
import { CREATE_WORKSHEET, DELETE_WORKSHEET, GET_WORKSHEET_BY_UUID, UPDATE_WORKSHEET } from "../../../../../app/service/abhyas-worksheet.service";
import { ParikshaTestDataGridCols } from "./data-grid-cols";
import { GET_PARIKSHA_TEST_FILTER, GET_PARIKSHA_TEST_LIST } from "../../../../../app/service/pariksha-test.service";
import { MathJaxContext, MathJax } from "better-react-mathjax";
import { GET_BOARD_FILTERS_LIST, GET_CHAPTER_FILTERS_LIST, GET_MEDIUM_FILTERS_LIST, GET_STANDARD_FILTERS_LIST, GET_SUBJECT_FILTERS_LIST } from "../../../../../app/service/abhyas-worksheet-filter.service";
import { GET_BOARD_FILTER_LIST, GET_CHAPTER_FILTER_LIST, GET_MEDIUM_FILTER_LIST, GET_STANDARD_FILTER_LIST, GET_SUBJECT_FILTER_LIST } from "../../../../../app/service/common-module.service";

interface Props {
    isLinked?: string;
    display?: string;
    boardUuid?: any;
    mediumUuid?: any;
    standardUuid?: any;
    subjectUuid?: any;
    chapterUuid?: any;
    topicUuid?: any;
    moduelCode?: string;
    moduleId?: any;
    updateList?: (e: any) => void;
    selectTest?: ((e: string) => void);
    filterData?: any;
    isLoading?: boolean
}
export const ParikshaTestDataList: React.FC<Props> = (props: Props) => {
    const [breadCrumLoading, setBreadCrumLoading] = useState<boolean>(false);
    const [testList, setTestList] = useState<any>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [pageArray, setPageNumbers] = useState([]);
    const [activePage, setActivePage] = useState(0);
    const [canShowPopup, setCanShowPopup] = useState<boolean>(false);
    const [testUuid, setTestUuid] = useState<string>("0");
    const [formLoading, setFormLoading] = useState<boolean>(false);
    const [currentFormData, setCurrentFormData] = useState<any>({});
    const [formData, setFormData] = useState<any>({});
    const [formValidationErrors, setFormValidationErrors] = useState<FormControlError[]>([]);
    const [nameError, setNameError] = useState('');
    const [preparedSearchData, setPreparedSearchData] = useState<any>({
        mode: "PARIKSHA",
        sorting_field: "number",
        sorting_type: "asc",
    });
    const medimForm = useRef<any>({});
    const searchForm = useRef<any>({});
    const [searchData, setSearchData] = useState<any>({});
    const [typeError, setTypeError] = useState<boolean>(false);
    const [boardError, setBoardError] = useState<boolean>(false);
    const [mediumError, setMediumError] = useState<boolean>(false);
    const [standardError, setStandardError] = useState<boolean>(false);
    const [subjectError, setSubjectError] = useState<boolean>(false);
    const [chapterError, setChapterError] = useState<boolean>(false);

    const [showQuestionPopUp, setShowQuestionPopUp] = useState<boolean>(false);
    const [loadingWorksheetQuestions, setLoadingWorksheetQuestions] = useState(false);
    const [questionsList, setQuestionsList] = useState<any>([]);
    let { code, id, BoardUuid, BoardName, MediumUuid, MediumName, StandardUuid, StandardName, SubjectUuid, SubjectName, ChapterUuid, ChapterName, TopicUuid, TopicName } = useParams<{ code: string, id: string, BoardUuid: string, BoardName: string, MediumUuid: string, MediumName: string, StandardUuid: string, StandardName: string, SubjectUuid: string, SubjectName: string, ChapterUuid: string, ChapterName: string, TopicUuid: string, TopicName: string }>();
    const moduleCode = code;
    const moduleId = Number(id);
    const boardUuid = BoardUuid ? BoardUuid : props.boardUuid;
    const mediumUuid = MediumUuid ? MediumUuid : props.mediumUuid;
    const standardUuid = StandardUuid ? StandardUuid : props.standardUuid;
    const subjectUuid = SubjectUuid ? SubjectUuid : props.subjectUuid;
    const chapterUuid = ChapterUuid ? ChapterUuid : props.chapterUuid;
    const topicUuid = TopicUuid ? TopicUuid : props.topicUuid;
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [numberError, setNumberError] = useState<string>("");
    const [timeError, setTimeError] = useState<string>("");
    const [questionError, setQuestionError] = useState<string>("");
    const [boardsList, setBoardsList] = useState<any>([]);
    const [mediumList, setMediumList] = useState<any>([]);
    const [standardList, setStandardList] = useState<any>([]);
    const [subjectList, setSubjectList] = useState<any>([]);
    const [chapterList, setChapterList] = useState<any>([]);
    const [originalFilterData, setOriginalFilterData] = useState<any>({});
    const FormValidations = [
        new FormField('type', [FormValidators.REQUIRED]),
        new FormField('level', [FormValidators.REQUIRED]),
    ];
    const history = useHistory();
    const [breadcumInfo, setBreadcumInfo] = useState<any>([
        {
            label: 'Board',
            value: decodeURIComponent(BoardName),
            routerLink: `/dashboard/module/info/ABHYAS/${moduleCode}/${moduleId}/worksheet`,
            subName: "(board)",
            isActive: false
        },
        {
            label: 'Medium',
            value: decodeURIComponent(MediumName),
            routerLink: `/dashboard/module/info/ABHYAS/${moduleCode}/${moduleId}/${boardUuid}/${BoardName}/medium`,
            subName: "(Medium)",
            isActive: false
        },
        {
            label: 'standard',
            value: decodeURIComponent(StandardName),
            routerLink: `/dashboard/module/info/ABHYAS/${moduleCode}/${moduleId}/${boardUuid}/${BoardName}/${MediumUuid}/${MediumName}/standard`,
            subName: "(standard)",
            isActive: false
        },
        {
            label: 'subject',
            value: decodeURIComponent(SubjectName),
            routerLink: `/dashboard/module/info/ABHYAS/${moduleCode}/${moduleId}/${boardUuid}/${BoardName}/${MediumUuid}/${MediumName}/${standardUuid}/${StandardName}/subject`,
            subName: "(subject)",
            isActive: false
        },
        {
            label: 'chapter',
            value: decodeURIComponent(ChapterName),
            routerLink: `/dashboard/module/info/ABHYAS/${moduleCode}/${moduleId}/${boardUuid}/${BoardName}/${MediumUuid}/${MediumName}/${standardUuid}/${StandardName}/${subjectUuid}/${SubjectName}/${moduleCode === "PARIKSHA" ? "pariksha-chapter" : "chapter"}`,
            subName: "(chapter)",
            isActive: false
        },
        {
            label: 'topic',
            value: decodeURIComponent(TopicName),
            routerLink: `/dashboard/module/info/ABHYAS/${moduleCode}/${moduleId}/${boardUuid}/${BoardName}/${MediumUuid}/${MediumName}/${standardUuid}/${StandardName}/${subjectUuid}/${SubjectName}/${chapterUuid}/${ChapterName}/topic`,
            subName: "(topic)",
            isActive: false
        },
        {
            label: 'Tests',
            value: 'Tests',
            nameHighlight: true,
            isActive: true
        }
    ]);


    useEffect(() => {
        if (Object.keys(props.filterData).length !== 0) {
            setOriginalFilterData(props.filterData)
            setFormLoading(true);
            setCurrentFormData(props.filterData);
            const data = { ...formData.value };
            data.board_uuid = props.filterData.board_uuid;
            setFormData({ ...data });
            if (props.filterData.board_uuid) {
                getMediumList(props.filterData.board_uuid);
            }
            if (props.filterData.medium_uuid) {
                getStandardList(props.filterData, props.filterData.medium_uuid, 'edit');
            }

            if (props.filterData.standard_uuid) {
                getSubjectList(props.filterData, props.filterData.standard_uuid, 'edit');
            }
            if (props.filterData.subject_uuid) {
                getChaptersList(props.filterData, props.filterData.subject_uuid, 'edit');
            }
            setTimeout(() => {
                setFormLoading(false);
            }, 2000);
        }
        getBoardData();
        // getWorksheetData();
        getParikshaTestData();

    }, [props?.isLoading])

    const getBoardData = () => {
        setFormLoading(true);
        GET_BOARD_FILTER_LIST().then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res?.err);
                    setFormLoading(false);
                } else {
                    const data = res['rows'];
                    setBoardsList(data);
                    setFormLoading(false);
                }
            }
        });
    }

    const onChangeBoard = (e: any) => {
        setMediumList([]);
        setStandardList([]);
        setSubjectList([]);
        setChapterList([]);
        setTestList([]);
        setQuestionsList([]);
        // setCount(0);
        if (e) {
            getMediumList(e);
        }

        const data = { ...formData.value };
        data.board_uuid = e;
        data.medium_uuid = '';
        data.standard_uuid = '';
        data.subject_uuid = '';
        data.chapter_uuid = '';

        if (e) {
            setBoardError(false);
        }
        if (currentFormData) {
            currentFormData.board_uuid = e;
            currentFormData.medium_uuid = '';
            currentFormData.standard_uuid = '';
            currentFormData.subject_uuid = '';
            currentFormData.chapter_uuid = '';
        }

    }

    const getMediumList = (id: any) => {
        GET_MEDIUM_FILTER_LIST(id).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res?.err);
                } else {
                    const data = res['rows'];
                    setMediumList(data);
                }
            }
        });
    }

    const onChangeMedium = (e: any) => {
        setStandardList([]);
        setSubjectList([]);
        setChapterList([]);
        setTestList([]);
        setQuestionsList([]);
        if (e) {
            getStandardList(formData, e, 'add');
        }

        const data = { ...formData.value };
        data.medium_uuid = e;
        data.standard_uuid = '';
        data.subject_uuid = '';
        data.chapter_uuid = '';
        if (e) {
            setMediumError(false);
        }
        if (currentFormData) {
            currentFormData.medium_uuid = e;
            currentFormData.standard_uuid = '';
            currentFormData.subject_uuid = '';
            currentFormData.chapter_uuid = '';
        }
    }

    const getStandardList = (formData: any, id: any, type: any) => {
        GET_STANDARD_FILTER_LIST(id).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res?.err);
                } else {
                    const data = res['rows'];
                    setStandardList(data);
                }
            }
        });
    }


    const onChangeStandard = (e: any) => {
        setSubjectList([]);
        setChapterList([]);
        setTestList([]);
        setQuestionsList([]);
        if (e) {
            getSubjectList(formData, e, 'add');
        }

        const data = { ...formData.value };
        data.standard_uuid = e;
        data.subject_uuid = '';
        data.chapter_uuid = '';
        if (e) {
            setStandardError(false);
        }
        if (currentFormData) {
            currentFormData.standard_uuid = e;
            currentFormData.subject_uuid = '';
            currentFormData.chapter_uuid = '';
        }
    }

    const getSubjectList = (formData: any, id: any, type: any) => {
        // const board_uuid = type === 'edit' ? formData?.board_uuid : formData?.value?.board_uuid;
        // const medium_uuid = type === 'edit' ? formData?.medium_uuid : formData?.value?.medium_uuid;
        GET_SUBJECT_FILTER_LIST(id).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res?.err);
                } else {
                    const data = res['rows'];
                    setSubjectList(data);
                }
            }
        });
    }




    const onChangeSubject = (e: any) => {
        setChapterList([]);
        setQuestionsList([]);
        if (e) {
            getChaptersList(formData, e, 'add');
        }

        const data = { ...formData.value };
        data.subject_uuid = e;
        data.chapter_uuid = '';
        if (e) {
            setSubjectError(false);
        }
        if (currentFormData) {
            currentFormData.subject_uuid = e;
            currentFormData.chapter_uuid = '';
        }
    }

    const getChaptersList = (formData: any, id: any, type: any) => {
        // const board_uuid = type === 'edit' ? formData?.board_uuid : formData?.value?.board_uuid;
        // const medium_uuid = type === 'edit' ? formData?.medium_uuid : formData?.value?.medium_uuid;
        // const standard_uuid = type === 'edit' ? formData?.standard_uuid : formData?.value?.standard_uuid;
        GET_CHAPTER_FILTER_LIST(id).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res?.err);
                } else {
                    const data = res['rows'];
                    setChapterList(data);
                }
            }
        });
    }

    const onChangeChapter = (e: any) => {
        setTestList([]);
        setQuestionsList([]);
        if (e) {
        }

        const data = { ...formData.value };
        data.chapter_uuid = e;
        if (e) {
            setChapterError(false);
        }
        if (currentFormData) {
            currentFormData.chapter_uuid = e;
        }
    }

    const getParikshaTestData = () => {
        if (Object.keys(props.filterData).length !== 0) {
            if (props.filterData.questions || props.filterData.number || props.filterData.time) {
                delete props.filterData.number;
                delete props.filterData.questions;
                delete props.filterData.time;
            }
        }

        setLoading(true);
        GET_PARIKSHA_TEST_LIST(activePage == 0 ? activePage : activePage - 1, "TESTS", getFilterData(props.filterData)).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res?.err);
                    setLoading(false);

                } else {
                    const data = res?.rows;
                    data?.forEach((element: any) => {
                        element.exam_type = element?.exam_type?.replace(/_/g, ' ');
                        element.created_dt = moment(element?.created_dt?.split("T")[0]).format('DD-MM-yyyy');
                        if (element?.modified_dt) {
                            element.modified_dt = moment(element?.modified_dt?.split("T")[0]).format('DD-MM-yyyy');
                        }
                    });
                    setTestList(data);
                    const pageNumbers = Math.ceil(res.count / 10);
                    const pageArray: any = [];
                    for (let i = 1; i <= pageNumbers; i++) {
                        pageArray.push(i);
                    }
                    setPageNumbers(pageArray);
                    setLoading(false);

                }
            }
        });
    }

    function getFilterData(inputObj: any) {
        const filteredObj: any = {};
        for (const key in inputObj) {
            if (inputObj[key] !== "") {
                filteredObj[key] = inputObj[key];
            }
        }
        return filteredObj;
    }


    const onPageChange = (data: any) => {
        setActivePage(data);
        const pageNumber = data - 1;
        setLoading(true);
        GET_PARIKSHA_TEST_LIST(pageNumber, "TESTS", props.filterData).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res?.err);
                    setLoading(false);
                } else {
                    const data = res.rows;
                    data?.forEach((element: any) => {
                        element.exam_type = element?.exam_type?.replace(/_/g, ' ');
                        element.created_dt = moment(element?.created_dt?.split("T")[0]).format('DD-MM-yyyy');
                        if (element?.modified_dt) {
                            element.modified_dt = moment(element?.modified_dt?.split("T")[0]).format('DD-MM-yyyy');
                        }
                    });
                    setTestList(data);
                    setLoading(false);
                }
            }
        });
    }
    const onDelete = (data: any) => {
        setLoading(true);
        const id = data.uuid;
        DELETE_WORKSHEET(id).then((res: any) => {
            if (res.status === "fail") {
                toast.error(res.message);
                setLoading(false);
            } else {
                setLoading(false);
                toast.success(res.message);
                getParikshaTestData();

                // getWorksheetData();
                if (props.subjectUuid && props.updateList) {
                    props.updateList(subjectUuid);
                }
            }
        });

    }
    const onEdit = (data: any) => {

        setTestUuid(data?.uuid);
        setFormLoading(false);
        setTypeError(false);
        setBoardError(false);
        setMediumError(false);
        setStandardError(false);
        setSubjectError(false);
        setChapterError(false);
        setNameError('');
        setNumberError("");
        setQuestionError("");
        setTimeError("");
        setCanShowPopup(true);
        if (data?.uuid) {
            setFormLoading(true);
            GET_WORKSHEET_BY_UUID(data?.uuid).then((res: any) => {
                if (res.status === 'fail') {
                    toast.error(res?.err);
                    setFormLoading(false);

                } else {
                    if (res.rows.length > 0) {
                        setCurrentFormData(res.rows[0]);
                        setFormLoading(false);
                        getMediumList(res.rows[0].board_uuid);
                        getStandardList(res.rows[0], res.rows[0].medium_uuid, 'edit');
                        getSubjectList(res.rows[0], res.rows[0].standard_uuid, 'edit');
                        getChaptersList(res.rows[0], res.rows[0].subject_uuid, 'edit');
                    }
                }
            });
        }
    }
    const handleClose = () => {
        // setFormData({});
        // setCurrentFormData({});
        setCanShowPopup(false);
        setShowQuestionPopUp(false);
        getParikshaTestData();

        if (props.filterData && props.updateList) {
            props.updateList(props.filterData);
        }
    }


    const addTest = (data: any) => {
        setNumberError("");
        setQuestionError("");
        setTimeError("");
        setFormLoading(false);
        setTypeError(false);
        setBoardError(false);
        setMediumError(false);
        setStandardError(false);
        setSubjectError(false);
        setChapterError(false);
        setCanShowPopup(true);
        setTestUuid(data);
        if (Object.keys(props.filterData).length === 0) {
            medimForm?.current.reset();
            const datafrom = {
                level: '',
                exam_type: "",
                number: '',
                name: '',
                time: "60",
                questions: "30",
                board_uuid: '',
                medium_uuid: '',
                standard_uuid: '',
                subject_uuid: '',
                chapter_uuid: ''
            };
            setFormData({ ...datafrom });
            setCurrentFormData({ ...datafrom });
        } else {
            getBoardData();
            if (Object.keys(props.filterData).length !== 0) {
                setFormLoading(true);
                setCurrentFormData(props.filterData);
                const data = { ...formData.value };
                data.board_uuid = props.filterData.board_uuid;
                setFormData({ ...data });
                if (props.filterData.board_uuid) {
                    getMediumList(props.filterData.board_uuid);
                }
                if (props.filterData.medium_uuid) {
                    getStandardList(props.filterData, props.filterData.medium_uuid, 'edit');
                }

                if (props.filterData.standard_uuid) {
                    getSubjectList(props.filterData, props.filterData.standard_uuid, 'edit');
                }
                if (props.filterData.subject_uuid) {
                    getChaptersList(props.filterData, props.filterData.subject_uuid, 'edit');
                }
                setTimeout(() => {
                    setFormLoading(false);
                }, 2000);
            }
        }

    }


    const handleFormInput = (data: any) => {
        data.value = { ...currentFormData, ...data.value };
        setFormData(data);
        const errors: any = FormValidator(FormValidations, data.value);
        setFormValidationErrors(errors);
    };




    const handleExamType = (e: any) => {
        const data = { ...formData.value };

        data.exam_type = e.target.value;
        setChapterError(false);
        if (e.target.value) {
            setTypeError(false);
        }
        // if (e.target.value === "CHAPTER_WISE_TEST") {
        //     data.chapter_uuid = "";
        //     if (currentFormData) {
        //         currentFormData.chapter_uuid = "";
        //     }
        // }
        if (currentFormData) {
            currentFormData.exam_type = e.target.value;
        }
    }
    const createTest = () => {
        const formsData = formData.value ? { ...formData.value } : { ...currentFormData };
        setIsFormSubmitted(true);
        const errors: FormControlError[] = FormValidator(FormValidations, formsData);
        setFormValidationErrors(errors);
        Object.keys(formsData).forEach(function (key) {
            if (typeof formsData[key] === 'string') {
                formsData[key] = formsData[key].trim();
            }
        });
        const payLoad = {
            board_uuid: formsData.board_uuid,
            medium_uuid: formsData.medium_uuid,
            standard_uuid: formsData.standard_uuid,
            subject_uuid: formsData.subject_uuid,
            chapter_uuid: formsData.exam_type === "CHAPTER_WISE_TEST" ? formsData?.chapter_uuid : '',
            topic_uuid: "",
            uuid: "",
            mode: "PARIKSHA",
            type: "TESTS",
            exam_type: formsData.exam_type,
            level: "",
            number: formsData?.number ? formsData?.number : '',
            name: formsData?.name ? formsData?.name : '',
            time: formsData?.time ? formsData?.time : 60,
            questions: formsData?.questions ? formsData?.questions : 30,
        }
        if (formsData.board_uuid && formsData.medium_uuid && formsData.standard_uuid && formsData.subject_uuid
            && formsData.exam_type
            && (!numberError && formsData?.number) && !questionError && !timeError) {
            if ((formsData.exam_type === "CHAPTER_WISE_TEST" && formsData.chapter_uuid) || formsData.exam_type !== "CHAPTER_WISE_TEST") {

                setFormLoading(true);
                CREATE_WORKSHEET(payLoad).then((res: any) => {
                    if (res.status === 'fail') {
                        const errorValue = Array.isArray(res?.err) ? res?.err[0] : res?.err;
                        toast.error(errorValue);
                        setFormLoading(false);
                    } else {
                        toast.success(res.message);
                        // getWorksheetData();
                        getParikshaTestData();
                        setFormLoading(false);
                        setCanShowPopup(false);
                        setFormData({});
                        setCurrentFormData({});
                        if (payLoad && props.updateList) {
                            props.updateList(props.filterData);
                        }
                    }
                });
            }
            else {
                setChapterError(true);
            }
        }
        else {
            if (!formsData.exam_type) {
                setTypeError(true);
            }

            if (!formsData.board_uuid) {
                setBoardError(true);
            }

            if (!formsData.medium_uuid) {
                setMediumError(true);
            }

            if (!formsData.standard_uuid) {
                setStandardError(true);
            }

            if (!formsData.subject_uuid) {
                setSubjectError(true);
            }


            if (!formsData?.number) {
                setNumberError("Please enter test number");

            } else {
                setNumberError(numberError);
            }
        }


    }
    const updateTest = () => {
        const formsData = formData.value ? { ...formData.value } : { ...currentFormData };
        setIsFormSubmitted(true);
        const errors: FormControlError[] = FormValidator(FormValidations, formsData);
        const payLoad = {
            uuid: testUuid,
            board_uuid: formsData.board_uuid,
            medium_uuid: formsData.medium_uuid,
            standard_uuid: formsData.standard_uuid,
            subject_uuid: formsData?.subject_uuid,
            chapter_uuid: formsData.exam_type === "CHAPTER_WISE_TEST" ? formsData?.chapter_uuid : '',
            topic_uuid: "",
            mode: "PARIKSHA",
            type: "TESTS",
            exam_type: formsData.exam_type,
            level: " ",
            number: formsData?.number ? formsData?.number : '',
            name: formsData?.name ? formsData?.name : '',
            time: formsData?.time ? formsData?.time : 60,
            questions: formsData?.questions ? formsData?.questions : 30,
        }
        setFormValidationErrors(errors);
        if (formsData.board_uuid && formsData.medium_uuid && formsData.standard_uuid && formsData.subject_uuid
            && formsData.exam_type && (!numberError && formsData?.number) && !questionError && !timeError) {
            if ((formsData.exam_type === "CHAPTER_WISE_TEST" && formsData.chapter_uuid) || formsData.exam_type !== "CHAPTER_WISE_TEST") {

                setFormLoading(true);
                UPDATE_WORKSHEET(payLoad).then((res: any) => {
                    if (res.status === 'fail') {
                        const errorValue = Array.isArray(res?.err) ? res?.err[0] : res?.err;
                        toast.error(errorValue);
                        setFormLoading(false);
                    } else {
                        toast.success(res.message);
                        // getWorksheetData();
                        getParikshaTestData();
                        setFormLoading(false);
                        setCanShowPopup(false);
                        setFormData({});
                        setCurrentFormData({});
                        if (payLoad && props.updateList) {
                            props.updateList(props.filterData);
                        }
                    }
                });
            }
            else {
                setChapterError(true);
            }
        } else {
            if (!formsData.exam_type) {
                setTypeError(true);
            }

            if (!formsData.board_uuid) {
                setBoardError(true);
            }

            if (!formsData.medium_uuid) {
                setMediumError(true);
            }

            if (!formsData.standard_uuid) {
                setStandardError(true);
            }

            if (!formsData.subject_uuid) {
                setSubjectError(true);
            }


            if (!formsData?.number) {
                setNumberError("Please enter test number");

            } else {
                setNumberError(numberError);
            }
        }


    }
    const handleSearchInput = (data: any) => {
        setSearchData(data);
    };
    function handleSearch() {
        const data = { ...searchData.value };
        if (data?.search) {
            setLoading(true);
            preparedSearchData.search = data?.search;
            GET_PARIKSHA_TEST_FILTER(preparedSearchData, "TESTS").then((res: any) => {
                if (res.status === 'fail') {
                    toast.error(res?.err);
                    setLoading(false);
                } else {
                    const data = res.rows;
                    data?.forEach((element: any) => {
                        element.created_dt = moment(element?.created_dt?.split("T")[0]).format('DD-MM-yyyy');
                        if (element?.modified_dt) {
                            element.modified_dt = moment(element?.modified_dt?.split("T")[0]).format('DD-MM-yyyy');
                        }
                    });
                    setActivePage(0);
                    setTestList(data);
                    const pageNumbers = Math.ceil(res.count / 10);
                    const pageArray: any = [];
                    for (let i = 1; i <= pageNumbers; i++) {
                        pageArray.push(i);
                    }
                    setPageNumbers(pageArray);
                    setLoading(false);
                }
            });
        }
    }
    function handleRefresh() {
        const data = searchData;
        data.value = {};
        setSearchData({ ...data });
        preparedSearchData.search = "";
        setPreparedSearchData(preparedSearchData);
        // getWorksheetData();
        getParikshaTestData();
        searchForm.current.reset();
    }

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleSearch();
        }
    };
    const onActions = (data: any) => {
        if (data.type === "link") {
            // history.push(
            //     `/dashboard/module/info/PARIKSHA/${props.moduelCode}/${props.moduleId}/${boardUuid}/test-info-list/${data?.data?.uuid}/${data?.data?.number}/question`
            // );
            if (data.data.uuid && props.selectTest) {
                props.selectTest(data.data.uuid);
            }
            // else {
            //     history.push(
            //         `/dashboard/module/info/ABHYAS/${moduleCode}/${moduleId}/${boardUuid}/${BoardName}/${MediumUuid}/${MediumName}/${standardUuid}/${StandardName}/${subjectUuid}/${SubjectName}/${chapterUuid}/${ChapterName}/${topicUuid}/${TopicName}/${data?.data?.uuid}/${data?.data?.number}/${data?.data?.type}/test-question`
            //     );
            //     // history.push(
            //     //     `/dashboard/module/info/ABHYAS/${moduleCode}/${moduleId}/${boardUuid}/${BoardName}/${MediumUuid}/${MediumName}/${standardUuid}/${StandardName}/${subjectUuid}/${SubjectName}/${data?.data?.uuid}/${data?.data?.number}/${data?.data?.type}/test-question`
            //     // );
            // }
        }
    };
    function onViewQuestion(name: any) {
        return { __html: name };
    }
    const viewQuestion = (data: any) => {
        setShowQuestionPopUp(true);
        setLoadingWorksheetQuestions(true);
        let preparedData = JSON.parse(data?.data)
        setQuestionsList(preparedData);
        setLoadingWorksheetQuestions(false);
    }
    const handleTestNumber = (value: any) => {
        const data = { ...formData.value };
        data.number = value;
        const re = /^[0-9\b]+$/;
        if (value) {
            if (re.test(value)) {
                if (value.length <= 7) {
                    setNumberError("");

                } else {
                    setNumberError("Upto 7 digits only")
                }
            }
            else {
                setNumberError("Please enter only numbers ")
            }
        } else {
            setNumberError("Please enter test number ")
        }
        if (currentFormData) {
            currentFormData.number = value;
        }
    }
    const handleTestTime = (value: any) => {
        const data = { ...formData.value };
        if (value) {
            data.time = value;
            const re = /^[0-9\b]+$/;
            if (re.test(value)) {
                if (value.length <= 7) {
                    setTimeError("");

                } else {
                    setTimeError("Upto 7 digits only")
                }
            }
            else {
                setTimeError("Please enter only numbers ")
            }
            if (currentFormData) {
                currentFormData.time = value;
            }
        } else {
            setTimeError("");
            if (currentFormData) {
                currentFormData.time = ""
            }
            data.time = "";

        }
    }
    const handleTestQuestion = (value: any) => {
        const data = { ...formData.value };
        if (value) {
            data.questions = value;
            const re = /^[0-9\b]+$/;
            if (re.test(value)) {
                if (value.length <= 7) {
                    setQuestionError("");

                } else {
                    setQuestionError("Upto 7 digits only")
                }
            }
            else {
                setQuestionError("Please enter only numbers ")
            }
            if (currentFormData) {
                currentFormData.questions = value;
            }

        } else {
            setQuestionError("");
            if (currentFormData) {
                currentFormData.questions = ""
            }
            data.questions = "";

        }
    }

    return (
        <div>
            <div
                className={`modal fade ${showQuestionPopUp ? "show" : "false"}`}
                style={{ display: showQuestionPopUp ? "block" : "none" }}
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog worksheet-quesions-dialog" role="document">
                    <div className="modal-content worksheet-questions-model">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                                Questions List
                            </h5>
                            <button
                                type="button"
                                className="close popup-close cursor-pointer"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={handleClose}
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body parent-info parent-model ">
                            {loadingWorksheetQuestions &&
                                <div className="text-center p-5">
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div>
                            }
                            {!loadingWorksheetQuestions && <div>
                                <MathJaxContext>
                                    <MathJax>
                                        {questionsList?.map((question: any, i: number) => (
                                            <div className="mt-4" key={i}>
                                                <div className='row col-md-12'>
                                                    <div className='row'>
                                                        {/* <div className='col-md-1' style={{ alignSelf: 'center' }}>
                                                            <span className='fw-bold' style={{ fontSize: '22px' }}>Q{question.id}:</span>
                                                        </div> */}
                                                        <div className='col-md-11' style={{ paddingTop: '15px' }}>
                                                            <div className='d-flex'>
                                                                <p className='fw-bold mb-0' style={{ fontSize: '22px' }}>
                                                                    Q{question.id}:
                                                                </p>
                                                                <div className='ms-4 ps-2'>
                                                                    <span className='fw-bold' style={{ fontSize: '22px' }}>
                                                                        {/* <MathJaxContext> */}
                                                                        {/* <MathJax> */}
                                                                        <div dangerouslySetInnerHTML={onViewQuestion(question.question)}></div>
                                                                        {/* </MathJax> */}
                                                                        {/* </MathJaxContext> */}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {question.options.map((options: any, j: number) => (
                                                        <div className='row' key={j}>
                                                            <div className='col-md-1'>
                                                                <span>{options.option}.</span>
                                                            </div>
                                                            <div className='col-md-6'>
                                                                {/* <MathJaxContext> */}
                                                                {/* <MathJax> */}
                                                                <span dangerouslySetInnerHTML={onViewQuestion(options.value)}></span>
                                                                {/* </MathJax> */}
                                                                {/* </MathJaxContext> */}
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        ))}
                                        {questionsList.length == 0 && <h6 className="text-center mt-3">No Data Found</h6>}

                                    </MathJax>
                                </MathJaxContext>
                            </div>}
                        </div>
                        <div className="modal-footer">

                            <button
                                type="button"
                                className="btn btn-secondary cursor-pointer btn-sm px-4 rounded-12 cursor-pointer py-2"
                                data-dismiss="modal"
                                onClick={handleClose}
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className={`modal fade ${canShowPopup ? "show" : "false"}`}
                style={{ display: canShowPopup ? "block" : "none" }}
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog parent-model-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                                {testUuid === "0" ?
                                    (<span>Add Test</span>)
                                    : (<span> Update Test</span>)
                                }
                            </h5>

                            <button
                                type="button"
                                className="close popup-close cursor-pointer"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={handleClose}
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body parent-info ">
                            {formLoading && (
                                <div className="text-center p-5">
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div>
                            )}
                            {!formLoading && (
                                <>
                                    <FormBuilder onUpdate={handleFormInput}>
                                        <form ref={medimForm}>
                                            <div className="row custom-form">


                                                <div className="col-md-4">
                                                    <div className="mb-4">
                                                        <label className="form-label mb-1">Board <sup className="text-danger f14">*</sup></label>
                                                        <select name="board_uuid" id="board_uuid" className="form-select ft-14 " onChange={(e) => { onChangeBoard(e.target.value) }} value={currentFormData?.board_uuid}>
                                                            <option value="">Select board</option>
                                                            {boardsList?.map((name: any, i: number) => (
                                                                <option key={i} value={name?.uuid}>
                                                                    {name?.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        {boardError && <p className="text-danger">Please select board</p>}
                                                    </div>
                                                </div>

                                                <div className="col-md-4">
                                                    <div className="mb-4">
                                                        <label className="form-label mb-1">Medium <sup className="text-danger f14">*</sup></label>
                                                        <select name="medium_uuid" id="medium_uuid" className="form-select ft-14 " onChange={(e) => { onChangeMedium(e.target.value) }} value={currentFormData?.medium_uuid}>
                                                            <option value="">Select medium</option>
                                                            {mediumList?.map((name: any, i: number) => (
                                                                <option key={i} value={name?.uuid}>
                                                                    {name?.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        {mediumError && <p className="text-danger">Please select medium</p>}
                                                    </div>
                                                </div>

                                                <div className="col-md-4">
                                                    <div className="mb-4">
                                                        <label className="form-label mb-1">Standard <sup className="text-danger f14">*</sup></label>
                                                        <select name="standard_uuid" id="standard_uuid" className="form-select ft-14 " onChange={(e) => { onChangeStandard(e.target.value) }} value={currentFormData?.standard_uuid}>
                                                            <option value="">Select standard</option>
                                                            {standardList?.map((name: any, i: number) => (
                                                                <option key={i} value={name?.uuid}>
                                                                    {name?.standard}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        {standardError && <p className="text-danger">Please select standard</p>}
                                                    </div>
                                                </div>

                                                <div className="col-md-4">
                                                    <div className="mb-4">
                                                        <label className="form-label mb-1">Subject <sup className="text-danger f14">*</sup></label>
                                                        <select name="subject_uuid" id="subject_uuid" className="form-select ft-14 " onChange={(e) => { onChangeSubject(e.target.value) }} value={currentFormData?.subject_uuid}>
                                                            <option value="">Select subject</option>
                                                            {subjectList?.map((name: any, i: number) => (
                                                                <option key={i} value={name?.uuid}>
                                                                    {name?.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        {subjectError && <p className="text-danger">Please select subject</p>}
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="mb-4">
                                                        <label className="form-label mb-0">Exam Type <sup className="text-danger f14">*</sup></label>
                                                        <select name="exam_type" id="exam_type" className="form-select f16" onChange={(e) => { handleExamType(e) }} value={currentFormData?.exam_type} >
                                                            <option value="">Select type</option>
                                                            <option value="CHAPTER_WISE_TEST">CHAPTER WISE TEST</option>
                                                            <option value="UNIT_TEST_1">UNIT TEST 1</option>
                                                            <option value="UNIT_TEST_2">UNIT TEST 2</option>
                                                            <option value="SEMESTER_TEST">SEMESTER TEST</option>
                                                            <option value="PRE_BOARD_TEST">PRE BOARD TEST</option>
                                                            <option value="FINAL_TEST">FINAL TEST</option>
                                                            <option value="GROUP_TEST">GROUP TEST</option>
                                                        </select>
                                                        {typeError && <p className="text-danger">Please select Type</p>}
                                                    </div>
                                                </div>

                                                {(currentFormData?.exam_type === "CHAPTER_WISE_TEST" || formData?.value?.exam_type === "CHAPTER_WISE_TEST") && <div className="col-md-4">
                                                    <div className="mb-4">
                                                        <label className="form-label mb-1">Chapter <sup className="text-danger f14">*</sup></label>
                                                        <select name="chapter_uuid" id="chapter_uuid" className="form-select ft-14 " onChange={(e) => { onChangeChapter(e.target.value) }} value={currentFormData?.chapter_uuid}>
                                                            <option value="">Select chapter</option>
                                                            {chapterList?.map((name: any, i: number) => (
                                                                <option key={i} value={name?.uuid}>
                                                                    {name?.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        {chapterError && <p className="text-danger">Please select chapter</p>}
                                                    </div>
                                                </div>}
                                                <div className="col-md-4 ">
                                                    <div className="mb-4">
                                                        <label className="form-label mb-1">Test Number <sup className="text-danger f14">*</sup></label>
                                                        <input className="form-control form-control-lg f16" type="text" name="number" defaultValue={currentFormData?.number} placeholder="Please enter  test number" onChange={(e) => { handleTestNumber(e.target.value) }} />
                                                        {numberError && <p className="text-danger">{numberError}</p>}
                                                    </div>
                                                </div>
                                                <div className="col-md-4 ">
                                                    <div className="mb-4">
                                                        <label className="form-label mb-1">Name </label>
                                                        <input className="form-control form-control-lg f16" type="text" name="name" defaultValue={currentFormData?.name} placeholder="Please enter  test name" />
                                                    </div>
                                                </div>

                                                <div className="col-md-4 ">
                                                    <div className="mb-4">
                                                        <label className="form-label mb-1">Test Time<span className="f12 ms-1 fw-normal">(in minutes)</span></label>
                                                        <input className="form-control form-control-lg f16" type="text" name="time" defaultValue={currentFormData?.time} placeholder="Please enter test time" onChange={(e) => { handleTestTime(e.target.value) }} />
                                                        {timeError && <p className="text-danger">{timeError}</p>}
                                                    </div>
                                                </div>
                                                <div className="col-md-4 ">
                                                    <div className="mb-4">
                                                        <label className="form-label mb-1">Total Questions<span className="f12 ms-1 fw-normal">(in test)</span></label>
                                                        <input className="form-control form-control-lg f16" type="text" name="questions" defaultValue={currentFormData?.questions} placeholder="Please enter no of questions in test  " onChange={(e) => { handleTestQuestion(e.target.value) }} />
                                                        {questionError && <p className="text-danger">{questionError}</p>}
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </FormBuilder>
                                </>
                            )}
                        </div>
                        <div className="modal-footer">
                            {testUuid === "0" && < a className="btn btn-primary btn-sm px-4 rounded-12 cursor-pointer py-2" onClick={() => createTest()}>Add </a>}
                            {testUuid !== "0" && <a className="btn btn-primary btn-sm px-4 rounded-12 cursor-pointer py-2" onClick={() => updateTest()}>Update</a>}
                            <button
                                type="button"
                                className="btn btn-secondary cursor-pointer btn-sm px-4 rounded-12 cursor-pointer py-2"
                                data-dismiss="modal"
                                onClick={handleClose}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className={`mt-3 ${props.display === "none" && "d-none"}`}>
                <Breadcum breadcumList={breadcumInfo} margin={"classic"} />
            </div> */}
            {/* <h5>Tests</h5> */}
            <div className="row  " style={{ marginBottom: '15px' }}>
                {/* <div className="col-md-4">
                    <FormBuilder onUpdate={handleSearchInput}>
                        <form ref={searchForm}>
                            <input type="text" id="search" className="form-control ft-14" placeholder="search" name="search" onKeyDown={(e) => handleKeyDown(e)} />
                        </form>
                    </FormBuilder>
                </div>
                <div className="col-md-4 pt-3 pt-md-0 ">
                    <button className="btn btn-primary px-3 cursor-pointer" type="button" onClick={() => handleSearch()}>Search</button>&nbsp;
                    <button className="btn clear-btn cursor-pointer" type="button" onClick={() => handleRefresh()}>
                        Clear
                    </button>
                </div> */}
                <div className="col-md-10"></div>
                <div className="col-md-2 pt-3 pt-md-0 text-end">
                    <p className="mb-0"><button style={{ position: 'relative', right: '0px', top: '-60px', height: '35px' }} className="btn btn-primary" onClick={() => addTest("0")}>Add Test</button></p>
                </div>
            </div>
            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading &&
                <div className="mt-3">
                    <DataTable TableCols={ParikshaTestDataGridCols} tableData={testList}
                        onActions={(e) => onActions(e)}
                        onChange={viewQuestion}
                        editInfo={onEdit}
                        deleteInfo={onDelete}
                        pageNumber={onPageChange} activePageNumber={activePage} pageNumbers={pageArray}></DataTable>
                </div>}

        </div>
    )
}

