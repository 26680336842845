import React, { useEffect, useState } from "react";
import NestedAbhyasList from "../../../../../routes/insideabhyas";
import NestedAbhyasWorksheetList from "../../../../../routes/abhyasinsideworksheet";
import { NavLink, useHistory, useLocation, useParams } from "react-router-dom";
import { Breadcum } from "../../../../../components/breadcum";
interface Props {

}
export const AbhyasWorksheetInfo: React.FC<Props> = (props) => {
    const location = useLocation();
    const { pathname } = location;
    const splitLocation = pathname.split("/");
    let { code, id, BoardUuid, BoardName, MediumUuid, MediumName, StandardUuid, StandardName, SubjectUuid, SubjectName, ChapterUuid, ChapterName, TopicUuid, TopicName } = useParams<{ code: string, id: string, BoardUuid: string, BoardName: string, MediumUuid: string, MediumName: string, StandardUuid: string, StandardName: string, SubjectUuid: string, SubjectName: string, ChapterUuid: string, ChapterName: string, TopicUuid: string, TopicName: string }>();
    const moduleCode = code;
    const moduleId = Number(id);
    const boardUuid = BoardUuid;
    const mediumUuid = MediumUuid;
    const standardUuid = StandardUuid;
    const subjectUuid = SubjectUuid;
    const chapterUuid = ChapterUuid;
    const topicUuid = TopicUuid;
    const [breadcumInfo, setBreadcumInfo] = useState<any>([
        {
            label: 'Board',
            value: BoardName,
            routerLink: `/dashboard/module/info/ABHYAS/${moduleCode}/${moduleId}/worksheet`,
            subName: "(board)",
            isActive: false
        },
        {
            label: 'Medium',
            value: MediumName,
            routerLink: `/dashboard/module/info/ABHYAS/${moduleCode}/${moduleId}/${boardUuid}/${BoardName}/medium`,
            subName: "(Medium)",
            isActive: false
        },
        {
            label: 'standard',
            value: StandardName,
            routerLink: `/dashboard/module/info/ABHYAS/${moduleCode}/${moduleId}/${boardUuid}/${BoardName}/${MediumUuid}/${MediumName}/standard`,
            subName: "(standard)",
            isActive: false
        },
        {
            label: 'subject',
            value: SubjectName,
            routerLink: `/dashboard/module/info/ABHYAS/${moduleCode}/${moduleId}/${boardUuid}/${BoardName}/${MediumUuid}/${MediumName}/${standardUuid}/${StandardName}/subject`,
            subName: "(subject)",
            isActive: false
        },
        {
            label: 'chapter',
            value: ChapterName,
            routerLink: `/dashboard/module/info/ABHYAS/${moduleCode}/${moduleId}/${boardUuid}/${BoardName}/${MediumUuid}/${MediumName}/${standardUuid}/${StandardName}/${subjectUuid}/${SubjectName}/chapter`,
            subName: "(chapter)",
            isActive: false
        },
        {
            label: 'topic',
            value: TopicName,
            routerLink: `/dashboard/module/info/ABHYAS/${moduleCode}/${moduleId}/${boardUuid}/${BoardName}/${MediumUuid}/${MediumName}/${standardUuid}/${StandardName}/${subjectUuid}/${SubjectName}/${chapterUuid}/${ChapterName}/topic`,
            subName: "(topic)",
            isActive: false
        },
        {
            label: 'Sheets',
            value: 'Worksheets',
            nameHighlight: true,
            isActive: true
        }
    ]);

    useEffect(() => {
        const newArray = [...breadcumInfo];
        if (splitLocation[21] === "WORKSHEETS" || splitLocation[19] === "worksheet-list") {
            newArray[newArray.length - 1].value = "WORKSHEETS";

        } else if (splitLocation[21] === "TESTS" || splitLocation[19] === "test") {
            newArray[newArray.length - 1].value = "TESTS";

        }
        setBreadcumInfo(newArray)
        // updateBreadcrumData()
    }, []);

    const updateBreadcrumData = () => {
        if (splitLocation[21] === "WORKSHEETS" || splitLocation[19] === "worksheet-list") {
            const updatedBreadcumInfo = breadcumInfo.map((item: any) => {
                if (item.label === 'Sheets') {
                    return { ...item, value: 'WORKSHEETS' };
                }
                return item;
            });
            setBreadcumInfo(updatedBreadcumInfo)

        } else if (splitLocation[21] === "TESTS" || splitLocation[19] === "test") {
            const updatedBreadcumInfo = breadcumInfo.map((item: any) => {
                if (item.label === 'Sheets') {
                    return { ...item, value: 'TESTS' };
                }
                return item;
            });
            setBreadcumInfo(updatedBreadcumInfo)

        }
    }
    return (
        <div>
            {/* <div className={`mt-3`}>
                <Breadcum breadcumList={breadcumInfo} margin={"classic"} />
            </div> */}
            <div className="mt-4">
                <ul className="nav nav-tabs custom-tabs">
                    <li className="nav-item">
                        <NavLink className={(splitLocation[21] === "WORKSHEETS" || splitLocation[17] === "topic" || splitLocation[19] === "worksheet-list") ? "nav-link active" : "nav-link"} to={{
                            pathname: `/dashboard/module/info/ABHYAS/${moduleCode}/${moduleId}/${boardUuid}/${BoardName}/${MediumUuid}/${MediumName}/${standardUuid}/${StandardName}/${subjectUuid}/${SubjectName}/chapter`, state: { data: "" }
                        }}>Chapters</NavLink>
                    </li>
                    {/* <li className="nav-item">
                        <NavLink className={splitLocation[21] == "WORKSHEETS" ? "nav-link active" : "nav-link"} to={{
                            pathname: `/dashboard/module/info/ABHYAS/${moduleCode}/${moduleId}/${boardUuid}/${BoardName}/${MediumUuid}/${MediumName}/${standardUuid}/${StandardName}/${subjectUuid}/${SubjectName}/${chapterUuid}/${ChapterName}/${topicUuid}/${TopicName}/worksheet-list`, state: { data: "" }
                        }}>Worksheets</NavLink>
                    </li> */}
                    <li className="nav-item">
                        <NavLink className={splitLocation[17] == "TESTS" ? "nav-link active" : "nav-link"} to={{ pathname: `/dashboard/module/info/ABHYAS/${moduleCode}/${moduleId}/${boardUuid}/${BoardName}/${MediumUuid}/${MediumName}/${standardUuid}/${StandardName}/${subjectUuid}/${SubjectName}/test`, state: { data: "" } }}> Tests</NavLink>
                    </li>
                </ul>
                <div>
                    <NestedAbhyasWorksheetList />
                </div>
            </div>

        </div>
    )
}