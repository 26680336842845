import { SchoolListItem } from "../model/school/school-list";
import API from "../utility/axios";
const apiUrl = "/api/v2";

export const GET_PAN_NUMBER = (pancard: any): Promise<any> => {
  return API.get(`${apiUrl}/organization/fetch/${pancard}`);
};

export const CREATE_ORGANISATION_SIGN_UP = (data: number): Promise<any> => {
  return API.post(`${apiUrl}/organization`, data);
};

export const GET_RESEND_OTP = (data: any): Promise<any> => {
  return API.post(`${apiUrl}/resend/otp`, data);
};

export const GET_REQUEST_OTP = (data: any): Promise<any[]> => {
  return API.post(`${apiUrl}/request/otp`, data);
};

export const GET_VERIFY_OTP = (data: any): Promise<any[]> => {
  return API.post(`${apiUrl}/verify/otp`, data);
};
