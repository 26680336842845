import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { GET_STUDENT_COUNT } from "../../../../app/service/student-count-service";

function SchoolProfileStudentscount() {
    const [studentCountList, setStudentCountList] = useState<any>([]);
    const [loading, setLoading] = useState(false);
    const [totalGirls, setTotalGirls] = useState<number>();
    const [totalBoys, setTotalBoys] = useState<number>();
    const [schoolStudentsCount, setSchoolStudentsCount] = useState(false);
    let { schoolCode } = useParams<{ schoolCode: string }>();
    const schoolsCode = schoolCode;

    useEffect(() => {
        getStudentCount();


    }, []);

    function getStudentCount() {
        setLoading(true);
        GET_STUDENT_COUNT(schoolsCode).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    const data = res.rows;
                    setLoading(false);
                    setStudentCountList(data);
                    let maleCount = 0;
                    let femaleCount = 0;
                    for (let i = 0; i < data.length; i++) {
                        maleCount += data[i].male;
                        femaleCount += data[i].female;
                    }
                    setTotalBoys(maleCount);
                    setTotalGirls(femaleCount);
                }
            }
        });
    }

    return (
        <div>
            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading &&
                <div className="table-responsive">
                    <table className="table table_new_ui">
                        <thead>
                            <tr>
                                <th>Standard</th>
                                <th>Division</th>
                                <th>Girls</th>
                                <th>Boys</th>
                            </tr>
                        </thead>
                        <tbody>
                            {studentCountList?.map((name: any, i: number) => (
                                <tr key={i}>
                                    <td>{name.standard}</td>
                                    <td>{name.division}</td>
                                    <td>{name.female}</td>
                                    <td>{name.male}</td>
                                </tr>
                            ))}
                            {studentCountList?.length > 0 &&
                                <tr>
                                    <td>Total</td>
                                    <td></td>
                                    <td>{totalGirls}</td>
                                    <td>{totalBoys}</td>
                                </tr>}

                        </tbody>
                    </table>
                    {studentCountList?.length === 0 && <p className="f14 mt-3 text-center">No Data Available</p>}
                </div>}

        </div>
    )
}

export default SchoolProfileStudentscount;