import React, { useEffect, useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import { ParentDataGridCols } from './data-grid-cols';
import { ToastContainer, toast } from 'react-toastify';
import { DELETE_INDIVIDUAL_USER, DELETE_USER, GET_INDIVIDUAL_USER, GET_PARENT_BY_INDIVIDUAL_STUDENT, GET_PARENT_BY_STUDENT, GET_USER_USER_CODE } from '../../../../app/service/users.service';
import { DataTable } from '../../../../components/data-table';

export const ParentList = () => {
    let { id, userCode, schoolName } = useParams<{ id: string, userCode: string, schoolName: string }>();
    const studentId = parseInt(id);
    const usersCode = userCode;
    const schoolsName = schoolName;
    const [pageArray, setPageNumbers] = useState([]);
    const [activePage, setActivePage] = useState(1);
    const [parentList, setParentList] = useState<any>([]);
    const [loading, setLoading] = useState(false);
    const [parentInfo, setParentInfo] = useState<any>({});
    const [userRole, setUserRole] = useState<any>();
    const [accessObjectsList, setAccessObjectsList] = useState<any>([]);
    const [studentsParents, setStudentsParents] = useState(false);
    const [apiloaded, setApiloaded] = useState(false);
    const history = useHistory();


    useEffect(() => {
        const userRole = localStorage.getItem("user_role");
        setUserRole(userRole);
        setLoading(true);
        GET_USER_USER_CODE(usersCode).then((res: any) => {
            // setApiloaded(true);
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    const data = res.rows[0];
                    setParentInfo(data);
                    getParents(data);
                    setLoading(false);
                }

            }
        });
        const accessObjectsData: any = localStorage.getItem("access_objects_data");
        setAccessObjectsList(JSON.parse(accessObjectsData));
        handleIndividualStudentsParentsCols(JSON.parse(accessObjectsData));
    }, []);

    function getParents(data: any) {
        if (data?.guardian) {
            const guardian = data?.guardian ? data.guardian.replace(/[^a-zA-Z0-9,-_]/g, "") : "";
            // const guardian = data?.guardian ? data.guardian : "";
            // GET_PARENT_BY_STUDENT(guardian).then((res: any) => {

            GET_PARENT_BY_INDIVIDUAL_STUDENT(guardian).then((res: any) => {

                if (res) {
                    if (res.status === 'fail') {
                        toast.error(res.message);
                        setLoading(false);
                    } else {
                        const data = res.rows;
                        data.forEach((element: any) => {
                            element.date_of_birth = element.date_of_birth ? element.date_of_birth.split(" ")[0] : '';
                            // element.student_code = schoolsName;
                        });

                        setParentList(data);
                        const pageNumbers = Math.ceil(res.count / 10);
                        const pageArray: any = [];
                        for (let i = 1; i <= pageNumbers; i++) {
                            pageArray.push(i);
                        }
                        setPageNumbers(pageArray);
                        setLoading(false);

                    }

                }
            });
        }

    }

    const onPageChange = (data: any) => {
        setActivePage(data);
    }

    const onEditAdmin = (data: any) => {
        history.push(`/dashboard/individual-student/info/${studentId}/parentform/${userCode}/${schoolsName}/${data.id}`);
    }

    const onDeleteParent = (data: any) => {
        setLoading(true);
        const id = data.id;
        DELETE_INDIVIDUAL_USER(id).then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res.message);
                setLoading(false);
            } else {
                setLoading(false);
                toast.success("Parent Deleted Successfully");
                getParents(parentInfo);
            }
        });
    }

    const handleIndividualStudentsParentsCols = (accessObjectsList: any) => {
        const preparedData = accessObjectsList?.filter((el: any) => el.object_code === "INDIVIDUAL");
        if (preparedData?.length > 0) {
            for (let i = 0; i < preparedData[0].child.length; i++) {
                for (let j = 0; j < ParentDataGridCols.length; j++) {

                    if (preparedData[0].child[i].object_code === "INDIVIDUAL-STUDENTS-PARENTS" && preparedData[0].child[i].p_read === 1) {

                        setStudentsParents(true);
                    }
                    if (preparedData[0].child[i].object_code === "INDIVIDUAL-STUDENTS-PARENTS-EDIT" && preparedData[0].child[i].p_read === 1) {
                        if (ParentDataGridCols[j].title == "Actions") {
                            ParentDataGridCols[j].isEdit = true;
                        }

                    }

                    if (preparedData[0].child[i].object_code === "INDIVIDUAL-STUDENTS-PARENTS-DELETE" && preparedData[0].child[i].p_read === 1) {
                        if (ParentDataGridCols[j].title == "Actions") {
                            ParentDataGridCols[j].isDelete = true;
                        }
                    }
                    if (preparedData[0].child[i].object_code === "INDIVIDUAL-STUDENTS-PARENTS-SET-PASSWORD" && preparedData[0].child[i].p_read === 1) {
                        if (ParentDataGridCols[j].title == "Actions") {
                            ParentDataGridCols[j].setPassword = true;
                        }
                    }


                    if (
                        (preparedData[0].child[i].object_code === "INDIVIDUAL-STUDENTS-PARENTS-SET-PASSWORD" && preparedData[0].child[i].p_read === 1) ||
                        (preparedData[0].child[i].object_code === "INDIVIDUAL-STUDENTS-DELETE" && preparedData[0].child[i].p_read === 1) ||
                        (preparedData[0].child[i].object_code === "INDIVIDUAL-STUDENTS-EDIT" && preparedData[0].child[i].p_read === 1)) {
                        if (ParentDataGridCols[j].title == "Actions") {

                            ParentDataGridCols[j].canShowColumn = true;
                        }
                    }
                }
            }
        }

    }

    return (
        <div>
            {studentsParents &&
                <>
                    {(userRole !== "PARENT") && (parentList.length < 2) && <div className="border-primary py-3 text-end">
                        <Link to={`/dashboard/individual-student/info/${studentId}/parentform/${userCode}/${schoolsName}/0`} className="btn btn-primary btn-sm px-5 rounded-12">New</Link>
                    </div>}
                    {loading &&
                        <div className="text-center p-5">
                            <div className="spinner-border" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                    }
                    {!loading &&
                        <DataTable TableCols={ParentDataGridCols} tableData={parentList}
                            editInfo={onEditAdmin} deleteInfo={onDeleteParent}
                            activePageNumber={activePage} pageNumber={onPageChange} pageNumbers={pageArray}></DataTable>
                    }
                </>
            }
        </div>
    )
}