import React, { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { DataTable } from "../../../../../components/data-table";
import { FormControlError, FormField, FormValidators } from "../../../../../components/form-builder/model/form-field";
import { toast } from "react-toastify";
import { FormValidator } from "../../../../../components/form-builder/validations";
import FormBuilder from "../../../../../components/form-builder";
import { Breadcum } from "../../../../../components/breadcum";
import { GET_BOARD_BY_UUID } from "../../../../../app/service/abhyas-board.service";
import { AbhyasMediumDataGridCols } from "../medium/data-grid-cols";
import { CREATE_TOPIC, DELETE_TOPIC, GET_TOPIC_BY_UUID, GET_TOPIC_FILTER, GET_TOPIC_LIST, GET_TOPIC_TEST_LIST, UPDATE_TOPIC } from "../../../../../app/service/abhyas-topic.service";
import moment from "moment";
import { AbhyasTopicDataGridCols } from "./data-grid-cols";
// import { AbhyasMediumDataGridCols } from "../../data-grid-cols";
// import { AbhyasMediumDataGridCols } from "./board-grid-cols";
interface Props {
    isLinked: string;
    display: string;
    boardUuid: any;
    mediumUuid: any;
    standardUuid: any;
    subjectUuid: any;
    chapterUuid: any;
    updateList?: (e: string) => void;
    selectList?: (e: string, name: string) => void;

}
export const AbhyasTopicList: React.FC<Props> = (props) => {
    const [breadCrumLoading, setBreadCrumLoading] = useState<boolean>(false);
    const [topicList, setTopicList] = useState<any>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [pageArray, setPageNumbers] = useState([]);
    const [activePage, setActivePage] = useState(0);
    const [canShowPopup, setCanShowPopup] = useState<boolean>(false);
    const [topicId, setTopicId] = useState<string>("0");
    const [formLoading, setFormLoading] = useState<boolean>(false);
    const [currentFormData, setCurrentFormData] = useState<any>({});
    const [formData, setFormData] = useState<any>([]);
    const [formValidationErrors, setFormValidationErrors] = useState<FormControlError[]>([]);
    const [nameError, setNameError] = useState('');
    const [preparedSearchData, setPreparedSearchData] = useState<any>({});
    const medimForm = useRef<any>({});
    const searchForm = useRef<any>({});
    const [searchData, setSearchData] = useState<any>({});
    let { code, id, BoardUuid, BoardName, MediumUuid, MediumName, StandardUuid, StandardName, SubjectUuid, SubjectName, ChapterUuid, ChapterName } = useParams<{ code: string, id: string, BoardUuid: string, BoardName: string, MediumUuid: string, MediumName: string, StandardUuid: string, StandardName: string, SubjectUuid: string, SubjectName: string, ChapterUuid: string, ChapterName: string }>();
    const moduleCode = code;
    const moduleId = id;
    const boardUuid = BoardUuid ? BoardUuid : props.boardUuid;
    const mediumUuid = MediumUuid ? MediumUuid : props.mediumUuid;
    const standardUuid = StandardUuid ? StandardUuid : props.standardUuid;
    const subjectUuid = SubjectUuid ? SubjectUuid : props.subjectUuid;
    const chapterUuid = ChapterUuid ? ChapterUuid : props.chapterUuid;
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const FormValidations = [
        new FormField('name', [FormValidators.REQUIRED]),
        new FormField('code', [FormValidators.REQUIRED]),
    ];
    const history = useHistory();
    const [breadcumInfo, setBreadcumInfo] = useState<any>([
        {
            label: 'Board',
            value: decodeURIComponent(BoardName),
            routerLink: `/dashboard/module/info/ABHYAS/${moduleCode}/${moduleId}/worksheet`,
            subName: "(board)",
            isActive: false
        },
        {
            label: 'Medium',
            value: decodeURIComponent(MediumName),
            routerLink: `/dashboard/module/info/ABHYAS/${moduleCode}/${moduleId}/${boardUuid}/${BoardName}/medium`,
            subName: "(Medium)",
            isActive: false
        },
        {
            label: 'standard',
            value: decodeURIComponent(StandardName),
            routerLink: `/dashboard/module/info/ABHYAS/${moduleCode}/${moduleId}/${boardUuid}/${BoardName}/${MediumUuid}/${MediumName}/standard`,
            subName: "(standard)",
            isActive: false
        },
        {
            label: 'subject',
            value: decodeURIComponent(SubjectName),
            routerLink: `/dashboard/module/info/ABHYAS/${moduleCode}/${moduleId}/${boardUuid}/${BoardName}/${MediumUuid}/${MediumName}/${standardUuid}/${StandardName}/subject`,
            subName: "(subject)",
            isActive: false
        },
        {
            label: 'chapter',
            value: decodeURIComponent(ChapterName),
            routerLink: `/dashboard/module/info/ABHYAS/${moduleCode}/${moduleId}/${boardUuid}/${BoardName}/${MediumUuid}/${MediumName}/${standardUuid}/${StandardName}/${subjectUuid}/${SubjectName}/${moduleCode === "PARIKSHA" ? "pariksha-chapter" : "chapter"}`,
            subName: "(chapter)",
            isActive: false
        },
        {
            label: 'Topics',
            value: 'Topics',
            nameHighlight: true,
            isActive: true
        }
    ]);

    useEffect(() => {
        for (let i = 0; i < AbhyasTopicDataGridCols.length; i++) {
            if (moduleCode === 'PARIKSHA') {
                AbhyasTopicDataGridCols[i].isLink = false;
            }
        }
        getTopicData();
    }, [])


    const getTopicData = () => {
        setLoading(true);
        // GET_TOPIC_TEST_LIST(activePage, {}).then((res: any) => {

        GET_TOPIC_LIST(activePage == 0 ? activePage : activePage - 1, boardUuid, mediumUuid, standardUuid, subjectUuid, chapterUuid, {}).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res?.err);
                    setLoading(false);
                    setBreadCrumLoading(false);

                } else {
                    const data = res['rows'];
                    data?.forEach((element: any) => {
                        element.created_dt = moment(element?.created_dt?.split("T")[0]).format('DD-MM-yyyy');
                        if (element?.modified_dt) {
                            element.modified_dt = moment(element?.modified_dt?.split("T")[0]).format('DD-MM-yyyy');
                        }
                    });
                    setTopicList(data);
                    const pageNumbers = Math.ceil(res.count / 10);
                    const pageArray: any = [];
                    for (let i = 1; i <= pageNumbers; i++) {
                        pageArray.push(i);
                    }
                    setPageNumbers(pageArray);
                    setLoading(false);
                    setBreadCrumLoading(false);

                }
            }
        });
    }


    const onPageChange = (data: any) => {
        setActivePage(data);
        const pageNumber = data - 1;
        setLoading(true);
        GET_TOPIC_LIST(pageNumber, boardUuid, mediumUuid, standardUuid, subjectUuid, chapterUuid, preparedSearchData ? preparedSearchData : {}).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res?.err);
                    setLoading(false);
                } else {
                    const data = res.rows;
                    data?.forEach((element: any) => {
                        element.created_dt = moment(element?.created_dt?.split("T")[0]).format('DD-MM-yyyy');
                        if (element?.modified_dt) {
                            element.modified_dt = moment(element?.modified_dt?.split("T")[0]).format('DD-MM-yyyy');
                        }
                    });
                    setTopicList(data);
                    setLoading(false);
                }
            }
        });
    }
    const onDelete = (data: any) => {
        setLoading(true);
        const id = data.uuid;
        DELETE_TOPIC(id).then((res: any) => {
            if (res.status === "fail") {
                toast.error(res?.err);
                setLoading(false);
            } else {
                setLoading(false);
                toast.success(res.message);
                getTopicData();
                if (props.chapterUuid && props.updateList) {
                    props.updateList(chapterUuid);
                }
            }
        });

    }
    const onEdit = (data: any) => {
        setTopicId(data?.uuid);
        setFormLoading(false);
        setNameError('');
        setCanShowPopup(true);
        if (data?.uuid) {
            setFormLoading(true);
            GET_TOPIC_BY_UUID(data?.uuid).then((res: any) => {
                if (res.status === 'fail') {
                    toast.error(res?.err);
                    setFormLoading(false);

                } else {
                    if (res.rows.length > 0) {
                        setCurrentFormData(res.rows[0]);
                        setFormLoading(false);

                    }
                }
            });
        }
    }
    const handleClose = () => {
        setFormData({});
        setCurrentFormData({});
        setCanShowPopup(false);
    }
    const addUpdateBoard = (data: any) => {
        setFormLoading(false);
        setNameError('');
        setCanShowPopup(true);
        setTopicId(data);
        const datafrom = {
            description: '',
            name: ''
        };
        setFormData({ ...datafrom });
        setCurrentFormData({ ...datafrom });
        medimForm?.current.reset();
    }
    const handleFormInput = (data: any) => {
        data.value = { ...currentFormData, ...data.value };
        setFormData(data);
        const errors: any = FormValidator(FormValidations, data.value);
        setFormValidationErrors(errors);
    };

    const onChangeName = (event: any) => {
        setNameError('');
        // if (currentFormData) {
        //     currentFormData.name = event;
        // }
        if (event) {
            return true;
        } else {
            setNameError('Please enter topic name');
        }
    }
    const createTopic = () => {
        const formsData = formData.value ? { ...formData.value } : { ...currentFormData };
        setIsFormSubmitted(true);
        const errors: FormControlError[] = FormValidator(FormValidations, formsData);
        setFormValidationErrors(errors);
        Object.keys(formsData).forEach(function (key) {
            if (typeof formsData[key] === 'string') {
                formsData[key] = formsData[key].trim();
            }
        });
        const payLoad = [{
            board_uuid: boardUuid,
            medium_uuid: mediumUuid,
            standard_uuid: standardUuid,
            subject_uuid: subjectUuid,
            chapter_uuid: chapterUuid,
            sequence: 0,
            name: formsData?.name,
            description: formsData?.description

        }]
        if (onChangeName(formsData?.name)) {
            setFormLoading(true);
            CREATE_TOPIC(payLoad).then((res: any) => {
                if (res.status === 'fail') {
                    const errorValue = Array.isArray(res?.err) ? res?.err[0] : res?.err;
                    toast.error(errorValue);
                    setFormLoading(false);
                } else {
                    toast.success(res.message);
                    getTopicData();
                    setFormLoading(false);
                    setCanShowPopup(false);
                    setFormData({});
                    setCurrentFormData({});
                    if (props.chapterUuid && props.updateList) {
                        props.updateList(chapterUuid);
                    }
                }
            });
        } else {
            if (!nameError && !onChangeName(formsData?.name)) {
                setNameError('Please enter topic name');
            }

        }
    }
    const updateTopic = () => {
        const formsData = formData.value ? { ...formData.value } : { ...currentFormData };
        setIsFormSubmitted(true);
        const errors: FormControlError[] = FormValidator(FormValidations, formsData);
        const payLoad = {
            subject_uuid: subjectUuid,
            medium_uuid: mediumUuid,
            standard_uuid: standardUuid,
            description: formsData?.description,
            name: formsData?.name,
            board_uuid: boardUuid,
            chapter_uuid: chapterUuid,
            sequence: 0,
            uuid: topicId
        }
        setFormValidationErrors(errors);
        if (onChangeName(formsData?.name)) {

            setFormLoading(true);
            CREATE_TOPIC([payLoad]).then((res: any) => {
                if (res.status === 'fail') {
                    const errorValue = Array.isArray(res?.err) ? res?.err[0] : res?.err;
                    toast.error(errorValue);
                    setFormLoading(false);
                } else {
                    toast.success(res.message);
                    getTopicData();
                    setFormLoading(false);
                    setCanShowPopup(false);
                    setFormData({});
                    setCurrentFormData({});
                    if (props.chapterUuid && props.updateList) {
                        props.updateList(chapterUuid);
                    }
                }
            });
        } else {
            if (!nameError && !onChangeName(formsData?.name)) {
                setNameError('Please enter topic name');
            }

        }
    }
    const handleSearchInput = (data: any) => {
        setSearchData(data);
    };
    function handleSearch() {
        const data = { ...searchData.value };
        if (data?.search) {
            setLoading(true);
            for (const key of Object.keys(data)) {
                if (data[key] === "") {
                    delete data[key];
                }
            }
            setPreparedSearchData(data);
            GET_TOPIC_FILTER(data, boardUuid, mediumUuid, standardUuid, subjectUuid, chapterUuid).then((res: any) => {
                if (res.status === 'fail') {
                    toast.error(res?.err);
                    setLoading(false);
                } else {
                    const data = res.rows;
                    data?.forEach((element: any) => {
                        element.created_dt = moment(element?.created_dt?.split("T")[0]).format('DD-MM-yyyy');
                        if (element?.modified_dt) {
                            element.modified_dt = moment(element?.modified_dt?.split("T")[0]).format('DD-MM-yyyy');
                        }
                    });
                    setActivePage(0);
                    setTopicList(data);
                    const pageNumbers = Math.ceil(res.count / 10);
                    const pageArray: any = [];
                    for (let i = 1; i <= pageNumbers; i++) {
                        pageArray.push(i);
                    }
                    setPageNumbers(pageArray);
                    setLoading(false);
                }
            });
        }
    }
    function handleRefresh() {
        const data = searchData;
        data.value = {};
        setSearchData({ ...data });
        setPreparedSearchData({});
        getTopicData();
        searchForm.current.reset();
    }
    const onActions = (data: any) => {
        if (props.isLinked === "false" && props.selectList) {
            props.selectList(data?.data?.uuid, data?.data?.name)
        }
        else if (data.type === "link") {
            if (moduleCode === "PARIKSHA") {
                // history.push(
                //     `/dashboard/module/info/ABHYAS/${moduleCode}/${moduleId}/${boardUuid}/${BoardName}/${MediumUuid}/${MediumName}/${standardUuid}/${StandardName}/${chapterUuid}/${ChapterName}/${data?.data?.uuid}/${data?.data?.name}/pariksha-test`
                // );
                // history.push(
                //     `/dashboard/module/info/ABHYAS/${moduleCode}/${moduleId}/${boardUuid}/${BoardName}/${MediumUuid}/${MediumName}/${standardUuid}/${StandardName}/${subjectUuid}/${SubjectName}/${chapterUuid}/${ChapterName}/${data?.data?.uuid}/${data?.data?.name}/pariksha-test`
                // );
            } else {
                history.push(
                    `/dashboard/module/info/ABHYAS/${moduleCode}/${moduleId}/${boardUuid}/${BoardName}/${MediumUuid}/${MediumName}/${standardUuid}/${StandardName}/${subjectUuid}/${SubjectName}/${chapterUuid}/${ChapterName}/${data?.data?.uuid}/${encodeURIComponent(data?.data?.name)}/worksheet-list`
                );
            }
        }
    };
    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleSearch();
        }
    };
    return (
        <div>
            <div
                className={`modal fade ${canShowPopup ? "show" : "false"}`}
                style={{ display: canShowPopup ? "block" : "none" }}
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog parent-model-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                                {topicId === "0" ? "Add topic" : "Update topic"}
                            </h5>
                            <button
                                type="button"
                                className="close popup-close cursor-pointer"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={handleClose}
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body parent-info ">
                            {formLoading && (
                                <div className="text-center p-5">
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div>
                            )}
                            {!formLoading && (
                                <>
                                    <FormBuilder onUpdate={handleFormInput}>
                                        <form ref={medimForm}>
                                            <div className="row ">

                                                <div className="col-md-6 custom-form">
                                                    <div className="mb-4">
                                                        <label className="form-label">Topic Name <sup style={{ color: 'red', fontSize: '14px', }}>*</sup></label>
                                                        <input className="form-control form-control-lg f16 mt-md-4 fw-normal" type="text" name="name" defaultValue={currentFormData?.name} onChange={(e) => onChangeName(e.target.value)} placeholder="Please enter topic name" />
                                                        {nameError && <p className="text-danger">{nameError}</p>}
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="mb-4">
                                                        <label className="form-label">Description </label>
                                                        <textarea className="form-control form-control-lg f16" name="description" defaultValue={currentFormData?.description} placeholder="Please enter topic description" />
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </FormBuilder>
                                </>
                            )}
                        </div>
                        <div className="modal-footer">
                            {topicId === "0" && < a className="btn btn-primary btn-sm px-4 rounded-12 cursor-pointer py-2" onClick={() => createTopic()}>Add</a>}
                            {topicId !== "0" && <a className="btn btn-primary btn-sm px-4 rounded-12 cursor-pointer py-2" onClick={() => updateTopic()}>Update</a>}
                            <button
                                type="button"
                                className="btn btn-secondary cursor-pointer btn-sm px-4 rounded-12 cursor-pointer py-2"
                                data-dismiss="modal"
                                onClick={handleClose}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`mt-3 ${props.display === "none" && "d-none"}`}>
                <Breadcum breadcumList={breadcumInfo} margin={"classic"} />
            </div>


            <div className="row  mt-2" style={{ marginBottom: '15px' }}>
                <div className="col-md-4">
                    <FormBuilder onUpdate={handleSearchInput}>
                        <form ref={searchForm}>
                            <input type="text" id="search" className="form-control ft-14" placeholder="Topic name" name="search" onKeyDown={(e) => handleKeyDown(e)} />
                        </form>
                    </FormBuilder>
                </div>
                <div className="col-md-4 pt-3 pt-md-0 ">
                    <button className="btn btn-primary px-3 cursor-pointer" type="button" onClick={() => handleSearch()}>Search</button>&nbsp;
                    <button className="btn clear-btn cursor-pointer" type="button" onClick={() => handleRefresh()}>
                        Clear
                    </button>
                </div>
                <div className="col-md-4 pt-3 pt-md-0 text-end ">
                    <p className="mb-0"><button className="btn btn-primary" onClick={() => addUpdateBoard("0")}>Add Topic</button></p>
                </div>

            </div>
            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading &&
                <div className="mt-3">
                    <DataTable TableCols={AbhyasTopicDataGridCols} tableData={topicList}
                        onActions={(e) => onActions(e)}
                        editInfo={onEdit}
                        deleteInfo={onDelete}
                        pageNumber={onPageChange} activePageNumber={activePage} pageNumbers={pageArray}></DataTable>
                </div>}

        </div>
    )
}

